import React, { useMemo } from "react";
import styled from 'styled-components';
import { TripLink } from '../trips';
import { SearchLink } from '../search';
import { SectionLabel, SimpleLink, GithubLink, YoutubeLink, SpotifyLink } from '../links';
import { ProductLink } from '../products';
import { NFTLinkGallerySquare } from '../nfts';


const StyledList = styled.div`
    width: 100%;
    margin-top: 16px;
    margin-bottom: ${props => props.bottomsocial ? '32px' : '0'};

    ${({ enablegrid }) => enablegrid && `
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
    `}

    & > * {
        margin-bottom: 16px;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }
`;


const List = ({ width, trips, links, products, nfts, handleViewTrip, handleOpenLink, handleViewProduct, handleViewNFT, customizations }) => {

    const hasTrips = trips && trips.length > 0;
    const hasLinks = links && links.length > 0;
    const hasProducts = products && products.length > 0;
    const hasNFTs = nfts && nfts.length > 0;
    // let hasYouTube = false;
    let hasYouTubeShorts = false;
    // let hasReels = false;
    // let hasTikToks = false;
    // let hasSpotify = false;

    // validate only one category is fetched
    const onlyOneCategoryIsTrue = (hasTrips + hasLinks + hasProducts + hasNFTs) === 1;

    // calculate max NFT card width
    const maxNFTCardWidth = useMemo(() => {
        if(width < 991) return (width - (3 * 16)) / 2; // for mobile
        
        // calculate app section minus the padding on larger screens
        let section = 0;
        if(width >= 991 && width < 1440) {
            section = width - (width * 0.66); // for tablet
        } else if(width >= 1440 && width < 1600) {
            section = width - (width * 0.70); // for laptop
        } else {
            section = width - (width * 0.75); // for laptop
        }

        return (section - (3 * 32)) / 2;
    }, [width]);



    let listItems = null;

    if(hasTrips && onlyOneCategoryIsTrue) {
        listItems = trips.map((item, index) => {
            if(item.isSearch) return <SearchLink key={index} data={item} handleSearch={handleViewTrip} customizations={customizations} />;
            else if(item.isSectionLabel) return <SectionLabel key={index} data={item} customizations={customizations} />;
            return <TripLink key={index} data={item} handleOpen={handleViewTrip} customizations={customizations} />;
        });
    } else if(hasLinks && onlyOneCategoryIsTrue) {
        listItems = links.map((item, index) => {
            switch(item.type) {
                case 'SIMPLE':
                    if(item.isSectionLabel) return <SectionLabel key={index} data={item} customizations={customizations} />;
                    return <SimpleLink key={index} link={item} customizations={customizations} />;
                
                case 'GITHUB':
                    return <GithubLink key={index} link={item} handleOpen={handleOpenLink} customizations={customizations} />;

                case 'YOUTUBE': {
                    // hasYouTube = true;
                    return <YoutubeLink key={index} data={item} customizations={customizations} />;
                }

                case 'YOUTUBESHORT': {
                    hasYouTubeShorts = true;
                    return <YoutubeLink key={index} data={item} customizations={customizations} maxWidth={maxNFTCardWidth + 'px'} maxHeight={'300px'} />;
                }

                // case 'INSTAGRAMREEL': {
                //     // hasReels = true;
                //     return <InstagramReelLink key={index} data={item} customizations={customizations} maxWidth={maxNFTCardWidth + 'px'} maxHeight={'300px'} />;
                // }

                case 'SPOTIFY': {
                    // hasSpotify = true;
                    return <SpotifyLink key={index} data={item} customizations={customizations} />;
                }

                default:
                    return <SimpleLink key={index} link={item} customizations={customizations} />;
            }
        });
    } else if(hasProducts && onlyOneCategoryIsTrue) {
        listItems = products.map((item, index) => {
            if(item.isSectionLabel) return <SectionLabel key={index} data={item} customizations={customizations} />;
            return <ProductLink key={index} data={item} handleOpen={handleViewProduct} customizations={customizations} />;
        });
    } else if(hasNFTs && onlyOneCategoryIsTrue) {
        listItems = nfts.map((item, index) => <NFTLinkGallerySquare key={index} data={item} handleOpen={handleViewNFT} customizations={customizations} maxWidth={maxNFTCardWidth + 'px'} />);
    }

    return (
        <StyledList bottomsocial={customizations.bottomSocial} enablegrid={hasNFTs || hasYouTubeShorts} >
            {listItems}
        </StyledList>
    );
};

export default List;