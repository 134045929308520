import React from "react";
import styled from 'styled-components';
import BookingsListItem from './BookingsListItem';
import { Divider } from '../custom/Divider';
import { deviceConstants } from "../../constants";


const List = styled.div`
    margin-top: 32px;
    padding-bottom: 80px;
`;

const StyledDivider = styled(Divider)`
    @media ${deviceConstants.tablet} {
        display: none;
    }
`;


export const BookingsList = (props) => {
    const { bookings, handleViewBooking } = props;

    const hasBookings = bookings && bookings.length > 0;

    return (
        <List>
            {hasBookings && bookings.map((item, index) => {
                return (
                    <div key={index}>
                        <BookingsListItem data={item} handleView={handleViewBooking} />
                        {index + 1 < bookings.length && <StyledDivider style={{marginBottom: '16px'}} />}
                    </div>
                );
            })}
        </List>
    );
};