import {
    Container,
    TopSection,
    TopSectionColumn,
    Name,
    Review
} from './GoogleReviewsListItem.styles';
import { Avatar } from '../../custom/Avatar';
import { StarSystem } from '../../custom/Rating';


const GoogleReviewsListItem = (props) => {
    const { review, ...rest } = props;

    return (
        <Container {...rest} >
            <TopSection>
                <Avatar imageSize={30} url={review.profilePhotoUrl} />

                <TopSectionColumn>
                    <Name>{review.authorName}</Name>
                    <StarSystem rating={review.rating} />
                </TopSectionColumn>
            </TopSection>

            <Review>{review.text}</Review>
        </Container>
    );
};

export default GoogleReviewsListItem;