import styled from 'styled-components';
import FormRange from 'react-bootstrap/FormRange';

import { colorConstants, deviceConstants } from "../../constants";
import { Button } from '../custom/Buttons';


export const Container = styled.div`
    height: 90vh;
    padding: 32px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const CropperContainer = styled.div`
    height: 50vh;
    min-width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    border-radius: 0;

    position: fixed;
    bottom: 0;

    @media ${deviceConstants.tablet} {
        width: 25%;
        border-radius: 2px;
        
        position: absolute;
        bottom: 20px;
    }

    @media ${deviceConstants.desktop} {
        width: 20%;
    }
`;

export const Controllers = styled.div`
    width: 100%;
    margin-top: 24px;

    & > * {
        margin-top: 16px;
    }

    & > *:first-child {
        margin-top: 0px;
    }
`;

export const Control = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ControlLabel = styled.div`
    min-width: 15%;
    font-size: 12px;
    font-weight: 600;
    font-style: italic;

    margin-right: 16px;
`;

export const Slider = styled(FormRange)`
    :focus::-webkit-slider-thumb {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }

    ::-webkit-slider-thumb {
        background: ${colorConstants.primaryDark};
    }
`;