/**
 * Method to generate default trip JSON Object
 * 
 * @param {String} defaultType The default trip type
 * @returns JSON Object with default Trip values
 */
 export function defaultTrip(defaultType, userId){
    return {
        userId: userId,
        type: defaultType,
        enabled: false,
        isBookable: false,
        skipDetailsPage: false,
        ...resetTripLocationData(),
        ...resetTripPlaceData()
    };
}


/**
 * Method to reset to default Trip location values
 * 
 * @returns JSON Object with default Trip location values
 */
export function resetTripLocationData(){
    return {
        location: '',
        city: '',
        country: ''
    };
}


/**
 * Method to reset default Trip place values
 * 
 * @returns JSON Object with default Trip place values
 */
export function resetTripPlaceData(){
    return {
        name: '',
        partnerId: null,
        googlePlaceId: '',
        description: '',
        customUrl: '',
        affiliate: { label: '', url: '' },
        tripPhotoUrls: []
    };
}


/**
 * Method to generate default trip search component JSON Object
 * 
 * @param {String} type The trip search type
 * @param {Integer} userId The user id
 * @returns JSON Object with Trip Search component values
 */
export function defaultTripSearchComponentData(type, userId){
    let path = `search-${type === 'ACCOMMODATION' ? 'stays' : 'experiences'}`;
    return {
        userId,
        type,
        enabled: true,
        isBookable: false,
        isSearch: true,
        skipDetailsPage: false,
        city: 's',
        country: 's',
        name: 's',
        // partnerId: null,
        googlePlaceId: path,
        description: 's',
        customUrl: path,
        affiliateTitle: 's',
        affiliateLink: 's',
    };
}


/**
 * Method to generate default trip section component JSON Object
 * 
 * @param {String} type The trip section type
 * @param {Integer} userId The user id
 * @returns JSON Object with Trip Section component values
 */
export function defaultTripSectionComponentData(type, userId){
    let path = `section-${type === 'ACCOMMODATION' ? 'stays' : 'experiences'}`;
    return {
        userId,
        type,
        enabled: true,
        isBookable: false,
        isSearch: false,
        isSectionLabel: true,
        skipDetailsPage: false,
        city: 's',
        country: 's',
        name: 's',
        // partnerId: null,
        googlePlaceId: path,
        description: 'My Section',
        customUrl: path,
        affiliateTitle: 's',
        affiliateLink: 's',
    };
}


/**
 * Method to generate default google search JSON Object
 * 
 * @returns JSON Object with default Google Search values
 */
export function defaultGoogleSearch(){
    return {
        location: {
            locations: [],
            selectedId: '',
            selectedCoordinates: { lat: 0, lng: 0 }
        },
        place: {
            places: [],
            preview: null
        }
    };
}


/**
 * Method to generate default photos JSON Object
 * 
 * @returns JSON Object with default Photos values
 */
export function defaultPhotos(){
    return {
        crop: {
            pendingImage: '',
            pendingImageFilename: '',
            pendingImageFileSize: 0,
            pendingImageDimensions: { height: 0, width: 0 }
        },
        temp: []
    };
}