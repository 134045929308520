/**
 * Method to generate default product JSON Object
 * 
 * @param {String} defaultType The default product type
 * @returns JSON Object with default Product values
 */
 export function defaultProduct(defaultType, userId){
    return {
        userId: userId,
        partnerId: null,
        type: defaultType,
        enabled: false,
        skipDetailsPage: false,
        price: null,
        name: '',
        description: '',
        customUrl: '',
        button: { label: '', url: '' },
        imageUrls: [],
    };
}


/**
 * Method to generate default product section component JSON Object
 * 
 * @param {String} type The product section type
 * @param {Integer} userId The user id
 * @returns JSON Object with Product Section component values
 */
export function defaultProductSectionComponentData(type, userId){
    let path = `section-${type.toLowerCase()}`;
    return {
        userId,
        type,
        enabled: true,
        isSectionLabel: true,
        skipDetailsPage: false,
        price: null,
        name: 's',
        // partnerId: null,
        description: 'My Section',
        customUrl: path,
        btnLabel: 's',
        btnUrl: 's',
    };
}