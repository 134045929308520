import React from 'react';
import styled from 'styled-components';
import FormControl from 'react-bootstrap/FormControl'

import { colorConstants } from "../../../constants";

const GradientBorder = styled.div`
    display: flex;
    align-items: center;
    background: ${colorConstants.gradientHorizontal};
    border-radius: 0px;
    padding: 0 0 1px 0;
    margin-top: 3px;

    ${({ box }) => box && `
        border-radius: 4px;
        padding: 1px;
        margin-top: 8px;
    `}

    ${({ box2 }) => box2 && `
        border-radius: 2px;
        padding: 1px;
        margin-top: 8px;
    `}

    :focus-within {
        background: ${colorConstants.primaryDark};
    }
`;

const StyledFormControl = styled(FormControl)`
    font-size: 12px;
    font-weight: 500;
    padding: 8px 0 0 0;
    border-radius: 0px;

    ${({ box }) => box && `
        font-weight: 400;
        padding: 12px;
        border-radius: 4px;
    `}

    ${({ box2 }) => box2 && `
        font-weight: 400;
        padding: 12px;
        border-radius: 2px;
    `}

    color: ${colorConstants.black};
    border: none;

    :focus {
        border-color: transparent;

        ${({ box }) => box && `
            border-color: ${colorConstants.primaryDark};
        `}

        box-shadow: none;
        outline: none;
    }

    ::placeholder {
        color: ${props => props.nolabel ? colorConstants.mediumGrey : colorConstants.softGrey};
        font-weight: ${props => props.nolabel ? '600' : '400'};
    }
`;

export const GradientFormInput = (props) => {
    const { box, box2 } = props;

    return (
        <GradientBorder box={box} box2={box2} >
            <StyledFormControl {...props} />
        </GradientBorder>
    );
};