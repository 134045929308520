import React, { useMemo } from "react";
import {
    Container, DashboardSection, ProfilePreviewSection,
    TopSection,
    Settings, SettingsButton,
    TopSectionControls, CopyLink,
    UserInfoSection,
    UserInfoSectionColumn,
    UserInfoFullNameRow,
    UserInfoFullName,
    UserInfoProfileLink,
    OpenPublicPageButton,
    UserInfoEmail,
    UserInfoAccountType,
    ContentSection,
    Header,
    HeaderGreetingsName,
    CreatorApplicationSection,
    QuickLinks,
    NotificationCardsSection,
    CategoriesOverviewSection
} from './DashboardPage.styles';
import { Avatar } from '../custom/Avatar';
import { NotificationCard } from './cards/NotificationCard';
import { QuickLink } from './cards/QuickLink';
import { CategoryOverviewCard } from './cards/CategoryOverviewCard';
import { generateFirebasePhotoLink } from '../../utils';
import { getUserAccountType, otherConstants, urlConstants, getProfileLink, colorConstants } from '../../constants';
import Icon from '../../assets/icons/Icon';


export const DashboardPage = (props) => {
    const {
        user,
        creatorModeStatus,
        handleSideBarToggle,
        handleOpenSettings,
        handleOpenCustomizations,
        handleOpenLinks,
        handleOpenTrips,
        handleOpenProducts,
        handleOpenFavourites,
        handleOpenNFTs,
        handleAddTrip,
        // handleAddProduct,
        // handleAddNFT,
        handleBecomeCreator,
        handleCopyLink,
        handleUpgrade
    } = props;

    // set user info account type
    const userInfoAccountTypeText = useMemo(() => (getUserAccountType(user, true) + ' account'), [user]);

    const showCreatorApplicationStatus = useMemo(() => {
        if(creatorModeStatus && creatorModeStatus === 'REVIEWING') return true;
        return false;
    }, [creatorModeStatus]);

    return (
        <Container>

            <DashboardSection>
                <TopSection>
                    <Settings>
                        <SettingsButton onClick={handleSideBarToggle} />
                    </Settings>

                    { user.creator &&
                    <TopSectionControls>
                        <CopyLink onClick={() => handleCopyLink(urlConstants.baseUrlClient + getProfileLink(user.username))}>
                            <Icon type='copy' size={13} customcolor={colorConstants.darkGrey} />
                            Copy Link
                        </CopyLink>
                    </TopSectionControls> }

                    <UserInfoSection>
                        <Avatar border={true} mode="view" imageSize={72} url={user.profilePhotoUrl} onClick={handleOpenSettings} cursor='pointer' />

                        <UserInfoSectionColumn className="first-step" >
                            <UserInfoFullNameRow>
                                { !user.creator && <UserInfoFullName>{user.name}</UserInfoFullName> }
                                { user.creator &&
                                <UserInfoProfileLink href={getProfileLink(user.username)} target='_blank' >
                                    {otherConstants.domain + getProfileLink(user.username)}
                                    <OpenPublicPageButton />
                                </UserInfoProfileLink> }
                            </UserInfoFullNameRow>

                            <UserInfoEmail>{user.confirmedEmail === 'not-confirmed' ? user.email : user.confirmedEmail}</UserInfoEmail>
                            <UserInfoAccountType>{userInfoAccountTypeText}</UserInfoAccountType>
                        </UserInfoSectionColumn>
                    </UserInfoSection>
                    
                </TopSection>

                <ContentSection>
                    <QuickLinks className="third-step" >
                        { !user.creator &&
                        <QuickLink
                            linkIcon={true}
                            onClick={handleOpenFavourites}
                            title='Organize links'
                            text='Manage your saved links' /> }

                        { user.creator && user.dashData.profileComplete < 100 &&
                        <QuickLink
                            className="last-step"
                            profileIcon={true}
                            onClick={handleOpenSettings}
                            title='Complete profile'
                            text='Social links, website & bio'
                            secondaryText={user.dashData.profileComplete + '%'} /> }

                        { user.creator &&
                        <QuickLink
                            linkIcon={true}
                            addIcon={true}
                            onClick={handleOpenLinks}
                            title='Add link'
                            text='Portfolio, blog & shop' /> }

                        { user.creator &&
                        <QuickLink
                            tripIcon={true}
                            addIcon={true}
                            onClick={handleAddTrip}
                            title='Add trip'
                            text='Experiences & stays' /> }

                        {/* { user.creator &&
                        <QuickLink
                            nftIcon={true}
                            addIcon={true}
                            onClick={handleAddNFT}
                            title='Add NFT'
                            text='Doodles, Bored Apes & more' /> } */}

                        { user.creator &&
                        <QuickLink
                            customizeIcon={true}
                            editIcon={true}
                            onClick={handleOpenCustomizations}
                            title='Style your page'
                            text='Change colors, fonts & more' /> }

                        {/* { user.creator &&
                        <QuickLink
                            productIcon={true}
                            addIcon={true}
                            onClick={handleAddProduct}
                            title='Add product'
                            text='Books, tech gadgets & gear' /> } */}

                        {/* { user.creator &&
                        <QuickLink
                            editBioIcon={true}
                            onClick={handleOpenSettings}
                            title='Update your bio'
                            text='Edit your bio, name & social' /> } */}
                    </QuickLinks>

                    <Header>
                        Hello,
                        <HeaderGreetingsName>{user.firstName}!</HeaderGreetingsName>
                    </Header>

                    { showCreatorApplicationStatus &&
                    <CreatorApplicationSection>
                        <Icon type='review' size={32} customcolor={colorConstants.secondary} />
                        <span>Your creator application is under review.</span>
                    </CreatorApplicationSection> }

                    <NotificationCardsSection>
                        { !user.creator && !showCreatorApplicationStatus &&
                        <NotificationCard
                            imgUrl={generateFirebasePhotoLink('public%2Fapp%2Fbanners%2Fadmin_dash_become_creator.jpg?alt=media')}
                            onClick={handleBecomeCreator}
                            title='Become a Creator'
                            text="Switch to creator account and turn your passions into livelihood" /> }

                        { user.creator && !user.basic && !user.premium && !user.platinum && user.dashData.profileComplete > 50 &&
                        <NotificationCard
                            imgUrl={generateFirebasePhotoLink('public%2Fapp%2Fbanners%2Fadmin_dash_upgrade_premium.jpg?alt=media')}
                            onClick={handleUpgrade}
                            title='Upgrade Plan'
                            text="Customize your page with your personal touch & creativity" /> }
                    </NotificationCardsSection>

                    <CategoriesOverviewSection className="second-step" >
                        { user.creator && user.pageData.metadata.links.simple.enabled &&
                        <CategoryOverviewCard
                            isLink={true}
                            title='Links'
                            data={user.pageData.metadata.links.simple.totalActive + ' / ' + user.pageData.metadata.links.simple.total}
                            label='active'
                            onClick={handleOpenLinks} /> }
                        
                        { user.creator && user.pageData.metadata.links.blog.enabled &&
                        <CategoryOverviewCard
                            isBlog={true}
                            title='Blogs'
                            data={user.pageData.metadata.links.blog.totalActive + ' / ' + user.pageData.metadata.links.blog.total}
                            label='active'
                            onClick={handleOpenLinks} /> }

                        { user.creator && user.pageData.metadata.links.accommodation.enabled &&
                        <CategoryOverviewCard
                            isStay={true}
                            title='Stays'
                            data={user.pageData.metadata.links.accommodation.totalActive + ' / ' + user.pageData.metadata.links.accommodation.total}
                            label='active'
                            onClick={handleOpenTrips} /> }

                        { user.creator && user.pageData.metadata.links.experience.enabled &&
                        <CategoryOverviewCard
                            isExperience={true}
                            title='Experiences'
                            data={user.pageData.metadata.links.experience.totalActive + ' / ' + user.pageData.metadata.links.experience.total}
                            label='active'
                            onClick={handleOpenTrips} /> }

                        { user.creator && user.pageData.metadata.links.github.enabled &&
                        <CategoryOverviewCard
                            isGithub={true}
                            title='Github'
                            data={user.pageData.metadata.links.github.totalActive + ' / ' + user.pageData.metadata.links.github.total}
                            label='active'
                            onClick={handleOpenLinks} /> }

                        { user.creator && user.pageData.metadata.links.youtube.enabled &&
                        <CategoryOverviewCard
                            isYouTube={true}
                            title='YouTube'
                            data={user.pageData.metadata.links.youtube.totalActive + ' / ' + user.pageData.metadata.links.youtube.total}
                            label='active'
                            onClick={handleOpenLinks} /> }

                        { user.creator && user.pageData.metadata.links.youtubeshort.enabled &&
                        <CategoryOverviewCard
                            isYouTube={true}
                            title='Shorts'
                            data={user.pageData.metadata.links.youtubeshort.totalActive + ' / ' + user.pageData.metadata.links.youtubeshort.total}
                            label='active'
                            onClick={handleOpenLinks} /> }

                        { user.creator && user.pageData.metadata.links.instagramreel.enabled &&
                        <CategoryOverviewCard
                            isReel={true}
                            title='Reels'
                            data={user.pageData.metadata.links.instagramreel.totalActive + ' / ' + user.pageData.metadata.links.instagramreel.total}
                            label='active'
                            onClick={handleOpenLinks} /> }

                        { user.creator && user.pageData.metadata.links.tiktok.enabled &&
                        <CategoryOverviewCard
                            isTikTok={true}
                            title='TikTok'
                            data={user.pageData.metadata.links.tiktok.totalActive + ' / ' + user.pageData.metadata.links.tiktok.total}
                            label='active'
                            onClick={handleOpenLinks} /> }

                        { user.creator && user.pageData.metadata.links.spotify.enabled &&
                        <CategoryOverviewCard
                            isSpotify={true}
                            title='Spotify'
                            data={user.pageData.metadata.links.spotify.totalActive + ' / ' + user.pageData.metadata.links.spotify.total}
                            label='active'
                            onClick={handleOpenLinks} /> }
                        
                        { user.creator && user.pageData.metadata.links.nft.enabled &&
                        <CategoryOverviewCard
                            isNFT={true}
                            title='NFTs'
                            data={user.pageData.metadata.links.nft.totalActive + ' / ' + user.pageData.metadata.links.nft.total}
                            label='active'
                            onClick={handleOpenNFTs} /> }

                        { user.creator && user.pageData.metadata.links.physicalProduct.enabled &&
                        <CategoryOverviewCard
                            isPhysicalProduct={true}
                            title='Products'
                            data={user.pageData.metadata.links.physicalProduct.totalActive + ' / ' + user.pageData.metadata.links.physicalProduct.total}
                            label='active'
                            onClick={handleOpenProducts} /> }

                        <CategoryOverviewCard
                            isFavourite={true}
                            title='Saved'
                            data={user.pageData.metadata.favourites.total}
                            label='total'
                            onClick={handleOpenFavourites} />
                    </CategoriesOverviewSection>
                </ContentSection>
            </DashboardSection>

            <ProfilePreviewSection>
                
            </ProfilePreviewSection>

        </Container>
    );
};