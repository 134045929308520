import styled from 'styled-components';
import { Bookmarks, ArrowRight } from 'react-bootstrap-icons';

import { colorConstants } from "../../../constants";


export const Container = styled.div`
    width: 47%;
    margin: 8px 0;
    padding: 16px;

    border-radius: 8px;
    background: ${colorConstants.gradientVertical};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :hover {
        cursor: ${props => props.cursor};
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
`;

export const TopSection = styled.div`
    margin-bottom: 48px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const MiddleSection = styled.div`
    margin-bottom: 48px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const BottomSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${colorConstants.white};
`;

export const Data = styled.div`
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    text-align: center;

    color: ${colorConstants.black};
`;

export const Label = styled.div`
    font-size: 13px;
    font-weight: 600;
    line-height: normal;

    color: ${colorConstants.white};
`;

export const FavouritesIcon = styled(Bookmarks)`
    font-size: 24px;
    color: ${colorConstants.black};
`;

export const RightIcon = styled(ArrowRight)`
    font-size: 24px;
    color: ${colorConstants.black};
`;