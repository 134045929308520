import React, { useRef, useMemo } from 'react';

import {
    Container,
    TopSection, Header, Name, EnableToggle,
    Group, GroupInputTitle, GroupInputText, WarningText,
    UploadPhotosContainer, FileUploader
} from './EditProduct.styles';
import { GradientFormInput, GradientRichEditor, SimpleLinkInput } from '../../custom/FormInput';
import { UploadPhoto } from '../../custom/Images';
import { contentAddProductPage } from '../../../constants';
import { useWindowSize } from '../../../utils';


export const EditProduct = ({
    product,
    handleChange,
    handleDescriptionLengthChange,
    productDescriptionLength,
    handleUploadPhoto1,
    handleUploadPhoto2
}) => {

    // detect screen size
    const [width] = useWindowSize();

    // calculate max image width
    const maxImageWidth = useMemo(() => {
        if(width < 991) return (width - (3 * 32)) / 2; // for mobile
        
        // calculate app section minus the padding on larger screens
        let section = 0;
        if(width >= 991 && width < 1440) {
            section = width - (width * 0.66); // for tablet
        } else if(width >= 1440 && width < 1600) {
            section = width - (width * 0.70); // for laptop
        } else {
            section = width - (width * 0.75); // for laptop
        }

        return (section - (3 * 32)) / 2;
    }, [width]);


    // open file browser on profile click
    const inputPhoto1 = useRef(null);
    const openFileUploader1 = () => {
      inputPhoto1.current.click();
    };
    const inputPhoto2 = useRef(null);
    const openFileUploader2 = () => {
      inputPhoto2.current.click();
    };


    return (
        <Container>
            
            <TopSection>
                <Header>
                    <Name>{product.name}</Name>
                </Header>

                <EnableToggle type="switch" name={'product-enable'} checked={product.enabled} onChange={handleChange} />
            </TopSection>

            <Group>
                <GroupInputTitle>Product Name</GroupInputTitle>
                <GradientFormInput type="text" name="name" value={product.name} onChange={handleChange} />
                { (!product.name || product.name.length < 4) && <WarningText>Product name is required</WarningText> }
            </Group>

            { product && product.imageUrls &&
            <Group>
                <GroupInputTitle>Upload Product Photos</GroupInputTitle>

                <UploadPhotosContainer>
                    <UploadPhoto showEdit={true} url={product.imageUrls[0] ? product.imageUrls[0] + '&' + Date.now() : null} onClick={openFileUploader1} width={maxImageWidth + 'px'} />
                    <FileUploader type='file' accept="image/*" id='photo1' ref={inputPhoto1} onChange={handleUploadPhoto1} />
                    <UploadPhoto showEdit={true} url={product.imageUrls[1] ? product.imageUrls[1] + '&' + Date.now() : null} onClick={openFileUploader2} width={maxImageWidth + 'px'} />
                    <FileUploader type='file' accept="image/*" id='photo2' ref={inputPhoto2} onChange={handleUploadPhoto2} />
                </UploadPhotosContainer>
            </Group> }

            <Group>
                <GroupInputTitle>Product Description</GroupInputTitle>
                <GradientRichEditor
                    placeholder={contentAddProductPage.placeholders.description}
                    value={product.description}
                    // withDelay={500}
                    onChange={(value) => handleChange({ target: { name: 'description', value } })}
                    maxLength={10000}
                    getCurrentTextLength={handleDescriptionLengthChange} />
                { (!product.description || product.description.length < 57 || productDescriptionLength < 50) && <WarningText>Write at least 50 characters description</WarningText> }
            </Group>

            { !product.partnerId &&
            <Group>
                <GroupInputTitle>Add Custom Link</GroupInputTitle>
                <SimpleLinkInput
                    data={product.button}
                    hasBorder={true}
                    handleLabelChange={handleChange}
                    handleUrlChange={handleChange} />
            </Group> }

            <Group>
                <GroupInputTitle>Edit Product Listing URL</GroupInputTitle>
                <GradientFormInput box2='true' type="text" name="customUrl" value={product.customUrl} onChange={handleChange} />
            </Group>

            { !product.partnerId &&
            <Group row='true' >
                <GroupInputText>Skip Product Listing Page</GroupInputText>
                <EnableToggle type="switch" name={'skipDetailsPage-enable'} checked={product.skipDetailsPage} onChange={handleChange} />
            </Group> }
        </Container>
    );
};