import styled from 'styled-components';
import { colorConstants } from "../../../constants";



export const Container = styled.div`
    height: 128px;
    width: 100%;
    margin: 16px 0;
    padding: 16px;

    border-radius: 8px;
    background: ${colorConstants.verySoftWhite};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-image: url("${props => props.imgUrl}");
    background-size: cover;

    :hover {
        cursor: ${props => props.cursor};
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
`;

export const TopSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const BottomSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const Title = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${colorConstants.white};
`;

export const Text = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 1.25;
    color: ${colorConstants.white};
`;