import React from 'react';
import { GradientFormInput } from '../../custom/FormInput';
import {
    Container,
    Title,
    TopMessage,
    StyledForm,
    StyledFormGroup,
    FormInputTitle,
    StyledFormText,
    StyledButton,
    NavigationContainer,
    StyledNavigationLink
} from './ResetPasswordRequest.styles';
import { sitemap } from '../../../constants';


const ResetPasswordRequest = (props) => {
    const { credentials, submitted, handleSubmit, handleChange, alert } = props;

    return (
        <Container>
            <Title>Reset Your Password</Title>
            
            <TopMessage>
                Enter your email address and we will send you instructions to reset your password.
            </TopMessage>

            <StyledForm id="reset-password-request">
                <StyledFormGroup>
                    <FormInputTitle>Email Address</FormInputTitle>
                    <GradientFormInput type="email" name="email" value={credentials.email} onChange={handleChange} />
                    { submitted && !credentials.email && <StyledFormText>Email is required</StyledFormText> }
                    { alert && alert.message && <StyledFormText>{alert.message}</StyledFormText> }
                </StyledFormGroup>
            </StyledForm>

            <StyledButton
                gradient={true}
                type="submit"
                onClick={handleSubmit}
            >
                Continue
            </StyledButton>

            <NavigationContainer>
                <StyledNavigationLink to={sitemap.auth.login} >Back to Login</StyledNavigationLink>
            </NavigationContainer>
        </Container>
    );
};

export default ResetPasswordRequest;