import styled from 'styled-components';
import { ChevronLeft, Trash, Pencil, ShareFill, Bookmark, BookmarkFill, SlashCircle, Clock } from 'react-bootstrap-icons';
import { Button } from '../../custom/Buttons';
import { colorConstants, deviceConstants, shadowConstants } from "../../../constants";


export const Container = styled.div`
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${deviceConstants.tablet} {
        box-shadow: ${shadowConstants.adminDesktop};
        background: ${colorConstants.white};
    }
`;

export const ContentSection = styled.div`
    ${({ bottommargin }) => bottommargin && `
        margin-bottom: 170px;
    `}
`;

export const ButtonsSection = styled.div`

`;

export const Row = styled.div`
    display: flex;
    align-items: ${({ align }) => align};
    justify-content: ${({ justify }) => justify};

    ${({ space }) => space && `
        & > * {
            margin-right: 8px;
        }

        & > *:last-child {
            margin-right: 0px;
        }
    `};
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({ align }) => align};
    justify-content: ${({ justify }) => justify};

    ${({ space }) => space && `
        & > * {
            margin-bottom: 8px;
        }

        & > *:last-child {
            margin-bottom: 0px;
        }
    `};
`;

export const PhotoSection = styled.div`
    position: relative;
`;

export const TopControls = styled.div`
    padding: 16px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TopControlFader = styled.div`
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 33%, rgba(255,255,255,0.527345972568715) 95%);
    opacity: 0.1;
    z-index: 1;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const TopControlsColumn = styled.div`
    display: flex;
    justify-content: ${props => props.align};
    align-items: center;

    & > * {
        margin-right: 24px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const BackButton = styled(ChevronLeft)`
    color: ${colorConstants.white};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const DeleteButton = styled(Trash)`
    color: ${colorConstants.white};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const EditButton = styled(Pencil)`
    color: ${colorConstants.white};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const ShareButton = styled(ShareFill)`
    color: ${colorConstants.white};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const FavorButton = styled(Bookmark)`
    color: ${colorConstants.white};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const UnFavorButton = styled(BookmarkFill)`
    color: ${colorConstants.primaryDark};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.white};
    }
`;

export const TripHeaderSection = styled.div`
    padding: 16px;
`;

export const TripDescriptionSection = styled.div`
    padding: 16px 0;

    blockquote {
        border-left: 5px solid ${colorConstants.verySoftGrey};
        color: ${colorConstants.mediumGrey};
        font-family: 'Hoefler Text', 'Georgia', serif;
        font-style: italic;
        margin: 16px 0;
        padding: 10px 20px;
    }

    code {
        background-color: ${colorConstants.verySoftGreyVersion2};
        font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
        font-size: 12px;
        padding: 2px;
    }
`;

export const CreatorProfileSection = styled.div`
    margin-bottom: 8px;
    padding: 0 16px;

    display: flex;
    align-items: center;

    :hover {
        cursor: pointer;
    }
`;

export const CreatorName = styled.div`
    margin-left: 8px;

    font-weight: 500;
    font-size: 14px;

    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

export const SectionBody = styled.div`
    padding: 0 ${({ extrapadding }) => extrapadding ? '32px' : '16px'};
    font-weight: 400;
    font-size: 14px;

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: none;

    ${({ spacer }) => spacer && `
        & > * {
            margin-bottom: 16px;
        }

        & > *:last-child {
            margin-bottom: 0px;
        }
    `}

    ${({ bottompadding }) => bottompadding && `
        padding-bottom: 24px;
    `}
`;

export const BodyTitle = styled.div`
    ${({ margintop }) => margintop && `
        margin-top: ${margintop};
    `};
    ${({ marginbot }) => marginbot && `
        margin-bottom: ${marginbot};
    `};
    font-weight: 600;
    font-size: 15px;
    color: ${colorConstants.black};
`;

export const BodyList = styled.div`
    padding: 0;
    margin-bottom: 0;

    display: ${({ normal }) => normal ? 'block' : 'flex'};
    flex-wrap: wrap;
    gap: 8px;
`;

export const BodyListItem = styled.div`
    padding: ${({ noback }) => noback ? '4px' : '8px'} 8px;
    border-radius: 4px;

    line-height: 1;
    color: ${colorConstants.black};
    background: ${({ noback }) => noback ? 'none' : colorConstants.verySoftGreyVersion2};

    :before {
        color: ${colorConstants.primaryDark};
        margin-right: 8px;

        content: "✓";

        ${({ paid }) => paid && `
            content: "$";
        `};

        ${({ bullet }) => bullet && `
            content: "●";
        `};

        ${({ hideicon }) => hideicon && `
            content: '';
            margin-right: 0px;
        `};
    }
`;

export const GoogleMapSection = styled.div`
    margin-top: 32px;

    font-weight: 500;
    font-size: 13px;
`;

export const GoogleMapImage = styled.img`
    width: 100%;
    height: 180px;
    border-radius: 4px;
    object-fit: cover;

    :hover {
        cursor: pointer;
    }
`;

export const PartnerAddress = styled.div`
    margin-top: 8px;
    color: ${colorConstants.darkGrey};
`;

export const GoogleMapsButton = styled.div`
    display: inline-block;
    margin-top: 8px;
    
    color: ${colorConstants.primary};

    :hover {
        cursor: pointer;
        color: ${colorConstants.secondary};
    }
`;

export const NoMealsIcon = styled(SlashCircle)`
    color: ${colorConstants.primaryDark};
    margin-right: 8px;
`;

export const CheckInOutIcon = styled(Clock)`
    color: ${colorConstants.primaryDark};
    margin-right: 8px;
`;

export const SectionSubTitle = styled.div`
    padding: 16px;
    margin-top: 16px;

    font-weight: 700;
    font-size: 20px;
`;

export const TripAffiliateButton = styled(Button)`
    width: 100%;

    @media ${deviceConstants.tablet} {
        border-radius: 2px;
    }
`;

export const PartnerSection = styled.div`

`;

export const PartnerDescriptionSection = styled.div`
    padding-bottom: 8px;
`;

export const PartnerBookingSection = styled.div`
    padding-bottom: 8px;
`;

export const PartnerBookingSectionSpinner = styled.div`
  margin-top: 16px;
  text-align: center;
  color: ${colorConstants.primaryDark};
`;

export const PartnerBookingOptionsSection = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;

    & > * {
        margin-bottom: 6px;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }
`;

export const PartnerBookingRoomRateHeaderSection = styled.div`
    width: 100%;
    padding: 8px 0;

    line-height: 1.2;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const RoomRateHeaderSectionText = styled.div`
    font-weight: 500;
    font-size: ${({ subheader }) => subheader ? '10px' : '18px'};
    color: ${({ subheader }) => subheader ? colorConstants.softGrey : colorConstants.black};
`;

export const RoomRateHeaderSectionPrice = styled.div`
    font-weight: 500;
    font-size: 20px;
    text-align: end;
`;

export const RoomRateDiscountOffer = styled.div`
    font-weight: 500;
    font-size: 13px;
    color: ${colorConstants.warning};
    text-decoration: line-through;
`;

export const RoomRateHeaderSectionBorder = styled.div`
    width: 100%;
    height: 1px;
    background: ${colorConstants.gradientHorizontal};
`;

export const WarningText = styled.div`
    margin-top: 16px;
    font-weight: 500;
    font-size: 12px;
    color: ${colorConstants.warning};
`;

export const OfferExpirySection = styled.div`
    margin-bottom: 8px;
`;