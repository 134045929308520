import {
    Container,
    Photo,
    ContentColumn,
    Content,
    Name,
} from './FavouriteNFTListItem.styles';


const FavouriteNFTListItem = (props) => {
    const { data, handleView, ...rest } = props;

    return (
        <Container {...rest} >
            <Photo onClick={() => handleView(data)} src={data.image} />

            <ContentColumn>
                <Content onClick={() => handleView(data)}>
                    <Name onClick={() => handleView(data)}>{`${data.name} (#${data.tokenId})`}</Name>
                </Content>
            </ContentColumn>
        </Container>
    );
};

export default FavouriteNFTListItem;