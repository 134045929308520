import React, { useMemo } from 'react';
import {
    Container, Header, Title, Body, BodyText,
    DetailsContainer, Photo, DetailsColumn,
    PriceContainer,
    ButtonsContainer, CheckoutButton, GradientDivider,
} from './HotelRoomCheckRatePopup.styles';
import { RoomCancellationPolicyComponent } from './RoomCancellationPolicyComponent';
import { addCurrencySymbol } from '../../../utils';



export const HotelRoomCheckRatePopup = ({ show, onHide, data, handleAcceptChanges }) => {

    const formattedTotalPrice = useMemo(() => {
        if(!data) return null;
        let total = data.price.totalPerRoom ? data.price.total : data.price.total * data.rooms;
        return addCurrencySymbol(data.price.currencyCode, total / 100);
    }, [data]);

    const cancellationPolicies = useMemo(() => {
        if(data && data.cancellationPolicy && data.cancellationPolicy.components && data.cancellationPolicy.components.length > 0) {
            return (
                <BodyText small='true' nomargin='true' >
                    {data.cancellationPolicy.components.map((c, index) => <RoomCancellationPolicyComponent key={index} component={c} />)}
                </BodyText>
            );
        }

        return null;
    }, [data]);

    const roomRateNotes = useMemo(() => {
        if(!data || (!data.notes && !data.roomNotes)) return null;
        let output = [];
        if(data.notes) output.push(data.notes);
        if(data.roomNotes) output.push(data.roomNotes);
        if(output && output.length > 1) return (<BodyText small='true' nomargin='true' ><ol>{output.map((note, index) => <li key={index}>{note}</li>)}</ol></BodyText>);
        return (<BodyText small='true' nomargin='true' >{output}</BodyText>);
    }, [data]);

    if(!data) return null;
    
    return (
        <Container show={show} onHide={onHide}>
            <Header closeButton>
                <Title>Room Rate Update</Title>
            </Header>

            <Body>
                <BodyText>The room rate you requested was updated since your search started. Here's the latest data:</BodyText>
                
                <DetailsContainer>
                    { data.image && <Photo src={data.image.url} /> }

                    <DetailsColumn>
                        <BodyText bold='true' nomargin='true' >{data.hotelName}</BodyText>
                        <BodyText small='true' nomargin='true' >{data.start} - {data.end}</BodyText>
                        <BodyText small='true' nomargin='true' >{data.adults} adults, {data.rooms} rooms</BodyText>
                    </DetailsColumn>
                </DetailsContainer>

                <GradientDivider margintop='16px' marginbottom='0px' />

                <PriceContainer>
                    <div>{data.roomName}</div>
                    <div>{formattedTotalPrice}</div>
                </PriceContainer>

                <GradientDivider margintop='0px' marginbottom='8px' />
                

                {cancellationPolicies && <BodyText bold='true' margintop='true' >Cancellation Policy</BodyText>}
                {cancellationPolicies}

                {roomRateNotes && <BodyText bold='true' margintop='true' >Important Notes</BodyText>}
                {roomRateNotes}

                <ButtonsContainer>
                    <CheckoutButton onClick={handleAcceptChanges} >RESERVE</CheckoutButton>
                    <CheckoutButton secondary='true' onClick={onHide}>VIEW RATES</CheckoutButton>
                </ButtonsContainer>
            </Body>
      </Container>
    );
};