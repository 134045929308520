import React, { forwardRef, useState, useMemo, useCallback } from 'react';
import YouTube from 'react-youtube';
import { Container, Thumbnail, Fader, Image, Title, PlayIcon } from './YoutubeLink.styles';
import { colorConstants } from "../../constants";


export const YoutubeLink = forwardRef((props, ref) => {
    const { data, customizations, maxWidth, maxHeight, ...rest } = props;
    let { buttons, fonts } = customizations;

    // set component state
    const [isReady, setIsReady] = useState(false);
    const [isPlay, setIsPlay] = useState(false);
    const [player, setPlayer] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState(colorConstants.darkGrey);

    // set width & height
    const maxwidth = useMemo(() => (maxWidth || '100%'), [maxWidth]);
    const maxheight = useMemo(() => (maxHeight || '150px'), [maxHeight]);

    // defaults
    if(buttons.color === '') buttons.color = colorConstants.softBlack;
    if(buttons.fontColor === '') buttons.fontColor = colorConstants.white;

    // set button shape
    const buttonShape = buttons.shape === 'OVAL' ? '24px' : buttons.shape === 'ROUNDED' ? '8px' : '0px';

    // set button color
    const buttonColor = buttons.style === 'OUTLINE' ? 'transparent' : buttons.color;
    const buttonColorHover = buttons.style === 'OUTLINE' ? buttons.color : 'transparent';

    // set button font color
    const fontColor = buttons.style === 'OUTLINE' ? buttons.color : buttons.fontColor;
    const fontColorHover = buttons.style === 'OUTLINE' ? buttons.fontColor : buttons.color;

    // initialize video player
    const onPlayerReady = useCallback((event) => {
        // get player reference
        if(!player) setPlayer(event);
        // start video
        event.target.playVideo();
        // reset background color
        setBackgroundColor('none');
    }, [player]);

    // show/hide thumbnail based on player state changes
    const onStateChange = useCallback((event) => {
        // when video is unstarted (-1), paused (2) or ended (0) set isPlay to false. Find all events here => https://developers.google.com/youtube/iframe_api_reference#Events
        if(event.data === -1 || event.data === 2 || event.data === 0) setIsPlay(false);
        else setIsPlay(true);
    }, []);

    // set play click functionality
    const handlePlay = useCallback(() => {
        // load player
        if(!isReady) setIsReady(true);
        // hide thumbnail
        setIsPlay(true);
        // start video
        if(isReady && player) player.target.playVideo();
    }, [player, isReady]);

    // set youtube player options
    const videoPlayerOptions = {
        width: maxwidth,
        height: maxheight,
        playerVars: {
            autoplay: 1,
            frameborder: 0,
            allowfullscreen: 1
        },
    };


    return (
        <Container
            ref={ref}
            maxwidth={maxwidth}
            maxheight={maxheight}
            backgroundColor={backgroundColor}
            shape={buttonShape}
            borderColor={buttons.color}
            color={buttonColor}
            colorHover={buttonColorHover}
            fontColor={fontColor}
            fontColorHover={fontColorHover}
            fontType={fonts.type}
            {...rest}
        >
            { isReady &&
            <YouTube
                videoId={data.contentId}
                onReady={onPlayerReady}
                onStateChange={onStateChange}
                className='youtube-player'
                iframeClassName='youtube-player'
                opts={videoPlayerOptions}
                title={data.label} /> }

            { !isPlay &&
            <Thumbnail maxwidth={maxwidth} >
                <Image className='thumbnail' src={data.imageUrl} />
                <Fader className='fader' />
                <Title>{data.label}</Title>
                <PlayIcon className='button-play' onClick={() => handlePlay()} />
            </Thumbnail> }
        </Container>
    );
});