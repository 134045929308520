import React, { useState, useCallback } from 'react';
import {
    Container,
    Header,
    Subheader,
    Table,
    TableRow,
    TableRowText,
    TableRowColumn,
    SelectorGradientBorder, Selector,
    FeeNoticeText,
    UpgradeText,
} from './PotentialEarningsTable.styles';


export const PotentialEarningsTable = ({
    userAccountType,
    handleUpgrade,
}) => {

    const [selectedPlan, setSelectedPlan] = useState('Platinum');
    const [bunjeeFeePercentage, setBunjeeFeePercentage] = useState('1.05%');
    const [bunjeeFees, setBunjeeFees] = useState('€60');
    const [earnings, setEarnings] = useState('€124');
    const [showUpgrade, setShowUpgrade] = useState(userAccountType !== 'platinum');

    const handleSelectorChange = useCallback((e) => {
        let { name, value } = e.target;
        if(name !== 'plan-selector') return;

        setSelectedPlan(value);

        switch(value) {
            case 'Premium': {
                setBunjeeFeePercentage('5.25%');
                setBunjeeFees('€115');
                setEarnings('€69');
                setShowUpgrade(userAccountType === 'basic' || userAccountType === 'creator' || userAccountType === 'standard');
                break;
            }

            case 'Basic': {
                setBunjeeFeePercentage('7.35%');
                setBunjeeFees('€143');
                setEarnings('€41');
                setShowUpgrade(userAccountType === 'creator' || userAccountType === 'standard');
                break;
            }

            case 'Free': {
                setBunjeeFeePercentage('9.45%');
                setBunjeeFees('€170');
                setEarnings('€14');
                setShowUpgrade(false);
                break;
            }

            default: {
                setBunjeeFeePercentage('1.05%'); // Platinum Plan
                setBunjeeFees('€60');
                setEarnings('€124');
                setShowUpgrade(userAccountType !== 'platinum');
                break;
            }
        }
    }, [userAccountType]);

    return (
        <Container>
            <Header>Example Booking</Header>
            <Subheader>Potential earnings per booking sold with direct partnership</Subheader>

            <Table>
                <TableRow hasborder={true} >
                    <TableRowText>Guest pays</TableRowText>
                    <TableRowText number={true} >€1,500</TableRowText>
                </TableRow>

                <TableRow>
                    <TableRowText>Hotel or property cost</TableRowText>
                    <TableRowText number={true} >€1,316</TableRowText>
                </TableRow>

                <TableRow>
                    <TableRowText>Platform fees* & taxes</TableRowText>
                    <TableRowText number={true} >{bunjeeFees}</TableRowText>
                </TableRow>

                <TableRow hasborder={true} textRight={true} >
                    <TableRowColumn>
                        <SelectorGradientBorder>
                            <Selector name='plan-selector' value={selectedPlan} onChange={handleSelectorChange}>
                                <option value='Platinum'>Platinum</option>
                                <option value='Premium'>Premium</option>
                                <option value='Basic'>Basic</option>
                                <option value='Free'>Free</option>
                            </Selector>
                        </SelectorGradientBorder>
                        <Subheader>You earn</Subheader>
                    </TableRowColumn>
                    
                    <Subheader gradient={true} >{earnings}</Subheader>
                </TableRow>

                <TableRow normarginleft='true' >
                    <FeeNoticeText>* we only get {bunjeeFeePercentage} of a booking's total value before taxes and fees.</FeeNoticeText>
                </TableRow>

                { showUpgrade &&
                <TableRow normarginleft='true' >
                    <UpgradeText onClick={() => handleUpgrade()}>Upgrade Now</UpgradeText>
                </TableRow> }
            </Table>
        </Container>
    );
};