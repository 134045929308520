import React, { useMemo } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ReactHtmlParser from 'react-html-parser';

import {
    Container, ContentSection, ButtonsSection,
    PhotoSection,
    TopControls,
    TopControlFader,
    TopControlsColumn,
    BackButton,
    DeleteButton,
    EditButton,
    ShareButton,
    FavorButton,
    UnFavorButton,
    TripHeaderSection,
    TripDescriptionSection,
    CreatorProfileSection,
    CreatorName,
    SectionBody, BodyTitle,
    BodyList, BodyListItem,
    GoogleMapSection, GoogleMapImage, PartnerAddress, GoogleMapsButton,
    NoMealsIcon, CheckInOutIcon,
    SectionSubTitle,
    TripAffiliateButton,
    PartnerSection,
    PartnerDescriptionSection,
    PartnerBookingSection, PartnerBookingSectionSpinner,
    PartnerBookingOptionsSection,
    PartnerBookingRoomRateHeaderSection, RoomRateHeaderSectionText, RoomRateHeaderSectionPrice, RoomRateDiscountOffer, RoomRateHeaderSectionBorder,
    WarningText, OfferExpirySection,
} from './TripPage.styles';
import { CarouselPhoto } from '../../custom/Images';
import { HotelHeader } from '../../hotels/components';
import { Avatar } from '../../custom/Avatar';
import { DateSelector, NumberPicker } from '../../custom/Buttons';
import { CountdownTimer } from '../../custom/Time';
import GoogleReviewsList from './GoogleReviewsList';
import { MealSelector } from './MealSelector';
import { BedTypeSelector } from './BedTypeSelector';
import { RoomSelector } from './RoomSelector';
import { BookingReservationButton } from './BookingReservationButton';
import { BookingSearchButton } from './BookingSearchButton';
import { RoomOccupancy } from './RoomOccupancy';
import { RoomCancellationPolicy } from './RoomCancellationPolicy';
import { TripFooter } from './TripFooter';
import { useWindowSize, addCurrencySymbol } from '../../../utils';
import { colorConstants } from "../../../constants";


export const TripPage = (props) => {
    const {
        authenticated,
        isCreator,
        trip,
        isSearchExpired,
        handleSearchExpired,
        booking,
        hotelBooking,
        genericError,
        hotelDataLoading,
        occupancyOptionsToggle,
        availabilityCheckRequired, availabilityCheckButtonText,
        isFav,
        handleBackButton,
        handleOpenTerms,
        handleDelete,
        handleEdit,
        handleShare,
        handleFavor,
        handleUnFavor,
        handleViewCreatorProfile,
        handleOpenMap,
        handleDatePickerToggle,
        handleBookingOptions,
        handleAvailabilitySearch,
        handleShowChildrenAges,
        handleReserveBooking
    } = props;

    // detect screen size
    const [width] = useWindowSize();

    // get device type by screen size
    const device = useMemo(() => {
        let output = {
            isMobile: false,
            isTablet: false,
            isLaptop: false,
            isDesktop: false,
        };
        
        // for Mobile
        if(width < 991) {
            output.isMobile = true;
        } else if(width >= 991 && width < 1440) {
            // for tablet
            output.isTablet = true;
        } else if(width >= 1440 && width < 1600) {
            // for laptop
            output.isLaptop = true;
        } else {
            // for laptop
            output.isDesktop = true;
        }
        return output;

    }, [width]);

    const childrenAges = useMemo(() => {
        if(booking.children === 0 || booking.childrenAges.length === 0) return null;
        let output = '';
        if(booking.childrenAges.length === 1) {
            output = `My child is ${booking.childrenAges.toString()} years old.`;
        } else {
            let ages = [...booking.childrenAges];
            let lastAge = ages.pop();
            output = `My children are ${ages.join(', ')} and ${lastAge} years old.`;
        }
        return output;
    }, [booking]);

    const hasGoogleReviews = useMemo(() => {
        if(trip && trip.google && trip.google.reviews && trip.google.reviews.length > 0) return true;
        return false;
    }, [trip]);

    const hasPartner = useMemo(() => {
        if(trip && trip.partner && trip.partner.id && trip.partner.isReady) return true;
        return false;
    }, [trip]);

    const hasPartnerPhotos = useMemo(() => {
        if(hasPartner && trip.partner.photos && trip.partner.photos.length > 0) return true;
        return false;
    }, [hasPartner, trip]);

    const hasMorePhotos = useMemo(() => {
        if((trip && trip.google && trip.google.photos && trip.google.photos.length > 0) || hasPartnerPhotos) return true;
        return false;
    }, [hasPartnerPhotos, trip]);

    const hasAmenities = useMemo(() => {
        if(hasPartner && trip.partner.amenities && trip.partner.amenities.length > 0) return true;
        return false;
    }, [hasPartner, trip]);

    const hasRooms = useMemo(() => {
        if(hasPartner && trip.partner.rooms && trip.partner.rooms.length > 0) return true;
        return false;
    }, [hasPartner, trip]);

    const hasSelectedRoom = useMemo(() => (hasRooms && booking.selectedRoom && booking.selectedRoom.id), [hasRooms, booking.selectedRoom]);

    const hasSelectedRoomRate = useMemo(() => (hasSelectedRoom && booking.selectedRoomRate && booking.selectedRoomRate.id), [hasSelectedRoom, booking.selectedRoomRate]);

    const hasDiscountedRoomRate = useMemo(() => (hasSelectedRoomRate && booking.selectedRoomRate.price && booking.selectedRoomRate.price.total && booking.selectedRoomRate.price.offer && booking.selectedRoomRate.price.offer.name && booking.selectedRoomRate.price.offer.total), [hasSelectedRoomRate, booking.selectedRoomRate.price]);

    const hasRoomDescription = useMemo(() => (hasSelectedRoom && booking.selectedRoom.hasDescription), [hasSelectedRoom, booking.selectedRoom]);

    const hasRoomRateNotes = useMemo(() => (hasSelectedRoomRate && (booking.selectedRoom.notes || booking.selectedRoomRate.notes)), [hasSelectedRoomRate, booking.selectedRoom, booking.selectedRoomRate]);

    const hasRoomAmenities = useMemo(() => {
        if(hasSelectedRoom && booking.selectedRoom.amenities && booking.selectedRoom.amenities.length > 0) return true;
        return false;
    }, [hasSelectedRoom, booking]);

    const roomStayInfo = useMemo(() => {
        if(!hasSelectedRoom || !booking.selectedRoom.stayInfo) return null;

        return booking.selectedRoom.stayInfo.map((stay, index) => (
            <div key={index} style={{ marginBottom: '16px' }} >
                <BodyTitle marginbot='8px' >{stay.description}</BodyTitle>
                <BodyList normal='true' >{stay.components.map((c, cIndex) => (<BodyListItem key={cIndex} noback='true' bullet='true' >{c.details}</BodyListItem>))}</BodyList>
            </div>
        ));
    }, [hasSelectedRoom, booking.selectedRoom]);

    const mealsListItems = useMemo(() => {
        if(hasSelectedRoomRate && booking.selectedRoomRate.meal) {
            if(booking.selectedRoomRate.meal.components && booking.selectedRoomRate.meal.components.length > 0) return booking.selectedRoomRate.meal.components.map(a => <BodyListItem key={a.type} >{a.formatted}</BodyListItem>);
            else if(booking.selectedRoomRate.meal.type === 'noMeal') return <BodyListItem hideicon='true' key={booking.selectedRoomRate.meal.type} ><NoMealsIcon />Meals not included</BodyListItem>;
        }

        return null;
    }, [hasSelectedRoomRate, booking.selectedRoomRate]);

    const roomRateNotes = useMemo(() => {
        if(!hasRoomRateNotes) return null;
        let output = [];
        if(booking.selectedRoomRate.notes) output.push(booking.selectedRoomRate.notes);
        if(booking.selectedRoom.notes) output.push(booking.selectedRoom.notes);
        if(output && output.length > 1) return (<ol>{output.map((note, index) => <li key={index}>{note}</li>)}</ol>);
        return output;
    }, [hasRoomRateNotes, booking.selectedRoom, booking.selectedRoomRate]);

    const formattedRoomRatePrice = useMemo(() => {
        if(!hasSelectedRoomRate) return null;
        let price = booking.selectedRoomRate.price.totalPerRoom || booking.selectedRoomRate.price.total;
        if(booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice) price = price + booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice; // FEATURE: re-calculate local taxes whem multi-room booking enabled
        return addCurrencySymbol(booking.currency.code, price / 100);
    },[hasSelectedRoomRate, booking.selectedRoomRate, booking.currency]);

    const formattedRoomRatePriceBeforeDiscount = useMemo(() => {
        if(!hasDiscountedRoomRate) return null;
        let price = booking.selectedRoomRate.price.offer.totalPerRoom;
        if(booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice) price = price + booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice;
        return addCurrencySymbol(booking.currency.code, price / 100);
    },[hasDiscountedRoomRate, booking.selectedRoomRate, booking.currency]);

    const formattedTotalReservationPrice = useMemo(() => {
        if(!hasSelectedRoomRate) return null;
        let price = booking.selectedRoomRate.price.totalPerRoom ? booking.selectedRoomRate.price.total : (booking.selectedRoomRate.price.total * booking.rooms);
        if(booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice) price = price + booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice;
        return addCurrencySymbol(booking.currency.code, price / 100);
    },[hasSelectedRoomRate, booking.selectedRoomRate, booking.currency, booking.rooms]);

    // const formattedTotalReservationPriceBeforeDiscount = useMemo(() => {
    //     if(!hasDiscountedRoomRate) return null;
    //     return addCurrencySymbol(booking.currency.code, booking.selectedRoomRate.price.offer.total / 100);
    // },[hasDiscountedRoomRate, booking.selectedRoomRate, booking.currency]);

    const hasCustomLink = useMemo(() => {
        if((!hasPartner || !trip.isBookable) && trip.affiliate && trip.affiliate.label && trip.affiliate.url) return true;
        return false;
    }, [hasPartner, trip]);

    const hasGoogleSearchLink = useMemo(() => {
        if((!hasPartner || !trip.isBookable) && !hasCustomLink && trip.name) return true;
        return false;
    }, [hasPartner, hasCustomLink, trip]);

    const showBottomPadding = useMemo(() => ((hasPartner && !hasSelectedRoom) || hasSelectedRoomRate || hasCustomLink || hasGoogleSearchLink), [hasPartner, hasSelectedRoom, hasCustomLink, hasGoogleSearchLink, hasSelectedRoomRate]);

    const error = useMemo(() => {
        if(hotelBooking && hotelBooking.config && hotelBooking.config.error) return hotelBooking.config.error;
        return genericError;
    }, [hotelBooking, genericError]);

    const hasNoAvailabilityForSelectedDates = useMemo(() => {
        if(!hasRooms && error) return true;
        if(hasSelectedRoom && (!booking.selectedRoomRate || !booking.selectedRoomRate.id)) return true;
        return false;
    }, [error, hasRooms, hasSelectedRoom, booking.selectedRoomRate]);

    const associateNoticeText = useMemo(() => {
        if(!trip || !trip.creator) return '';
        return `This booking is offered by Bunjee and ${trip.creator.displayName || ('@' + trip.creator.username)} earns when you make a reservation.`;
    }, [trip]);

    const customLinkButton = useMemo(() => {
        let url = null;
        let text = '';

        if(hasCustomLink) {
            url = trip.affiliate.url;
            text = trip.affiliate.label;
        } else if(hasGoogleSearchLink) {
            url = 'https://www.google.com/search?q=' + trip.name;
            text = 'Find out more';
        }

        if(!url) return null;

        return (
            <a href={url} target="_blank" rel="noreferrer">
                <TripAffiliateButton gradient={true}>{text}</TripAffiliateButton>
            </a>
        );
    }, [hasCustomLink, hasGoogleSearchLink, trip]);

    const pageFooter = useMemo(() => (hasPartner && !hasSelectedRoom) ? <TripFooter handleOpenTerms={handleOpenTerms} /> : null, [hasPartner, hasSelectedRoom, handleOpenTerms]);

    return (
        <Container>
            <ContentSection bottommargin={showBottomPadding ? 'true' : ''} >
                <PhotoSection>
                    <CarouselPhoto isFirebase={true} height={'325px'} controls={!device.isMobile} src={trip.tripPhotoUrls} name={trip.customUrl} />
                    <TopControls>
                        <TopControlsColumn align='flex-start' >
                            { authenticated && <BackButton onClick={handleBackButton} size={26} /> }
                        </TopControlsColumn>
                        <TopControlsColumn align='flex-end' >
                            { authenticated && isCreator && <DeleteButton onClick={handleDelete} /> }
                            { authenticated && isCreator && <EditButton onClick={handleEdit} /> }
                            <ShareButton onClick={handleShare} />
                            {!isFav && !isCreator && <FavorButton onClick={handleFavor} />}
                            {isFav && !isCreator && <UnFavorButton onClick={handleUnFavor} />}
                        </TopControlsColumn>
                        <TopControlFader />
                    </TopControls>
                </PhotoSection>

                { hasPartner &&
                <TripHeaderSection>
                    <HotelHeader
                        name={trip.name}
                        type={trip.partner.type}
                        stars={trip.partner.stars}
                        city={trip.city}
                        country={trip.country}
                        rating={trip.google && trip.google.rating}
                        reviewsCount={trip.google && trip.google.userRatingsTotal} />
                </TripHeaderSection> }

                <TripDescriptionSection>
                    <CreatorProfileSection onClick={() => handleViewCreatorProfile(trip.creator.username)} >
                        <Avatar imageSize={30} url={trip.creator.profilePhotoUrl} cursor='pointer' />
                        <CreatorName>{trip.creator.displayName || trip.creator.username}</CreatorName>
                    </CreatorProfileSection>

                    <SectionBody>{ReactHtmlParser(trip.description)}</SectionBody>
                </TripDescriptionSection>

                { hasPartner &&
                <PartnerSection>
                    <PartnerDescriptionSection>
                        { hasMorePhotos && <SectionSubTitle>More Photos</SectionSubTitle>}

                        { hasMorePhotos &&
                        <CarouselPhoto
                            height={'325px'}
                            controls={!device.isMobile}
                            src={(!trip.isBookable || !hasPartnerPhotos) ? trip.google.photos : trip.partner.photos}
                            lazyLoad={2}
                            name={trip.customUrl + '-more-photos'}
                        />}

                        <SectionSubTitle>About</SectionSubTitle>

                        <SectionBody>{trip.isBookable ? trip.partner.description : (trip.google.description || trip.partner.description)}</SectionBody>

                        { trip.google &&
                        <SectionBody>
                            <GoogleMapSection>
                                { trip.google.mapsImageUrl && <GoogleMapImage onClick={() => handleOpenMap()} src={trip.google.mapsImageUrl} /> }
                                { trip.google.formattedAddress && <PartnerAddress>{trip.google.formattedAddress}</PartnerAddress> }
                                <GoogleMapsButton onClick={() => handleOpenMap()} >View in a map&nbsp;&nbsp;&nbsp;{'>'}</GoogleMapsButton>
                            </GoogleMapSection>
                        </SectionBody> }

                        { hasGoogleReviews && <SectionSubTitle>Google Reviews</SectionSubTitle>}

                        { hasGoogleReviews && <GoogleReviewsList reviews={trip.google.reviews} /> }
                    </PartnerDescriptionSection>

                    { trip.isBookable && 
                    <PartnerBookingSection>
                        { hasAmenities && <SectionSubTitle>Amenities</SectionSubTitle> }
                        { hasAmenities &&
                        <SectionBody>
                            <BodyList>
                                {trip.partner.amenities.map(a => <BodyListItem key={a.code} paid={a.isPaid} >{a.label}</BodyListItem>)}
                            </BodyList>
                        </SectionBody> }

                        <SectionSubTitle>Policies & Conditions</SectionSubTitle>
                        <SectionBody>
                            <BodyList>
                                {trip.partner.checkInFrom &&
                                <BodyListItem hideicon='true' ><CheckInOutIcon />Check-in from {trip.partner.checkInFrom}</BodyListItem>}

                                {trip.partner.checkOutTo &&
                                <BodyListItem hideicon='true' ><CheckInOutIcon />Check-out until {trip.partner.checkOutTo}</BodyListItem>}

                                {trip.partner.policiesInfo &&
                                <BodyListItem>{trip.partner.policiesInfo}</BodyListItem>}
                            </BodyList>
                        </SectionBody>

                        <SectionSubTitle>Availability</SectionSubTitle>
                        <SectionBody extrapadding='true' >
                            <DateSelector
                                onClick={handleDatePickerToggle}
                                dates={booking.dates} />

                            { occupancyOptionsToggle &&
                            <PartnerBookingOptionsSection>
                                <NumberPicker
                                    id='adults'
                                    title='Adults'
                                    subtitle='Ages 13 or above'
                                    min={1}
                                    max={booking.adultsMax}
                                    onChange={handleBookingOptions} />

                                <NumberPicker
                                    id='children'
                                    title='Children'
                                    subtitle='Ages 2–12'
                                    value={booking.children}
                                    max={booking.childrenMax}
                                    onChange={handleBookingOptions}
                                    footerText={childrenAges}
                                    footerButton={() => handleShowChildrenAges(true)}
                                    footerButtonText='Change' />

                                <NumberPicker
                                    id='infants'
                                    title='Infants'
                                    subtitle='Under 2'
                                    max={5}
                                    onChange={handleBookingOptions} />
                                
                                {/* <NumberPicker // FEATURE: enable for multiroom booking feature
                                    id='rooms'
                                    title='Rooms'
                                    min={1}
                                    max={booking.roomsMax}
                                    onChange={handleBookingOptions} /> */}
                            </PartnerBookingOptionsSection> }

                            { occupancyOptionsToggle &&
                            <BookingSearchButton
                                label={availabilityCheckButtonText}
                                onClick={handleAvailabilitySearch}
                                highlight={availabilityCheckRequired || !hasRooms} /> }

                            { hasNoAvailabilityForSelectedDates &&
                            <WarningText>This property has no availability on our site for these dates. Try again with different dates.</WarningText> }

                            { hotelDataLoading &&
                            <PartnerBookingSectionSpinner>
                                <Spinner animation="border" />
                            </PartnerBookingSectionSpinner> }
                        </SectionBody>

                        { hasRooms && <SectionSubTitle>Room Info</SectionSubTitle> }

                        { hasRooms &&
                        <SectionBody spacer='true' bottompadding='true'>
                            <MealSelector
                                id='selectedMeal'
                                title='Meal:'
                                options={booking.mealOptions}
                                selected={booking.selectedMeal}
                                onChange={handleBookingOptions} />
                            
                            <BedTypeSelector
                                title='Bed (if available):'
                                titleValue={booking.doubleBedType ? '1 Double Bed' : '2 Single Beds'}
                                doubleBedType={booking.doubleBedType}
                                onChange={handleBookingOptions} />
                                
                            <RoomSelector
                                title='Room'
                                options={booking.roomOptions}
                                onChange={handleBookingOptions} />
                        </SectionBody> }

                        { hasSelectedRoom && booking.selectedRoom.photos &&
                        <CarouselPhoto
                                height={'325px'}
                                controls={!device.isMobile}
                                src={booking.selectedRoom.photos}
                                lazyLoad={2}
                                name={booking.selectedRoom.name + 'room-photos'} /> }
                        
                        { hasSelectedRoom && <SectionSubTitle>{booking.selectedRoom.name}</SectionSubTitle> }

                        { hasSelectedRoomRate &&
                        <SectionBody>
                            <RoomRateHeaderSectionBorder />
                            <PartnerBookingRoomRateHeaderSection>
                                <div>
                                    <RoomRateHeaderSectionText>Price for {booking.dates.totalDays} night{(booking.dates.totalDays > 1) ? 's' : ''}</RoomRateHeaderSectionText>
                                    <RoomRateHeaderSectionText subheader='true'>Includes taxes and charges</RoomRateHeaderSectionText>
                                </div>
                                <RoomRateHeaderSectionPrice>
                                    { hasDiscountedRoomRate &&
                                    <RoomRateDiscountOffer>
                                        {formattedRoomRatePriceBeforeDiscount}
                                    </RoomRateDiscountOffer> }
                                    {formattedRoomRatePrice}
                                </RoomRateHeaderSectionPrice>
                            </PartnerBookingRoomRateHeaderSection>
                            <RoomRateHeaderSectionBorder />
                        </SectionBody> }

                        { hasRoomDescription && <SectionSubTitle>Description</SectionSubTitle> }
                        { hasRoomDescription &&
                        <SectionBody>
                            {booking.selectedRoom.description}
                        </SectionBody> }

                        { hasSelectedRoomRate && <SectionSubTitle>Room Occupancy</SectionSubTitle> }
                        { hasSelectedRoomRate &&
                        <SectionBody>
                            <RoomOccupancy
                                id='occupancy-selector'
                                options={booking.selectedRoom.options.occupancy}
                                adults={booking.adults}
                                children={booking.children}
                                maxRoomCapacity={booking.selectedRoom.maxOccupancy}
                                roomsAvailable={booking.selectedRoomRate.roomsOnSale}
                                onChange={handleBookingOptions} />
                        </SectionBody> }

                        { roomStayInfo &&
                        <SectionBody>
                            {roomStayInfo}
                        </SectionBody> }

                        { hasRoomAmenities && <SectionSubTitle>Room Amenities</SectionSubTitle> }
                        { hasRoomAmenities &&
                        <SectionBody>
                            <BodyList>
                                {booking.selectedRoom.amenities.map(a => <BodyListItem key={a.code} paid={a.isPaid} >{a.label}</BodyListItem>)}
                            </BodyList>
                        </SectionBody> }

                        { hasSelectedRoomRate && <SectionSubTitle>Meals Included</SectionSubTitle> }
                        { hasSelectedRoomRate &&
                        <SectionBody>
                            <BodyList>
                                {mealsListItems}
                            </BodyList>
                        </SectionBody> }

                        { hasSelectedRoomRate && <SectionSubTitle>Cancellation Policy</SectionSubTitle> }
                        { hasSelectedRoomRate &&
                        <SectionBody>
                            <RoomCancellationPolicy
                                id='cancellation-policy-selector'
                                enabled={booking.hasCancellationPoliciesOptions}
                                options={booking.selectedRoom.options.cancellation}
                                selected={booking.selectedRoomRate.cancellationPolicy}
                                onChange={handleBookingOptions}
                                handleOpenTerms={handleOpenTerms} />
                        </SectionBody> }

                        { hasRoomRateNotes && <SectionSubTitle>Important Notes</SectionSubTitle> }
                        { hasRoomRateNotes &&
                        <SectionBody>
                            {roomRateNotes}
                        </SectionBody>}
                    </PartnerBookingSection> }
                </PartnerSection> }
            </ContentSection>

            { hasSelectedRoomRate &&
            <OfferExpirySection>
                <SectionBody>
                    <CountdownTimer
                        targetDate={booking.selectedRoomRate.createdAt}
                        minutes={booking.selectedRoomRate.ttl}
                        handleExpired={handleSearchExpired}
                        label='Offer expires in '
                        color={colorConstants.secondaryDark}
                        warnOnComplete={true}
                        size={14} />
                </SectionBody>
            </OfferExpirySection> }

            <ButtonsSection>
                {customLinkButton}

                { hasSelectedRoomRate &&
                <BookingReservationButton
                    enable={!availabilityCheckRequired && !isSearchExpired}
                    error={error}
                    onClick={handleReserveBooking}
                    amount={formattedTotalReservationPrice}
                    amountText='Includes taxes & charges'
                    noticeText={associateNoticeText}
                    buttonText='RESERVE' /> }
            </ButtonsSection>

            {pageFooter}
        </Container>
    );
};