import React, { useRef, useMemo } from 'react';

import {
    Container,
    StyledForm,
    StyledFormGroup,
    FormInputTitle,
    StyledFormText,
    StyledButton,
    UploadPhotosContainer,
    FileUploader
} from './AddProduct.styles';
import {
    GradientFormSelector,
    GradientRichEditor,
    GradientFormInput
} from '../../custom/FormInput';
import { UploadPhoto } from '../../custom/Images';
import { SimpleLinkInput } from '../../custom/FormInput';
import { contentAddProductPage } from '../../../constants';


export const AddProduct = (props) => {
    const {
        product,
        productTypes,
        submitted,
        creating,
        hasPhotos,
        handleSubmit,
        handleChange,
        handleDescriptionLengthChange,
        productDescriptionLength,
        alert,
        handleUploadPhoto1,
        handleUploadPhoto2
    } = props;

    // open file browser on profile click
    const inputPhoto1 = useRef(null);
    const openFileUploader1 = () => {
      inputPhoto1.current.click();
    };
    const inputPhoto2 = useRef(null);
    const openFileUploader2 = () => {
      inputPhoto2.current.click();
    };

    // get product name placeholder based on selected product type
    const productNamePlaceholder = useMemo(() => {
        return product.type === productTypes[0] ? contentAddProductPage.placeholders.physicalProductNameSearch : contentAddProductPage.placeholders.digitalProductNameSearch
    }, [product.type, productTypes]);

    // validate if has product name
    const hasProductName = product.name && product.name.length > 3;

    
    return (
        <Container>
            <StyledForm id="add-product">
                <StyledFormGroup>
                    <FormInputTitle>Product Type</FormInputTitle>
                    <GradientFormSelector
                        options={productTypes}
                        id="selector"
                        name="type"
                        selected={productTypes[productTypes.findIndex(item => item === product.type)]}
                        onChange={handleChange} />
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>Product Name</FormInputTitle>
                    <GradientFormInput placeholder={productNamePlaceholder} type="text" name="name" value={product.name} onChange={handleChange} />
                    { submitted && !hasProductName && <StyledFormText>Product name is required</StyledFormText> }
                    { alert && alert.message && !creating && <StyledFormText>{alert.message}</StyledFormText> }
                </StyledFormGroup>

                { hasProductName &&
                <StyledFormGroup>
                  <FormInputTitle>Product Description</FormInputTitle>
                  <GradientRichEditor
                    placeholder={contentAddProductPage.placeholders.description}
                    value={product.description}
                    onChange={(value) => handleChange({ target: { name: 'description', value } })}
                    maxLength={10000}
                    getCurrentTextLength={handleDescriptionLengthChange} />
                  { submitted && (!product.description || productDescriptionLength < 50) && <StyledFormText>Write at least 50 characters description</StyledFormText> }
                </StyledFormGroup> }

                { hasProductName &&
                <StyledFormGroup>
                    <FormInputTitle>Upload Photos</FormInputTitle>

                    <UploadPhotosContainer>
                        <UploadPhoto showEdit={true} url={product.imageUrls[0]} onClick={openFileUploader1} />
                        <FileUploader type='file' accept="image/*" id='photo1' ref={inputPhoto1} onChange={handleUploadPhoto1} />
                        <UploadPhoto showEdit={true} url={product.imageUrls[1]} onClick={openFileUploader2} />
                        <FileUploader type='file' accept="image/*" id='photo2' ref={inputPhoto2} onChange={handleUploadPhoto2} />
                    </UploadPhotosContainer>
                    { submitted && !hasPhotos && <StyledFormText>You should add at least one photo</StyledFormText> }
                </StyledFormGroup> }

                { hasProductName && !product.partnerId &&
                <StyledFormGroup>
                    <FormInputTitle>Add Custom Link</FormInputTitle>
                    <SimpleLinkInput
                        data={product.button}
                        hasBorder={true}
                        handleLabelChange={handleChange}
                        handleUrlChange={handleChange} />
                </StyledFormGroup> }
            </StyledForm>

            <StyledButton
                static={hasProductName} // set button position to static
                gradient={true}
                type="submit"
                disabled={!hasProductName}
                onClick={handleSubmit}
            >
                Add Product
            </StyledButton>
        </Container>
    );
};