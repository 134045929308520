import styled from "styled-components";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormText from "react-bootstrap/FormText";
import FormLabel from "react-bootstrap/FormLabel";
import { Button } from '../../custom/Buttons';

import { colorConstants } from "../../../constants";



export const StyledForm = styled(Form)`
  font-size: 12px;
  padding-bottom: 32px;
`;

export const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

export const StyledFormText = styled(FormText)`
  color: ${colorConstants.warning};
`;

export const StyledFormLabel = styled(FormLabel)`
    font-weight: 600;
    color: ${colorConstants.mediumGrey};
`;

export const RecaptchaContainer = styled.div`
  margin-top: 24px;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
`;

export const SpinnerContainer = styled.div`
  margin-top: 16px;
  text-align: center;
`;

export const GDPR = styled.div`
  color: ${colorConstants.darkGrey};
  font-size: 11px;
  margin-top: 24px;
`;

export const AlertWarning = styled.div`
  font-weight: 600;
  color: ${colorConstants.warning};
  text-align: center;
  padding: 16px 16px 0 16px;
`;

export const AlertSuccess = styled(AlertWarning)`
  color: green !important;
`;