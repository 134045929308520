import Feature from './Feature';
import { GradientFormSelector } from '../../custom/FormInput';
import {
    Container,
    Title
} from './FeaturesList.styles';


const FeaturesList = (props) => {
    let { basic, premium, platinum, options, handleChange, handleNotification } = props;

    // get access level
    const hasBasicAccess = basic || premium || platinum;
    // const hasPremiumAccess = premium || platinum;
    // const hasPlatinumAccess = platinum;

    // check if has default links view options
    const showDefaultLinksViewSection = options.placeholders && options.placeholders.length > 0;

    return (
        <Container>
            <Feature label='Links' header={true} className='section-header' />
            <Feature name='showStandardLinks' label='Standard' enabled={options.showStandardLinks} onToggle={handleChange} />
            {/* <Feature name='showBlogLinks' label='Blog' enabled={options.showBlogLinks} onToggle={handleChange} disabled={true} notification='Coming soon' /> */}
            <Feature name='showYoutubeLinks' label='YouTube' enabled={options.showYoutubeLinks} onToggle={handleChange} />
            <Feature name='showYoutubeshortLinks' label='YouTube Shorts' enabled={options.showYoutubeshortLinks} onToggle={handleChange} />
            {/* <Feature name='showInstagramreelLinks' label='Instagram Reels' enabled={options.showInstagramreelLinks} onToggle={handleChange} /> */}
            {/* <Feature name='showTiktokLinks' label='TikTok' enabled={options.showTiktokLinks} onToggle={handleChange} /> */}
            <Feature name='showSpotifyLinks' label='Spotify' enabled={options.showSpotifyLinks} onToggle={handleChange} />
            {/* <Feature name='showGithubLinks' label='Github' enabled={options.showGithubLinks} onToggle={handleChange} /> */}
            
            <Feature label='Products' header={true} className='section-header' />
            <Feature name='showPhysicalProductLinks' label='Product' enabled={options.showPhysicalProductLinks} onToggle={handleChange} />
            {/* <Feature name='showDigitalProductLinks' label='Digital Product' enabled={options.showDigitalProductLinks} onToggle={handleChange} /> */}
            {/* <Feature name='showNFTLinks' label='NFT' enabled={options.showNFTLinks} onToggle={handleChange} /> */}

            {/* <Feature label='Travel' header={true} className='section-header' /> */}
            {/* <Feature name='showHotelLinks' label='Stays' enabled={options.showHotelLinks} onToggle={handleChange} /> */}
            {/* <Feature name='showTravelAgencyLinks' label='Experiences' enabled={options.showTravelAgencyLinks} onToggle={handleChange} /> */}

            <Feature
                header={true}
                className='section-header'
                name='showOverviewPage'
                label='Homepage'
                enabled={options.showOverviewPage}
                onToggle={handleChange}
                disabled={!hasBasicAccess}
                notification={!hasBasicAccess ? 'Upgrade' : null}
                handleNotification={handleNotification} />

            {showDefaultLinksViewSection && <Title>Set Default Page Section</Title> }
            {showDefaultLinksViewSection &&
            <GradientFormSelector
                options={options.placeholders}
                id="defaultlinksview-selector"
                selected={options.defaultLinksView}
                onChange={handleChange} /> }
        </Container>
    );
};

export default FeaturesList;