import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormText from 'react-bootstrap/FormText';
import FormLabel from "react-bootstrap/FormLabel";
import { Link } from "react-router-dom";
import { deviceConstants, colorConstants, shadowConstants } from "../../../constants";
import { Button } from '../../custom/Buttons';


export const Container = styled.div`
    font-size: 12px;

    @media ${deviceConstants.tablet} {
        display: flex;
        justify-content: top;

        height: 100vh;
        margin-top: 32px;
        padding: 32px;
        border-radius: 8px;
        box-shadow: ${shadowConstants.adminDesktop};
        background: ${colorConstants.white};
    }
`;

export const SectionTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
    color: ${colorConstants.darkGrey};
`;

export const StyledForm = styled(Form)`
    width: 100%;
`;

export const StyledFormGroup = styled(FormGroup)`
    margin-top: 16px;
`;

export const FormInputTitle = styled.div`
    font-weight: 600;
    color: ${colorConstants.mediumGrey};
    margin-bottom: 1px;
`;

export const FormInputText = styled.div`
    font-weight: 500;
    color: ${colorConstants.softGrey};
`;

export const StyledFormText = styled(FormText)`
  color: ${colorConstants.warning};
`;

export const StyledFormLabel = styled(FormLabel)`
    font-weight: 600;
    color: ${colorConstants.mediumGrey};
`;

export const AlertWarning = styled.div`
  font-weight: 600;
  color: ${colorConstants.warning};
  text-align: center;
  padding: 16px 16px 0 16px;
`;

export const AlertSuccess = styled(AlertWarning)`
  color: green !important;
`;

export const ButtonSection = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    @media ${deviceConstants.tablet} {
        width: 25%;
        border-radius: 2px;
        
        position: absolute;
        bottom: 20px;
        left: auto;
        right: auto;
    }

    @media ${deviceConstants.desktop} {
        width: 20%;
    }
`;

export const StyledButton = styled(Button)`
    width: 100%;
`;

export const TermsText = styled.div`
    margin-bottom: 16px;
    padding: 0 32px;

    color: ${colorConstants.darkGrey};
    font-size: 9px;
    margin-top: 24px;

    @media ${deviceConstants.tablet} {
        margin-bottom: 8px;
        padding: 0;
    }
`;

export const TermsLink = styled(Link)`
    color: ${colorConstants.darkGrey};

    :hover {
        color: ${colorConstants.primaryDark};
    }
`;