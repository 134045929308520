import React, { useEffect, useState } from "react";


function Icon({ url, type, platform, size, circle, color, customcolor, ...other }) {
    // const ImportedIconRef = useRef();
    const [iconModule, setIconModule] = useState(null);

    let name = platform || type;
    if(circle) name = 'circle-' + name;
    if(color) name = name + '-color';

    useEffect(() => {
        // flag used to subscribe to async task
        let isSubscribed = true;

        // var importIcon = async () => {
        //     try {
        //         ImportedIconRef.current = (await import(/* webpackMode: "eager" */ `!!@svgr/webpack?-svgo,+titleProp,+ref!./${type}/${platform ? platform + '/' : ''}${name}.svg`)).default;
        //     } catch(err) {
        //         console.error(`Icon with name: ${name} not found!`);
        //     }
        // };
        // importIcon();

        import(/* webpackMode: "eager" */ `!!@svgr/webpack?-svgo,+titleProp,+ref!./${type}/${platform ? platform + '/' : ''}${name}.svg`)
            .then((module) => {
                setIconModule(isSubscribed ? module : null);
            })
            .catch((error) => {
                console.error(`Icon with name: ${name} not found!`);
            });

        // cancel asyn task subsciption on component unmount
        return () => (isSubscribed = false)
    }, [type, platform, name]);

    if (!iconModule) return null;
    // if(!ImportedIconRef.current) return null;

    const ImportedIcon = iconModule.default;
    // const { current: ImportedIcon } = ImportedIconRef;
    return <ImportedIcon style={{ fill: customcolor, height: size + 'px', width: size + 'px' }} {...other} />;
}

export default Icon;