import {
    Container,
    TopSection,
    MiddleSection,
    BottomSection,
    Title,
    Data,
    Label,
    FavouritesIcon,
    RightIcon
} from './CategoryOverviewCard.styles';
import Icon from '../../../assets/icons/Icon';

import { colorConstants } from "../../../constants";


export const CategoryOverviewCard = (props) => {
    const {
        isStay, isExperience, isLink, isBlog, isGithub, isYouTube, isReel, isTikTok, isSpotify, isNFT, isPhysicalProduct, isFavourite,
        title, data, label, onClick
    } = props;

    return (
        <Container onClick={onClick} cursor={onClick ? 'pointer' : null} className='category-card' >
            <TopSection>
                <Title>{title}</Title>
            </TopSection>

            <MiddleSection>
                <Data>{data}</Data>
                <Label>{label}</Label>
            </MiddleSection>

            <BottomSection>
                { isStay && <Icon type='hotel' size={24} customcolor={colorConstants.black} /> }
                { isExperience && <Icon type='hiking' size={24} customcolor={colorConstants.black} /> }
                { isLink && <Icon type='link' size={24} customcolor={colorConstants.black} /> }
                { isBlog && <Icon type='open_book' size={24} customcolor={colorConstants.black} /> }
                { isGithub && <Icon type='social' platform='github' size={24} customcolor={colorConstants.black} /> }
                { isYouTube && <Icon type='social' platform='youtube' size={24} customcolor={colorConstants.black} /> }
                { isReel && <Icon type='social' platform='instagram' size={24} customcolor={colorConstants.black} /> }
                { isTikTok && <Icon type='social' platform='tiktok' size={24} customcolor={colorConstants.black} /> }
                { isSpotify && <Icon type='social' platform='spotify' size={24} customcolor={colorConstants.black} /> }
                { isNFT && <Icon type='crypto' platform='nft' size={24} customcolor={colorConstants.black} /> }
                { isPhysicalProduct && <Icon type='product' size={24} customcolor={colorConstants.black} /> }
                { isFavourite && <FavouritesIcon /> }
                <RightIcon />
            </BottomSection>
        </Container>
    );
};