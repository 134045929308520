import styled from 'styled-components';
import FormCheck from 'react-bootstrap/FormCheck';
import { deviceConstants, colorConstants, shadowConstants } from "../../../constants";


export const Container = styled.div`
    width: 100%;
    padding-bottom: 170px;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 12px;

    @media ${deviceConstants.tablet} {
        margin-top: 32px;
        padding: 32px;
        border-radius: 8px;
        box-shadow: ${shadowConstants.adminDesktop};
        background: ${colorConstants.white};
    }
`;

export const TopSection = styled.div`
    margin-bottom: 24px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const Header = styled.div`

`;

export const Name = styled.div`
    font-weight: 600;
    font-size: 22px;
`;

export const Collection = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: ${colorConstants.primaryDark};
`;

export const EnableToggle = styled(FormCheck)`

    input:hover {
        cursor: pointer;
    }
    
    input:checked {
        background: ${colorConstants.primaryDark};
        border-color: ${colorConstants.primaryDark};
        background-repeat: no-repeat;
    }

    input:focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }
`;

export const Image = styled.img`
    width: 100%;
    max-height: 325px;
    object-fit: cover;

    border-radius: 8px;
`;

export const Content = styled.div`
    width: 100%;
    padding: 32px 0;
    color: ${colorConstants.black};

    & > * {
        margin-bottom: 32px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;

export const Section = styled.div`
    padding: 16px;
    border: 1px solid ${colorConstants.verySoftGreyVersion2};
    border-radius: 2px;

    font-weight: 500;
    font-size: 14px;
`;

export const SectionTitle = styled.div`
    margin-bottom: 12px;
    padding-bottom: 4px;
    border-bottom: 1px solid ${colorConstants.verySoftGreyVersion2};
    font-weight: 600;
    font-size: 16px;
`;

export const SectionItem = styled.div`
    width: 100%;
    margin-bottom: 4px;
    color: ${colorConstants.darkGrey};

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SectionItemBold = styled.span`
    font-weight: 600;
    color: ${colorConstants.mediumGrey};
`;

export const LinksArea = styled.div`
    margin-top: 12px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > * {
        margin-right: 8px;
    }

    & > *:last-child {
        margin-right: 0;
    }
`;

export const Link = styled.a`
    text-decoration: none;

    :hover {
        color: ${colorConstants.black};
        cursor: pointer;
    }
`;

export const AttributesList = styled.div`
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    & > * {
        margin-bottom: 24px;
    }
`;

export const AttributeContainer = styled.div`
    width: ${props => props.maxwidth || '120px'};
    padding: 16px;
    border-radius: 8px;
    background: ${colorConstants.verySoftGreyVersion2};
    color: ${colorConstants.black};
    font-size: 14px;

    :hover {
        background: ${colorConstants.verySoftGrey};
    }
`;

export const AttributeType = styled.div`
    margin-bottom: 8px;
    font-weight: 500;
`;

export const AttributeValue = styled.div`
    font-weight: 600;
`;

export const Group = styled.div`
    width: 100%;
    margin-bottom: 32px;

    ${({ row }) => row && `
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    `}
`;

export const GroupInputTitle = styled.div`
    font-weight: 600;
    color: ${colorConstants.softGrey};
    margin-bottom: 1px;
`;

export const GroupInputText = styled.div`
    font-weight: 500;
    font-size: 12px;
`;