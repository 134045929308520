import React from 'react';
import styled from 'styled-components';
import Form from "react-bootstrap/Form";

import { colorConstants } from "../../../constants";


const Container = styled(Form.Check)`
  display: flex;
  align-items: center;
`;

const OptionButton = styled(Form.Check.Input)`
  margin: 0;
  padding: 0;
  margin-right: 16px;
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  :hover {
    cursor: pointer;
  }

  :checked {
    background-color: ${colorConstants.primaryDark};
    border-color: ${colorConstants.primaryDark};
  }
`;

const OptionText = styled(Form.Check.Label)`
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: ${({ textsize }) => textsize ? textsize : '14px'};
  overflow-wrap: anywhere;
`;


export const FormCheck = ({ type, id, text, value, fontSize, checkBoxSize, isActive, onChange }) => {

    return (
        <Container type={type || 'radio'}>
            <OptionButton size={checkBoxSize} value={value} name={id + '-option'} onChange={onChange} type={type || 'radio'} checked={isActive}/>
            <OptionText textsize={fontSize}>{text}</OptionText>
        </Container>
    );
};