import styled from 'styled-components';
import { PlusSquare } from 'react-bootstrap-icons';
import FormCheck from 'react-bootstrap/FormCheck';

import { colorConstants } from "../../constants";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${colorConstants.black};
`;

const Button = styled(PlusSquare)`
  :hover {
    cursor: pointer;
  }
`;

const StyledFormCheck = styled(FormCheck)`

    input:hover {
        cursor: pointer;
    }
    
    input:checked {
        background: ${colorConstants.primaryDark};
        border-color: ${colorConstants.primaryDark};
        background-repeat: no-repeat;
    }

    input:focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }
`;


const SettingsSectionTitleButton = (props) => {
    const { title, size, handleClick, handleToggle, enabled } = props;

    return (
        <Container>
            <Title>{title}</Title>
            { handleClick && size && <Button color={colorConstants.black} size={size} onClick={handleClick} />}
            { !enabled && handleToggle && <StyledFormCheck type="switch" name="section-enable" checked={enabled} onChange={(event) => handleToggle(event, title)} />}
        </Container>
    );
};

export default SettingsSectionTitleButton