import { publicProductsReducerConstants } from '../constants';

export function publicProducts(state = { products: [] }, action) {

  switch (action.type) {

    case publicProductsReducerConstants.GETALL_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicProductsReducerConstants.GETALL_SUCCESS:
        return {
            products: action.products
        };

    case publicProductsReducerConstants.GETALL_FAILURE:
        return {
            products: [],
            error: action.error
        };

    case publicProductsReducerConstants.GETBYSLUG_REQUEST:
        return {
            ...state,
            loading: true
        };
    
    case publicProductsReducerConstants.GETBYSLUG_SUCCESS:
        return {
            products: [action.product]
        };

    case publicProductsReducerConstants.GETBYSLUG_FAILURE: {
        let { loading, ...stateCopy } = state; // remove loading flag
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case publicProductsReducerConstants.FILTER_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicProductsReducerConstants.FILTER_SUCCESS:
        return {
            products: action.products
        };

    case publicProductsReducerConstants.FILTER_FAILURE: {
        let { loading, ...stateCopy } = state; // remove loading flag
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case publicProductsReducerConstants.RESET_ALL: {
        return { products: [] }
    }

    default:
        return state
  }
}