/**
 * Method to prepare NFT data before submission to server
 * 
 * @param {JSON Object} data The NFT data
 * @returns JSON Object with finalized NFT data for submission
 */
export function finalizeNFTData(data) {
    const { button, ...rest } = data;

    return {
        ...rest,
        btnLabel: button.label,
        btnUrl: button.url,
    };
}


/**
 * Method to get the Opensea link for an NFT
 * 
 * @param {String} contractAddress The contract address
 * @param {String} tokenId The token id
 * @returns String with the opensea link for the nft
 */
export function getOpenseaUrl(contractAddress, tokenId, chain) {
    switch(chain) {
        case 'Polygon':
            return `https://opensea.io/assets/matic/${contractAddress}/${tokenId}`;

        case 'Ethereum':
            return `https://opensea.io/assets/ethereum/${contractAddress}/${tokenId}`;

        default:
            return null;
    }
}


/**
 * Method to get the Coinbase link for an NFT
 * 
 * @param {String} contractAddress The contract address
 * @param {String} tokenId The token id
 * @returns String with the coinbase link for the nft
 */
export function getCoinbaseUrl(contractAddress, tokenId, chain) {
    switch(chain) {
        case 'Polygon':
            return null;

        case 'Ethereum':
            return `https://nft.coinbase.com/nft/ethereum/${contractAddress}/${tokenId}`;

        default:
            return null;
    }
}


/**
 * Method to obfuscate/shorten a crypto address
 * 
 * @param {String} address The crypto address
 * @returns A string with the obfuscated crypto address
 */
export const obfuscateAddress = (address) => {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4, address.length)}`;
};


// Method to get NFT contract address
export const getNFTContractUrl = (contractAddress, chain) => {
    switch(chain) {
        case 'Polygon':
            return `https://polygonscan.com/address/${contractAddress}`;

        case 'Ethereum':
            return `https://etherscan.io/address/${contractAddress}`;

        default:
            return null;
    }
};