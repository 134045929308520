// This reducer manages the state related to login (and logout) actions.
import { authReducerConstants } from '../constants';

let cookie = JSON.parse(localStorage.getItem('user'));
const initialState = cookie ? { loggedIn: true, cookie } : {};

export function auth(state = initialState, action) {

  switch (action.type) {

    case authReducerConstants.REGISTER_REQUEST:
      return { registering: true };

    case authReducerConstants.REGISTER_SUCCESS:
      return {};

    case authReducerConstants.REGISTER_FAILURE:
      return {};

    case authReducerConstants.LOGIN_REQUEST:
      return {
        loggingIn: true
      };

    case authReducerConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        cookie: action.cookie
      };

    case authReducerConstants.LOGIN_FAILURE:
      return {};
    
    case authReducerConstants.LOGIN_WITH_SOCIAL_REQUEST:
      return { loggingIn: true };
    
    case authReducerConstants.LOGIN_WITH_SOCIAL_SUCCESS:
      return {};

    case authReducerConstants.LOGOUT:
      return {};
    
    case authReducerConstants.UPDATEPASSWORD_REQUEST:
        return {
          ...state,
          updatingPassword: true
        };

    case authReducerConstants.UPDATEPASSWORD_SUCCESS:
        return {};

    case authReducerConstants.UPDATEPASSWORD_FAILURE: {
      let { updatingPassword, ...stateCopy } = state; // remove updatingPassword flag and add error
      return { ...stateCopy, updatePasswordError: action.error };
    }

    case authReducerConstants.CONFIRM_EMAIL_REQUEST:
        return {
          ...state,
          confirmingEmail: true
        };

    case authReducerConstants.CONFIRM_EMAIL_SUCCESS:
      let { confirmingEmail, ...stateCopy } = state; // remove confirmingEmail flag
        return stateCopy;

    case authReducerConstants.CONFIRM_EMAIL_FAILURE: {
      let { confirmingEmail, ...stateCopy } = state; // remove confirmingEmail flag and add error
      return { ...stateCopy, confirmEmailError: action.error };
    }

    case authReducerConstants.RESET_PASSWORD_REQUEST_REQUEST: {
      return {};
    }

    case authReducerConstants.RESET_PASSWORD_REQUEST_SUCCESS: {
      return { resetPasswordRequested: true };
    }

    case authReducerConstants.RESET_PASSWORD_REQUEST_FAILURE: {
      return { resetPasswordRequestError: action.error };
    }

    case authReducerConstants.RESET_PASSWORD_REQUEST: {
      return {};
    }

    case authReducerConstants.RESET_PASSWORD_SUCCESS: {
      return { resetPassword: true };
    }

    case authReducerConstants.RESET_PASSWORD_FAILURE: {
      return { resetPasswordError: action.error };
    }

    case authReducerConstants.LOAD_CSRF_TOKEN_SUCCESS: {
      return {
        ...state,
        isCsrfReady: true
      };
    }

    case authReducerConstants.CLEAR_ALL:
        return {};

    default:
      return state
  }
}