import { nftPublicReducerConstants } from "../constants";
import { nftPublicService } from "../services";

export const nftPublicActions = {
    getAll,
    getBySlug,
    filter,
    reset
};

function getAll() {
    return dispatch => {
        dispatch(request());

        nftPublicService.getAll()
            .then(
                nfts => dispatch(success(nfts)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: nftPublicReducerConstants.GETALL_REQUEST } }
    function success(nfts) { return { type: nftPublicReducerConstants.GETALL_SUCCESS, nfts } }
    function failure(error) { return { type: nftPublicReducerConstants.GETALL_FAILURE, error } }
}

function getBySlug(username, slug) {
    return dispatch => {
        dispatch(request({ username, slug }));

        nftPublicService.getBySlug(username, slug)
            .then(
                nft => dispatch(success(nft)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(nft) { return { type: nftPublicReducerConstants.GETBYSLUG_REQUEST, nft } }
    function success(nft) { return { type: nftPublicReducerConstants.GETBYSLUG_SUCCESS, nft } }
    function failure(error) { return { type: nftPublicReducerConstants.GETBYSLUG_FAILURE, error } }
}

function filter(data) {
    return dispatch => {
        dispatch(request());

        nftPublicService.filter(data)
            .then(
                nfts => dispatch(success(nfts)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: nftPublicReducerConstants.FILTER_REQUEST } }
    function success(nfts) { return { type: nftPublicReducerConstants.FILTER_SUCCESS, nfts } }
    function failure(error) { return { type: nftPublicReducerConstants.FILTER_FAILURE, error } }
}

function reset() {
    return { type: nftPublicReducerConstants.RESET_ALL };
}