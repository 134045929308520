import { http, handleResponse, handleError } from "../utils";

export const publicOverviewService = {
    get,
};

async function get(query) {
    // construct api call url
    let url = '/public/overview';
    if(query) url = url + `?${query.toString()}`
    return await http.get(url)
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}