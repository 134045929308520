import styled from 'styled-components';
import { Link45deg } from 'react-bootstrap-icons';

import { colorConstants } from '../../../constants';
import { Button } from '../Buttons';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Header = styled.div`
    margin-top: 24px;
    font-size: 18px;
    font-weight: 600;
    color: ${colorConstants.black};
    text-align: center;
`;

const Text = styled.div`
    margin-bottom: 24px;
    font-size: 12px;
    font-weight: 400;
    color: ${colorConstants.mediumGrey};
    text-align: center;
`;


export const NoLinksFound = (props) => {
    const { onClick } = props;

    return (
        <Container>
            <Link45deg size={72} color={colorConstants.black} />
            <Header>Add Some Links</Header>
            <Text>Share links and resources with your fans</Text>
            <Button outline={true} small={true} onClick={onClick} >Add Link</Button>
        </Container>
    );
};