import { publicLinksReducerConstants } from "../constants";
import { publicLinksService } from "../services";

export const publicLinksActions = {
    getAll,
    filter,
    reset
};

function getAll() {
    return dispatch => {
        dispatch(request());

        publicLinksService.getAll()
            .then(
                links => dispatch(success(links)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publicLinksReducerConstants.GETALL_REQUEST } }
    function success(links) { return { type: publicLinksReducerConstants.GETALL_SUCCESS, links } }
    function failure(error) { return { type: publicLinksReducerConstants.GETALL_FAILURE, error } }
}

function filter(data) {
    return dispatch => {
        dispatch(request());

        publicLinksService.filter(data)
            .then(
                links => dispatch(success(links)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publicLinksReducerConstants.FILTER_REQUEST } }
    function success(links) { return { type: publicLinksReducerConstants.FILTER_SUCCESS, links } }
    function failure(error) { return { type: publicLinksReducerConstants.FILTER_FAILURE, error } }
}

function reset() {
    return { type: publicLinksReducerConstants.RESET_ALL };
}