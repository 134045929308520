import React, { Component } from "react";
import { connect } from 'react-redux';

import { tripActions, hotelActions, travelAgencyActions, googlePlacesActions } from '../../../actions';
import { ScreenContainer } from './AddTripContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { AddTrip }  from "../../../components/trips";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { Upgrade } from '../../../components/custom/Popup';
import { TopBar } from '../../../components/custom/TopBar';
import ImageCrop from "../../../components/cropper/ImageCrop";
import {
    defaultTrip,
    resetTripLocationData,
    resetTripPlaceData,
    defaultGoogleSearch,
    defaultPhotos,
    history,
    notNull,
    compressImage,
    storageRef,
    uploadFile,
    getFileDownloadURL,
    removeTokenFromFileDownloadURL,
    clearReduxState,
    createSlug,
    finalizeTripData,
    generateInviteData,
    getImageFileDimensions
} from '../../../utils';
import { topbarConstants, featureOptions, otherConstants, sitemap, testUsersList, testHotelSearchResults, getUserAccountType } from "../../../constants";


class AddTripContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            init: false,
            fromUrl: sitemap.admin.trips.list,
            isTestUser: false,
            typingTimeout: 0, // used to know when user stops typing
            submitted: false,
            creating: false,
            googleSearch: defaultGoogleSearch(),
            photos: defaultPhotos(),
            trip: defaultTrip(featureOptions.trip.types[0]),
            tripDescriptionLength: 0, // used to force user to type a minimum description before adding
            showUpgradePopup: false, // used to toggle upgrade popup
        };

        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleDescriptionLengthChange = this.handleDescriptionLengthChange.bind(this);
        this.handleChangeLocation = this.handleChangeLocation.bind(this);
        this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleSelectedName = this.handleSelectedName.bind(this);
        this.handleIsBookable = this.handleIsBookable.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSendInviteRequest = this.handleSendInviteRequest.bind(this);
        this.handleUploadPhoto1 = this.handleUploadPhoto1.bind(this);
        this.handleUploadPhoto2 = this.handleUploadPhoto2.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);
        /* Upgrade */
        this.handleToggleUpgradePopup = this.handleToggleUpgradePopup.bind(this);
        this.handleUpgrade = this.handleUpgrade.bind(this);
    }

    componentDidMount() {
        if(!this.props.location.state || !this.props.location.state.from || !this.props.location.state.data || !this.props.location.state.data.type || !featureOptions.trip.types.includes(this.props.location.state.data.type) || !this.props.user || !this.props.user.id) {
            history.replace({ pathname: this.state.fromUrl, state: { error: 'Invalid container startup props' } });
        }

        // set from URL
        let initialState = { ...this.state, fromUrl: this.props.location.state.from };

        // get data from previous page
        const { type } = this.props.location.state.data;

        // set trip type
        if(type) initialState = { ...initialState, trip: defaultTrip(type) };

        // set initial settings page state
        this.setState(initialState);
    }

    componentDidUpdate() {
        // initialize state
        if(!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access
            if(!this.props.user.creator) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            this.setState({ ...this.state, init: true, isTestUser: testUsersList.includes(this.props.user.username), trip: { ...this.state.trip, userId: this.props.user.id } });
        }

        /* CHECK IF SELECTED TRIP IS ALREADY A PARTNER */
        if (this.state.trip.type === featureOptions.trip.types[0] && this.props.hotel && this.props.hotel.id && (!this.state.trip.partnerId || this.state.trip.partnerId !== this.props.hotel.id)){
            // if hotel found in db, save partnerId in trip state
            this.setState({
                ...this.state,
                trip: {
                    ...this.state.trip,
                    isBookable: true,
                    partnerId: this.props.hotel.id,
                    customUrl: this.props.hotel.slug
                }
            });
        } else if (this.state.trip.type === featureOptions.trip.types[1] && this.props.travelAgency.agencies && this.props.travelAgency.agencies.length === 1 && (!this.state.trip.partnerId || this.state.trip.partnerId !== this.props.travelAgency.agencies[0].id)){
            // if travel agency found in db, save partnerId in trip state
            this.setState({
                ...this.state,
                trip: {
                    ...this.state.trip,
                    isBookable: true,
                    partnerId: this.props.travelAgency.agencies[0].id,
                    customUrl: this.props.travelAgency.agencies[0].slug
                }
            });
        }
    }

    handleTopBarBackButton() {
        if(this.state.photos.crop.pendingImage) this.setState({ ...this.state, photos: { ...this.state.photos, crop: defaultPhotos().crop } }); // reset crop image screen
        else {
            clearReduxState(this.state.trip.type, featureOptions.trip.types, this.props);
            history.push({ pathname: this.state.fromUrl }); // Go back
        }
    }

    handleChange(event) {
        event.preventDefault();

        let { name, value } = event.target;
        const { trip } = this.state;

        // clear state and redirect to trips list page if the trip type is changed
        if(name === 'type' && value !== this.state.trip.type){
            this.setState({
                submitted: false,
                creating: false,
                googleSearch: defaultGoogleSearch(),
                photos: defaultPhotos(),
                trip: defaultTrip(value, this.state.trip.userId)
            }, () => history.replace({ pathname: this.state.fromUrl, state: { error: 'Invalid trip type' } }));
            return;
        } else if(name === 'customUrl') {
            // limit trip custom url to minimum 5 characters
            if(value.length <= 5) return;
            // create slug from input
            value = createSlug(value);
        } else if(name === 'simple-link-label') {
            // save affiliateTitle value
            name = 'affiliate';
            value = { ...this.state.trip.affiliate, label: value };
        } else if(name === 'simple-link-url') {
            // save affiliateLink value
            name = 'affiliate';
            value = { ...this.state.trip.affiliate, url: value };
        }

        this.setState({
            submitted: false,
            creating: false,
            trip: {
                ...trip,
                [name]: value, // update input data
            }
        });
    }

    handleDescriptionChange(value) {
        const { trip } = this.state;

        this.setState({
            submitted: false,
            creating: false,
            trip: {
                ...trip,
                description: value, // update input data
            }
        });
    }

    handleDescriptionLengthChange(length){
        this.setState({ ...this.state, tripDescriptionLength: length });
    }

    handleChangeLocation(event) {
        event.preventDefault();

        const { value } = event.target;
        const { trip, googleSearch } = this.state;

        // callback method to display location suggestion results
        const getLocation = async (keyword) => {
            // get suggestions from Google API
            let suggestions = await googlePlacesActions.getSuggestions(keyword, 'regions');

            // save location suggestion list in state
            this.setState({
                ...this.state,
                googleSearch: {
                    ...googleSearch,
                    location: {
                        ...googleSearch.location,
                        locations: suggestions
                    }
                }
            });
        };

        this.setState({
            typingTimeout: setTimeout(() => { getLocation(value) }, 500), // call getLocation() method
            submitted: false,
            creating: false,
            googleSearch: {
                location: {
                    ...googleSearch.location,
                    selectedId: '', // reset location id
                    selectedCoordinates: { lat: 0, lng: 0 } // reset location coordinates
                },
                place: defaultGoogleSearch().place // reset places
            },
            photos: defaultPhotos(), // reset all photos
            trip: {
                ...trip,
                ...resetTripLocationData(), // reset trip location data
                ...resetTripPlaceData(), // reset trip place data
                location: value // update location input data
            }
        }, () => clearReduxState(trip.type, featureOptions.trip.types, this.props));

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }
    
    async handleSelectedLocation(location){
        const { googleSearch, trip } = this.state;

        // get location details
        let details = await googlePlacesActions.getDetails(location.place_id);
        
        // save selected location name and id
        this.setState({
            ...this.state,
            googleSearch: {
                ...googleSearch,
                location: {
                    locations: [], // reset locations
                    selectedId: location.place_id, // save selected location place id
                    selectedCoordinates: { lat: details.latitude, lng: details.longitude }, // save selected location coordinates
                }
            },
            trip: {
                ...trip,
                location: location.description, // save selected location name
                city: details.city,
                country: details.country,
            }
        });
    }

    handleChangeName(event) {
        event.preventDefault();

        const { value } = event.target;
        const { trip, googleSearch } = this.state;

        // callback method to display place suggestion results
        const getPlaces = async (lat, lng, radius, type, keyword) => {
            let places = null;

            if(this.state.isTestUser && keyword === 'Impala') {
                // display Impalaland test hotels
                places = testHotelSearchResults.impala;

            } else {
                // get place suggestions from Google API based on types: 'travel_agency' or 'lodging'
                places = await googlePlacesActions.getNearby(lat, lng, radius, type, keyword);
            }

            // save place suggestion list in state
            this.setState({
                ...this.state,
                googleSearch: {
                    ...googleSearch,
                    place: {
                        ...googleSearch.place,
                        places: places
                    }
                }
            });
        };

        const tripTypes = featureOptions.trip.types;
        const searchTypes = featureOptions.trip.googlePlacesSearchType;

        this.setState({
            ...this.state,
            typingTimeout: googleSearch.location.selectedId ? setTimeout(() => { getPlaces(googleSearch.location.selectedCoordinates.lat, googleSearch.location.selectedCoordinates.lng, 50000, trip.type === tripTypes[1] ? searchTypes[1] : searchTypes[0], value) }, 500) : null, // call getPlaces() only if user selected location
            submitted: false,
            creating: false,
            photos: defaultPhotos(), // reset all photos
            trip: {
                ...trip,
                ...resetTripPlaceData(), // reset trip place data
                name: googleSearch.location.selectedId ? value : "LOCATION REQUIRED" // update input data only if user selected location id
            }
        }, () => clearReduxState(trip.type, tripTypes, this.props));

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleSelectedName(place) {
        const { googleSearch, trip } = this.state;

        this.setState({
            ...this.state,
            googleSearch: {
                ...googleSearch,
                place: defaultGoogleSearch().place
            },
            trip: {
                ...trip,
                name: place.description, // save selected place name
                googlePlaceId: place.place_id, // save selected place id
                customUrl: createSlug(place.description + ' ' + trip.city + ' ' + trip.country) // create slug
            }
        }, async () => {

            /* VALIDATE NO DUPLICATE TRIP */
            
            this.props.validate({ userId: this.props.user.id, googlePlaceId: place.place_id, type: trip.type });

            /* GET SELECTED PLACE DETAILS PREVIEW */

            // Get selected place details preview
            let previewDetails = await googlePlacesActions.getDetailsPreview(place.place_id, { name: place.description });

            // Save preview data in preview state (data: name, location, rating, photo url)
            this.setState({
                ...this.state,
                googleSearch: {
                    ...this.state.googleSearch,
                    place: {
                        ...this.state.googleSearch.place,
                        preview: {
                            ...previewDetails,
                            location: this.state.trip.location
                        }
                    }
                }
            });

            /* CHECK IF SELECTED TRIP IS ALREADY A PARTNER */

            // if accommodation selected
            if(trip.type === featureOptions.trip.types[0]){
                // check if selected Place ID exists in hotel db
                this.props.findHotel(new URLSearchParams({
                    name: place.description,
                    googlePlaceId: place.place_id,
                    city: trip.city,
                    country: trip.country,
                    lng: googleSearch.location.selectedCoordinates.lng,
                    lat: googleSearch.location.selectedCoordinates.lat,
                    radius: 50000
                }));

                // FEATURE: if partner not found in hotel then check for Place ID in hostel and airbnb DBs as well

            } else if (trip.type === featureOptions.trip.types[1]) {
                // check if selected Place ID exists in travel agencies db
                this.props.findAgency(new URLSearchParams({ name: place.description, googlePlaceId: place.place_id, city: trip.city, country: trip.country }));
            }
        });
    }

    handleIsBookable(option){
        // option: 0 => isBookable option is selected
        const bookable = option === 0;

        this.setState({
            ...this.state,
            trip: {
                ...this.state.trip,
                isBookable: bookable,
                skipDetailsPage: bookable ? false : this.state.trip.skipDetailsPage, // must open trip details page to book
            }
        });
    }
    
    handleSubmit() {
        const { trip } = this.state;

        this.setState({
            submitted: true,
            creating: true // enable spinner
        }, async () => {

            // do not submit trip if no photos
            if(!this.state.photos.temp || this.state.photos.temp.length === 0){
                // disable spinner and stop
                this.setState({ ...this.state, creating: false });
                return;
            }

            // do not submit trip if no description
            if(!this.state.trip.description || this.state.tripDescriptionLength < 50) {
                // disable spinner and stop
                this.setState({ ...this.state, creating: false });
                return;
            }

            if (this.state.submitted){
                // create new trip
                let createNewTripPromise = new Promise(async (resolve, reject) => {

                                                        // create new trip
                                                        await this.props.create(finalizeTripData(trip));

                                                        // send place invite request (skip if test hotel)
                                                        if(!trip.googlePlaceId.includes('bunjee_test_hotel_')) googlePlacesActions.createInvite(generateInviteData(trip));

                                                        if(this.props.trip.id) resolve(this.props.trip.id); // return newlly created trip's ID saved in state

                                                        reject('Could not create trip');
                                                    });

                await createNewTripPromise
                        .then(async (tripId) => {
                            try {
                                // get local trip photo URLs
                                let { tripPhotoUrls } = trip;

                                for(const photo of this.state.photos.temp) {
                                    // create firebase storage reference of image to upload
                                    const imageRef = storageRef(otherConstants.environment + '/users/' + this.props.user.id + '/trips/' + tripId + '/photos/trip-' + tripId + '-' + photo.file.name);

                                    // Upload image to firebase storage
                                    const snapshot = await uploadFile(imageRef, photo.file);

                                    // Get firebase public url of uploaded photo
                                    let uploadedPhotoFirebaseUrl = await getFileDownloadURL(snapshot.ref);

                                    // Clean public url
                                    uploadedPhotoFirebaseUrl = removeTokenFromFileDownloadURL(uploadedPhotoFirebaseUrl);

                                    // remove local image url from trip photos
                                    tripPhotoUrls = tripPhotoUrls.filter(url => {
                                        if(url === photo.url){
                                            URL.revokeObjectURL(url); // revoke local file url
                                            return false;
                                        }
                                        return true;
                                    });

                                    // add firebase url in trip photos
                                    tripPhotoUrls.push(uploadedPhotoFirebaseUrl);
                                }

                                // save uploaded trip photos url in state
                                this.setState({
                                    ...this.state,
                                    trip: {
                                        ...this.state.trip,
                                        tripPhotoUrls: tripPhotoUrls
                                    }
                                }, async () => {
                                    // update trip photos in db
                                    if(tripPhotoUrls && tripPhotoUrls.length > 0) await this.props.update({ id: tripId, userId: trip.userId, tripPhotoUrls });

                                    clearReduxState(trip.type, featureOptions.trip.types, this.props);

                                    // reset photos in state and disable spinner
                                    this.setState({ ...this.state, creating: false, photos: defaultPhotos() });

                                    // Go back to previous screen
                                    history.push({
                                        pathname: this.state.fromUrl,
                                        state: {
                                            from: sitemap.admin.trips.add
                                        }
                                    });
                                });
                            } catch (error) {
                                throw error;
                            }
                        })
                        .catch(err => {
                            // stop spinner and reset trip data
                            this.setState({
                                ...this.state,
                                creating: false,
                                googleSearch: defaultGoogleSearch(),
                                photos: defaultPhotos(),
                                trip: defaultTrip(this.state.trip.type, this.state.trip.userId)
                            });
                        });
            }
        });   
    }

    handleSendInviteRequest() {
        const { trip } = this.state;

        this.setState({
            submitted: true
        }, async () => {
            if (this.state.submitted){

                // create invite
                var invitePromise = new Promise(async (resolve, reject) => resolve(await googlePlacesActions.createInvite(generateInviteData(trip))));

                // send invite and go to previous screen
                await invitePromise
                        .then(() => {
                            clearReduxState(trip.type, featureOptions.trip.types, this.props);
                            
                            // Go back to previous screen
                            history.push({
                                pathname: this.state.fromUrl,
                                state: {
                                    from: sitemap.admin.trips.add
                                }
                            });
                        })
                        .catch(console.log);
            }
        });   
    }

    handleUploadPhoto1(e){
        // Save inserted image url in state
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save inserted photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        ...this.state.photos,
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: 'photo-1',
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    handleUploadPhoto2(e){
        // Save inserted image url in state
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save inserted photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        ...this.state.photos,
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: 'photo-2',
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    async handleCroppedImage(croppedImage){

        // get temp images from state
        let { temp } = this.state.photos;
        if(!temp) temp = [];

        // get trip photo urls from state
        let { tripPhotoUrls } = this.state.trip;
        if(!tripPhotoUrls) tripPhotoUrls = [];

        // remove previously uploaded image if any
        if(temp.length > 0) {
            temp = temp.filter(img => {
                            if(img.file.name.includes(croppedImage.name)){
                                // remove previous url from trip photo urls array
                                if(tripPhotoUrls.length > 0) tripPhotoUrls = tripPhotoUrls.filter(url => url !== img.url);
                                return false;
                            }
                            return true;
                        });
        }

        // compress cropped image file
        const compressedFile = await compressImage(croppedImage, croppedImage.name, 1080);

        // save compressed image to temp state
        let tempImage = { file: compressedFile, url: notNull(URL.createObjectURL(compressedFile)) };
        temp.push(tempImage);

        // save compressed file URL
        tripPhotoUrls.push(tempImage.url);

        this.setState({
            ...this.state,
            trip: {
                ...this.state.trip,
                tripPhotoUrls: tripPhotoUrls // save compressed image URI
            },
            photos: {
                crop: defaultPhotos().crop, // reset pending image for cropping
                temp: temp
            }
        });
    }

    handleToggleUpgradePopup() {
        this.setState({ ...this.state, showUpgradePopup: !this.state.showUpgradePopup });
    }

    handleUpgrade(){
        history.push({
            pathname: sitemap.billing.upgrade,
            state: {
                from: sitemap.admin.trips.add
            }
        });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.addTrip} />

                {this.state.init &&
                <TopBar
                    sticky={this.state.trip.name && this.state.trip.googlePlaceId}
                    title={`Add ${this.state.trip.type === featureOptions.trip.types[0] ? 'Accommodation' : 'Experience'}`}
                    backButton={this.handleTopBarBackButton} /> }

                { this.state.init && !this.state.photos.crop.pendingImage &&
                <AddTrip
                    trip={this.state.trip}
                    userAccountType={getUserAccountType(this.props.user)}
                    handleUpgrade={this.handleToggleUpgradePopup}
                    googleSearch={this.state.googleSearch}
                    submitted={this.state.submitted}
                    creating={this.state.creating}
                    hasPhotos={this.state.photos.temp && this.state.photos.temp.length > 0}
                    alert={this.props.alert} // show error if not updated
                    isDuplicate={this.props.duplicate}
                    handleChange={this.handleChange}
                    handleDescriptionChange={this.handleDescriptionChange}
                    handleDescriptionLengthChange={this.handleDescriptionLengthChange}
                    tripDescriptionLength={this.state.tripDescriptionLength}
                    handleChangeLocation={this.handleChangeLocation}
                    handleSelectedLocation={this.handleSelectedLocation}
                    handleChangeName={this.handleChangeName}
                    handleSelectedName={this.handleSelectedName}
                    handleIsBookable={this.handleIsBookable}
                    handleSubmit={this.handleSubmit}
                    handleSendInviteRequest={this.handleSendInviteRequest}
                    handleUploadPhoto1={this.handleUploadPhoto1}
                    handleUploadPhoto2={this.handleUploadPhoto2} /> }

                { this.state.photos.crop.pendingImage &&
                <ImageCrop
                    url={this.state.photos.crop.pendingImage}
                    filename={this.state.photos.crop.pendingImageFilename}
                    fileSize={this.state.photos.crop.pendingImageFileSize}
                    imageDimensions={this.state.photos.crop.pendingImageDimensions}
                    circularCrop={false}
                    handleCroppedImage={this.handleCroppedImage} /> }

                {this.state.showUpgradePopup &&
                <Upgrade
                    show={this.state.showUpgradePopup}
                    onClick={this.handleUpgrade}
                    onHide={this.handleToggleUpgradePopup} /> }

                {this.state.creating && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { alert, user, trip, hotel, travelAgency } = state;

    // get validation
    const { duplicate } = trip;

    // export state data to props
    return { alert, user, trip, duplicate, hotel, travelAgency };
}

const actionCreators = {
    validate: tripActions.validate,
    create: tripActions.create,
    update: tripActions.update,
    findHotel: hotelActions.find,
    clearHotels: hotelActions.clear,
    findAgency: travelAgencyActions.getAll,
    clearAgencies: travelAgencyActions.clear
}

const connectedAddTripContainer = connect(mapState, actionCreators)(AddTripContainer);
export { connectedAddTripContainer as AddTripContainer };