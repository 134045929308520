import styled from 'styled-components';

import { colorConstants } from '../../../constants';



export const Container = styled.div`
    width: 100%;
`;

export const Title = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: ${colorConstants.black};
    text-align: center;
`;

export const OptionsContainer = styled.div`
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .active {
        padding: 1px;
        background: ${colorConstants.gradientHorizontal};
    }
`;

export const OptionSelectionGradient = styled.div`
    padding: 1px;
    border-radius: 500px;
    background: ${colorConstants.white};

    :hover {
        cursor: pointer;
    }
`;

export const Option = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: ${colorConstants.black};
    padding: 2px 56px;
    border-radius: 500px;
    background: ${colorConstants.white};
`;