import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { Button as GradientButton } from '../Buttons';
import { colorConstants, deviceConstants } from '../../../constants';


export const Container = styled(Modal)`

`;

export const Header = styled(Modal.Header)`
    border-bottom: none;
`;

export const Title = styled(Modal.Title)`
    font-weight: 700;
    font-size: 20px;
    color: ${colorConstants.darkGrey};
`;

export const Body = styled.div`
    padding: 16px;

    display: flex;
    align-items: center;
`;

export const IconsList = styled.div`
    margin-left: -22px;
    display: flex;

    z-index: 1;

    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    & > * {
        margin-right: 16px;
    }

    & > *:last-child {
        margin-right: 0px;
    }

    @media ${deviceConstants.laptop} {
        overflow-x: hidden;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
`;

export const Button = styled.div`
    padding: 8px 0;
    border-radius: 4px;
    max-width: 76px;

    text-align: center;

    :hover {
        cursor: pointer;
        background: ${colorConstants.verySoftGrey};
    }

    & > *:first-child {
        margin-left: 8px;
        margin-right: 8px;
    }
`;

export const Label = styled.div`
    font-weight: 600;
    font-size: 12px;
    color: ${colorConstants.black};

    margin-top: 4px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ScrollForwardButton = styled.div`
    padding: 8px;
    margin-left: -22px;
    width: 56px;
    height: 34px;
    background: ${colorConstants.primaryDark};
    color: ${colorConstants.white};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 1000px;
    z-index: 20;
    visibility: ${props => props.hide ? 'hidden' : 'visible'};
    
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        cursor: pointer;
    }
`;

export const ScrollBackButton = styled.div`
    padding: 8px;
    width: 56px;
    height: 34px;
    background: ${colorConstants.primaryDark};
    color: ${colorConstants.white};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 1000px;
    z-index: 20;
    visibility: ${props => props.hide ? 'hidden' : 'visible'};

    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        cursor: pointer;
    }
`;

export const LinkContainer = styled.div`
    margin: 32px;
    padding: 8px 16px;
    border: 1px solid ${colorConstants.softGrey};
    border-radius: 2px;

    display: flex;
    align-items: center;
`;

export const Link = styled.div`
    width: 100%;
    margin-right: 16px;
    font-weight: 400;
    font-size: 12px;
    color: ${colorConstants.darkGrey};
    border-radius: 2px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const CopyLinkButton = styled(GradientButton)`
    padding-left: 16px;
    padding-right: 16px;
`;