// Manages the trips section of the application state used to list trips and trip info
import { tripReducerConstants } from '../constants';

export function trip(state = {}, action) {

  switch (action.type) {

    case tripReducerConstants.VALIDATE_REQUEST:
        return state;

    case tripReducerConstants.VALIDATE_SUCCESS: {
        return {
            ...state,
            ...action.validation,
        };
    }

    case tripReducerConstants.VALIDATE_FAILURE:
        return state;

    case tripReducerConstants.CREATE_REQUEST:
        return { creating: true };

    case tripReducerConstants.CREATE_SUCCESS:
        return { id: action.trip.id };

    case tripReducerConstants.CREATE_FAILURE:
        return {};
    
    case tripReducerConstants.GETALL_REQUEST:
        return {
            ...state,
            loading: true
        };

    case tripReducerConstants.GETALL_SUCCESS:
        return {
            trips: action.trips
        };

    case tripReducerConstants.GETALL_FAILURE:
        return {
            trips: [],
            error: action.error
        };

    case tripReducerConstants.GETBYID_REQUEST:
        return {
            loading: true
        };
    
    case tripReducerConstants.GETBYID_SUCCESS:
        return {
            ...action.trip
        };

    case tripReducerConstants.GETBYID_FAILURE:
        let { loading, ...stateCopy } = state;
        return {
            ...stateCopy,
            error: action.error
        };

    case tripReducerConstants.GETBYSLUG_REQUEST:
        return {
            loading: true
        };
    
    case tripReducerConstants.GETBYSLUG_SUCCESS:
        return {
            ...action.trip
        };

    case tripReducerConstants.GETBYSLUG_FAILURE: {
        let { loading, ...stateCopy } = state;
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case tripReducerConstants.UPDATE_REQUEST: {
        if(state.id && !state.trips) {
            // single trip edit
            if (state.id === action.trip.id) return { ...state, updating: true };
        } else if(!state.id && state.trips) {
            // trips list edit
            let updating = false;
            state.trips.forEach((item, index) => {
                if (item.id === action.trip.id) updating = true;
            });
            if(updating) return { ...state, updating };
        }

        return state;
    }

    case tripReducerConstants.UPDATE_SUCCESS: {
        // remove updating flag
        let { updating, ...stateCopy } = state;

        if(state.id && !state.trips) {
            // single trip edit
            if (state.id === action.trip.id) return { ...stateCopy, ...action.trip };
        } else if(!state.id && state.trips) {
            // trips list edit
            let updatedTrips = state.trips.map((item, index) => {
                if (item.id === action.trip.id) return action.trip;
                else return item;
            });
            return { ...stateCopy, trips: updatedTrips };
        }

        return stateCopy;
    }

    case tripReducerConstants.UPDATE_FAILURE: {
        // remove updating flag
        let { updating, ...stateCopy } = state;

        if(state.id && !state.trips) {
            // single trip edit
            if (state.id === action.trip.id) return { ...stateCopy, updateError: action.error };
        } else if(!state.id && state.trips) {
            // trips list edit
            let hasError = false;
            state.trips.forEach((item, index) => {
                if (item.id === action.trip.id) hasError = true;
            });
            if(hasError) return { ...stateCopy, updateError: action.error };
        }

        return stateCopy;
    }

    case tripReducerConstants.FAVOURITE_REQUEST: {
        if(state.id && !state.trips) {
            // single trip edit
            if (state.id === action.data.id) return { ...state, savingToFavourites: true };
        } else if(!state.id && state.trips) {
            // trips list edit
            let savingToFavourites = false;
            state.trips.forEach((item, index) => {
                if (item.id === action.data.id) savingToFavourites = true;
            });
            if(savingToFavourites) return { ...state, savingToFavourites };
        }

        return state;
    }

    case tripReducerConstants.FAVOURITE_SUCCESS: {
        // remove savingToFavourites flag
        let { savingToFavourites, ...stateCopy } = state;

        if(state.id && !state.trips) {
            // single trip edit
            if (state.id === action.trip.id) return { ...stateCopy, favourite: action.trip.favourite };
        } else if(!state.id && state.trips) {
            // trips list edit
            let updatedTrips = state.trips.map((item, index) => {
                if (item.id === action.trip.id) return { ...item, favourite: action.trip.favourite };
                else return item;
            });
            return { ...stateCopy, trips: updatedTrips };
        }

        return stateCopy;
    }

    case tripReducerConstants.FAVOURITE_FAILURE: {
        // remove savingToFavourites flag
        let { savingToFavourites, ...stateCopy } = state;

        if(state.id && !state.trips) {
            // single trip edit
            if (state.id === action.id) return { ...stateCopy, favouriteError: action.error };
        } else if(!state.id && state.trips) {
            // trips list edit
            let hasError = false;
            state.trips.forEach((item, index) => {
                if (item.id === action.id) hasError = true;
            });
            if(hasError) return { ...stateCopy, favouriteError: action.error };
        }

        return stateCopy;
    }

    case tripReducerConstants.DELETE_REQUEST:
        if (state.id === action.id) return { ...state, deleting: true };
        else if (state.trips) {
            let deleting = false;
            state.trips.forEach(item => {
                if (item.id === action.id) deleting = true;
            });
            if(deleting) return { ...state, deleting };
        }
        return state;

    case tripReducerConstants.DELETE_SUCCESS:
        if (state.id === action.id) return {};
        else if (state.trips) return { trips: state.trips.filter(item => item.id !== action.id) };
        return state;

    case tripReducerConstants.DELETE_FAILURE: {
        let { deleting, ...stateCopy } = state;
        return { ...stateCopy, deleteError: action.error }; // remove deleting flag and add error
    }

    case tripReducerConstants.CLEAR_ALL:
        return {};

    default:
        return state
  }
}