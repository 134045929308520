import React, { Component } from "react";
import { connect } from 'react-redux';

import { authActions, userActions, customerActions, paymentMethodActions, subscriptionActions, alertActions } from '../../../actions';
import { ScreenContainer } from './UpgradeCheckoutContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { Header } from '../../../components/custom/TopBar';
import { UpgradeCheckoutPage } from "../../../components/upgrade";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { sitemap, urlConstants } from "../../../constants";
import { history, analytics, defaultUser, defaultCustomer, defaultPaymentMethod, prepareSubscriptionData, checkIsSubscribed, prepareSubscriptionCheckoutConfigData } from '../../../utils';


class UpgradeCheckoutContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            initCustomer: false, // initialize server check if customer exists
            initPaymentMethod: false, // initialize server check if payment method exists
            initSubscription: false, // initialize server check if subscription exists
            fromUrl: sitemap.admin.dashboard,
            scrollToSection: '', // used to scroll to particular section
            typingTimeout: 0, // used to know when user stops typing
            selectedPlan: '',
            isMonthly: true,
            coupon: '',
            user: defaultUser(),
            customer: defaultCustomer(),
            paymentMethod: defaultPaymentMethod(),
        };

        this.handleScrollReset = this.handleScrollReset.bind(this);

        this.handleChangeMonthly = this.handleChangeMonthly.bind(this);
        this.handleChangeCoupon = this.handleChangeCoupon.bind(this);
        this.handleOpenTerms = this.handleOpenTerms.bind(this);
        this.handleChangeBillingDetails = this.handleChangeBillingDetails.bind(this);
        this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
        this.handleChangeSavedPaymentMethod = this.handleChangeSavedPaymentMethod.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCompleted = this.handleCompleted.bind(this);
    }

    componentDidMount() {
        this.props.loadCsrfToken(); // load csrf token in cookies
        this.props.getById(this.props.userId); // get user data from db
        window.addEventListener('scroll', this.handleScroll);
        window.scrollTo(0, 0); // force scroll to top of page

        if(this.props.location.state) {
            let initialState = {};
            if(this.props.location.state.from) initialState = { ...this.state, ...initialState, fromUrl: this.props.location.state.from }; // set from URL
            // get data from previous page
            if(this.props.location.state.data) {
                const { scrollToSection, plan, isMonthly } = this.props.location.state.data;
                // set section to scroll to
                if(scrollToSection) initialState = { ...this.state, ...initialState, scrollToSection };
                // set selected plan and billing cycle
                if(plan && plan.length > 0 && this.props.location.state.data.hasOwnProperty('isMonthly')) {
                    initialState = { ...this.state, ...initialState, isMonthly, selectedPlan: plan };
                } else {
                    history.replace({ pathname: this.state.fromUrl || sitemap.admin.dashboard, state: { error: 'No plan selected' } });
                    return;
                }
            }
            // set initial settings page state
            this.setState(initialState);
        } else {
            history.replace({ pathname: sitemap.admin.dashboard, state: { error: 'Invalid Params' } });
            return;
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.clearAlert(); // reset alert in redux state
        this.props.resetUser(); // reset user to force update on next screen
        this.props.resetCustomer();
        this.props.resetSubscription();
    }

    componentDidUpdate() {
        // load user data from server into view
        if (!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access
            if(!this.props.user.creator) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }

            // load select plan details
            this.props.getPlans(new URLSearchParams({ plan: this.state.selectedPlan }));
            // find customer on server with userId (if exists)
            this.props.getCustomer(this.props.user.id, false);
            // find active subscriptions on server with userId (if exists)
            this.props.getSubscription(this.props.user.id);

            // save user in state
            this.setState({
                ...this.state,
                init: true,
                customer: defaultCustomer(this.props.user.name, this.props.user.email),
                user: this.props.user,
            });
        }


        // save existing customer in state
        if(this.state.init && !this.state.initCustomer && this.props.customer && (this.props.customer.name || this.props.customer.error)) {
            let { customer } = this.state;

            if(this.props.customer.name) {
                customer = { ...customer, ...this.props.customer };
                // get all payment methods
                this.props.getAllPaymentMethods(this.props.customer.id);
                // load checkout configs for existing customers
                const loadConfigQueryData = prepareSubscriptionCheckoutConfigData(this.props.user, this.state.selectedPlan, this.state.coupon, false, customer);
                this.props.loadConfigs(new URLSearchParams(loadConfigQueryData));
            }

            this.setState({ ...this.state, initCustomer: true, customer });
        }


        // save default payment method
        if(this.state.init && this.state.initCustomer && !this.state.initPaymentMethod && this.props.paymentMethod && this.props.paymentMethod.list && this.props.paymentMethod.list.length > 0) {
            const pm = this.props.paymentMethod.list.find(item => item.default);
            this.setState({ ...this.state, initPaymentMethod: true, paymentMethod: { ...pm, paymentMethodId: pm.id, paymentType: pm.type } });
        }


        // validate if user has active subscription
        if(this.state.init && this.state.initCustomer && this.state.customer.name && !this.state.initSubscription && this.props.subscription && this.props.subscription.id) {

            const hasActiveSubscription = checkIsSubscribed(this.state.user) && this.props.subscription.isActive && !this.props.subscription.isCancelled;
            this.setState({ ...this.state, initSubscription: true, hasActiveSubscription });
        }
    }

    handleScrollReset(){
        if(this.state.init && this.state.scrollToSection) {
            this.setState({ ...this.state, scrollToSection: '' });
        }
    }

    handleChangeMonthly(event){
        this.setState({ ...this.state, isMonthly: !this.state.isMonthly });
    }

    handleChangeCoupon(event){
        const { value } = event.target;

        // update checkout configs
        const loadConfigQueryData = prepareSubscriptionCheckoutConfigData(this.props.user, this.state.selectedPlan, value, !this.props.customer.name, this.state.customer);

        this.setState({
            ...this.state,
            typingTimeout: setTimeout(() => { this.props.loadConfigs(new URLSearchParams(loadConfigQueryData)) }, 1300),
            coupon: value
        });

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleOpenTerms(option) {
        let url = null;

        switch(option) {
            case 'TERMS': {
                url = sitemap.landing.z.policies.terms;
                break;
            }

            case 'TERMS_CUSTOMERS': {
                url = sitemap.landing.z.policies.termsForCustomers;
                break;
            }

            case 'PRIVACY': {
                url = sitemap.landing.z.policies.privacy;
                break;
            }

            default:
                break;
        }

        if(url) window.open(urlConstants.baseUrlClient + url);
    }

    handleChangeBillingDetails(event){
        let { name, value } = event.target;
        this.setState({ ...this.state, customer: { ...this.state.customer, [name]: value } });
    }

    handleChangeBillingAddress(event){
        let { name, value } = event.target;

        this.setState({
            ...this.state,
            customer: {
                ...this.state.customer,
                address: {
                    ...this.state.customer.address,
                    [name]: value,
                }
            }
        }, () => {
            const { address } = this.state.customer;

            // update checkout configs when new address is typed for a new customer
            if(!this.props.customer.name && address && address.addressLine1 && address.city && address.postalCode && address.country) {
                const loadConfigQueryData = prepareSubscriptionCheckoutConfigData(this.props.user, this.state.selectedPlan, this.state.coupon, true, this.state.customer);
                // save timeout method to state
                this.setState({ ...this.state, typingTimeout: setTimeout(() => { this.props.loadConfigs(new URLSearchParams(loadConfigQueryData)) }, 1300) });
            }

            // clear timer
            if(this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
        });
    }

    handleChangePaymentMethod(event){
        const isCard = event && event.elementType && event.elementType === 'card';
        const isCardCvc = event && event.elementType && event.elementType === 'cardCvc';

        // skip if card cvc input
        if(isCardCvc) return;

        // get current payment method (if any)
        let updated = this.state.paymentMethod;

        if(isCard) {
            // save card brand
            updated.brand = event.complete ? event.brand : '';
        } else {
            const { name, value } = event.target;
            updated[name] = value;
        }

        this.setState({
            ...this.state,
            paymentMethod: {
                ...this.state.paymentMethod,
                ...updated,
            },
        });
    }

    handleChangeSavedPaymentMethod(){
        // go to settings page and scroll to payment methods section
        history.replace({
            pathname: sitemap.admin.settings,
            state: {
                from: this.state.fromUrl,
                data: {
                    scrollToSection: 'billing-info',
                },
            }
        });
    }

    async handleSubmit(type) {
        if(!this.state.init) return;

        let paymentType = this.state.initPaymentMethod ? this.state.paymentMethod.paymentType : type;

        this.setState({
            ...this.state,
            paymentMethod: { ...this.state.paymentMethod, paymentType, }
        }, async () => {

            if(this.state.hasActiveSubscription) {
                // change subscription to selected plan
                const paymentMethod = {
                    paymentType: this.props.subscription.paymentType,
                    paymentMethodId: this.props.subscription.paymentMethodId,
                };
                this.props.changeSubscription(this.props.subscription.id, prepareSubscriptionData(this.state, this.state.customer, paymentMethod));
                // register click event in GA
                analytics.event('Subscription Checkout', 'Purchase Button Click', 'Upgrade/downgrade subscription');

            } else if(!this.props.subscription || !this.props.subscription.id) {
                // create new subscription on server
                this.props.createSubscription(prepareSubscriptionData(this.state, this.state.customer, this.state.paymentMethod));
                // register click event in GA
                analytics.event('Subscription Checkout', 'Purchase Button Click', 'New subscription created');
            }
        });
    }

    handleCompleted(){
        // redirect to dashboard with delay
        setTimeout(() => {
            history.replace({
                pathname: sitemap.admin.dashboard,
                state: {
                    data: {
                        upgrade: 'upgraded-subscription'
                    },
                }
            });
        }, 1500);
    }


    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title='Plan Checkout' />

                <Header
                    border={true}
                    showLogo={true}
                    secondaryButtonText='All Plans'
                    secondaryButtonLinkTo={sitemap.billing.upgrade}
                />

                { this.state.init &&
                <UpgradeCheckoutPage
                    subscription={this.props.subscription}
                    hasActiveSubscription={this.state.hasActiveSubscription}
                    isMonthly={this.state.isMonthly}
                    handleChangeMonthly={this.handleChangeMonthly}
                    customer={this.state.customer}
                    coupon={this.state.coupon}
                    handleChangeCoupon={this.handleChangeCoupon}
                    handleOpenTerms={this.handleOpenTerms}
                    paymentMethod={this.state.paymentMethod}
                    handleChangeBillingDetails={this.handleChangeBillingDetails}
                    handleChangeBillingAddress={this.handleChangeBillingAddress}
                    handleChangePaymentMethod={this.handleChangePaymentMethod}
                    handleChangeSavedPaymentMethod={this.handleChangeSavedPaymentMethod}
                    handleSubmit={this.handleSubmit}
                    onComplete={this.handleCompleted} /> }
                
                { (!this.props.user || this.props.user.loading || (this.props.customer && this.props.customer.loading) || (this.props.subscription && (this.props.subscription.loadingConfigs || this.props.subscription.loadingPlans)))
                && <FullScreenSpinner /> }
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { user, customer, subscription, paymentMethod } = state;

    // export state data to props
    return { userId, user, customer, subscription, paymentMethod };
}

const actionCreators = {
    loadCsrfToken: authActions.loadCsrfToken,
    getCustomer: customerActions.getByUserId,
    resetCustomer: customerActions.clear,
    getById: userActions.getById,
    resetUser: userActions.clear,
    getPlans: subscriptionActions.getPlans,
    loadConfigs: subscriptionActions.checkoutConfig,
    getSubscription: subscriptionActions.getByUserId,
    createSubscription: subscriptionActions.create,
    changeSubscription: subscriptionActions.update,
    resetSubscription: subscriptionActions.clear,
    getAllPaymentMethods: paymentMethodActions.getAll,
    clearAlert: alertActions.clear
}

const connectedUpgradeCheckoutContainer = connect(mapState, actionCreators)(UpgradeCheckoutContainer);
export { connectedUpgradeCheckoutContainer as UpgradeCheckoutContainer };