import styled from 'styled-components';
import { PlayFill } from 'react-bootstrap-icons';
import { colorConstants } from "../../constants";



export const Container = styled.div`
    height: ${props => props.maxheight};
    width: ${props => props.maxwidth};
    min-width: ${props => props.maxwidth};
    
    border-radius: ${props => props.shape};

    font-family: ${props => props.fontType || 'inherit'} !important;
    background-color: ${colorConstants.darkGrey};

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    position: relative;

    .fader {
        border-radius: ${props => props.shape};
    }

    .thumbnail {
        height: ${props => props.maxheight};
        width: ${props => props.maxwidth};
        border-radius: ${props => props.shape};
    }

    .button-play {
        :hover {
            cursor: pointer;
        }
    }

    .youtube-player {
        height: ${props => props.maxheight};
        width: ${props => props.maxwidth};
        z-index: 70;
        border-radius: ${props => props.shape};
    }

    :hover {
        cursor: default;
    }
`;

export const Thumbnail = styled.div`
    width: ${props => props.maxwidth};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 71;
`;

export const Image = styled.img`
    object-fit: cover;
    z-index: 72;
`;

export const Fader = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 73;

    background: ${colorConstants.black} !important;
    opacity: 0.25;
`;

export const Title = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    
    z-index: 74;

    padding: 6px 8px;
    margin: 0;

    font-size: 14px;
    font-weight: 600;
    color: ${colorConstants.white} !important;

    line-height: 1em;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; // to use this we need to set max-width
`;

export const PlayIcon = styled(PlayFill)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 48px;
    height: 48px;
    padding: 8px;

    z-index: 74;

    font-size: 32px;
    opacity: 0.8;
    color: ${colorConstants.white} !important;
`;