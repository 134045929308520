import { Link } from 'react-router-dom';
import styled from "styled-components";

import { Button } from '../../custom/Buttons';
import { ReactComponent as RightArrow } from '../../../assets/screens/EarlyAccess/right-arrow.svg';
import { ReactComponent as DownArrow } from '../../../assets/screens/EarlyAccess/down-arrow.svg';
import { deviceConstants, colorConstants } from "../../../constants";



export const Container = styled.div`
    padding-bottom: 350px;
    padding-left: 24px;
    padding-right: 24px;

    @media ${deviceConstants.laptop} {
        padding-bottom: 400px;
        padding-left: 0;
        padding-right: 0;
    }
`;

// Section
export const Section = styled.div`
    width: 100%;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.laptop} { 
        padding: 80px 16%;

        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    @media ${deviceConstants.desktop} { 
        padding: 80px 22%;
    }
`;

export const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${({ center }) => center && `
        padding-top: 22px;
    `}

    @media ${deviceConstants.laptop} {
        width: 100%;

        ${({ right }) => right && `
            margin-left: 5%;
        `}

        align-items: flex-start;

        ${({ center }) => center && `
            align-items: center;
            padding-top: 42px;
        `}
    }
`;

export const SectionHeader = styled.h1`
    font-weight: 700;
    font-size: 26px;
    text-align: center;

    ${({ gradient }) => gradient && `
        background: ${colorConstants.gradientForText};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `}

    ${({ large }) => large && `
        font-size: 36px;
    `}

    @media ${deviceConstants.tablet} {
        max-width: 320px;

        ${({ full }) => full && `
            max-width: 100%;
        `}
    }

    @media ${deviceConstants.laptop} {
        font-size: 38px;
        text-align: start;
        white-space: pre-wrap;

        ${({ large }) => large && `
            font-size: 63px;
            text-align: center;
        `}
    }
`;

export const SectionSubheader = styled.p`
    margin-top: 8px;

    font-weight: 500;
    font-size: 14px;
    text-align: center;
    white-space: pre-wrap;

    ${({ large }) => large && `
        margin-top: 12px;
        font-size: 16px;
    `}

    @media ${deviceConstants.tablet} {
        max-width: 420px;

        ${({ full }) => full && `
            max-width: 70%;
        `}
    }

    @media ${deviceConstants.laptop} {
        margin-top: 24px;

        font-size: 15px;
        text-align: start;

        ${({ large }) => large && `
            margin-top: 14px;
            font-size: 22px;
            text-align: center;
        `}
    }
`;

export const SectionButton = styled(Button)`
  margin-top: 24px;
  margin-bottom: 20px;

  width: 70%;

  padding-left: 0;
  padding-right: 0;

  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;

  @media ${deviceConstants.tablet} {
    max-width: 320px;
  }

  @media ${deviceConstants.laptop} {
    width: 320px;

    margin-top: 48px;
    margin-bottom: 16px;
    font-size: 14px;
  }
`;

export const SectionLoginSpan = styled.span`
    font-weight: 500;
    font-size: 13px;
`;

export const SectionLoginLink = styled(Link)`
    font-weight: 500;
    font-size: 13px;
    color: ${colorConstants.darkGrey};

    :hover {
        color: ${colorConstants.primaryDark};
    }
`;

export const SectionImageWrapper = styled.div`
    margin-top: 64px;

    ${({ left }) => left && `
        margin-top: 0;
        margin-bottom: 64px;
    `}

    @media ${deviceConstants.laptop} {
        margin-top: 0;
        margin-bottom: 0;

        ${({ left }) => left && `
            margin-left: -4%;
            margin-right: 4%;
        `}
    }
`;

export const SectionImage = styled.img`
    width: 100%;
    // max-width: 100%; // to make img fit in div
    // max-height: 100%; // to make img fit in div

    @media ${deviceConstants.tablet} {
        height: 320px;
        width: auto;
    }

    @media ${deviceConstants.laptop} {
        height: 500px;
    }
`;



// Registration Section
export const RegistrationSection = styled.div`
    width: 100%;
    padding: 40px 0 30px 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.laptop} {
        padding: 180px 16% 80px 16%;

        flex-direction: row;
        justify-content: space-evenly;
        align-items: stretch;
    }

    @media ${deviceConstants.desktop} { 
        padding: 180px 22% 80px 22%;
    }
`;

export const RegistrationSectionContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.tablet} {
        max-width: 320px;
    }

    @media ${deviceConstants.laptop} {
        // margin-right: 160px;
        width: 100%;
        margin-top: -80px;

        display: block;
    }
`;

export const RegistrationSectionHeader = styled.h1`
    font-weight: 700;
    font-size: 26px;
    text-align: center;

    @media ${deviceConstants.tablet} {
        max-width: 320px;
    }

    @media ${deviceConstants.laptop} {
        font-size: 32px;
        text-align: start;
        white-space: pre-wrap;
    }
`;

export const RegistrationSectionSubheader = styled.p`
    margin-top: 8px;

    font-weight: 500;
    font-size: 14px;
    text-align: center;
    white-space: pre-wrap;

    @media ${deviceConstants.tablet} {
        max-width: 320px;
    }

    @media ${deviceConstants.laptop} {
        margin-top: 24px;

        font-size: 15px;
        text-align: start;
    }
`;

export const RegistrationSectionArrowWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 8px 0;

    @media ${deviceConstants.tablet} {
        max-width: 320px;
    }

    @media ${deviceConstants.laptop} {
        justify-content: flex-end;
        padding: 24px 0;
    }
`;

export const RegistrationSectionRightArrow = styled(RightArrow)`
    display: none;

    @media ${deviceConstants.laptop} {
        display: block;
        max-width: 150px;
        max-height: 150px;
        margin-right: 24px;
    }
`;

export const RegistrationSectionDownArrow = styled(DownArrow)`
    max-width: 100px;
    max-height: 100px;

    @media ${deviceConstants.laptop} {
        display: none;
    }
`;

export const RegistrationSectionImage = styled.img`
    width: 100%;
    height: 500px;
    object-fit: cover;

    z-index: -1;
    position: absolute;
    left: 0;
    margin-top: 640px;

    @media ${deviceConstants.laptop} {
        height: auto;
        object-fit: fill;

        margin-top: 390px;
    }
`;

export const RegistrationSectionForm = styled.div`
    max-width: 380px;
    padding: 40px 16px 40px 16px;
    border-radius: 8px;
    background: ${colorConstants.white};
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    @media ${deviceConstants.laptop} {
        padding: 40px 32px 56px 32px;    
    }
`;

export const RegistrationSectionFormTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
    text-align: center;

    @media ${deviceConstants.tablet} {
        font-size: 24px;
        white-space: pre-wrap;
    }
`;

// Creators Section
export const CreatorsSpotlightSection = styled.div`
    width: 100%;
    padding: 64px 0 80px 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.laptop} {
        padding: 80px 16% 80px 16%;
    }

    @media ${deviceConstants.desktop} {
        padding: 80px 22% 80px 22%;
    }
`;

// Creator Section Header
export const CreatorsSpotlightSectionHeader = styled.h1`
    margin-bottom: 58px;

    font-weight: 700;
    font-size: 26px;
    text-align: center;

    ${({ gradient }) => gradient && `
        background: ${colorConstants.gradientForText};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `}

    @media ${deviceConstants.tablet} {
        max-width: 320px;
    }

    @media ${deviceConstants.laptop} {
        font-size: 32px;
        text-align: start;
        white-space: pre-wrap;
    }
`;