import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ReCAPTCHA from "react-google-recaptcha";

import {
  Container,
  TopSection,
  Title,
  MainSection,
  StyledForm,
  StyledFormGroup,
  StyledFormLabel,
  StyledFormControl,
  StyledFormText,
  LoginButton,
  StyledLinksContainer,
  StyledRegisterLink,
  StyledResetLink,
  DividerWrapper,
  VerticalDividerWrapper,
  SocialLoginsWrapper,
  BottomSection,
  PrivacyText,
  PrivacyLink,
  RecaptchaContainer,
  SpinnerContainer,
  AlertWarning,
  AlertSuccess
} from './LoginForm.styles';
import { Divider, VerticalDivider } from '../../custom/Divider';
import SocialLogins from '../SocialLogins';

import { otherConstants, contentLoginPage, sitemap } from "../../../constants";


export const LoginForm = (props) => {
    const {
      alert,
      handleChange,
      handleSubmit,
      handleSubmitGoogle,
      handleSubmitFacebook,
      handleCaptcha,
      loggingIn,
      email,
      password,
      submitted,
      captcha
    } = props;

    return (
      <Container>

        <TopSection>
          <Title>{contentLoginPage.title}</Title>
        </TopSection>

        <MainSection>
          <StyledForm onSubmit={handleSubmit}>
            <StyledFormGroup className={(submitted && !email ? ' has-error' : '')}>
              <StyledFormLabel>{contentLoginPage.form.labels.email}</StyledFormLabel>
              <StyledFormControl type="email" placeholder={contentLoginPage.form.placeholders.email} name="email" value={email} onChange={handleChange} />
              {submitted && !email && <StyledFormText>{contentLoginPage.form.warnings.email}</StyledFormText>}
            </StyledFormGroup>

            <StyledFormGroup className={(submitted && !password ? ' has-error' : '')}>
              <StyledFormLabel>{contentLoginPage.form.labels.password}</StyledFormLabel>
              <StyledFormControl type="password" placeholder={contentLoginPage.form.placeholders.password} name="password" value={password} onChange={handleChange} />
              {submitted && !password && <StyledFormText>{contentLoginPage.form.warnings.password}</StyledFormText>}
            </StyledFormGroup>

            <RecaptchaContainer>
              <ReCAPTCHA
                sitekey={otherConstants.captchaKey}
                onChange={handleCaptcha}
              />
              {submitted && !captcha && <StyledFormText>{contentLoginPage.form.warnings.captcha}</StyledFormText>}
            </RecaptchaContainer>
            
            <StyledFormGroup>
              <LoginButton gradient={true} type="submit">{contentLoginPage.buttons.login.label}</LoginButton>
              
              {loggingIn && <SpinnerContainer><Spinner animation="border" /></SpinnerContainer>}
            </StyledFormGroup>

            {alert.message && alert.type === 'alert-danger' && <AlertWarning>{alert.message}</AlertWarning>}
            {alert.message && alert.type === 'alert-success' && <AlertSuccess>{alert.message}</AlertSuccess>}

            <StyledLinksContainer>
              <div>
                <StyledResetLink to={sitemap.auth.password.reset}>{contentLoginPage.links.resetPassword.label}</StyledResetLink>
              </div>
              
              <div>
                <StyledRegisterLink to={sitemap.auth.register}>{contentLoginPage.links.register.label}</StyledRegisterLink>
              </div>
            </StyledLinksContainer>
          </StyledForm>

          <DividerWrapper>
            <Divider label="or" />
          </DividerWrapper>

          <VerticalDividerWrapper>
            <VerticalDivider label="or" />
          </VerticalDividerWrapper>

          <SocialLoginsWrapper>
            <SocialLogins fullButtons={true} handleGoogle={handleSubmitGoogle} handleFacebook={handleSubmitFacebook} />
          </SocialLoginsWrapper>
        </MainSection>
        
        <BottomSection>
          <PrivacyText>
            {contentLoginPage.gdpr.part1}
            <PrivacyLink to={sitemap.landing.z.policies.terms}>{contentLoginPage.links.terms.label}</PrivacyLink>
            {contentLoginPage.gdpr.part2}
            <PrivacyLink to={sitemap.landing.z.policies.privacy}>{contentLoginPage.links.privacy.label}</PrivacyLink>
          </PrivacyText>
        </BottomSection>
        
      </Container>
    );
};