import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

import { colorConstants } from '../../../constants';


export const Container = styled(Modal)`

`;

export const Header = styled(Modal.Header)`
    border-bottom: none;
    display: flex;
    align-items: flex-start;
`;

export const Title = styled(Modal.Title)`
    font-weight: 700;
    font-size: 20px;
    background: ${colorConstants.gradientForText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const Body = styled.div`
    padding: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const BodyText = styled.div`
    width: 100%;
    margin: 8px 0;

    ${({ bold }) => bold && `
        font-weight: 600;
        font-size: 17px;
    `};

    ${({ small }) => small && `
        font-weight: 400;
        font-size: 13px;
        color: ${colorConstants.mediumGrey};
    `};

    ${({ nomargin }) => nomargin && `
        margin: 0 0;
    `};

    ${({ margintop }) => margintop && `
        margin-top: 24px;
    `};
`;

export const DetailsContainer = styled.div`
    width: 100%;
    margin-top: 24px;
    margin-bottom: 12px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const Photo = styled.img`
    width: 130px;
    border-radius: 2px;
    object-fit: cover;
`;

export const DetailsColumn = styled.div`
    margin-left: 16px;
`;

export const PriceContainer = styled.div`
    width: 100%;
    margin: 12px 0;

    font-weight: 500;
    font-size: 15px;
    color: ${colorConstants.mediumGrey};

    display: flex;
    align-items: center;
    justify-content: space-between;

    & > *:first-child {
        width: 70%;
        line-height: 1;
        margin: 0;
    }

    & > *:last-child {
        width: 30%;
        line-height: 1;
        margin: 0;
        text-align: end;
        color: ${colorConstants.black};
    }
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    margin-top: 32px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    & > *:first-child {
        width: 60%;
        margin-right: 16px;
    }

    & > *:last-child {
        width: 40%;
    }
`;

export const CheckoutButton = styled.div`
    width: 100%;
    padding: 6px 0px;

    font-size: 16px;
    font-weight: 600;
    text-align: center;

    background: ${({ secondary }) => !secondary ? colorConstants.gradientHorizontal : 'none'};
    color: ${({ secondary }) => !secondary ? colorConstants.white : colorConstants.darkGrey};

    border: none;
    border-radius: 2px;

    :hover {
        cursor: pointer;
        background: ${({ secondary }) => !secondary ? colorConstants.primary : colorConstants.verySoftGrey};
        color: ${({ secondary }) => !secondary ? colorConstants.white : colorConstants.secondary};
    }
`;

export const GradientDivider = styled.div`
    width: 100%;
    margin-top: ${({ margintop }) => margintop};
    margin-bottom: ${({ marginbottom }) => marginbottom};
    height: 1px;
    background: ${colorConstants.gradientHorizontal};
`;