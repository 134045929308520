// Ref. https://react-bootstrap.github.io/components/carousel/
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';
import placeholder from '../../../assets/photo_placeholder.svg';


const Image = styled.img`
    object-fit: ${props => props.height ? 'cover' : 'fill'};
`;

const StyledCarousel = styled(Carousel)`
.carousel-indicators {

    align-items: center;

    button {
        display: none;
        width: 14px;
        height: 14px;
        border-radius: 500px;
        border: none;
    }

    button:first-child, button:last-child {
        display: block;
    }

    button.active {
        display: block;
    }

    /* All items excluding the active */
    button:not(.active) {
        width: 10px;
        height: 10px;
    }

    /* When the first item is the active item, it selects the next item */
    button.active:first-child + button {
        display: block;
    }

    /* When the last item is the active item, it selects the previous item */
     button:nth-last-child(2):has(+ .active) {
        display: block;
    }
  }
`;



/**
 * Carousel Functional Component
 * 
 * @param {BOOLEAN} isFirebase Set true to indicate that the firebase url needs to apend the time miliseconds to force load updated photos
 * @param {STRING} src The array or single string of images to load
 * @param {STRING} name The name of the photo
 * @param {BOOLEAN} controls Set to false to disable the left/right navigation controls
 * @param {BOOLEAN} indicators Set to false to disable the position indicators
 * @param {INTEGER} autoCycle Set to null to disable auto cycle of photos or set the number of seconds for cycle interval
 * @param {INTEGER} lazyLoad Set the number of first images to load - must be above 0 or null to disable lazy load and load all photos instantly
 * @param {BOOLEAN} hardStop Set to true to disable continue cycle and set hard stops
 * @param {INTEGER} height Set the images height
 */
export const CarouselPhoto = ({ isFirebase, src, name, controls, indicators, autoCycle, lazyLoad, hardStop, height }) => {

    const [carouselName, setCarouselName] = useState(name);
    const [photoUrls, setPhotoUrls] = useState([]);
    const [activeImageIndex, setActiveImageIndex] = useState(lazyLoad ? lazyLoad - 1 : null);
    const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
    const [isResetting, setIsResetting] = useState(false);
    const [isSinglePhoto, setIsSinglePhoto] = useState(false);

    // execute when changing image URLs set
    useEffect(() => {
        if(src && Array.isArray(src) && src.length > 0 && name !== carouselName) {
            // save new carousel name
            setCarouselName(name);

            // set resetting to true which disables the transition delay
            if(activeCarouselIndex !== 0) setIsResetting(true);

            // reset carousel position
            setActiveCarouselIndex(0);
        }

    }, [src, name, carouselName, activeCarouselIndex]);

    // execute any formatting to urls
    useEffect(() => {
        if(src && Array.isArray(src) && src.length > 0) {
            // check if single url
            if(src.length === 1) setIsSinglePhoto(true);

            // format urls
            const formattedUrls = src.map((img, index) => {
                let url = (typeof img === 'string') ? img : img.url;
                // append date to force new images to be displayed in carousel
                return `${url}${isFirebase ? '&' : '?'}${Date.now()}${index}`;
            });

            setPhotoUrls(formattedUrls);
        }

    }, [src, isFirebase]);


    const images = useMemo(() => {
        if(isSinglePhoto) return null;

        return photoUrls.map((url, index) => (
            <Carousel.Item key={index} >
                <Image
                    height={height}
                    className="d-block w-100"
                    src={activeImageIndex ? (index <= activeImageIndex ? url : placeholder) : url}
                    alt={name + '-image-' + index}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        // currentTarget.src = new_url_here; // replace url here
                        setPhotoUrls(prev => prev.filter((p, p_index) => p_index !== index )); // remove url that failed
                    }}
                />
            </Carousel.Item>
        ));

    }, [photoUrls, isSinglePhoto, name, height, activeImageIndex]);

    // return single photo
    if(isSinglePhoto) return <Image height={height} className="d-block w-100" src={photoUrls[0]} alt={name + '-image'} key='0' />;

    return <StyledCarousel
                slide={!isResetting} // disable slide transition delay when resetting
                onSlid={() => activeCarouselIndex === 0 ? setIsResetting(false) : null} // end resetting when carousel goes back to first image
                activeIndex={activeCarouselIndex}
                wrap={hardStop ? !hardStop : false}
                controls={controls}
                indicators={indicators}
                interval={autoCycle || null}
                onSelect={(index) => setActiveCarouselIndex(index)}
                onSlide={lazyLoad ? index => setActiveImageIndex(index + (lazyLoad - 1)) : null}
            >
                {images}
            </StyledCarousel>;
};