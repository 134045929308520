import { http, handleResponse, handleError } from "../utils";

export const publicUsersService = {
    // getAll,
    getByUsername,
    // filter
    getUsernameAvailability
};

// async function getAll() {
//     return await http.get("/public/users")
//                     .then((res) => handleResponse(res, false))
//                     .catch((err) => handleError(err, false));
// }

async function getByUsername(username) {
    return await http.get(`/public/users/${username}`)
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

// async function filter(data) {
//     return await http.get(`/public/users?${data.toString()}`)
//                     .then((res) => handleResponse(res, false))
//                     .catch((err) => handleError(err, false));
// }

async function getUsernameAvailability(username) {
    return await http.get(`/public/users/usernameAvailability/${username}`)
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}