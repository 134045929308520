import React from 'react';
import styled from 'styled-components';
import FormControl from 'react-bootstrap/FormControl'
import { Search } from 'react-bootstrap-icons';

import { colorConstants } from "../../../constants";

const GradientBorder = styled.div`
    display: flex;
    align-items: center;
    background: ${colorConstants.gradientHorizontal};
    padding: 1px;
    border-radius: 2px;
    margin-top: 6px;
    margin-bottom: 2px;

    :focus-within {
        background: ${colorConstants.primaryDark};
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    background: white;
`;

const StyledFormControl = styled(FormControl)`
    font-size: 12px;
    font-weight: 500;
    color: ${colorConstants.black};
    padding: 8px;
    border-radius: 2px;
    border-color: ${colorConstants.white};

    :focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }

    ::placeholder {
        color: ${colorConstants.softGrey};
    }
`;

const SearchIcon = styled(Search)`
    padding: 8px;
`;

export const GradientFormInputSearchBox = (props) => {
    return (
        <GradientBorder>
            <Content>
                <StyledFormControl {...props} />
                <SearchIcon color={colorConstants.mediumGrey} size={36} />
            </Content>
        </GradientBorder>
    );
};