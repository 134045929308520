import styled from 'styled-components';

import { colorConstants } from "../../../constants";


export const Container = styled.div`
    height: 100%;
    width: 100%;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1999;

    display: flex;
    justify-content: center;
    align-items: center;   
`;

export const Fader = styled.div`
    height: 100%;
    width: 100%;

    position: fixed;
    
    background: ${props => props.newColor || colorConstants.softGrey};
    opacity: 0.75;
`;