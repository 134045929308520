import styled from 'styled-components';
import { ChevronLeft, Trash, Pencil, ShareFill, Bookmark, BookmarkFill } from 'react-bootstrap-icons';
import { Button } from '../../custom/Buttons';
import { colorConstants, deviceConstants, shadowConstants } from "../../../constants";


export const Container = styled.div`
    padding-bottom: 32px;

    @media ${deviceConstants.tablet} {
        box-shadow: ${shadowConstants.adminDesktop};
        background: ${colorConstants.white};
    }
`;

export const TopBar = styled.div`
    width: 100%;
    padding: 16px 16px 0 16px;

    display: flex;
    align-items: center;

    position: relative;
`;

export const TopBarTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    text-align: center;

    position: absolute;
    left: 0;
    right: 0;
`;

export const BackButton = styled(ChevronLeft)`
    color: ${colorConstants.black};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const PhotoSection = styled.div`
    width: 100%;
    padding-top: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Image = styled.img`
    width: ${props => props.customwidth};
    height: ${props => props.customwidth};
    border-radius: 8px;
    object-fit: cover;
`;

export const HeaderSection = styled.div`
    padding: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const HeaderSectionContent = styled.div`
    margin-bottom: 8px;
    line-height: 1.2;
`;

export const Name = styled.div`
    font-weight: 600;
    font-size: 22px;

    color: ${colorConstants.black};
`;

export const Collection = styled.div`
    font-weight: 500;
    font-size: 14px;

    color: ${colorConstants.primaryDark};
`;

export const HeaderSectionControls = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * {
        margin-right: 16px;
    }

    // & > *:last-child {
    //     margin-right: 0px;
    // }
`;

export const DeleteButton = styled(Trash)`
    color: ${colorConstants.black};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const EditButton = styled(Pencil)`
    color: ${colorConstants.black};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const ShareButton = styled(ShareFill)`
    color: ${colorConstants.black};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const FavorButton = styled(Bookmark)`
    color: ${colorConstants.black};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const UnFavorButton = styled(BookmarkFill)`
    color: ${colorConstants.primaryDark};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.black};
    }
`;


export const ContentSection = styled.div`
    width: 100%;
    padding: 16px 16px;
    color: ${colorConstants.black};

    & > * {
        margin-bottom: 32px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;

export const CaptionSection = styled.div`
    blockquote {
        border-left: 5px solid ${colorConstants.verySoftGrey};
        color: ${colorConstants.mediumGrey};
        font-family: 'Hoefler Text', 'Georgia', serif;
        font-style: italic;
        margin: 16px 0;
        padding: 10px 20px;
    }

    code {
        background-color: ${colorConstants.verySoftGreyVersion2};
        font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
        font-size: 12px;
        padding: 2px;
    }
`;

export const CreatorProfileSection = styled.div`
    margin-bottom: 16px;

    display: flex;
    align-items: center;

    :hover {
        cursor: pointer;
    }
`;

export const CreatorName = styled.div`
    margin-left: 8px;

    font-weight: 500;
    font-size: 14px;

    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

export const Caption = styled.div`
    font-weight: 400;
    font-size: 14px;

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: none;
`;

export const Section = styled.div`
    padding: 16px;
    border: 1px solid ${colorConstants.verySoftGreyVersion2};
    border-radius: 2px;

    font-weight: 500;
    font-size: 14px;
`;

export const SectionTitle = styled.div`
    margin-bottom: 12px;
    padding-bottom: 4px;
    border-bottom: 1px solid ${colorConstants.verySoftGreyVersion2};
    font-weight: 600;
    font-size: 16px;
`;

export const SectionItem = styled.div`
    width: 100%;
    margin-bottom: 4px;
    color: ${colorConstants.darkGrey};

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SectionItemBold = styled.span`
    font-weight: 600;
    color: ${colorConstants.mediumGrey};
`;

export const LinksArea = styled.div`
    margin-top: 12px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > * {
        margin-right: 8px;
    }

    & > *:last-child {
        margin-right: 0;
    }
`;

export const Link = styled.a`
    text-decoration: none;

    :hover {
        color: ${colorConstants.black};
        cursor: pointer;
    }
`;

export const AttributesList = styled.div`
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    & > * {
        margin-bottom: 24px;
    }
`;

export const AttributeContainer = styled.div`
    width: ${props => props.maxwidth || '120px'};
    padding: 16px;
    border-radius: 8px;
    background: ${colorConstants.verySoftGreyVersion2};
    color: ${colorConstants.black};
    font-size: 14px;

    :hover {
        background: ${colorConstants.verySoftGrey};
    }
`;

export const AttributeType = styled.div`
    margin-bottom: 8px;
    font-weight: 500;
`;

export const AttributeValue = styled.div`
    font-weight: 600;
`;

export const SectionSubTitle = styled.div`
    padding: 16px;
    margin-top: 40px;

    font-weight: 700;
    font-size: 20px;
`;

export const ButtonSection = styled.div`
    width: 100%;
    margin-top: 80px;
    margin-bottom: 32px;
    padding: 0 16px;
`;

export const ButtonLink = styled.a`
    width: 100%;
    text-decoration: none;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    border-radius: 4px;
`;

export const PartnerSection = styled.div`
    padding: 16px;
`;

export const PartnerDescriptionSection = styled.div`
    padding-bottom: 16px;
`;