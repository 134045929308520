import React from 'react';
import ContentLoader from "react-content-loader"



export const SearchListSpinner = ({ streaming, ...props }) => {
    return (
        <ContentLoader
            width='100%'
            height={streaming ? 400 : 602}
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="0" y="0" rx="8" ry="8" width="100%" height="190" />
            <rect x="0" y="206" rx="8" ry="8" width="100%" height="190" />
            { !streaming && <rect x="0" y="412" rx="8" ry="8" width="100%" height="190" /> }
        </ContentLoader>
    );
};