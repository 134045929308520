import React, { useMemo } from "react";

import {
    Container,
    Photo,
    ContentColumn,
    Content,
    Name,
    Row
} from './BookingsListItem.styles';
import { StarSystem } from '../custom/Rating';
import { addCurrencySymbol } from '../../utils';


const BookingsListItem = (props) => {
    const { data, handleView, ...rest } = props;

    // format total paid amount
    const formattedAmount = useMemo(() => {
        let price = data.total;
        if(data.taxesAndFeesNotIncludedInPrice) price = price + data.taxesAndFeesNotIncludedInPrice;
        return addCurrencySymbol(data.currencyCode, price / 100);
    }, [data.currencyCode, data.total, data.taxesAndFeesNotIncludedInPrice]);

    return (
        <Container {...rest} >
            <Photo onClick={() => handleView(data)} src={data.photos[0].url} fallbackSrc={data.photos[1].url} />

            <ContentColumn>
                <Content onClick={() => handleView(data)}>
                    <Name onClick={() => handleView(data)}>{data.name}</Name>

                    <StarSystem onClick={() => handleView(data)} rating={data.stars} color='gold' />

                    <Row onClick={() => handleView(data)}>{data.city + ', ' + data.country}</Row>

                    <Row onClick={() => handleView(data)}>{data.start + ' - ' + data.end}</Row>

                    <Row onClick={() => handleView(data)}>{formattedAmount} &#x2022; {data.status}</Row>
                </Content>
            </ContentColumn>
        </Container>
    );
};

export default BookingsListItem;