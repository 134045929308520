import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
    Header,
    Title,
    Body,
    IconsList,
    Button,
    Label,
    ScrollForwardButton,
    ScrollBackButton,
    LinkContainer,
    Link,
    CopyLinkButton
} from './Share.styles';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import Icon from '../../../assets/icons/Icon';


export const Share = (props) => {
    const { title, onHide, show, url, imageUrl } = props;


    /* Observe if first and last list items are visible or not in the screen */

    // create first and last item refs
    const firstItemRef = useRef(null);
    const lastItemRef = useRef(null);

    // create first and last item intersecting state flags
    const [isFirstItemIntersecting, setFirstItemIntersecting] = useState(true);
    const [isLastItemIntersecting, setLastItemIntersecting] = useState(false);

    // observe first and last items
    useEffect(() => {
        // create observers
        const options = { threshold: 0.8 };
        const firstItemObserver = new IntersectionObserver(([entry]) => setFirstItemIntersecting(entry.isIntersecting), options);
        const lastItemObserver = new IntersectionObserver(([entry]) => setLastItemIntersecting(entry.isIntersecting), options);

        // assign items to respective observers
        if(firstItemRef.current && lastItemRef.current) {
            firstItemObserver.observe(firstItemRef.current);
            lastItemObserver.observe(lastItemRef.current);
        }

        // remove the observers as soon as the component is unmounted
        return () => {
            firstItemObserver.disconnect();
            lastItemObserver.disconnect();
        };
    }, [show]);


    /* Create onScroll function listener */

    // create scroll container ref
    const scrollContainerRef = useRef(null);

    // subscribe to scroll events
    useEffect(() => {
        window.addEventListener("wheel", handleScroll);
        // un-subscribe from scroll events
        return () => {
            window.removeEventListener("wheel", handleScroll);
        };
    }, []);

    // function to handle wheel scroll
    function handleScroll(event) {
        if(!scrollContainerRef.current) return;
        const scrollContainer = scrollContainerRef.current;
        if(event.deltaY > 0) scrollContainer.scrollLeft += 20;
        else if(event.deltaY < 0) scrollContainer.scrollLeft += -20;
    }

    // function to handle button scroll
    function handleScrollButtons(forward){
        if(!scrollContainerRef.current) return;
        const scrollContainer = scrollContainerRef.current;
        if(forward) scrollContainer.scrollLeft += 40;
        else scrollContainer.scrollLeft += -40;
    }


    /* Share functions */

    // Method to copy link to clipboard
    function copyToClipboard(){
        navigator.clipboard.writeText(url);
    }

    // Method to share link on Facebook
    function shareOnFacebook(){
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + url);
    }

    // Method to share link on Twitter
    function shareOnTwitter(){
        window.open('https://twitter.com/intent/tweet?url=' + url);
    }

    // Method to share link on WhatsApp
    function shareOnWhatsApp(){
        window.open('https://wa.me?text=' + url);
    }

    // Method to share link via Email
    function shareViaEmail(){
        window.open('mailto:?body=' + url);
    }

    // Method to share link on Reddit
    function shareOnReddit(){
        window.open('https://www.reddit.com/submit?url=' + url);
    }

    // Method to share link on Pinterest
    function shareOnPinterest() {
        // window.open('https://pinterest.com/pin/create/link/?url=' + encodeURIComponent(url) + '&media=' + imageUrl);
        window.open('https://www.pinterest.co.uk/pin-builder/?url=' + encodeURIComponent(url) + '&media=' + imageUrl);
    }

    // Method to share link on LinkedIn
    function shareOnLinkedin(){
        window.open('https://www.linkedin.com/shareArticle/?url=' + encodeURIComponent(url));
    }

    // Method to share link on Tumblr
    function shareOnTumblr(){
        window.open('https://www.tumblr.com/widgets/share/tool?shareSource=legacy&canonicalUrl=&posttype=link&content=' + encodeURIComponent(url) + '&url=' + encodeURIComponent(url));
    }

    // Method to share link on VK
    function shareOnVK(){
        window.open('https://vk.com/share.php?url=' + encodeURIComponent(url));
    }

    // Method to share link on OK
    function shareOnOk(){
        window.open('https://connect.ok.ru/offer?url=' + encodeURIComponent(url) + imageUrl ? '&imageUrl=' + imageUrl : '');
    }


    return (
        <Container show={show} onHide={onHide}>
            <Header closeButton>
                <Title>{title}</Title>
            </Header>

            <Body>
                <ScrollBackButton hide={isFirstItemIntersecting} onClick={() => handleScrollButtons(false)}><ChevronLeft size={16} /></ScrollBackButton>

                <IconsList ref={scrollContainerRef} >
                    <Button onClick={() => shareOnFacebook()} ref={firstItemRef} >
                        <Icon type='social' platform="facebook" size={60} circle={true} color={true} target='_blank' rel='noreferrer' />
                        <Label>Facebook</Label>
                    </Button>
                    <Button onClick={() => shareOnTwitter()}>
                        <Icon type='social' platform="twitter" size={60} circle={true} color={true} target='_blank' rel='noreferrer' />
                        <Label>Twitter</Label>
                    </Button>
                    <Button onClick={() => shareOnWhatsApp()}>
                        <Icon type='social' platform="whatsapp" size={60} circle={true} color={true} target='_blank' rel='noreferrer' />
                        <Label>WhatsApp</Label>
                    </Button>
                    <Button onClick={() => shareViaEmail()}>
                        <Icon type='email' size={60} circle={true} color={true} target='_blank' rel='noreferrer' />
                        <Label>Email</Label>
                    </Button>
                    <Button onClick={() => shareOnReddit()}>
                        <Icon type='social' platform="reddit" size={60} circle={true} color={true} target='_blank' rel='noreferrer' />
                        <Label>Reddit</Label>
                    </Button>
                    {imageUrl &&
                    <Button onClick={() => shareOnPinterest()}>
                        <Icon type='social' platform="pinterest" size={60} circle={true} color={true} target='_blank' rel='noreferrer' />
                        <Label>Pinterest</Label>
                    </Button> }
                    <Button onClick={() => shareOnLinkedin()}>
                        <Icon type='social' platform="linkedin" size={60} circle={true} color={true} target='_blank' rel='noreferrer' />
                        <Label>LinkedIn</Label>
                    </Button>
                    <Button onClick={() => shareOnTumblr()}>
                        <Icon type='social' platform="tumblr" size={60} circle={true} color={true} target='_blank' rel='noreferrer' />
                        <Label>Tumblr</Label>
                    </Button>
                    <Button onClick={() => shareOnVK()}>
                        <Icon type='social' platform="vk" size={60} circle={true} color={true} target='_blank' rel='noreferrer' />
                        <Label>ВКонтакте</Label>
                    </Button>
                    <Button onClick={() => shareOnOk()} ref={lastItemRef} >
                        <Icon type='social' platform="ok" size={60} circle={true} color={true} target='_blank' rel='noreferrer' />
                        <Label>Одноклассники</Label>
                    </Button>
                </IconsList>

                <ScrollForwardButton hide={isLastItemIntersecting} onClick={() => handleScrollButtons(true)}><ChevronRight size={16} /></ScrollForwardButton>
            </Body>

            <LinkContainer>
                <Link>{url}</Link>
                <CopyLinkButton small={true} gradient={true} onClick={copyToClipboard} >COPY</CopyLinkButton>
            </LinkContainer>
      </Container>
    );
};