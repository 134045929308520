/**
 * Refs:
 * 
 * Draggable Resources
 * - https://www.youtube.com/watch?v=y1w6C9A5a2A
 * - https://www.npmjs.com/package/react-beautiful-dnd
 * - https://github.com/AfzalH/react-drag-and-drop-list-re-order/
 */
import React from "react";
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";



const List = styled.div`
`;

export const DraggableList = ({ name, handleReorder, items, listStyles, listItemStyles, CustomListItem, ...props }) => {
    return (
        <DragDropContext
            onDragEnd={(param) => {
                const srcI = param.source.index;
                const desI = param.destination?.index;
                if(handleReorder) handleReorder(srcI, desI);
            }}
        >
            <List style={{ ...listStyles }}>
                <Droppable droppableId={"droppable-" + name}>
                    {(provided, _) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {items.map((item, index) => (
                                <Draggable
                                    key={index}
                                    draggableId={"draggable-" + name + index}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            key={index}
                                            style={{
                                                ...provided.draggableProps.style,
                                                ...listItemStyles,
                                                boxShadow: snapshot.isDragging ? "0 0 .4rem #666" : "none"
                                            }}
                                        >
                                            <CustomListItem data={item} dragHandleProps={provided.dragHandleProps} {...props} />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </List>
        </DragDropContext>
    );
};