

/**
 * Method to serialize hotel booking checkout config request data
 * 
 * @param {JSON Object} data Booking data object
 * @returns JSON Object with serialized booking reservation data
 */
export function hotelBookingCheckoutConfigData(data) {
    let output = {};

    output.createdAt = data.checkRateCreatedAt || data.selectedRoomRate.createdAt;
    output.partnerId = data.partnerId;
    output.creatorId = data.creatorId;
    output.userId = data.userId || -1; // set to -1 for checkout with no guest user account
    output.guestUserId = data.guestUserId;
    output.currencyCode = data.currency.code;
    // partner data
    output.commissionPercentage = data.selectedRoomRate.price.commissionPercentage;
    output.hotelCost = data.selectedRoomRate.price.hotelCost;
    // price breakdown data
    output.basePrice = data.selectedRoomRate.price.basePrice;
    output.taxesAndFees = data.selectedRoomRate.price.taxesAndFees;
    output.taxesAndFeesNotIncludedInPrice = data.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice;
    output.basePriceBeforeCharges = data.selectedRoomRate.price.basePriceBeforeCharges;
    output.platformFee = data.selectedRoomRate.price.platformFee;
    output.vatAmount = data.selectedRoomRate.price.vat.amount;
    output.total = data.selectedRoomRate.price.total;
    // booking data
    output.start = data.dates.from.date;
    output.startFormatted = data.dates.from.label;
    output.end = data.dates.to.date;
    output.endFormatted = data.dates.to.label;
    output.checkInFrom = data.checkInFrom;
    output.checkOutUntil = data.checkOutUntil;
    output.nights = data.dates.totalDays;
    output.adults = data.adults;
    output.children = data.children;
    output.childrenAges = data.childrenAges;
    output.infants = data.infants;
    output.rooms = data.rooms;
    output.doubleBed = data.doubleBedType;
    output.meal = data.mealOptions[data.selectedMeal].type;
    output.roomName = data.selectedRoom.name;
    output.sourceRoomRateId = data.selectedRoomRate.id;
    output.roomOccupancy = data.selectedRoom.maxOccupancy;
    output.roomRateOccupancy = data.selectedRoomRate.maxOccupancy;
    output.cancellationPolicy = data.selectedCancellationPolicy;
    // booking metadata
    if(data.selectedRoomRate.cancellationPolicy && data.selectedRoomRate.cancellationPolicy.components && data.selectedRoomRate.cancellationPolicy.components.length > 0) output.cancellationPolicyComponents = data.selectedRoomRate.cancellationPolicy.components;
    output.notes = [];
    if(data.selectedRoomRate.notes) output.notes.push(data.selectedRoomRate.notes);
    if(data.selectedRoom.notes) output.notes.push(data.selectedRoom.notes);
    if(!output.notes || output.notes.length === 0) output.notes = null; // skip if no notes
    
    return output;
}



/**
 * Method to serialize the hotel booking payment config request data
 * 
 * @param {JSON OBJECT} data The booking estimate configuration data object
 * @param {JSON OBJECT} customer The customer object
 * @param {JSON OBJECT} paymentMethod The selected payment method object
 * @returns 
 */
export function hotelBookingPaymentConfigData(data, customer, paymentMethod, extra) {
    let output = {};

    output.userId = data.userId || -1; // set to -1 for checkout with no guest user account
    output.guestUserId = data.guestUserId;
    output.estimateId = data.id;
    output.estimateSecret = data.estimateSecret;
    output.total = data.total;
    // billing details
    output.name = customer.name;
    output.email = data.guestUserId ? customer.email : null; // send email if guest user account
    output.phone = customer.phone;
    output.addressLine1 = customer.address.addressLine1;
    output.addressLine2 = customer.address.addressLine2;
    output.city = customer.address.city;
    output.state = customer.address.state;
    output.postalCode = customer.address.postalCode;
    output.country = customer.address.country;
    // add extra data
    if(extra) {
        output.registerNewAccount = extra.registerNewAccount;
        if(extra.notes) output.notesFromGuest = extra.notes;
    }
    // payment method info
    output.paymentType = paymentMethod.paymentType;
    output.paymentMethodId = paymentMethod.paymentMethodId;
    output.cardHolderName = paymentMethod.holderName;

    return output;
}