import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { colorConstants, deviceConstants, shadowConstants } from '../../../constants';


export const Container = styled(Modal)`
    height: 100vh;
    padding: 0;
    margin: 0;
    background: ${colorConstants.white};

    .modal-dialog {
        max-width: 100%;
        margin: 0;
    }

    .modal-content {
        margin: 0;
        padding: 0;
        border-radius: 0;
        border: none;
    }
`;

export const Header = styled(Modal.Header)`
    border-bottom: none;
    height: 56px;
`;

export const Title = styled(Modal.Title)`
    margin-right: 32px;

    font-weight: 700;
    font-size: 20px;
    background: ${colorConstants.gradientForText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Body = styled.div`
    // height: calc(100vh - 80px);
    height: calc(100vh - 56px);
    padding: 0px;
    border-radius: 0px;
    background: ${colorConstants.white};
`;

export const SectionSpinner = styled.div`
    margin-top: 100px;
    text-align: center;
    color: ${colorConstants.primaryDark};
`;

export const Marker = styled.div`
    padding: 8px 12px;
    border-radius: 16px;
    
    background: ${({ active }) => active ? colorConstants.darkGrey : colorConstants.white};
    box-shadow: ${shadowConstants.googleMapsMarker};

    color: ${({ active }) => active ? colorConstants.white : colorConstants.black};
    font-weight: 500;
    font-size: 14px;
    text-align: center;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    transform: translate(-50%, -50%);
`;

export const MarkerText = styled.div`
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 5px 0 5px;
    border-color: ${({ active }) => active ? colorConstants.darkGrey : colorConstants.white} transparent transparent transparent;
`;

export const SelectedItemContainer = styled.div`
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;

    position: fixed;
    bottom: 16px;
    left: 0px;

    @media ${deviceConstants.tablet} { 
        padding-left: 33%;
        padding-right: 33%;
    }

    @media ${deviceConstants.laptop} { 
        padding-left: 35%;
        padding-right: 35%;
    }

    @media ${deviceConstants.desktop} { 
        padding-left: 37.5%;
        padding-right: 37.5%;
    }
`;

export const LoadMoreButton = styled.div`
    width: 150px;
    padding: 4px 24px;
    border-radius: 24px;
    
    background: ${colorConstants.white};
    box-shadow: ${shadowConstants.googleMapsMarker};

    color: ${colorConstants.black};
    font-weight: 500;
    font-size: 14px;
    text-align: center;

    position: absolute;
    top: 72px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;

    :hover {
        color: ${colorConstants.primary};
        cursor: pointer;
    }
`;