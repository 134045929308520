import { http, authHeader, handleResponse, handleError } from "../utils";

export const tripService = {
    validate,
    create,
    getAll,
    getById,
    getBySlug,
    update,
    favourite,
    delete: _delete
};

async function validate(data) {
    return await http.post("/trips/validate", data, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function create(data) {
    return await http.post("/trips", data, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function getAll(query) {
    return await http.get(`/trips?${query.toString()}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function getById(id, query) {
    // construct api call url
    let url = `/trips/${id}`;
    if(query) url = url + `?${query.toString()}`
    // call api
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function getBySlug(slug, query) {
    // construct api call url
    let url = `/trips/getBySlug/${slug}`;
    if(query) url = url + `?${query.toString()}`
    // call api
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function update(data) {
    return await http.put(`/trips/${data.id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function favourite(data) {
    return await http.put(`/trips/favourite/${data.id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function _delete(id) {
    return await http.delete(`/trips/${id}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}