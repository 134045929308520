import styled from 'styled-components';
import { Image } from '../custom/Images';
import { colorConstants, deviceConstants, shadowConstants } from "../../constants";


export const Container = styled.div`
    height: 120px;
    display: flex;
    justify-content: flex-start;

    margin-bottom: 16px;

    @media ${deviceConstants.tablet} {
        height: 136px;
        padding: 8px;
        border-radius: 2px;
        box-shadow: ${shadowConstants.adminListItems};
        background: ${colorConstants.white};
    }
`;

export const Photo = styled(Image)`
    width: 120px;
    border-radius: 2px;
    object-fit: cover;

    :hover {
        cursor: pointer;
    }
`;

export const ContentColumn = styled.div`
    width: 100%;
    margin-left: 16px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    position: relative;
`;

export const Content = styled.div`
    position: absolute;
    top: 0;
    bottom: 24px;

    :hover {
        cursor: pointer;
    }
`;

export const Name = styled.h3`
    font-size: 16px;
    font-weight: 700;
    color: ${colorConstants.black};
    margin: 0;

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: none;

    :hover {
        cursor: pointer;
    }
`;

export const Row = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${colorConstants.darkGrey};

    :hover {
        cursor: pointer;
    }
`;