import React from 'react';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

import { colorConstants } from "../../../constants";



const Range = styled(Form.Range)`

    height: 20px;
    width: 100%;
    -webkit-appearance: none;
    
    :focus {
        outline: none;
    }
    

    :focus::-webkit-slider-thumb {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }

    ::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 14px;
        width: 14px;
        border-radius: 15px;
        background: ${colorConstants.primaryDark};
        cursor: grab;
        -webkit-appearance: none;
        margin-top: -6px;
    }

    ::-moz-range-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 14px;
        width: 14px;
        border-radius: 15px;
        background: ${colorConstants.primaryDark};
        cursor: grab;
    }

    ::-ms-thumb {
        margin-top: 1px;
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 14px;
        width: 14px;
        border-radius: 15px;
        background: ${colorConstants.primaryDark};
        cursor: grab;
    }



    :focus::-webkit-slider-runnable-track {
        background: ${colorConstants.gradientHorizontal};
    }

    ::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        box-shadow: 0px 0px 0px #000000;
        background: ${colorConstants.gradientHorizontal};
        border-radius: 13px;
        border: 0px solid #000000;
    }

    ::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        box-shadow: 0px 0px 0px #000000;
        background: ${colorConstants.gradientHorizontal};
        border-radius: 13px;
        border: 0px solid #000000;
    }

    ::-ms-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    
    ::-ms-fill-lower {
        background: ${colorConstants.gradientHorizontal};
        border: 0px solid #000000;
        border-radius: 26px;
        box-shadow: 0px 0px 0px #000000;
    }

    ::-ms-fill-upper {
        background: ${colorConstants.gradientHorizontal};
        border: 0px solid #000000;
        border-radius: 26px;
        box-shadow: 0px 0px 0px #000000;
    }

    :focus::-ms-fill-lower {
        background: ${colorConstants.gradientHorizontal};
    }

    :focus::-ms-fill-upper {
        background: ${colorConstants.gradientHorizontal};
    }
`;


export const Slider = ({ id, min, max, step, value, onChange }) => {

    return <Range name={id + '-slider'} min={min} max={max} step={step} value={value} onChange={onChange} />;
};