import styled from 'styled-components';
import { Trash, Pencil } from 'react-bootstrap-icons';
import { colorConstants, deviceConstants, shadowConstants } from "../../constants";


export const Container = styled.div`
    height: 120px;
    display: flex;
    justify-content: flex-start;
    background: ${colorConstants.white};

    margin-bottom: 16px;

    @media ${deviceConstants.tablet} {
        height: 136px;
        padding: 8px;
        border-radius: 2px;
        box-shadow: ${shadowConstants.adminListItems};
    }

    ${({ issearch }) => issearch && `
        padding: 8px 16px 8px 0px !important;
        background: ${colorConstants.secondaryLight} !important;
        border-radius: 4px !important;
    `}
`;

export const ContentColumn = styled.div`
    width: 100%;
    margin-left: 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Content = styled.div`
    
`;

export const Title = styled.h3`
    font-size: 16px;
    font-weight: 700;
    color: ${colorConstants.black};
    margin: 0;
`;

export const Text = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${colorConstants.darkGrey};
`;

export const ButtonsSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ButtonSectionLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > * {
        margin-right: 8px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const ButtonSectionRight = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * {
        margin-right: 12px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const EditButton = styled(Pencil)`
    padding: 4px;
    color: ${colorConstants.mediumGrey};

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const DeleteButton = styled(Trash)`
    padding: 4px;
    color: ${colorConstants.mediumGrey};

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;