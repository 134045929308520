// home constants for redux reducer
export const homepageReducerConstants = {
    JOIN_WAITLIST_REQUEST: 'JOIN_WAITLIST_REQUEST',
    JOIN_WAITLIST_SUCCESS: 'JOIN_WAITLIST_SUCCESS',
    JOIN_WAITLIST_FAILURE: 'JOIN_WAITLIST_FAILURE'
};


// alert constants for redux reducer
export const alertReducerConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};


// auth constants for redux reducer
export const authReducerConstants = {
    REGISTER_REQUEST: 'AUTH_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'AUTH_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'AUTH_REGISTER_FAILURE',

    LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',
    
    LOGOUT: 'AUTH_LOGOUT',

    LOGIN_WITH_SOCIAL_REQUEST: 'AUTH_LOGIN_WITH_SOCIAL_REQUEST',
    LOGIN_WITH_SOCIAL_SUCCESS: 'AUTH_LOGIN_WITH_SOCIAL_SUCCESS',
    
    UPDATEPASSWORD_REQUEST: 'AUTH_UPDATE_PASSWORD_REQUEST',
    UPDATEPASSWORD_SUCCESS: 'AUTH_UPDATE_PASSWORD_SUCCESS',
    UPDATEPASSWORD_FAILURE: 'AUTH_UPDATE_PASSWORD_FAILURE',

    CONFIRM_EMAIL_REQUEST: 'AUTH_CONFIRM_EMAIL_REQUEST',
    CONFIRM_EMAIL_SUCCESS: 'AUTH_CONFIRM_EMAIL_SUCCESS',
    CONFIRM_EMAIL_FAILURE: 'AUTH_CONFIRM_EMAIL_FAILURE',

    RESET_PASSWORD_REQUEST_REQUEST: 'AUTH_RESET_PASSWORD_REQUEST_REQUEST',
    RESET_PASSWORD_REQUEST_SUCCESS: 'AUTH_RESET_PASSWORD_REQUEST_SUCCESS',
    RESET_PASSWORD_REQUEST_FAILURE: 'AUTH_RESET_PASSWORD_REQUEST_FAILURE',

    RESET_PASSWORD_REQUEST: 'AUTH_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'AUTH_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'AUTH_RESET_PASSWORD_FAILURE',

    LOAD_CSRF_TOKEN_SUCCESS: 'AUTH_LOAD_CSRF_TOKEN_SUCCESS',

    CLEAR_ALL: 'AUTH_CLEAR_ALL'
};


// user constants for redux reducer
export const userReducerConstants = {
    GETBYID_REQUEST: 'USERS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'USERS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'USERS_GETBYID_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    CREATOR_STATUS_REQUEST: 'USERS_CREATOR_STATUS_REQUEST',
    CREATOR_STATUS_SUCCESS: 'USERS_CREATOR_STATUS_SUCCESS',
    CREATOR_STATUS_FAILURE: 'USERS_CREATOR_STATUS_FAILURE',

    // BECOME_CREATOR_REQUEST: 'USERS_BECOME_CREATOR_REQUEST',
    // BECOME_CREATOR_SUCCESS: 'USERS_BECOME_CREATOR_SUCCESS',
    // BECOME_CREATOR_FAILURE: 'USERS_BECOME_CREATOR_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    CLEAR_ALL: 'USERS_CLEAR_ALL'
};


// link constants for redux reducer
export const linkReducerConstants = {
    CREATE_REQUEST: 'LINKS_CREATE_REQUEST',
    CREATE_SUCCESS: 'LINKS_CREATE_SUCCESS',
    CREATE_FAILURE: 'LINKS_CREATE_FAILURE',

    GETALL_REQUEST: 'LINKS_GETALL_REQUEST',
    GETALL_SUCCESS: 'LINKS_GETALL_SUCCESS',
    GETALL_FAILURE: 'LINKS_GETALL_FAILURE',

    GETBYID_REQUEST: 'LINKS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'LINKS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'LINKS_GETBYID_FAILURE',

    UPDATE_REQUEST: 'LINKS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'LINKS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'LINKS_UPDATE_FAILURE',

    DELETE_REQUEST: 'LINKS_DELETE_REQUEST',
    DELETE_SUCCESS: 'LINKS_DELETE_SUCCESS',
    DELETE_FAILURE: 'LINKS_DELETE_FAILURE',

    CLEAR_ALL: 'LINKS_CLEAR_ALL'
};


// trip constants for redux reducer
export const tripReducerConstants = {
    VALIDATE_REQUEST: 'TRIPS_VALIDATE_REQUEST',
    VALIDATE_SUCCESS: 'TRIPS_VALIDATE_SUCCESS',
    VALIDATE_FAILURE: 'TRIPS_VALIDATE_FAILURE',

    CREATE_REQUEST: 'TRIPS_CREATE_REQUEST',
    CREATE_SUCCESS: 'TRIPS_CREATE_SUCCESS',
    CREATE_FAILURE: 'TRIPS_CREATE_FAILURE',

    GETALL_REQUEST: 'TRIPS_GETALL_REQUEST',
    GETALL_SUCCESS: 'TRIPS_GETALL_SUCCESS',
    GETALL_FAILURE: 'TRIPS_GETALL_FAILURE',

    GETBYID_REQUEST: 'TRIPS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'TRIPS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'TRIPS_GETBYID_FAILURE',

    GETBYSLUG_REQUEST: 'TRIPS_GETBYSLUG_REQUEST',
    GETBYSLUG_SUCCESS: 'TRIPS_GETBYSLUG_SUCCESS',
    GETBYSLUG_FAILURE: 'TRIPS_GETBYSLUG_FAILURE',

    UPDATE_REQUEST: 'TRIPS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'TRIPS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'TRIPS_UPDATE_FAILURE',

    FAVOURITE_REQUEST: 'TRIPS_FAVOURITE_REQUEST',
    FAVOURITE_SUCCESS: 'TRIPS_FAVOURITE_SUCCESS',
    FAVOURITE_FAILURE: 'TRIPS_FAVOURITE_FAILURE',

    DELETE_REQUEST: 'TRIPS_DELETE_REQUEST',
    DELETE_SUCCESS: 'TRIPS_DELETE_SUCCESS',
    DELETE_FAILURE: 'TRIPS_DELETE_FAILURE',

    CLEAR_ALL: 'TRIPS_CLEAR_ALL'
};


// hotel constants for redux reducer
export const hotelReducerConstants = {
    FIND_REQUEST: 'HOTELS_FIND_REQUEST',
    FIND_SUCCESS: 'HOTELS_FIND_SUCCESS',
    FIND_FAILURE: 'HOTELS_FIND_FAILURE',

    GETALL_REQUEST: 'HOTELS_GETALL_REQUEST',
    GETALL_SUCCESS: 'HOTELS_GETALL_SUCCESS',
    GETALL_FAILURE: 'HOTELS_GETALL_FAILURE',

    GETBYID_REQUEST: 'HOTELS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'HOTELS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'HOTELS_GETBYID_FAILURE',

    GETAVAILABILITY_REQUEST: 'HOTELS_GETAVAILABILITY_REQUEST',
    GETAVAILABILITY_SUCCESS: 'HOTELS_GETAVAILABILITY_SUCCESS',
    GETAVAILABILITY_FAILURE: 'HOTELS_GETAVAILABILITY_FAILURE',

    CHECKRATE_REQUEST: 'HOTELS_CHECKRATE_REQUEST',
    CHECKRATE_SUCCESS: 'HOTELS_CHECKRATE_SUCCESS',
    CHECKRATE_FAILURE: 'HOTELS_CHECKRATE_FAILURE',

    CLEAR_ALL: 'HOTELS_CLEAR_ALL',
    
    CLEAR_CHECKRATE: 'HOTELS_CLEAR_CHECKRATE',
};


// public hotel constants for redux reducer
export const publicHotelReducerConstants = {
    FIND_ALL_BY_LOCATION_REQUEST: 'PUBLIC_HOTELS_FIND_ALL_BY_LOCATION_REQUEST',
    FIND_ALL_BY_LOCATION_SUCCESS: 'PUBLIC_HOTELS_FIND_ALL_BY_LOCATION_SUCCESS',
    FIND_ALL_BY_LOCATION_FAILURE: 'PUBLIC_HOTELS_FIND_ALL_BY_LOCATION_FAILURE',

    GET_SEARCH_RESULTS_BY_SESSION_REQUEST: 'PUBLIC_HOTELS_GET_SEARCH_RESULTS_BY_SESSION_REQUEST',
    GET_SEARCH_RESULTS_BY_SESSION_SUCCESS: 'PUBLIC_HOTELS_GET_SEARCH_RESULTS_BY_SESSION_SUCCESS',
    GET_SEARCH_RESULTS_BY_SESSION_FAILURE: 'PUBLIC_HOTELS_GET_SEARCH_RESULTS_BY_SESSION_FAILURE',

    GET_BY_PARTNER_ID_REQUEST: 'PUBLIC_HOTELS_GET_BY_PARTNER_ID_REQUEST',
    GET_BY_PARTNER_ID_SUCCESS: 'PUBLIC_HOTELS_GET_BY_PARTNER_ID_SUCCESS',
    GET_BY_PARTNER_ID_FAILURE: 'PUBLIC_HOTELS_GET_BY_PARTNER_ID_FAILURE',

    GETBYSLUG_REQUEST: 'PUBLIC_HOTELS_GETBYSLUG_REQUEST',
    GETBYSLUG_SUCCESS: 'PUBLIC_HOTELS_GETBYSLUG_SUCCESS',
    GETBYSLUG_FAILURE: 'PUBLIC_HOTELS_GETBYSLUG_FAILURE',

    CLEAR_SEARCH_SESSION: 'PUBLIC_HOTELS_CLEAR_SEARCH_SESSION',

    CLEAR_HOTEL_DETAILS: 'PUBLIC_HOTELS_CLEAR_HOTEL_DETAILS',

    CLEAR_ALL: 'PUBLIC_HOTELS_CLEAR_ALL',
};


// hotel booking constants for redux reducer
export const hotelBookingReducerConstants = {
    CHECKOUT_CONFIG_REQUEST: 'HOTEL_BOOKING_CHECKOUT_CONFIG_REQUEST',
    CHECKOUT_CONFIG_SUCCESS: 'HOTEL_BOOKING_CHECKOUT_CONFIG_SUCCESS',
    CHECKOUT_CONFIG_FAILURE: 'HOTEL_BOOKING_CHECKOUT_CONFIG_FAILURE',

    CHECKOUT_CONFIG_WITH_COUPON_REQUEST: 'HOTEL_BOOKING_CHECKOUT_CONFIG_WITH_COUPON_REQUEST',
    CHECKOUT_CONFIG_WITH_COUPON_SUCCESS: 'HOTEL_BOOKING_CHECKOUT_CONFIG_WITH_COUPON_SUCCESS',
    CHECKOUT_CONFIG_WITH_COUPON_FAILURE: 'HOTEL_BOOKING_CHECKOUT_CONFIG_WITH_COUPON_FAILURE',

    PAYMENT_CONFIG_REQUEST: 'HOTEL_BOOKING_PAYMENT_CONFIG_REQUEST',
    PAYMENT_CONFIG_SUCCESS: 'HOTEL_BOOKING_PAYMENT_CONFIG_SUCCESS',
    PAYMENT_CONFIG_FAILURE: 'HOTEL_BOOKING_PAYMENT_CONFIG_FAILURE',

    GETALL_REQUEST: 'HOTEL_BOOKING_GETALL_REQUEST',
    GETALL_SUCCESS: 'HOTEL_BOOKING_GETALL_SUCCESS',
    GETALL_FAILURE: 'HOTEL_BOOKING_GETALL_FAILURE',

    GETBYID_REQUEST: 'HOTEL_BOOKING_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'HOTEL_BOOKING_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'HOTEL_BOOKING_GETBYID_FAILURE',

    SENDCONFIRMATION_REQUEST: 'HOTEL_BOOKING_SENDCONFIRMATION_REQUEST',
    SENDCONFIRMATION_SUCCESS: 'HOTEL_BOOKING_SENDCONFIRMATION_SUCCESS',
    SENDCONFIRMATION_FAILURE: 'HOTEL_BOOKING_SENDCONFIRMATION_FAILURE',

    CANCEL_REQUEST: 'HOTEL_BOOKING_CANCEL_REQUEST',
    CANCEL_SUCCESS: 'HOTEL_BOOKING_CANCEL_SUCCESS',
    CANCEL_FAILURE: 'HOTEL_BOOKING_CANCEL_FAILURE',

    RESET_CHECKOUT_CONFIG: 'HOTEL_BOOKING_RESET_CHECKOUT_CONFIG',
    CLEAR_ALL: 'HOTEL_BOOKING_CLEAR_ALL'
};


// travel agents constants for redux reducer
export const travelAgencyReducerConstants = {
    GETALL_REQUEST: 'TRAVEL_AGENCIES_GETALL_REQUEST',
    GETALL_SUCCESS: 'TRAVEL_AGENCIES_GETALL_SUCCESS',
    GETALL_FAILURE: 'TRAVEL_AGENCIES_GETALL_FAILURE',

    GETBYID_REQUEST: 'TRAVEL_AGENCIES_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'TRAVEL_AGENCIES_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'TRAVEL_AGENCIES_GETBYID_FAILURE',

    CLEAR_ALL: 'TRAVEL_AGENCIES_CLEAR_ALL'
};


// public users constants for redux reducer
export const publicUsersReducerConstants = {
    GETALL_REQUEST: 'PUBLIC_USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PUBLIC_USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PUBLIC_USERS_GETALL_FAILURE',

    GETBYUSERNAME_REQUEST: 'PUBLIC_USERS_GETBYUSERNAME_REQUEST',
    GETBYUSERNAME_SUCCESS: 'PUBLIC_USERS_GETBYUSERNAME_SUCCESS',
    GETBYUSERNAME_FAILURE: 'PUBLIC_USERS_GETBYUSERNAME_FAILURE',

    FILTER_REQUEST: 'PUBLIC_USERS_FILTER_REQUEST',
    FILTER_SUCCESS: 'PUBLIC_USERS_FILTER_SUCCESS',
    FILTER_FAILURE: 'PUBLIC_USERS_FILTER_FAILURE',

    USERNAME_AVAILABILITY_REQUEST: 'PUBLIC_USERS_USERNAME_AVAILABILITY_REQUEST',
    USERNAME_AVAILABILITY_SUCCESS: 'PUBLIC_USERS_USERNAME_AVAILABILITY_SUCCESS',
    USERNAME_AVAILABILITY_FAILURE: 'PUBLIC_USERS_USERNAME_AVAILABILITY_FAILURE',

    RESET_ALL: 'PUBLIC_USERS_RESET_ALL'
};


// public links constants for redux reducer
export const publicLinksReducerConstants = {
    GETALL_REQUEST: 'PUBLIC_LINKS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PUBLIC_LINKS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PUBLIC_LINKS_GETALL_FAILURE',

    FILTER_REQUEST: 'PUBLIC_LINKS_FILTER_REQUEST',
    FILTER_SUCCESS: 'PUBLIC_LINKS_FILTER_SUCCESS',
    FILTER_FAILURE: 'PUBLIC_LINKS_FILTER_FAILURE',

    RESET_ALL: 'PUBLIC_LINKS_RESET_ALL'
};


// public trips constants for redux reducer
export const publicTripsReducerConstants = {
    GETALL_REQUEST: 'PUBLIC_TRIPS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PUBLIC_TRIPS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PUBLIC_TRIPS_GETALL_FAILURE',

    GETBYSLUG_REQUEST: 'PUBLIC_TRIPS_GETBYSLUG_REQUEST',
    GETBYSLUG_SUCCESS: 'PUBLIC_TRIPS_GETBYSLUG_SUCCESS',
    GETBYSLUG_FAILURE: 'PUBLIC_TRIPS_GETBYSLUG_FAILURE',

    FILTER_REQUEST: 'PUBLIC_TRIPS_FILTER_REQUEST',
    FILTER_SUCCESS: 'PUBLIC_TRIPS_FILTER_SUCCESS',
    FILTER_FAILURE: 'PUBLIC_TRIPS_FILTER_FAILURE',

    RESET_ALL: 'PUBLIC_TRIPS_RESET_ALL'
};


// customer constants for redux reducer
export const customerReducerConstants = {
    GETBYUSERID_REQUEST: 'CUSTOMERS_GETBYUSERID_REQUEST',
    GETBYUSERID_SUCCESS: 'CUSTOMERS_GETBYUSERID_SUCCESS',
    GETBYUSERID_FAILURE: 'CUSTOMERS_GETBYUSERID_FAILURE',

    UPDATE_REQUEST: 'CUSTOMERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CUSTOMERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CUSTOMERS_UPDATE_FAILURE',

    CLEAR_ALL: 'CUSTOMERS_CLEAR_ALL'
};


// subscription constants for redux reducer
export const subscriptionReducerConstants = {
    GET_PLANS_REQUEST: 'SUBSCRIPTIONS_GET_PLANS_REQUEST',
    GET_PLANS_SUCCESS: 'SUBSCRIPTIONS_GET_PLANS_SUCCESS',
    GET_PLANS_FAILURE: 'SUBSCRIPTIONS_GET_PLANS_FAILURE',

    CHECKOUT_CONFIG_REQUEST: 'SUBSCRIPTIONS_CHECKOUT_CONFIG_REQUEST',
    CHECKOUT_CONFIG_SUCCESS: 'SUBSCRIPTIONS_CHECKOUT_CONFIG_SUCCESS',
    CHECKOUT_CONFIG_FAILURE: 'SUBSCRIPTIONS_CHECKOUT_CONFIG_FAILURE',

    GETBY_USERID_REQUEST: 'SUBSCRIPTIONS_GETBY_USERID_REQUEST',
    GETBY_USERID_SUCCESS: 'SUBSCRIPTIONS_GETBY_USERID_SUCCESS',
    GETBY_USERID_FAILURE: 'SUBSCRIPTIONS_GETBY_USERID_FAILURE',
    
    CREATE_REQUEST: 'SUBSCRIPTIONS_CREATE_REQUEST',
    CREATE_SUCCESS: 'SUBSCRIPTIONS_CREATE_SUCCESS',
    CREATE_FAILURE: 'SUBSCRIPTIONS_CREATE_FAILURE',

    UPDATE_REQUEST: 'SUBSCRIPTIONS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SUBSCRIPTIONS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SUBSCRIPTIONS_UPDATE_FAILURE',

    CANCEL_REQUEST: 'SUBSCRIPTIONS_CANCEL_REQUEST',
    CANCEL_SUCCESS: 'SUBSCRIPTIONS_CANCEL_SUCCESS',
    CANCEL_FAILURE: 'SUBSCRIPTIONS_CANCEL_FAILURE',

    GET_COUPON_REQUEST: 'SUBSCRIPTIONS_GET_COUPON_REQUEST',
    GET_COUPON_SUCCESS: 'SUBSCRIPTIONS_GET_COUPON_SUCCESS',
    GET_COUPON_FAILURE: 'SUBSCRIPTIONS_GET_COUPON_FAILURE',

    CLEAR_ALL: 'SUBSCRIPTIONS_CLEAR_ALL'
};


// payment metho constants for redux reducer
export const paymentMethodReducerConstants = {
    GETALL_REQUEST: 'PAYMENT_METHODS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PAYMENT_METHODS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PAYMENT_METHODS_GETALL_FAILURE',

    UPDATE_REQUEST: 'PAYMENT_METHODS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PAYMENT_METHODS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PAYMENT_METHODS_UPDATE_FAILURE',

    CLEAR_ALL: 'PAYMENT_METHODS_CLEAR_ALL',
};


// payment metho constants for redux reducer
export const creatorPayoutReducerConstants = {
    GET_EARNINGS_STATS_BYID_REQUEST: 'CREATOR_PAYOUT_GET_EARNINGS_STATS_BYID_REQUEST',
    GET_EARNINGS_STATS_BYID_SUCCESS: 'CREATOR_PAYOUT_GET_EARNINGS_STATS_BYID_SUCCESS',
    GET_EARNINGS_STATS_BYID_FAILURE: 'CREATOR_PAYOUT_GET_EARNINGS_STATS_BYID_FAILURE',

    CLEAR_ALL: 'CREATOR_PAYOUT_CLEAR_ALL',
};


// product constants for redux reducer
export const productReducerConstants = {
    CREATE_REQUEST: 'PRODUCTS_CREATE_REQUEST',
    CREATE_SUCCESS: 'PRODUCTS_CREATE_SUCCESS',
    CREATE_FAILURE: 'PRODUCTS_CREATE_FAILURE',

    GETALL_REQUEST: 'PRODUCTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PRODUCTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PRODUCTS_GETALL_FAILURE',

    GETBYID_REQUEST: 'PRODUCTS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'PRODUCTS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'PRODUCTS_GETBYID_FAILURE',

    GETBYSLUG_REQUEST: 'PRODUCTS_GETBYSLUG_REQUEST',
    GETBYSLUG_SUCCESS: 'PRODUCTS_GETBYSLUG_SUCCESS',
    GETBYSLUG_FAILURE: 'PRODUCTS_GETBYSLUG_FAILURE',

    UPDATE_REQUEST: 'PRODUCTS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PRODUCTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PRODUCTS_UPDATE_FAILURE',

    FAVOURITE_REQUEST: 'PRODUCTS_FAVOURITE_REQUEST',
    FAVOURITE_SUCCESS: 'PRODUCTS_FAVOURITE_SUCCESS',
    FAVOURITE_FAILURE: 'PRODUCTS_FAVOURITE_FAILURE',

    DELETE_REQUEST: 'PRODUCTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'PRODUCTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'PRODUCTS_DELETE_FAILURE',

    CLEAR_ALL: 'PRODUCTS_CLEAR_ALL'
};


// public products constants for redux reducer
export const publicProductsReducerConstants = {
    GETALL_REQUEST: 'PUBLIC_PRODUCTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PUBLIC_PRODUCTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PUBLIC_PRODUCTS_GETALL_FAILURE',

    GETBYSLUG_REQUEST: 'PUBLIC_PRODUCTS_GETBYSLUG_REQUEST',
    GETBYSLUG_SUCCESS: 'PUBLIC_PRODUCTS_GETBYSLUG_SUCCESS',
    GETBYSLUG_FAILURE: 'PUBLIC_PRODUCTS_GETBYSLUG_FAILURE',

    FILTER_REQUEST: 'PUBLIC_PRODUCTS_FILTER_REQUEST',
    FILTER_SUCCESS: 'PUBLIC_PRODUCTS_FILTER_SUCCESS',
    FILTER_FAILURE: 'PUBLIC_PRODUCTS_FILTER_FAILURE',

    RESET_ALL: 'PUBLIC_PRODUCTS_RESET_ALL'
};


// public overview constants for redux reducer
export const publicOverviewReducerConstants = {
    GET_REQUEST: 'PUBLIC_OVERVIEW_GET_REQUEST',
    GET_SUCCESS: 'PUBLIC_OVERVIEW_GET_SUCCESS',
    GET_FAILURE: 'PUBLIC_OVERVIEW_GET_FAILURE',

    RESET_ALL: 'PUBLIC_OVERVIEW_RESET_ALL'
};


// crypto constants for redux reducer
export const cryptoWalletReducerConstants = {
    WALLET_CONNECT_REQUEST: 'WALLET_CONNECT_REQUEST',
    WALLET_CONNECT_SUCCESS: 'WALLET_CONNECT_SUCCESS',
    WALLET_CONNECT_FAILURE: 'WALLET_CONNECT_FAILURE',

    WALLET_DISCONNECT_REQUEST: 'WALLET_DISCONNECT_REQUEST',
    WALLET_DISCONNECT_SUCCESS: 'WALLET_DISCONNECT_SUCCESS',
    WALLET_DISCONNECT_FAILURE: 'WALLET_DISCONNECT_FAILURE',

    WALLET_GETALL_REQUEST: 'WALLET_GETALL_REQUEST',
    WALLET_GETALL_SUCCESS: 'WALLET_GETALL_SUCCESS',
    WALLET_GETALL_FAILURE: 'WALLET_GETALL_FAILURE',

    WALLET_GET_ASSETS_REQUEST: 'WALLET_GET_ASSETS_REQUEST',
    WALLET_GET_ASSETS_SUCCESS: 'WALLET_GET_ASSETS_SUCCESS',
    WALLET_GET_ASSETS_FAILURE: 'WALLET_GET_ASSETS_FAILURE',

    WALLET_CLEAR_ALL: 'WALLET_CLEAR_ALL',

    WALLET_ASSETS_CLEAR_ALL: 'WALLET_ASSETS_CLEAR_ALL',
};


// nft constants for redux reducer
export const nftReducerConstants = {
    CREATE_REQUEST: 'NFT_CREATE_REQUEST',
    CREATE_SUCCESS: 'NFT_CREATE_SUCCESS',
    CREATE_FAILURE: 'NFT_CREATE_FAILURE',

    GETALL_REQUEST: 'NFT_GETALL_REQUEST',
    GETALL_SUCCESS: 'NFT_GETALL_SUCCESS',
    GETALL_FAILURE: 'NFT_GETALL_FAILURE',

    GETBYID_REQUEST: 'NFT_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'NFT_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'NFT_GETBYID_FAILURE',

    GETBYSLUG_REQUEST: 'NFT_GETBYSLUG_REQUEST',
    GETBYSLUG_SUCCESS: 'NFT_GETBYSLUG_SUCCESS',
    GETBYSLUG_FAILURE: 'NFT_GETBYSLUG_FAILURE',

    UPDATE_REQUEST: 'NFT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'NFT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'NFT_UPDATE_FAILURE',

    FAVOURITE_REQUEST: 'NFT_FAVOURITE_REQUEST',
    FAVOURITE_SUCCESS: 'NFT_FAVOURITE_SUCCESS',
    FAVOURITE_FAILURE: 'NFT_FAVOURITE_FAILURE',

    DELETE_REQUEST: 'NFT_DELETE_REQUEST',
    DELETE_SUCCESS: 'NFT_DELETE_SUCCESS',
    DELETE_FAILURE: 'NFT_DELETE_FAILURE',

    CLEAR_ALL: 'NFT_CLEAR_ALL'
};

// public NFT constants for redux reducer
export const nftPublicReducerConstants = {
    GETALL_REQUEST: 'NFT_PUBLIC_GETALL_REQUEST',
    GETALL_SUCCESS: 'NFT_PUBLIC_GETALL_SUCCESS',
    GETALL_FAILURE: 'NFT_PUBLIC_GETALL_FAILURE',

    GETBYSLUG_REQUEST: 'NFT_PUBLIC_GETBYSLUG_REQUEST',
    GETBYSLUG_SUCCESS: 'NFT_PUBLIC_GETBYSLUG_SUCCESS',
    GETBYSLUG_FAILURE: 'NFT_PUBLIC_GETBYSLUG_FAILURE',

    FILTER_REQUEST: 'NFT_PUBLIC_FILTER_REQUEST',
    FILTER_SUCCESS: 'NFT_PUBLIC_FILTER_SUCCESS',
    FILTER_FAILURE: 'NFT_PUBLIC_FILTER_FAILURE',

    RESET_ALL: 'NFT_PUBLIC_RESET_ALL'
};