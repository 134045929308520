import axios from "axios";

const http = axios.create({
    baseURL: 'https://api.github.com',
    headers: {
        "Content-type": "application/json"
    }
});

export const githubService = {
    getRepos,
};


// https://api.github.com/users/xnorcode/repos
async function getRepos(data){
    return await http.get(`/users/${data.username}/repos`)
                    .then((res) => {
                        return res.data.map(repo => {
                            return {
                                id: repo.id,
                                label: repo.name,
                                url: repo.html_url,
                                description: repo.description,
                                githubProgrammingLanguage: repo.language,
                            };
                        });
                    })
                    .catch((err) => []);
}