import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";

// This method is used by the redux store to create centralized redux
// state store for the entire react application
export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...[thunkMiddleware]))
);