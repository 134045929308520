import React, { useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
    Container, TopBar, TopBarTitle, BackButton,
    PhotoSection, Image,
    HeaderSection, HeaderSectionContent, Name, Collection,
    HeaderSectionControls, DeleteButton, EditButton, ShareButton, FavorButton, UnFavorButton,
    ContentSection, Section, SectionTitle, SectionItem, SectionItemBold,
    LinksArea, Link,
    AttributesList, AttributeContainer, AttributeType, AttributeValue,
    CaptionSection, CreatorProfileSection, CreatorName, Caption,
    SectionSubTitle,
    ButtonSection, ButtonLink, StyledButton,
    PartnerSection,
    PartnerDescriptionSection
} from './NFTPage.styles';
import { Avatar } from '../../custom/Avatar';
import Icon from '../../../assets/icons/Icon';
import { useWindowSize, getCoinbaseUrl, getOpenseaUrl, getNFTContractUrl, obfuscateAddress } from '../../../utils';


export const NFTPage = ({
    authenticated,
    isLinkCreator,
    nft,
    isFav,
    handleBackButton,
    handleDelete,
    handleEdit,
    handleShare,
    handleFavor,
    handleUnFavor,
    handleViewCreatorProfile
}) => {

    // detect screen size
    const [width] = useWindowSize();

    // calculate max Attribute card width
    const maxAttrCardWidth = useMemo(() => {
        if(width < 991) return (width - (3 * 24) - 8) / 2; // for mobile
        
        // calculate app section minus the padding on larger screens
        let section = 0;
        if(width >= 991 && width < 1440) {
            section = width - (width * 0.66); // for tablet
        } else if(width >= 1440 && width < 1600) {
            section = width - (width * 0.70); // for laptop
        } else {
            section = width - (width * 0.75); // for laptop
        }

        return (section - (3 * 24) - 16) / 2;
    }, [width]);

    // calculate image width
    const maxImageWidth = useMemo(() => {
        if(width < 991) return width - 32; // for mobile
        
        // calculate app section minus the padding on larger screens
        let section = 0;
        if(width >= 991 && width < 1440) {
            section = width - (width * 0.66); // for tablet
        } else if(width >= 1440 && width < 1600) {
            section = width - (width * 0.70); // for laptop
        } else {
            section = width - (width * 0.75); // for laptop
        }

        return section - 32;
    }, [width]);


    const openseaUrl = useMemo(() => {
        if(nft.contract && nft.chainTokenId) return getOpenseaUrl(nft.contract, nft.chainTokenId, nft.blockchain);
        return null;
    }, [nft]);

    const coinbaseUrl = useMemo(() => {
        if(nft.contract && nft.chainTokenId) return getCoinbaseUrl(nft.contract, nft.chainTokenId, nft.blockchain);
        return null;
    }, [nft]);

    const hasAttrs = nft && nft.attributes && nft.attributes.length > 0;
    let attrsList = null;
    if(hasAttrs) {
        attrsList = nft.attributes.map((item, index) => {
            return (
                <AttributeContainer key={index} maxwidth={maxAttrCardWidth + 'px'} >
                    <AttributeType>{item.type}</AttributeType>
                    <AttributeValue>{item.value}</AttributeValue>
                </AttributeContainer>
            );
        });
    }

    const hasPartner = nft.partner && nft.partner.id;

    const hasCustomLink = !hasPartner && nft.button && nft.button.label && nft.button.url;

    const hasOpenseaLink = !hasPartner && !hasCustomLink && openseaUrl;

    const hasGoogleSearchLink = !hasPartner && !hasCustomLink && !hasOpenseaLink && nft.name;

    return (
        <Container>
            { authenticated &&
            <TopBar>
                <BackButton onClick={handleBackButton} size={18} />
                <TopBarTitle>Preview</TopBarTitle>
            </TopBar> }

            <PhotoSection>
                <Image src={nft.image} customwidth={maxImageWidth + 'px'} />
            </PhotoSection>

            <HeaderSection>
                <HeaderSectionContent>
                    <Name>{nft.name}</Name>
                    {nft.collection && <Collection>{nft.collection}</Collection>}
                </HeaderSectionContent>

                <HeaderSectionControls>
                    { authenticated && isLinkCreator && <DeleteButton onClick={handleDelete} /> }
                    { authenticated && isLinkCreator && <EditButton onClick={handleEdit} /> }
                    <ShareButton onClick={handleShare} />
                    {!isFav && !isLinkCreator && <FavorButton onClick={handleFavor} />}
                    {isFav && !isLinkCreator && <UnFavorButton onClick={handleUnFavor} />}
                </HeaderSectionControls>
            </HeaderSection>

            <ContentSection>
                {nft.caption &&
                <CaptionSection>
                    <CreatorProfileSection onClick={() => handleViewCreatorProfile(nft.creator.username)} >
                        <Avatar imageSize={30} url={nft.creator.profilePhotoUrl} cursor='pointer' />
                        <CreatorName>{nft.creator.displayName || nft.creator.username}</CreatorName>
                    </CreatorProfileSection>

                    <Caption>{ReactHtmlParser(nft.caption)}</Caption>
                </CaptionSection>}

                <Section>
                    <SectionTitle>Description</SectionTitle>
                    {nft.description && <SectionItem>{nft.description}</SectionItem>}

                    <LinksArea>
                        { nft.websiteUrl &&
                        <Link href={nft.websiteUrl} target='_blank' rel='noreferrer'>
                            <Icon type='website' size={16} />
                        </Link> }

                        { openseaUrl &&
                        <Link href={openseaUrl} target='_blank' rel='noreferrer'>
                            <Icon type='crypto' platform='opensea' circle={true} color={true} size={16} />
                        </Link> }

                        { coinbaseUrl &&
                        <Link href={coinbaseUrl} target='_blank' rel='noreferrer'>
                            <Icon type='crypto' platform='coinbase' color={true} size={16} />
                        </Link> }

                        { nft.contract &&
                        <Link href={getNFTContractUrl(nft.contract, nft.blockchain)} target='_blank' rel='noreferrer'>
                            <Icon type='crypto' platform='etherscan' circle={true} color={true} size={16} />
                        </Link> }
                    </LinksArea>
                </Section>

                { nft.collection &&
                <Section>
                    <SectionTitle>Collection</SectionTitle>

                    <SectionItem>
                        <SectionItemBold>{nft.collection}</SectionItemBold>
                    </SectionItem>
                </Section> }

                { hasAttrs &&
                <Section>
                    <SectionTitle>Attributes</SectionTitle>

                    <AttributesList>
                        {attrsList}
                    </AttributesList>
                </Section> }

                <Section>
                    <SectionTitle>Details</SectionTitle>

                    { nft.contract &&
                    <SectionItem>
                        <SectionItemBold>Contract Address</SectionItemBold>
                        <Link href={getNFTContractUrl(nft.contract, nft.blockchain)} target='_blank' rel='noreferrer'>
                            {obfuscateAddress(nft.contract)}
                        </Link>
                    </SectionItem> }

                    { nft.chainTokenId &&
                    <SectionItem>
                        <SectionItemBold>Token ID</SectionItemBold>
                        <Link href={nft.metadataUrl} target='_blank' rel='noreferrer'>
                            {nft.chainTokenIdShort || nft.chainTokenId}
                        </Link>
                    </SectionItem> }

                    { nft.contractType &&
                    <SectionItem>
                        <SectionItemBold>Token Standard</SectionItemBold>
                        {nft.contractType}
                    </SectionItem> }

                    { nft.blockchain &&
                    <SectionItem>
                        <SectionItemBold>Blockchain</SectionItemBold>
                        {nft.blockchain}
                    </SectionItem> }
                </Section>
            </ContentSection>

            { hasOpenseaLink &&
            <ButtonSection>
                <ButtonLink href={openseaUrl} target="blank">
                    <StyledButton gradient={true} >View on Opensea</StyledButton>
                </ButtonLink>
            </ButtonSection> }

            { hasCustomLink &&
            <ButtonSection>
                <ButtonLink href={nft.button.url} target="blank">
                    <StyledButton gradient={true} >{nft.button.label}</StyledButton>
                </ButtonLink>
            </ButtonSection> }

            { hasGoogleSearchLink &&
            <ButtonSection>
                <ButtonLink href={'https://www.google.com/search?q=' + nft.name} target="blank">
                    <StyledButton gradient={true} >Find out more</StyledButton>
                </ButtonLink>
            </ButtonSection> }

            { hasPartner &&
            <PartnerSection>
                <PartnerDescriptionSection>
                    <SectionSubTitle>About</SectionSubTitle>
                </PartnerDescriptionSection>
            </PartnerSection> }
        </Container>
    );
};