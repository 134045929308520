/**
 * Method to detect screen size changes
 * 
 * Usage:
 * 1. import module useWindowSize from /utils
 * 2. get with: const [width, height] = useWindowSize();
 */
import { useLayoutEffect, useState } from 'react';

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
      function updateSize() {
        // const screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0); // got this from another implementation
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener('resize', updateSize);

      updateSize();

      return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    return size;
  }