import styled from 'styled-components';
import FormControl from 'react-bootstrap/FormControl'
import { ArrowClockwise } from 'react-bootstrap-icons';

import { colorConstants } from "../../../constants";


export const Container = styled.div`
    width: 100%;
    margin-top: 8px;
`;

export const ColorPickerContainer = styled.div`
    margin-bottom: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const ColorPickerContainerColumnLeft = styled.div`
    margin-right: 64px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const ColorPickerContainerColumnRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const ColorPreviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ColorPreview = styled.div`
    height: 32px;
    width: 32px;
    border-radius: 500px;
    border: 2px solid ${colorConstants.white};
    background: ${props => props.color || colorConstants.softGrey};

    z-index: 1;

    :hover {
        cursor: ${props => props.deactivate ? 'initial' : 'pointer'};
    }
`;

export const ColorPreviewGradient = styled.div`
    height: 29px;
    width: 64px;
    margin: 0 -14px;
    background: ${props => props.color};

    z-index: 0;
`;

export const ColorPreviewTextContainer = styled.div`
    width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const ColorPickerText = styled.div`
    font-size: 10px;
    font-weight: 500;
    color: ${colorConstants.black};
`;

export const ManualColorPickerContainer = styled.div`
    margin-bottom: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const ManualColorPickerContainerColumnLeft = styled.div`
    margin-right: 64px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const ManualColorPickerContainerColumnRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const InputBoxGradientBorder = styled.div`
    height: 32px;
    width: 100px;
    display: flex;
    align-items: center;
    background: ${props => props.deactivate ? colorConstants.softGrey : colorConstants.gradientHorizontal};
    border-radius: 4px !important;
    padding: 1px;

    :focus-within {
        background: ${colorConstants.primaryDark};
    }

    .input-icon {
        display: ${props => props.icon ? 'flex' : 'none'};
    }

    .input-box {
        border-radius: ${props => props.icon ? '4px 0 0 4px' : ''} !important;
    }
`;

export const StyledFormControl = styled(FormControl)`
    height: 100%;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    color: ${colorConstants.black};
    padding: 4px 12px;
    border-radius: 4px;
    border: none;

    :focus {
        border-color: ${colorConstants.primaryDark};
        box-shadow: none;
        outline: none;
    }

    ::placeholder {
        color: ${props => props.nolabel ? colorConstants.mediumGrey : colorConstants.softGrey};
        font-weight: ${props => props.nolabel ? '600' : '400'};
    }
`;

export const InputBoxIcon = styled.div`
    height: 100%;
    background: ${({ deactivate }) => deactivate ? colorConstants.softGrey: colorConstants.white};
    border-radius: 0 4px 4px 0 !important;
    padding: 0 8px;

    align-items: center;
    justify-content: center;
`;

export const AngleIcon = styled(ArrowClockwise)`
    
`;