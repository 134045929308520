import { linkReducerConstants } from "../constants";
import { linkService } from "../services";
import { alertActions } from './';

export const linkActions = {
    create,
    getAll,
    getById,
    update,
    delete: _delete,
    clear
};

function create(link) {
    return async dispatch => {
        dispatch(request());

        await linkService.create(link)
            .then(
                link => {
                    dispatch(success(link));
                    dispatch(alertActions.success('Link created successfully'));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: linkReducerConstants.CREATE_REQUEST } }
    function success(link) { return { type: linkReducerConstants.CREATE_SUCCESS, link } }
    function failure() { return { type: linkReducerConstants.CREATE_FAILURE } }
}

function getAll(query) {
    return dispatch => {
        dispatch(request());

        linkService.getAll(query)
            .then(
                links => dispatch(success(links)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: linkReducerConstants.GETALL_REQUEST } }
    function success(links) { return { type: linkReducerConstants.GETALL_SUCCESS, links } }
    function failure(error) { return { type: linkReducerConstants.GETALL_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request({ id }));

        linkService.getById(id)
            .then(
                link => dispatch(success(link)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(link) { return { type: linkReducerConstants.GETBYID_REQUEST, link } }
    function success(link) { return { type: linkReducerConstants.GETBYID_SUCCESS, link } }
    function failure(error) { return { type: linkReducerConstants.GETBYID_FAILURE, error } }
}

function update(link) {
    return async dispatch => {
        dispatch(request(link));

        await linkService.update(link)
            .then(
                link => { 
                    dispatch(success(link));
                    dispatch(alertActions.success('Update successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(link) { return { type: linkReducerConstants.UPDATE_REQUEST, link } }
    function success(link) { return { type: linkReducerConstants.UPDATE_SUCCESS, link } }
    function failure(error) { return { type: linkReducerConstants.UPDATE_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        linkService.delete(id)
            .then(
                link => dispatch(success(id)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(id) { return { type: linkReducerConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: linkReducerConstants.DELETE_SUCCESS, id } }
    function failure(error) { return { type: linkReducerConstants.DELETE_FAILURE, error } }
}

function clear() {
    return { type: linkReducerConstants.CLEAR_ALL };
}