import styled from 'styled-components';
import Form from "react-bootstrap/Form";
import { Trash } from 'react-bootstrap-icons';

import { colorConstants } from '../../../constants';



export const Container = styled.div`
    width: 100%;
`;

export const OptionsContainer = styled.div`
    & > * {
    margin-bottom: 16px;
    }

    & > *:last-child {
    margin-bottom: 0;
    }
`;


/* --- START: LESS INFO STYLES --- */
export const Option = styled(Form.Check)`

`;

export const OptionButton = styled(Form.Check.Input)`
  margin-right: 32px;

  :hover {
    cursor: pointer;
  }

  :checked {
    background-color: ${colorConstants.primaryDark};
    border-color: ${colorConstants.primaryDark};
  }
`;

export const OptionText = styled(Form.Check.Label)`

`;
/* --- END: LESS INFO STYLES --- */


/* --- START: FULL DETAILS STYLES --- */
export const FullDetailsBorder = styled.div`
  background: ${props => props.active ? colorConstants.gradientHorizontal : 'none'};
  padding: 1px;
  margin-top: 10px;
  margin-bottom: 8px;
  border-radius: 2px;

  display: flex;
  align-items: center;

  ${({ active }) => !active && `
    :hover {
      cursor: pointer;
      background: ${colorConstants.gradientHorizontal} !important;
    }
  `}
`;

export const FullDetailsContainer = styled.div`
  border-radius: 2px;
  border-color: ${colorConstants.white};
  background: ${colorConstants.white};
  width: 100%;
  padding: 4px 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FullDetailsTextArea = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin-right: 32px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export const FullDetailsText = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${colorConstants.black};
`;

export const FullDetailsButtons = styled.div`
    margin-left: 32px;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * {
      margin-right: 8px;
    }
  
    & > *:last-child {
      margin-right: 0;
    }
`;

export const DeleteButton = styled(Trash)`
  color: ${colorConstants.black};
  font-size: 14px;
  :hover {
      cursor: pointer;
  }
`;

/* --- END: FULL DETAILS STYLES --- */