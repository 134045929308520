import React, { Component } from "react";
import { connect } from 'react-redux';
import Joyride from 'react-joyride';

import { authActions, userActions, homepageActions, customerActions, paymentMethodActions, creatorPayoutActions, subscriptionActions, alertActions, publicUsersActions, cryptoActions } from '../../actions';
import { ScreenContainer } from './SettingsContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { Notification } from '../../components/custom/Popup';
import SettingsPage from "../../components/settings/SettingsPage";
import CreatorApplicationForm from '../../components/settings/creatorApplicationForm/CreatorApplicationForm';
import EditUserAddress from '../../components/settings/address/EditUserAddress';
import AddSocialMediaLinks from '../../components/settings/social/AddSocialMediaLinks';
import { TopBar } from '../../components/custom/TopBar';
import { SideBar } from '../../components/custom/SideBar';
import ImageCrop from "../../components/cropper/ImageCrop";
import { FullScreenSpinner } from "../../components/custom/Spinner";
import { Warning } from '../../components/custom/Popup';
import { ConnectWalletsMenu } from '../../components/custom/Menu';
import { topbarConstants, otherConstants, sitemap, getProfileLink, colorConstants, contentSettingsPage } from "../../constants";
import {
    history,
    notNull,
    prepareUserData,
    defaultUser,
    defaultUserPhoto,
    defaultCustomer,
    compressImage,
    storageRef,
    uploadFile,
    getFileDownloadURL,
    removeTokenFromFileDownloadURL,
    getImageFileDimensions,
    prepareCustomerData,
    analytics,
} from '../../utils';


class SettingsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            initCustomer: false, // initialize customer data from server db
            fromUrl: sitemap.admin.dashboard,
            typingTimeout: 0, // used to know when user stops typing
            sidebar: false,
            sidebarReset: false,
            sidebarPositionOffset: 0,
            sticky: false, // used when scrolling to change topbar
            showCreatorModeApply: false,
            editUserAddress: false, // used to toggle on/off user address edit form
            editSocialMediaLinks: false, // used to toggle on/off the add social media links form
            selectedSocialLink: 0, // set default selected social platform
            deleteWarning: false, // hide/show delete user account warning
            deleting: false, // deleting user flag
            cancelSubscriptionWarning: false, // hide/show cancel subscription warning
            cancelingSubscription: false, // canceling subscription flag
            showWalletsMenu: false, // toggle crypto wallets
            scrollToSection: '', // used to scroll to particular section
            popup: null,
            customer: defaultCustomer(),
            user: defaultUser(),
            photos: defaultUserPhoto()
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);
        this.handleSideBarToggle = this.handleSideBarToggle.bind(this);
        this.handleScrollReset = this.handleScrollReset.bind(this);
        /* Settings Page */
        this.handleViewPublicPage = this.handleViewPublicPage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        /* User Profile Photo Upload */
        this.handleUploadPhoto = this.handleUploadPhoto.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);
        /* Change Username */
        this.handleCheckUsernameAvailability = this.handleCheckUsernameAvailability.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        // this.handleUsernameChangeCancel = this.handleUsernameChangeCancel.bind(this);
        /* User Social Media Links */
        this.handleAddSocialMediaLink = this.handleAddSocialMediaLink.bind(this);
        this.handleEditSocialMediaLink = this.handleEditSocialMediaLink.bind(this);
        this.handleSubmitSocialMediaLink = this.handleSubmitSocialMediaLink.bind(this);
        this.handleDeleteSocialMediaLink = this.handleDeleteSocialMediaLink.bind(this);
        /* Creator Mode Application Form Page */
        this.handleShowCreatorModeApply = this.handleShowCreatorModeApply.bind(this);
        this.handleCreatorModeApply = this.handleCreatorModeApply.bind(this);
        /* Subscription */
        this.handleChangeSubscription = this.handleChangeSubscription.bind(this);
        this.handleCancelSubscriptionRequest = this.handleCancelSubscriptionRequest.bind(this);
        this.handleCancelSubscriptionAbort = this.handleCancelSubscriptionAbort.bind(this);
        this.handleCancelSubscription = this.handleCancelSubscription.bind(this);
        /* User Address */
        this.handleEditUserAddress = this.handleEditUserAddress.bind(this);
        this.handleSubmitUserAddress = this.handleSubmitUserAddress.bind(this);
        /* Other buttons */
        this.handleAddBilling = this.handleAddBilling.bind(this);
        this.handleToggleWalletsMenu = this.handleToggleWalletsMenu.bind(this);
        this.handleConnectCryptoWallet = this.handleConnectCryptoWallet.bind(this);
        this.handleDisconnectCryptoWallet = this.handleDisconnectCryptoWallet.bind(this);
        this.handleAddPayment = this.handleAddPayment.bind(this);
        this.handleCollectPayment = this.handleCollectPayment.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleLogout = this.handleLogout.bind(this);

        this.handleDeleteAccountRequest = this.handleDeleteAccountRequest.bind(this);
        this.handleDeleteAccountRequestCancel = this.handleDeleteAccountRequestCancel.bind(this);
        this.handleDeleteAccount = this.handleDeleteAccount.bind(this);

        /* App Tour */
        this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
        this.handleClearPopupNotification = this.handleClearPopupNotification.bind(this);
    }

    componentDidMount() {
        this.props.loadCsrfToken(); // load csrf token in cookies
        this.props.getById(this.props.userId); // get user data from db
        window.addEventListener('scroll', this.handleScroll);
        if(this.props.location.state) {
            let initialState = {};
            if(this.props.location.state.from) initialState = { ...this.state, ...initialState, fromUrl: this.props.location.state.from }; // set from URL
            // get data from previous page
            if(this.props.location.state.data) {
                const { scrollToSection } = this.props.location.state.data;
                // set section to scroll to
                if(scrollToSection) initialState = { ...this.state, ...initialState, scrollToSection };
            }
            // set initial settings page state
            this.setState(initialState);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.resetUsernameCheckResults();
        this.props.resetCustomer();
        this.props.resetSubscription();
        this.props.resetPaymentMethods();
        this.props.resetCreatorPayout();
    }

    componentDidUpdate() {
        // load user data from server into view
        if (!this.state.init && this.props.user && this.props.user.id) {
            this.setState({ ...this.state, init: true, user: this.props.user });
            // check creator mode status
            this.props.getCreatorModeStatus(this.props.user.id);
            // find customer on server with userId (if exists)
            this.props.getCustomer(this.props.user.id, false);
            // find active subscriptions on server with userId (if exists)
            this.props.getSubscription(this.props.user.id);
            
            if(this.props.user.creator) {
                // get creator earnings stats
                this.props.getEarningsStats(this.props.user.id);
                // get connected crypto wallets
                this.props.loadWallets(new URLSearchParams({ userId: this.props.user.id }));
            }
        }

        // load customer data from server into view
        if(this.state.init && !this.state.initCustomer && this.props.customer && (this.props.customer.name || this.props.customer.error)) {
            if(this.props.customer.id && this.props.customer.name) {
                this.setState({
                    ...this.state,
                    initCustomer: true,
                    customer: {
                        ...this.state.customer,
                        ...this.props.customer,
                    }
                });
                // get all payment methods
                this.props.getAllPaymentMethods(this.props.customer.id);
            } else {
                this.setState({ ...this.state, initCustomer: true });
            }
        }

        // close creator mode application screen and update status
        if(this.state.init && !this.state.user.creator && !this.props.creatorApplySubmitting && this.props.creatorApplySubmittionSuccess && this.state.showCreatorModeApply) {
            // close creator mode application screen
            this.setState({ ...this.state, showCreatorModeApply: false });
            // get creator mode status update
            this.props.getCreatorModeStatus(this.props.user.id);
        }
    }

    handleScroll(event) {
        // Enable sticky mode on scroll and reset sidebar
        this.setState({ ...this.state, sidebar: false, sidebarReset: true, sidebarPositionOffset: window.pageYOffset, sticky: window.pageYOffset > 1 });
    }
    
    handleTopBarBackButton() {
        if(this.state.photos.crop.pendingImage) this.setState({ ...this.state, photos: defaultUserPhoto() }); // reset crop image screen
        else if(this.state.editUserAddress) this.setState({ ...this.state, editUserAddress: false }); // close edit user address screen
        else if(this.state.editSocialMediaLinks) this.setState({ ...this.state, editSocialMediaLinks: false, selectedSocialLink: 0 }); // close edit social media links screen
        else if(this.state.showCreatorModeApply) this.setState({ ...this.state, showCreatorModeApply: false }); // close creator mode application screen
        // else history.push(this.state.fromUrl); // go back to previous screen
    }

    handleSideBarToggle(){
        this.setState({ ...this.state, sidebar: !this.state.sidebar, sidebarReset: false });
    }

    handleScrollReset(){
        if(this.state.init && this.state.scrollToSection) {
            this.setState({ ...this.state, scrollToSection: '' });
        }
    }

    handleViewPublicPage(){
        history.push({
            pathname: this.state.init ? getProfileLink(this.state.user.username) : this.state.fromUrl,
            state: {
                from: sitemap.admin.settings
            }
        });
    }

    handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        const { user } = this.state;

        this.setState({
            typingTimeout: setTimeout(() => {
                this.props.update(prepareUserData(this.state.user)); // request update user data on server 2sec after user stops typing
                }, 1000),
            user: {
                ...user,
                [name]: value, // update input data
                updatedAt: Date().toLocaleString() //edit last updated date
            }
        });

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleUploadPhoto(e) {
        if(!this.state.user.email) return;
        
        // generate gilename
        const filename = `${this.state.user.email.split('@')[0]}-profile-photo`;

        // Save uploaded image url in user state
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save uploaded photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: filename,
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    async handleCroppedImage(croppedImage) {
        try {
            // enable spinner
            this.setState({ ...this.state, photos: { uploading: true, crop: defaultUserPhoto().crop } });

            // compress cropped image file
            const compressedFile = await compressImage(croppedImage, croppedImage.name, 512);

            // create firebase storage reference of profile image to upload
            const imageRef = storageRef(otherConstants.environment + '/users/' + this.state.user.id + '/' + compressedFile.name);

            // Upload compressed image to firebase storage
            const snapshot = await uploadFile(imageRef, compressedFile);

            // Get firebase public url of uploaded photo
            let uploadedPhotoFirebaseUrl = await getFileDownloadURL(snapshot.ref);

            // Clean public url
            uploadedPhotoFirebaseUrl = removeTokenFromFileDownloadURL(uploadedPhotoFirebaseUrl);

            // update user profile url in state
            this.setState({ ...this.state, user: { ...this.state.user, profilePhotoUrl: uploadedPhotoFirebaseUrl, updatedAt: Date().toLocaleString() } }, () => {
                // update user profile photo in db
                this.props.update(prepareUserData(this.state.user));
                // disable spinner
                this.setState({ ...this.state, photos: defaultUserPhoto() });
            });
        } catch (error) {
            throw error;
        }
    }

    handleCheckUsernameAvailability(event) {
        let { value } = event.target;
        const { user } = this.state;
        if (!user.creator) return;

        // force lowercase usernames
        value = value.toLowerCase();

        this.setState({
            ...this.state,
            typingTimeout: setTimeout(() => { this.props.checkUsername(value) }, 1000),
            user: {
                ...user,
                username: value, // update input data
                updatedAt: Date().toLocaleString() //edit last updated date
            }
        });

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleUsernameChange(){
        // update username on server
        this.props.update(prepareUserData(this.state.user));
        // reset username check results
        this.props.resetUsernameCheckResults();
    }

    // handleUsernameChangeCancel(){
    //     // reset state to previous username
    //     this.setState({
    //         ...this.state,
    //         user: {
    //             ...this.state.user,
    //             username: this.props.user.username
    //         }
    //     });
    //     // reset username check results
    //     this.props.resetUsernameCheckResults();
    // }

    handleAddSocialMediaLink(){
        // open edit social media screen to add new links
        this.setState({ ...this.state, editSocialMediaLinks: true });
    }

    handleEditSocialMediaLink(index) {
        // open edit social media screen and pass selected social media link to edit
        this.setState({ ...this.state, editSocialMediaLinks: true, selectedSocialLink: index });
    }

    handleSubmitSocialMediaLink(links) {
        // update social media links in state and db
        this.setState({
            ...this.state,
            editSocialMediaLinks: false, // hide edit social links screen
            selectedSocialLink: 0, // reset selected link
            user: {
                ...this.state.user,
                social: links,
                updatedAt: Date().toLocaleString() //edit last updated date
            }
        }, () => this.props.update(prepareUserData(this.state.user)));
    }

    handleDeleteSocialMediaLink(name) {
        const { user } = this.state;
        const { social } = user;
        
        this.setState({
            user: {
                ...user,
                social: {
                    ...social,
                    [name]: "" // delete social media profile link
                },
                updatedAt: Date().toLocaleString() //edit last updated date
            }
        }, () => this.props.update(prepareUserData(this.state.user))); // update user in DB
    }

    handleShowCreatorModeApply() {
        this.setState({ ...this.state, showCreatorModeApply: !this.state.showCreatorModeApply });
    }

    handleCreatorModeApply(data){
        if(this.state.user.creator || !this.state.user.id) return;

        // submit creator account application form
        this.props.joinCreatorWaitlist(data);

        // register click event
        analytics.event('Settings Page', 'Creator Application Form Submittion', 'Clicked Form Submit Button');

        /* CONTINUES AT componentDidUpdate() */
    }

    handleChangeSubscription() {
        if(!this.state.init) return;

        history.push({
            pathname: sitemap.billing.upgrade,
            state: {
                from: sitemap.admin.settings,
                data: {
                    scrollToSection: this.props.subscription && this.props.subscription.plan ? this.props.subscription.plan.toLowerCase() : null
                }
            }
        });
    }

    handleCancelSubscriptionRequest() {
        if(!this.state.init) return;
        this.setState({ ...this.state, cancelSubscriptionWarning: true });
    }

    handleCancelSubscriptionAbort() {
        this.setState({ ...this.state, cancelSubscriptionWarning: false });
    }

    handleCancelSubscription() {
        // start spinner and hide warning
        this.setState({ ...this.state, cancelSubscriptionWarning: false });

        // cancel subscription
        if(this.props.subscription && this.props.subscription.id && this.props.subscription.isActive && !this.props.subscription.isCancelled) {
            this.props.cancelSubscription(this.props.subscription.id);
        }
    }

    handleEditUserAddress() {
        if(!this.state.customer.email) return; // customer does not exist

        this.setState({ ...this.state, editUserAddress: true });
    }

    handleSubmitUserAddress(address) {
        if(!this.state.customer.email) return; // customer does not exist

        // Update Customer billing Address
        this.setState({
            ...this.state,
            editUserAddress: false,
            customer: {
                ...this.state.customer,
                address: address,
            }
        }, () => {
            let formattedCustomerData = prepareCustomerData(this.state.customer);
            formattedCustomerData.type = 'stripe';
            this.props.updateCustomer(this.state.customer.id, formattedCustomerData);
        });
    }

    handleAddBilling() {
        alert("Added Billing Method !!");
    }

    handleToggleWalletsMenu(toggle){
        this.setState({ ...this.state, showWalletsMenu: toggle });
    }

    handleConnectCryptoWallet(account) {
        if(!this.state.init) return;
        this.props.connectCryptoWallet({ userId: this.state.user.id, ...account });
        this.handleToggleWalletsMenu(false);
    }

    handleDisconnectCryptoWallet(account) {
        if(!this.state.init) return;

        // notify to delete their NFTs before disconnecting their wallet
        if(this.state.user.pageData && this.state.user.pageData.metadata  && this.state.user.pageData.metadata.links.nft.total > 0) {
            // create notification
            let popup = {
                button: contentSettingsPage.hasNFTsWarning.firstButton,
                title: contentSettingsPage.hasNFTsWarning.title,
                body: contentSettingsPage.hasNFTsWarning.body,
            };
            this.setState({ ...this.state, popup });

            return;
        }

        this.props.disconnectCryptoWallet(account.id);
        this.handleToggleWalletsMenu(false);
    }

    handleAddPayment() {
        console.log("Added Payment Info!");
    }

    handleCollectPayment() {
        this.props.payoutRequest(this.props.user.id);
    }

    handlePasswordChange() {
        // open password change screen
        history.push({
            pathname: sitemap.auth.password.change,
            state: {
                from: sitemap.admin.settings
            }
        });
    }

    handleEmailChange() {
        // open email change screen
        history.push({
            pathname: sitemap.auth.email.change,
            state: {
                from: sitemap.admin.settings,
            }
        });
    }

    handleLogout(){
        // logout app
        history.push({ pathname: sitemap.auth.logout });
    }

    handleDeleteAccountRequest(){
        if(!this.state.init) return;

        // notify to cancel their subscription before deleting their account
        if(this.props.subscription && this.props.subscription.isActive && !this.props.subscription.isCancelled) {
            // create notification
            let popup = {
                button: contentSettingsPage.activeSubscriptionWarning.firstButton,
                title: contentSettingsPage.activeSubscriptionWarning.title,
                body: contentSettingsPage.activeSubscriptionWarning.body,
            };
            this.setState({ ...this.state, popup });

            return;
        }

        // enable delete account popup
        this.setState({ ...this.state, deleteWarning: true });
    }

    handleDeleteAccountRequestCancel(){
        this.setState({ ...this.state, deleteWarning: false });
    }

    async handleDeleteAccount(){
        // start spinner and hide warning
        this.setState({ ...this.state, deleting: true, deleteWarning: false });

        // Delete user account
        await this.props.deleteUser(this.state.user.id);
    }

    handleJoyrideCallback(data) {
        if(!data || !this.state.init) return;
        
        const { status } = data;

        if(status === 'finished' || status === 'skipped') {
            this.setState({ ...this.state, user: { ...this.state.user, isSettingsTourDone: true } }, () => this.props.update(prepareUserData(this.state.user)));
        }
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    handleClearPopupNotification(){
        this.setState({ ...this.state, popup: null });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.settings} />

                { this.state.init && this.state.user.creator && !this.state.user.isSettingsTourDone &&
                <Joyride
                    callback={this.handleJoyrideCallback}
                    continuous
                    hideCloseButton
                    disableCloseOnEsc
                    disableOverlayClose
                    hideBackButton
                    // scrollToFirstStep
                    showProgress
                    showSkipButton
                    steps={contentSettingsPage.tour}
                    run={true}
                    styles={{
                        options: {
                            primaryColor: colorConstants.primaryDark,
                        },
                    }}
                /> }

                <TopBar
                    title={this.state.photos.crop.pendingImage ? topbarConstants.titles.crop : topbarConstants.titles.settings}
                    sticky={this.state.sticky}
                    menuButton={(this.state.photos.crop.pendingImage || this.state.editUserAddress || this.state.editSocialMediaLinks || this.state.showCreatorModeApply) ? null : this.handleSideBarToggle}
                    backButton={(this.state.photos.crop.pendingImage || this.state.editUserAddress || this.state.editSocialMediaLinks || this.state.showCreatorModeApply) ? this.handleTopBarBackButton : null}
                    submitButton={this.props.usernameCheckResults && this.props.usernameCheckResults.available ? this.handleUsernameChange : null} />
                
                {this.state.init &&
                <SideBar
                    options={{ isAuthenticated: true, creator: this.state.user.creator, basic: this.state.user.basic, premium: this.state.user.premium, platinum: this.state.user.platinum, links: this.state.user.pageData.metadata.links }}
                    toggle={this.handleSideBarToggle}
                    from={sitemap.admin.settings}
                    enable={this.state.sidebar}
                    reset={this.state.sidebarReset}
                    position={this.state.sidebarPositionOffset} /> }

                { this.state.init && !this.state.photos.crop.pendingImage && !this.state.showCreatorModeApply && !this.state.editUserAddress && !this.state.editSocialMediaLinks &&
                <SettingsPage
                    handleScrollReset={this.handleScrollReset}
                    scrollToSection={this.state.scrollToSection}
                    handleViewPublicPage={this.handleViewPublicPage}
                    handleChange={this.handleChange}
                    handleCheckUsernameAvailability={this.handleCheckUsernameAvailability}
                    handleUploadPhoto={this.handleUploadPhoto}
                    user={this.state.user}
                    creatorModeStatus={this.props.creatorModeStatus}
                    crypto={this.props.crypto}
                    customer={this.state.customer}
                    subscription={this.props.subscription}
                    paymentMethods={(this.props.paymentMethod && this.props.paymentMethod.list) ? this.props.paymentMethod.list : null}
                    creatorPayout={this.props.creatorPayout}
                    handleAddSocialMediaLink={this.handleAddSocialMediaLink}
                    handleEditSocialMediaLink={this.handleEditSocialMediaLink}
                    handleDeleteSocialMediaLink={this.handleDeleteSocialMediaLink}
                    handleShowCreatorModeApply={this.handleShowCreatorModeApply}
                    handleChangeSubscription={this.handleChangeSubscription}
                    handleCancelSubscriptionRequest={this.handleCancelSubscriptionRequest}
                    handleEditUserAddress={this.handleEditUserAddress}
                    handleAddBilling={this.handleAddBilling}
                    handleConnectCryptoWallet={() => this.handleToggleWalletsMenu(true)}
                    handleDisconnectCryptoWallet={this.handleDisconnectCryptoWallet}
                    handleAddPayment={this.handleAddPayment}
                    handleCollectPayment={this.handleCollectPayment}
                    handlePasswordChange={this.handlePasswordChange}
                    handleEmailChange={this.handleEmailChange}
                    handleLogout={this.handleLogout}
                    handleDeleteAccountRequest={this.handleDeleteAccountRequest} /> }

                { this.state.init && this.state.showCreatorModeApply &&
                <CreatorApplicationForm
                    user={this.state.user}
                    alert={this.props.alert}
                    submitData={this.handleCreatorModeApply} /> }

                { this.state.init && this.state.editUserAddress &&
                <EditUserAddress
                    currentAddress={this.state.customer.address}
                    submitData={this.handleSubmitUserAddress} /> }

                { this.state.init && this.state.editSocialMediaLinks &&
                <AddSocialMediaLinks
                    links={this.state.user.social}
                    preSelected={this.state.selectedSocialLink}
                    submitData={this.handleSubmitSocialMediaLink} /> }

                { this.state.photos.crop.pendingImage &&
                <ImageCrop
                    url={this.state.photos.crop.pendingImage}
                    filename={this.state.photos.crop.pendingImageFilename}
                    fileSize={this.state.photos.crop.pendingImageFileSize}
                    imageDimensions={this.state.photos.crop.pendingImageDimensions}
                    circularCrop={true}
                    handleCroppedImage={this.handleCroppedImage} /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                <ConnectWalletsMenu
                    show={this.state.showWalletsMenu}
                    wallets={this.props.crypto && this.props.crypto.wallets ? this.props.crypto.wallets : null}
                    onHide={() => this.handleToggleWalletsMenu(false)}
                    disconnectWallet={this.handleDisconnectCryptoWallet}
                    connectWallet={this.handleConnectCryptoWallet} />

                { (this.state.deleting || this.state.cancelingSubscription || this.props.customer.loading || this.props.subscription.loading || this.props.subscription.cancelling ||
                !this.props.user || this.props.user.loading || this.state.photos.uploading || this.props.crypto.connecting || this.props.creatorApplySubmitting) &&
                <FullScreenSpinner /> }

                <Warning
                    show={this.state.deleteWarning}
                    onHide={this.handleDeleteAccountRequestCancel}
                    firstButtonClick={this.handleDeleteAccount}
                    secondButtonClick={this.handleDeleteAccountRequestCancel}
                    firstButton={contentSettingsPage.deleteUserWarning.firstButton}
                    firstButtonColor={colorConstants.darkGrey}
                    firstButtonBackgroundColor={colorConstants.softGrey}
                    secondButton={contentSettingsPage.deleteUserWarning.secondButton}
                    title={contentSettingsPage.deleteUserWarning.title}
                    body={contentSettingsPage.deleteUserWarning.body} />
                
                <Warning
                    show={this.state.cancelSubscriptionWarning}
                    onHide={this.handleCancelSubscriptionAbort}
                    firstButtonClick={this.handleCancelSubscription}
                    secondButtonClick={this.handleCancelSubscriptionAbort}
                    firstButton={contentSettingsPage.cancelSubscriptionWarning.firstButton}
                    firstButtonColor={colorConstants.warningDark}
                    firstButtonBackgroundColor={colorConstants.softGrey}
                    secondButton={contentSettingsPage.cancelSubscriptionWarning.secondButton}
                    title={contentSettingsPage.cancelSubscriptionWarning.title}
                    body={contentSettingsPage.cancelSubscriptionWarning.body} />
                
                {this.state.popup &&
                <Warning
                    show={this.state.popup !== null}
                    onHide={this.handleClearPopupNotification}
                    firstButtonClick={this.handleClearPopupNotification}
                    firstButton={this.state.popup.button}
                    title={this.state.popup.title}
                    body={this.state.popup.body} /> }

                {/* {this.props.usernameCheckResults && this.props.usernameCheckResults.available &&
                <Warning
                    show={this.props.usernameCheckResults.available}
                    onHide={this.handleUsernameChangeCancel}
                    firstButtonClick={this.handleUsernameChange}
                    secondButtonClick={this.handleUsernameChangeCancel}
                    firstButton={contentSettingsPage.usernameChangeWarning.firstButton}
                    firstButtonColor={colorConstants.darkGrey}
                    firstButtonBackgroundColor={colorConstants.softGrey}
                    secondButton={contentSettingsPage.usernameChangeWarning.secondButton}
                    title={contentSettingsPage.usernameChangeWarning.title}
                    body={contentSettingsPage.usernameChangeWarning.body} /> } */}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user, customer, subscription, paymentMethod, creatorPayout, crypto } = state;
    const usernameCheckResults = state.publicUsers.usernameCheckResult;

     // get creator mode status
     const { creatorModeStatus } = user;

     // get creator mode application submission status
     const creatorApplySubmitting = state.homepage.joining;
     const creatorApplySubmittionSuccess = state.homepage.success;

    // export state data to props
    return { userId, alert, user, creatorModeStatus, usernameCheckResults, customer, subscription, paymentMethod, creatorPayout, crypto, creatorApplySubmitting, creatorApplySubmittionSuccess };
}

const actionCreators = {
    getById: userActions.getById,
    update: userActions.update,
    getCreatorModeStatus: userActions.creatorModeStatus,
    joinCreatorWaitlist: homepageActions.joinWaitlist,
    deleteUser: userActions.delete,
    loadCsrfToken: authActions.loadCsrfToken,
    getCustomer: customerActions.getByUserId,
    updateCustomer: customerActions.update,
    resetCustomer: customerActions.clear,
    getSubscription: subscriptionActions.getByUserId,
    cancelSubscription: subscriptionActions.cancel,
    resetSubscription: subscriptionActions.clear,
    getAllPaymentMethods: paymentMethodActions.getAll,
    // updatePaymentMethod: paymentMethodActions.update,
    getEarningsStats: creatorPayoutActions.getEarningsStatsById,
    payoutRequest: creatorPayoutActions.payoutRequest,
    resetCreatorPayout: creatorPayoutActions.clear,
    connectCryptoWallet: cryptoActions.connectWallet,
    disconnectCryptoWallet: cryptoActions.disconnectWallet,
    loadWallets: cryptoActions.getAllWallets,
    resetPaymentMethods: paymentMethodActions.clear,
    checkUsername: publicUsersActions.getUsernameAvailability,
    resetUsernameCheckResults: publicUsersActions.reset,
    clearAlert: alertActions.clear
}

const connectedSettingsContainer = connect(mapState, actionCreators)(SettingsContainer);
export { connectedSettingsContainer as SettingsContainer };