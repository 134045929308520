import React from 'react';
import styled from 'styled-components';
import FormSelect from 'react-bootstrap/FormSelect';

import { colorConstants } from "../../../constants";

const GradientBorder = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    background: ${({ disabled }) => disabled ? colorConstants.softGrey: colorConstants.gradientHorizontal};
    padding: 1px;
    border-radius: ${props => props.big ? '4px' : '2px'};
    margin-top: ${props => props.big ? '8px' : '4px'};
    margin-bottom: ${props => props.big ? '0' : '2px'};

    :focus-within {
        background: ${colorConstants.primaryDark};
    }
`;

const StyledFormSelect = styled(FormSelect)`
    font-size: 12px;
    font-weight: ${props => props.big ? '400' : '500'};
    color: ${colorConstants.black};
    padding: ${props => props.big ? '12px' : '8px'};
    border-radius: ${props => props.big ? '4px' : '2px'};
    border-color: ${colorConstants.white};

    :focus {
        border-color: ${props => props.big ? colorConstants.primaryDark : 'transparent'};
        box-shadow: none;
        outline: none;
    }
`;

export const GradientFormSelector = (props) => {
  const { title, options, selected, big, disabled, ...other } = props;

  const items = options.map((option, index) => {
      if(!option.notice) return <option key={index} value={option.name || option}>{option.label || option}</option>;
      return <option key={index} value={option.name || option}>{option.label || option}&emsp;&emsp;{option.notice}</option>;
  });

    return (
        <GradientBorder disabled={disabled} big={big} >
            <StyledFormSelect big={big} name={'form-selector'} value={selected} disabled={disabled} {...other} >
                {title && <option>{title}</option>}
                {items}
            </StyledFormSelect>
        </GradientBorder>
    );
};