import React, { Component } from "react";
import { connect } from 'react-redux';

import { tripActions, alertActions } from '../../../actions';
import { ScreenContainer } from './EditTripContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { Notification, Upgrade } from '../../../components/custom/Popup';
import { EditTrip, EditSearchLink }  from "../../../components/trips";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { TopBar } from '../../../components/custom/TopBar';
import ImageCrop from "../../../components/cropper/ImageCrop";
import {
    defaultTrip,
    defaultPhotos,
    history,
    notNull,
    compressImage,
    storageRef,
    uploadFile,
    getFileDownloadURL,
    removeTokenFromFileDownloadURL,
    createSlug,
    finalizeTripData,
    getImageFileDimensions
} from '../../../utils';
import { sitemap, topbarConstants, featureOptions, otherConstants, getUserAccountType } from "../../../constants";


class EditTripContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            init: false,
            sticky: false,
            typingTimeout: 0, // used to know when user stops typing
            fromUrl: sitemap.admin.dashboard,
            updating: false,
            photos: defaultPhotos(),
            trip: defaultTrip(featureOptions.trip.types[0]),
            isSearch: false,
            tripDescriptionLength: 0, // used to force user to type a minimum description before adding
            showUpgradePopup: false, // used to toggle upgrade popup
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleDescriptionLengthChange = this.handleDescriptionLengthChange.bind(this);
        this.handleIsBookable = this.handleIsBookable.bind(this);
        this.handleUploadPhoto1 = this.handleUploadPhoto1.bind(this);
        this.handleUploadPhoto2 = this.handleUploadPhoto2.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);
        /* Upgrade */
        this.handleToggleUpgradePopup = this.handleToggleUpgradePopup.bind(this);
        this.handleUpgrade = this.handleUpgrade.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if(this.props.userId && this.props.location.state && this.props.location.state.from && this.props.location.state.data && this.props.location.state.data.tripId) {
            this.setState({
                fromUrl: this.props.location.state.from,
                trip: {
                    ...this.state.trip,
                    id: this.props.location.state.data.tripId,
                    userId: this.props.userId
                }
            });
            this.props.getById(this.props.location.state.data.tripId, null); // get trip from db
        } else {
            history.replace({ pathname: sitemap.admin.dashboard, state: { error: 'Invalid container startup props' } });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        if(!this.state.init && this.props.trip &&  this.props.trip.id) this.setState({ ...this.state, init: true, trip: this.props.trip, isSearch: this.props.trip.isSearch });
    }

    handleScroll(event) {
        // Enable sticky mode on scroll
        this.setState({ ...this.state, sticky: window.pageYOffset > 1 });
    }

    handleTopBarBackButton() {
        if(this.state.photos.crop.pendingImage) this.setState({ ...this.state, photos: { crop: defaultPhotos().crop } }); // reset crop image screen
        else {
            // Go back
            history.push({
                pathname: this.state.fromUrl,
                state: {
                    data: {
                        tripId: this.state.trip.id
                    }
                }
            }); 
        }
    }

    handleChange(event) {
        event.preventDefault();

        let { name, value } = event.target;
        let withTimeout = true;

        if(name === 'customUrl') {
            // limit trip custom url to minimum 5 characters
            if(value.length <= 5) return;
            // create slug from input
            value = createSlug(value);
        } else if(name === 'simple-link-label' || name === 'search-link-label') {
            // save affiliateTitle value
            name = 'affiliate';
            value = { ...this.state.trip.affiliate, label: value };
        } else if(name === 'simple-link-url') {
            // save affiliateLink value
            name = 'affiliate';
            value = { ...this.state.trip.affiliate, url: value };
        } else if(name === 'trip-enable') {
            name = 'enabled';
            value = !this.state.trip.enabled;
            withTimeout = false;
        } else if(name === 'skipDetailsPage-enable') {
            name = 'skipDetailsPage';
            value = !this.state.trip.skipDetailsPage;
            withTimeout = false;
        }

        this.setState({
            updating: false,
            typingTimeout: withTimeout ? setTimeout(() => { this.props.update(finalizeTripData(this.state.trip)) }, 1000) : null, // request update trip data on server 1sec after user stops typing
            trip: {
                ...this.state.trip,
                [name]: value, // update input data
            }
        }, () => !withTimeout ? this.props.update(finalizeTripData(this.state.trip)) : null);

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleDescriptionChange(value) {
        const { trip } = this.state;

        if(!value || value.length < 57 || this.state.tripDescriptionLength < 50) return;

        this.setState({
            updating: false,
            trip: {
                ...trip,
                description: value, // update input data
            }
        }, () => this.props.update(finalizeTripData(this.state.trip)));
    }

    handleDescriptionLengthChange(length){
        this.setState({ ...this.state, tripDescriptionLength: length });
    }

    handleIsBookable(option){
        // option: 0 => isBookable option is selected
        const bookable = option === 0;

        this.setState({
            ...this.state,
            trip: {
                ...this.state.trip,
                isBookable: bookable,
                skipDetailsPage: bookable ? false : this.state.trip.skipDetailsPage, // must open trip details page to book
            }
        }, () => this.props.update(finalizeTripData(this.state.trip)));
    }

    handleUploadPhoto1(e) {
        // Save inserted image url in state
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save inserted photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: 'trip-' + this.state.trip.id + '-photo-1',
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    handleUploadPhoto2(e) {
        // Save inserted image url in state
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save inserted photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: 'trip-' + this.state.trip.id + '-photo-2',
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    async handleCroppedImage(croppedImage) {

        // enable spinner and reset pending image for cropping
        this.setState({ ...this.state, updating: true, photos: { crop: defaultPhotos().crop } });

        // get trip photo urls from state
        let { tripPhotoUrls } = this.state.trip;
        if(!tripPhotoUrls) tripPhotoUrls = [];

        // compress cropped image file
        const compressedFile = await compressImage(croppedImage, croppedImage.name, 1080);

        // create firebase storage reference of image to upload
        const imageRef = storageRef(otherConstants.environment + '/users/' + this.state.trip.userId + '/trips/' + this.state.trip.id + '/photos/' + compressedFile.name);

        // Upload image to firebase storage
        const snapshot = await uploadFile(imageRef, compressedFile);

        // Get firebase public url of uploaded photo
        let uploadedPhotoFirebaseUrl = await getFileDownloadURL(snapshot.ref);

        // Clean public url
        uploadedPhotoFirebaseUrl = removeTokenFromFileDownloadURL(uploadedPhotoFirebaseUrl);

        // save uploaded photo URL
        tripPhotoUrls[croppedImage.name.includes('photo-1') ? 0 : 1] = uploadedPhotoFirebaseUrl;

        this.setState({
            ...this.state,
            trip: {
                ...this.state.trip,
                tripPhotoUrls // save uploaded photo URL
            }
        }, async () => {
            // update photo urls in db
            await this.props.update({ ...finalizeTripData(this.state.trip), tripPhotoUrls });

            // disable updating spinner
            this.setState({ ...this.state, updating: false });
        });
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    handleToggleUpgradePopup() {
        this.setState({ ...this.state, showUpgradePopup: !this.state.showUpgradePopup });
    }

    handleUpgrade(){
        history.push({
            pathname: sitemap.billing.upgrade,
            state: {
                from: sitemap.admin.trips.edit
            }
        });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.editTrip} />

                <TopBar
                    sticky={this.state.sticky}
                    title={this.state.photos.crop.pendingImage ? topbarConstants.titles.crop : !this.state.isSearch ? topbarConstants.titles.editTrip : 'Search Link'}
                    backButton={this.handleTopBarBackButton} />

                { this.state.init && !this.state.photos.crop.pendingImage && !this.state.isSearch &&
                <EditTrip
                    trip={this.state.trip}
                    userAccountType={getUserAccountType(this.props.user)}
                    handleUpgrade={this.handleToggleUpgradePopup}
                    handleChange={this.handleChange}
                    handleDescriptionChange={this.handleDescriptionChange}
                    handleDescriptionLengthChange={this.handleDescriptionLengthChange}
                    tripDescriptionLength={this.state.tripDescriptionLength}
                    handleIsBookable={this.handleIsBookable}
                    handleUploadPhoto1={this.handleUploadPhoto1}
                    handleUploadPhoto2={this.handleUploadPhoto2} /> }

                { this.state.init && this.state.isSearch &&
                <EditSearchLink
                    data={this.state.trip}
                    userAccountType={getUserAccountType(this.props.user)}
                    handleUpgrade={this.handleToggleUpgradePopup}
                    handleChange={this.handleChange} /> }

                { this.state.photos.crop.pendingImage &&
                <ImageCrop
                    url={this.state.photos.crop.pendingImage}
                    filename={this.state.photos.crop.pendingImageFilename}
                    fileSize={this.state.photos.crop.pendingImageFileSize}
                    imageDimensions={this.state.photos.crop.pendingImageDimensions}
                    circularCrop={false}
                    handleCroppedImage={this.handleCroppedImage} /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                {this.state.showUpgradePopup &&
                <Upgrade
                    show={this.state.showUpgradePopup}
                    onClick={this.handleUpgrade}
                    onHide={this.handleToggleUpgradePopup} /> }

                {this.state.updating && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, trip, user } = state;
    
    // export state data to props
    return { userId, alert, trip, user };
}

const actionCreators = {
    update: tripActions.update,
    getById: tripActions.getById,
    clearAlert: alertActions.clear
}

const connectedEditTripContainer = connect(mapState, actionCreators)(EditTripContainer);
export { connectedEditTripContainer as EditTripContainer };