import {
    IconWrap,
    Icons
} from './GradientRichEditorDraft.styles';


import { colorConstants } from "../../../../../constants";


// Select icon to render
export function getIcon(name){
    const IconToRender = Icons[name];
    return <IconWrap><IconToRender /></IconWrap>;
}


// Custom overrides for "code" style
export const styleMap = {
    CODE: {
        backgroundColor: colorConstants.verySoftGreyVersion2,
        color: '#d63384',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 12,
        padding: 2,
    },
    // 'blockquote': {
    //     borderLeft: '5px solid #eee',
    //     color: '#666',
    //     fontFamily: '"Hoefler Text", "Georgia", serif',
    //     fontStyle: 'italic',
    //     margin: '16px 0',
    //     padding: '10px 20px',
    // }
};


// Add custom classes here
export function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote':
            return 'RichEditor-blockquote';

        default:
            return null;
    }
};


// Add block types here
export const BLOCK_TYPES = [
    {label: 'h1', style: 'header-one'},
    {label: 'h2', style: 'header-two'},
    {label: 'h3', style: 'header-three'},
    // {label: 'h4', style: 'header-four'},
    // {label: 'h5', style: 'header-five'},
    // {label: 'h6', style: 'header-six'},
    {label: 'quote', style: 'blockquote'},
    {label: 'ul', style: 'unordered-list-item'},
    {label: 'ol', style: 'ordered-list-item'},
    // {label: 'codeBlock', style: 'code-block'},
];


// Add inline styles here
export const INLINE_STYLES = [
    {label: 'bold', style: 'BOLD'},
    {label: 'italic', style: 'ITALIC'},
    {label: 'underline', style: 'UNDERLINE'},
    {label: 'code', style: 'CODE'},
];


// Get block type for current selected
export function getBlockType(editorState){
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent()
                                .getBlockForKey(selection.getStartKey())
                                .getType();
    return blockType;
}


// Get inline style for current selected
export function getInlineStyle(editorState){
    const currentStyle = editorState.getCurrentInlineStyle();
    return currentStyle;
}