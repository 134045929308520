import React, { useState, useEffect } from 'react';
import {
    Container,
    Title,
    OptionsContainer,
    OptionSelectionGradient,
    Option,
} from './BillingCycleSelector.styles';



export const BillingCycleSelector = ({
    title,
    handleChangeMonthly,
}) => {

    const [isMonthly, setIsMonthly] = useState(true);

    useEffect(() => {
        if(handleChangeMonthly) handleChangeMonthly(isMonthly);
    }, [isMonthly, handleChangeMonthly]);

    return (
        <Container>
            <Title>{title}</Title>

            <OptionsContainer>
                <OptionSelectionGradient
                    onClick={() => setIsMonthly(true)}
                    className={isMonthly ? 'active' : null}>
                    <Option>Monthly</Option>
                </OptionSelectionGradient>

                <OptionSelectionGradient
                    onClick={() => setIsMonthly(false)}
                    className={isMonthly ? null : 'active'}>
                    <Option>Yearly</Option>
                </OptionSelectionGradient>
            </OptionsContainer>
        </Container>
    );
};