import { travelAgencyReducerConstants } from '../constants';

export function travelAgency(state = {}, action) {

  switch (action.type) {
    
    case travelAgencyReducerConstants.GETALL_REQUEST:
        return {
            ...state,
            loading: true
        };

    case travelAgencyReducerConstants.GETALL_SUCCESS:
        return {
            agencies: action.agencies
        };

    case travelAgencyReducerConstants.GETALL_FAILURE:
        let { loading, ...stateCopy } = state; // remove loading flag
        return {
            ...stateCopy,
            error: action.error
        };

    case travelAgencyReducerConstants.GETBYID_REQUEST:
        return {
            loading: true
        };
    
    case travelAgencyReducerConstants.GETBYID_SUCCESS:
        return {
            ...action.agency
        };

    case travelAgencyReducerConstants.GETBYID_FAILURE:
        return {
            error: action.error
        };
    
    case travelAgencyReducerConstants.CLEAR_ALL:
        return {};

    default:
        return state
  }
}