import { http, authHeader, handleResponse, handleError } from "../utils";

export const subscriptionService = {
    getPlans,
    checkoutConfig,
    getByUserId,
    create,
    update,
    cancel,
};


async function getPlans(query) {
    let url = '/subscriptions/plans';
    if(query) url = url + `?${query.toString()}`;
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function checkoutConfig(query) {
    let url = '/subscriptions/checkout-configs';
    if(query) url = url + `?${query.toString()}`;
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getByUserId(userId, query) {
    let url = `/subscriptions/${userId}`;
    if(query) url = url + `?${query.toString()}`
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function create(data) {
    return await http.post("/subscriptions", data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function update(id, data) {
    return await http.put(`/subscriptions/${id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function cancel(id) {
    return await http.delete(`/subscriptions/${id}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}