import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from 'react-router-dom';

import { authActions, userActions, publicUsersActions, publicHotelActions } from '../../actions';
import { ScreenContainer } from './SearchContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { SearchList, SearchLink, SearchPageTopBar, SearchPageToolBar } from "../../components/search";
import { SideBar } from '../../components/custom/SideBar';
import { HotelFilters, Map } from '../../components/custom/Popup';
import { EmptyTripSearchResults } from '../../components/custom/EmptyState';
import { FullScreenSpinner } from "../../components/custom/Spinner";
import { history } from '../../utils';
import { sitemap, urlConstants, getProfileLink, getHotelLink, getSearchLink } from "../../constants";


export const SearchContainer = () => {
    const dispatch = useDispatch();

    const { username } = useParams();
    const { state, pathname } = useLocation();

    // application state
    const { cookie, user, creatorUser, loadingHotels, errorHotels, hotels, searchSession } = useSelector(state => ({
        cookie: state.auth.cookie,
        user: state.user,
        creatorUser: state.publicUsers.users[0],
        loadingHotels: state.publicHotel.loading,
        errorHotels: state.publicHotel.error,
        hotels: state.publicHotel.hotelsAtLocation,
        searchSession: state.publicHotel.searchSession,
    }));

    // component state
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [sidebarReset, setSidebarReset] = useState(false);
    const [sidebarPositionOffset, setSidebarPositionOffset] = useState(0);
    const [sticky, setSticky] = useState(false);
    const [init, setInit] = useState(false);
    const [isLoadingFirstResults, setIsLoadingFirstResults] = useState(false);
    const [isRetrying, setIsRetrying] = useState(false);
    const [items, setItems] = useState(null);
    const [type, setType] = useState('');
    const [typeLabel, setTypeLabel] = useState('');
    const [creator, setCreator] = useState('');
    const [search, setSearch] = useState({});
    const [isSearchScrollComplete, setIsSearchScrollComplete] = useState(false);
    const [isFirstBottomListCheck, setIsFirstBottomListCheck] = useState(false);
    const [isLoadingMoreResults, setIsLoadingMoreResults] = useState(false);
    const [filtersToggle, setFiltersToggle] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [filterState, setFilterState] = useState(null);
    const [filterResetTrigger, setFilterResetTrigger] = useState(null);
    const [sortModeState, setSortModeState] = useState(null);
    const [isMapVisible, setIsMapVisible] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [isNewSearchRequest, setIsNewSearchRequest] = useState(false);


    // observer to listen to last list item
    const observer = useRef();
    const lastItemRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        // load more search results when last list item is visible
        observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) setIsSearchScrollComplete(true);
        });
        if (node) observer.current.observe(node);
    }, []);


    const handleScroll = useCallback(event => {
        // Enable sticky mode on scroll and reset sidebar
        setSidebar(false);
        setSidebarReset(true);
        setSidebarPositionOffset(window.pageYOffset);
        setSticky(window.pageYOffset > 110);
        // load more search results when scroll reaches bottom of page
        // const { scrollTop, scrollHeight } = document.documentElement;
        // const viewportHeight = window.innerHeight;
        // const isBottomOfList = (scrollTop + viewportHeight) >= (scrollHeight - 250);
        // setIsSearchScrollComplete(isBottomOfList);
    }, []);


    // go back to previous screen
    const handleTopBarBackButton = useCallback(() => {
        // reset sidebar if enabled
        if(sidebar) {
            setSidebar(false);
            setSidebarReset(true);
            return;
        }

        // clear search results // TODO: also clear on sidebar navigation
        dispatch(publicHotelActions.clear());

        // go back
        history.push({ pathname: getProfileLink(username) });

    }, [username, sidebar, dispatch]);


    const handleSideBarToggle = useCallback(() => {
        setSidebar(!sidebar);
        setSidebarReset(false);
    }, [sidebar]);


    // generate sidebar options
    const sideBarOptions = useMemo(() => {
        if(authenticated && user && user.id) return { isAuthenticated: authenticated, creator: user.creator, basic: user.basic, premium: user.premium, platinum: user.platinum, links: user.pageData.metadata.links };
        return { isAuthenticated: authenticated };
    }, [authenticated, user]);


    // open hotel details page
    const handleViewDetails = useCallback(hotel => {
        if(!creator || !creator.username || !hotel || !hotel.id || !hotel.slug) return;

        // add session to params
        let search = (searchSession && searchSession.session) ? ('?session=' + searchSession.session) : null;

        history.push({
            pathname: getHotelLink(creator.username, hotel.slug),
            search,
            state: {
                from: getSearchLink(creator.username),
                data: {
                    hotelId: hotel.id,
                }
            }
        });

    }, [creator, searchSession]);


    const handleSearchExpired = useCallback(() => {
        setItems(null);
        setIsMapVisible(false);
    }, []);


    const handleFilter = useCallback((config) => {
        if(!config) {
            setIsFiltering(false);
            setFiltersToggle(false);
            return;
        }

        if(config.isResetTrigger) {
            setFilterState(null);
            setSortModeState(null);
            setFilterResetTrigger(null);
            setIsFiltering(false);
            setFiltersToggle(false);
            return;
        }

        // start filtering - shows spinner in filters
        setIsFiltering(true);

        if(config.isResetting) {
            setFilterState(null);
            setSortModeState(null);

        } else {
            // check if sorting is requested
            const isSortModeRequest = config.hasOwnProperty('isSortMode');

            if(isSortModeRequest) {
                // save sort mode
                setSortModeState(config.isSortMode > 0 ? config.isSortMode : null);
                // get saved filters and new sort mode
                config = { ...filterState, isSortMode: config.isSortMode };

            } else {
                // save new filters
                setFilterState(config);
                // get saved sort mode and new filters
                config = { ...config, isSortMode: sortModeState };
            }
        }

        // re-load search results based on filters
        let params = { pagination: true, session: searchSession.session };
        // set filters to query params
        if(config) params.filter = config;
        // call action
        dispatch(publicHotelActions.getSearchResultsBySession(params));

        setInit(false);
        setIsFirstBottomListCheck(true);

    }, [filterState, sortModeState, searchSession, dispatch]);


    const handleOpenMap = useCallback(() => {
        setIsMapVisible(true);
    }, []);


    const handleOpenTerms = useCallback((option) => {
        let url = null;

        switch(option) {
            case 'TERMS': {
                url = sitemap.landing.z.policies.terms;
                break;
            }

            case 'TERMS_CUSTOMERS': {
                url = sitemap.landing.z.policies.termsForCustomers;
                break;
            }

            case 'PRIVACY': {
                url = sitemap.landing.z.policies.privacy;
                break;
            }

            default:
                break;
        }

        if(url) window.open(urlConstants.baseUrlClient + url);
    }, []);


    // method to execute when the search button is clicked
    const onSearchClicked = useCallback(() => {
        setIsNewSearchRequest(true);
        setItems(null);
        setIsLoadingMoreResults(false);
        setIsRetrying(false);
    }, []);


    // method to execute when new search results are downloaded
    const onSearchCompleted = useCallback(success => {
        if(componentDidMount && init && success) {
            // reset filters
            setFilterResetTrigger('RESET');
            setFilterState(null);
            setSortModeState(null);
            // reset init to start results check
            setInit(false);
        }
    }, [componentDidMount, init]);


    /** componentDidMount **/
    useEffect(() => {
        if(!init && !componentDidMount) {
            // set component did mount
            setComponentDidMount(true);

            // load csrf token in cookies
            dispatch(authActions.loadCsrfToken());

            if(state) {
                // get data from previous page
                if(state.data) {
                    const { creator, search, type } = state.data;
                    // get creator data
                    if(creator) setCreator(creator);
                    // get search data
                    if(search) setSearch(search);
                    if(type) {
                        setType(type);
                        setTypeLabel(type === 'ACCOMMODATION' ? 'hotel' : 'experience');
                    }
                }
            }

            const hasSearchQuery = state && state.data && state.data.search;
            const hasSavedSessionQuery = searchSession && searchSession.query;
            const hasSavedSessionType = searchSession && searchSession.type;

            // redirect back if no required data found
            if(!hasSearchQuery && !hasSavedSessionQuery) {
                history.replace({ pathname: getProfileLink(username), state: { error: 'No data found' } });
                return;
            }

            // get creator's public profile data
            if(!state || !state.data || !state.data.creator) {
                dispatch(publicUsersActions.getByUsername(username));
            }

            // get saved search session query
            if(hasSavedSessionQuery) {
                setSearch({
                    ...searchSession.query,
                    adults: parseInt(searchSession.query.adults),
                    children: parseInt(searchSession.query.children),
                    infants: parseInt(searchSession.query.infants),
                    dates: {
                        data: {
                            from: new Date(searchSession.query.start),
                            to: new Date(searchSession.query.end),
                        },
                        from: {
                            date: searchSession.query.start,
                            label: searchSession.query.startFormatted
                        },
                        to: {
                            date: searchSession.query.end,
                            label: searchSession.query.endFormatted
                        },
                        totalDays: parseInt(searchSession.query.nights),
                    },
                });
            }

            // get saved search session type
            if(hasSavedSessionType) {
                setType(searchSession.type);
                setTypeLabel(searchSession.type === 'ACCOMMODATION' ? 'hotel' : 'experience');
            }

            // listen to scroll events
            window.addEventListener('scroll', handleScroll);
            // force scroll to top of page
            window.scrollTo(0, 0);

            // execute methods for authenticated users
            if(cookie && cookie.id) {
                // set authenticated mode
                setAuthenticated(true);
                // get user data from db
                if(!user || !user.id) dispatch(userActions.getById(cookie.id));
            }
        }

    }, [componentDidMount, init, cookie, user, username, handleScroll, state, dispatch, searchSession]);


    /** componentWillUnmount **/
    useEffect(() => {
        return () => {
            // remove scroll listener
            window.removeEventListener('scroll', handleScroll);
        }

    }, [handleScroll, dispatch]);

    useEffect(() => {
        return () => {
            // clear timer
            if(typingTimeout) clearTimeout(typingTimeout);
        }

    }, [typingTimeout]);


    /** componentDidUpdate **/
    useEffect(() => {
        if(componentDidMount) {

            // method to call action
            const loadSearchResultsBySession = (withFilters) => {
                let params = { stream: true, session: searchSession.session, page: searchSession.page, index: searchSession.index };
                // get retry attempts
                if(searchSession.hasOwnProperty('retry')) params.retry = searchSession.retry;
                // get filters
                if(withFilters) {
                    let filter = filterState;
                    if(sortModeState) filter = { ...filter, isSortMode: sortModeState };
                    if(filter) params.filter = filter;
                }
                // request hotel data from server
                dispatch(publicHotelActions.getSearchResultsBySession(params));
            };

            // request first search result data
            if(!init && !loadingHotels && !hotels && !errorHotels && searchSession && searchSession.session && searchSession.isInit && !isFiltering && !isLoadingFirstResults) {
                setIsLoadingFirstResults(true);
                setIsFirstBottomListCheck(true);
                loadSearchResultsBySession();
            }

            // method to retry getting the search results
            if(!init && !loadingHotels && !errorHotels && searchSession && searchSession.retry > 0 && !isFiltering && (isLoadingFirstResults || isLoadingMoreResults) && !isRetrying) {
                setIsRetrying(true);
                // retry after 3.5 seconds times the retry count
                setTypingTimeout(setTimeout(() => {
                    loadSearchResultsBySession(true);
                    setIsRetrying(false);
                }, 3500 * searchSession.retry));
                // clear timer
                if(typingTimeout) clearTimeout(typingTimeout);
            }

            // load hotel search results
            if(!init && !loadingHotels && hotels && !errorHotels && !searchSession.retry) {
                setInit(true);
                setItems(hotels);
                setIsLoadingFirstResults(false);
                setIsLoadingMoreResults(false);
                setIsRetrying(false);
                setIsNewSearchRequest(false);
                // stops filtering and closes filter popup
                setIsFiltering(false);
                setFiltersToggle(false);
            }

            // // check if list overflows below screen or not - for example, if only 1 hotel is listed then enable bootom of list so that it does not need scroll to fetch more results if any
            // if(init && !loadingHotels && items && !errorHotels && isFirstBottomListCheck && searchSession && !searchSession.retry && !isFiltering) {
            //     setIsFirstBottomListCheck(false);
            //     if(!items || items.length === 0) {
            //         setIsSearchScrollComplete(true);
            //     } else {
            //         // check if bottom of page is visible
            //         const { scrollTop, scrollHeight } = document.documentElement;
            //         const viewportHeight = window.innerHeight;
            //         const isBottomOfList = (scrollTop + viewportHeight) >= (scrollHeight - 250);
            //         setIsSearchScrollComplete(isBottomOfList);
            //     }
            // }

            // show error
            if(!loadingHotels && errorHotels) {
                setItems(null);
                setIsLoadingFirstResults(false);
                setIsLoadingMoreResults(false);
                setIsRetrying(false);
                setIsNewSearchRequest(false);
            }

            // save creator data
            if(init && !creator && creatorUser) {
                setCreator(creatorUser);
            }

            // retrieve more session search results from API
            if(init && isSearchScrollComplete && !loadingHotels && !errorHotels && !isLoadingMoreResults && !isLoadingFirstResults && !isRetrying && searchSession && searchSession.hasMoreResults && !searchSession.retry && !searchSession.auto) {
                setIsLoadingMoreResults(true);
                setIsSearchScrollComplete(false);
                loadSearchResultsBySession(true);
                setInit(false);
            }

            // auto-retrieve one more page of search results after initial results load if size was less than page limit
            if(init && hotels && !loadingHotels && !errorHotels && !isLoadingMoreResults && !isLoadingFirstResults && searchSession && searchSession.hasMoreResults && searchSession.auto && !searchSession.retry) {
                setIsLoadingMoreResults(true);
                setIsSearchScrollComplete(false);

                // retry after 3 seconds
                setTypingTimeout(setTimeout(() => {
                    loadSearchResultsBySession();
                    setInit(false);
                }, 3000));
                // clear timer
                if(typingTimeout) clearTimeout(typingTimeout);
            }
        }

    }, [componentDidMount, init, loadingHotels, hotels, items, errorHotels, isSearchScrollComplete, isFirstBottomListCheck, isLoadingFirstResults, isLoadingMoreResults, isRetrying, isFiltering, searchSession, creator, creatorUser, dispatch, typingTimeout, filterState, sortModeState]);



    return (
        <ScreenContainer id='screen'>
            <MetaTags title={`Best ${typeLabel} deals by @${creator.username}`} />

            <SearchPageTopBar
                imageUrl={creator.profilePhotoUrl}
                displayName={creator.displayName}
                username={creator.username}
                backButton={() => handleTopBarBackButton()}
                menuButton={() => handleSideBarToggle()} />

            <SearchLink
                data={{ type, config: search }}
                hideTitle={true}
                fullScreen={true}
                noBorderWidget={true}
                onSuccess={onSearchCompleted}
                handleSearch={onSearchClicked} />

            { !isNewSearchRequest && items &&
            <SearchPageToolBar
                sticky={sticky}
                resultsCount={searchSession && searchSession.total}
                offersCount={searchSession && searchSession.offersCount}
                isSearchProcessingComplete={searchSession && searchSession.isResultsSerializationCompleted}
                createdAt={searchSession && searchSession.createdAt}
                ttl={searchSession && searchSession.ttl}
                type={type}
                handleSearchExpired={handleSearchExpired}
                handleFilter={() => setFiltersToggle(prev => !prev)}
                handleSort={mode => handleFilter({ isSortMode: mode })}
                handleMap={handleOpenMap} /> }

            { init &&
            <SideBar
                options={sideBarOptions}
                toggle={handleSideBarToggle}
                from={pathname}
                enable={sidebar}
                reset={sidebarReset}
                position={sidebarPositionOffset} /> }

            <HotelFilters
                show={filtersToggle}
                processing={isFiltering}
                resetTrigger={filterResetTrigger}
                // onHide={() => setFiltersToggle(false)}
                handleFilter={handleFilter} />

            <Map
                show={isMapVisible}
                onHide={() => setIsMapVisible(false)}
                data={{
                    places: items,
                    center: (searchSession && searchSession.query) ? [searchSession.query.selectedLocationLat, searchSession.query.selectedLocationLng] : null,
                    name: (searchSession && searchSession.query) ? searchSession.query.location : 'Location Search'
                }}
                handleSelectedItemClick={handleViewDetails}
                handleLoadMore={() => setIsSearchScrollComplete(true)}
                hasMoreToLoad={searchSession && searchSession.hasMoreResults}
                loading={isLoadingMoreResults}
            />

            <SearchList
                data={items}
                lastItemRef={lastItemRef}
                loading={isLoadingMoreResults}
                type={type}
                sticky={sticky}//added for sticky search toolbar
                handleViewDetails={handleViewDetails}
                handleOpenTerms={handleOpenTerms} />

            { componentDidMount && !loadingHotels && !isNewSearchRequest && !isRetrying && !items && <EmptyTripSearchResults type={type} error={errorHotels} /> }

            { (loadingHotels || isNewSearchRequest || isRetrying) && !isLoadingMoreResults && <FullScreenSpinner /> }
        </ScreenContainer>
    );
};