import { otherConstants } from '../constants';

// Check Null values and return empty ""
export function notNull(value){
    if(value) return value;
    return "";
}

// find the greatest common divisor (GCD) and divide both values by that
export function gcd (a,b){
    if (b === 0) return a;
    return gcd (b, a % b);
}

/**
 * Get Object URL from Blob file
 * 
 * @param {Blob} blob Takes a Blob file
 * @param {String} previousFileUrl The previous Object URL created to release
 * @returns Object URL
 */
export function getObjectURLFromBlobFile(blob, previousFileUrl) {
    try {
        window.URL.revokeObjectURL(previousFileUrl);
        return window.URL.createObjectURL(blob);
    } catch (e) {
        throw new Error(e);
    }
}

/**
 * Get Blob from Object URL - implement with async/await
 * 
 * @param {String} url The Object URL of the file.
 * @returns new file
 */
export function getBlobFromObjectURL(url) {
    try {
        return fetch(url).then(r => r.blob());
    } catch (e) {
        throw new Error(e);
    }
}

/**
 * Get file from Object URL - implement with async/await
 * 
 * @param {String} url The Object URL of the file.
 * @param {String} fileName The file name of the file.
 * @param {String} fileType The file type of the file ie. "image/png"
 * @returns new file
 */
export function getFileFromObjectURL(url, fileName, fileType) {
    try {
        return fetch(url).then(r => r.blob()).then(blobFile => new File([blobFile], fileName, { type: fileType }));
    } catch (e) {
        throw new Error(e);
    }
}

/**
 * Validate social media profile URLs
 * Ref:
 *  - https://regex101.com/
 *  - https://regex-generator.olafneumann.org/
 * 
 * @param {String} url The social media profile URL
 * @returns A String with the URLs platform name
 */
export function validateSocialUrl(url){
    if (/^(https?:\/\/)?((w{3}\.)?)twitter\.com\/(#!\/)?[a-z0-9_]+$/i.test(url)) return 'Twitter';
    else if (/^(https?:\/\/)?((w{3}\.)?)facebook\.com\/.*/i.test(url)) return 'Facebook';
    else if (/^(https?:\/\/)?((w{3}\.)?)youtube\.com\/(user)?(c)?(channel)?\/.*/i.test(url)) return 'YouTube';
    else if (/^(https?:\/\/)?((w{3}\.)?)instagram\.com\/.*/i.test(url)) return 'Instagram';
    else if (/^(https?:\/\/)?((w{3}\.)?)tiktok\.com\/@.*/i.test(url)) return 'TikTok';
    else if (/^(https?:\/\/)?((w{3}\.)?)github\.com\/.*/i.test(url)) return 'Github';
    else if (/^(https?:\/\/)?((w{3}\.)?)twitch\.tv\/.*/i.test(url)) return 'Twitch';
    else if (/^(https?:\/\/)?((w{3}\.)?)discord\.(gg)?(com)?\/.*/i.test(url)) return 'Discord';
    else if (/^(https?:\/\/)?((w{3}\.)?)patreon\.com\/.*/i.test(url)) return 'Patreon';
    else if (/^(https?:\/\/)?((w{3}\.)?)pinterest\.(com)?(co.uk)?\/.*/i.test(url)) return 'Pinterest';
    else if (/^(https?:\/\/)?((w{3}\.)?)open.spotify\.com\/artist\/.*/i.test(url)) return 'Spotify';
    else if (/^(https?:\/\/)?((w{3}\.)?)t\.me\/.*/i.test(url)) return 'Telegram';
    else if (/^\+[0-9]*$/i.test(url)) return 'WhatsApp';
    else if (/^(https?:\/\/).*\.substack\.com/i.test(url)) return 'SubStack';
    else if (/^(https?:\/\/)?((w{3}\.)?)soundcloud\.com\/.*/i.test(url)) return 'SoundCloud';
    else if (/^(https?:\/\/)?((w{3}\.)?)snapchat\.com\/add\/.*/i.test(url)) return 'SnapChat';
    else if (/^(https?:\/\/)?((w{3}\.)?)etsy\.com\/shop\/.*/i.test(url)) return 'Etsy';
    else if (/^(https?:\/\/)?((w{3}\.)?)clubhouse\.com\/@.*/i.test(url)) return 'ClubHouse';
    else if (/^(https?:\/\/)www.cameo\.com\/.*/i.test(url)) return 'Cameo';
    else if (/^(https?:\/\/).*\.bandcamp\.com/i.test(url)) return 'BandCamp';
    else if (/^(https?:\/\/)music\.apple\.com\/.*\/(album)?(artist)?\/.*/i.test(url)) return 'Apple Music';
    else if (/^(https?:\/\/)podcasts\.apple\.com\/.*\/podcast\/.*\/.*/i.test(url)) return 'Apple Podcast';
    else if (/^(https?:\/\/)apps\.apple\.com\/.*\/app\/.*\/.*/i.test(url)) return 'Apple App Store';
    else if (/^(https?:\/\/)play\.google\.com\/store\/apps\/details\?.*/i.test(url)) return 'Android Play Store';
    else if (/^(https?:\/\/)?((w{3}\.)?)amazon\.(com)?(co.uk)?(com.au)?(com.br)?(ca)?(cn)?(fr)?(de)?(in)?(it)?(co.jp)?(com.mx)?(nl)?(pl)?(sg)?(es)?(com.tr)?(ae)?\/shop\/.*/i.test(url)) return 'Amazon';
    else if (/^(https?:\/\/)?((w{3}\.)?)amazon\.(com)?(co.uk)?(com.au)?(com.br)?(ca)?(cn)?(fr)?(de)?(in)?(it)?(co.jp)?(com.mx)?(nl)?(pl)?(sg)?(es)?(com.tr)?(ae)?\/stores\/.*\/page\/.*/i.test(url)) return 'Amazon';
    else return 'unknown';
}

/**
 * Generate twitter profile URL by handle
 * 
 * @param {String} handle The handle starting with an '@'
 * @returns String with full twitter profile url
 */
export function buildTwitterUrlFromHandle(handle){
    if (handle.charAt(0) === "@") return "https://www.twitter.com/" + handle.substring(1);
    return "https://www.twitter.com/" + handle;
}

/**
 * Generate tiktok profile URL by handle
 * 
 * @param {String} handle The handle starting with an '@'
 * @returns String with full tiktok profile url
 */
 export function buildTikTokUrlFromHandle(handle){
    return "https://www.tiktok.com/" + handle;
}

/**
 * Extract social media handle from url
 * 
 * @param {String} platform String of platform type
 * @param {String} url The social url string
 * @returns String with social media handle
 */
export function getHandleFromSocialUrl(platform, url){
    switch(platform) {
        case "YouTube":
            return url.replace(/^(https?:\/\/)?(w{3}\.)?youtube\.com\/(user)?(c)?(channel)?\//, "@");
        case "Twitter":
            return url.replace(/^(https?:\/\/)?(w{3}\.)?twitter\.com\//, "@");
        case "TikTok":
            return url.replace(/^(https?:\/\/)?(w{3}\.)?tiktok\.com\/@/, "@");
        case "Facebook":
            return url.replace(/^(https?:\/\/)?(w{3}\.)?facebook\.com\//, "@");
        case "Instagram":
            return url.replace(/^(https?:\/\/)?(w{3}\.)?instagram\.com\//, "@");
        case "Github":
            return url.replace(/^(https?:\/\/)?(w{3}\.)?github\.com\//, "@");
        case "Twitch":
            return url.replace(/^(https?:\/\/)?(w{3}\.)?twitch\.tv\//, "@");
        case "Discord":
            return url.replace(/^(https?:\/\/)?((w{3}\.)?)discord\.(gg)?(com)?\//, "");
        case "Patreon":
            return url.replace(/^(https?:\/\/)?(w{3}\.)?patreon\.com\//, "@");
        case "Pinterest":
            return url.replace(/^(https?:\/\/)?(w{3}\.)?pinterest\.com\//, "@");
        case "Spotify":
            return url.replace(/^(https?:\/\/)?((w{3}\.)?)open.spotify\.com\/artist\//, "");
        case "Telegram":
            return url.replace(/^(https?:\/\/)?((w{3}\.)?)t\.me\//, "@");
        case "SubStack": {
            const firstPartRemoved = url.replace(/^(https?:\/\/)/, "@");
            return firstPartRemoved.replace(/.substack\.com?(\/)/, "");
        }
        case "SoundCloud":
            return url.replace(/^(https?:\/\/)?(w{3}\.)?soundcloud\.com\//, "@");
        case "SnapChat":
            return url.replace(/^(https?:\/\/)?((w{3}\.)?)snapchat\.com\/add\//, "@");
        case "Etsy":
            return url.replace(/^(https?:\/\/)?((w{3}\.)?)etsy\.com\/shop\//, "@");
        case "ClubHouse":
            return url.replace(/^(https?:\/\/)?(w{3}\.)?clubhouse\.com\/@/, "@");
        case "Cameo":
            return url.replace(/^(https?:\/\/)www.cameo\.com\//, "@");
        case "BandCamp": {
            const firstPartRemoved = url.replace(/^(https?:\/\/)/, "@");
            return firstPartRemoved.replace(/.bandcamp\.com?(\/)/, "");
        }
        case "Apple Music": {
            const firstPartRemoved = url.replace(/^(https?:\/\/)music\.apple\.com\/.*\/(album)?(artist)?\//, "");
            return firstPartRemoved.replace(/\/.*/, "");
        }
        case "Apple Podcast": {
            const firstPartRemoved = url.replace(/^(https?:\/\/)podcasts\.apple\.com\/.*\/podcast\//, "@");
            return firstPartRemoved.replace(/\/.*/, "");
        }
        case "Apple App Store": {
            const firstPartRemoved = url.replace(/^(https?:\/\/)apps\.apple\.com\/.*\/app\//, "");
            return firstPartRemoved.replace(/\/.*/, "");
        }
        case "Android Play Store":
            return url.replace(/^(https?:\/\/)play\.google\.com\/store\/apps\/details\?.*=/, "");
        case "Amazon": {
            if((/^(https?:\/\/)?((w{3}\.)?)amazon\.(com)?(co.uk)?(com.au)?(com.br)?(ca)?(cn)?(fr)?(de)?(in)?(it)?(co.jp)?(com.mx)?(nl)?(pl)?(sg)?(es)?(com.tr)?(ae)?\/shop\/.*/i.test(url))) {
                return url.replace(/^(https?:\/\/)?((w{3}\.)?)amazon\.(com)?(co.uk)?(com.au)?(com.br)?(ca)?(cn)?(fr)?(de)?(in)?(it)?(co.jp)?(com.mx)?(nl)?(pl)?(sg)?(es)?(com.tr)?(ae)?\/shop\//, "@");
            } else {
                const firstPartRemoved = url.replace(/^(https?:\/\/)?((w{3}\.)?)amazon\.(com)?(co.uk)?(com.au)?(com.br)?(ca)?(cn)?(fr)?(de)?(in)?(it)?(co.jp)?(com.mx)?(nl)?(pl)?(sg)?(es)?(com.tr)?(ae)?\/stores\//, "@");
                return firstPartRemoved.replace(/\/page\/.*/, "");
            }
        }
        default:
            return url;
    }
}


/**
 * Make user address readable
 * 
 * @param {JSON Object} address The user address fields
 * @returns String of pretty user address
 */
export function makeUserAddressPretty(address){
    try {
        let text = [];
        if(address.addressLine1) text.push(address.addressLine1);
        if(address.addressLine2) text.push(address.addressLine2);
        if(address.city) text.push(address.city);
        if(address.state) text.push(address.state);
        if(address.postalCode) text.push(address.postalCode);
        if(address.country) text.push(address.country);

        if (text) return text.join(', ');
        return '';
    } catch (e) {
        throw new Error(e);
    }
}

/**
 * Appends referrer to outgoing url
 * 
 * @param {String} url String of the url
 * @returns String of the url with referrer appended
 */
export function appendReferrer(url){
    return url + '?ref=' + otherConstants.domain;
}


/**
 * Method to process and construct photo url addresses
 * 
 * @param {String} url The photo URL to process
 * @returns The fully processed photo url
 */
export function constructPhotoUrl(url){
    // construct firebase photo urls
    if(url.startsWith('https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/')) return url + '&' + Date.now();
    // construct google authentication photo url
    else if(url.startsWith('https://lh3.googleusercontent.com/')) return url + '?updatedAt=' + Date.now();
    // construct facebook authentication photo url
    else if(url.startsWith('https://graph.facebook.com/')) return url + '?updatedAt=' + Date.now();
}


/**
 * Method to generate a firebase photo url based on given path
 * 
 * @param {String} path Path of photo
 * @returns String with the full url
 */
export function generateFirebasePhotoLink(path){
    return 'https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/o/' + path;
}


/**
 * Method to format an address with HTML
 * 
 * @param {ARRAY} address Array with address line strings
 * @returns HTML with the formatted address
 */
export function formatAddressHTML(address) {
    return address.map((line, index) => {
        if(index !== (address.length - 1)) return (<span key={index}>{line}<br/></span>);
        return (<span key={index}>{line}</span>);
    });
}


/**
 * Method to calculate the time difference between 2 dates
 * 
 * @param {DATE Object} a The first (past) date
 * @param {DATE Object} b The second (future) date
 * @param {STRING} mode Output mode option
 * @returns The time difference in selected mode
 */
export function dateDifferenceIn(a, b, mode) {
    const MS_PER_SECOND = 1000;
    const MS_PER_MINUTE = MS_PER_SECOND * 60;
    const MS_PER_HOUR = MS_PER_MINUTE * 60;
    const MS_PER_DAY = MS_PER_HOUR * 24;

    // calculate difference based on miliseconds
    const diff = b.getTime() - a.getTime();

    // get diff based on mode
    switch(mode) {
        case 'DAYS':
            return diff / MS_PER_DAY;
        
        case 'HOURS':
            return diff / MS_PER_HOUR;
        
        case 'MINUTES':
            return diff / MS_PER_MINUTE;

        // SECONDS
        default:
            return diff / MS_PER_SECOND;
    }
}