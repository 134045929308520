import styled from 'styled-components';
import { deviceConstants } from '../../../constants';
import { Logo } from '../../../components/custom/Branding';


export const ScreenContainer = styled.div`
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;


    @media ${deviceConstants.tablet} { 
        padding-left: 33%;
        padding-right: 33%;
    }

    @media ${deviceConstants.laptop} { 
        padding-left: 39%;
        padding-right: 39%;
    }

    @media ${deviceConstants.desktop} { 
        padding-left: 41.8%;
        padding-right: 41.8%;
    }
`;

export const StyledLogo = styled(Logo)`
    width: 144px;
    position: absolute;
    top: 0;
    margin-top: 40px;
`;