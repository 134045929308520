import { http, handleResponse, handleError } from "../utils";

export const publicLinksService = {
    getAll,
    filter
};

async function getAll() {
    return await http.get("/public/links")
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function filter(data) {
    return await http.get(`/public/links?${data.toString()}`)
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}