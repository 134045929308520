import { useMemo } from 'react';
import styled from 'styled-components';
import { InfoCircle } from 'react-bootstrap-icons';

import { colorConstants } from '../../constants';
import { addCurrencySymbol } from '../../utils';
import { Button } from '../custom/Buttons';
import { NoEarningsFound } from '../custom/EmptyState';



const Container = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;
`;

const Payable = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Total = styled.div`
    font-weight: 700;
    font-size: 24px;
    color: ${colorConstants.primaryDark};
`;

const InfoTitle = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;

    font-weight: 600;
    color: ${colorConstants.darkGrey};
`;

const InfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const InfoRowText = styled.div`
    width: 75%;
    font-weight: 400;
    color: ${colorConstants.darkGrey};
`;

const InfoRowSubText = styled.div`
    margin-top: 12px;

    font-weight: 400;
    font-size: 10px;
    color: ${colorConstants.darkGrey};
`;

const InfoRowAmount = styled.div`
    font-weight: 600;
    color: ${colorConstants.darkGrey};
`;



const SettingsEarnings = ({ data, onClick }) => {

    const hasPayable = useMemo(() => {
        if(data && data.totals && data.totals.payable > 0) return false; // FEATURE: enable payout request
        return false;
    }, [data]);

    if(!data || !data.totals || (data.totals.sales === 0 && data.totals.payable === 0 && data.totals.processing === 0 && data.totals.paid === 0)) return <NoEarningsFound />;

    return (
        <Container>
            <Payable>
                <Total>{addCurrencySymbol(data.currency, data.totals.payable / 100)}</Total>
                { hasPayable && <Button outline={true} small={true} onClick={onClick} >Collect</Button> }
            </Payable>
            <InfoRowSubText><InfoCircle color={colorConstants.secondaryDark} size={10} /> These are your payable earnings, estimated after applicable daily currency conversion rates.</InfoRowSubText>

            <InfoTitle>Sales</InfoTitle>
            <InfoRow>
                <InfoRowText>The total sales amount you generated</InfoRowText>
                <InfoRowAmount>{addCurrencySymbol(data.currency, data.totals.sales / 100)}</InfoRowAmount>
            </InfoRow>
            <InfoRowSubText><InfoCircle color={colorConstants.secondaryDark} size={10} /> These funds become payable after any refund or other applicable policies expire.</InfoRowSubText>

            <InfoTitle>Outgoing</InfoTitle>
            <InfoRow>
                <InfoRowText>The total amount currently on the way to your bank account</InfoRowText>
                <InfoRowAmount>{addCurrencySymbol(data.currency, data.totals.processing / 100)}</InfoRowAmount>
            </InfoRow>
            <InfoRowSubText><InfoCircle color={colorConstants.secondaryDark} size={10} /> Every 21st of the month we send any payable earnings to your bank account.</InfoRowSubText>

            <InfoTitle>Paid</InfoTitle>
            <InfoRow>
                <InfoRowText>The total amount of earnings paid from Bunjee to you so far</InfoRowText>
                <InfoRowAmount>{addCurrencySymbol(data.currency, data.totals.paid / 100)}</InfoRowAmount>
            </InfoRow>
        </Container>
    );
};

export default SettingsEarnings;