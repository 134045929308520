import styled from 'styled-components';


export const Container = styled.div`
    border: solid 2px ${props => props.borderColor};
    border-radius: ${props => props.shape};
    background-color: ${props => props.color};

    div {
        color: ${props => props.fontColor};
    }

    :hover {
        cursor: pointer;
        background-color: ${props => props.colorHover};

        div {
            color: ${props => props.fontColorHover};
        }
    }
`;

export const StyledLink = styled.a`
    padding: 12px 30px;
    text-decoration: none;
    display: block;
    width: 100%;
`;

export const Name = styled.div`
    font-family: ${props => props.fontType || 'inherit'} !important;
    font-size: 15px;
    font-weight: 600;
    margin: 0;

    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: none;
`;