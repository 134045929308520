import React, { Fragment } from 'react';
import { Switch, Redirect } from "react-router-dom";

import { PrivateRoute } from './PrivateRoute';
import { RestrictRoute } from './RestrictRoute';
import { RequestLoginRoute } from './RequestLoginRoute';
import { PublicRoute } from './PublicRoute';

import { TravelLandingContainer } from '../containers/home';
import { PoliciesCenter, PrivacyPolicy, TermsOfService, CustomerTermsOfService, AssociateTerms, DataProcessingAddendum, CookiePolicy } from '../containers/home/policies';
import { WhitelistEmail } from '../containers/faq'; // FEATURE: Move this to Help Center
import { UpgradeContainer, UpgradeCheckoutContainer } from '../containers/upgrade';
import { LoginContainer, RegisterContainer, LogoutContainer, SocialAuthContainer, ChangePasswordContainer, ResetPasswordContainer, ChangeEmailContainer, ConfirmContainer } from '../containers/auth';
import { DashboardContainer } from '../containers/dashboard';
import { ProfileContainer } from '../containers/profile';
import { SettingsContainer } from '../containers/settings';
import { CustomizeContainer } from '../containers/customize';
import { MyTripsContainer, AddTripContainer, EditTripContainer, TripContainer, TripCheckoutContainer } from '../containers/trips';
import { SearchContainer } from '../containers/search';
import { HotelContainer } from '../containers/hotels';
import { MyLinksContainer, AddGithubProjectContainer, EditGithubProjectContainer } from '../containers/links';
import { MyProductsContainer, AddProductContainer, EditProductContainer, ViewProductContainer } from '../containers/products';
import { MyNFTsContainer, AddNFTContainer, EditNFTContainer, ViewNFTContainer } from '../containers/nfts';
import { MyFavouritesContainer } from '../containers/favourites';
import { MyBookingsContainer, BookingDetailsContainer } from '../containers/bookings';

import { sitemap } from '../constants';


export const MainRouter = () => (
    <Fragment>
        <Switch>

            {/*** LANDING PAGE ROUTES ***/}
            <RestrictRoute exact path={sitemap.landing.home} component={TravelLandingContainer} />
            {/* <PublicRoute exact path={sitemap.landing.z.travellers} component={TravelLandingContainer} /> */}
            {/* <PublicRoute exact path={sitemap.landing.z.techies} component={TechiesEarlyAccessContainer} /> */}
            {/* <PublicRoute exact path={sitemap.landing.z.nft} component={NFTLandingContainer} /> */}
            {/*** POLICY PAGES ROUTES ***/}
            <PublicRoute exact path={sitemap.landing.z.policies.center} component={PoliciesCenter} />
            <PublicRoute exact path={sitemap.landing.z.policies.privacy} component={PrivacyPolicy} />
            <PublicRoute exact path={sitemap.landing.z.policies.terms} component={TermsOfService} />
            <PublicRoute exact path={sitemap.landing.z.policies.termsForCustomers} component={CustomerTermsOfService} />
            <PublicRoute exact path={sitemap.landing.z.policies.termsForAssociates} component={AssociateTerms} />
            <PublicRoute exact path={sitemap.landing.z.policies.dpa} component={DataProcessingAddendum} />
            <PublicRoute exact path={sitemap.landing.z.policies.cookie} component={CookiePolicy} />
            {/*** FAQ PAGES ROUTES ***/}
            <PublicRoute exact path={sitemap.landing.help.whitelistEmailInstruction} component={WhitelistEmail} />


            {/*** AUTHENTICATION ROUTES ***/}
            <RestrictRoute path={sitemap.auth.login} component={LoginContainer} />
            <RestrictRoute path={sitemap.auth.register} component={RegisterContainer} />
            <RestrictRoute exact path={sitemap.auth.social.google} component={SocialAuthContainer} />
            <RestrictRoute exact path={sitemap.auth.social.facebook} component={SocialAuthContainer} />
            <PrivateRoute path={sitemap.auth.logout} component={LogoutContainer} />


            {/*** UPGRADE ROUTES ***/}
            <RequestLoginRoute path={sitemap.billing.upgrade} component={UpgradeContainer} />
            <RequestLoginRoute path={sitemap.billing.checkout} component={UpgradeCheckoutContainer} />


            {/*** PROFILE & ACCOUNT SETTINGS ROUTES ***/}
            <PrivateRoute exact path={sitemap.admin.dashboard} component={DashboardContainer} />
            <PrivateRoute path={sitemap.admin.settings} component={SettingsContainer} />
            <PrivateRoute path={sitemap.admin.customize} component={CustomizeContainer} />

            <PrivateRoute path={sitemap.auth.password.change} component={ChangePasswordContainer} />
            <RestrictRoute path={sitemap.auth.password.reset} component={ResetPasswordContainer} />
            <PrivateRoute path={sitemap.auth.email.change} component={ChangeEmailContainer} />
            <RequestLoginRoute path={sitemap.auth.confirm} component={ConfirmContainer} />


            {/*** LINK ROUTES ***/}
            <PrivateRoute exact path={sitemap.admin.links.list} component={MyLinksContainer} />
            <PrivateRoute exact path={sitemap.admin.links.github.add} component={AddGithubProjectContainer} />
            <PrivateRoute exact path={sitemap.admin.links.github.edit} component={EditGithubProjectContainer} />


            {/*** TRIP ROUTES ***/}
            <PrivateRoute exact path={sitemap.admin.trips.list} component={MyTripsContainer} />
            <PrivateRoute path={sitemap.admin.trips.add} component={AddTripContainer} />
            <PrivateRoute path={sitemap.admin.trips.edit} component={EditTripContainer} />
            <PrivateRoute path={sitemap.admin.trips.checkout} component={TripCheckoutContainer} />


            {/*** PRODUCT ROUTES ***/}
            <PrivateRoute exact path={sitemap.admin.products.list} component={MyProductsContainer} />
            <PrivateRoute path={sitemap.admin.products.add} component={AddProductContainer} />
            <PrivateRoute path={sitemap.admin.products.edit} component={EditProductContainer} />


            {/*** NFT ROUTES ***/}
            <PrivateRoute exact path={sitemap.admin.nft.list} component={MyNFTsContainer} />
            <PrivateRoute path={sitemap.admin.nft.add} component={AddNFTContainer} />
            <PrivateRoute path={sitemap.admin.nft.edit} component={EditNFTContainer} />
            

            {/*** FAVOURITES ROUTES ***/}
            <PrivateRoute exact path={sitemap.admin.favourites} component={MyFavouritesContainer} />


            {/*** BOOKINGS ROUTES ***/}
            <PublicRoute exact path={sitemap.admin.bookings.list} component={MyBookingsContainer} />
            <PublicRoute exact path={sitemap.admin.bookings.view} component={BookingDetailsContainer} />


            {/*** PUBLIC PROFILE ROUTES ***/}
            <PublicRoute exact path={sitemap.admin.profile} component={ProfileContainer}/>
            <PublicRoute path={sitemap.admin.trips.view} component={TripContainer} />
            <PublicRoute path={sitemap.admin.nft.view} component={ViewNFTContainer} />
            <PublicRoute path={sitemap.admin.products.view} component={ViewProductContainer} />


            {/*** PUBLIC TRAVEL ROUTES ***/}
            <PublicRoute exact path={sitemap.admin.travel.search} component={SearchContainer} />
            <PublicRoute exact path={sitemap.admin.travel.hotel} component={HotelContainer} />
            <PrivateRoute exact path={sitemap.admin.travel.hotelCheckout} component={TripCheckoutContainer} />


            {/*** REDIRECT UN-KNOWN ROUTES TO HOME ***/}
            <Redirect from="*" to={sitemap.landing.unknown} />
        </Switch>
    </Fragment>
)