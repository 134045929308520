import { publicUsersReducerConstants } from "../constants";
import { publicUsersService } from "../services";
import { alertActions } from './';

export const publicUsersActions = {
    // getAll,
    getByUsername,
    // filter,
    getUsernameAvailability,
    reset
};

// function getAll() {
//     return dispatch => {
//         dispatch(request());

//         publicUsersService.getAll()
//             .then(
//                 users => dispatch(success(users)),
//                 error => dispatch(failure(error.toString()))
//             );
//     };

//     function request() { return { type: publicUsersReducerConstants.GETALL_REQUEST } }
//     function success(users) { return { type: publicUsersReducerConstants.GETALL_SUCCESS, users } }
//     function failure(error) { return { type: publicUsersReducerConstants.GETALL_FAILURE, error } }
// }

function getByUsername(username) {
    return dispatch => {
        dispatch(request({ username }));

        publicUsersService.getByUsername(username)
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(user) { return { type: publicUsersReducerConstants.GETBYUSERNAME_REQUEST, user } }
    function success(user) { return { type: publicUsersReducerConstants.GETBYUSERNAME_SUCCESS, user } }
    function failure(error) { return { type: publicUsersReducerConstants.GETBYUSERNAME_FAILURE, error } }
}

// function filter(data) {
//     return dispatch => {
//         dispatch(request());

//         publicUsersService.filter(data)
//             .then(
//                 users => dispatch(success(users)),
//                 error => dispatch(failure(error.toString()))
//             );
//     };

//     function request() { return { type: publicUsersReducerConstants.FILTER_REQUEST } }
//     function success(users) { return { type: publicUsersReducerConstants.FILTER_SUCCESS, users } }
//     function failure(error) { return { type: publicUsersReducerConstants.FILTER_FAILURE, error } }
// }

function getUsernameAvailability(username) {
    return dispatch => {
        dispatch(request());

        publicUsersService.getUsernameAvailability(username)
            .then(
                res => {
                    dispatch(success(res));
                    if(!res.available) dispatch(alertActions.success('Username is invalid'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Username is invalid'));
                }
            );
    };

    function request() { return { type: publicUsersReducerConstants.USERNAME_AVAILABILITY_REQUEST } }
    function success(username) { return { type: publicUsersReducerConstants.USERNAME_AVAILABILITY_SUCCESS, username } }
    function failure(error) { return { type: publicUsersReducerConstants.USERNAME_AVAILABILITY_FAILURE, error } }
}

function reset() {
    return { type: publicUsersReducerConstants.RESET_ALL };
}