import React, { Component } from "react";
import { connect } from 'react-redux';
import qs from 'qs';

import { authActions, alertActions, userActions } from '../../../actions';
import { ScreenContainer } from './ConfirmContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { Notification } from '../../../components/custom/Popup';
import ConfirmEmail from "../../../components/auth/confirm/email/ConfirmEmail";
import { TopBar } from '../../../components/custom/TopBar';
import { SideBar } from '../../../components/custom/SideBar';
import { history, defaultUser } from '../../../utils';
import { topbarConstants, sitemap } from "../../../constants";


class ConfirmContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            emailConfirmationRequested: false,
            emailConfirmation: "",
            mode: '',
            redirectCounter: 5,
            timer: "",
            sidebar: false,
            sidebarReset: false,
            sidebarPositionOffset: 0,
            sticky: false,
            user: defaultUser()
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuButton = this.handleMenuButton.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }
    
    componentDidMount() {
        // redirect to homepage if no confirmation query
        if (!this.props.location.search) {
            history.push({
                pathname: sitemap.admin.dashboard,
                state: {
                    from: sitemap.auth.confirm
                }
            });
            return;
        }

        if(!this.props.user || !this.props.user.id) {
            // get user data from db
            this.props.getById(this.props.userId);
        } else if(!this.state.init && this.props.user && this.props.user.id) {
            // load user data from server into component state
            this.setState({ ...this.state, init: true, user: this.props.user });
        }

        window.addEventListener('scroll', this.handleScroll);

        // get query from link
        let query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        // save confirmation code to state
        if (query && query.emailConfirmation && !this.state.emailConfirmation) this.setState({ emailConfirmation: query.emailConfirmation });

        // save confirmation mode to state
        if (query && query.mode && !this.state.mode) this.setState({ mode: query.mode });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.clearAlert(); // reset alert in redux state
    }

    componentDidUpdate() {
        // load user data from server into component state
        if (!this.state.init && this.props.user && this.props.user.id) {
            this.setState({ ...this.state, init: true, user: this.props.user });
        }

        // send email confirmation request
        if (this.state.init && !this.state.emailConfirmationRequested && this.state.emailConfirmation){
            this.setState({
                emailConfirmationRequested: true // mark as requested
            }, () => this.state.mode !== 'test' ? this.props.confirmEmail(this.state.user.id, { email: this.state.user.email, emailConfirmation: this.state.emailConfirmation }) : null);
        }

        // start redirect counter after success alert is received
        if (!this.state.timer && this.state.emailConfirmationRequested && ((this.props.alert && this.props.alert.type === 'alert-success') || this.state.mode === 'test')){
            this.setState({
                timer: setInterval(() => { this.setState({ redirectCounter: this.state.redirectCounter - 1 }) }, 1000),
            });    
        }

        // redirect to dashboard after counter completes or if mode is set to 'firebase'
        if (this.state.redirectCounter === 0) {
            clearInterval(this.state.timer);
            this.setState({ ...this.state, redirectCounter: 5 });

            // redirect to dashboard
            history.push({
                pathname: sitemap.admin.dashboard,
                state: {
                    from: sitemap.auth.confirm
                }
            });
        }
    }

    handleScroll(event) {
        // Enable sticky mode on scroll and reset sidebar
        this.setState({ ...this.state, sidebar: false, sidebarReset: true, sidebarPositionOffset: window.pageYOffset, sticky: window.pageYOffset > 1 });
    }

    handleMenuButton() {
        this.setState({ ...this.state, sidebar: !this.state.sidebar, sidebarReset: false });
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.confirm} />

                <TopBar
                    title={topbarConstants.titles.confirm}
                    sticky={this.state.sticky}
                    menuButton={this.handleMenuButton} />

                {this.state.init &&
                <SideBar
                    options={{ isAuthenticated: true, creator: this.state.user.creator, basic: this.state.user.basic, premium: this.state.user.premium, platinum: this.state.user.platinum, links: this.state.user.pageData.metadata.links }}
                    toggle={this.handleMenuButton}
                    enable={this.state.sidebar}
                    reset={this.state.sidebarReset}
                    position={this.state.sidebarPositionOffset} /> }

                <ConfirmEmail
                    alert={this.props.alert}
                    test={this.state.mode === 'test'}
                    redirectCounter={this.state.redirectCounter} />

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user } = state;
    
    // export state data to props
    return { userId, alert, user };
}

const actionCreators = {
    confirmEmail: authActions.confirmEmail,
    getById: userActions.getById,
    clearAlert: alertActions.clear
}

const connectedConfirmContainer = connect(mapState, actionCreators)(ConfirmContainer);
export { connectedConfirmContainer as ConfirmContainer };