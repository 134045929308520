import React, { useState } from 'react';
import { GradientFormInput } from '../../custom/FormInput';
import {
    Container,
    StyledForm,
    StyledFormGroup,
    FormInputTitle,
    StyledFormText,
    StyledButton,
    CountrySelector,
} from './EditUserAddress.styles';
import { defaultBillingAddress } from '../../../utils';


const EditUserAddress = (props) => {
    const { currentAddress, submitData } = props;

    const [address, setAddress] = useState(currentAddress ? currentAddress : defaultBillingAddress()); // set default address

    const hasAddress = address.addressLine1 || address.addressLine2 || address.city || address.state || address.postalCode || address.country;
    const hasAddressCompleted = address.addressLine1 && address.city && address.postalCode && address.country;

    // handle change
    function handleChange(event){
        const { name, value } = event.target;

        setAddress({
            ...address,
            [name]: value // update input data
        });
    }

    // handle submit
    function handleSubmit(){
        if(!hasAddressCompleted) return;
        submitData(address);
    }

    return (
        <Container>
            <StyledForm id="edit-user-address">
                <StyledFormGroup>
                    <FormInputTitle>Address Line 1</FormInputTitle>
                    <GradientFormInput type="text" name="addressLine1" value={address.addressLine1} onChange={handleChange} />
                    {hasAddress && !address.addressLine1 && <StyledFormText>Address is required</StyledFormText>}
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>Address Line 2</FormInputTitle>
                    <GradientFormInput type="text" name="addressLine2" value={address.addressLine2} onChange={handleChange} />
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>City</FormInputTitle>
                    <GradientFormInput type="text" name="city" value={address.city} onChange={handleChange} />
                    {hasAddress && !address.city && <StyledFormText>City is required</StyledFormText>}
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>State</FormInputTitle>
                    <GradientFormInput type="text" name="state" value={address.state} onChange={handleChange} />
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>Postal Code</FormInputTitle>
                    <GradientFormInput type="text" name="postalCode" value={address.postalCode} onChange={handleChange} />
                    {hasAddress && !address.postalCode && <StyledFormText>Postal code is required</StyledFormText>}
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>Country</FormInputTitle>
                    <CountrySelector
                            name="country"
                            countryCode={address.country}
                            output='code'
                            gradient='true'
                            onSelect={(value) => handleChange({ target: { name: 'country', value } })} />
                    {hasAddress && !address.country && <StyledFormText>Country is required</StyledFormText>}
                </StyledFormGroup>
            </StyledForm>

            <StyledButton
                disabled={!hasAddressCompleted}
                gradient={true}
                type="submit"
                onClick={handleSubmit}
            >
                Save Address
            </StyledButton>
        </Container>
    );
};

export default EditUserAddress;