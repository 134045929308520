import React from 'react';
import styled from 'styled-components';

import { colorConstants } from "../../../constants";

const Container = styled.div`
  position: relative;
`;

const Line = styled.div`
    width: 1px;
    height: 450px;
    border-radius: 50px;
    background: ${colorConstants.primaryDark};
    background: ${colorConstants.gradientVertical};
`;

const Label = styled.div`
  text-align: center;
  color: ${colorConstants.black};
  font-size: 14px;
  background: ${colorConstants.white};
  max-width: 32px;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: -7px;
`;

export const VerticalDivider = (props) => {
    const { label } = props;
    return (
        <Container>
            <Line />
            <Label>{label}</Label>
        </Container>
    );
};