import React, { Component } from "react";
import { connect } from 'react-redux';

import { alertActions, linkActions, githubActions } from '../../../../actions';
import { ScreenContainer } from './AddGithubProjectContainer.styles';
import { MetaTags } from '../../../../components/custom/Helmet';
import { AddGithubProject }  from "../../../../components/links";
import { FullScreenSpinner } from "../../../../components/custom/Spinner";
import { TopBar } from '../../../../components/custom/TopBar';
import { Upgrade } from '../../../../components/custom/Popup';
import ImageCrop from "../../../../components/cropper/ImageCrop";
import {
    defaultPhotos,
    history,
    notNull,
    compressImage,
    storageRef,
    uploadFile,
    getFileDownloadURL,
    removeTokenFromFileDownloadURL,
    getImageFileDimensions,
} from '../../../../utils';
import { otherConstants, sitemap } from "../../../../constants";


class AddGithubProjectContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            init: false,
            fromUrl: sitemap.admin.links.list,
            typingTimeout: 0, // used to know when user stops typing
            showUpgradePopup: false, // used to toggle upgrade popup
            username: '',
            loadingRepos: false,
            reposSearchError: '',
            repos: [],
            project: {
                label: '',
                url: '',
                description: '',
            },
            photos: defaultPhotos(),
            submitted: false,
            creating: false,
        };

        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);

        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleRepoSelected = this.handleRepoSelected.bind(this);
        this.handleChangeProject = this.handleChangeProject.bind(this);

        this.handleUploadPhoto = this.handleUploadPhoto.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleToggleUpgradePopup = this.handleToggleUpgradePopup.bind(this);
        this.handleUpgrade = this.handleUpgrade.bind(this);
    }

    componentDidMount() {
        if(this.props.location.state && this.props.location.state.from) {
            this.setState({ fromUrl: this.props.location.state.from });
        } else {
            history.replace({ pathname: this.state.fromUrl, state: { error: 'Invalid container startup props' } });
        }

        if(!this.props.user || !this.props.user.id) {
            history.replace({ pathname: this.state.fromUrl, state: { error: 'Invalid container startup props' } });
        }
    }

    componentDidUpdate() {
        // initialize state
        if(!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access
            if(!this.props.user.creator) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            this.setState({ ...this.state, init: true });
        }
    }

    componentWillUnmount(){
        this.props.reset(); // reset link in redux state
        this.props.clearAlert(); // reset alert in redux state
    }

    handleTopBarBackButton() {
        if(this.state.photos.crop.pendingImage) this.setState({ ...this.state, photos: { ...this.state.photos, crop: defaultPhotos().crop } }); // reset crop image screen
        else history.push({ pathname: this.state.fromUrl }); // Go back
    }

    handleChangeUsername(event) {

        let { value } = event.target;

        const loadRepos = (username) => {
            this.setState({ ...this.state, loadingRepos: true }, async () => {
                const repos = await githubActions.getRepos(username);
                this.setState({ ...this.state, loadingRepos: false, repos, reposSearchError: (!repos || repos.length === 0) ? 'Could not find any repos' : '' });
            });
        }

        this.setState({
            ...this.state,
            submitted: false,
            typingTimeout: setTimeout(() => { loadRepos(value) }, 1200),
            username: value,
            project: { label: '', url: '', description: '', }, // reset project
            reposSearchError: '',
        });

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleRepoSelected(repo) {
        this.setState({ ...this.state, project: repo, repos: [], reposSearchError: '' });
    }

    handleChangeProject(event) {
        let { name, value } = event.target;

        if(name === 'simple-link-label') name = 'label';
        else if (name === 'simple-link-url') name = 'url';

        this.setState({
            ...this.state,
            submitted: false,
            project: {
                ...this.state.project,
                [name]: value,
            },
        });
    }

    handleUploadPhoto(e){
        // only allow subscription members to add photo
        if(!this.props.user || !this.props.user.id || (!this.props.user.basic && !this.props.user.premium && !this.props.user.platinum)) return;

        // Save inserted image url in state
        if (this.state.project.id && e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save inserted photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        ...this.state.photos,
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: 'github-link-logo',
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    async handleCroppedImage(croppedImage) {
        try {
            // get temp images from state
            let { temp } = this.state.photos;
            if(!temp) temp = [];

            // compress cropped image file
            const compressedFile = await compressImage(croppedImage, croppedImage.name, 256);

            // save compressed image to temp state
            let tempImage = { file: compressedFile, url: notNull(URL.createObjectURL(compressedFile)) };
            temp.push(tempImage);

            // update user cover url in state
            this.setState({
                ...this.state,
                project: {
                    ...this.state.project,
                    imageUrl: tempImage.url, // save temp image url
                },
                photos: {
                    crop: defaultPhotos().crop, // reset pending image for cropping
                    temp,
                },
            });
        } catch (error) {
            throw error;
        }
    }

    handleSubmit() {
        this.setState({
            submitted: true,
            creating: true, // enable spinner
        }, async () => {

            // get project from state
            let { project } = this.state;

            // set default github link attributes
            project.type = 'GITHUB';
            project.userId = this.props.user.id;

            // disable spinner do not add project
            if(!project.label || project.label.length < 3) {
                this.setState({ ...this.state, creating: false });
                return;
            }

            // create new Github link
            let createNewLinkPromise = new Promise(async (resolve, reject) => {

                // create new link
                await this.props.create(project);

                if(this.props.link.id) resolve(this.props.link.id); // return newlly created link's ID

                reject('Could not create Github link');
            });

            await createNewLinkPromise.then(async (linkId) => {
                // Upload photo
                if(linkId && this.props.user && this.props.user.id && this.state.photos.temp && this.state.photos.temp.length > 0) {
                    // create firebase storage reference of image to upload
                    const imageRef = storageRef(otherConstants.environment + '/users/' + this.props.user.id + '/links/' + linkId + '/' + this.state.photos.temp[0].file.name);

                    // Upload compressed image to firebase storage
                    const snapshot = await uploadFile(imageRef, this.state.photos.temp[0].file);

                    // Get firebase public url of uploaded photo
                    let uploadedPhotoFirebaseUrl = await getFileDownloadURL(snapshot.ref);

                    // Clean public url
                    uploadedPhotoFirebaseUrl = removeTokenFromFileDownloadURL(uploadedPhotoFirebaseUrl);

                    // update link image url
                    await this.props.update({ id: linkId, imageUrl: uploadedPhotoFirebaseUrl });
                }
                
            }).catch(err => this.setState({ ...this.state, creating: false })); // stop spinner

            // Go back to links list
            history.push({
                pathname: this.state.fromUrl,
                state: {
                    data: {
                        status: 'github-success'
                    }
                }
            });
        });
    }

    handleToggleUpgradePopup() {
        this.setState({ ...this.state, showUpgradePopup: !this.state.showUpgradePopup });
    }

    handleUpgrade() {
        history.push({
            pathname: sitemap.billing.upgrade,
            state: {
                from: sitemap.admin.links.list
            }
        });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title='Add Github Project' />

                {this.state.init &&
                <TopBar
                    sticky={false}
                    title='Add Github Project'
                    backButton={this.handleTopBarBackButton} /> }

                { this.state.init && !this.state.photos.crop.pendingImage &&
                <AddGithubProject
                    alert={this.props.alert}
                    isUpgraded={this.props.user && this.props.user.id && (this.props.user.basic || this.props.user.premium || this.props.user.platinum)}
                    handleUpgrade={this.handleToggleUpgradePopup}
                    username={this.state.username}
                    repos={this.state.repos}
                    reposSearchError={this.state.reposSearchError}
                    handleRepoSelected={this.handleRepoSelected}
                    project={this.state.project}
                    submitted={this.state.submitted}
                    handleChangeUsername={this.handleChangeUsername}
                    handleChangeProject={this.handleChangeProject}
                    handleUploadPhoto={this.handleUploadPhoto}
                    handleSubmit={this.handleSubmit} /> }

                { this.state.photos.crop.pendingImage &&
                <ImageCrop
                    url={this.state.photos.crop.pendingImage}
                    filename={this.state.photos.crop.pendingImageFilename}
                    fileSize={this.state.photos.crop.pendingImageFileSize}
                    imageDimensions={this.state.photos.crop.pendingImageDimensions}
                    circularCrop={false}
                    handleCroppedImage={this.handleCroppedImage} /> }

                {this.state.showUpgradePopup &&
                <Upgrade
                    show={this.state.showUpgradePopup}
                    onClick={this.handleUpgrade}
                    onHide={this.handleToggleUpgradePopup} /> }

                { (this.state.creating || this.state.loadingRepos) && <FullScreenSpinner /> }
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { alert, user, link } = state;

    // export state data to props
    return { alert, user, link };
}

const actionCreators = {
    create: linkActions.create,
    update: linkActions.update,
    reset: linkActions.clear,
    clearAlert: alertActions.clear
}

const connectedAddGithubProjectContainer = connect(mapState, actionCreators)(AddGithubProjectContainer);
export { connectedAddGithubProjectContainer as AddGithubProjectContainer };