import {
    Container,
    Title,
    Header,
    StyledParagraph as Text,
    StyledLink as Link,
    StyledTable as Table,
} from './CookiePolicyPage.styles';
import { sitemap, companyConstants } from '../../../../constants';
import { formatAddressHTML } from '../../../../utils';



export const CookiePolicyPage = () => {
    return (
        <Container>
            <Title>Cookie Policy</Title>


            <Header>Overview</Header>

            <Text>Our pledge is to put users first. Protecting your privacy is a top priority of ours. We aim to provide reliable and innovative products and services so that we bring as much value to you as we possibly we can. We strive to be transparent about our privacy practices so, we have written our Cookie Policy and related documents in a plain language.</Text>

            <Text>In this Cookie Policy, we explain what cookies are, what cookies are used on your device when you use our services and how we use them.</Text>

            <Text>In this Cookie Policy, we do not go through how we generally handle your personal information. Instead, you may read our Privacy Policy <Link to={sitemap.landing.z.policies.privacy}>here</Link> to learn about it.</Text>


            <Header>What are cookies?</Header>

            <Text>A cookie is a piece of text that is sent to or accessed from your web browser or the memory of your device. Cookies are a tool that typical hold information such the domain name from which the cookie came from, its expiry date and an identifier, as well as information about your device like user settings, browsing history and other activities done while using our services.</Text>


            <Header>Different types of cookies</Header>

            <Text><strong><i>Third-party cookies and cookies from our services</i></strong></Text>

            <Text>Cookies from our services are placed by us, for example, to fit our website to your browser's language preferences and to better understand how you use our website and services. Third-party cookies are the cookies our service providers and partners place on your device, for example, to measure the number of users on our websites or to be able to allow you to share content with others across social media platforms.</Text>

            <Text><strong><i>Session and persistent cookies</i></strong></Text>

            <Text>Session cookies are cookies created to last only until you close your browser and we use those cookies for different reasons, including to know more about how you use our websites and services on a single browser session and to assist you use our websites and services more efficiently. Persistent cookies, on the other hand, last longer and are not deleted when you close your browser. We use persistent cookies mostly to help you sign-in to our websites and services faster and for analytical purposes.</Text>


            <Header>Other tracking technologies</Header>

            <Text>Web beacons (also called pixel tags), tracking URLs or software development kits are other technologies that are also used for similar reasons. Web beacons are very small graphic images that enclose a unique identifier which make us able to recognise when someone has opened an e-mail we sent them or have visited our websites. Tracking URLs are custom links that enable us to understand where does the traffic to our website pages comes from. Software development kits are small portions of code in the apps, that work like cookies and web beacons.</Text>

            <Text>To make things simple when we refer to “cookies” in this Cookie Policy we also mean these technologies as well.</Text>


            <Header>Why we use cookies</Header>

            <Text>In order to provide, improve and secure our services, as well as to remember your preferences, recognize you when you visit our websites and services and personalizing and tailoring ads to your interests we are using cookies. We may also link information from cookies with other personal information we hold about you in order to achieve these purposes.</Text>

            <Text>When you visit our websites and services, some or all of the below types of cookies may be placed on your device.</Text>

            <Table>
                <thead>
                    <tr>
                        <th>Cookie Type</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><strong>Necessary website cookies</strong></td>
                        <td>These type of cookies are strictly necessary to deliver you services and products, such as access to secure areas.</td>
                    </tr>
                    <tr>
                        <td><strong>Analytics cookies</strong></td>
                        <td>These cookies help us determine how our websites are performing and how effective marketing campaigns are and ensure a smooth and improved experience of our websites for you.</td>
                    </tr>
                    <tr>
                        <td><strong>Advertising cookies</strong></td>
                        <td>These cookies are used to perform functions like preventing the same ad from repeatedly appearing, making sure the ads are properly shown for advertisers, choosing advertisements based on your interests and counting the ads displayed and measuring their performance, such as how many website visitors clicked on a particular ad.</td>
                    </tr>
                    <tr>
                        <td><strong>Social networking cookies</strong></td>
                        <td>These cookies are used to provide you with the ability to share through third-party social networking and other websites, content and webpages on our services that you might find interesting, as well as for advertising purposes.</td>
                    </tr>
                </tbody>
            </Table>


            <Header>How you can control cookies</Header>

            <Text>You have different options in regards of cookie management, though, please note that modifications to cookie preferences may lower your experience quality within our websites and services. In some cases, you may be unable to use all or part of our websites or services.</Text>

            <Text><strong><i>Browser and devices controls</i></strong></Text>

            <Text>You are allowed to control or deny cookies or even to get notified when a cookie is placed on your computer by some web browsers. You may find guides and information about these cookie management settings for each browser in their help menu.</Text>

            <Text>Mobile devices also provide you with settings to reset device identifiers which differ from device to device how you may achieve this. You may find guides and information about these settings for each device in their menu.</Text>

            <Text><strong><i>Interest-based advertising tools</i></strong></Text>

            <Text>There are tools that can help you opt-out from interest-based advertising such as the <a href="http://www.youronlinechoices.eu">Interactive Digital Advertising Alliance</a>, the <a href="http://optout.aboutads.info">Digital Advertising Alliance</a> or <a href="https://youradchoices.com/appchoices">Appchoices</a> for apps.</Text>

            <Text>Opting out from these networks with the above tools or any other settings it does not mean you will stop seeing advertisements, it means you will not see personalized advertisements.</Text>


            <Header>Social Cookies</Header>

            <Text>To provide you with the ability to share content on social media we use social media plug-ins in some of our websites or services features, such as share to Facebook™ or Instagram™ buttons. We automatically receive information from the social media based on your social media account settings when you use the corresponding share to button on our websites or services. You may read more information about social media cookies in your social media platform's cookie and privacy policy.</Text>


            <Header>Google™ Cookies</Header>

            <Text><strong><i>Google™ Maps API Cookies</i></strong></Text>

            <Text>We also have few features on our websites or services that need the placement of Google™ Maps API Cookies to function, on which, you consent to the collection and storage of these kinds of cookies on your device, as well as, the access, usage and sharing of the data collected by those cookies with Google.</Text>

            <Text>Google is responsible for the management of the data gathered by these kinds of cookies and you may see more information about this <a href="https://www.google.com/policies/technologies/cookies">here</a>.</Text>

            <Text><strong><i>Google Analytics</i></strong></Text>

            <Text>Our analytics, which are provided by a Google service, also use cookies and other data collection technologies to function, such as gather information about your use of our websites and services so that we can see and follow your trends and be able to provide you with better services.</Text>

            <Text>You may opt out of Google Analytics by going <a href="https://www.google.com/settings/ads">here</a> or by downloading the Google Analytics opt-out browser add-on <a href="https://tools.google.com/dlpage/gaoptout">here</a>.</Text>


            <Header id='contact'>Contacting us</Header>

            <Text>If you have any questions about this Cookie Policy, you can contact us:</Text>

            <Text>By email: <a href='mailto:info@bunj.ee'>info@bunj.ee</a></Text>

            <Text>By post:</Text>

            <Text>Support Team<br/>{companyConstants.name.full.toUpperCase()}<br/>{formatAddressHTML(companyConstants.address)}</Text>


            <Text>This document was last updated on January 18, 2022</Text>

        </Container>
    );
};