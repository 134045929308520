import React, { useState } from 'react';
import { socialConstants } from "../../../constants";
import { GradientFormInput, GradientFormSelector } from '../../custom/FormInput';
import {
    Container,
    StyledForm,
    StyledFormGroup,
    FormInputTitle,
    StyledFormText,
    StyledButton
} from './AddSocialMediaLinks.styles';
import { defaultUserSocialMedia, validateSocialUrl } from '../../../utils';


const AddSocialMediaLinks = (props) => {
    const { links, preSelected, submitData } = props;

    const [social, setSocial] = useState(links ? links : defaultUserSocialMedia()); // set default social links
    const [isSelectedLink, setSelectedLink] = useState(preSelected ? preSelected : 0); // set default selected social platform
    const [isValidLink, setValidLink] = useState(true); // set false if not valid social link

    const entries = Object.entries(social);
    const socialUrl = entries[isSelectedLink][1];
    const socialType = entries[isSelectedLink][0];


    // handle change
    function handleChange(event) {
        event.preventDefault();

        // Handle input value change here
        if(event.target instanceof HTMLInputElement) {
            const { name, value } = event.target;
            // update social links in state
            setSocial({
                ...social,
                [name]: value
            });
        }

        // Handle select value change here
        if(event.target instanceof HTMLSelectElement) {
            // update selected platform
            setSelectedLink(event.target.options.selectedIndex);
        }
    }


    // handle submit
    function handleSubmit(event) {
        event.preventDefault();

        // get link and type
        let type = event.currentTarget.elements.selector.value;
        let link = event.currentTarget.elements.socialLink.value;

        // validate social link
        let validatedLinkType = validateSocialUrl(link);

        // set validation status false
        if (type !== validatedLinkType) {
            setValidLink(false);
            return;
        }

        // reset validSocialLink flag
        setValidLink(true);

        // submit final data
        submitData(social);
    };

    return (
        <Container>
            <StyledForm onSubmit={handleSubmit} id="add-social-media-link">
                <StyledFormGroup>
                    <FormInputTitle>Select Social Media</FormInputTitle>
                    <GradientFormSelector
                        options={socialConstants.platforms}
                        id="selector"
                        selected={socialConstants.platforms[isSelectedLink]}
                        onChange={handleChange} />
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>Your Profile URL</FormInputTitle>
                    <GradientFormInput
                        type="text"
                        placeholder={socialConstants.placeholders[isSelectedLink]}
                        id="socialLink"
                        name={socialType}
                        value={socialUrl}
                        onChange={handleChange} />
                    {socialUrl && !isValidLink &&
                        <StyledFormText>The URL is not a valid link for {socialConstants.platforms[isSelectedLink]}</StyledFormText>}
                </StyledFormGroup>
            </StyledForm>

            <StyledButton
                    gradient={true}
                    disabled={!socialUrl}
                    type="submit"
                    form="add-social-media-link"
                >
                    Add Social Media Link
                </StyledButton>
        </Container>
    );
};

export default AddSocialMediaLinks;