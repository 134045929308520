import {
    Container,
    Photo,
    Fader,
    TopSection,
    Name,
    BottomSection,
    Location
} from './TripInfo.styles';
import { StarSystem } from '../../custom/Rating';


const TripInfo = (props) => {
    const { place, ...rest } = props;

    return (
        <Container {...rest} >
            <Photo src={place.photo} />

            <Fader />

            <TopSection>
                <Name>{place.name}</Name>
                {place.rating && <StarSystem rating={place.rating} />}
            </TopSection>

            <BottomSection>
                <Location>{place.location}</Location>
            </BottomSection>
        </Container>
    );
};

export default TripInfo;