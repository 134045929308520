/**
 * Dependencies: npm i draft-js draft-convert
 */
import React, { useRef, useState, useEffect } from 'react';
import { Editor, EditorState, RichUtils, getDefaultKeyBinding } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import 'draft-js/dist/Draft.css';
import {
    GradientBorder,
    Toolbar,
    Button,
    MaxTextIndicator
} from './GradientRichEditorDraft.styles';
import {
    getIcon,
    getBlockType,
    getInlineStyle,
    BLOCK_TYPES,
    INLINE_STYLES,
    styleMap,
    getBlockStyle
} from './draft.helpers';



export const GradientRichEditor = (props) => {
    let { value, withDelay, onChange, maxLength, getCurrentTextLength, placeholder } = props;

    // set editor initialized
    const [initialized, setInitialized] = useState(false);

    // if has saved HTML data then convert DraftJS state object
    if(value) {
        const initialState = convertFromHTML(value);
        value = EditorState.createWithContent(initialState);
    }

    // set default editor state
    const [editorState, setEditorState] = useState(value || EditorState.createEmpty());
    
    // output after a given delay
    const [typingTimeout, setTypingTimeout] = useState(0);

    // save text length
    const [textLength, setTextLength] = useState(value ? value.getCurrentContent().getPlainText().length : 0);

    // get editor ref for focus
    const editorRef = useRef(null);

    
    // set focus on editor
    function focus(){
        if(editorRef && editorRef.current) editorRef.current.focus();
    }

    // method to save state changes in state and in server
    function modelChanged(newEditorState){
        const newContent = newEditorState.getCurrentContent();
        const oldContent = editorState.getCurrentContent();
        const newContentLength = newContent.getPlainText().length;
        
        if(newContent === oldContent || newContentLength <= maxLength) {
            // save new content text length
            setTextLength(newContentLength);
            // return current text length
            if(getCurrentTextLength) getCurrentTextLength(textLength);
            // save changes in editor state
            setEditorState(newEditorState);

            // convert editor state to HTML
            if(!onChange || newContent === oldContent) return;
            const html = convertToHTML(newContent);

            if(withDelay) {
                // output content with 0.5 sec delay after stop typing
                setTypingTimeout(setTimeout(() => { onChange(html) }, withDelay));
                // clear timeout
                if (typingTimeout) clearTimeout(typingTimeout);
            } else {
                // output content without delay
                onChange(html);
            }
        }
    }

    // method to handle keyboard commands, ie 'CTRL+B'
    function handleKeyCommand(command, editorState){
        const newEditorState = RichUtils.handleKeyCommand(editorState, command);
        if (newEditorState) {
            modelChanged(newEditorState);
            return 'handled';
        }
    
        return 'not-handled';
    }

    // method to indent lists when TAB is pressed
    function mapKeyToEditorCommand(e){
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(e, editorState, 4, /* maxDepth */);
            if (newEditorState !== editorState) {
                modelChanged(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    }

    // method to add/remove block types from selected text
    function toggleBlockType(blockType){
        modelChanged(RichUtils.toggleBlockType(editorState, blockType));
    }

    // method to add/remove inline styles from selected text
    function toggleInlineStyle(inlineStyle){
        modelChanged(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    }

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    }

    // method to prevent the beforeInput event from rendering the new character or from executing onChange if returns true
    function handleBeforeInput(chars) {
        const totalLength = editorState.getCurrentContent().getPlainText().length + chars.length;
        return totalLength > maxLength;
    }

    useEffect(() => {

        // commands to initialize the editor
        if(!initialized) {
            // return initial text length
            if(getCurrentTextLength) getCurrentTextLength(textLength);

            setInitialized(true);
        }

        // Cleanup when component unmounts
        return function cleanup() {
            // reset timeout
            if(typingTimeout) clearTimeout(typingTimeout);
        };
    }, [initialized, setInitialized, typingTimeout, getCurrentTextLength, textLength]);


    return(
        <GradientBorder>
            <Toolbar>
                { INLINE_STYLES.map((type) => 
                    <Button
                        key={type.label}
                        active={getInlineStyle(editorState).has(type.style)}
                        onClick={() => toggleInlineStyle(type.style)}
                    >
                        {getIcon(type.label)}
                    </Button>
                )}
                { BLOCK_TYPES.map((type) => 
                    <Button
                        key={type.label}
                        active={getBlockType(editorState) === type.style}
                        onClick={() => toggleBlockType(type.style)}
                    >
                        {getIcon(type.label)}
                    </Button>
                )}
            </Toolbar>
            <div className={className} onClick={focus}>
                <Editor
                    blockStyleFn={getBlockStyle}
                    customStyleMap={styleMap}
                    editorState={editorState}
                    handleBeforeInput={handleBeforeInput}
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={mapKeyToEditorCommand}
                    onChange={modelChanged}
                    placeholder={placeholder || "Tell a story..."}
                    ref={editorRef}
                    spellCheck={true}
                    // autoCapitalize={'none'}
                    // autoComplete={'off'}
                    // autoCorrect={'off'}
                />
            </div>
            <MaxTextIndicator>{textLength + ' / ' + maxLength}</MaxTextIndicator>
        </GradientBorder>
    );
};