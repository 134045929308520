import React from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { sitemap, deviceConstants, colorConstants } from "../../../constants";


const Container = styled.div`
    padding: 70px 32px 170px 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${deviceConstants.tablet} {
        padding: 70px 20% 170px 20%;
    }
`;

const Title = styled.h1`
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    color: ${colorConstants.black};

    @media ${deviceConstants.tablet} {
        font-size: 38px;
        text-align: start;
    }
`;

const PoliciesContainer = styled.div`
    width: 100%;
    margin-top: 40px;

    display: flex;
    flex-direction: column;

    & > * {
        margin-bottom: 32px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    @media ${deviceConstants.tablet} {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        & > * {
            margin-bottom: 32px;
        }
    }
`;

const Card = styled.div`
    @media ${deviceConstants.tablet} {
        width: 45%;
    }
`;

const CardHeader = styled.div`
    margin-bottom: 16px;

    font-weight: 500;
    font-size: 22px;
    color: ${colorConstants.black};

    @media ${deviceConstants.tablet} {
        font-size: 26px;
        text-align: start;
    }
`;

const CardText = styled.div`
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    color: ${colorConstants.mediumGrey};
`;

const CardLink = styled(Link)`
    font-weight: 400;
    font-size: 14px;
    color: ${colorConstants.primaryDark};

    :hover {
        color: ${colorConstants.black};
    }
`;


export const PoliciesCenterPage = () => {
    return (
        <Container>
            <Title>Policies Center</Title>

            <PoliciesContainer>
                <Card>
                    <CardHeader>Privacy Policy</CardHeader>
                    <CardText>Explains what information we collect and why, how we use it, and how to access and update it.</CardText>
                    <CardLink to={sitemap.landing.z.policies.privacy}>Read our Privacy Policy</CardLink>
                </Card>

                <Card>
                    <CardHeader>Terms of Service</CardHeader>
                    <CardText>Describes the rules which you agree to when using our services.</CardText>
                    <CardLink to={sitemap.landing.z.policies.terms}>Terms of Service</CardLink>
                </Card>

                <Card>
                    <CardHeader>Customer Terms of Service</CardHeader>
                    <CardText>Describes the rules which you agree to when you complete a Booking of our Travel Experiences.</CardText>
                    <CardLink to={sitemap.landing.z.policies.termsForCustomers}>Customer Terms of Service</CardLink>
                </Card>

                <Card>
                    <CardHeader>Associate Terms and Conditions</CardHeader>
                    <CardText>Describes the rules which you agree to when you participate in the Bunjee Associates Program.</CardText>
                    <CardLink to={sitemap.landing.z.policies.termsForAssociates}>Associate Terms and Conditions</CardLink>
                </Card>

                <Card>
                    <CardHeader>Data Processing Addendum</CardHeader>
                    <CardText>Explains your responsibilities (and ours) for respecting the privacy rights of visitors to your profile.</CardText>
                    <CardLink to={sitemap.landing.z.policies.dpa}>Data Processing Addendum</CardLink>
                </Card>

                <Card>
                    <CardHeader>Cookie Policy</CardHeader>
                    <CardText>Explains what cookies are, why and how we use cookies, and how you can control it.</CardText>
                    <CardLink to={sitemap.landing.z.policies.cookie}>Read our Cookie Policy</CardLink>
                </Card>
            </PoliciesContainer>
        </Container>
    );
};