import React, { useMemo } from "react";

import { Container } from './BookingDetailsPage.styles';
import { HotelBooking } from './HotelBooking';


export const BookingDetailsPage = ({
    hotelBooking,
    handleOpenCreatorPage,
    handleCopyConfirmationCode,
    handleSendConfirmationEmail,
    handleOpenAddressWithGoogleMaps,
    handleCallPhonenumber,
    handleSendEmail,
    handleUpdateBooking,
    handleCancelBooking,
    handleContactUs,
}) => {

    // determine booking type
    const isHotelBooking = useMemo(() => {
        if(hotelBooking && hotelBooking.type === 'ACCOMMODATION') return true;
        return false;
    }, [hotelBooking]);

    return (
        <Container>

            { isHotelBooking &&
            <HotelBooking
                hotelBooking={hotelBooking}
                handleOpenCreatorPage={handleOpenCreatorPage}
                handleCopyConfirmationCode={handleCopyConfirmationCode}
                handleSendConfirmationEmail={handleSendConfirmationEmail}
                handleOpenAddressWithGoogleMaps={handleOpenAddressWithGoogleMaps}
                handleCallPhonenumber={handleCallPhonenumber}
                handleSendEmail={handleSendEmail}
                handleUpdateBooking={handleUpdateBooking}
                handleCancelBooking={handleCancelBooking}
                handleContactUs={handleContactUs}
            /> }

        </Container>
    );
};