import React from 'react';
import {
    Container,
    Header,
    Title,
    Body,
    AddLinkButton,
    ButtonIcon,
    ButtonText,
} from './ProductsMenu.styles';
import Icon from '../../../assets/icons/Icon';
import { colorConstants } from '../../../constants';



export const ProductsMenu = (props) => {
    const { show, onClick, onHide } = props;

    return (
        <Container show={show} onHide={onHide} centered={true} >
            <Header closeButton>
                <Title>Add to Bunjee</Title>
            </Header>

            <Body>
                <AddLinkButton onClick={() => onClick('PHYSICAL')} >
                    <ButtonIcon>
                        <Icon type='product' size={30} customcolor={colorConstants.secondaryDark} />
                    </ButtonIcon>
                    <ButtonText>Add Physical Product</ButtonText>
                </AddLinkButton>

                <AddLinkButton onClick={() => onClick('PHYSICAL_SECTION')} >
                    <ButtonIcon>
                        <Icon type='section_divider' size={30} customcolor={colorConstants.secondaryDark} />
                    </ButtonIcon>
                    <ButtonText>Create Physical Products Section</ButtonText>
                </AddLinkButton>

                {/* <AddLinkButton onClick={() => onClick('DIGITAL')} >
                    <ButtonIcon>
                        <Icon type='digital_file' size={30} customcolor={colorConstants.secondaryDark} />
                    </ButtonIcon>
                    <ButtonText>Add Digital Product</ButtonText>
                </AddLinkButton>

                <AddLinkButton onClick={() => onClick('DIGITAL_SECTION')} >
                    <ButtonIcon>
                        <Icon type='section_divider' size={30} customcolor={colorConstants.secondaryDark} />
                    </ButtonIcon>
                    <ButtonText>Create Digital Products Section</ButtonText>
                </AddLinkButton> */}
            </Body>
      </Container>
    );
};