import { http, authHeader, handleResponse, handleError } from "../utils";

export const customerService = {
    getByUserId,
    update,
};


async function getByUserId(id, isGuestCheckoutSession) {
    let url = `/customers/${id}?isGuestCheckoutSession=${isGuestCheckoutSession}`;
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function update(customerId, data) {
    return await http.put(`/customers/${customerId}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}