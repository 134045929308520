import styled from 'styled-components';
import { Logo } from "./Logo";
import { colorConstants, otherConstants } from "../../../constants";


const Container = styled.div`

`;

const StyledLogo = styled(Logo)`
    min-width: 144px;
`;

const AppVersion = styled.div`
    margin-top: 16px;
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: ${colorConstants.softGrey};
`;

export const AboutSection = (props) => {
    const { versionColor, ...rest } = props;
    
    return (
        <Container>
            <StyledLogo {...rest} />
            <AppVersion versionColor={versionColor} >{otherConstants.appVersion + ' ' + otherConstants.appBuild}</AppVersion>
        </Container>
    );
};