import styled from 'styled-components';
import { Trash, Pencil } from 'react-bootstrap-icons';
import { colorConstants } from "../../../constants";


export const Container = styled.div`
    width: 100%;
    margin-bottom: 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Content = styled.div`
    width: 85%;
    padding-right: 24px;

    :hover {
        cursor: pointer;
    }
`;

export const Name = styled.h3`
    font-size: 15px;
    font-weight: 600;
    color: ${colorConstants.black};
    margin: 0;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :hover {
        cursor: pointer;
    }
`;

export const Location = styled.div`
    font-size: 11px;
    font-weight: 400;
    color: ${colorConstants.darkGrey};

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :hover {
        cursor: pointer;
    }
`;

export const ButtonsSection = styled.div`
    display: flex;
    justify-content: flex-start;

    & > * {
        margin-right: 16px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const EditButton = styled(Pencil)`
    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const DeleteButton = styled(Trash)`
    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;