import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colorConstants, deviceConstants } from '../../constants';
import { Icon } from "../../components/custom/Branding";


export const Container = styled.div`
    padding-top: 76px;
    padding-bottom: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const IconWrapper = styled.div`
    margin-bottom: 40px;
`;

export const StyledIcon = styled(Icon)`
    @media ${deviceConstants.tablet} {
        min-width: 100px;
    }
`;

export const Header = styled.div`
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: normal;
    color: ${colorConstants.black};
    text-align: center;
`;

export const SubHeader = styled.div`
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: ${colorConstants.black};
    text-align: left;

    @media ${deviceConstants.tablet} { 
        text-align: center;
    }
`;

export const StyledLink = styled(Link)`
  color: ${colorConstants.black};
  
  :hover{
    color: ${colorConstants.primaryDark};
  }
`;