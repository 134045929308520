/**
 * Resources:
 *  - https://www.npmjs.com/package//react-gtm-module
 *  - https://support.google.com/analytics/answer/7201382
 *  - https://support.google.com/tagmanager/answer/13034206
 *  - https://support.google.com/tagmanager/answer/6311518
 *  - https://www.plumfind.com/academy/how-to-implement-google-tag-manager-for-single-page-applications-such-as-react
 *  - https://support.google.com/analytics/answer/11377859?hl=en
 *  - https://www.themarketingtechnologist.co/excluding-google-tag-manager-debugging-traffic-in-google-analytics/
 *  - https://www.termsfeed.com/blog/gdpr-google-analytics-ga4
 */
import TagManager from 'react-gtm-module';
import { gtmConfig } from '../../config';
import { otherConstants } from '../../constants';
const isAnalyticsEnabled = otherConstants.isProduction;



/**
 * Method to initialize Google Analytics
 */
function init() {
    if(!isAnalyticsEnabled) return;
    let configs = { gtmId: gtmConfig.code };
    // enable GA Debug View
    if(otherConstants.isDev) {
        configs.auth = gtmConfig.devEnvAuth;
        configs.preview = gtmConfig.devEnvName;
    }
    TagManager.initialize(configs);
}



/**
 * Method to send a custom triggered action event to G. Analytics
 * 
 * @param {String} category Required. A Top level category for the events
 * @param {String} action Required. A description of the behaviour
 * @param {String} label Optional. More precise labelling of the related action
 * @param {Integer or String} value Optional. A value generated by the specified action
 * @param {Boolean} nonInteraction Optional. Set true if an event is not triggered by a user interaction, but instead by our code ie. page load
 */
function event(category, action, label, value, nonInteraction) {
    if(!isAnalyticsEnabled) return;
    TagManager.dataLayer({ dataLayer: { event: 'btn_click', category, action, label, value, nonInteraction } });
}



export const analytics = {
    init,
    event,
};