import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';
import html from './WhitelistEmailInstructions.txt';

import { ScreenContainer } from './WhitelistEmail.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { Header } from '../../components/custom/TopBar';
import { HomeFooter } from '../../components/custom/Footer';
import { sitemap } from '../../constants';



export class WhitelistEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scrollToSection: '', // used to scroll to particular section
            html: '',
        };

        this.handleScrollReset = this.handleScrollReset.bind(this);
        this.handleScrollToSection = this.handleScrollToSection.bind(this);
    }

    componentDidMount(){
        fetch(html)
            .then(r => r.text())
            .then(html => this.setState({ ...this.state, html }));
    }

    handleScrollReset(){
        if(this.state.scrollToSection) {
            this.setState({ ...this.state, scrollToSection: '' });
        }
    }

    handleScrollToSection(scrollToSection){
        this.setState({ ...this.state, scrollToSection });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title='Whitelist Email' />

                <Header
                    border={true}
                    showLogo={true}
                    logoLinkTo={sitemap.landing.home}
                    primaryButtonText='LOG IN'
                    primaryButtonLinkTo={sitemap.auth.login}
                />

                {ReactHtmlParser(this.state.html)}

                <HomeFooter menu={false} />
            </ScreenContainer>
        );
    }
}