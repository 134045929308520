import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colorConstants } from "../../../constants";

const LinkWrapper = styled(Link)`
    width: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;

    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    color: ${colorConstants.white};

    z-index: 1000;

    :hover {
        color: ${colorConstants.black};
    }
`;

const Button = styled.div`
    height: 24px;
    padding: 0 16px;
    background: ${colorConstants.primaryDark};
    background: ${colorConstants.gradientHorizontal};

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const GradientStripeButton = (props) => {
    const { text, link } = props;

    return (
        <LinkWrapper to={link}><Button>{text}</Button></LinkWrapper>
    );
};