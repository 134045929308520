import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { DashSquare, PlusSquare, Trash } from 'react-bootstrap-icons';

import { colorConstants } from '../../../constants';


export const Container = styled(Modal)`

`;

export const Header = styled(Modal.Header)`
    border-bottom: none;
    display: flex;
    align-items: flex-start;
`;

export const Title = styled(Modal.Title)`
    font-weight: 700;
    font-size: 20px;
    background: ${colorConstants.gradientForText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const Body = styled.div`
    padding: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const BodyTitle = styled.div`
    width: 100%;
    margin-bottom: 12px;

    font-weight: 500;
    font-size: 15px;
    color: ${colorConstants.black};
`;

export const BodySubtitle = styled.div`
    width: 100%;
    margin-bottom: 24px;

    font-weight: 400;
    font-size: 13px;
    color: ${colorConstants.black};
`;

export const ButtonsList = styled.div`
    width: 100%;
    padding: 0px 16px;
`;

export const Button = styled.div`
    width: 100%;    
    padding: 8px 0px;
    margin: 0px;

    border: none;
    border-radius: 4px;

    background: ${colorConstants.white};
    color: ${colorConstants.black};

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ButtonLeft = styled.div`
    width: 42%;
    margin-right: 16px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const ButtonTitle = styled.div`
    font-weight: 600;
    font-size: 15px;
`;

export const ButtonRight = styled.div`
    width: 58%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
        margin-right: 8px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const ButtonNumber = styled.div`
    padding: 0 8px;

    font-weight: 700;
    font-size: 15px;
    text-align: center;
`;

export const ButtonMinus = styled(DashSquare)`
    ${({ isdisabled }) => isdisabled && `
        fill: ${colorConstants.softGrey};
        pointer-events: none;
    `}

    ${({ isdisabled }) => !isdisabled && `
        fill: ${colorConstants.black};
        pointer-events: auto;
    `}

    :hover {
        cursor: pointer;
    }
`;

export const ButtonPlus = styled(PlusSquare)`
    ${({ isdisabled }) => isdisabled && `
        fill: ${colorConstants.softGrey};
        pointer-events: none;
    `}

    ${({ isdisabled }) => !isdisabled && `
        fill: ${colorConstants.black};
        pointer-events: auto;
    `}

    :hover {
        cursor: pointer;
    }
`;

export const DeleteChildButton = styled(Trash)`
    margin-right: 16px;
    fill: ${colorConstants.black};

    :hover {
        cursor: pointer;
        fill: ${colorConstants.primary};
    }
`;

export const AddChildButton = styled.div`
    padding: 2px 8px;
    margin-top: 12px;

    font-weight: 500;
    font-size: 15px;
    text-align: center;

    ${({ isdisabled }) => isdisabled && `
        color: ${colorConstants.softGrey};
        pointer-events: none;
    `}

    ${({ isdisabled }) => !isdisabled && `
        color: ${colorConstants.secondary};
        pointer-events: auto;

        :hover {
            cursor: pointer;
            color: ${colorConstants.primary};
        }
    `}
`;