// Manages the public links section of the application state used to list links and link info
import { publicLinksReducerConstants } from '../constants';

export function publicLinks(state = { links: [] }, action) {

  switch (action.type) {

    case publicLinksReducerConstants.GETALL_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicLinksReducerConstants.GETALL_SUCCESS:
        return {
            links: action.links
        };

    case publicLinksReducerConstants.GETALL_FAILURE:
        return {
            links: [],
            error: action.error
        };

    case publicLinksReducerConstants.FILTER_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicLinksReducerConstants.FILTER_SUCCESS:
        return {
            links: action.links
        };

    case publicLinksReducerConstants.FILTER_FAILURE: {
        let { loading, ...stateCopy } = state; // remove loading flag
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case publicLinksReducerConstants.RESET_ALL: {
        return { links: [] }
    }

    default:
        return state
  }
}