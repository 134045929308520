import { http, authHeader, handleResponse, handleError } from "../utils";

export const creatorPayoutService = {
    getEarningsStatsById,
    payoutRequest,
};


async function getEarningsStatsById(creatorId) {
    let url = `/creator-payout/stats/${creatorId}`;
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function payoutRequest(creatorId) {
    let url = `/creator-payout/request/${creatorId}`;
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}