import React from 'react';
import { GradientFormInput } from '../../custom/FormInput';
import {
    Container,
    Title,
    TopMessage,
    StyledForm,
    StyledFormGroup,
    FormInputTitle,
    StyledFormText,
    PasswordRequirements,
    StyledButton
} from './ResetPassword.styles';


const ResetPassword = (props) => {
    const { credentials, submitted, isStrongPassword, handleSubmit, handleChange, alert } = props;

    return (
        <Container>
            <Title>Reset Your Password</Title>

            <TopMessage>
                Enter a new password below to change your password.
            </TopMessage>

            <StyledForm id="reset-password">
                <StyledFormGroup>
                    <FormInputTitle>New Password</FormInputTitle>
                    <GradientFormInput type="password" name="password" value={credentials.password} onChange={handleChange} />
                    { submitted && !credentials.password && <StyledFormText>New Password is required</StyledFormText> }
                    { alert && alert.message && <StyledFormText>{alert.message}</StyledFormText> }
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>Confirm New Password</FormInputTitle>
                    <GradientFormInput type="password" name="passwordConfirm" value={credentials.passwordConfirm} onChange={handleChange} />
                    { submitted && (credentials.password !== credentials.passwordConfirm) && <StyledFormText>New Password does not match</StyledFormText> }
                    { submitted && (credentials.password === credentials.passwordConfirm) && !isStrongPassword && 
                        <StyledFormText>Password must meet the following requirements
                            <PasswordRequirements>
                                <li>8 characters minimum length</li>
                                <li>at least 1 uppercase and 1 lowercase</li>
                                <li>at least 1 number and 1 symbol</li>
                            </PasswordRequirements>
                        </StyledFormText>
                    }
                </StyledFormGroup>
            </StyledForm>

            <StyledButton
                gradient={true}
                type="submit"
                onClick={handleSubmit}
            >
                Reset Password
            </StyledButton>
        </Container>
    );
};

export default ResetPassword;