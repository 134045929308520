import styled from 'styled-components';


export const Container = styled.div`
    border: none;

    div {
        color: ${props => props.fontColor};
    }
`;

export const Name = styled.div`
    width: 100%;
    padding: 24px 30px 0px 30px;
    text-align: center;
    display: block;

    font-family: ${props => props.fontType || 'inherit'} !important;
    font-size: 15px;
    font-weight: 700;
    margin: 0;

    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: none;
`;