import React, { Component } from "react";
import { connect } from 'react-redux';

import { authActions } from '../../actions';
import { LoginForm } from '../../components/auth';
import { ScreenContainer } from './LoginContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { Header } from '../../components/custom/TopBar';
import { history, analytics } from '../../utils';
import { sitemap, contentLoginPage } from '../../constants';


class LoginContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            submitted: false,
            captcha: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitGoogle = this.handleSubmitGoogle.bind(this);
        this.handleSubmitFacebook = this.handleSubmitFacebook.bind(this);
        this.handleCaptcha = this.handleCaptcha.bind(this);
    }

    componentDidMount(){
        this.props.initAuth(); // load csrf token in cookies
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email, password, captcha } = this.state;
        if (email && password && captcha) {
            this.props.login({ email, password }, this.props.location);
            // register click event
            analytics.event('Login Page', 'Email Login', 'Clicked Email Login Form Submit Button');
        }
    }

    handleSubmitGoogle(){
        history.push({
            ...this.props.location, // pass emailConfirm params
            from: this.props.location.from, // pass emailConfirm params
            pathname: sitemap.auth.social.google,
            state: {
                from: sitemap.auth.login,
                data: {
                    provider: 'google'
                }
            }
        });
        // register click event
        analytics.event('Login Page', 'Social Login', 'Clicked Google Login Button');
    }

    handleSubmitFacebook(){
        history.push({
            ...this.props.location, // pass emailConfirm params
            from: this.props.location.from, // pass emailConfirm params
            pathname: sitemap.auth.social.facebook,
            state: {
                from: sitemap.auth.login,
                data: {
                    provider: 'facebook'
                }
            }
        });
        // register click event
        analytics.event('Login Page', 'Social Login', 'Clicked Facebook Login Button');
    }

    handleCaptcha(value) {
        if (value) this.setState({ captcha: true });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title='Login' />

                <Header
                    fullScreen={true}
                    border={true}
                    onlyMobileBorder={true}
                    showLogo={true}
                    logoLinkTo={sitemap.landing.home}
                    primaryButtonText={contentLoginPage.header.button}
                    primaryButtonLinkTo={sitemap.auth.register} />

                <LoginForm
                    alert={this.props.alert}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    handleSubmitGoogle={this.handleSubmitGoogle}
                    handleSubmitFacebook={this.handleSubmitFacebook}
                    handleCaptcha={this.handleCaptcha}
                    loggingIn={this.props.loggingIn}
                    email={this.state.email}
                    password={this.state.password}
                    submitted={this.state.submitted}
                    captcha={this.state.captcha} />
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { alert } = state;
    const { loggingIn } = state.auth;

    // export state data to props
    return { alert, loggingIn };
}

const actionCreators = {
    login: authActions.login,
    initAuth: authActions.loadCsrfToken
};

const connectedLoginContainer = connect(mapState, actionCreators)(LoginContainer);
export { connectedLoginContainer as LoginContainer };