import React from 'react';
import {
    Container,
    TopSection,
    Header,
    Title,
    Body,
    Image,
    FeaturesList,
    UpgradeButton,
    CloseButton,
} from './Upgrade.styles';
import { contentPopupUpgrade } from '../../../constants';
import { Feature } from '../../upgrade/plans/Feature';
import { generateFirebasePhotoLink } from '../../../utils';



export const Upgrade = (props) => {
    const { show, onClick, onHide } = props;

    return (
        <Container show={show} onHide={onHide}>
            <TopSection>
                <Header closeButton />
                <Image url={generateFirebasePhotoLink('public%2Fapp%2Fpopups%2Fupgrade-premium-popup.jpg?alt=media')} />
            </TopSection>

            <Body>
                <Title>{contentPopupUpgrade.title}</Title>

                <FeaturesList>
                    <Feature text={contentPopupUpgrade.features.text1} active={true} />
                    <Feature text={contentPopupUpgrade.features.text2} active={true} />
                    <Feature text={contentPopupUpgrade.features.text3} active={true} />
                    <Feature text={contentPopupUpgrade.features.text4} active={true} />
                </FeaturesList>

                <UpgradeButton medium={true} midCorner={true} gradient={true} onClick={onClick} >{contentPopupUpgrade.upgradeBtn}</UpgradeButton>

                <CloseButton onClick={onHide} >{contentPopupUpgrade.cancelBtn}</CloseButton>
            </Body>
      </Container>
    );
};