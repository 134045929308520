import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormText from 'react-bootstrap/FormText';
import { Button } from '../../custom/Buttons';
import { deviceConstants, colorConstants } from "../../../constants";


export const Container = styled.div`
    padding: 0 32px;
    font-size: 12px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${deviceConstants.laptop} {
        padding: 0 16px;
    }

    @media ${deviceConstants.desktop} {
        padding: 0;
    }
`;

export const Title = styled.div`
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 500;
    color: ${colorConstants.black};
`;

export const TopMessage = styled.div`
    padding: 0 16px;
    margin-bottom: 24px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;

    @media ${deviceConstants.laptop} {
        padding: 0 8px;
    }
`;

export const StyledForm = styled(Form)`
    width: 100%;
`;

export const StyledFormGroup = styled(FormGroup)`
    margin-top: 16px;
`;

export const FormInputTitle = styled.div`
    font-weight: 600;
    color: ${colorConstants.softGrey};
    margin-bottom: 1px;
`;

export const StyledFormText = styled(FormText)`
  color: ${colorConstants.warning};
`;

export const PasswordRequirements = styled.ul`
  // list-style-type: none;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 32px;
`;