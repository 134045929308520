import { useMemo } from 'react';
import {
    Container, ContainerFader, FirstColumn, SecondColumn, Image,
    ContentSection, Name, Description,
} from './TripLink.styles';
import { StarSystem, GoogleRating } from '../custom/Rating';
import { colorConstants } from "../../constants";


export const TripLink = ({ data, handleOpen, customizations, ...rest }) => {
    let { buttons, fonts } = customizations;

    // defaults
    if(buttons.color === '') buttons.color = colorConstants.softBlack;
    if(buttons.fontColor === '') buttons.fontColor = colorConstants.white;

    // set button shape
    const buttonShape = buttons.shape === 'OVAL' ? '500px' : buttons.shape === 'ROUNDED' ? '8px' : '0px';

    // set button color
    const buttonColor = buttons.style === 'OUTLINE' ? 'transparent' : buttons.color;
    const buttonColorHover = buttons.style === 'OUTLINE' ? buttons.color : 'transparent';

    // set button font color
    const fontColor = buttons.style === 'OUTLINE' ? buttons.color : buttons.fontColor;
    const fontColorHover = buttons.style === 'OUTLINE' ? buttons.fontColor : buttons.color;

    const hasPartner = useMemo(() => data && data.partner, [data]);
    const hasGoogle = useMemo(() => data && data.google, [data]);

    return (
        <Container
            shape={buttonShape}
            borderColor={buttons.color}
            color={buttonColor}
            colorHover={buttonColorHover}
            fontColor={fontColor}
            fontColorHover={fontColorHover}
            onClick={() => handleOpen(data)} {...rest}>

            <ContainerFader className='fader' />

            <FirstColumn>
                { data.tripPhotoUrls && data.tripPhotoUrls.length > 0 && <Image className='trip-image' src={data.tripPhotoUrls[0]} /> }
            </FirstColumn>

            <SecondColumn className='content-box' fontType={fonts.type} >
                <ContentSection>
                    <div>
                        <Name>{data.name}</Name>
                        <Description>{data.city + ', ' + data.country}</Description>
                    </div>
                    { hasPartner && data.partner.stars && <StarSystem color={fontColor} rating={data.partner.stars} />}
                    {(!hasPartner || !data.partner.stars) && hasGoogle && data.google.rating && <GoogleRating color={fontColor} type='S4' rating={data.google.rating} reviewsCount={data.google.userRatingsTotal} /> }
                </ContentSection>
            </SecondColumn>
        </Container>
    );
};