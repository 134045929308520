import React, { useMemo } from 'react';
import {
    Container, TopSection, Header, Name, Collection, EnableToggle,
    Image,
    Content, Section, SectionTitle, SectionItem, SectionItemBold, LinksArea, Link,
    AttributesList, AttributeContainer, AttributeType, AttributeValue,
    Group, GroupInputTitle, GroupInputText,
} from './EditNFT.styles';
import { GradientRichEditor, GradientFormInput, SimpleLinkInput } from '../../custom/FormInput';
import Icon from '../../../assets/icons/Icon';
import { useWindowSize, getCoinbaseUrl, getOpenseaUrl, getNFTContractUrl, obfuscateAddress } from '../../../utils';


export const EditNFT = ({
    nft,
    handleChange,
    addNFTMode,
}) => {

    // detect screen size
    const [width] = useWindowSize();

    // calculate max Attribute card width
    const maxAttrCardWidth = useMemo(() => {
        if(width < 991) return (width - (3 * 32) - 8) / 2; // for mobile
        
        // calculate app section minus the padding on larger screens
        let section = 0;
        if(width >= 991 && width < 1440) {
            section = width - (width * 0.66); // for tablet
        } else if(width >= 1440 && width < 1600) {
            section = width - (width * 0.70); // for laptop
        } else {
            section = width - (width * 0.75); // for laptop
        }

        return (section - (3 * 32) - 16) / 2;
    }, [width]);

    const hasAttrs = nft && nft.attributes && nft.attributes.length > 0;
    let attrsList = null;
    if(hasAttrs) {
        attrsList = nft.attributes.map((item, index) => {
            return (
                <AttributeContainer key={index} maxwidth={maxAttrCardWidth + 'px'} >
                    <AttributeType>{item.type}</AttributeType>
                    <AttributeValue>{item.value}</AttributeValue>
                </AttributeContainer>
            );
        });
    }

    const openseaUrl = useMemo(() => {
        if(nft.contract && nft.chainTokenId) return getOpenseaUrl(nft.contract, nft.chainTokenId, nft.blockchain);
        return null;
    }, [nft]);

    const coinbaseUrl = useMemo(() => {
        if(nft.contract && nft.chainTokenId) return getCoinbaseUrl(nft.contract, nft.chainTokenId, nft.blockchain);
        return null;
    }, [nft]);

    
    return (
        <Container>

            <TopSection>
                <Header>
                    <Name>{nft.name}</Name>
                    {nft.collection && <Collection>{nft.collection}</Collection>}
                </Header>

                {!addNFTMode && <EnableToggle type="switch" name={'nft-enable'} checked={nft.enabled} onChange={handleChange} />}
            </TopSection>

            <Image src={nft.image} />

            <Content>
                <Section>
                    <SectionTitle>Description</SectionTitle>
                    {nft.description && <SectionItem>{nft.description}</SectionItem>}

                    <LinksArea>
                        { nft.websiteUrl &&
                        <Link href={nft.websiteUrl} target='_blank' rel='noreferrer'>
                            <Icon type='website' size={16} />
                        </Link> }

                        { openseaUrl &&
                        <Link href={openseaUrl} target='_blank' rel='noreferrer'>
                            <Icon type='crypto' platform='opensea' circle={true} color={true} size={16} />
                        </Link> }

                        { coinbaseUrl &&
                        <Link href={coinbaseUrl} target='_blank' rel='noreferrer'>
                            <Icon type='crypto' platform='coinbase' color={true} size={16} />
                        </Link> }

                        { nft.contract &&
                        <Link href={getNFTContractUrl(nft.contract, nft.blockchain)} target='_blank' rel='noreferrer'>
                            <Icon type='crypto' platform='etherscan' circle={true} color={true} size={16} />
                        </Link> }
                    </LinksArea>
                </Section>

                { nft.collection &&
                <Section>
                    <SectionTitle>Collection</SectionTitle>

                    <SectionItem>
                        <SectionItemBold>{nft.collection}</SectionItemBold>
                    </SectionItem>
                </Section> }

                { hasAttrs &&
                <Section>
                    <SectionTitle>Attributes</SectionTitle>

                    <AttributesList>
                        {attrsList}
                    </AttributesList>
                </Section> }

                <Section>
                    <SectionTitle>Details</SectionTitle>

                    { nft.contract &&
                    <SectionItem>
                        <SectionItemBold>Contract Address</SectionItemBold>
                        <Link href={getNFTContractUrl(nft.contract, nft.blockchain)} target='_blank' rel='noreferrer'>
                            {obfuscateAddress(nft.contract)}
                        </Link>
                    </SectionItem> }

                    { nft.chainTokenId &&
                    <SectionItem>
                        <SectionItemBold>Token ID</SectionItemBold>
                        <Link href={nft.metadataUrl} target='_blank' rel='noreferrer'>
                            {nft.chainTokenIdShort || nft.chainTokenId}
                        </Link>
                    </SectionItem> }

                    { nft.contractType &&
                    <SectionItem>
                        <SectionItemBold>Token Standard</SectionItemBold>
                        {nft.contractType}
                    </SectionItem> }

                    { nft.blockchain &&
                    <SectionItem>
                        <SectionItemBold>Blockchain</SectionItemBold>
                        {nft.blockchain}
                    </SectionItem> }
                </Section>
            </Content>

            <Group>
                <GroupInputTitle>Add Caption</GroupInputTitle>
                <GradientRichEditor
                    placeholder='Tell people about your cool NFT...'
                    value={nft.caption}
                    onChange={(value) => handleChange({ target: { name: 'caption', value } })}
                    maxLength={2000} />
            </Group>

            { !nft.partnerId &&
            <Group>
                <GroupInputTitle>Add Custom Link</GroupInputTitle>
                <SimpleLinkInput
                    data={nft.button}
                    hasBorder={true}
                    handleLabelChange={handleChange}
                    handleUrlChange={handleChange} />
            </Group> }

            {!addNFTMode &&
            <Group>
                <GroupInputTitle>Edit NFT Listing URL</GroupInputTitle>
                <GradientFormInput box2='true' type="text" name="customUrl" value={nft.customUrl} onChange={handleChange} />
            </Group> }

            { !addNFTMode && !nft.partnerId &&
            <Group row='true' >
                <GroupInputText>Skip NFT Listing Page</GroupInputText>
                {!addNFTMode && <EnableToggle type="switch" name={'skipDetailsPage-enable'} checked={nft.skipDetailsPage} onChange={handleChange} />}
            </Group> }
        </Container>
    );
};