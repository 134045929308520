import { useMemo } from 'react';
import styled from 'styled-components';
import Icon from '../../../assets/icons/Icon';
import { colorConstants } from "../../../constants";


const Container = styled.div`
    display: inline-block;
`;

const Row = styled.div`
    display: flex;
    align-items: ${({ align }) => align || 'center'};
    justify-content: ${({ justify }) => justify || 'space-between'};

    ${({ space }) => space && `
        & > * {
            margin-right: 8px;
        }

        & > *:last-child {
            margin-right: 0px;
        }
    `};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({ align }) => align};
    justify-content: ${({ justify }) => justify};

    ${({ space }) => space && `
        & > * {
            margin-bottom: 8px;
        }

        & > *:last-child {
            margin-bottom: 0px;
        }
    `};
`;

const Rating = styled.div`
    margin-left: 8px;
    padding: 4px 8px;
    border-radius: 4px 4px 4px 0;
    background: ${({ backgroundColor }) => backgroundColor || colorConstants.primaryDark};

    font-size: 12px;
    font-weight: 700;

    color: ${({ color }) => color || colorConstants.white};
`;

const Rating2 = styled.div`
    padding: 2px 5px;
    border-radius: 4px 4px 4px 0;
    background: ${({ backgroundColor }) => backgroundColor || colorConstants.primaryDark};

    font-size: 10px;
    font-weight: 800;
    line-height: 1;

    color: ${({ color }) => color || colorConstants.white};
`;

const Rating3 = styled.div`
    min-width: 32px;
    padding: 4px 8px;
    border-radius: 4px 4px 4px 0;
    background: ${({ backgroundColor }) => backgroundColor || colorConstants.primaryDark};

    text-align: center;
    font-size: 16px;
    font-weight: 700;

    color: ${({ color }) => color || colorConstants.white};
`;

const ReviewsCount = styled.div`
    margin-top: 4px;
    text-align: center;

    font-weight: 500;
    font-size: 10px;

    color: ${({ color }) => color || colorConstants.darkGrey};
`;

const RatingStyle2 = styled.div`
    font-weight: 700;
    font-size: 16px;

    color: ${({ color }) => color || colorConstants.black};
`;

const RatingStyle3 = styled.div`
    font-weight: 400;
    font-size: 10px;

    color: ${({ color }) => color || colorConstants.darkGrey};
`;

const RatingStyle4 = styled.div`
    font-weight: 700;
    font-size: 15px;
    line-height: 1;
    text-align: right;

    color: ${({ color }) => color || colorConstants.black};
`;

const ReviewsCountStyle2 = styled.div`
    margin-top: 4px;

    font-weight: 500;
    font-size: 12px;

    color: ${({ color }) => color || colorConstants.darkGrey};
`;

const ReviewsCountStyle3 = styled.div`
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    text-align: right;

    color: ${({ color }) => color || colorConstants.darkGrey};
`;


export const GoogleRating = ({ rating, reviewsCount, type, backgroundColor, color }) => {
    
    const formatRating = useMemo(() => (rating ? rating * 2 : 0), [rating]);
    const formatReviewCount = useMemo(() => (!reviewsCount || reviewsCount === 0) ? 'No reviews yet' : `${reviewsCount} reviews`, [reviewsCount]);

    if(!rating) return null;

    let ratingLabel = '';

    if(formatRating >= 9.0) {
        ratingLabel = 'Exceptional';
    } else if(formatRating >= 8.0 && formatRating <= 8.9) {
        ratingLabel = 'Fabulous';
    } else if(formatRating >= 7.0 && formatRating <= 7.9) {
        ratingLabel = 'Very Good';
    } else if(formatRating >= 6.0 && formatRating <= 6.9) {
        ratingLabel = 'Good';
    } else {
        ratingLabel = 'Okay';
    }

    switch(type) {
        case 'S1': {
            return (
                <Container>
                    <Row>
                        <Icon type='social' platform="google" size={20} circle={false} color={!color} customcolor={color} />
                        <Rating backgroundColor={backgroundColor} color={color}>{formatRating}</Rating>
                    </Row>
                    <ReviewsCount color={color}>{formatReviewCount}</ReviewsCount>
                </Container>
            );
        }

        case 'S2': {
            return (
                <Container>
                    <RatingStyle2>{formatRating}/10 {ratingLabel}</RatingStyle2>
                    <ReviewsCountStyle2>{formatReviewCount} on Google</ReviewsCountStyle2>
                </Container>
            );
        }

        case 'S3': {
            return (
                <Row align='center' justify='flex-start' space='true' >
                    <Icon type='social' platform="google" size={30} circle={false} color={!color} customcolor={color} />
                    <Column>
                        <RatingStyle2 color={color}>{formatRating}/10 {ratingLabel}</RatingStyle2>
                        <ReviewsCountStyle2 color={color}>{formatReviewCount}</ReviewsCountStyle2>
                    </Column>
                </Row>
            );
        }

        case 'S4': {
            return <RatingStyle3 color={color}>{formatRating}/10 {ratingLabel}</RatingStyle3>;
        }

        case 'S5': {
            return <RatingStyle3 color={color}>{formatReviewCount}</RatingStyle3>;
        }

        case 'S6': {
            return (
                <Row align='flex-start' justify='flex-start'>
                    <Rating2 backgroundColor={backgroundColor} color={color}>{formatRating}</Rating2>
                </Row>
            );
        }

        case 'S7': {
            return (
                <Row align='flex-end' justify='flex-end' space='true' >
                    <Icon type='social' platform="google" size={14} circle={false} color={!color} customcolor={color} />
                    <Column>
                        <RatingStyle4 color={color}>{ratingLabel}</RatingStyle4>
                        <ReviewsCountStyle3 color={color}>{formatReviewCount}</ReviewsCountStyle3>
                    </Column>
                    <Rating3 backgroundColor={backgroundColor} color={color}>{formatRating}</Rating3>
                </Row>
            );
        }

        case 'S8': {
            return (
                <Row align='flex-end' justify='flex-end' space='true' >
                    <Column>
                        <RatingStyle4 color={color}>{ratingLabel}</RatingStyle4>
                        <div style={{ marginTop: '2px' }} />
                        <Row align='flex-end' justify='flex-end' space='true' >
                            <Icon type='social' platform="google" size={13} circle={false} color={!color} customcolor={color} />
                            <ReviewsCountStyle3 color={color}>{formatReviewCount}</ReviewsCountStyle3>
                        </Row>
                    </Column>
                    <Rating3 backgroundColor={backgroundColor} color={color}>{formatRating}</Rating3>
                </Row>
            );
        }

        default:
            return null;
    }
};