import styled from 'styled-components';
import { colorConstants } from '../../../constants';


export const Container = styled.div`
    min-width: ${props => props.overview ? '85%' : '100%'};
    
    border-radius: ${props => props.shape};
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    position: relative;

    .fader {
        background: ${props => props.borderColor};
        border-radius: ${props => props.shape};
    }

    .project-generative-logo {
        border-top-left-radius: ${props => props.shape};
        border-bottom-left-radius: ${props => props.shape};
        color: ${colorConstants.white} !important;
    }

    .project-logo {
        border-top-left-radius: ${props => props.shape};
        border-bottom-left-radius: ${props => props.shape};
    }

    .github-icon {
        fill: ${props => props.fontColor};
    }

    .content-box {
        background-color: ${props => props.color};
        color: ${props => props.fontColor};

        border-bottom-right-radius: ${props => props.shape};
        border-top-right-radius: ${props => props.shape};
    }

    :hover {
        cursor: pointer;

        .content-box {
            background-color: ${props => props.colorHover};
            color: ${props => props.fontColorHover};
        }

        .github-icon {
            fill: ${props => props.fontColorHover};
        }
    }
`;

export const ContainerFader = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0.1;

    z-index: 70;
`;

export const FirstColumn = styled.div`
    z-index: 71;
`;

export const SecondColumn = styled.div`
    width: 100%;
    height: 80px;
    padding: 6px 16px;
    z-index: 71;

    font-family: ${props => props.fontType || 'inherit'} !important;

    display: flex;
    justify-content: space-between;
`;

export const IconLogo = styled.div`
    width: 90px;
    height: 80px;

    background: ${props => props.color};

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 18px;
    font-weight: 700;
`;

export const Logo = styled.img`
    width: 90px;
    height: 80px;
    object-fit: cover;
`;

export const ContentSection = styled.div`
    
`;

export const Name = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin: 0;

    /** break text to multiple lines to contain it within div */
    word-break: break-all;
    /** limit text to 1 line */
    // line-height: 1.5em;
    // height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const Description = styled.div`
    width: 100%;
    font-size: 10px;
    font-weight: 300;
    margin: 2px 0 0 0;

    /** break text to multiple lines to contain it within div */
    word-break: break-all;
    /** limit text to 2 lines */
    line-height: 1.5em;
    height: 3em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const IconsSection = styled.div`
    margin-top: 2px;

    $ > * {
        fill: ${props => props.color};
    }

    :hover {
        $ > * {
            fill: ${props => props.hovercolor};
        }
    }
`;

export const Devicon = styled.div`
    font-size: 10px;
    fill: ${props => props.color};
`;

export const ControlsSection = styled.div`
    margin-left: 16px;
    margin-top: 4px;

    ${({ buttonshape }) => buttonshape && `
        margin-right: 8px;
    `}

    display: flex;
    flex-direction: column;
`;