// Manages the links section of the application state used to list links and link info
import { linkReducerConstants } from '../constants';

export function link(state = {}, action) {

  switch (action.type) {

    case linkReducerConstants.CREATE_REQUEST:
        return { creating: true };

    case linkReducerConstants.CREATE_SUCCESS:
        return { id: action.link.id };

    case linkReducerConstants.CREATE_FAILURE:
        return {};
    
    case linkReducerConstants.GETALL_REQUEST:
        return {
            ...state,
            loading: true
        };

    case linkReducerConstants.GETALL_SUCCESS:
        return {
            links: action.links
        };

    case linkReducerConstants.GETALL_FAILURE:
        return {
            links: [],
            error: action.error
        };

    case linkReducerConstants.GETBYID_REQUEST:
        return {
            loading: true
        };
    
    case linkReducerConstants.GETBYID_SUCCESS:
        return {
            ...action.link
        };

    case linkReducerConstants.GETBYID_FAILURE:
        return {
            error: action.error
        };

    case linkReducerConstants.UPDATE_REQUEST:
        if (state.id === action.link.id) return { ...state, updating: true };
        return state;

    case linkReducerConstants.UPDATE_SUCCESS: { // I use blocks here to scope the declared variables
        let { updating, ...stateCopy } = state; // remove updating flag
        if (state.id === action.link.id) return { ...stateCopy, ...action.link };
        else if(state.links) {
            let updatedLinks = state.links.map(item => item.id !== action.link.id);
            return { ...stateCopy, ...updatedLinks };
        }
        return stateCopy;
    }

    case linkReducerConstants.UPDATE_FAILURE: { // I use blocks here to scope the declared variables
        let { updating, ...stateCopy } = state; // remove updating flag
        return { ...stateCopy, updateError: action.error };
    }

    case linkReducerConstants.DELETE_REQUEST:
        if (state.id === action.id) return { ...state, deleting: true };
        else if (state.links) {
            let deleting = false;
            state.links.forEach(item => {
                if (item.id === action.id) deleting = true;
            });
            if(deleting) return { ...state, deleting };
        }
        return state;

    case linkReducerConstants.DELETE_SUCCESS:
        if (state.id === action.id) return {};
        else if (state.links) return { links: state.links.filter(item => item.id !== action.id) };
        return state;

    case linkReducerConstants.DELETE_FAILURE: {
        let { deleting, ...stateCopy } = state;
        return { ...stateCopy, deleteError: action.error }; // remove deleting flag and add error
    }

    case linkReducerConstants.CLEAR_ALL:
        return {};

    default:
        return state
  }
}