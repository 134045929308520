import React from 'react';
import styled from 'styled-components';
import { GeoAltFill } from 'react-bootstrap-icons';

import { colorConstants, deviceConstants } from "../../../constants";

const Container = styled.div`
    margin-top: ${({ topoffset }) => topoffset};
    z-index: 3;

    position: absolute;
    width: 100%;
    padding-left: ${({ custompadding }) => custompadding};
    padding-right: ${({ custompadding }) => custompadding};
    left: 0;

    ${({ isFullScreen }) => isFullScreen && `
        @media ${deviceConstants.tablet} {
            width: 25%;
            padding: 0;
            left: auto;
        }

        @media ${deviceConstants.desktop} {
            width: 20%;
        }
    `};
`;

const List = styled.ul`
    height: 150px;
    padding: 0;
    overflow: auto;
    font-size: 12px;
    font-weight: 500;

    color: ${({ fontColor }) => fontColor || colorConstants.black};
    background: ${({ color }) => color || colorConstants.white};

    border-radius: ${({ shape }) => shape || '2px'};
    box-shadow: 0 4px 6px rgb(32 33 36 / 28%);

    @media ${deviceConstants.tablet} {
        height: 250px;

        ::-webkit-scrollbar {
            width: 6px;
        }
    
        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
            border-radius: 10px;
        }
        
        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
        }
    }

    .search-list-item {
        :hover {
            cursor: pointer;
            color: ${({ fontColorHover }) => fontColorHover || colorConstants.primaryDark};
            background: ${({ colorHover }) => colorHover || colorConstants.verySoftGrey};

            .search-text-container {
                color: ${({ fontColorHover }) => fontColorHover || colorConstants.darkGrey};
            }

            .search-text-primary {
                color: ${({ fontColorHover }) => fontColorHover || colorConstants.darkGrey};
            }

            .search-text-secondary {
                color: ${({ fontColorHover }) => fontColorHover || colorConstants.mediumGrey};    
            }
        }
    }

    .search-text-container {
        color: ${({ fontColor }) => fontColor || colorConstants.darkGrey};
    }

    .search-text-primary {
        color: ${({ fontColor }) => fontColor || colorConstants.darkGrey};
    }

    .search-text-secondary {
        color: ${({ fontColor }) => fontColor || colorConstants.mediumGrey};
    }

    & > * {
        margin-bottom: 8px;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }
`;

const ListItem = styled.li`
    padding: 8px;

    list-style-type: none;
`;

const ListItemContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LocationIcon = styled(GeoAltFill)`
    font-size: 14px;
    margin-left: 8px;
`;

const LocationTextContainer = styled.div`
    margin-left: 8px;
`;

const PrimaryText = styled.div`
    font-weight: 700;
`;

const SecondaryText = styled.div`
    font-weight: 400;
`;



export const SearchAutocompleteResults = ({ items, handleClick, schema, isLocation, shape, padding, topoffset, isFullScreen, color, colorHover, fontColor, fontColorHover }) => {

    if(!items || items.length === 0) return null;
    
    let options = null;

    // create location list items
    if(isLocation) {
        options = items.map((item, index) => (
            <ListItem className='search-list-item' key={index} onClick={() => handleClick(item)}>
                <ListItemContent>
                    <LocationIcon />
                    <LocationTextContainer className='search-text-container'>
                        <PrimaryText className='search-text-primary'>{item['main_text']}</PrimaryText>
                        <SecondaryText className='search-text-secondary'>{item['secondary_text']}</SecondaryText>
                    </LocationTextContainer>
                </ListItemContent>
            </ListItem>
        ));
    }

    // create default list items
    if(!options) options = items.map((item, index) => (<ListItem key={index} onClick={() => handleClick(item)}>{item[schema]}</ListItem>));


    return (
        <Container isFullScreen={isFullScreen} custompadding={padding} topoffset={topoffset} >
            <List shape={shape} color={color} colorHover={colorHover} fontColor={fontColor} fontColorHover={fontColorHover} >{options}</List>
        </Container>
    );
};