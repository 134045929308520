import styled from 'styled-components';
import { ReactComponent as LogoGradientSvg } from "../../../assets/branding/logo_gradient.svg";
import { ReactComponent as LogoBlackSvg } from "../../../assets/branding/logo_black.svg";
import { urlConstants } from '../../../constants';


const Container = styled.div`
    display: flex;
    justify-content: center;
    z-index: ${props => props.zIndex || 0};
`;

const Url = styled.a`
    margin: 0;
    padding: 0;
`;

const StyledLogoGradient = styled(LogoGradientSvg)`
    height: auto;
    min-width: 112px;
`;

const StyledLogoBlack = styled(LogoBlackSvg)`
    height: auto;
    min-width: 112px;

    // Use this attribute to change color
    g {
        fill: ${props => props.setcolor ? props.setcolor : '#181818'};
    }
`;


export const Logo = (props) => {
    const { noRedirect, gradient, color, zIndex, ...rest } = props;

    const logo = gradient ? <StyledLogoGradient {...rest} /> : <StyledLogoBlack setcolor={color} {...rest} />;

    return (
        <Container zIndex={zIndex} >
            {!noRedirect && <Url href={urlConstants.baseUrlClient}>{logo}</Url> }
            {noRedirect && logo}
        </Container>
    );
};