import {
    Container,
    Photo,
    ContentColumn,
    Content,
    Name,
    AnalyticsSection,
    Stat,
    Views,
    Saves,
    ButtonsSection,
    ButtonSectionLeft,
    EditButton,
    DeleteButton,
    EnableToggle
} from './NFTListItem.styles';


const NFTListItem = (props) => {
    const { data, handleView, handleEdit, handleDelete, handleEnabled, ...rest } = props;

    return (
        <Container {...rest} >
            <Photo onClick={() => handleView(data.id, data.customUrl)} src={data.image} />

            <ContentColumn>
                <Content onClick={() => handleView(data.id, data.customUrl)}>
                    <Name onClick={() => handleView(data.id, data.customUrl)}>{`${data.name} (#${data.tokenId})`}</Name>

                    { data.analytics && <AnalyticsSection onClick={() => handleView(data.id, data.customUrl)}>
                        <Stat>{data.analytics.views}<Views size={11} /></Stat>
                        <Stat>{data.analytics.saves}<Saves size={11} /></Stat>
                        <Stat>{data.analytics.earnings}</Stat>
                    </AnalyticsSection> }
                </Content>

                <ButtonsSection>
                    <ButtonSectionLeft>
                        <EditButton size={22} onClick={() => handleEdit(data.id, data.customUrl)} />
                        <DeleteButton size={22} onClick={() => handleDelete(data.id, data.customUrl)} />
                    </ButtonSectionLeft>

                    <EnableToggle type="switch" name={'enable-item-' + data.id} checked={data.enabled} onChange={() => handleEnabled(data.id, !data.enabled)} />
                </ButtonsSection>
            </ContentColumn>
        </Container>
    );
};

export default NFTListItem;