import React, { useRef, useEffect, useMemo } from "react";
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { Avatar } from '../custom/Avatar';
import { GradientFormInput, GradientInputEditor } from '../custom/FormInput';
import {
  NoSocialLinksFound,
  NoCreditCardsFound,
  NoCryptoWalletsFound,
  // NoPaymentInfoFound,
  NoUserAddressFound
} from '../custom/EmptyState';
import SettingsSectionTitleButton from './SettingsSectionTitleButton';
import SettingsSocialItem from './SettingsSocialItem';
import SettingsCryptoWalletItem from './SettingsCryptoWalletItem';
import SettingsUserAddressButton from './SettingsUserAddressButton';
import SettingsEarnings from './SettingsEarnings';
import { SavedPaymentMethod } from '../custom/Finance';
import {
  Container,
  UserInfoSection,
  ProfilePhotoSection,
  FileUploader,
  PublicProfileLink,
  StyledFormGroup,
  FormInputTitle,
  MaxTextIndicator,
  SocialMediaSection,
  CreatorModeSection,
  FinanceInfoSection,
  SubscriptionSection, SubscriptionSectionTop, SubscriptionName, SubscriptionDaysLeft,
  SubscriptionManagementSection, SubscriptionNextBillingAt, ChangeSubscriptionButton, CancelSubscription,
  BillingMethodSection, PaymentMethodsArea,
  UserAddressSection,
  CryptoWalletSection,
  // PaymentInfoSection,
  EarningsSection,
  MoreOptionsSection,
  StyledButton,
  AboutSectionWrap,
  StyledAboutSection,
  SpinnerContainer,
} from './SettingsPage.styles';
import { socialConstants } from "../../constants";
import { getHandleFromSocialUrl, makeUserAddressPretty, constructPhotoUrl } from '../../utils';


const SettingsPage = (props) => {
    const {
      handleScrollReset,
      scrollToSection,
      handleChange,
      handleCheckUsernameAvailability,
      handleUploadPhoto,
      user,
      creatorModeStatus,
      crypto,
      customer,
      paymentMethods,
      creatorPayout,
      subscription,
      handleViewPublicPage,
      handleAddSocialMediaLink,
      handleEditSocialMediaLink,
      handleDeleteSocialMediaLink,
      handleShowCreatorModeApply,
      handleChangeSubscription,
      handleCancelSubscriptionRequest,
      handleEditUserAddress,
      handleAddBilling,
      handleConnectCryptoWallet,
      handleDisconnectCryptoWallet,
      // handleAddPayment,
      handleCollectPayment,
      handlePasswordChange,
      handleEmailChange,
      handleLogout,
      handleDeleteAccountRequest
    } = props;

    // used to scroll page to sections
    const creatorModeSectionRef = useRef(null);
    const subscriptionSectionRef = useRef(null);
    const cryptoWalletSectionRef = useRef(null);
    const billingInfoSectionRef = useRef(null);

    // open file browser on profile click
    const inputPhoto = useRef(null);
    const openFileUploader = () => {
      inputPhoto.current.click();
    };

    // show creator mode apply section
    const showCreatorModeApplySection = useMemo(() => {
      if(!creatorModeStatus || creatorModeStatus === 'NO_APPLICATION') return true;
      return false;
  }, [creatorModeStatus]);

    // make user address easily readable
    const readableUserAddress = makeUserAddressPretty(customer.address);

    // empty state flags
    let hasSocialLinks = true;
    let hasBillingMethod = paymentMethods && paymentMethods.length > 0;
    // let hasBank = true;

    // show Subscription Section
    const showSubscriptionSection = subscription && subscription.isActive;
    // show Billing Method Section
    const showBillingMethodSection = customer && customer.id && hasBillingMethod;

    // calculate max content width
    const maxSocialMediaUsernameWidth = useMemo(() => {
      const screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      return (screenWidth - 64) / 3;
  }, []);

    // set social media links
    let socialMediaLinks = Object.entries(user.social).map((entry, index) => {
                            // skip empty links
                            if (!entry[1]) return null;
                            hasSocialLinks = false;
                            return <SettingsSocialItem
                                    maxUsernameWidth={maxSocialMediaUsernameWidth}
                                    key={entry[0]}
                                    size={14}
                                    title={socialConstants.platforms[index] + ':'}
                                    subtitle={getHandleFromSocialUrl(socialConstants.platforms[index], entry[1])}
                                    handleEdit={() => handleEditSocialMediaLink(index)}
                                    handleDelete={() => handleDeleteSocialMediaLink(entry[0])} />
                          });
    
    // display connected wallets
    const hasCryptoWallets = crypto && crypto.wallets && crypto.wallets.length > 0;
    let wallets = null;
    if(hasCryptoWallets) {
      wallets = crypto.wallets.map((item, index) => {
        return <SettingsCryptoWalletItem
                  key={index}
                  data={item}
                  handleDisconnect={handleDisconnectCryptoWallet} />
      });
    }

    useEffect(() => {

      // Scroll to page section
      if(scrollToSection) {
        switch(scrollToSection){
          // scroll to Creator Mode Apply Section
          case 'creator-application': {
            if(creatorModeSectionRef.current) {
              creatorModeSectionRef.current.scrollIntoView();
            }
            break;
          }

          case 'subscription': {
            if(subscriptionSectionRef.current) {
              subscriptionSectionRef.current.scrollIntoView();
            }
            break;
          }

          case 'crypto-wallet': {
            if(cryptoWalletSectionRef.current) {
              cryptoWalletSectionRef.current.scrollIntoView();
            }
            break;
          }

          case 'billing-info': {
            if(billingInfoSectionRef.current) {
              billingInfoSectionRef.current.scrollIntoView();
            }
            break;
          }
  
          default:
            break;
        }

        handleScrollReset();
      }

      return () => {
        // execute code on component unmount here
      };
    }, [handleScrollReset, scrollToSection]);

    const isCryptoEnabled = useMemo(() => {
      if(user && user.pageData && user.pageData.metadata && user.pageData.metadata.links && user.pageData.metadata.links.nft && user.pageData.metadata.links.nft.enabled) return true;
      return false;
    }, [user]);

    return (
          <Container>

            <UserInfoSection>
              <ProfilePhotoSection className="first-step" >
                <FileUploader type='file' accept="image/*" id='photo' ref={inputPhoto} onChange={handleUploadPhoto} />
                <Avatar border={true} mode="upload" imageSize={110} url={user.profilePhotoUrl ? constructPhotoUrl(user.profilePhotoUrl) : null} onClick={openFileUploader} cursor='pointer' />
              </ProfilePhotoSection>

              <PublicProfileLink onClick={user.creator ? handleViewPublicPage : openFileUploader}>{user.creator ? 'View Public Page' : 'Change photo'}</PublicProfileLink>

              <Form id="user-profile-info" className="second-step" >
                { user.creator &&
                <StyledFormGroup>
                    <FormInputTitle>Username</FormInputTitle>
                    <GradientFormInput type="text" name="username" value={user.username} onChange={handleCheckUsernameAvailability} />
                </StyledFormGroup> }

                <StyledFormGroup>
                    <FormInputTitle>Full Name</FormInputTitle>
                    <GradientFormInput type="text" name="name" value={user.name} onChange={handleChange} />
                </StyledFormGroup>

                { user.creator &&
                <StyledFormGroup>
                    <FormInputTitle>Display Name</FormInputTitle>
                    <GradientFormInput type="text" name="displayName" value={user.displayName} onChange={handleChange} maxLength="30" />
                    <MaxTextIndicator>{user.displayName && user.displayName.length > 0 ? user.displayName.length : '0'}/30</MaxTextIndicator>
                </StyledFormGroup> }
                
                { user.creator &&
                <StyledFormGroup>
                  <FormInputTitle>Bio</FormInputTitle>
                  <GradientInputEditor as="textarea" rows={3} cols={2000} name="bio" maxLength="80" value={user.bio} onChange={handleChange} />
                  <MaxTextIndicator>{user.bio && user.bio.length > 0 ? user.bio.length : '0'}/80</MaxTextIndicator>
                </StyledFormGroup> }

                { user.creator &&
                <StyledFormGroup>
                    <FormInputTitle>Website</FormInputTitle>
                    <GradientFormInput type="text" placeholder="https://www.example.com" name="website" value={user.website} onChange={handleChange} />
                </StyledFormGroup> }
              </Form>

              { user.creator &&
              <SocialMediaSection className="third-step" >
                <SettingsSectionTitleButton size={16} title="Social Links" handleClick={!hasSocialLinks ? handleAddSocialMediaLink : null} />
                {socialMediaLinks}
                {hasSocialLinks && <NoSocialLinksFound onClick={handleAddSocialMediaLink} />}
              </SocialMediaSection> }

              <div ref={creatorModeSectionRef} />

              { !user.creator && showCreatorModeApplySection &&
              <CreatorModeSection>
                <SettingsSectionTitleButton title='Become a Creator' />
                <StyledButton small={true} oval={false} outline={true} onClick={handleShowCreatorModeApply} >Get a Creator Account</StyledButton>
              </CreatorModeSection> }

            </UserInfoSection>

            <FinanceInfoSection>
              <div ref={subscriptionSectionRef} />

              { showSubscriptionSection &&
              <SubscriptionSection>
                <SettingsSectionTitleButton title="Subscription" />

                <SubscriptionSectionTop>
                  <SubscriptionName>{subscription.plan + ' plan'}</SubscriptionName>
                  <SubscriptionDaysLeft>{subscription.daysLeft + ' days left'}</SubscriptionDaysLeft>
                </SubscriptionSectionTop>

                <SubscriptionManagementSection>
                  <SubscriptionNextBillingAt>
                    {!subscription.isCancelled ? ('Your next billing is on ' + subscription.nextBillingAt) : ('On ' + subscription.nextBillingAt + ", you'll move to a Free plan and you won't be charged.")}
                  </SubscriptionNextBillingAt>
                  { !subscription.isCancelled && <ChangeSubscriptionButton outline={true} small={true} onClick={handleChangeSubscription} >Change Plan</ChangeSubscriptionButton> }
                </SubscriptionManagementSection>

                { !subscription.isCancelled &&
                <CancelSubscription onClick={handleCancelSubscriptionRequest}>Cancel my subscription</CancelSubscription> }
              </SubscriptionSection> }

              <div ref={billingInfoSectionRef} />

              { showBillingMethodSection &&
              <BillingMethodSection>
                <SettingsSectionTitleButton size={16} title="Billing Info" handleClick={!hasBillingMethod ? handleAddBilling : null} />
                <PaymentMethodsArea>
                  <SavedPaymentMethod fullDetails={true} data={paymentMethods} />
                </PaymentMethodsArea>
                { !hasBillingMethod && <NoCreditCardsFound onClick={handleAddBilling} /> }
                {/* // FEATURE: Add payment method management functionality ie. add, remove or change default*/}
              </BillingMethodSection> }

              { customer.email &&
              <UserAddressSection>
                <SettingsSectionTitleButton title="Address" />

                {readableUserAddress && <SettingsUserAddressButton text={readableUserAddress} onClick={handleEditUserAddress} /> }
                {!readableUserAddress && <NoUserAddressFound onClick={handleEditUserAddress} />}
              </UserAddressSection> }

              <div ref={cryptoWalletSectionRef} />

              { user.creator && isCryptoEnabled &&
              <CryptoWalletSection>
                <SettingsSectionTitleButton size={16} title="Crypto Wallet" handleClick={hasCryptoWallets ? handleConnectCryptoWallet : null} />
                {wallets}
                { !hasCryptoWallets && <NoCryptoWalletsFound onClick={handleConnectCryptoWallet} /> }
              </CryptoWalletSection> }

              {/* // FEATURE: Enable Payment info section */}
              {/* { user.creator &&
              <PaymentInfoSection>
                <SettingsSectionTitleButton title="Payment Info" />
                {hasBank && <NoPaymentInfoFound onClick={handleAddPayment} />}
              </PaymentInfoSection> } */}

              { user.creator && creatorPayout &&
              <EarningsSection className="fourth-step" >
                <SettingsSectionTitleButton title="Earnings" />
                { creatorPayout.loading && <SpinnerContainer><Spinner animation="border" /></SpinnerContainer> }
                { !creatorPayout.loading && <SettingsEarnings data={creatorPayout.stats} onClick={handleCollectPayment} /> }
              </EarningsSection> }
            </FinanceInfoSection>

            <MoreOptionsSection>
              <SettingsSectionTitleButton title="Account" />
              { user.authProvider && user.authProvider === 'password' && <StyledButton small={true} oval={false} outline={true} onClick={handlePasswordChange} >Change Password</StyledButton> }

              <StyledButton small={true} oval={false} outline={true} onClick={handleEmailChange} >Change Email</StyledButton>
              
              <AboutSectionWrap>
                <StyledAboutSection noRedirect={true} gradient={true} />
              </AboutSectionWrap>

              <StyledButton small={true} oval={false} outline={true} onClick={handleLogout} >Logout</StyledButton>

              <StyledButton small={true} oval={false} outline={true} onClick={handleDeleteAccountRequest} >Delete Account</StyledButton>
            </MoreOptionsSection>
          </Container>
    );
};

export default SettingsPage;