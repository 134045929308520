import React, { useMemo } from "react";

import { Container } from './UpgradeCheckoutPage.styles';
import { BillingForm } from '../../custom/Finance';
import { SelectedPlanSummary } from './SelectedPlanSummary';
import { UpgradeCheckoutSummary } from './UpgradeCheckoutSummary';
import { addCurrencySymbol } from '../../../utils';
import { contentUpgradePage } from '../../../constants';



export const UpgradeCheckoutPage = ({
    subscription, hasActiveSubscription, isMonthly, handleChangeMonthly,
    customer,
    coupon, handleChangeCoupon,
    handleOpenTerms,
    paymentMethod,
    handleChangeBillingDetails,
    handleChangeBillingAddress,
    handleChangePaymentMethod,
    handleChangeSavedPaymentMethod,
    handleSubmit, onComplete,
}) => {

    // validate checkout page configured
    const isConfigured = useMemo(() => subscription && subscription.config && subscription.config.status === 'success', [subscription]);

    // check if error
    // const hasError = useMemo(() => !isConfigured && subscription.config && subscription.config.error, [isConfigured, subscription]);

    // validate coupon code error
    const inValidCoupon = useMemo(() => {
        if(subscription && subscription.config && subscription.config.couponError === 'Invalid coupon code') return true;
        return false;
    }, [subscription]);

    // get plan details
    const planDetails = useMemo(() => (subscription && subscription.plans) ? subscription.plans : null, [subscription]);

    // get product pricing details
    const monthlyPrice = useMemo(() => {
        if(isConfigured) return addCurrencySymbol(subscription.config.data.monthly.currency, subscription.config.data.monthly.unitPrice);
        else if(planDetails) return addCurrencySymbol(planDetails.monthly.currency.name, planDetails.monthly.price);
        return null;
    }, [isConfigured, subscription, planDetails]);

    const planYearlyPricePerMonth = useMemo(() => {
        if(!planDetails) return null;
        return planDetails.monthly.price - (planDetails.monthly.price * planDetails.yearlyBillingDiscountPercentage);
    }, [planDetails]);

    const yearlyPrice = useMemo(() => {
        if(isConfigured) return addCurrencySymbol(subscription.config.data.monthly.currency, subscription.config.data.yearly.unitPrice);
        else if(planDetails && planYearlyPricePerMonth) return addCurrencySymbol(planDetails.yearly.currency.name, planYearlyPricePerMonth * 12);
        return null;
    }, [isConfigured, subscription, planDetails, planYearlyPricePerMonth]);

    const yearlyPricePerMonth = useMemo(() => {
        if(isConfigured) return addCurrencySymbol(subscription.config.data.monthly.currency, subscription.config.data.yearly.unitPricePerMonth);
        else if(planDetails && planYearlyPricePerMonth) return addCurrencySymbol(planDetails.yearly.currency.name, planYearlyPricePerMonth);
        return null;
    }, [isConfigured, subscription, planDetails, planYearlyPricePerMonth]);

    // get selected plan invoice data
    const invoice = useMemo(() => {
        if(!isConfigured) return null;
        return isMonthly ? subscription.config.data.monthly : subscription.config.data.yearly;
    }, [isConfigured, subscription.config, isMonthly]);

    // get upgrade checkout summary component
    const checkoutSummaryComponent = useMemo(() => {
        if(!planDetails) return null;

        return <UpgradeCheckoutSummary
                    summary={contentUpgradePage.checkoutPage.summary}
                    plan={planDetails}
                    isMonthly={isMonthly}
                    monthlyPrice={monthlyPrice}
                    yearlyPrice={yearlyPrice}
                    isDataReady={isConfigured}
                    data={invoice} />
    }, [isConfigured, planDetails, isMonthly, monthlyPrice, yearlyPrice, invoice]);



    return (
        <Container>

            { planDetails &&
            <SelectedPlanSummary
                plan={planDetails}
                isMonthly={isMonthly}
                handleChangeMonthly={handleChangeMonthly}
                monthlyPrice={monthlyPrice}
                yearlyPricePerMonth={yearlyPricePerMonth} /> }

            { planDetails &&
            <BillingForm
                disableDetailsChange={hasActiveSubscription}
                // Coupon
                disableCoupons={hasActiveSubscription}
                coupon={coupon}
                handleChangeCoupon={handleChangeCoupon}
                inValidCoupon={inValidCoupon}
                // Billing Details
                handleOpenTerms={handleOpenTerms}
                name={customer.name}
                email={customer.email}
                phone={customer.phone}
                handleChangeBillingDetails={handleChangeBillingDetails}
                // Billing Address
                address={customer.address}
                handleChangeBillingAddress={handleChangeBillingAddress}
                // Payment
                paymentMethod={paymentMethod}
                handleChangePaymentMethod={handleChangePaymentMethod}
                handleChangeSavedPaymentMethod={handleChangeSavedPaymentMethod}
                isCardCvcValidationEnabled={true}
                // Payment: Stripe
                clientSecret={subscription.clientSecret}
                stripeId={subscription.stripeSubId}
                isSetupIntent={subscription.isSetupIntent}
                // Pre Button Section: show checkout summary
                preButtonSection={checkoutSummaryComponent}
                // Payment: Button
                successMessage={contentUpgradePage.checkoutPage.purchaseSuccessMessage}
                buttonText={contentUpgradePage.checkoutPage.purchaseButton}
                handleSubmit={handleSubmit}
                onComplete={onComplete}
            /> }
        </Container>
    );
};