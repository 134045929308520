// Manages the products section of the application state used to list products and product info
import { productReducerConstants } from '../constants';

export function product(state = {}, action) {

  switch (action.type) {

    case productReducerConstants.CREATE_REQUEST:
        return { creating: true };

    case productReducerConstants.CREATE_SUCCESS:
        return { id: action.product.id };

    case productReducerConstants.CREATE_FAILURE:
        return {};
    
    case productReducerConstants.GETALL_REQUEST:
        return {
            ...state,
            loading: true
        };

    case productReducerConstants.GETALL_SUCCESS:
        return {
            products: action.products
        };

    case productReducerConstants.GETALL_FAILURE:
        return {
            products: [],
            error: action.error
        };

    case productReducerConstants.GETBYID_REQUEST:
        return {
            loading: true
        };
    
    case productReducerConstants.GETBYID_SUCCESS:
        return {
            ...action.product
        };

    case productReducerConstants.GETBYID_FAILURE:
        let { loading, ...stateCopy } = state;
        return {
            ...stateCopy,
            error: action.error
        };

    case productReducerConstants.GETBYSLUG_REQUEST:
        return {
            loading: true
        };
    
    case productReducerConstants.GETBYSLUG_SUCCESS:
        return {
            ...action.product
        };

    case productReducerConstants.GETBYSLUG_FAILURE: {
        let { loading, ...stateCopy } = state;
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case productReducerConstants.UPDATE_REQUEST: {
        if(state.id && !state.products) {
            // single product edit
            if (state.id === action.product.id) return { ...state, updating: true };
        } else if(!state.id && state.products) {
            // products list edit
            let updating = false;
            state.products.forEach((item, index) => {
                if (item.id === action.product.id) updating = true;
            });
            if(updating) return { ...state, updating };
        }

        return state;
    }

    case productReducerConstants.UPDATE_SUCCESS: {
        // remove updating flag
        let { updating, ...stateCopy } = state;

        if(state.id && !state.products) {
            // single product edit
            if (state.id === action.product.id) return { ...stateCopy, ...action.product };
        } else if(!state.id && state.products) {
            // products list edit
            let updatedProducts = state.products.map((item, index) => {
                if (item.id === action.product.id) return action.product;
                else return item;
            });
            return { ...stateCopy, products: updatedProducts };
        }

        return stateCopy;
    }

    case productReducerConstants.UPDATE_FAILURE: {
        // remove updating flag
        let { updating, ...stateCopy } = state;

        if(state.id && !state.products) {
            // single product edit
            if (state.id === action.product.id) return { ...stateCopy, updateError: action.error };
        } else if(!state.id && state.products) {
            // products list edit
            let hasError = false;
            state.products.forEach((item, index) => {
                if (item.id === action.product.id) hasError = true;
            });
            if(hasError) return { ...stateCopy, updateError: action.error };
        }

        return stateCopy;
    }

    case productReducerConstants.FAVOURITE_REQUEST: {
        if(state.id && !state.products) {
            // single product edit
            if (state.id === action.data.id) return { ...state, savingToFavourites: true };
        } else if(!state.id && state.products) {
            // products list edit
            let savingToFavourites = false;
            state.products.forEach((item, index) => {
                if (item.id === action.data.id) savingToFavourites = true;
            });
            if(savingToFavourites) return { ...state, savingToFavourites };
        }

        return state;
    }

    case productReducerConstants.FAVOURITE_SUCCESS: {
        // remove savingToFavourites flag
        let { savingToFavourites, ...stateCopy } = state;

        if(state.id && !state.products) {
            // single product edit
            if (state.id === action.product.id) return { ...stateCopy, favourite: action.product.favourite };
        } else if(!state.id && state.products) {
            // products list edit
            let updatedProducts = state.products.map((item, index) => {
                if (item.id === action.product.id) return { ...item, favourite: action.product.favourite };
                else return item;
            });
            return { ...stateCopy, products: updatedProducts };
        }

        return stateCopy;
    }

    case productReducerConstants.FAVOURITE_FAILURE: {
        // remove savingToFavourites flag
        let { savingToFavourites, ...stateCopy } = state;

        if(state.id && !state.products) {
            // single product edit
            if (state.id === action.id) return { ...stateCopy, favouriteError: action.error };
        } else if(!state.id && state.products) {
            // products list edit
            let hasError = false;
            state.products.forEach((item, index) => {
                if (item.id === action.id) hasError = true;
            });
            if(hasError) return { ...stateCopy, favouriteError: action.error };
        }

        return stateCopy;
    }

    case productReducerConstants.DELETE_REQUEST: {
        if (state.id === action.id) return { ...state, deleting: true };
        else if (state.products) {
            let deleting = false;
            state.products.forEach(item => {
                if (item.id === action.id) deleting = true;
            });
            if(deleting) return { ...state, deleting };
        }
        return state;
    }

    case productReducerConstants.DELETE_SUCCESS: {
        if (state.id === action.id) return {};
        else if (state.products) return { products: state.products.filter(item => item.id !== action.id) };
        return state;
    }

    case productReducerConstants.DELETE_FAILURE: {
        let { deleting, ...stateCopy } = state;
        return { ...stateCopy, deleteError: action.error }; // remove deleting flag and add error
    }

    case productReducerConstants.CLEAR_ALL:
        return {};

    default:
        return state;
  }
}