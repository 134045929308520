import React, { useMemo } from 'react';
import {
    Container,
    StyledForm,
    StyledFormGroup,
    FormInputTitle,
    // StyledFormText,
    SpinnerWrap,
    StyledSpinner,
    List,
    StyledButton,
} from './SelectNFT.styles';
import { GradientFormSelector } from '../../custom/FormInput';
import { NoNFTFound } from '../../custom/EmptyState';
import { SelectNFTCard } from './SelectNFTCard';
import { useWindowSize } from '../../../utils';


export const SelectNFT = ({
    sticky,
    wallets,
    selectedWallet,
    assets,
    loadingWallets,
    loadingAssets,
    initAssets,
    handleChangeWallet,
    handleSelect,
    handleLoadMore,
}) => {

    // detect screen size
    const [width] = useWindowSize();

    // calculate max NFT card width
    const maxNFTCardWidth = useMemo(() => {
        if(width < 991) return (width - (3 * 32)) / 2; // for mobile
        
        // calculate app section minus the padding on larger screens
        let section = 0;
        if(width >= 991 && width < 1440) {
            section = width - (width * 0.66); // for tablet
        } else if(width >= 1440 && width < 1600) {
            section = width - (width * 0.70); // for laptop
        } else {
            section = width - (width * 0.75); // for laptop
        }

        return (section - (3 * 32)) / 2;
    }, [width]);


    // show spinner when true
    const loading = loadingWallets || loadingAssets;

    // check if has assets in wallet
    const hasAssets = initAssets && assets && assets.length > 0;

    // show spinner when true
    const syncingAssets = selectedWallet && !selectedWallet.synced && !hasAssets;

    // show load more button
    const loadMoreButton = hasAssets && assets.length === 20;


    // show wallet assets
    let assetsList = null;
    if(hasAssets) {
        assetsList = assets.map((asset, index) => <SelectNFTCard maxWidth={maxNFTCardWidth + 'px'} key={index} data={asset} handleSelect={handleSelect} />);
    }


    // calculate wallet selector options
    const walletOptions = useMemo(() => {
        return wallets.map((item) => {
            return {
                ...item,
                name: item.type,
                label: item.type
            };
        });
    }, [wallets]);

    
    return (
        <Container sticky={sticky ? 'true' : null}>
            <StyledForm id="select-wallet" className='select-wallet'>
                <StyledFormGroup>
                    <FormInputTitle>Select Wallet</FormInputTitle>
                    <GradientFormSelector
                        options={walletOptions}
                        id="selector"
                        name="type"
                        selected={walletOptions[0]}
                        onChange={handleChangeWallet} />
                </StyledFormGroup>
            </StyledForm>

            { (loading || syncingAssets) && <NoNFTFound header='Loading NFTs' text='This might take a few moments, you can leave this page and come back...' />}
            { (loading || syncingAssets) && <SpinnerWrap><StyledSpinner animation="border" /></SpinnerWrap> }

            { !loading && !syncingAssets && !hasAssets && <NoNFTFound header='NFTs Not Found' text='We could not find any NFTs in this wallet' /> }

            <List className='assets-list'>
                {assetsList}
            </List>

            { loadMoreButton &&
            <StyledButton
                static={sticky ? 'true' : null}
                gradient={true}
                type="submit"
                onClick={handleLoadMore}
            >
                Load more
            </StyledButton> }
        </Container>
    );
};