import { homepageReducerConstants } from "../constants";
import { homepageService } from "../services";
import { alertActions } from './';

export const homepageActions = {
    joinWaitlist
};

function joinWaitlist(data) {
    return dispatch => {
        dispatch(request());

        homepageService.joinWaitlist(data)
            .then(
                () => {
                    dispatch(success());
                    dispatch(alertActions.success(`Congratulations, your Bunjee is now reserved! ${data.note === 'Standard User' ? 'Your application is under review.' : 'Proceed with your registration.'}`));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: homepageReducerConstants.JOIN_WAITLIST_REQUEST } }
    function success() { return { type: homepageReducerConstants.JOIN_WAITLIST_SUCCESS } }
    function failure() { return { type: homepageReducerConstants.JOIN_WAITLIST_FAILURE } }
}