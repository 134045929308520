// Manages the public trips section of the application state used to list trips and trip info
import { publicTripsReducerConstants } from '../constants';

export function publicTrips(state = { trips: [] }, action) {

  switch (action.type) {

    case publicTripsReducerConstants.GETALL_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicTripsReducerConstants.GETALL_SUCCESS:
        return {
            trips: action.trips
        };

    case publicTripsReducerConstants.GETALL_FAILURE:
        return {
            trips: [],
            error: action.error
        };

    case publicTripsReducerConstants.GETBYSLUG_REQUEST:
        return {
            ...state,
            loading: true
        };
    
    case publicTripsReducerConstants.GETBYSLUG_SUCCESS:
        return {
            trips: [action.trip]
        };

    case publicTripsReducerConstants.GETBYSLUG_FAILURE: {
        let { loading, ...stateCopy } = state; // remove loading flag
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case publicTripsReducerConstants.FILTER_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicTripsReducerConstants.FILTER_SUCCESS:
        return {
            trips: action.trips
        };

    case publicTripsReducerConstants.FILTER_FAILURE: {
        let { loading, ...stateCopy } = state; // remove loading flag
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case publicTripsReducerConstants.RESET_ALL: {
        return { trips: [] }
    }

    default:
        return state
  }
}