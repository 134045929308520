import React from 'react';
import styled from 'styled-components';

import { colorConstants } from "../../constants";
import Icon from '../../assets/icons/Icon';


const SocialLoginContainer = styled.div`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SocialLoginTitle = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${colorConstants.black};
`;

const SocialIconsContainer = styled.div`
  width: ${props => props.fullButtons ? '100%' : '70%'};
  margin-top: 16px;

  display: flex;
  flex-direction: ${props => props.fullButtons ? 'column' : 'row'};
  align-items: center;
  justify-content: ${props => props.fullButtons ? 'center' : 'space-between'};

  & > * {
    margin-bottom: ${props => props.fullButtons ? '16px' : '0'};
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  .button-small {
    display: ${props => props.fullButtons ? 'none' : 'initial'};

    :hover {
      cursor: pointer;
    }
  }

  .button-large {
    display: ${props => props.fullButtons ? 'initial' : 'none'};
  }
`;

const GradientBorder = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    background: ${colorConstants.gradientHorizontal};
    border-radius: 4px;
    padding: 1px;

    :hover {
        background: ${colorConstants.primaryDark};
    }
`;

const Button = styled.div`
    width: 100%;
    background: ${colorConstants.white};
    padding: 8px 12px;
    border-radius: 4px;
    border: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    :hover {
      cursor: pointer;
      background: ${colorConstants.verySoftWhite};
    }

    & > * {
      margin-right: 32px;
    }

    & > *:first-child {
      margin-left: 16px;
    }

    & > *:last-child {
      margin-right: 0;
    }
`;

const ButtonLabel = styled.div`
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    color: ${colorConstants.black};
    text-align: center;
`;


const SocialLogins = (props) => {
  const { fullButtons, handleGoogle, handleFacebook } = props;

    return (
        <SocialLoginContainer>
          <SocialLoginTitle>Continue with</SocialLoginTitle>

          <SocialIconsContainer fullButtons={fullButtons} >

            <Icon onClick={() => handleGoogle()} className='button-small' type='social' platform="google" size={50} circle={false} color={true} />
            <Icon onClick={() => handleGoogle()} className='button-small' type='social' platform="youtube" size={50} circle={true} color={true} />
            <Icon onClick={() => handleFacebook()} className='button-small' type='social' platform="facebook" size={50} circle={true} color={true} />

            <GradientBorder className='button-large'>
              <Button onClick={() => handleGoogle()}>
                <Icon type='social' platform="google" size={20} circle={false} color={true} />
                <ButtonLabel>Log in with Google</ButtonLabel>
              </Button>
            </GradientBorder>

            <GradientBorder className='button-large'>
              <Button onClick={() => handleGoogle()}>
                <Icon type='social' platform="youtube" size={20} circle={false} color={true} />
                <ButtonLabel>Log in with YouTube</ButtonLabel>
              </Button>
            </GradientBorder>

            <GradientBorder className='button-large'>
              <Button onClick={() => handleFacebook()}>
                <Icon type='social' platform="facebook" size={20} circle={false} color={true} />
                <ButtonLabel>Log in with Facebook</ButtonLabel>
              </Button>
            </GradientBorder>

          </SocialIconsContainer>
        </SocialLoginContainer>
    );
};

export default SocialLogins;