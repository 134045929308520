import React, { useRef, useEffect } from "react";
import { 
    Container,
    HeroSection, HeroContent, HeroHeader, HeroSubheader, HeroButton, HeroLoginSpan, HeroLoginLink, HeroImageWrapper, HeroImage,
    MiddleSection, MiddleContent, MiddleHeader, MiddleSubheader, MiddleArrowWrapper, MiddleRightArrow, MiddleDownArrow, MiddleImage,
    MiddleForm, MiddleFormTitle
 } from './EarlyAccessLinkInBio.styles';
import { Form } from './Form';

import { generateFirebasePhotoLink } from '../../../utils';
import { sitemap, contentEarlyAccessLinkInBio } from "../../../constants";


export const EarlyAccessLinkInBio = (props) => {
    const {
        alert,
        handleScrollReset,
        scrollToSection,
        handleScrollToForm,
        handleChange,
        handleSubmit,
        handleCaptcha,
        registering,
        user,
        submitted,
        captcha,
        isEmail,
        isSocialLink,
    } = props;

    // used to scroll page to middle section with early access form
    const middleSectionRef = useRef(null);

    // set banner images
    const heroImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Fhero-section-image-compressed.png?alt=media');
    const middleSectionImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Fmiddle-section-image.jpg?alt=media');

    useEffect(() => {

        // Scroll to page section
        if(scrollToSection) {
          switch(scrollToSection){
            // scroll to Early Access Form
            case 'early-access-form': {
              if(middleSectionRef.current) {
                middleSectionRef.current.scrollIntoView();
                handleScrollReset();
              }
              break;
            }
    
            default:
              break;
          }
        }
  
        return () => {
          // execute code on component unmount here
        };
      }, [handleScrollReset, scrollToSection]);


    return (
        <Container>
            <HeroSection onSubmit={handleSubmit}>

                <HeroContent>
                    <HeroHeader>{contentEarlyAccessLinkInBio.hero.header}</HeroHeader>
                    <HeroSubheader>{contentEarlyAccessLinkInBio.hero.subheader}</HeroSubheader>
                    <HeroButton gradient={true} midCorner={true} onClick={() => handleScrollToForm('Hero Apply Button')}>{contentEarlyAccessLinkInBio.hero.primaryCta}</HeroButton>
                    <div>
                        <HeroLoginSpan>{contentEarlyAccessLinkInBio.hero.secondaryCta.part1}</HeroLoginSpan>
                        <HeroLoginLink to={sitemap.auth.login} >{contentEarlyAccessLinkInBio.hero.secondaryCta.part2}</HeroLoginLink>
                    </div>
                </HeroContent>

                <HeroImageWrapper>
                    <HeroImage src={heroImageUrl} alt="Bunjee Product Image"/>
                </HeroImageWrapper>

            </HeroSection>

            <MiddleSection ref={middleSectionRef}>

                <MiddleContent>
                    <MiddleHeader>{contentEarlyAccessLinkInBio.cta.header}</MiddleHeader>
                    <MiddleSubheader>{contentEarlyAccessLinkInBio.cta.text}</MiddleSubheader>
                    <MiddleArrowWrapper>
                        <MiddleRightArrow />
                        <MiddleDownArrow />
                    </MiddleArrowWrapper>
                </MiddleContent>

                <MiddleForm>
                    <MiddleFormTitle>{contentEarlyAccessLinkInBio.form.title}</MiddleFormTitle>

                    <Form
                        alert={alert}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleCaptcha={handleCaptcha}
                        registering={registering}
                        user={user}
                        submitted={submitted}
                        captcha={captcha}
                        isEmail={isEmail}
                        isSocialLink={isSocialLink}
                    />
                </MiddleForm>

                <MiddleImage src={middleSectionImageUrl} alt="Bunjee Banner"/>

            </MiddleSection>
        </Container>
    );
};