import styled from 'styled-components';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { Button } from '../../custom/Buttons';

import { colorConstants, deviceConstants } from '../../../constants';



export const Container = styled.div`
    width: 100%;
    border: 1px solid ${colorConstants.softGrey};
    border-radius: 8px;
    background: ${colorConstants.white};
    padding: 24px 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.div`
    font-size: 32px;
    font-weight: 700;
    color: ${colorConstants.black};
`;

export const PriceSection = styled.div`
    width: 100%;
    margin-top: 8px;
    margin-bottom: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.tablet} {
        margin-top: 16px;
        margin-bottom: 32px;
    }
`;

export const PriceTopLabel = styled.div`
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    color: ${colorConstants.mediumGrey};

    @media ${deviceConstants.tablet} {
        margin-bottom: 24px;
    }
`;

export const Price = styled.div`
    padding: 0;
    display: flex;
    align-items: flex-end;
`;

export const Currency = styled.div`
    margin-right: 4px;
    font-size: 18px;
    font-weight: 500;
    align-self: start;
    color: ${colorConstants.black};
`;

export const Amount = styled.div`
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    color: ${colorConstants.black};
`;

export const PricingBillingLabel = styled.div`
    margin-left: 4px;
    font-size: 16px;
    font-weight: 400;
    color: ${colorConstants.mediumGrey};
`;

export const Overview = styled.div`
    min-height: 90px;
    margin-bottom: 24px;

    font-size: 14px;
    font-weight: 500;
    color: ${colorConstants.black};
    text-align: center;

    @media ${deviceConstants.tablet} {
        margin-bottom: 32px;
    }
`;

export const UpgradeButton = styled(Button)`
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;

    @media ${deviceConstants.tablet} {
        padding-left: 4px;
        padding-right: 4px;
    }
`;

export const ActivePlan = styled.div`
    width: 100%;
    padding: 12px 56px;
    border: 1px solid ${colorConstants.softGrey};
    border-radius: 2px;
    
    font-size: 14px;
    font-weight: 600;
    color: ${colorConstants.softGrey};
    text-align: center;
    text-transform: uppercase;

    @media ${deviceConstants.tablet} {
        padding-left: 4px;
        padding-right: 4px;
    }
`;

export const FeaturesSection = styled.div`
    width: 100%;
    margin-top: 24px;
`;

export const FeaturesTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    :hover {
        cursor: pointer;
    }

    @media ${deviceConstants.tablet} {
        :hover {
            cursor: initial;
        }

        .icon {
            display: none;
        }
    }
`;

export const FeaturesTitleText = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${colorConstants.black};
`;

export const FeaturesTitleIconDown = styled(ChevronDown)`
    font-size: 14px;
    color: ${colorConstants.black};
`;

export const FeaturesTitleIconUp = styled(ChevronUp)`
    font-size: 14px;
    color: ${colorConstants.black};
`;

export const FeaturesList = styled.div`
    margin-top: 8px;

    & > * {
        margin-bottom: 12px;
        display: ${({ show }) => show ? 'flex' : 'none'} !important;

        @media ${deviceConstants.tablet} {
            display: flex !important;
        }
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;