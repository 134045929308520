import styled from 'styled-components';
import FormCheck from 'react-bootstrap/FormCheck';

import { colorConstants } from "../../../constants";


export const Container = styled.div`
    width: 100%;
    padding: 4px 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Header = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: ${colorConstants.black};
`;

export const SubHeader = styled.div`
    margin-left: 16px;
    font-weight: 500;
    font-size: 12px;
    color: ${colorConstants.mediumGrey};
`;

export const ControlsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const Notification = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: ${colorConstants.primaryDark};

    margin-right: 16px;

    ${({ hover }) => hover && `
        :hover {
            color: ${colorConstants.primary};
            cursor: pointer;
        }
    `};
`;

export const StyledFormCheck = styled(FormCheck)`

    input:hover {
        cursor: pointer;
    }
    
    input:checked {
        background: ${colorConstants.primaryDark};
        border-color: ${colorConstants.primaryDark};
        background-repeat: no-repeat;
    }

    input:focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }
`;