import { subscriptionReducerConstants } from "../constants";
import { subscriptionService } from "../services";
import { alertActions } from './';


export const subscriptionActions = {
    getPlans,
    checkoutConfig,
    getByUserId,
    create,
    update,
    cancel,
    clear
};


function getPlans(query) {
    return dispatch => {
        dispatch(request());

        subscriptionService.getPlans(query)
                        .then(
                            plans => dispatch(success(plans)),
                            error => dispatch(failure(error.toString()))
                        );
    };

    function request() { return { type: subscriptionReducerConstants.GET_PLANS_REQUEST } }
    function success(plans) { return { type: subscriptionReducerConstants.GET_PLANS_SUCCESS, plans } }
    function failure(error) { return { type: subscriptionReducerConstants.GET_PLANS_FAILURE, error } }
}

function checkoutConfig(query) {
    return dispatch => {
        dispatch(request());

        subscriptionService.checkoutConfig(query)
                        .then(
                            config => dispatch(success(config)),
                            error => dispatch(failure(error.toString()))
                        );
    };

    function request() { return { type: subscriptionReducerConstants.CHECKOUT_CONFIG_REQUEST } }
    function success(config) { return { type: subscriptionReducerConstants.CHECKOUT_CONFIG_SUCCESS, config } }
    function failure(error) { return { type: subscriptionReducerConstants.CHECKOUT_CONFIG_FAILURE, error } }
}

function getByUserId(userId, query) {
    return dispatch => {
        dispatch(request());

        subscriptionService.getByUserId(userId, query)
                        .then(
                            subscription => dispatch(success(subscription)),
                            error => dispatch(failure(error.toString()))
                        );
    };

    function request() { return { type: subscriptionReducerConstants.GETBY_USERID_REQUEST } }
    function success(subscription) { return { type: subscriptionReducerConstants.GETBY_USERID_SUCCESS, subscription } }
    function failure(error) { return { type: subscriptionReducerConstants.GETBY_USERID_FAILURE, error } }
}

function create(data) {
    return async dispatch => {
        dispatch(request());

        await subscriptionService.create(data)
                                .then(
                                    subscription => {
                                        dispatch(success(subscription));
                                        dispatch(alertActions.success('Subscription created successfully'));
                                    },
                                    error => {
                                        dispatch(failure(error.toString()));
                                        dispatch(alertActions.error(error.toString()));
                                    }
                                );
    };

    function request() { return { type: subscriptionReducerConstants.CREATE_REQUEST } }
    function success(subscription) { return { type: subscriptionReducerConstants.CREATE_SUCCESS, subscription } }
    function failure(error) { return { type: subscriptionReducerConstants.CREATE_FAILURE, error } }
}

function update(id, data) {
    return async dispatch => {
        dispatch(request());

        await subscriptionService.update(id, data)
                                .then(
                                    subscription => { 
                                        dispatch(success(subscription));
                                        dispatch(alertActions.success('Update successful'));
                                    },
                                    error => {
                                        dispatch(failure(error.toString()));
                                        dispatch(alertActions.error(error.toString()));
                                    }
                                );
    };

    function request() { return { type: subscriptionReducerConstants.UPDATE_REQUEST } }
    function success(subscription) { return { type: subscriptionReducerConstants.UPDATE_SUCCESS, subscription } }
    function failure(error) { return { type: subscriptionReducerConstants.UPDATE_FAILURE, error } }
}

function cancel(id) {
    return dispatch => {
        dispatch(request());

        subscriptionService.cancel(id)
                    .then(
                        () => dispatch(success()),
                        error => dispatch(failure(error.toString()))
                    );
    };

    function request() { return { type: subscriptionReducerConstants.CANCEL_REQUEST } }
    function success() { return { type: subscriptionReducerConstants.CANCEL_SUCCESS } }
    function failure(error) { return { type: subscriptionReducerConstants.CANCEL_FAILURE, error } }
}

function clear() {
    return { type: subscriptionReducerConstants.CLEAR_ALL };
}