import { getLocalData } from './local-storage';



/**
 * Get the logged in user's username
 * 
 * @returns String with username
 */
export function getLoggedinUsername() {
    let user = getLocalData('user');

    if(user) return user.username;
    return null;
}


/**
 * Prepare the user data object with no nested objects
 * 
 * @param {JSON Object} user The user object with nested objects for social and address
 * @returns The user object with all data in parent object (no nested objects)
 */
 export function prepareUserData(user){
    try {
        // exclude email, creator, basic, premium and platinum from updates
        const { email, creator, basic, premium, platinum, social, pageOptions, ...rest } = user;
        // merge all objects
        return Object.assign(rest, social, pageOptions);
    } catch (e) {
        throw new Error(e);
    }
}


/**
 * Prepare data for update password request
 * 
 * @param {JSON Object} credentials The credentials JSON object that includes current and new password
 * @returns JSON Object with password and newPassword values
 */
 export function prepareUpdatePasswordData(credentials){
    const { email, password, newPassword } = credentials;
    return { email, password, newPassword };
}


/**
 * Method to check if default links view option arrays are equal
 * 
 * @param {Array} a Array with default links view json object options
 * @param {Array} b Array with default links view json object options
 * @returns true if arrays are same
 */
export function defaultLinksViewOptionsEquals(a, b) {
    return Array.isArray(a) && Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val.name === b[index].name && val.label === b[index].label);
}