/**
 * Method to generate default user JSON Object
 * 
 * @returns JSON Object with default user values
 */
 export function defaultUser(){
    return {
        id: "",
        firebaseId: '',
        authProvider: '',
        username: "",
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        confirmedEmail: "",
        displayName: "",
        website: "",
        bio: "",
        profilePhotoUrl: "",
        social: defaultUserSocialMedia(),
        creator: false,
        basic: false,
        premium: false,
        platinum: false,
        pageOptions: defaultPageOptions(),
        pageData: defaultPageData(),
        dashData: defaultDashData(),
        updatedAt: ""
    };
}


/**
 * Method to generate default social media links JSON Object
 * 
 * @returns JSON Object with default social media links values
 */
export function defaultUserSocialMedia(){
    return {
        youtubeProfileUrl: "",
        facebookProfileUrl: "",
        instagramProfileUrl: "",
        twitterProfileUrl: "",
        tiktokProfileUrl: "",
        githubProfileUrl: "",
        twitchProfileUrl: "",
        discordProfileUrl: "",
        patreonProfileUrl: "",
        pinterestProfileUrl: "",
        spotifyProfileUrl: "",
        telegramProfileUrl: "",
        whatsappProfileUrl: "",
        substackProfileUrl: "",
        soundcloudProfileUrl: "",
        snapchatProfileUrl: "",
        etsyProfileUrl: "",
        clubhouseProfileUrl: "",
        cameoProfileUrl: "",
        bandcampProfileUrl: "",
        applepodcastProfileUrl: "",
        applemusicProfileUrl: "",
        appleappstoreProfileUrl: "",
        androidplaystoreProfileUrl: "",
        amazonProfileUrl: "",
    };
}


/**
 * Method to generate default page options JSON Object
 * 
 * @returns JSON Object with default page option values
 */
 export function defaultPageOptions(){
    return {
        defaultLinksView: '',
        showOverviewPage: false,
        showStandardLinks: false,
        showGithubLinks: false,
        showBlogLinks: false,
        showYoutubeLinks: false,
        showYoutubeshortLinks: false,
        showInstagramreelLinks: false,
        showTiktokLinks: false,
        showSpotifyLinks: false,
        showHotelLinks: false,
        showTravelAgencyLinks: false,
        showPhysicalProductLinks: false,
        showDigitalProductLinks: false,
        showNFTLinks: false,
        // customizations
        pageStyle: '',
        pageCoverImage: '',
        pageTheme: '',
        pageThemeDark: false,
        // photo
        pagePhotoStyle: '',
        pagePhotoBorderColor: '',
        // background
        pageBackgroundColor: '',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: '',
        pageButtonStyle: '',
        pageButtonColor: '',
        pageButtonFontColor: '',
        // fonts
        pageFontType: '',
        pageFontColor: '',
        // social icons
        pageSocialIconShape: '',
        pageSocialIconColor: '',
        pageSocialIconLocationTop: true,
        // branding
        pageBrandingBunjeeLogo: true,
    };
}


/**
 * Method to generate default page data JSON Object
 * 
 * @returns JSON Object with default page data values
 */
export function defaultPageData() {
    return {
        metadata: {
            links: {
                simple: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                github: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                blog: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                youtube: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                youtubeshort: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                instagramreel: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                tiktok: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                spotify: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                accommodation: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                experience: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                physicalProduct: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                digitalProduct: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
                nft: {
                    enabled: false,
                    total: 0,
                    totalActive: 0,
                },
            },
            defaultLinksViewOptions: null,
            favourites: {
                total: 0
            },
        }
    };
}


/**
 * Method to generate default dashboard page data JSON Object
 * 
 * @returns JSON Object with default dashboard data values
 */
 export function defaultDashData(){
    return {
        profileComplete: 0,
    };
}


/**
 * Method to generate default photos JSON Object
 * 
 * @returns JSON Object with default Photos values
 */
export function defaultUserPhoto(){
    return {
        uploading: false,
        crop: {
            pendingImage: '',
            pendingImageFilename: '',
            pendingImageFileSize: 0,
            pendingImageDimensions: { height: 0, width: 0 }
        }
    };
}