import styled from 'styled-components';
import FormCheck from 'react-bootstrap/FormCheck';
import { Button } from '../custom/Buttons';
import { colorConstants, deviceConstants, shadowConstants } from '../../constants';


export const Container = styled.div`
  font-size: 12px;
  padding-bottom: 80px;
`;

export const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${colorConstants.black};
  margin-bottom: 16px;
`;

export const SectionSubTitle = styled.div`
  margin-top: 14px;
  font-weight: 500;
  font-size: 14px;
  color: ${colorConstants.black};
`;

export const FeaturesSection = styled.div`
  margin-top: 32px;

  @media ${deviceConstants.tablet} {
    padding: 32px;
    border-radius: 8px;
    box-shadow: ${shadowConstants.adminDesktop};
    background: ${colorConstants.white};
  }
`;

export const PhotoSection = styled.div`
  margin-top: 48px;

  @media ${deviceConstants.tablet} {
    padding: 32px;
    border-radius: 8px;
    box-shadow: ${shadowConstants.adminDesktop};
    background: ${colorConstants.white};
  }
`;

export const PageStylesSection = styled.div`

`;

export const PhotoStyleSection = styled.div`
  margin-top: 48px;
`;

export const BackgroundSection = styled.div`
  margin-top: 48px;
  
  @media ${deviceConstants.tablet} {
    padding: 32px;
    border-radius: 8px;
    box-shadow: ${shadowConstants.adminDesktop};
    background: ${colorConstants.white};
  }
`;

export const ThemesSection = styled.div`

`;

export const CustomBackgroundSection = styled.div`
  margin-top: 48px;
`;

export const FileUploader = styled.input`
  display: none;
`;

export const CustomButtonsSection = styled.div`
  margin-top: 48px;

  @media ${deviceConstants.tablet} {
    padding: 32px;
    border-radius: 8px;
    box-shadow: ${shadowConstants.adminDesktop};
    background: ${colorConstants.white};
  }
`;

export const CustomFontsSection = styled.div`
  margin-top: 48px;

  @media ${deviceConstants.tablet} {
    padding: 32px;
    border-radius: 8px;
    box-shadow: ${shadowConstants.adminDesktop};
    background: ${colorConstants.white};
  }
`;

export const CustomFontsPreviewContainer = styled.div`
  margin-bottom: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CustomFontsPreview = styled.div`
  padding: ${props => !props.dark ? '12px' : '0'};
  border-radius: 8px;
  background: ${props => !props.dark ? colorConstants.verySoftGreyVersion2: 'none'};

  font-family: ${props => props.fontStyles.type || 'inherit'} !important;
  font-size: 56px;
  line-height: normal;
  color: ${props => props.fontStyles.color || colorConstants.black} !important;
`;

export const SocialIconsSection = styled.div`
  margin-top: 48px;

  @media ${deviceConstants.tablet} {
    padding: 32px;
    border-radius: 8px;
    box-shadow: ${shadowConstants.adminDesktop};
    background: ${colorConstants.white};
  }
`;

export const ToggleSection = styled.div`
  margin-top: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ToggleText = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${colorConstants.black};
`;

export const ToggleButton = styled(FormCheck)`

    input:hover {
        cursor: pointer;
    }
    
    input:checked {
        background: ${colorConstants.primaryDark};
        border-color: ${colorConstants.primaryDark};
        background-repeat: no-repeat;
    }

    input:focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }
`;

export const OtherOptionsSection = styled.div`
  margin-top: 48px;
  
  @media ${deviceConstants.tablet} {
    padding: 32px;
    border-radius: 8px;
    box-shadow: ${shadowConstants.adminDesktop};
    background: ${colorConstants.white};
  }
`;

export const BrandingSection = styled.div`

`;

export const ResetDefaultsSection = styled.div`
  margin-top: 48px;
`;

export const ResetDefaultsButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`;

export const Notification = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${colorConstants.primaryDark};

  :hover {
    color: ${colorConstants.primary};
    cursor: pointer;
  }
`;