import styled from "styled-components";

import { CreatorSpotlightItem } from "./CreatorSpotlightItem";
import { deviceConstants } from "../../constants";


const Container = styled.div`
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 32px 0px;

    @media ${deviceConstants.laptop} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 48px 24px;
    }
`;



export const CreatorSpotlight = ({ items, handleClick }) => {
    return (
        <Container>
            {items.map((item, index) => <CreatorSpotlightItem key={index} data={item} handleClick={handleClick} />)}
        </Container>
    );
};