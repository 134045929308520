import React, { useRef, useEffect } from "react";
import { 
    Container,
    HeroSection, HeroContent, HeroHeader, HeroSubheader, HeroButton, HeroLoginSpan, HeroLoginLink, HeroImageWrapper, HeroImage,
    MiddleSection, MiddleContent, MiddleHeader, MiddleSubheader, MiddleArrowWrapper, MiddleRightArrow, MiddleDownArrow, MiddleImage,
    MiddleForm, MiddleFormTitle
 } from './TechiesEarlyAccess.styles';
import { TechiesForm } from './TechiesForm';

import { generateFirebasePhotoLink } from '../../../utils';
import { sitemap, contentTechiesEarlyAccess } from "../../../constants";


export const TechiesEarlyAccess = (props) => {
    const {
        alert,
        handleScrollReset,
        scrollToSection,
        handleScrollToForm,
        handleChange,
        handleSubmit,
        handleCaptcha,
        registering,
        user,
        submitted,
        captcha,
        isEmail,
        isSocialLink,
    } = props;

    // used to scroll page to middle section with early access form
    const middleSectionRef = useRef(null);

    // set banner images
    const heroImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Ftechies%2Fdeveloper-landing-page-hero-2-compressed.png?alt=media');
    const middleSectionImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Ftechies%2Ftechies-middle-section-banner-image.jpg?alt=media');


    useEffect(() => {

        // Scroll to page section
        if(scrollToSection) {
          switch(scrollToSection){
            // scroll to Early Access Form
            case 'early-access-form': {
              if(middleSectionRef.current) {
                middleSectionRef.current.scrollIntoView();
                handleScrollReset();
              }
              break;
            }
    
            default:
              break;
          }
        }
  
        return () => {
          // execute code on component unmount here
        };
      }, [handleScrollReset, scrollToSection]);


    return (
        <Container>
            <HeroSection onSubmit={handleSubmit}>

                <HeroContent>
                    <HeroHeader>{contentTechiesEarlyAccess.hero.header}</HeroHeader>
                    <HeroSubheader>{contentTechiesEarlyAccess.hero.subheader}</HeroSubheader>
                    <HeroButton gradient={true} midCorner={true} onClick={() => handleScrollToForm('Hero Apply Button')}>{contentTechiesEarlyAccess.hero.primaryCta}</HeroButton>
                    <div>
                        <HeroLoginSpan>{contentTechiesEarlyAccess.hero.secondaryCta.part1}</HeroLoginSpan>
                        <HeroLoginLink to={sitemap.auth.login} >{contentTechiesEarlyAccess.hero.secondaryCta.part2}</HeroLoginLink>
                    </div>
                </HeroContent>

                <HeroImageWrapper>
                    <HeroImage src={heroImageUrl} alt="Bunjee for Gamers and Developers Product Image" />
                </HeroImageWrapper>

            </HeroSection>

            <MiddleSection ref={middleSectionRef}>

                <MiddleContent>
                    <MiddleHeader>{contentTechiesEarlyAccess.cta.header}</MiddleHeader>
                    <MiddleSubheader>{contentTechiesEarlyAccess.cta.text}</MiddleSubheader>
                    <MiddleArrowWrapper>
                        <MiddleRightArrow />
                        <MiddleDownArrow />
                    </MiddleArrowWrapper>
                </MiddleContent>

                <MiddleForm>
                    <MiddleFormTitle>{contentTechiesEarlyAccess.form.title}</MiddleFormTitle>

                    <TechiesForm
                        alert={alert}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleCaptcha={handleCaptcha}
                        registering={registering}
                        user={user}
                        submitted={submitted}
                        captcha={captcha}
                        isEmail={isEmail}
                        isSocialLink={isSocialLink}
                    />
                </MiddleForm>

                <MiddleImage src={middleSectionImageUrl} alt="Bunjee for Gamers and Developers Banner"/>

            </MiddleSection>
        </Container>
    );
};