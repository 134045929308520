/**
 * Method to generate default customer JSON Object.
 * 
 * @param {JSON OBJECT} name Initialize customer with name
 * @param {JSON OBJECT} email Initialize customer with email
 * @returns JSON Object with default customer values
 */
 export function defaultCustomer(name, email){
    return {
        name: name || "",
        email: email || "",
        phone: "",
        address: defaultBillingAddress(),
    };
}


/**
 * Method to generate default billing address JSON Object
 * 
 * @returns JSON Object with default billing address values
 */
 export function defaultBillingAddress(){
    return {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
    };
}