import styled from 'styled-components';

import { deviceConstants, colorConstants } from "../../../constants";

/**
 * Create custom button
 * 
 * @param {Boolean} gradient Enable gradient background color
 * @param {String} customBackgroundColor Force background color
 * @param {String} customColor Force text color
 * @param {Boolean} oval Enable oval shaped/very rounded corners
 * @param {Boolean} midCorner Enable 4px radius corners
 * @param {String} customFontSize Force custom font size
 * @param {Boolean} small Enable small size button mode
 * @param {Boolean} medium Enable medium size button mode
 * @returns Custom styled button
 */
export const Button = styled.button`
    font-size: 12px;
    font-size: ${props => props.customFontSize};
    font-weight: 600;

    background: ${colorConstants.primaryDark};
    background: ${props => props.gradient && colorConstants.gradientHorizontal};
    background: ${props => props.customBackgroundColor};
    background: ${props => props.outline && 'none'};

    color: ${colorConstants.white};
    color: ${props => props.customColor};
    color: ${props => props.outline && colorConstants.black};
    
    border: ${props => props.outline ? '1px solid ' + colorConstants.primaryDark : 'none'};
    border-radius: ${props => props.oval ? "1000px" : props.midCorner ? "4px" : "2px"};
    
    ${({ small, medium }) => !small && !medium && `
        padding: 12px 56px;
    `}

    ${({ small }) => small && `
        padding: 4px 56px;
    `}

    ${({ medium }) => medium && `
        padding: 8px 56px;
    `}

    :hover {
        background: ${colorConstants.primaryDark};
        color: ${props => props.outline && colorConstants.white};
    }

    :disabled {
        background: ${colorConstants.softGrey};
        color: ${colorConstants.darkGrey};
    }

    @media ${deviceConstants.tablet} {
        
    }
`;