/**
 * Prepare customer data object
 * 
 * @param {JSON Object} data Updated customer data
 * @returns The new customer data object
 */
 export function prepareCustomerData(data){
    try {
        return {
            name: data.name,
            phone: data.phone,
            addressLine1: data.address.addressLine1,
            addressLine2: data.address.addressLine2,
            city: data.address.city,
            state: data.address.state,
            postalCode: data.address.postalCode,
            country: data.address.country,
        };
    } catch (e) {
        throw new Error(e);
    }
}


/**
 * Method to format customer address
 * 
 * @param {JSON OBJECT} data The customer address data
 * @param {BOOLEAN} stripeFormat Set to true to format address for stripe api
 * @returns JSON Object with formatted customer address
 */
export function prepareCustomerAddress(data, stripeFormat) {
    const { addressLine1, addressLine2, city, state, postalCode, country } = data;

    if(!postalCode) return {};

    let address = { city, state, country };

    // format address for stripe api
    if(stripeFormat) {
        return {
            line1: addressLine1,
            line2: addressLine2,
            postal_code: postalCode,
            ...address,
        };
    }

    return { addressLine1, addressLine2, postalCode, ...address };
}