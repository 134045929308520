import React, { useState, useEffect } from 'react';
import {
    Container,
    Title,
    PriceSection,
    PriceTopLabel,
    Price,
    Currency,
    Amount,
    PricingBillingLabel,
    Overview,
    UpgradeButton,
    ActivePlan,
    FeaturesSection,
    FeaturesTitle, FeaturesTitleText, FeaturesTitleIconDown, FeaturesTitleIconUp,
    FeaturesList,
} from './BillingPlan.styles';
import { Feature } from './Feature';



export const BillingPlan = ({
    handleUpgrade,
    name,
    price,
    priceTopLabel,
    priceDiscount,
    currency,
    overview,
    features,
    isMonthly,
    isActive,
}) => {

    const [planPrice, setPlanPrice] = useState(price || 0);
    const [showFeatures, setShowFeatures] = useState(false);

    // calculate discounted price for yearly billing
    useEffect(() => {
        if(isMonthly) {
            setPlanPrice(price);
        } else {
            setPlanPrice(price - (price * priceDiscount));
        }
    }, [isMonthly, price, priceDiscount]);

    return (
        <Container>
            <Title>{name}</Title>

            <PriceSection>
                <PriceTopLabel>{priceTopLabel}</PriceTopLabel>
                <Price>
                    <Currency>{currency}</Currency>
                    <Amount>{planPrice}</Amount>
                    <PricingBillingLabel>/mo</PricingBillingLabel>
                </Price>
            </PriceSection>

            <Overview>{overview}</Overview>

            {!isActive &&
            <UpgradeButton
                onClick={handleUpgrade ? () => handleUpgrade(name) : null}
                gradient={true}>
                    {'Upgrade to ' + name}
            </UpgradeButton> }

            {isActive && <ActivePlan>Your current plan</ActivePlan>}

            {features && features.length > 0 &&
            <FeaturesSection>
                <FeaturesTitle onClick={() => setShowFeatures(!showFeatures)}>
                    <FeaturesTitleText>Key Features</FeaturesTitleText>
                    {!showFeatures && <FeaturesTitleIconDown className='icon' />}
                    {showFeatures && <FeaturesTitleIconUp className='icon' />}
                </FeaturesTitle>
                
                <FeaturesList show={showFeatures}>
                    {features.map((item, index) => <Feature key={index} text={item} active={true} />)}
                </FeaturesList>
            </FeaturesSection> }
        </Container>
    );
};