/**
 * Prepare the subscription data object
 * 
 * @param {JSON Object} data All subscription data
 * @param {JSON Object} customer All customer data
 * @param {JSON Object} paymentMethod All payment method data
 * @returns The new subscription data object
 */
 export function prepareSubscriptionData(data, customer, paymentMethod){
     let output = {
        userId: data.user.id,
        plan: data.selectedPlan,
        isMonthly: data.isMonthly,
        coupon: data.coupon,
        // billing details
        name: customer.name,
        phone: customer.phone,
        addressLine1: customer.address.addressLine1,
        addressLine2: customer.address.addressLine2,
        city: customer.address.city,
        state: customer.address.state,
        postalCode: customer.address.postalCode,
        country: customer.address.country,
    };

    
    if(paymentMethod.paymentMethodId) {
        // used on subscription update
        output = { ...output, ...paymentMethod };
    } else {
        let pm = {
            // payment info
            paymentType: paymentMethod.paymentType,
            cardHolderName: paymentMethod.holderName,
            cardNumber: paymentMethod.number,
            expMonth: paymentMethod.expMonth,
            expYear: paymentMethod.expYear,
            cardBrand: paymentMethod.brand,
            cardCvc: paymentMethod.cvc,
        };
        output = { ...output, ...pm };
    }
    
    return output;
}


/**
 * Method to check if a user has any premium account type
 * 
 * @param {JSON OBJECT} user The user account types
 * @returns Boolean data type, true if user has premium account
 */
export function checkIsSubscribed(user){
    return user.basic || user.premium || user.platinum;
}


/**
 * Method to get subscription checkout config data values to query the server.
 * 
 * @param {JSON OBJECT} user The user data object from state
 * @param {STRING} plan The selected plan
 * @param {STRING} coupon The selected promotion code
 * @param {Boolean} newCustomer set to true if is new customer
 * @param {JSON OBJECT} customer The customer data object; must have at least name and address.
 * @returns JSON OBJECT with checkout config required data values
 */
export function prepareSubscriptionCheckoutConfigData(user, plan, coupon, newCustomer, customer) {
    if(!user || !user.id || !plan) return {};

    // initialize with mandatory params
    let config = {
        userId: user.id,
        plan
    };

    // add coupon
    if(coupon) {
        config.coupon = coupon;
        config.email = customer.email || user.email;
    }

    // add customer billing details
    if(newCustomer) {
        if(!customer || !customer.name || !customer.address) return {};

        // customer details
        config.name = customer.name;
        config.email = customer.email || user.email;
        // address info
        config.addressLine1 = customer.address.addressLine1;
        config.city = customer.address.city;
        config.postalCode = customer.address.postalCode;
        config.country = customer.address.country;
        // address optional
        if(customer.address.addressLine2) config.addressLine2 = customer.address.addressLine2;
        if(customer.address.state) config.state = customer.address.state;
    }

    return config;
}