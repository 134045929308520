import styled from 'styled-components';
import { Trash, Pencil, Eye, Bookmark } from 'react-bootstrap-icons';
import FormCheck from 'react-bootstrap/FormCheck';
import { colorConstants, deviceConstants, shadowConstants } from "../../constants";


export const Container = styled.div`
    height: 120px;
    display: flex;
    justify-content: flex-start;
    background: ${colorConstants.white};

    margin-bottom: 16px;

    @media ${deviceConstants.tablet} {
        height: 136px;
        padding: 8px;
        border-radius: 2px;
        box-shadow: ${shadowConstants.adminListItems};
    }

    ${({ issearch }) => issearch && `
        padding: 8px 16px 8px 0px !important;
        background: ${colorConstants.secondaryFaded} !important;
        border-radius: 2px !important;
    `}
`;

export const Photo = styled.img`
    width: 120px;
    border-radius: 2px;
    object-fit: cover;

    :hover {
        cursor: pointer;
    }
`;

export const ContentColumn = styled.div`
    width: 100%;
    margin-left: 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Content = styled.div`
    :hover {
        cursor: pointer;
    }
`;

export const Name = styled.h3`
    font-size: 16px;
    font-weight: 700;
    color: ${colorConstants.black};
    margin: 0;

    // width: 180px; // FEATURE: cut trip name to fit screen
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;

    :hover {
        cursor: pointer;
    }
`;

export const Location = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${colorConstants.darkGrey};

    :hover {
        cursor: pointer;
    }
`;

export const AnalyticsSection = styled.div`
    margin-top: 4px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > * {
        margin-right: 8px;
    }

    & > *:last-child {
        margin-right: 0px;
    }

    :hover {
        cursor: pointer;
    }
`;

export const Stat = styled.div`
    color: ${colorConstants.mediumGrey};
    font-size: 10px;
    font-weight: 300;
    line-height: normal;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Views = styled(Eye)`
    margin-left: 4px;
`;

export const Saves = styled(Bookmark)`
    margin-left: 4px;
`;

export const ButtonsSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ButtonSectionLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > * {
        margin-right: 8px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const ButtonSectionRight = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * {
        margin-right: 12px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const EditButton = styled(Pencil)`
    padding: 4px;
    color: ${colorConstants.mediumGrey};

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const DeleteButton = styled(Trash)`
    padding: 4px;
    color: ${colorConstants.mediumGrey};

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const EnableToggle = styled(FormCheck)`

    input:hover {
        cursor: pointer;
    }
    
    input:checked {
        background: ${colorConstants.primaryDark};
        border-color: ${colorConstants.primaryDark};
        background-repeat: no-repeat;
    }

    input:focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }
`;