import React, { useMemo } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import {
    Container, ContentSection, ButtonsSection,
    PhotoSection,
    TopControls,
    TopControlFader,
    TopControlsColumn,
    BackButton,
    ShareButton,
    HeaderSection,
    SectionBody, BodyTitle,
    BodyList, BodyListItem,
    GoogleMapSection, GoogleMapImage, PartnerAddress, GoogleMapsButton,
    NoMealsIcon, CheckInOutIcon,
    SectionSubTitle,
    PartnerSection,
    PartnerDescriptionSection,
    PartnerBookingSection, PartnerBookingSectionSpinner,
    PartnerBookingOptionsSection,
    PartnerBookingRoomRateHeaderSection, RoomRateHeaderSectionText, RoomRateHeaderSectionPrice, RoomRateDiscountOffer, RoomRateHeaderSectionBorder,
    WarningText, OfferExpirySection,
} from './HotelPage.styles';
import { CarouselPhoto } from '../custom/Images';
import { HotelHeader } from './components';
import { DateSelector, NumberPicker } from '../custom/Buttons';
import { CountdownTimer } from '../custom/Time';
import GoogleReviewsList from '../trips/trip/GoogleReviewsList';
import { MealSelector } from '../trips/trip/MealSelector';
import { BedTypeSelector } from '../trips/trip/BedTypeSelector';
import { RoomSelector } from '../trips/trip/RoomSelector';
import { BookingReservationButton } from '../trips/trip/BookingReservationButton';
import { BookingSearchButton } from '../trips/trip/BookingSearchButton';
import { RoomOccupancy } from '../trips/trip/RoomOccupancy';
import { RoomCancellationPolicy } from '../trips/trip/RoomCancellationPolicy';
import { TripFooter } from '../trips/trip/TripFooter';
import { useWindowSize, addCurrencySymbol } from '../../utils';
import { colorConstants } from "../../constants";


export const HotelPage = (props) => {
    const {
        authenticated,
        isOpenInNewTab,
        hotel,
        isSearchExpired,
        handleSearchExpired,
        booking,
        hotelBooking,
        genericError,
        hotelDataLoading,
        occupancyOptionsToggle,
        availabilityCheckRequired, availabilityCheckButtonText,
        handleBackButton,
        handleOpenTerms,
        handleShare,
        handleOpenMap,
        handleDatePickerToggle,
        handleBookingOptions,
        handleAvailabilitySearch,
        handleShowChildrenAges,
        handleReserveBooking
    } = props;

    // detect screen size
    const [width] = useWindowSize();

    // get device type by screen size
    const device = useMemo(() => {
        let output = {
            isMobile: false,
            isTablet: false,
            isLaptop: false,
            isDesktop: false,
        };
        
        // for Mobile
        if(width < 991) {
            output.isMobile = true;
        } else if(width >= 991 && width < 1440) {
            // for tablet
            output.isTablet = true;
        } else if(width >= 1440 && width < 1600) {
            // for laptop
            output.isLaptop = true;
        } else {
            // for laptop
            output.isDesktop = true;
        }
        return output;

    }, [width]);

    const childrenAges = useMemo(() => {
        if(booking.children === 0 || booking.childrenAges.length === 0) return null;
        let output = '';
        if(booking.childrenAges.length === 1) {
            output = `My child is ${booking.childrenAges.toString()} years old.`;
        } else {
            let ages = [...booking.childrenAges];
            let lastAge = ages.pop();
            output = `My children are ${ages.join(', ')} and ${lastAge} years old.`;
        }
        return output;
    }, [booking]);

    const hasGoogleReviews = useMemo(() => {
        if(hotel && hotel.google && hotel.google.reviews && hotel.google.reviews.length > 0) return true;
        return false;
    }, [hotel]);

    const hasPartner = useMemo(() => {
        if(hotel && hotel.partner && hotel.partner.id && hotel.partner.isReady) return true;
        return false;
    }, [hotel]);

    const hasPartnerPhotos = useMemo(() => {
        if(hasPartner && hotel.partner.photos && hotel.partner.photos.length > 0) return true;
        return false;
    }, [hasPartner, hotel]);

    const hasMorePhotos = useMemo(() => {
        if(hasPartnerPhotos && hotel.google && hotel.google.photos && hotel.google.photos.length > 0) return true;
        return false;
    }, [hasPartnerPhotos, hotel]);

    const hasAmenities = useMemo(() => {
        if(hasPartner && hotel.partner.amenities && hotel.partner.amenities.length > 0) return true;
        return false;
    }, [hasPartner, hotel]);

    const hasRooms = useMemo(() => {
        if(hasPartner && hotel.partner.rooms && hotel.partner.rooms.length > 0) return true;
        return false;
    }, [hasPartner, hotel]);

    const hasSelectedRoom = useMemo(() => (hasRooms && booking.selectedRoom && booking.selectedRoom.id), [hasRooms, booking.selectedRoom]);

    const hasSelectedRoomRate = useMemo(() => (hasSelectedRoom && booking.selectedRoomRate && booking.selectedRoomRate.id), [hasSelectedRoom, booking.selectedRoomRate]);

    const hasDiscountedRoomRate = useMemo(() => (hasSelectedRoomRate && booking.selectedRoomRate.price && booking.selectedRoomRate.price.total && booking.selectedRoomRate.price.offer && booking.selectedRoomRate.price.offer.name && booking.selectedRoomRate.price.offer.total), [hasSelectedRoomRate, booking.selectedRoomRate.price]);

    const hasRoomDescription = useMemo(() => (hasSelectedRoom && booking.selectedRoom.hasDescription), [hasSelectedRoom, booking.selectedRoom]);

    const hasRoomRateNotes = useMemo(() => (hasSelectedRoomRate && (booking.selectedRoom.notes || booking.selectedRoomRate.notes)), [hasSelectedRoomRate, booking.selectedRoom, booking.selectedRoomRate]);

    const hasRoomAmenities = useMemo(() => {
        if(hasSelectedRoom && booking.selectedRoom.amenities && booking.selectedRoom.amenities.length > 0) return true;
        return false;
    }, [hasSelectedRoom, booking]);

    const roomStayInfo = useMemo(() => {
        if(!hasSelectedRoom || !booking.selectedRoom.stayInfo) return null;

        return booking.selectedRoom.stayInfo.map((stay, index) => (
            <div key={index} style={{ marginBottom: '16px' }} >
                <BodyTitle marginbot='8px' >{stay.description}</BodyTitle>
                <BodyList normal='true' >{stay.components.map((c, cIndex) => (<BodyListItem key={cIndex} noback='true' bullet='true' >{c.details}</BodyListItem>))}</BodyList>
            </div>
        ));
    }, [hasSelectedRoom, booking.selectedRoom]);

    const mealsListItems = useMemo(() => {
        if(hasSelectedRoomRate && booking.selectedRoomRate.meal) {
            if(booking.selectedRoomRate.meal.components && booking.selectedRoomRate.meal.components.length > 0) return booking.selectedRoomRate.meal.components.map(a => <BodyListItem key={a.type} >{a.formatted}</BodyListItem>);
            else if(booking.selectedRoomRate.meal.type === 'noMeal') return <BodyListItem hideicon='true' key={booking.selectedRoomRate.meal.type} ><NoMealsIcon />Meals not included</BodyListItem>;
        }

        return null;
    }, [hasSelectedRoomRate, booking.selectedRoomRate]);

    const roomRateNotes = useMemo(() => {
        if(!hasRoomRateNotes) return null;
        let output = [];
        if(booking.selectedRoomRate.notes) output.push(booking.selectedRoomRate.notes);
        if(booking.selectedRoom.notes) output.push(booking.selectedRoom.notes);
        if(output && output.length > 1) return (<ol>{output.map((note, index) => <li key={index}>{note}</li>)}</ol>);
        return output;
    }, [hasRoomRateNotes, booking.selectedRoom, booking.selectedRoomRate]);

    const formattedRoomRatePrice = useMemo(() => {
        if(!hasSelectedRoomRate) return null;
        let price = booking.selectedRoomRate.price.totalPerRoom || booking.selectedRoomRate.price.total;
        if(booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice) price = price + booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice; // FEATURE: re-calculate local taxes whem multi-room booking enabled
        return addCurrencySymbol(booking.currency.code, price / 100);
    },[hasSelectedRoomRate, booking.selectedRoomRate, booking.currency]);

    const formattedRoomRatePriceBeforeDiscount = useMemo(() => {
        if(!hasDiscountedRoomRate) return null;
        let price = booking.selectedRoomRate.price.offer.totalPerRoom;
        if(booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice) price = price + booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice;
        return addCurrencySymbol(booking.currency.code, price / 100);
    },[hasDiscountedRoomRate, booking.selectedRoomRate, booking.currency]);

    const formattedTotalReservationPrice = useMemo(() => {
        if(!hasSelectedRoomRate) return null;
        let price = booking.selectedRoomRate.price.totalPerRoom ? booking.selectedRoomRate.price.total : (booking.selectedRoomRate.price.total * booking.rooms);
        if(booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice) price = price + booking.selectedRoomRate.price.taxesAndFeesNotIncludedInPrice;
        return addCurrencySymbol(booking.currency.code, price / 100);
    },[hasSelectedRoomRate, booking.selectedRoomRate, booking.currency, booking.rooms]);

    // const formattedTotalReservationPriceBeforeDiscount = useMemo(() => {
    //     if(!hasDiscountedRoomRate) return null;
    //     return addCurrencySymbol(booking.currency.code, booking.selectedRoomRate.price.offer.total / 100);
    // },[hasDiscountedRoomRate, booking.selectedRoomRate, booking.currency]);

    const showBottomPadding = useMemo(() => ((hasPartner && !hasSelectedRoom) || hasSelectedRoomRate), [hasPartner, hasSelectedRoom, hasSelectedRoomRate]);

    const error = useMemo(() => {
        if(hotelBooking && hotelBooking.config && hotelBooking.config.error) return hotelBooking.config.error;
        return genericError;
    }, [hotelBooking, genericError]);

    const hasNoAvailabilityForSelectedDates = useMemo(() => {
        if(!hasRooms && error) return true;
        if(hasSelectedRoom && (!booking.selectedRoomRate || !booking.selectedRoomRate.id)) return true;
        return false;
    }, [error, hasRooms, hasSelectedRoom, booking.selectedRoomRate]);

    const associateNoticeText = useMemo(() => {
        if(!hotel || !hotel.creator) return '';
        return `This booking is offered by Bunjee and ${hotel.creator.displayName || ('@' + hotel.creator.username)} earns when you make a reservation.`;
    }, [hotel]);

    const pageFooter = useMemo(() => (hasPartner && !hasSelectedRoom) ? <TripFooter handleOpenTerms={handleOpenTerms} /> : null, [hasPartner, hasSelectedRoom, handleOpenTerms]);

    return (
        <Container>
            <ContentSection bottommargin={showBottomPadding ? 'true' : ''} >
                <PhotoSection>
                    <CarouselPhoto height={'325px'} controls={!device.isMobile} src={hasPartnerPhotos ? hotel.partner.photos : hotel.google.photos} lazyLoad={2} name={hotel.partner.slug + '-photos'} />
                    <TopControls>
                        <TopControlsColumn align='flex-start' >
                            { (authenticated || !isOpenInNewTab) && <BackButton onClick={handleBackButton} size={26} /> }
                        </TopControlsColumn>
                        <TopControlsColumn align='flex-end' >
                            <ShareButton onClick={handleShare} />
                        </TopControlsColumn>
                        <TopControlFader />
                    </TopControls>
                </PhotoSection>

                <HeaderSection>
                    <HotelHeader
                        name={hotel.partner.name}
                        type={hotel.partner.type}
                        stars={hotel.partner.stars}
                        city={hotel.partner.address && hotel.partner.address.city}
                        country={hotel.partner.address && hotel.partner.address.country}
                        rating={hotel.google && hotel.google.rating}
                        reviewsCount={hotel.google && hotel.google.userRatingsTotal} />
                </HeaderSection>

                <PartnerSection>
                    <PartnerDescriptionSection>
                        {/* <SectionSubTitle>Description</SectionSubTitle> */}

                        <SectionBody>{hotel.partner.description || hotel.google.description}</SectionBody>

                        { hotel.google &&
                        <SectionBody>
                            <GoogleMapSection>
                                { hotel.google.mapsImageUrl && <GoogleMapImage onClick={() => handleOpenMap()} src={hotel.google.mapsImageUrl} /> }
                                { hotel.google.formattedAddress && <PartnerAddress>{hotel.google.formattedAddress}</PartnerAddress> }
                                <GoogleMapsButton onClick={() => handleOpenMap()} >View in a map&nbsp;&nbsp;&nbsp;{'>'}</GoogleMapsButton>
                            </GoogleMapSection>
                        </SectionBody> }

                        { hasMorePhotos && <SectionSubTitle>More Photos</SectionSubTitle>}

                        { hasMorePhotos &&
                        <CarouselPhoto
                            height={'325px'}
                            controls={!device.isMobile}
                            src={hotel.google.photos}
                            lazyLoad={2}
                            name={hotel.partner.slug + '-more-photos'}
                        />}

                        { hasGoogleReviews && <SectionSubTitle>Google Reviews</SectionSubTitle>}

                        { hasGoogleReviews && <GoogleReviewsList reviews={hotel.google.reviews} /> }
                    </PartnerDescriptionSection>

                    <PartnerBookingSection>
                        { hasAmenities && <SectionSubTitle>Amenities</SectionSubTitle> }
                        { hasAmenities &&
                        <SectionBody>
                            <BodyList>
                                {hotel.partner.amenities.map(a => <BodyListItem key={a.code} paid={a.isPaid} >{a.label}</BodyListItem>)}
                            </BodyList>
                        </SectionBody> }

                        <SectionSubTitle>Policies & Conditions</SectionSubTitle>
                        <SectionBody>
                            <BodyList>
                                {hotel.partner.checkInFrom &&
                                <BodyListItem hideicon='true' ><CheckInOutIcon />Check-in from {hotel.partner.checkInFrom}</BodyListItem>}

                                {hotel.partner.checkOutTo &&
                                <BodyListItem hideicon='true' ><CheckInOutIcon />Check-out until {hotel.partner.checkOutTo}</BodyListItem>}

                                {hotel.partner.policiesInfo &&
                                <BodyListItem>{hotel.partner.policiesInfo}</BodyListItem>}
                            </BodyList>
                        </SectionBody>

                        <SectionSubTitle>Availability</SectionSubTitle>
                        <SectionBody extrapadding='true' >
                            <DateSelector
                                onClick={handleDatePickerToggle}
                                dates={booking.dates} />

                            { occupancyOptionsToggle &&
                            <PartnerBookingOptionsSection>
                                <NumberPicker
                                    id='adults'
                                    title='Adults'
                                    subtitle='Ages 13 or above'
                                    value={booking.adults}
                                    min={1}
                                    max={booking.adultsMax}
                                    onChange={handleBookingOptions} />

                                <NumberPicker
                                    id='children'
                                    title='Children'
                                    subtitle='Ages 2–12'
                                    value={booking.children}
                                    max={booking.childrenMax}
                                    onChange={handleBookingOptions}
                                    footerText={childrenAges}
                                    footerButton={() => handleShowChildrenAges(true)}
                                    footerButtonText='Change' />

                                <NumberPicker
                                    id='infants'
                                    title='Infants'
                                    subtitle='Under 2'
                                    value={booking.infants}
                                    max={5}
                                    onChange={handleBookingOptions} />
                                
                                {/* <NumberPicker // FEATURE: enable for multiroom booking feature
                                    id='rooms'
                                    title='Rooms'
                                    value={booking.rooms}
                                    min={1}
                                    max={booking.roomsMax}
                                    onChange={handleBookingOptions} /> */}
                            </PartnerBookingOptionsSection> }

                            { occupancyOptionsToggle &&
                            <BookingSearchButton
                                label={availabilityCheckButtonText}
                                onClick={handleAvailabilitySearch}
                                highlight={availabilityCheckRequired || !hasRooms} /> }

                            { hasNoAvailabilityForSelectedDates &&
                            <WarningText>This property has no availability on our site for these dates. Try again with different dates.</WarningText> }

                            { hotelDataLoading &&
                            <PartnerBookingSectionSpinner>
                                <Spinner animation="border" />
                            </PartnerBookingSectionSpinner> }
                        </SectionBody>

                        { hasRooms && <SectionSubTitle>Room Info</SectionSubTitle> }

                        { hasRooms &&
                        <SectionBody spacer='true' bottompadding='true'>
                            <MealSelector
                                id='selectedMeal'
                                title='Meal:'
                                options={booking.mealOptions}
                                selected={booking.selectedMeal}
                                onChange={handleBookingOptions} />
                            
                            <BedTypeSelector
                                title='Bed (if available):'
                                titleValue={booking.doubleBedType ? '1 Double Bed' : '2 Single Beds'}
                                doubleBedType={booking.doubleBedType}
                                onChange={handleBookingOptions} />
                                
                            <RoomSelector
                                title='Room'
                                options={booking.roomOptions}
                                onChange={handleBookingOptions} />
                        </SectionBody> }

                        { hasSelectedRoom && booking.selectedRoom.photos &&
                        <CarouselPhoto
                                height={'325px'}
                                controls={!device.isMobile}
                                src={booking.selectedRoom.photos}
                                lazyLoad={2}
                                name={booking.selectedRoom.name + '-room-photos'} /> }
                        
                        { hasSelectedRoom && <SectionSubTitle>{booking.selectedRoom.name}</SectionSubTitle> }

                        { hasSelectedRoomRate &&
                        <SectionBody>
                            <RoomRateHeaderSectionBorder />
                            <PartnerBookingRoomRateHeaderSection>
                                <div>
                                    <RoomRateHeaderSectionText>Price for {booking.dates.totalDays} night{(booking.dates.totalDays > 1) ? 's' : ''}</RoomRateHeaderSectionText>
                                    <RoomRateHeaderSectionText subheader='true'>Includes taxes and charges</RoomRateHeaderSectionText>
                                </div>
                                <RoomRateHeaderSectionPrice>
                                    { hasDiscountedRoomRate &&
                                    <RoomRateDiscountOffer>
                                        {formattedRoomRatePriceBeforeDiscount}
                                    </RoomRateDiscountOffer> }
                                    {formattedRoomRatePrice}
                                </RoomRateHeaderSectionPrice>
                            </PartnerBookingRoomRateHeaderSection>
                            <RoomRateHeaderSectionBorder />
                        </SectionBody> }

                        { hasRoomDescription && <SectionSubTitle>Description</SectionSubTitle> }
                        { hasRoomDescription &&
                        <SectionBody>
                            {booking.selectedRoom.description}
                        </SectionBody> }

                        { hasSelectedRoomRate && <SectionSubTitle>Room Occupancy</SectionSubTitle> }
                        { hasSelectedRoomRate &&
                        <SectionBody>
                            <RoomOccupancy
                                id='occupancy-selector'
                                options={booking.selectedRoom.options.occupancy}
                                adults={booking.adults}
                                children={booking.children}
                                maxRoomCapacity={booking.selectedRoom.maxOccupancy}
                                roomsAvailable={booking.selectedRoomRate.roomsOnSale}
                                onChange={handleBookingOptions} />
                        </SectionBody> }

                        { roomStayInfo &&
                        <SectionBody>
                            {roomStayInfo}
                        </SectionBody> }

                        { hasRoomAmenities && <SectionSubTitle>Room Amenities</SectionSubTitle> }
                        { hasRoomAmenities &&
                        <SectionBody>
                            <BodyList>
                                {booking.selectedRoom.amenities.map(a => <BodyListItem key={a.code} paid={a.isPaid} >{a.label}</BodyListItem>)}
                            </BodyList>
                        </SectionBody> }

                        { hasSelectedRoomRate && <SectionSubTitle>Meals Included</SectionSubTitle> }
                        { hasSelectedRoomRate &&
                        <SectionBody>
                            <BodyList>
                                {mealsListItems}
                            </BodyList>
                        </SectionBody> }

                        { hasSelectedRoomRate && <SectionSubTitle>Cancellation Policy</SectionSubTitle> }
                        { hasSelectedRoomRate &&
                        <SectionBody>
                            <RoomCancellationPolicy
                                id='cancellation-policy-selector'
                                enabled={booking.hasCancellationPoliciesOptions}
                                options={booking.selectedRoom.options.cancellation}
                                selected={booking.selectedRoomRate.cancellationPolicy}
                                onChange={handleBookingOptions}
                                handleOpenTerms={handleOpenTerms} />
                        </SectionBody> }

                        { hasRoomRateNotes && <SectionSubTitle>Important Notes</SectionSubTitle> }
                        { hasRoomRateNotes &&
                        <SectionBody>
                            {roomRateNotes}
                        </SectionBody>}
                    </PartnerBookingSection>
                </PartnerSection>
            </ContentSection>

            { hasSelectedRoomRate &&
            <OfferExpirySection>
                <SectionBody>
                    <CountdownTimer
                        targetDate={booking.selectedRoomRate.createdAt}
                        minutes={booking.selectedRoomRate.ttl}
                        handleExpired={handleSearchExpired}
                        label='Offer expires in '
                        color={colorConstants.secondaryDark}
                        warnOnComplete={true}
                        size={14} />
                </SectionBody>
            </OfferExpirySection> }

            <ButtonsSection>
                { hasSelectedRoomRate &&
                <BookingReservationButton
                    enable={!availabilityCheckRequired && !isSearchExpired}
                    error={error}
                    onClick={handleReserveBooking}
                    amount={formattedTotalReservationPrice}
                    amountText='Includes taxes & charges'
                    noticeText={associateNoticeText}
                    buttonText='RESERVE' /> }
            </ButtonsSection>

            {pageFooter}
        </Container>
    );
};