import styled from 'styled-components';
import { ChevronLeft, Trash, Pencil, ShareFill, Bookmark, BookmarkFill } from 'react-bootstrap-icons';
import { Button } from '../../custom/Buttons';
import { colorConstants, deviceConstants, shadowConstants } from "../../../constants";


export const Container = styled.div`
    min-height: 100vh;
    padding-bottom: 170px;
    position: relative;

    @media ${deviceConstants.tablet} {
        box-shadow: ${shadowConstants.adminDesktop};
        background: ${colorConstants.white};
    }
`;

export const PhotoSection = styled.div`
    position: relative;
`;

export const TopControls = styled.div`
    padding: 16px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TopControlFader = styled.div`
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.7290266448376226) 24%, rgba(0,0,0,0.5469538157059699) 51%, rgba(0,0,0,0.23322832550989148) 71%, rgba(255,255,255,0) 93%);
    opacity: 0.1;
    z-index: 1;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const TopControlsColumn = styled.div`
    display: flex;
    justify-content: ${props => props.align};
    align-items: center;

    & > * {
        margin-right: 24px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const BackButton = styled(ChevronLeft)`
    color: ${colorConstants.white};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const DeleteButton = styled(Trash)`
    color: ${colorConstants.white};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const EditButton = styled(Pencil)`
    color: ${colorConstants.white};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const ShareButton = styled(ShareFill)`
    color: ${colorConstants.white};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const FavorButton = styled(Bookmark)`
    color: ${colorConstants.white};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const UnFavorButton = styled(BookmarkFill)`
    color: ${colorConstants.primaryDark};
    z-index: 2;

    :hover {
        cursor: pointer;
        color: ${colorConstants.white};
    }
`;

export const HeaderSection = styled.div`
    padding: 16px;
    line-height: 1.2;
`;

export const Name = styled.div`
    font-weight: 800;
    font-size: 18px;

    color: ${colorConstants.black};
`;

export const CaptionSection = styled.div`
    padding: 16px 0;

    blockquote {
        border-left: 5px solid ${colorConstants.verySoftGrey};
        color: ${colorConstants.mediumGrey};
        font-family: 'Hoefler Text', 'Georgia', serif;
        font-style: italic;
        margin: 16px 0;
        padding: 10px 20px;
    }

    code {
        background-color: ${colorConstants.verySoftGreyVersion2};
        font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
        font-size: 12px;
        padding: 2px;
    }
`;

export const CreatorProfileSection = styled.div`
    margin-bottom: 8px;
    padding: 0 16px;

    display: flex;
    align-items: center;

    :hover {
        cursor: pointer;
    }
`;

export const CreatorName = styled.div`
    margin-left: 8px;

    font-weight: 500;
    font-size: 14px;

    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

export const Caption = styled.div`
    padding: 0 16px;
    font-weight: 400;
    font-size: 14px;

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: none;
`;

export const ButtonSection = styled.div`
    width: 100%;

    position: absolute;
    bottom: 0;
    // left: 50%;
    // transform: translate(-50%, 0);
`;

export const ButtonLink = styled.a`
    width: 100%;
    text-decoration: none;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    border-radius: 0px;
`;

export const PartnerSection = styled.div`

`;

export const SectionSubTitle = styled.div`
    padding: 16px;
    margin-top: 40px;

    font-weight: 700;
    font-size: 20px;
`;

export const PartnerDescriptionSection = styled.div`
    padding-bottom: 16px;
`;