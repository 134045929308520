import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getLocalData } from '../utils';
import { sitemap } from '../constants';

// The react private route component renders a route component if the user is logged in,
// otherwise it redirects the user to the homepage. The way it checks is by checking if
// there's a user object in the local storage.
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        getLocalData('user')
            ? <Component {...props} />
            : <Redirect to={{ pathname: sitemap.landing.home, state: { from: props.location } }} />
    )} />
)