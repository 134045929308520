import {
    Container,
    Photo,
    ContentColumn,
    Content,
    Name,
    AnalyticsSection,
    Stat,
    Views,
    Saves,
    ButtonsSection,
    ButtonSectionLeft,
    ButtonSectionRight,
    EditButton,
    DeleteButton,
    EnableToggle
} from './ProductsListItem.styles';
import { DragHandle } from "../custom/DragHandle";
import { SectionLabelInput } from '../custom/FormInput/Links/SectionLabelInput';

import { colorConstants } from '../../constants';



const ProductsListItem = ({
    data,
    dragHandleProps,
    handleView,
    handleEdit,
    handleDelete,
    handleEnabled,
    ...rest
}) => {


    /*** SECTION LABEL COMPONENT ***/
    if(data.isSectionLabel) return <SectionLabelInput
                                        data={data}
                                        dragHandleProps={dragHandleProps}
                                        handleLabelChange={handleEdit}
                                        handleDelete={handleDelete} />;

    return (
        <Container {...rest} >
            <Photo onClick={() => handleView(data.id, data.customUrl)} src={data.imageUrls[0]} />

            <ContentColumn>
                <Content onClick={() => handleView(data.id, data.customUrl)}>
                    <Name onClick={() => handleView(data.id, data.customUrl)}>{data.name}</Name>

                    { data.analytics && <AnalyticsSection onClick={() => handleView(data.id, data.customUrl)}>
                        <Stat>{data.analytics.views}<Views size={11} /></Stat>
                        <Stat>{data.analytics.saves}<Saves size={11} /></Stat>
                        <Stat>{data.analytics.earnings}</Stat>
                    </AnalyticsSection> }
                </Content>

                <ButtonsSection>
                    <ButtonSectionLeft>
                        <EditButton size={22} onClick={() => handleEdit(data.id, data.customUrl)} />
                        <DeleteButton size={22} onClick={() => handleDelete(data.id, data.customUrl)} />
                    </ButtonSectionLeft>

                    <ButtonSectionRight>
                        { dragHandleProps && <DragHandle size={24} customcolor={colorConstants.darkGrey} {...dragHandleProps} /> }
                        <EnableToggle type="switch" name={'enable-item-' + data.id} checked={data.enabled} onChange={() => handleEnabled(data.id, !data.enabled)} />
                    </ButtonSectionRight>
                </ButtonsSection>
            </ContentColumn>
        </Container>
    );
};

export default ProductsListItem;