import React, { useMemo, forwardRef } from 'react';
import {
    Container, ContainerFader, FirstColumn, SecondColumn,
    IconLogo, Logo,
    ContentSection, Name, Description, IconsSection, Devicon,
    ControlsSection,
} from './GithubLink.styles';
import { colorConstants } from "../../../constants";
import Icon from '../../../assets/icons/Icon';


export const GithubLink = forwardRef((props, ref) => {
    const { link, handleOpen, handleShare, customizations, overview, ...rest } = props;
    let { buttons, fonts } = customizations;

    // defaults
    if(buttons.color === '') buttons.color = colorConstants.softBlack;
    if(buttons.fontColor === '') buttons.fontColor = colorConstants.white;

    // set button shape
    const buttonShape = buttons.shape === 'OVAL' ? '500px' : buttons.shape === 'ROUNDED' ? '8px' : '0px';

    // set button color
    const buttonColor = buttons.style === 'OUTLINE' ? 'transparent' : buttons.color;
    const buttonColorHover = buttons.style === 'OUTLINE' ? buttons.color : 'transparent';

    // set button font color
    const fontColor = buttons.style === 'OUTLINE' ? buttons.color : buttons.fontColor;
    const fontColorHover = buttons.style === 'OUTLINE' ? buttons.fontColor : buttons.color;

    // set programming language icon
    const languageIcon = useMemo(() => (!link.githubProgrammingLanguage ? '' : 'devicon-' + link.githubProgrammingLanguage + '-plain'), [link.githubProgrammingLanguage]);

    return (
        <Container
            ref={ref}
            overview={overview}
            shape={buttonShape}
            borderColor={buttons.color}
            color={buttonColor}
            colorHover={buttonColorHover}
            fontColor={fontColor}
            fontColorHover={fontColorHover}
            onClick={() => handleOpen(link.url)} {...rest}>

            <ContainerFader className='fader' />

            <FirstColumn>
                { !link.imageUrl && <IconLogo className='project-generative-logo' color={link.iconColor} >{link.iconText}</IconLogo> }
                { link.imageUrl && <Logo className='project-logo' src={link.imageUrl} /> }
            </FirstColumn>

            <SecondColumn className='content-box' fontType={fonts.type} >
                <ContentSection>
                    <Name>{link.label}</Name>
                    <Description>{link.description}</Description>

                    <IconsSection color={fontColor} hovercolor={fontColorHover}>
                        { languageIcon && <Devicon className={languageIcon} /> }
                    </IconsSection>
                </ContentSection>

                <ControlsSection buttonshape={buttons.shape === 'OVAL'}>
                    <Icon className='github-icon' type='social' platform='github' circle={true} size={10} />
                </ControlsSection>
            </SecondColumn>
        </Container>
    );
});