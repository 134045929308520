import { useEffect, useState, useMemo, useRef } from 'react';
import {
    Container,
    ScrollBackButton, ScrollForwardButton,
    MenuItems,
    Button,
    Background,
    Label,
} from './FiltersMenu.styles';
import Icon from '../../../assets/icons/Icon';


export const FiltersMenu = ({ options, hasTheme, noActive, handleFilter, handleReset, ...rest }) => {

    const [isActive, setActive] = useState(-1);

    const [onlyOneOptionIsTrue, setOnlyOneOptionIsTrue] = useState(false);
    
    const [items, setItems] = useState([]);



    /* Observe if first and last filter menu list items are visible or not in the screen */

    // create first and last item refs
    const firstItemRef = useRef(null);
    const lastItemRef = useRef(null);

    // create first and last item intersecting state flags
    const [isFirstItemIntersecting, setFirstItemIntersecting] = useState(true);
    const [isLastItemIntersecting, setLastItemIntersecting] = useState(false);

    // observe first and last filter menu items
    useEffect(() => {
        // create observers
        const options = { threshold: 0.8 };
        const firstItemObserver = new IntersectionObserver(([entry]) => setFirstItemIntersecting(entry.isIntersecting), options);
        const lastItemObserver = new IntersectionObserver(([entry]) => setLastItemIntersecting(entry.isIntersecting), options);

        // assign items to respective observers
        if(firstItemRef.current && lastItemRef.current) {
            firstItemObserver.observe(firstItemRef.current);
            lastItemObserver.observe(lastItemRef.current);
        }

        // remove the observers as soon as the component is unmounted
        return () => {
            firstItemObserver.disconnect();
            lastItemObserver.disconnect();
        };
    }, [items]);
    
    /* Create onScroll function listener */

    // create scroll container ref
    const scrollContainerRef = useRef(null);

    // function to handle button scroll
    function handleScrollButtons(forward){
        if(!scrollContainerRef.current) return;
        const scrollContainer = scrollContainerRef.current;
        if(forward) scrollContainer.scrollLeft += 60;
        else scrollContainer.scrollLeft += -60;
    }


    useEffect(() => {
        // check which filters to show
        let filterItems = [];

        const hasSimple = options.showStandardLinks && (options.force || options.metadata.simple.totalActive > 0);
        if(hasSimple) filterItems.push({ enabled: hasSimple, id: 0, label: 'Links', icon_type: 'link', icon_platform: null });

        const hasAccommodation = options.showHotelLinks && (options.force || options.metadata.accommodation.totalActive > 0);
        if(hasAccommodation) filterItems.push({ enabled: hasAccommodation, id: 1, label: 'Stays', icon_type: 'hotel', icon_platform: null });

        const hasExperience = options.showTravelAgencyLinks && (options.force || options.metadata.experience.totalActive > 0);
        if(hasExperience) filterItems.push({ enabled: hasExperience, id: 2, label: 'Experiences', icon_type: 'hiking', icon_platform: null });

        const hasBlog = options.showBlogLinks && (options.force || options.metadata.blog.totalActive > 0);
        if(hasBlog) filterItems.push({ enabled: hasBlog, id: 3, label: 'Blogs', icon_type: 'open_book', icon_platform: null });

        const hasGithub = options.showGithubLinks && (options.force || options.metadata.github.totalActive > 0);
        if(hasGithub) filterItems.push({ enabled: hasGithub, id: 4, label: 'Github', icon_type: 'social', icon_platform: 'github' });

        const hasProduct = options.showPhysicalProductLinks && (options.force || options.metadata.physicalProduct.totalActive > 0);
        if(hasProduct) filterItems.push({ enabled: hasProduct, id: 5, label: 'Products', icon_type: 'product', icon_platform: null });

        const hasDigitalProduct = options.showDigitalProductLinks && (options.force || options.metadata.digitalProduct.totalActive > 0);
        if(hasDigitalProduct) filterItems.push({ enabled: hasDigitalProduct, id: 6, label: 'eProducts', icon_type: 'digital_file', icon_platform: null });

        const hasNFT = options.showNFTLinks && (options.force || options.metadata.nft.totalActive > 0);
        if(hasNFT) filterItems.push({ enabled: hasNFT, id: 7, label: 'NFTs', icon_type: 'crypto', icon_platform: 'nft' });

        const hasYoutube = options.showYoutubeLinks && (options.force || options.metadata.youtube.totalActive > 0);
        if(hasYoutube) filterItems.push({ enabled: hasYoutube, id: 8, label: 'YouTube', icon_type: 'social', icon_platform: 'youtube' });

        const hasYoutubeshort = options.showYoutubeshortLinks && (options.force || options.metadata.youtubeshort.totalActive > 0);
        if(hasYoutubeshort) filterItems.push({ enabled: hasYoutubeshort, id: 9, label: 'Shorts', icon_type: 'social', icon_platform: 'youtube' });

        const hasInstagramreel = options.showInstagramreelLinks && (options.force || options.metadata.instagramreel.totalActive > 0);
        if(hasInstagramreel) filterItems.push({ enabled: hasInstagramreel, id: 10, label: 'Reels', icon_type: 'social', icon_platform: 'instagram' });

        const hasTiktok = options.showTiktokLinks && (options.force || options.metadata.tiktok.totalActive > 0);
        if(hasTiktok) filterItems.push({ enabled: hasTiktok, id: 11, label: 'TikTok', icon_type: 'social', icon_platform: 'tiktok' });

        const hasSpotify = options.showSpotifyLinks && (options.force || options.metadata.spotify.totalActive > 0);
        if(hasSpotify) filterItems.push({ enabled: hasSpotify, id: 12, label: 'Spotify', icon_type: 'social', icon_platform: 'spotify' });

        // check if only one option is enabled
        const onlyOneOptionIsTrueValue = (hasSimple + hasAccommodation + hasExperience + hasBlog + hasGithub + hasProduct + hasDigitalProduct + hasNFT + hasYoutube + hasYoutubeshort + hasInstagramreel + hasTiktok + hasSpotify) === 1;
        setOnlyOneOptionIsTrue(onlyOneOptionIsTrueValue);

        const hasOverview = options.showOverviewPage && !onlyOneOptionIsTrueValue;
        if(hasOverview) filterItems.unshift({ enabled: hasOverview, id: 99, label: 'Homepage', icon_type: 'house', icon_platform: null });

        setItems(filterItems);

    }, [options]);


    // check button style
    // const isButtonStyleFill = useMemo(() => options.pageButtonStyle === 'FILL', [options]);
    // set customizations
    const fontFamily = useMemo(() => hasTheme ? options.pageFontType : null, [hasTheme, options]);
    // const fontColor = useMemo(() => hasTheme ? (isButtonStyleFill ? options.pageButtonFontColor : options.pageButtonColor) : null, [hasTheme, isButtonStyleFill, options]);
    const fontColor = useMemo(() => hasTheme ? options.pageFontColor : null, [hasTheme, options]);
    // const iconColor = useMemo(() => isButtonStyleFill ? options.pageButtonFontColor : options.pageButtonColor, [isButtonStyleFill, options]);
    const iconColor = useMemo(() => hasTheme ? options.pageFontColor : null, [hasTheme, options]);


    // set default view
    useEffect(() => {
        // do not set active filter
        if(noActive) return;

        // set active filter based on options
        switch (options.defaultLinksView) {
            case 'OVERVIEW': {
                setActive(99);
                return;
            }
            case 'SIMPLE': {
                setActive(0);
                return;
            }
            case 'ACCOMMODATION': {
                setActive(1);
                return;
            }
            case 'EXPERIENCE': {
                setActive(2);
                return;
            }
            case 'BLOG': {
                setActive(3);
                return;
            }
            case 'GITHUB': {
                setActive(4);
                return;
            }
            case 'PHYSICAL': {
                setActive(5);
                return;
            }
            case 'DIGITAL': {
                setActive(6);
                return;
            }
            case 'NFT': {
                setActive(7);
                return;
            }
            case 'YOUTUBE': {
                setActive(8);
                return;
            }
            case 'YOUTUBESHORT': {
                setActive(9);
                return;
            }
            case 'INSTAGRAMREEL': {
                setActive(10);
                return;
            }
            case 'TIKTOK': {
                setActive(11);
                return;
            }
            case 'SPOTIFY': {
                setActive(12);
                return;
            }
            default: {
                setActive(-1);
                return;
            }
        }
    }, [noActive, options.defaultLinksView]);

    function handleClick(option){
        if(handleReset && option === isActive){
            setActive(-1);
            handleReset();
        } else {
            setActive(option);
            handleFilter(option);
        }
    }

    
    // do not render filters
    if(onlyOneOptionIsTrue) return null;


    // add filter menu items
    if(!items || items.length === 0) return null;

    const filters = items.map((item, index) => {
        // set first and last item refs
        const isFirst = index === 0;
        const isLast = index === items.length - 1;
        const ref = isFirst ? firstItemRef : (isLast ? lastItemRef : null);

        return (
        <Button ref={ref} key={item.id} hastheme={hasTheme} active={isActive === item.id ? 1 : 0} onClick={() => handleClick(item.id)} >
            <Background className='fader' />
            <Icon className='icon' type={item.icon_type} platform={item.icon_platform} size={14} customcolor={iconColor} />
            <Label>{item.label}</Label>
        </Button>
        );
    });


    return (
        <Container>
            <ScrollBackButton
                hide={isFirstItemIntersecting ? 'true' : null}
                size={20}
                color={fontColor}
                onClick={() => handleScrollButtons(false)}
            />

            <MenuItems ref={scrollContainerRef} type={fontFamily} color={fontColor} {...rest} >{filters}</MenuItems>

            <ScrollForwardButton
                hide={isLastItemIntersecting ? 'true' : null}
                size={20}
                color={fontColor}
                onClick={() => handleScrollButtons(true)}
            />
        </Container>
    );
};