import React, { useState, useCallback, useMemo } from "react";
import {
    Container,
    CouponSection, CouponToggle, CouponToggleText,
    BillingDetailsSection, BillingDetailsTitle,
    StyledForm, StyledFormGroup, StyledFormLabel, StyledFormControl, CountrySelector, StyledFormText,
    GuestCheckoutRegisterSection, GuestCheckoutRegisterTitle, GuestCheckoutRegisterSectionRow, GuestCheckoutSpan, GuestCheckoutLink,
    BillingAddressSection, BillingAddressTitle,
    AdditionalNotesSection, AdditionalNotesTitle,
    BillingPaymentSection,
    Footer, EncryptedMessage, EncryptedIcon, FooterRow, FooterLink,
} from './BillingForm.styles';
import { contentCheckoutForm } from '../../../constants';
import { GradientInputEditor, FormCheck } from '../FormInput';
import { StripePaymentForm } from './StripePaymentForm';



export const BillingForm = ({
    disableDetailsChange,
    disableCoupons, coupon, handleChangeCoupon, inValidCoupon,
    isGuestCheckout, registerNewAccount, handleRegister, handleLogin, handleOpenTerms,
    name, email, phone, handleChangeBillingDetails,
    address, handleChangeBillingAddress,
    notes, handleChangeNotes,
    paymentMethod, handleChangePaymentMethod, handleChangeSavedPaymentMethod,
    isCardCvcValidationEnabled,
    clientSecret, stripeId, isSetupIntent,
    disableSubmitButton, preButtonSection,
    successMessage, buttonText,
    handleSubmit, onComplete,
}) => {

    // component state vars
    const [submitted, setSubmitted] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [showCoupon, setShowCoupon] = useState(false);

    // validate has address
    const hasAddress = useMemo(() => {
        if(address.addressLine1 || address.addressLine2 || address.city || address.state || address.postalCode || address.country) return true;
        return false;
    }, [address]);

    // submit button method
    const handleSubmitButton = useCallback((type) => {
        setSubmitted(true);
        if(handleSubmit) handleSubmit(type);
    }, [handleSubmit]);

    const handleProcessing = useCallback((value) => {
        setProcessing(value);
    }, [setProcessing]);


    return (
        <Container>
            { !disableCoupons &&
            <CouponSection>
                <CouponToggle>
                    {!showCoupon &&
                    <CouponToggleText
                        onClick={() => setShowCoupon(!showCoupon)}
                    >
                        {contentCheckoutForm.coupon.toggle}
                    </CouponToggleText> }
                </CouponToggle>

                {showCoupon &&
                    <StyledFormGroup>
                        <StyledFormLabel>{contentCheckoutForm.coupon.name}</StyledFormLabel>
                        <StyledFormControl
                            disabled={processing}
                            type="text"
                            placeholder={contentCheckoutForm.coupon.placeholder}
                            name="coupon"
                            value={coupon}
                            onChange={handleChangeCoupon} />
                        { inValidCoupon && coupon && <StyledFormText>{contentCheckoutForm.coupon.error}</StyledFormText> }
                    </StyledFormGroup> }
            </CouponSection> }


            <BillingDetailsSection>
                <BillingDetailsTitle>{contentCheckoutForm.billingDetails.title}</BillingDetailsTitle>

                <StyledForm>
                    <StyledFormGroup>
                        <StyledFormLabel>{contentCheckoutForm.billingDetails.name}</StyledFormLabel>
                        <StyledFormControl
                            disabled={processing || disableDetailsChange}
                            type="text"
                            placeholder={disableDetailsChange ? '' : contentCheckoutForm.billingDetails.placeholders.name}
                            name="name"
                            value={name}
                            onChange={handleChangeBillingDetails} />
                        { submitted && !name && <StyledFormText>{contentCheckoutForm.billingDetails.name + ' is required'}</StyledFormText> }
                    </StyledFormGroup>

                    <StyledFormGroup>
                        <StyledFormLabel>{contentCheckoutForm.billingDetails.email}</StyledFormLabel>
                        <StyledFormControl
                            disabled={processing || !isGuestCheckout}
                            type="email"
                            placeholder={isGuestCheckout ? contentCheckoutForm.billingDetails.placeholders.email : null}
                            name="email"
                            value={email}
                            onChange={isGuestCheckout ? handleChangeBillingDetails : null} />
                        { isGuestCheckout && submitted && !email && <StyledFormText>{contentCheckoutForm.billingDetails.email + ' is required'}</StyledFormText> }
                    </StyledFormGroup>
                </StyledForm>
            </BillingDetailsSection>


            { isGuestCheckout &&
            <GuestCheckoutRegisterSection>
                <GuestCheckoutRegisterTitle>{contentCheckoutForm.guestCheckout.title}</GuestCheckoutRegisterTitle>

                <GuestCheckoutRegisterSectionRow>{contentCheckoutForm.guestCheckout.subtitle1}</GuestCheckoutRegisterSectionRow>

                <GuestCheckoutRegisterSectionRow>
                    <FormCheck
                        type='checkbox'
                        fontSize='11px'
                        checkBoxSize='18px'
                        id='register-new-account'
                        text={contentCheckoutForm.guestCheckout.register}
                        isActive={registerNewAccount}
                        onChange={handleRegister} />
                </GuestCheckoutRegisterSectionRow>

                {/* <GuestCheckoutRegisterSectionRow> // FEATURE: add login button when guest checkout sessions
                    <GuestCheckoutSpan>{contentCheckoutForm.guestCheckout.login.text}</GuestCheckoutSpan>
                    <GuestCheckoutLink onClick={handleLogin}>{contentCheckoutForm.guestCheckout.login.buttonText}</GuestCheckoutLink>
                </GuestCheckoutRegisterSectionRow> */}
                
                <GuestCheckoutRegisterSectionRow>
                    <GuestCheckoutSpan>{contentCheckoutForm.guestCheckout.gdpr.part1}</GuestCheckoutSpan>
                    <GuestCheckoutLink onClick={() => handleOpenTerms('TERMS')}>{contentCheckoutForm.guestCheckout.gdpr.termsLabel}</GuestCheckoutLink>
                    <GuestCheckoutSpan>{contentCheckoutForm.guestCheckout.gdpr.part2}</GuestCheckoutSpan>
                    <GuestCheckoutLink onClick={() => handleOpenTerms('PRIVACY')}>{contentCheckoutForm.guestCheckout.gdpr.privacyLabel}</GuestCheckoutLink>
                </GuestCheckoutRegisterSectionRow>
            </GuestCheckoutRegisterSection> }


            <BillingAddressSection>
                <BillingAddressTitle>{contentCheckoutForm.billingAddress.title}</BillingAddressTitle>

                <StyledForm>
                    <StyledFormGroup>
                        <StyledFormLabel>{contentCheckoutForm.billingAddress.line1}</StyledFormLabel>
                        <StyledFormControl
                            disabled={processing || disableDetailsChange}
                            type="text"
                            name="addressLine1"
                            placeholder={disableDetailsChange ? '' : contentCheckoutForm.billingAddress.placeholders.line1}
                            value={address.addressLine1}
                            onChange={handleChangeBillingAddress} />
                        { hasAddress && !address.addressLine1 && <StyledFormText>{contentCheckoutForm.billingAddress.line1 + ' is required'}</StyledFormText> }
                    </StyledFormGroup>

                    <StyledFormGroup>
                        <StyledFormLabel>{contentCheckoutForm.billingAddress.line2}</StyledFormLabel>
                        <StyledFormControl
                            disabled={processing || disableDetailsChange}
                            type="text"
                            name="addressLine2"
                            placeholder={disableDetailsChange ? '' : contentCheckoutForm.billingAddress.placeholders.line2}
                            value={address.addressLine2}
                            onChange={handleChangeBillingAddress} />
                    </StyledFormGroup>

                    <StyledFormGroup>
                        <StyledFormLabel>{contentCheckoutForm.billingAddress.city}</StyledFormLabel>
                        <StyledFormControl
                            disabled={processing || disableDetailsChange}
                            type="text"
                            name="city"
                            placeholder={disableDetailsChange ? '' : contentCheckoutForm.billingAddress.placeholders.city}
                            value={address.city}
                            onChange={handleChangeBillingAddress} />
                        { hasAddress && !address.city && <StyledFormText>{contentCheckoutForm.billingAddress.city + ' is required'}</StyledFormText> }
                    </StyledFormGroup>

                    <StyledFormGroup>
                        <StyledFormLabel>{contentCheckoutForm.billingAddress.state}</StyledFormLabel>
                        <StyledFormControl
                            disabled={processing || disableDetailsChange}
                            type="text"
                            name="state"
                            placeholder={disableDetailsChange ? '' : contentCheckoutForm.billingAddress.placeholders.state}
                            value={address.state}
                            onChange={handleChangeBillingAddress} />
                    </StyledFormGroup>

                    <StyledFormGroup>
                        <StyledFormLabel>{contentCheckoutForm.billingAddress.postalCode}</StyledFormLabel>
                        <StyledFormControl
                            disabled={processing || disableDetailsChange}
                            type="text"
                            name="postalCode"
                            placeholder={disableDetailsChange ? '' : contentCheckoutForm.billingAddress.placeholders.postalCode}
                            value={address.postalCode}
                            onChange={handleChangeBillingAddress} />
                        { hasAddress && !address.postalCode && <StyledFormText>{contentCheckoutForm.billingAddress.postalCode + ' is required'}</StyledFormText> }
                    </StyledFormGroup>

                    <StyledFormGroup>
                        <StyledFormLabel>{contentCheckoutForm.billingAddress.country}</StyledFormLabel>
                        <CountrySelector
                            disabled={processing || disableDetailsChange}
                            name="country"
                            placeholder={disableDetailsChange ? '' : contentCheckoutForm.billingAddress.placeholders.country}
                            countryCode={address.country}
                            output='code'
                            box='true'
                            onSelect={(value) => handleChangeBillingAddress({ target: { name: 'country', value } })} />
                        { hasAddress && !address.country && <StyledFormText>{contentCheckoutForm.billingAddress.country + ' is required'}</StyledFormText> }
                    </StyledFormGroup>
                </StyledForm>
            </BillingAddressSection>

            { handleChangeNotes &&
            <AdditionalNotesSection>
                <AdditionalNotesTitle>{contentCheckoutForm.additionalNotes.title}</AdditionalNotesTitle>
                <GradientInputEditor disabled={processing} as="textarea" rows={3} cols={2000} name="additional-notes" maxLength="500" value={notes} placeholder={contentCheckoutForm.additionalNotes.placeholder} onChange={handleChangeNotes} />
            </AdditionalNotesSection> }

            <BillingPaymentSection>
                <StripePaymentForm
                    disableDetailsChange={disableDetailsChange}
                    clientSecret={clientSecret}
                    stripeId={stripeId}
                    isSetupIntent={isSetupIntent}
                    holderName={paymentMethod.holderName}
                    email={email}
                    phone={phone}
                    address={address}
                    paymentMethod={paymentMethod}
                    handleChange={handleChangePaymentMethod}
                    handleChangeSavedPaymentMethod={handleChangeSavedPaymentMethod}
                    isCardCvcValidationEnabled={isCardCvcValidationEnabled}
                    preButtonSection={preButtonSection}
                    buttonText={buttonText}
                    disableSubmitButton={inValidCoupon || disableSubmitButton}
                    successMessage={successMessage}
                    handleSubmit={handleSubmitButton}
                    handleProcessing={handleProcessing}
                    onComplete={onComplete}
                />
            </BillingPaymentSection>

            <Footer>
                <EncryptedMessage>
                    <EncryptedIcon />
                    {contentCheckoutForm.footer.encryption}
                </EncryptedMessage>
                <FooterRow>{contentCheckoutForm.footer.terms.part1}<FooterLink onClick={() => handleOpenTerms('TERMS')}>{contentCheckoutForm.footer.terms.label}</FooterLink>{contentCheckoutForm.footer.terms.part2}<FooterLink onClick={() => handleOpenTerms('TERMS_CUSTOMERS')}>{contentCheckoutForm.footer.terms.labelCustomer}</FooterLink>{contentCheckoutForm.footer.terms.part3}</FooterRow>
                <FooterRow><FooterLink onClick={() => handleOpenTerms('PRIVACY')}>{contentCheckoutForm.footer.privacy}</FooterLink></FooterRow>
            </Footer>
        </Container>
    );
};