import { creatorPayoutReducerConstants } from "../constants";
import { creatorPayoutService } from "../services";


export const creatorPayoutActions = {
    getEarningsStatsById,
    payoutRequest,
    clear,
};


function getEarningsStatsById(creatorId) {
    return dispatch => {
        dispatch(request());

        creatorPayoutService.getEarningsStatsById(creatorId)
            .then(
                stats => dispatch(success(stats)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: creatorPayoutReducerConstants.GET_EARNINGS_STATS_BYID_REQUEST } }
    function success(stats) { return { type: creatorPayoutReducerConstants.GET_EARNINGS_STATS_BYID_SUCCESS, stats } }
    function failure(error) { return { type: creatorPayoutReducerConstants.GET_EARNINGS_STATS_BYID_FAILURE, error } }
}

function payoutRequest(creatorId) {
    return dispatch => {
        dispatch(request());

        creatorPayoutService.payoutRequest(creatorId)
            .then(
                stats => dispatch(success(stats)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: creatorPayoutReducerConstants.GET_EARNINGS_STATS_BYID_REQUEST } }
    function success(stats) { return { type: creatorPayoutReducerConstants.GET_EARNINGS_STATS_BYID_SUCCESS, stats } }
    function failure(error) { return { type: creatorPayoutReducerConstants.GET_EARNINGS_STATS_BYID_FAILURE, error } }
}

function clear() {
    return { type: creatorPayoutReducerConstants.CLEAR_ALL };
}