import { useMemo } from 'react';
import {
    Container,
    ContentColumn,
    Content,
    Title,
    Text,
    ButtonsSection,
    ButtonSectionLeft,
    ButtonSectionRight,
    EditButton,
    DeleteButton,
} from './SearchListItem.styles';
import { DragHandle } from "../custom/DragHandle";

import { colorConstants } from '../../constants';



const SearchListItem = ({
    data,
    dragHandleProps,
    handleEdit,
    handleDelete,
    ...rest
}) => {

    let isStays = useMemo(() => data.type === 'ACCOMMODATION' ? true : false, [data]);

    return (
        <Container {...rest} issearch={true} className="trip-search-component" >
            <ContentColumn>
                <Content>
                    <Title>{isStays ? 'Stays' : 'Experiences'} Search Link</Title>

                    <Text>
                        Your page visitors can now easily search and book {isStays ? 'stays' : 'experiences'} at their desired destinations and dates. You earn from all completed bookings.
                    </Text>
                </Content>

                <ButtonsSection>
                    <ButtonSectionLeft>
                        <DeleteButton size={22} onClick={() => handleDelete(data.id, data.customUrl)} />
                        <EditButton size={22} onClick={() => handleEdit(data.id, data.customUrl)} />
                    </ButtonSectionLeft>

                    <ButtonSectionRight>
                        { dragHandleProps && <DragHandle size={24} customcolor={colorConstants.darkGrey} {...dragHandleProps} /> }
                    </ButtonSectionRight>
                </ButtonsSection>
            </ContentColumn>
        </Container>
    );
};

export default SearchListItem;