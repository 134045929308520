import React from "react";
import styled from 'styled-components';
import FavouriteTripsListItem from './FavouriteTripsListItem';
import FavouriteProductsListItem from './FavouriteProductsListItem';
import FavouriteNFTListItem from './FavouriteNFTListItem';
import { Divider } from '../custom/Divider';
import { deviceConstants } from "../../constants";


const List = styled.div`
    margin-top: 32px;
    padding-bottom: 80px;
`;

const StyledDivider = styled(Divider)`
    @media ${deviceConstants.tablet} {
        display: none;
    }
`;


const FavouritesList = (props) => {
    const { trips, handleViewTrip, nfts, handleViewNFT, products, handleViewProduct } = props;

    const hasTrips = trips && trips.length > 0;
    const hasNFTs = nfts && nfts.length > 0;
    const hasProducts = products && products.length > 0;

    return (
        <List>
            {hasTrips && trips.map((item, index) => {
                return (
                    <div key={index}>
                        <FavouriteTripsListItem trip={item} handleView={handleViewTrip} />
                        {index + 1 < trips.length && <StyledDivider style={{marginBottom: '16px'}} />}
                    </div>
                );
            })}

            {hasProducts && products.map((item, index) => {
                return (
                    <div key={index}>
                        <FavouriteProductsListItem data={item} handleView={handleViewProduct} />
                        {index + 1 < products.length && <StyledDivider style={{marginBottom: '16px'}} />}
                    </div>
                );
            })}

            {hasNFTs && nfts.map((item, index) => {
                return (
                    <div key={index}>
                        <FavouriteNFTListItem data={item} handleView={handleViewNFT} />
                        {index + 1 < nfts.length && <StyledDivider style={{marginBottom: '16px'}} />}
                    </div>
                );
            })}
        </List>
    );
};

export default FavouritesList;