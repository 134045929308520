import { publicOverviewReducerConstants } from '../constants';

export function publicOverview(state = { data: [] }, action) {

  switch (action.type) {

    case publicOverviewReducerConstants.GET_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicOverviewReducerConstants.GET_SUCCESS:
        return {
            data: action.data
        };

    case publicOverviewReducerConstants.GET_FAILURE:
        return {
            data: [],
            error: action.error
        };

    
    case publicOverviewReducerConstants.RESET_ALL: {
        return { data: [] }
    }

    default:
        return state
  }
}