import { Component } from "react";
import { connect } from 'react-redux';

import { authActions } from '../../../actions';
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { sitemap } from '../../../constants';

const signinWithPopup = true; // select social login method


class SocialAuthContainer extends Component {

    componentDidMount(){
        if(this.props.location.state && (this.props.location.state.from === sitemap.auth.register || this.props.location.state.from === sitemap.auth.login) && this.props.location.state.data) {
            // get provider name from params
            let { provider } = this.props.location.state.data;
            // get from pathname stored in location prop
            const { from, search } = this.props.location;
            if(signinWithPopup) {
                // login with social (popup)
                this.props.loginWithPopup({ provider, params: { from, search } });
            } else {
                // initiate login with social
                this.props.login({ provider, params: { from } });
            }

        } else if(!signinWithPopup) {
            // check if any social login redirect results pending
            this.props.loadSocialLoginResults(this.props.location);
        }
    }

    render() {
        return (
            <div>
                {this.props.loggingIn && <FullScreenSpinner />}
            </div>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { loggingIn } = state.auth;

    // export state data to props
    return { loggingIn };
}

const actionCreators = {
    loginWithPopup: authActions.loginWithSocialPopup,
    login: authActions.loginWithSocial,
    loadSocialLoginResults: authActions.loadSocialLoginResults,
}

const connectedSocialAuthContainer = connect(mapState, actionCreators)(SocialAuthContainer);
export { connectedSocialAuthContainer as SocialAuthContainer };