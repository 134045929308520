import styled from 'styled-components';
import { CalendarWeek, GeoAlt, Telephone, Envelope, CreditCard } from 'react-bootstrap-icons';

import { colorConstants } from '../../../constants';



export const Container = styled.div`
    width: 100%;

    font-size: 14px;
    font-weight: 500;
    color: ${colorConstants.black};
`;

export const GradientDivider = styled.div`
    width: 100%;
    margin-top: ${({ margintop }) => margintop};
    margin-bottom: ${({ marginbottom }) => marginbottom};
    height: 1px;
    background: ${colorConstants.gradientHorizontal};
`;

export const Section = styled.div`
    width: 100%;
    margin-bottom: 24px;
`;

export const SectionTitle = styled.div`
    margin-bottom: 8px;

    font-size: ${({ sub }) => sub ? '16px' : '18px'};
    font-weight: ${({ sub }) => sub ? '600' : '700'};
    color: ${({ sub }) => sub ? colorConstants.darkGrey : colorConstants.black};
`;

export const CreatorContainer = styled.div`
    margin-bottom: 16px;
    font-size: 12px;

    display: flex;
`;

export const CreatorLink = styled.div`
    color: ${colorConstants.secondary};

    :hover {
        color: ${colorConstants.primary};
        cursor: pointer;
    }
`;

export const StatusSection = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: ${colorConstants.black};

    display: flex;
`;

export const StatusText = styled.div`
    font-weight: 500;
    color: ${colorConstants.mediumGrey};
`;

export const ConfirmationCodeContainer = styled.div`
    padding: 8px 16px;
    border-radius: 4px;
    background: ${colorConstants.verySoftGrey};

    :hover {
        cursor: pointer;
    }
`;

export const ConfirmatonCodeLabel = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${colorConstants.black};
`;

export const ConfirmatonCodeRow = styled.div`
    display: flex;
    align-items: center;
`;

export const ConfirmatonCodeNumber = styled.div`
    margin-right: 8px;
    font-size: 14px;
    font-weight: 600;
    color: ${colorConstants.mediumGrey};
`;

export const CheckInRow = styled.div`
    width: 100%;
    margin-bottom: 10px;

    display: flex;
`;

export const CheckInContent = styled.div`
    width: 100%;
    margin-left: 16px;
    line-height: 2;

    font-weight: 500;
    font-size: 10px;
    color: ${colorConstants.mediumGrey};
`;

export const CheckInContentTop = styled.div`
    width: 100%;
    line-height: 1.1;

    display: flex;

    & > * {
        margin-right: 16px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const CheckInContentTopCol = styled.div`
    width: 100%;
    font-size: 15px;
    color: ${colorConstants.mediumGrey};

    ${({ rowleftalign }) => rowleftalign && `
        width: 50%;
    `}

    ${({ bold }) => bold && `
        font-weight: 600;
        color: ${colorConstants.black};
    `}
`;

export const CheckInIcon = styled(CalendarWeek)`
    font-size: 14px;
    color: ${colorConstants.darkGrey};
`;

export const AddressSection = styled.div`
    width: 100%;
    margin-bottom: 10px;

    display: flex;

    :hover {
        cursor: pointer;
    }
`;

export const AddressContent = styled.div`
    width: 100%;
    margin-left: 16px;
    line-height: 1.1;
    font-size: 15px;
    color: ${colorConstants.mediumGrey};

    overflow-wrap: anywhere;
`;

export const AddressIcon = styled(GeoAlt)`
    font-size: 14px;
    color: ${colorConstants.darkGrey};
`;

export const HotelWebsiteLink = styled.a`
    font-size: 12px;
    color: ${colorConstants.primary};
    text-decoration: underline;

    :hover {
        color: ${colorConstants.secondary};
        cursor: pointer;
    }
`;

export const ContactDetailsRow = styled.div`
    width: 100%;
    margin-bottom: 10px;

    display: flex;

    :hover {
        cursor: pointer;
    }
`;

export const ContactDetailsRowCol = styled.div`
    width: 100%;
    margin-left: 16px;
    line-height: 1.1;
    font-size: 15px;
    color: ${colorConstants.mediumGrey};

    overflow-wrap: anywhere;
`;

export const PhoneIcon = styled(Telephone)`
    font-size: 14px;
    color: ${colorConstants.darkGrey};
`;

export const EmailIcon = styled(Envelope)`
    font-size: 14px;
    color: ${colorConstants.darkGrey};
`;

export const PriceRow = styled.div`
    width: 100%;

    font-size: 16px;
    font-weight: 600;
    line-height: 2;

    display: flex;
    align-items: flex-end;

    & > * {
        margin-right: 16px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const PriceRowCol = styled.div`
    width: 100%;
    color: ${colorConstants.mediumGrey};

    ${({ columnrightalign }) => columnrightalign && `
        flex: 1;
        text-align: end;
        min-width: 30%;
    `}

    ${({ smalltext }) => smalltext && `
        font-size: 12px;
    `}

    ${({ bold }) => bold && `
        font-weight: 600;
        color: ${colorConstants.black};
    `}
`;

export const RoomDetailsRow = styled.li`
    margin-left: 8px;
    color: ${colorConstants.mediumGrey};
`;

export const HolderNameRow = styled.div`
    width: 100%;
    font-size: 15px;
    color: ${colorConstants.mediumGrey};
`;

export const PaidWithRow = styled.div`
    width: 100%;
    margin-bottom: 10px;

    display: flex;
`;

export const PaidWithRowCol = styled.div`
    width: 100%;
    margin-left: 16px;
    line-height: 1.1;
    font-size: 15px;
    color: ${colorConstants.mediumGrey};
`;

export const CreditCardIcon = styled(CreditCard)`
    font-size: 14px;
    color: ${colorConstants.darkGrey};
`;

export const ManagementRequestLink = styled.div`
    margin-bottom: 8px;
    color: ${colorConstants.primary};
    text-decoration: underline;

    :hover {
        cursor: pointer;
        color: ${colorConstants.secondary};
    }

    ${({ disable }) => disable && `
        text-decoration: none;
        color: ${colorConstants.softGrey2};
        
        :hover {
            cursor: default;
            color: ${colorConstants.softGrey2};
        }
    `};
`;

export const RemarksRow = styled.div`
    width: 100%;
    font-size: 9px;
    color: ${colorConstants.mediumGrey};
`;