import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { DashSquare, PlusSquare } from 'react-bootstrap-icons';

import { colorConstants } from "../../../constants";


const FooterWrap = styled.div`
    width: 100%;
`;

const Container = styled.div`
    width: 100%;    
    padding: ${({ custompadding }) => custompadding || '8px 0px'};
    margin: 0px;

    border: none;
    border-radius: ${({ shape }) => shape || '4px'};

    background: ${({ backgroundColor }) => backgroundColor || colorConstants.white};
    color: ${({ fontcolor }) => fontcolor || colorConstants.black};

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Left = styled.div`
    margin-right: 16px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 15px;
`;

const Subtitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: ${({ fontcolor }) => fontcolor || colorConstants.mediumGrey};
    opacity ${({ fontcolor }) => fontcolor ? 0.6 : 1};
`;

const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > * {
        margin-right: 8px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

const Number = styled.div`
    padding: 0 8px;
    width: 38px;

    font-weight: 700;
    font-size: 15px;
    text-align: center;
`;

const Minus = styled(DashSquare)`
    ${({ isdisabled, isdark }) => isdisabled && `
        fill: ${isdark ? colorConstants.mediumGrey : colorConstants.softGrey};
        pointer-events: none;
    `}

    ${({ isdisabled, fontcolor }) => !isdisabled && `
        fill: ${fontcolor || colorConstants.black};
        pointer-events: auto;
    `}

    :hover {
        cursor: pointer;
    }
`;

const Plus = styled(PlusSquare)`
    ${({ isdisabled, isdark }) => isdisabled && `
        fill: ${isdark ? colorConstants.mediumGrey : colorConstants.softGrey};
        pointer-events: none;
    `}

    ${({ isdisabled, fontcolor }) => !isdisabled && `
        fill: ${fontcolor || colorConstants.black};
        pointer-events: auto;
    `}

    :hover {
        cursor: pointer;
    }
`;

const Footer = styled.div`
    padding: ${({ custompadding }) => custompadding || '0px 8px'};
    color: ${({ fontcolor }) => fontcolor || colorConstants.darkGrey};
    font-weight: 400;
    font-size: 13px;
`;

const FooterTextButton = styled.u`
    color: ${({ fontcolor }) => fontcolor || colorConstants.primary};
    :hover {
        cursor: pointer;
        color: ${({ fontColorHover }) => fontColorHover || colorConstants.secondary};
    }
`;



export const NumberPicker = ({ id, title, subtitle, value, min, max, onChange, footerText, footerButton, footerButtonText, padding, footerPadding, shape, backgroundColor, fontcolor, fontColorHover, isdark }) => {

    const [init, setInit] = useState(false);
    const [num, setNum] = useState(min || 0);
    const [disableMinus, setDisableMinus] = useState(true);
    const [disablePlus, setDisablePlus] = useState(false);

    // if value is set then I will use this instead of local state variable 'num'
    const hasValue = useMemo(() => ((value !== null) && (typeof value !== 'undefined')), [value]);

    const enableButtons = useCallback((isPlus) => {
        let newMin = min ? (min + 1) : 1; // add 1 to min as I'm disabling buttons and then I change the num state
        let minus = hasValue ? (value === newMin) : (num === newMin);
        let plus = hasValue ? (value === (max - 1)) : (num === (max - 1)); // subtracting 1 as I'm disabling buttons and then I change the num state
        setDisableMinus(!isPlus ? minus : false);
        setDisablePlus(isPlus ? plus : false);
    }, [hasValue, value, num, min, max]);

    const handleMinus = useCallback(() => {
        enableButtons(false);
        if(hasValue) {
            if(value === min || value === 0) return;
            if(onChange) onChange(id, value - 1);
            return;
        }
        if(num === min || num === 0) return;
        if(onChange) onChange(id, num - 1);
        setNum(prevState => prevState - 1);
    }, [hasValue, value, min, num, id, onChange, enableButtons]);

    const handlePlus = useCallback(() => {
        enableButtons(true);
        if(hasValue) {
            if(value === max) return;
            if(onChange) onChange(id, value + 1);
            return;
        }
        if(num === max) return;
        if(onChange) onChange(id, num + 1);
        setNum(prevState => prevState + 1);
    }, [hasValue, value, max, num, id, onChange, enableButtons]);

    useEffect(() => {
        // load defaults
        if(!init) {
            setInit(true);

            if(value) {
                setDisableMinus(value === (min || 1));
                setDisablePlus(value === max);
            }
        }

    }, [init, value, min, max]);


    const output = (
        <Container shape={shape} custompadding={padding} backgroundColor={backgroundColor} fontcolor={fontcolor} >
            <Left>
                <Title>{title}</Title>
                <Subtitle fontcolor={fontcolor} >{subtitle}</Subtitle>
            </Left>

            <Right>
                <Minus isdisabled={disableMinus ? 'true' : null} onClick={handleMinus} size={18} color={fontcolor || colorConstants.black} fontcolor={fontcolor} isdark={isdark} className='icon-minus' />
                <Number>{hasValue ? value : num}</Number>
                <Plus isdisabled={disablePlus ? 'true' : null} onClick={handlePlus} size={18} color={fontcolor || colorConstants.black} fontcolor={fontcolor} isdark={isdark} className='icon-plus' />
            </Right>
        </Container>
    );


    if(footerText) {
        return (
           <FooterWrap>
                {output}
                <Footer custompadding={footerPadding} fontcolor={fontcolor} >{footerText} {footerButton ? <FooterTextButton fontcolor={fontcolor} fontColorHover={fontColorHover} onClick={footerButton} >{footerButtonText ? footerButtonText : 'Click'}</FooterTextButton> : null}</Footer>
           </FooterWrap> 
        );
    }

    
    return output;
};