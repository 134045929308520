import React, { Component } from "react";
import { connect } from 'react-redux';
import validator from 'validator';

import { userActions, authActions } from '../../../actions';
import { ScreenContainer } from './ChangeEmailContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import ChangeEmail  from "../../../components/auth/email/ChangeEmail";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { TopBar } from '../../../components/custom/TopBar';
import { history, defaultUser } from '../../../utils';
import { topbarConstants, sitemap } from "../../../constants";


class ChangeEmailContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            fromUrl: sitemap.admin.dashboard,
            submitted: false,
            confirmedEmail: '',
            pendingEmailConfirmation: '',
            email: '',
            isEmail: false,
            user: defaultUser()
        };

        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // get user data from db
        if (!this.props.user || !this.props.user.id) this.props.getById(this.props.userId);
        else {
            this.setState({
                ...this.state,
                init: true,
                confirmedEmail: this.props.user.confirmedEmail === 'not-confirmed' ? this.props.user.email : this.props.user.confirmedEmail,
                pendingEmailConfirmation: this.props.user.confirmedEmail !== this.props.user.email ? this.props.user.email : '',
                user: this.props.user
            });
        }
        if(this.props.location.state && this.props.location.state.from) this.setState({ ...this.state, fromUrl: this.props.location.state.from }); // set from URL
    }

    componentDidUpdate(){
        // load user data from server into component state
        if (!this.state.init && this.props.user && this.props.user.id) {
            this.setState({
                ...this.state,
                init: true,
                confirmedEmail: this.props.user.confirmedEmail === 'not-confirmed' ? this.props.user.email : this.props.user.confirmedEmail,
                pendingEmailConfirmation: this.props.user.confirmedEmail !== this.props.user.email ? this.props.user.email : '',
                user: this.props.user
            });
        }
    }

    handleTopBarBackButton() {
        history.push({ pathname: this.state.fromUrl }); // Go back
    }

    handleChange(event) {
        const { name, value } = event.target;

        this.setState({
            ...this.state,
            submitted: false,
            [name]: value, // update input data
        });
    }

    handleSubmit() {
        this.setState({
            submitted: true,
            isEmail: validator.isEmail(this.state.email), // validate email
        }, async () => {
            if (this.state.submitted && this.state.email && this.state.isEmail && this.state.confirmedEmail !== this.state.email){
                // update email
                let updatePromise = new Promise(async (resolve, reject) => {
                                                try {
                                                    // send update user email request
                                                    await this.props.update({ id: this.props.userId, email: this.state.email });
                                                    // successfully updated
                                                    resolve('Email updated');
                                                } catch(err) {
                                                    reject(err);
                                                }
                                            });

                // execute update email promise
                await updatePromise
                        .then(() => this.props.forceLogout())
                        .catch((err) => console.log('EMAIL UPDATE ERROR: ', err));
            }
        });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.emailChange} />

                <TopBar
                    title={topbarConstants.titles.emailChange}
                    backButton={this.handleTopBarBackButton} />

                {this.state.init &&
                <ChangeEmail
                    email={this.state.email}
                    confirmedEmail={this.state.confirmedEmail}
                    pendingEmailConfirmation={this.state.pendingEmailConfirmation}
                    submitted={this.state.submitted}
                    alert={this.props.alert} // show error if not updated
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit} /> }

                {this.props.updating && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user } = state;
    const { updating } = user;

    // export state data to props
    return { userId, alert, user, updating };
}

const actionCreators = {
    update: userActions.update,
    getById: userActions.getById,
    forceLogout: authActions.logout
}

const connectedChangeEmailContainer = connect(mapState, actionCreators)(ChangeEmailContainer);
export { connectedChangeEmailContainer as ChangeEmailContainer };