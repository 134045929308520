// Connect MetaMask
export const connectMetaMask = async () => {
    if (!window.ethereum) {
        window.open('https://metamask.io/download/', '_blank');
        return false;
    };

    try {
        const res = await window.ethereum.request({ method: "eth_requestAccounts" });
        return res[0];
    } catch (error) {
        return false;
    }
}