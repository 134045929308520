import { firebase } from "./firebase";
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage";


// initialize Firebase Storage
export const storage = getStorage(firebase);


/**
 * Get storage reference of a given path/file
 * 
 * @param {String} path The full path to get reference
 * @returns Storage reference
 */
 export const storageRef = (path) => {
    return ref(storage, path);
};

/**
 * Upload file to Firebas Storage
 * 
 * @param {Storage Reference} ref Storage reference to the exact path of the uploaded file
 * @param {Blob} file The bloc file to upload
 * @returns Snapshop of the upload results
 */
export const uploadFile = async (ref, file) => {
    return await uploadBytes(ref, file);
};

/**
 * Get the download url of a newly uploaded file in Firebase Storage
 * 
 * @param {Snapshot Reference} snapshotRef The reference of a snapshot ie. snapshot.ref
 * @returns The Firebase Download URL of the uploaded file
 */
export const getFileDownloadURL = async (snapshotRef) => {
    return await getDownloadURL(snapshotRef);
};

/**
 * Remove token from Firebase Storage Download URL
 * 
 * @param {String} downloadURL The Firebase Download URL
 * @returns The Firebase Download URL without the token
 */
export const removeTokenFromFileDownloadURL = (downloadURL) => {
    return downloadURL.split("&token=")[0];
};

/**
 * Method to delete a specific file from firebase storage
 * 
 * @param {String} pathToFile Path to the file to be deleted
 */
export const deleteFirebaseStorageFile = async (pathToFile) => {
    // create firebase storage reference of file to delete
    const fileForDeletionRef = storageRef(pathToFile);

    // Delete the file
    deleteObject(fileForDeletionRef).then(() => {
        // File deleted successfully
    }).catch((error) => {
        // Uh-oh, an error occurred!
        throw new Error(error);
    });
};

/**
 * Method to delete a specific directory from firebase storage
 * 
 * @param {String} pathToDir Path to the directory to be deleted
 */
export const deleteFirebaseStorageDirectory = async (pathToDir) => {
    // Create a reference under which you want to list all directories and files
    const listRef = ref(storage, pathToDir);

    // Find all the sub-directories and files
    listAll(listRef)
        .then((res) => {
            // recursively call delete directory method for every sub-directory
            res.prefixes.forEach((folderRef) => deleteFirebaseStorageDirectory(folderRef.fullPath));
            // delete all files within given directory
            res.items.forEach((itemRef) => deleteFirebaseStorageFile(itemRef.fullPath));
        }).catch((error) => {
            // Uh-oh, an error occurred!
            throw new Error(error);
        });
};