/**
 * Method to get authorization header with bearer token for API calls
 * 
 * @param {String} Bearer auth token
 * @returns JSON Object with Authorization header
 */
export function authHeader(token) {
    return token ? { 'Authorization': 'Bearer ' + token } : {};
}


/**
 * Method to get header with CSRF token for API calls
 * 
 * @param {String} token String with CSRF Token
 * @returns JSON Object with CSRF token header
 */
export function csrfHeader(token) {
    return token ? { 'CSRF-TOKEN': token } : {};
}