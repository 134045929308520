// General project wide constants
export const otherConstants = {
    projectName: 'Bunjee',
    appVersion: 'Version 0.0.1',
    appBuild: '(web build) [00001]',
    domain: 'bunj.ee',
    subDomains: {
        partners: 'partners.bunj.ee'
    },
    supportEmail: 'support@bunj.ee',
    bookingsEmail: 'bookings@bunj.ee',
    environment: getEnv(),
    isProduction: getEnv() === 'production',
    isDev: getEnv() === 'development',
    captchaKey: getEnv() === 'development' ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" : process.env.REACT_APP_RECAPTCHA_KEY,
    stripePk: getEnv() === 'production' ? process.env.REACT_APP_STRIPE_PK : process.env.REACT_APP_STRIPE_PK_TEST,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_MAPS_JS_API_KEY,
};

// Project base URLs constants
export const urlConstants = {
    baseUrlClient: generateClientUrl(),
    baseUrlServer: generateServerUrl(),
    guestSessionEndpoint: '/guest-session',
};

// Static constants for company information
export const companyConstants = {
    name: {
        full: 'Bunjee Platforms LTD',
        trading: 'Bunjee'
    },
    address: [
        'Flat 1',
        '275 New North Road',
        'London, N1 7AA',
        'United Kingdom'
    ],
};



/* HELPER METHODS */


/**
 * Method to read Node_ENV variable
 * 
 * @returns String of current environment name
 */
function getEnv(){
    return !process.env.NODE_ENV ? 'development' : process.env.NODE_ENV;
}


/**
 * Method to generate client's base url based on current environment
 * 
 * @returns String with base url of the client
 */
 function generateClientUrl() {
    switch(otherConstants.environment) {
        case 'development': {
            if(!process.env.REACT_APP_DEVELOPMENT_PORT || process.env.REACT_APP_DEVELOPMENT_PORT === '80') return 'http://localhost';
            else return 'http://localhost:' + process.env.REACT_APP_DEVELOPMENT_PORT;
        }

        case 'test':
            return 'https://test.' + otherConstants.domain;

        case 'production':
            return 'https://' + otherConstants.domain;
        
        default:
            return 'https://' + otherConstants.domain;
    }
}


/**
 * Method to generate server's base url based on current environment
 * 
 * @returns String with base url of the server
 */
 function generateServerUrl() {
    switch(otherConstants.environment) {
        case 'development':
            return 'http://localhost:4000/v1';

        case 'test':
            return 'https://api.test.' + otherConstants.domain + '/v1';

        case 'production':
            return 'https://api.' + otherConstants.domain + '/v1';
        
        default:
            return 'https://api.' + otherConstants.domain + '/v1';
    }
}