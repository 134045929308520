import { userReducerConstants, authReducerConstants } from "../constants";
import { userService } from "../services";
import { alertActions } from './';

export const userActions = {
    getById,
    update,
    creatorModeStatus,
    // enableCreatorMode,
    delete: _delete,
    clear
};

function getById(id) {
    return dispatch => {
        dispatch(request());

        userService.getById(id)
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userReducerConstants.GETBYID_REQUEST } }
    function success(user) { return { type: userReducerConstants.GETBYID_SUCCESS, user } }
    function failure(error) { return { type: userReducerConstants.GETBYID_FAILURE, error } }
}

function update(user) {
    return dispatch => {
        dispatch(request(user));

        userService.update(user)
            .then(
                user => { 
                    dispatch(success(user));
                    dispatch(alertActions.success('Update successful'));
                },
                error => {
                    dispatch(failure(error.toString(), user.id));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userReducerConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: userReducerConstants.UPDATE_SUCCESS, user } }
    function failure(error, id) { return { type: userReducerConstants.UPDATE_FAILURE, error, id } }
}

function creatorModeStatus(id) {
    return dispatch => {
        dispatch(request());

        userService.creatorModeStatus(id)
            .then(
                status => dispatch(success(status)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userReducerConstants.CREATOR_STATUS_REQUEST } }
    function success(status) { return { type: userReducerConstants.CREATOR_STATUS_SUCCESS, status } }
    function failure(error) { return { type: userReducerConstants.CREATOR_STATUS_FAILURE, error } }
}

// function enableCreatorMode(id, creator) {
//     return dispatch => {
//         dispatch(request(id));

//         userService.enableCreatorMode({ id, creator })
//             .then(
//                 user => { 
//                     dispatch(success(user));
//                     dispatch(alertActions.success('Welcome to our creator community'));
//                     // reload page to show creator page options in settings
//                     window.location.reload();
//                 },
//                 error => {
//                     dispatch(failure(error.toString(), id));
//                     dispatch(alertActions.error(error.toString()));
//                 }
//             );
//     };

//     function request(id) { return { type: userReducerConstants.BECOME_CREATOR_REQUEST, id } }
//     function success(user) { return { type: userReducerConstants.BECOME_CREATOR_SUCCESS, user } }
//     function failure(error, id) { return { type: userReducerConstants.BECOME_CREATOR_FAILURE, error, id } }
// }

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                () => dispatch(success(id)),
                error => dispatch(failure(error.toString()))
            )
            .then(() => dispatch({ type: authReducerConstants.CLEAR_ALL }));
    };

    function request(id) { return { type: userReducerConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userReducerConstants.DELETE_SUCCESS, id } }
    function failure(error) { return { type: userReducerConstants.DELETE_FAILURE, error } }
}

function clear() {
    return { type: userReducerConstants.CLEAR_ALL };
}