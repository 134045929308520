import { combineReducers } from "redux";

import { homepage } from './homepage.reducer';
import { auth } from './auth.reducer';
import { alert } from './alert.reducer';
import { user } from "./user.reducer";
import { publicUsers } from './public.users.reducer';
import { trip } from "./trip.reducer";
import { publicTrips } from './public.trips.reducer';
import { hotel } from "./hotel.reducer";
import { publicHotel } from "./public.hotel.reducer";
import { hotelBooking } from "./hotel_booking.reducer";
import { travelAgency } from './travel_agency.reducer';
import { link } from "./link.reducer";
import { publicLinks } from "./public.links.reducer";
import { customer } from "./customer.reducer";
import { subscription } from "./subscription.reducer";
import { paymentMethod } from "./payment.method.reducer";
import { creatorPayout } from "./creator.payout.reducer";
import { product } from "./product.reducer";
import { publicProducts } from './public.products.reducer';
import { publicOverview } from './public.overview.reducer';
import { crypto } from './crypto.reducer';
import { nft } from './nft.reducer';
import { nftPublic } from './nft.public.reducer';

const rootReducer = combineReducers({
    homepage,
    auth,
    user,
    alert,
    publicUsers,
    trip,
    publicTrips,
    hotel,
    publicHotel,
    hotelBooking,
    travelAgency,
    link,
    publicLinks,
    customer,
    subscription,
    paymentMethod,
    creatorPayout,
    product,
    publicProducts,
    publicOverview,
    crypto,
    nft,
    nftPublic,
});

export default rootReducer;