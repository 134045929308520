import styled from 'styled-components';

import { colorConstants } from "../../../constants";


export const Container = styled.div`
    width: ${props => props.customwidth};
    height: 146px;
    
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .image {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    :hover {
        cursor: pointer;
        background-color: ${colorConstants.primary};
        color: ${colorConstants.white};
    }
`;

export const Image = styled.img`
    width: ${props => props.customwidth};
    height: 90px;
    object-fit: cover;
`;

export const Content = styled.div`
    max-width: ${props => props.customwidth};
    padding: 6px 8px;
`;

export const Name = styled.div`    
    margin-bottom: 6px;

    font-size: 14px;
    font-weight: 600;

    line-height: 1em;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; // to use this we need to set max-width
`;

export const TokenId = styled.div`
    margin-bottom: 4px;

    font-size: 12px;
    font-weight: 500;

    line-height: 1em;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; // to use this we need to set max-width
`;