import React, { useMemo } from "react";
import {
    Container, PageTitle,
    SelectedPlanSection, SelectedPlanTitle, SelectedPlanName, SelectedPlanOverview, SelectedBillingCycleSection,
    GradientText,
} from './SelectedPlanSummary.styles';
import { FormCheck } from '../../custom/FormInput';
import { contentUpgradePage } from '../../../constants';



export const SelectedPlanSummary = ({
    plan, isMonthly, handleChangeMonthly,
    monthlyPrice, yearlyPricePerMonth,
}) => {

     // set yearly plan billing option text
     const yearlyBillingOptionText = useMemo(() => {
         const savings = <GradientText>{`Save ${plan.yearlyBillingDiscountPercentage * 100}%`}</GradientText>;
         return <div>{`${yearlyPricePerMonth} /mo (Paid Yearly, `}{savings}{')'}</div>;
     }, [yearlyPricePerMonth, plan]);


    return (
        <Container>
            <PageTitle>{contentUpgradePage.checkoutPage.title}</PageTitle>

            { plan &&
            <SelectedPlanSection>
                <SelectedPlanTitle>{contentUpgradePage.checkoutPage.selectedPlanTitle}</SelectedPlanTitle>
                <SelectedPlanName>{plan.name} Plan</SelectedPlanName>
                <SelectedPlanOverview>{plan.overview}</SelectedPlanOverview>

                <SelectedBillingCycleSection>
                    <FormCheck
                        fontSize='14px'
                        id='monthly'
                        text={`${monthlyPrice} /mo (Paid Monthly)`}
                        isActive={isMonthly}
                        onChange={handleChangeMonthly} />

                    <FormCheck
                        fontSize='14px'
                        id='yearly'
                        text={yearlyBillingOptionText}
                        isActive={!isMonthly}
                        onChange={handleChangeMonthly} />
                </SelectedBillingCycleSection>
            </SelectedPlanSection> }
        </Container>
    );
};