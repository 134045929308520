import { http, authHeader, handleResponse, handleError } from "../utils";

export const paymentMethodService = {
    getAll,
    update,
};


async function getAll(customerId, query) {
    let url = `/payment-methods/${customerId}`;
    if(query) url = url + `?${query.toString()}`
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function update(id, data) {
    return await http.put(`/payment-methods/${id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}