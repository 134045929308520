import { cryptoService } from "../services";
import { cryptoWalletReducerConstants } from '../constants';
import { alertActions } from './';


export const cryptoActions = {
    connectWallet,
    disconnectWallet,
    getAllWallets,
    getWalletAssets,
    clearWallets,
    clearWalletAssets,
};


function connectWallet(data) {
    return async dispatch => {
        dispatch(request());

        await cryptoService.connectWallet(data)
            .then(
                wallets => {
                    dispatch(success(wallets));
                    dispatch(alertActions.success('Wallet connected'));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: cryptoWalletReducerConstants.WALLET_CONNECT_REQUEST } }
    function success(wallets) { return { type: cryptoWalletReducerConstants.WALLET_CONNECT_SUCCESS, wallets } }
    function failure() { return { type: cryptoWalletReducerConstants.WALLET_CONNECT_FAILURE } }
}

function disconnectWallet(id) {
    return async dispatch => {
        dispatch(request());

        await cryptoService.disconnectWallet(id)
            .then(
                wallets => {
                    dispatch(success(wallets));
                    dispatch(alertActions.success('Wallet disconnected'));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: cryptoWalletReducerConstants.WALLET_DISCONNECT_REQUEST } }
    function success(wallets) { return { type: cryptoWalletReducerConstants.WALLET_DISCONNECT_SUCCESS, wallets } }
    function failure() { return { type: cryptoWalletReducerConstants.WALLET_DISCONNECT_FAILURE } }
}

function getAllWallets(query) {
    return dispatch => {
        dispatch(request());

        cryptoService.getAllWallets(query)
            .then(
                wallets => dispatch(success(wallets)),
                error => dispatch(failure())
            );
    };

    function request() { return { type: cryptoWalletReducerConstants.WALLET_GETALL_REQUEST } }
    function success(wallets) { return { type: cryptoWalletReducerConstants.WALLET_GETALL_SUCCESS, wallets } }
    function failure() { return { type: cryptoWalletReducerConstants.WALLET_GETALL_FAILURE } }
}

function getWalletAssets(id, query) {
    return async dispatch => {
        dispatch(request());

        await cryptoService.getWalletAssets(id, query)
            .then(
                assets => dispatch(success(assets)),
                error => dispatch(failure())
            );
    };

    function request() { return { type: cryptoWalletReducerConstants.WALLET_GET_ASSETS_REQUEST } }
    function success(assets) { return { type: cryptoWalletReducerConstants.WALLET_GET_ASSETS_SUCCESS, assets } }
    function failure() { return { type: cryptoWalletReducerConstants.WALLET_GET_ASSETS_FAILURE } }
}

function clearWallets() {
    return { type: cryptoWalletReducerConstants.WALLET_CLEAR_ALL };
}

function clearWalletAssets() {
    return { type: cryptoWalletReducerConstants.WALLET_ASSETS_CLEAR_ALL };
}