import styled from 'styled-components';
import { Check, X } from 'react-bootstrap-icons';

import { colorConstants } from '../../../constants';



export const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const LeftColumn = styled.div`
    margin-right: 16px;
`;

export const ActiveIcon = styled(Check)`
    float: left;
    font-size: 18px;
    color: ${colorConstants.primaryDark};
`;

export const InactiveIcon = styled(X)`
    float: left;
    font-size: 18px;
    color: ${colorConstants.black};
`;

export const Text = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: ${colorConstants.black};
    text-align: start;
`;