import {
    Container,
    OptionsContainer,
    Option, OptionButton, OptionText,
    FullDetailsBorder, FullDetailsContainer, FullDetailsTextArea, FullDetailsText, FullDetailsButtons, DeleteButton,
} from './SavedPaymentMethod.styles';



export const SavedPaymentMethod = ({
    data,
    handleChange,
    handleDelete,
    fullDetails,
    expDate,
}) => {

    if(!data) return null;

    const savedMethods = data.map((item, index) => {
        switch(item.type) {
            case 'card': {
                if(fullDetails) {
                    return (
                        <FullDetailsBorder
                            key={index}
                            active={item.default}
                            onClick={(item.default || !handleChange) ? null : () => handleChange(item.id)}
                        >
                            <FullDetailsContainer>
                                <FullDetailsTextArea>
                                    <FullDetailsText>****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{item.last4}</FullDetailsText>
                                    <FullDetailsText>Expiry:&nbsp;&nbsp;{item.expMonth + '/' + item.expYear}</FullDetailsText>
                                </FullDetailsTextArea>

                                <FullDetailsButtons>
                                    { handleDelete && <DeleteButton onClick={() => handleDelete(item.id)} /> }
                                </FullDetailsButtons>
                            </FullDetailsContainer>
                        </FullDetailsBorder>
                    );
                } else {
                    return (
                        <Option type='radio' key={index}>
                            <OptionButton name='saved-card' onChange={() => handleChange(item.id)} type='radio' checked={item.default}/>
                            <OptionText>****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{item.last4}</OptionText>
                            {expDate && <OptionText>&nbsp;&nbsp;&nbsp;&nbsp;Exp.&nbsp;{item.expMonth + ' / ' + item.expYear}</OptionText>}
                        </Option>
                    );
                }
            }

            default:
                return null;
        }
    });

    return (
        <Container>
            <OptionsContainer>
                {savedMethods}
            </OptionsContainer>
        </Container>
    );
};