import {
    Container,
    Title,
    Header,
    StyledParagraph as Text,
    StyledLink as Link,
} from './CustomerTermsOfServicePage.styles';
import { sitemap, companyConstants, otherConstants } from '../../../../constants';
import { formatAddressHTML } from '../../../../utils';



export const CustomerTermsOfServicePage = () => {
    return (
        <Container>
            <Title>Customer Terms of Service</Title>


            <Header>1. Acceptance of the Terms of Service</Header>

            <Text>When using our Services either as a registered user or End User (as described in our general Terms of Service <Link to={sitemap.landing.z.policies.terms}>here</Link>) we may offer you travel-related products or services (“Travel Experience”). When you complete the purchase and booking of a Travel Experience (“Booking”) on our Services, whether you pay for it now or later, you accept our general Terms of Service (which you can find out more about <Link to={sitemap.landing.z.policies.terms}>here</Link>), these terms (“Customer Terms of Service”) and any other terms that you’re provided with during the booking process.</Text>


            <Header>2. Other Applicable Terms</Header>

            <Text>Additional rules and restrictions, including cancellation policies of Booking suppliers (“Rules”), which may apply to your Booking are referred to in these Customer Terms of Service. These Rules will be provided to you before completing the Booking, and any Booking will be subject to the Rules as stated on the booking page. It is important to read the Rules carefully. If you make a Booking on behalf of any other person, you are responsible for making them aware of the Rules and informing them that they will also be bound by them. We reserve the right to refuse access to information about our Travel Experience offerings and to Bookings at any time and for any reason, including a breach of these Customer Terms of Service, at our sole discretion.</Text>


            <Header>3. Changes to these Terms of Service</Header>

            <Text>We may revise these Customer Terms of Service from time to time by amending this page on our policies website, and you will be able to see the changes for any new version. You are responsible for reviewing this page each time you make a Booking to acknowledge any updates we have made, as they apply to you in all matters except for any Bookings made before the latest changes (refer to updated date at bottom). The Customer Terms of Service that were in effect when you made the Booking will still be applicable to that Booking.</Text>


            <Header>4. Booking Conditions and Policies</Header>

            <Text>When making a Booking, you agree that 1) you are at least 18 years of age and 2) have the authority to create a binding legal agreement with us and the relevant Booking supplier(s); 3) you will only make Bookings for yourself or on behalf of other persons for whom you have authority to do so (“Other Person”); 4) You will inform the Other Persons about these Customer Terms of Service (as updated by us from time to time) and any other relevant terms that are applicable to the Booking; 5) all information provided by you (including any information provided on behalf of Other Persons) in respect of a Booking is accurate, complete and up to date; and 6) you will comply with our Acceptable Use Policy set out below.</Text>

            <Text><u>Acceptable Use Policy:</u> When accessing our Booking information you agree not to 1) use our Booking information for any commercial purpose; 2) make any speculative, false, or fraudulent Booking or any Booking in anticipation of demand; 3) access, monitor or copy any content or Booking information using any robot, spider, scraper or other automated means or any manual process for any purpose without the express written permission of us and any supplier that owns such content or information; 4) violate the restrictions in any robot exclusion headers on our Booking information or bypass or circumvent other measures employed to prevent or limit access to our Booking information; 5) take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure; 6) deep-link to any portion of our Booking information (including, without limitation, the purchase path for any Travel Experiences) for any purpose without our express written permission; or 7) "frame", "mirror" or otherwise incorporate any part of our Booking information into any other website or application without our prior written authorization.</Text>

            <Text>We or our suppliers may cancel any Booking linked to your name, email address or account, and close associated accounts if there are indications of fraud, abuse or suspicious activity. Engaging in fraudulent activity may result in us and our suppliers taking legal action, and you could be held responsible for monetary losses to us and our suppliers, including litigation costs and damages. If you wish to dispute the cancellation of a Booking, or the freezing or closure of an account, please contact us <a href='#contact'>here</a>.</Text>

            <Text><u>Children:</u> Special conditions for children are agreed upon with each Booking supplier and are not based on any one criteria; therefore, and given that each Booking supplier applies its own special conditions or discounts, you shall enquire about this point when making your Booking.</Text>

            <Text><u>Accommodation services:</u> Such discounts or special conditions must be understood as applicable only when children share a room with 2 adults.</Text>

            <Text><u>COTS (UK) /CRIBS (US):</u> Cots/cribs and its availability is subject to the Travel Experience supplier’s confirmation. In the event that this service is required, please indicate this when making the Booking, as some Travel Experience suppliers have a limited availability of such items. This extra service can be paid by you directly at the Travel Experience supplier, if required.</Text>

            <Text><u>Third Person in Accommodation Services:</u> Almost all accommodation establishments will treat a Booking for a third person as a double room with an extra bed. You shall consult the supplement and/or discount applicable for an extra bed to be occupied by an adult, as this varies depending on the accommodation establishment. There are accommodation establishments with very few available extra beds, so it is imperative that you ensure your availability when making your Booking. Failure to do this may result in non-availability of the extra bed at the accommodation establishment, with no right to claim any service provision or amount whatsoever from us or our Booking suppliers.</Text>


            <Header>5. Booking Payments</Header>

            <Text>By submitting your Booking request, you give us permission to make Bookings on your behalf. When you make payment for a Booking depends on whether you prepay for your Booking (“Prepaid Booking”) or not, which will be notified to you prior to completion of your Booking. Please note that taxes and fees vary between the two payment options. Tax rates and foreign exchange rates could change in the time between Booking and your actual use of the Booking. Coupons may be available but will always be subject to their own specific terms and conditions. If you wish to use a coupon, it can only be applied to Prepaid Bookings.</Text>

            <Text><u>Prepaid Bookings (“Pay Now”):</u> Prepaid Bookings are paid for by you at the time of making the Booking. You authorise us to make the Booking for the total Booking price, which includes the Booking rate, displayed by us or our marketing affiliates and our register creator users (“Affiliate”), plus any additional tax recovery charges, taxes, service fees, any applicable taxes on our Services and any additional Booking fee separately charged by our Affiliates.</Text>

            <Text>If prepayment for your Booking is required or you choose to pay at the time of Booking, the total price will be charged to your credit card by {otherConstants.projectName} and the Booking will be payable through our supplier.</Text>

            <Text><u>Pay Later:</u> If prepayment for your Booking is not required, you can choose to pay at the time you use the Booking. If you select this “pay later” option, the applicable Booking supplier will take payment for the Booking in the local currency at the time you use the Booking.</Text>

            <Text><u>Additional Information:</u> Some bank and card companies charge their account holders a transaction fee when the card issuer and the merchant location (as defined by the card brand, e.g. Visa, MasterCard, American Express) are in different countries. The currency exchange rate, if applicable, and any transaction fee is determined solely by the bank or other agency processing the transaction. These fees may be applied by the card issuer as a charge to the cardholders account. When applied by the card issuer, the fees will be listed separately from the transaction amount on a credit or debit card statement. This means the amount listed on your credit or debit card statement may be a different figure than the figure shown on the billing summary page for a Booking based on our Booking information. If you have any questions about these fees or any exchange rate applied to your booking, please contact your bank. Some Booking suppliers may require you and/or the Other Persons to present a credit card or cash deposit upon check-in to cover additional expenses incurred during your stay.  Such deposit is unrelated to any payment received by us for your Booking.</Text>


            <Header>6. Booking Cancellation and Changes</Header>

            <Text>
                The Rules will set out the applicable cancellation period during which you can cancel or make changes to your Booking (the “Cancellation Period”). In limited cases, some Bookings cannot be changed or cancelled after they are made and this will be indicated in the specific Travel Experience’s cancellation policy.
                <br/>
                If permitted, you may cancel or change your prepaid Booking during the Cancellation Period but you will be charged the applicable cancellation or change fee as set out in the specific Travel Experience’s cancellation policy.
                <br/>
                If you are allowed to cancel or make changes to your Booking but do not do so before the end of the Cancellation Period, you will be liable to pay a cancellation charge equal to applicable nightly or other Travel Experience’s rate(s), taxes or tax recovery charges (as applicable), Service fees and any additional Booking fee separately charged by us and our Affiliates (the “Cancellation Penalty”) whether or not you use the Booking or not.
                <br/>
                If you do not show for the first night of your Booking but plan to check-in for subsequent nights, you must confirm with us and our Affiliates no later than the date of the first night of your reservation. Failure to do so may result in your Booking being cancelled and you being charged the Cancellation Penalty. If you do not show at all at your Booking’s establishment your Booking shall be considered a cancellation and you will be charged the Cancellation Penalty. If you check-out early, the Travel Experience supplier may charge the full amount of the original Booking, in which case reimbursement shall not apply.
            </Text>

            <Text>Throughout the year, some Travel Experiences may change name or trade name, which shall not be construed as a change of the Travel Experience or modification of the Booking.</Text>


            <Header>7. Group Bookings</Header>

            <Text>For Travel Experiences where accommodation Bookings are applicable, please note that we cannot guarantee Bookings for more than 1 rooms with any accommodation supplier for the same accommodation stay dates (“Group Booking”). If you make a Group Booking online, we may cancel such Group Booking and charge you any cancellation fee set out in the Rules. If you paid a non-refundable deposit in respect of your Group Booking, your deposit will be forfeited.</Text>


            <Header>8. Fraudulent Bookings, Packages and Taxes</Header>

            <Text>If any Booking (including any associated payment cards) shows signs or is connected to any suspected fraud, abuse or suspicious activity, we or our Affiliates may cancel any Booking associated with your name or email address.  In addition, we may verify (i.e. preauthorize) any credit card used for payment of a Booking.  If you or the holder of any credit card used for payment of a Booking have conducted any fraudulent activity, we reserve the right to take any necessary legal action and you and/or the card holder may be liable for our losses, including litigation costs and damages.  To contest the cancellation of a Booking, please contact us <a href='#contact'>here</a>.</Text>

            <Text><u>Packages:</u> If your Booking has been provided to you in conjunction with another Travel Experience (e.g. a flight and an accommodation) (a “Package” or a “Linked Travel Arrangement”), then us and our Affiliates who has provided this Package, or the first travel component of the Linked Travel Arrangement, to you, will be responsible for any changes or liabilities regarding that Package or Linked Travel Arrangement.</Text>

            <Text>
                <u>Taxes:</u> You acknowledge that, except as provided below with respect to tax obligations on the amount we retain for our Services, we do not collect taxes for remittance to applicable taxing authorities. The Travel Experience suppliers invoice us for certain charges, including, where appropriate, tax amounts. The Travel Experience suppliers are responsible for remitting the applicable taxes to the applicable taxing jurisdictions. The tax recovery charges which we charge you on Prepaid Bookings represent the estimated taxes (e.g. sales and use, occupancy, room tax, excise tax, value added tax, etc) that we pay to the Travel Experience supplier for taxes due on the rental of the room. Taxability and the appropriate tax rate vary depending on the location. The actual tax amounts paid by us to the Travel Experience suppliers may vary from the tax recovery charge amounts, depending upon the rates, taxability, etc. in effect at the time of your actual use of the Booking.
                <br/>
                You acknowledge that we provide you Services to facilitate the Booking for a consideration. We retain our Service fees as compensation for servicing your Booking. Our Service fees vary based on the amount and type of Booking. Sales, use and/or local Travel Experience occupancy taxes are imposed on the amounts that we charge for our Services (Service fee and/or facilitation fee) in certain jurisdictions. The actual tax amounts on our Services may vary depending on the rates in effect at the time of your actual use of the Booking.
                <br/>
                You acknowledge that authorities in certain countries may impose additional sales, use and/or local Travel Experience occupancy taxes (e.g., a tourist or visitor tax), which have to be paid locally. You are solely responsible for paying such additional taxes.
            </Text>


            <Header>9. International Travel</Header>

            <Text>
                You are responsible for ensuring that you meet any applicable foreign entry requirements and that your travel documents, such as passports and visas (transit, business, tourist, and otherwise), are in order and any other foreign entry requirements are met.
                <br/>
                By offering Bookings in particular international destinations, we do not represent or warrant that travel to such areas is advisable or without risk, and are not liable for damages or losses that may result from travel to such destinations. We recommend you check your country’s foreign travel guidance for further details.
            </Text>

            <Text><u>WARNING – USA RESTRICTIONS ON TRAVEL TO CUBA:</u> No Bookings for Travel Experiences in Cuba shall be facilitated, arranged or made by or through our US Affiliated companies or our US suppliers. You hereby acknowledges that travel to Cuba by citizens and residents of the United States, is subject to the laws of the United States pertaining to the U.S. embargo of Cuba and requires a license by the United States Government. No refunds will be made or liability incurred with respect to any travel arrangements made by citizens or residents of the United States without required licenses.</Text>

            <Text><u>WARNING – OTHER COUNTRIES/TERRITORIES POTENTIALLY SUBJECT TO RESTRICTIONS:</u> All Bookings for locations subject to sanctions by the United States, European Union or other countries must be consistent with applicable restrictions. No refunds will be made or liability incurred with respect to any travel arrangements, or related payment arrangements that are prohibited under applicable law.</Text>


            <Header>10. Disclaimer and Liability</Header>

            <Text>
                We, our Affiliates and our Travel Experience suppliers will make reasonable efforts to update our Booking information to ensure its accuracy. However, our Booking information is provided by the Travel Experience suppliers of our Bookings. As such, 1) our Booking information may be changed, enhanced, amended or deleted at any time, any non-availability or change may occur at any time without notice and without liability to us, our Affiliates or our Travel Experience suppliers; 2) we disclaim all liability for any errors or other inaccuracies relating to our Booking information, (including, without limitation, the pricing of Bookings, photographs of Travel Experiences, lists of Travel Experience amenities and general Travel Experience descriptions); 3) we make no guarantees about the availability of specific Travel Experiences; 4) we make no representations about the suitability of our Booking information for any purpose; 5) Travel Experience ratings displayed in our Booking information are intended as general guidelines only and we, our Affiliates and our Travel Experience suppliers cannot guarantee the accuracy of such ratings; 6) disclaim all warranties and conditions that our Booking information, and Services or any email sent from us are free of viruses or other harmful components and 7) all our Booking information and Services are provided “as is” without warranty of any kind.
                <br/>
                We expressly reserve the right to correct any pricing errors and/or Bookings made under an incorrect price. In such event, if available, we will offer you the opportunity to keep your Booking at the amended price or you may cancel your Booking without penalty.
            </Text>

            <Text>The Travel Experience and other suppliers providing Bookings to us are independent contractors and independent intermediaries and not employees of us.</Text>

            <Text>We and our Travel Experience suppliers are not liable for i) the acts, errors, omissions, representations, warranties, breaches or negligence of any Affiliate or the suppliers of the Bookings; or ii) for any personal injury, death, property damage, or other damages or expenses resulting there from.</Text>

            <Text>We and our Travel Experience suppliers have no liability and will make no refund in the event of any delay, cancellation, overbooking, strike, force majeure or other causes beyond our direct control; and we have no responsibility for any additional expenses, omissions, delays, re-routing or acts of any government or authority.</Text>

            <Text>To the fullest extent permitted by applicable law, in no event shall we or our Travel Experience suppliers be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of, or in any way connected with i) your access to our Booking information; or ii) the delay or inability to access our Booking information (including, but not limited to, any computer viruses, information, software, linked sites, and Bookings); whether based on negligence, contract, tort, strict liability, consumer protection law, or otherwise, and even if we or our Travel Experience suppliers have been advised of the possibility of such damages.</Text>

            <Text>If, despite the limitation above, we or our Travel Experience suppliers are found liable for any loss or damage which arises out of or is in any way connected with any of the occurrences described above, then the liability of us, or our Travel Experience suppliers will in no event exceed, in the aggregate, the greater of i) the Booking fee you paid to us, our Travel Experience suppliers or Affiliate in connection with the relevant Booking; or ii) One Hundred Dollars (US$100.00) or the equivalent in local currency. We do not exclude or limit in any way our liability for anything which cannot be excluded by applicable law. The limitation of liability reflects the allocation of risk between you and us. The limitations specified in this section will survive and apply even if any limited remedy specified in these Customer Terms of Service is found to have failed of its essential purpose. The limitations of liability provided in these Customer Terms of Service inure to the benefit of us and our Travel Experience suppliers and Affiliates.</Text>


            <Header>11. Disputes</Header>

            <Text>We, our Affiliates and our Travel Experience suppliers are committed to customer satisfaction, so if you have a problem or dispute, we will try to resolve your concerns. If we are unsuccessful, you may pursue claims as explained in this section. You agree to give us an opportunity to resolve any disputes or claims against us, our Affiliates or our Travel Experience suppliers relating in any way to our Booking information, Bookings, any dealings with our Customer Support Agents, any Bookings, or any representations made by us (“Claims”) by contacting us through the customer service details provided to you at the time of Booking and within these Customer Terms of Service, and legal action on the issue will not be taken by either party until at least 60 days have been spent attempting to resolve the matter.</Text>


            <Header id='contact'>12. Contacting us</Header>

            <Text>If you have any questions about this Customer Terms of Service, you can contact us:</Text>

            <Text>By email: <a href='mailto:info@bunj.ee'>info@bunj.ee</a></Text>

            <Text>By post:</Text>

            <Text>Support Team<br/>{companyConstants.name.full.toUpperCase()}<br/>{formatAddressHTML(companyConstants.address)}</Text>


            <Text>This document was last updated on February 16, 2023</Text>

        </Container>
    );
};