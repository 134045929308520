import React from 'react';
import { Container, Image, Content, Name, TokenId } from './SelectNFTCard.styles';


export const SelectNFTCard = ({ data, handleSelect, maxWidth }) => {

    return (
        <Container customwidth={maxWidth || '120px'} onClick={() => handleSelect(data)} >
            { data.image && data.image.length > 0 && <Image customwidth={maxWidth || '120px'} className='image' src={data.image} /> }

            <Content customwidth={maxWidth || '120px'} >
                <Name>{data.name}</Name>
                {data.chainTokenId && <TokenId>{`#${data.chainTokenIdShort || data.chainTokenId}`}</TokenId>}
            </Content>
        </Container>
    );
};