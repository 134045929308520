import React, { Component } from "react";
import { connect } from 'react-redux';
import Joyride from 'react-joyride';

import { userActions, linkActions, alertActions  } from '../../actions';
import { ScreenContainer } from './MyLinksContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { Notification } from '../../components/custom/Popup';
import { LinksMenu } from '../../components/custom/Menu';
import { NoLinksFound } from '../../components/custom/EmptyState';
import { LinksList } from "../../components/links";
import { TopBar } from '../../components/custom/TopBar';
import { SideBar } from '../../components/custom/SideBar';
import { FullScreenSpinner } from "../../components/custom/Spinner";
import { Warning } from '../../components/custom/Popup';
import { FiltersMenu } from '../../components/custom/Filter';
import { history, defaultUser, prepareUserData } from '../../utils';
import { topbarConstants, contentLinkPage, colorConstants, sitemap } from "../../constants";


class MyLinksContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // true when loaded user and list data from server
            fromUrl: sitemap.admin.dashboard,
            typingTimeout: 0, // used to know when user stops typing
            sidebar: false,
            sidebarReset: false,
            sidebarPositionOffset: 0,
            sticky: false,
            showLinksMenu: false, // toggle links menu on/off
            user: defaultUser(),
            creating: false,
            deleting: false,
            linkIdToDelete: null,
            deleteWarning: false, // hide/show delete trip warning
            initLinks: false, // used to set links in state
            links: []
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuButton = this.handleMenuButton.bind(this);
        this.handleLinksMenuToggle = this.handleLinksMenuToggle.bind(this);
        this.handleLinksMenuClick = this.handleLinksMenuClick.bind(this);

        this.handleReorderLinks = this.handleReorderLinks.bind(this);
        this.handleAddLink = this.handleAddLink.bind(this);
        this.handleEditLink = this.handleEditLink.bind(this);
        this.handleOpenLink = this.handleOpenLink.bind(this);

        this.handleDeleteLinkRequest = this.handleDeleteLinkRequest.bind(this);
        this.handleDeleteLink = this.handleDeleteLink.bind(this);
        this.handleDeleteLinkCancel = this.handleDeleteLinkCancel.bind(this);

        this.handleOpenEditScreen = this.handleOpenEditScreen.bind(this);

        this.handleFilter = this.handleFilter.bind(this);

        /* App Tour */
        this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        if(!this.props.user || !this.props.user.id) this.props.getById(this.props.userId); // get user data from db
        window.addEventListener('scroll', this.handleScroll);
        this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'SIMPLE' })); // get all simple links by user id

        if(this.props.location.state) {
            let initialState = {};
            if(this.props.location.state.from) initialState = { ...this.state, ...initialState, fromUrl: this.props.location.state.from }; // set from URL
            // get data from previous page
            if(this.props.location.state.data) {
                const { scrollToSection, status } = this.props.location.state.data;
                // set section to scroll to
                if(scrollToSection) initialState = { ...this.state, ...initialState, scrollToSection };
                // check status
                if(status === 'github-success') {
                    this.props.resetUser(); // reset current user
                    initialState = { ...this.state, ...initialState, init: false };
                    this.props.getById(this.props.userId); // refresh user data
                }
            }
            // set initial settings page state
            this.setState(initialState);
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.reset();
    }

    componentDidUpdate(){
        // load user data from db to state
        if(!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access
            if(!this.props.user.creator || (!this.props.user.pageOptions.showStandardLinks && !this.props.user.pageOptions.showGithubLinks && !this.props.user.pageOptions.showBlogLinks && !this.props.user.pageOptions.showYoutubeLinks && !this.props.user.pageOptions.showYoutubeshortLinks && !this.props.user.pageOptions.showInstagramreelLinks && !this.props.user.pageOptions.showTiktokLinks && !this.props.user.pageOptions.showSpotifyLinks)) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            this.setState({ ...this.state, init: true, user: this.props.user });
        }

        // load links from db to state component and initialize list
        if(this.state.init && !this.state.initLinks && this.props.links) {
            this.setState({ ...this.state, initLinks: true, links: this.props.links });
        }

        // get the newly created link's id and stop the spinner
        if(this.state.creating && this.props.newLinkId) {
            // update new link id
            let { links } = this.state;
            links.map(item => item.id === -1 ? item.id = this.props.newLinkId : item);
            // save link id and disable spinner
            this.setState({ ...this.state, links, creating: false });
        }
    }

    handleScroll(event) {
        // Enable sticky mode on scroll and reset sidebar
        this.setState({ ...this.state, sidebar: false, sidebarReset: true, sidebarPositionOffset: window.pageYOffset, sticky: window.pageYOffset > 1 });
    }

    handleMenuButton() {
        this.setState({ ...this.state, sidebar: !this.state.sidebar, sidebarReset: false });
    }

    handleLinksMenuToggle(toggle){
        // reset sidebar if enabled
        if(this.state.sidebar) this.setState({ ...this.state, sidebar: false, sidebarReset: true });
        else this.setState({ ...this.state, showLinksMenu: toggle });
    }

    handleLinksMenuClick(type) {
        const handleAddLinkPerType = () => {
            // create add link path
            let gotoPath = '';
            switch(type) {

                // open add github project screen
                case 'GITHUB': {
                    gotoPath = sitemap.admin.links.github.add;
                    break;
                }

                default: {
                    // add simple link
                    this.handleAddLink(type);
                    return;
                }
            }

            // open screen
            history.push({
                pathname: gotoPath,
                state: {
                    from: sitemap.admin.links.list
                }
            });
        };

        // close links menu and handle add link per type
        this.setState({ ...this.state, showLinksMenu: false }, () => handleAddLinkPerType());
    }

    handleReorderLinks(src, des) {
        if(des) {
            // create new list
            const newLinksList = [...this.state.links];
            // get dragged item's id
            const draggedItemId = newLinksList[src].id;
            // get dragged item's type
            const draggedItemType = newLinksList[src].type;

            // only sort links of the same type
            newLinksList.filter(item => item.type === draggedItemType);

            // remove item from first position and insert to new position
            newLinksList.splice(des, 0, newLinksList.splice(src, 1)[0]);

            let newOrder = [];
            let draggedItem = null;
            
            newLinksList.forEach((item, index) => {
                // update list order fields for all items
                item.order = index;
                // get updated dragged item
                if(item.id === draggedItemId) draggedItem = item;
                // generate updated order data map
                newOrder.push({ id: item.id, order: index });
            });
            
            // update links order on server
            this.setState({ ...this.state, links: newLinksList }, () => this.props.update({ ...draggedItem, newOrder }));
        }
    }

    async handleAddLink(type) {
        // check if section label
        let isSectionLabel = type === 'SIMPLE_SECTION';
        // do not save section label type
        if(isSectionLabel) type = null;

        // add new link at the top of the list
        let link = {
            userId: this.props.userId,
            id: -1,
            type: (type || 'SIMPLE'),
            label: '',
            url: '',
            description: isSectionLabel ? '' : null,
            enabled: isSectionLabel ? true : false,
            isSectionLabel
        };
        let { links } = this.state;
        links.unshift(link);

        // save new link in state and enable spinner
        this.setState({ ...this.state, links, creating: true });

        // create new link in db
        await this.props.create(link);
        
        /* After new link is created, we get it's id and stop
        the spinner within the componentDidUpdate() method */
    }

    handleEditLink(event, id) {
        event.preventDefault();

        let withTimeout = true;
        // get input name and updated value
        let { name, value } = event.target;

        // match input name with link data field
        if(name === 'simple-link-label') name = 'label';
        else if(name === 'section-label') name = 'description';
        else if(name === 'simple-link-url') name = 'url';
        else if(name === 'simple-link-enable') {
            name = 'enabled';
            withTimeout = false;
        }

        // update link
        this.setState({
            ...this.state,
            typingTimeout: withTimeout ? setTimeout(() => { this.props.update(this.state.links.find(link => link.id === id)) }, 1000) : null, // request update link data on server 1sec after user stops typing
            links: this.state.links.map(link => link.id === id ? { ...link, [name]: name === 'enabled' ? !link.enabled : value } : link)
        }, () => !withTimeout ? this.props.update(this.state.links.find(link => link.id === id)) : null);

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleDeleteLinkRequest(id){
        // show delete link warning
        this.setState({ ...this.state, linkIdToDelete: id, deleteWarning: true });
    }

    async handleDeleteLink() {
        // get id of link to be deleted from state
        let id = this.state.linkIdToDelete;

        // start spinner, hide warning and delete link from state
        this.setState({
            ...this.state,
            deleting: true,
            linkIdToDelete: null,
            deleteWarning: false,
            links: this.state.links.filter(link => link.id !== id) // reset list if is last link
        }, async () => {
            // delete from db
            await this.props.delete(id);

            // stop spinner
            this.setState({ ...this.state, deleting: false });
        });
    }

    handleDeleteLinkCancel(){
        // hide delete link warning
        this.setState({ ...this.state, linkIdToDelete: null, deleteWarning: false });
    }

    handleOpenLink(url) {
        // open link in new tab
        window.open(url);
    }

    handleOpenEditScreen(data) {
        // get link edit screen path
        let gotoPath = '';
        switch(data.type) {
            case 'GITHUB': {
                gotoPath = sitemap.admin.links.github.edit;
                break;
            }

            default:
                return;
        }

        // open add github project screen
        history.push({
            pathname: gotoPath,
            state: {
                from: sitemap.admin.links.list,
                data: {
                    linkId: data.id,
                }
            }
        });
    }

    handleFilter(selection) {
        // reset links from redux state
        this.props.reset();
        
        // reset state init to load new filtered links
        this.setState({ ...this.state, initLinks: false });

        // get links based on type
        switch (selection) {

            case 0: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'SIMPLE' }));
                return;
            }

            case 3: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'BLOG' }));
                return;
            }

            case 4: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'GITHUB' }));
                return;
            }

            case 8: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'YOUTUBE' }));
                return;
            }

            case 9: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'YOUTUBESHORT' }));
                return;
            }

            case 10: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'INSTAGRAMREEL' }));
                return;
            }

            case 11: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'TIKTOK' }));
                return;
            }

            case 12: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'SPOTIFY' }));
                return;
            }

            default: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'SIMPLE' }));
                return;
            }
        }
    }

    handleJoyrideCallback(data) {
        if(!data || !this.state.init) return;
        
        const { status } = data;

        if(status === 'finished' || status === 'skipped') {
            this.setState({ ...this.state, user: { ...this.state.user, isLinksTourDone: true } }, () => this.props.updateUser(prepareUserData(this.state.user)));
        }
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    render() {
        return (
            <ScreenContainer id='screen'>
                <MetaTags title={topbarConstants.titles.myLinks} />

                { this.state.init && this.state.user.creator && !this.state.user.isLinksTourDone &&
                <Joyride
                    callback={this.handleJoyrideCallback}
                    continuous
                    hideCloseButton
                    disableCloseOnEsc
                    disableOverlayClose
                    hideBackButton
                    // scrollToFirstStep
                    showProgress
                    showSkipButton
                    steps={contentLinkPage.tour}
                    run={true}
                    styles={{
                        options: {
                            primaryColor: colorConstants.primaryDark,
                        },
                    }}
                /> }

                <TopBar
                    title={topbarConstants.titles.myLinks}
                    sticky={this.state.sticky}
                    menuButton={this.handleMenuButton}
                    addButton={() => this.handleLinksMenuToggle(true)} />

                {this.state.init &&
                <SideBar
                    options={{ isAuthenticated: true, creator: this.state.user.creator, basic: this.state.user.basic, premium: this.state.user.premium, platinum: this.state.user.platinum, links: this.state.user.pageData.metadata.links }}
                    toggle={this.handleMenuButton}
                    from={sitemap.admin.links.list}
                    enable={this.state.sidebar}
                    reset={this.state.sidebarReset}
                    position={this.state.sidebarPositionOffset} /> }

                {this.state.init &&
                <FiltersMenu
                    handleFilter={this.handleFilter}
                    options={{
                        showStandardLinks: this.state.user.pageData.metadata.links.simple.enabled || this.state.user.pageData.metadata.links.simple.total > 0,
                        showGithubLinks: this.state.user.pageData.metadata.links.github.enabled || this.state.user.pageData.metadata.links.github.total > 0,
                        showBlogLinks: this.state.user.pageData.metadata.links.blog.enabled || this.state.user.pageData.metadata.links.blog.total > 0,
                        showYoutubeLinks: this.state.user.pageData.metadata.links.youtube.enabled || this.state.user.pageData.metadata.links.youtube.total > 0,
                        showYoutubeshortLinks: this.state.user.pageData.metadata.links.youtubeshort.enabled || this.state.user.pageData.metadata.links.youtubeshort.total > 0,
                        showInstagramreelLinks: this.state.user.pageData.metadata.links.instagramreel.enabled || this.state.user.pageData.metadata.links.instagramreel.total > 0,
                        showTiktokLinks: this.state.user.pageData.metadata.links.tiktok.enabled || this.state.user.pageData.metadata.links.tiktok.total > 0,
                        showSpotifyLinks: this.state.user.pageData.metadata.links.spotify.enabled || this.state.user.pageData.metadata.links.spotify.total > 0,
                        force: true,
                        defaultLinksView: 'SIMPLE'
                    }} /> }

                <LinksList
                    links={this.state.links}
                    handleReorder={this.handleReorderLinks}
                    handleOpenEditScreen={this.handleOpenEditScreen}
                    handleEditLink={this.handleEditLink}
                    handleDeleteLink={this.handleDeleteLinkRequest}
                    handleOpenLink={this.handleOpenLink} />

                { !this.state.deleting && !this.state.creating && !this.props.loading && (!this.state.links || this.state.links.length === 0) &&
                <NoLinksFound
                    onClick={() => this.handleLinksMenuToggle(true)} /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                <LinksMenu
                    show={this.state.showLinksMenu}
                    onHide={() => this.handleLinksMenuToggle(false)}
                    onClick={this.handleLinksMenuClick} />

                {(this.state.deleting || this.state.creating || this.props.loading) && <FullScreenSpinner />}

                <Warning
                    show={this.state.deleteWarning}
                    onHide={this.handleDeleteLinkCancel}
                    firstButtonClick={this.handleDeleteLink}
                    secondButtonClick={this.handleDeleteLinkCancel}
                    firstButton={contentLinkPage.deleteLinkWarning.firstButton}
                    firstButtonColor={colorConstants.darkGrey}
                    firstButtonBackgroundColor={colorConstants.softGrey}
                    secondButton={contentLinkPage.deleteLinkWarning.secondButton}
                    title={contentLinkPage.deleteLinkWarning.title}
                    body={contentLinkPage.deleteLinkWarning.body} />
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user } = state;
    const { username } = state.auth.cookie;
    const { loading, links } = state.link;
    const newLinkId = state.link.id;

    // export state data to props
    return { userId, alert, user, username, loading, links, newLinkId };
}

const actionCreators = {
    getById: userActions.getById,
    updateUser: userActions.update,
    resetUser: userActions.clear,
    create: linkActions.create,
    getAll: linkActions.getAll,
    update: linkActions.update,
    delete: linkActions.delete,
    reset: linkActions.clear,
    clearAlert: alertActions.clear
}

const connectedMyLinksContainer = connect(mapState, actionCreators)(MyLinksContainer);
export { connectedMyLinksContainer as MyLinksContainer };