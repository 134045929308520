import React, { useRef, useState, useEffect, useMemo } from "react";
import WebFont from 'webfontloader';
import { colord } from 'colord';

import { GradientFormSelector } from '../custom/FormInput';
import FeaturesList from './features/FeaturesList';
import { ColorInput, GradientColorInput } from '../custom/ColorPicker';
import { UploadPhoto } from '../custom/Images';
import {
  Container,
  SectionTitle,
  SectionSubTitle,
  FeaturesSection,
  PhotoSection,
  PageStylesSection,
  PhotoStyleSection,
  BackgroundSection,
  ThemesSection,
  CustomBackgroundSection,
  FileUploader,
  CustomButtonsSection,
  CustomFontsSection,
  CustomFontsPreviewContainer,
  CustomFontsPreview,
  SocialIconsSection,
  ToggleSection,
  ToggleText,
  ToggleButton,
  OtherOptionsSection,
  BrandingSection,
  ResetDefaultsSection,
  ResetDefaultsButton,
  Notification,
} from './CustomizePage.styles';
import { contentCustomizePage } from '../../constants';


const CustomizePage = (props) => {
    const {
      handleChangeFeatures,
      handleChangePageStyles,
      handleChangePhotoStyles,
      handleChangePageThemes,
      handleChangeBackground,
      handleUploadPhoto,
      handleChangeButtons,
      handleChangeFonts,
      handleChangeSocialIcons,
      handleChangeBranding,
      handleResetCustomizations,
      handleUpgrade,
      basic, premium, platinum,
      pageOptions,
      pageData,
    } = props;

    // get access level
    const hasBasicAccess = basic || premium || platinum;
    const hasPremiumAccess = premium || platinum;
    // const hasPlatinumAccess = platinum;

    // page option variables
    const { pageFontType, pageStyle, pageFontColor } = pageOptions;

    // component state
    const [isPageStyleCover, setIsPageStyleCover] = useState(false);
    const [isPageFontColorDark, setIsPageFontColorDark] = useState(false);

    // set theme options
    const themeOptions = useMemo(() => {
      return contentCustomizePage.options.pageThemes.map(item => {
        if(!hasBasicAccess && item.access === 'BASIC' && !item.notice) item.notice = '(upgrade)';
        return item;
      });
    }, [hasBasicAccess]);

    // open file browser on background image click
    const inputBackgroundImage = useRef(null);
    const openBackgroundImageUploader = () => {
      inputBackgroundImage.current.click();
    };

    // open file browser on cover image click
    const inputCoverImage = useRef(null);
    const openCoverImageUploader = () => {
      inputCoverImage.current.click();
    };

    // download new font family
    useEffect(() => {
      if(pageFontType && pageFontType !== 'NONE' && pageFontType !== '') {
        WebFont.load({ google: { families: [pageFontType] } });
      }
      return () => {
        // execute code on component unmount here
      };
    }, [pageFontType]);

    // check if pageStyle is non-COVER
    useEffect(() => {
      setIsPageStyleCover(pageStyle === 'COVER');
    }, [pageStyle]);

    // check font color
    useEffect(() => {
      if(colord(pageFontColor).isValid()) {
        setIsPageFontColorDark(colord(pageFontColor).isDark());
      }
    }, [pageFontColor]);

    return (
          <Container>
            <FeaturesSection className="first-step" >
              <SectionTitle>Page Sections</SectionTitle>
              <FeaturesList
                basic={basic}
                premium={premium}
                platinum={platinum}
                options={{ ...pageOptions, placeholders: pageData.metadata.defaultLinksViewOptions }}
                handleNotification={handleUpgrade}
                handleChange={handleChangeFeatures} />
            </FeaturesSection>

            <PhotoSection className="second-step" >
              <PageStylesSection>
                <SectionTitle>Photo Position {!hasBasicAccess && <Notification onClick={handleUpgrade}>Upgrade</Notification>}</SectionTitle>

                <GradientFormSelector
                  disabled={!hasBasicAccess} // accessible with Basic plan
                  options={contentCustomizePage.options.pageStyles}
                  id="page-styles-selector"
                  selected={pageOptions.pageStyle || contentCustomizePage.options.pageStyles[0].name}
                  onChange={handleChangePageStyles} />
                
                {isPageStyleCover && <SectionSubTitle>Cover Image</SectionSubTitle> }

                {isPageStyleCover && <UploadPhoto showEdit={true} url={pageOptions.pageCoverImage ? pageOptions.pageCoverImage + '&' + Date.now() : null} onClick={openCoverImageUploader} /> }
                {isPageStyleCover && <FileUploader type='file' accept="image/*" id='cover-image' ref={inputCoverImage} onChange={(e) => handleUploadPhoto(e, 'page-cover-image')} /> }
              </PageStylesSection>

              {!isPageStyleCover &&
              <PhotoStyleSection>
                <SectionTitle>Photo Style {!hasBasicAccess && <Notification onClick={handleUpgrade}>Upgrade</Notification>}</SectionTitle>

                <GradientFormSelector
                  disabled={!hasBasicAccess} // accessible with Basic plan
                  options={contentCustomizePage.options.pagePhotoStyles}
                  id="page-photo-styles-selector"
                  selected={pageOptions.pagePhotoStyle || contentCustomizePage.options.pagePhotoStyles[0].name}
                  onChange={(e) => handleChangePhotoStyles(e, 'pagePhotoStyle')} />
                
                { pageOptions.pagePhotoStyle && <SectionSubTitle>Photo Border Color</SectionSubTitle> }

                { pageOptions.pagePhotoStyle &&
                <ColorInput
                  name='pagePhotoBorderColor'
                  savedColor={pageOptions.pagePhotoBorderColor}
                  handleColorPick={handleChangePhotoStyles} /> }
              </PhotoStyleSection> }
            </PhotoSection>

            <BackgroundSection className="third-step" >
              <ThemesSection>
                <SectionTitle>Page Theme</SectionTitle>

                <GradientFormSelector
                  options={themeOptions}
                  id="page-themes-selector"
                  selected={pageOptions.pageTheme || contentCustomizePage.options.pageThemes[0].name}
                  onChange={handleChangePageThemes} />
              </ThemesSection>

              <CustomBackgroundSection>
                <SectionTitle>Custom Background {!hasBasicAccess && <Notification onClick={handleUpgrade}>Upgrade</Notification>}</SectionTitle>

                <SectionSubTitle>Solid Color</SectionSubTitle>

                <ColorInput
                  deactivate={!hasBasicAccess} // accessible with Basic plan
                  name='pageBackgroundColor'
                  savedColor={pageOptions.pageBackgroundGradient ? '' : pageOptions.pageBackgroundColor}
                  handleColorPick={handleChangeBackground} />

                {!isPageStyleCover && <SectionSubTitle>Gradient Color</SectionSubTitle> }

                {!isPageStyleCover && 
                <GradientColorInput
                  deactivate={!hasBasicAccess} // accessible with Basic plan
                  name='pageBackgroundGradient'
                  savedGradientColor={pageOptions.pageBackgroundGradient}
                  mode={pageOptions.pageCoverImage ? 'COVER' : null}
                  handleGradientColorPick={handleChangeBackground} /> }
                
                {!isPageStyleCover && <SectionSubTitle>Background Image</SectionSubTitle> }

                {!isPageStyleCover && <UploadPhoto deactivate={!hasBasicAccess} showEdit={true} url={pageOptions.pageBackgroundImage ? pageOptions.pageBackgroundImage + '&' + Date.now() : null} onClick={openBackgroundImageUploader} />}
                {!isPageStyleCover && <FileUploader type='file' accept="image/*" id='background-image' ref={inputBackgroundImage} onChange={(e) => handleUploadPhoto(e, 'page-background-image')} />}
              </CustomBackgroundSection>
            </BackgroundSection>
            
            <CustomButtonsSection className="fourth-step" >
              <SectionTitle>Custom Buttons {!hasBasicAccess && <Notification onClick={handleUpgrade}>Upgrade</Notification>}</SectionTitle>

              <SectionSubTitle>Shape</SectionSubTitle>

              <GradientFormSelector
                disabled={!hasBasicAccess} // accessible with Basic plan
                options={contentCustomizePage.options.pageButtonShapes}
                id="page-button-shape-selector"
                selected={pageOptions.pageButtonShape || contentCustomizePage.options.pageButtonShapes[0].name}
                onChange={(e) => handleChangeButtons(e, 'pageButtonShape')} />
              
              <SectionSubTitle>Style</SectionSubTitle>

              <GradientFormSelector
                disabled={!hasBasicAccess} // accessible with Basic plan
                options={contentCustomizePage.options.pageButtonStyles}
                id="page-button-style-selector"
                selected={pageOptions.pageButtonStyle || contentCustomizePage.options.pageButtonStyles[0].name}
                onChange={(e) => handleChangeButtons(e, 'pageButtonStyle')} />

              <SectionSubTitle>Color</SectionSubTitle>

              <ColorInput
                deactivate={!hasBasicAccess} // accessible with Basic plan
                name='pageButtonColor'
                savedColor={pageOptions.pageButtonColor}
                handleColorPick={handleChangeButtons} />
              
              <SectionSubTitle>Font Color</SectionSubTitle>

              <ColorInput
                deactivate={!hasBasicAccess} // accessible with Basic plan
                name='pageButtonFontColor'
                savedColor={pageOptions.pageButtonFontColor}
                handleColorPick={handleChangeButtons} />

            </CustomButtonsSection>

            <CustomFontsSection className="fifth-step" >
              <SectionTitle>Fonts {!hasBasicAccess && <Notification onClick={handleUpgrade}>Upgrade</Notification>}</SectionTitle>

              <CustomFontsPreviewContainer>
                <CustomFontsPreview
                  dark={isPageFontColorDark}
                  fontStyles={{ type: pageOptions.pageFontType, color: pageOptions.pageFontColor }}
                >
                  Aa
                </CustomFontsPreview>
              </CustomFontsPreviewContainer>

              <GradientFormSelector
                disabled={!hasBasicAccess} // accessible with Basic plan
                options={contentCustomizePage.options.pageFontTypes}
                id="page-font-type-selector"
                selected={pageOptions.pageFontType || contentCustomizePage.options.pageFontTypes[0].name}
                onChange={(e) => handleChangeFonts(e, 'pageFontType')} />

              <SectionSubTitle>Color</SectionSubTitle>

              <ColorInput
                deactivate={!hasBasicAccess} // accessible with Basic plan
                name='pageFontColor'
                savedColor={pageOptions.pageFontColor}
                handleColorPick={handleChangeFonts} />
            </CustomFontsSection>

            <SocialIconsSection className="sixth-step" >
              <SectionTitle>Social Icons {!hasBasicAccess && <Notification onClick={handleUpgrade}>Upgrade</Notification>}</SectionTitle>

              <SectionSubTitle>Icon Shape</SectionSubTitle>

              <GradientFormSelector
                disabled={!hasBasicAccess} // accessible with Basic plan
                options={contentCustomizePage.options.pageSocialIconShapes}
                id="page-social-icon-shape-selector"
                selected={pageOptions.pageSocialIconShape || contentCustomizePage.options.pageSocialIconShapes[0].name}
                onChange={(e) => handleChangeSocialIcons(e, 'pageSocialIconShape')} />
              
              <ToggleSection>
                <ToggleText>Custom Color</ToggleText>
                <ToggleButton disabled={!hasBasicAccess} type="switch" name={'pageSocialIconCustomColorToggle'} checked={!hasBasicAccess ? false : pageOptions.pageSocialIconColor} onChange={(event) => handleChangeSocialIcons(event, 'pageSocialIconCustomColorToggle')} />
              </ToggleSection>

              {hasBasicAccess && pageOptions.pageSocialIconColor && <SectionSubTitle>Icon Color</SectionSubTitle> }

              {hasBasicAccess && pageOptions.pageSocialIconColor &&
              <ColorInput
                name='pageSocialIconColor'
                savedColor={pageOptions.pageSocialIconColor}
                handleColorPick={handleChangeSocialIcons}
                deactivate={!pageOptions.pageSocialIconColor} /> }
              
              <SectionSubTitle>Icons Location</SectionSubTitle>

              <GradientFormSelector
                options={contentCustomizePage.options.pageSocialIconLocations}
                id="page-social-icon-location-selector"
                selected={pageOptions.pageSocialIconLocationTop ? 'NONE' : 'BOTTOM' || contentCustomizePage.options.pageSocialIconLocations[0].name}
                onChange={(e) => handleChangeSocialIcons(e, 'pageSocialIconLocationTop')} />

            </SocialIconsSection>

            <OtherOptionsSection>
              <BrandingSection className="seventh-step" >
                <SectionTitle>Branding</SectionTitle>

                <ToggleSection>
                  <ToggleText>Hide the Bunjee logo {!hasPremiumAccess && <Notification onClick={handleUpgrade}>Upgrade</Notification>}</ToggleText>
                  <ToggleButton disabled={!hasPremiumAccess} type="switch" name={'pageBrandingBunjeeLogo'} checked={!pageOptions.pageBrandingBunjeeLogo} onChange={(event) => handleChangeBranding(event, 'pageBrandingBunjeeLogo')} />
                </ToggleSection>
              </BrandingSection>

              <ResetDefaultsSection>
                <SectionTitle>Reset Customizations</SectionTitle>

                <ResetDefaultsButton small={true} oval={false} outline={true} onClick={handleResetCustomizations}>Revert Page Defaults</ResetDefaultsButton>
              </ResetDefaultsSection>
            </OtherOptionsSection>
          </Container>
    );
};

export default CustomizePage;