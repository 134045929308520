import { useMemo } from 'react';
import styled from 'styled-components';
import Icon from '../../../assets/icons/Icon';

import { colorConstants } from '../../../constants';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Header = styled.div`
    margin-top: 24px;
    font-size: 18px;
    font-weight: 600;
    color: ${colorConstants.black};
    text-align: center;
`;

const Text = styled.div`
    margin-bottom: 24px;
    font-size: 12px;
    font-weight: 400;
    color: ${colorConstants.mediumGrey};
    text-align: center;
`;


export const EmptyTripSearchResults = ({ type, error }) => {

    // get search type
    const isStays = useMemo(() => type === 'ACCOMMODATION', [type]);

    return (
        <Container>
            { !isStays && <Icon type='hiking_search' size={90} /> }
            { isStays && <Icon type='hotel_search' size={90} /> }
            <Header>{error ? "No offers, try one more search" : isStays ? 'Unbeatable Hotel Offers' : 'Exhilarating Tours & Activities'}</Header>
            <Text>{error ? error : isStays ? 'Embark on your perfect getaway with a choice of over 1 million accommodations across the globe!' : 'Embark on enchanting adventures and craft timeless memories! Discover captivating tours and activities across 680 extraordinary destinations.'}</Text>
        </Container>
    );
};