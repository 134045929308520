import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import {
    Container,
    CropperContainer,
    StyledButton,
    Controllers,
    Control,
    ControlLabel,
    Slider
} from './ImageCrop.styles';
import getCroppedImg from './cropper.helpers';


const ImageCrop = (props) => {
    const { url, filename, fileSize, imageDimensions, handleCroppedImage, circularCrop } = props;

    // declare new state variables
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    // set default zoom to 3 if image width/height larger than 2000px or file size larger than 1MB
    let defaultZoom = 1;
    if((imageDimensions && (imageDimensions.width > 2000 || imageDimensions.height > 2000)) || fileSize > 1000000) defaultZoom = 3;
    const [zoom, setZoom] = useState(defaultZoom);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);


    const onZoomChange = useCallback((event) => {
        event.preventDefault();
        setZoom(event.target.value);
    }, []);

    
    const onRotationChange = useCallback((event) => {
        event.preventDefault();
        setRotation(event.target.value);
    }, []);


    const submitCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(url, filename, croppedAreaPixels, rotation);
            handleCroppedImage(croppedImage);
          } catch (e) {
            console.error(e);
          }
    }, [url, filename, croppedAreaPixels, rotation, handleCroppedImage]);


    return (
        <Container>
            <CropperContainer>
                <Cropper
                    image={url}
                    crop={crop}
                    aspect={1}
                    cropShape={circularCrop ? 'round' : null}
                    showGrid={!circularCrop}
                    zoom={zoom}
                    maxZoom={3}
                    rotation={rotation}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                />
            </CropperContainer>

            <Controllers>
                <Control>
                    <ControlLabel>ZOOM</ControlLabel>
                    <Slider name='zoom-slider' min={1} max={3} step={0.1} value={zoom} onChange={onZoomChange} />
                </Control>

                <Control>
                    <ControlLabel>ROTATE</ControlLabel>
                    <Slider name='rotation-slider' min={0} max={360} step={1} value={rotation} onChange={onRotationChange} />
                </Control>
            </Controllers>

            <StyledButton gradient={true} onClick={submitCroppedImage} >Select Image</StyledButton>
        </Container>
    );
};

export default ImageCrop;