import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormText from 'react-bootstrap/FormText';

import { deviceConstants, colorConstants } from "../../../constants";
import { Button } from '../../custom/Buttons';


export const Container = styled.div`
    font-size: 12px;

    @media ${deviceConstants.tablet} {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

export const StyledForm = styled(Form)`
    width: 100%;
    padding-bottom: 170px;
    padding-left: 32px;
    padding-right: 32px;
`;

export const StyledFormGroup = styled(FormGroup)`
    margin-top: 16px;
`;

export const FormInputTitle = styled.div`
    font-weight: 600;
    color: ${colorConstants.softGrey};
    margin-bottom: 1px;
`;

export const StyledFormText = styled(FormText)`
  color: ${colorConstants.warning};
`;

export const StyledButton = styled(Button)`
    width: 100%;
    border-radius: 0px;
    position: ${props => props.static ? 'static' : 'fixed'};
    bottom: 0;

    @media ${deviceConstants.tablet} {
        width: ${props => props.static ? '100%' : '25%'};
        border-radius: 2px;

        position: ${props => props.static ? 'static' : 'absolute'};
        bottom: 20px;
        margin-bottom: ${props => props.static ? '20px' : '0'};
    }
    
    @media ${deviceConstants.desktop} {
        width: ${props => props.static ? '100%' : '20%'};
    }
`;

export const UploadPhotosContainer = styled.div`
    margin-top: 8px;
    
    display: flex;
    justify-content: center;

    & > * {
        display: block;
        margin: 0 10% 0 0;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const FileUploader = styled.input`
  display: none;
`;

export const MonetizationButtonSecion = styled.div`
    margin-top: 8px;
`;

export const NoticeMessage = styled.div`
    font-weight: 600;
    font-size: 15px;
    color: ${colorConstants.secondaryDark};
`;