import React from "react";
import Spinner from 'react-bootstrap/Spinner'
import ReCAPTCHA from "react-google-recaptcha";

import {
  MainContainer,
  BannerWrapper,
  Banner,
  FormWrapper,
  TopSection,
  Title,
  SubTitle,
  StyledForm,
  StyledFormGroup,
  StyledFormLabel,
  StyledFormControl,
  StyledFormText,
  RegisterButton,
  StyledLinksContainer,
  StyledLoginLink,
  PrivacyText,
  PrivacyLink,
  RecaptchaContainer,
  SpinnerContainer,
  AlertWarning,
  AlertSuccess,
  PasswordRequirements
} from './RegisterForm.styles';
import { Divider } from '../../custom/Divider';
import SocialLogins from '../SocialLogins';

import { generateFirebasePhotoLink } from '../../../utils';
import { otherConstants, sitemap } from "../../../constants";


export const RegisterForm = (props) => {
  const {
    alert,
    enableInviteCode,
    handleChange,
    handleSubmit,
    handleSubmitGoogle,
    handleSubmitFacebook,
    handleCaptcha,
    registering,
    user,
    submitted,
    captcha,
    isStrongPassword
  } = props;

  // set banner images
  const heroImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fauth%2Fregister-banner-4.jpg?alt=media');

  return (
    <MainContainer>
      <BannerWrapper>
        <Banner src={heroImageUrl} alt="Bunjee Registration Banner"/>
      </BannerWrapper>
      
      <FormWrapper>
        <TopSection>
          <Title>Turn your passions into livelihood.</Title>

          <SubTitle>
            An intuitive link in bio solution to easily connect your content, commerce and audiences at zero cost.
          </SubTitle>
        </TopSection>

        <StyledForm onSubmit={handleSubmit}>
          <StyledFormGroup
            className={submitted && !user.name ? " has-error" : ""}
          >
            <StyledFormLabel>Full Name</StyledFormLabel>
            <StyledFormControl
              type="text"
              placeholder="John Doe"
              name="name"
              value={user.name}
              onChange={handleChange}
            />
            {submitted && !user.name && (
              <StyledFormText>Full Name is required</StyledFormText>
            )}
          </StyledFormGroup>

          <StyledFormGroup
            className={submitted && !user.email ? " has-error" : ""}
          >
            <StyledFormLabel>Email</StyledFormLabel>
            <StyledFormControl
              type="email"
              placeholder="you@example.com"
              name="email"
              value={user.email}
              onChange={handleChange}
            />
            {submitted && !user.email && (
              <StyledFormText>Email is required</StyledFormText>
            )}
          </StyledFormGroup>

          <StyledFormGroup
            className={submitted && !user.password ? " has-error" : ""}
          >
            <StyledFormLabel>Password</StyledFormLabel>
            <StyledFormControl
              type="password"
              placeholder="Password"
              name="password"
              value={user.password}
              onChange={handleChange}
            />
            {submitted && !user.password && (
              <StyledFormText>Password is required</StyledFormText>
            )}
            <StyledFormControl
              type="password"
              placeholder="Confirm Password"
              name="passwordConfirm"
              value={user.passwordConfirm}
              onChange={handleChange}
            />
            {submitted && (user.password !== user.passwordConfirm) && (
              <StyledFormText>Password does not match</StyledFormText>
            )}
            {submitted && (user.password === user.passwordConfirm) && !isStrongPassword && (
              <StyledFormText>Password must meet the following requirements
                <PasswordRequirements>
                  <li>8 characters minimum length</li>
                  <li>at least 1 uppercase and 1 lowercase</li>
                  <li>at least 1 number and 1 symbol</li>
                </PasswordRequirements>
              </StyledFormText>
            )}
          </StyledFormGroup>

          { enableInviteCode &&
          <StyledFormGroup
            className={submitted && !user.code ? " has-error" : ""}
          >
            <StyledFormLabel>Invite Code</StyledFormLabel>
            <StyledFormControl
              type="text"
              placeholder="Your invite code"
              name="code"
              value={user.code}
              onChange={handleChange}
            />
            {submitted && !user.code && (
              <StyledFormText>Invite Code is required</StyledFormText>
            )}
          </StyledFormGroup> }

          <RecaptchaContainer>
            <ReCAPTCHA
              sitekey={otherConstants.captchaKey}
              onChange={handleCaptcha}
            />
            {submitted && !captcha && (
              <StyledFormText>reCAPTCHA is required</StyledFormText>
            )}
          </RecaptchaContainer>

          <PrivacyText>
            By creating your account, you agree to our{" "}
            <PrivacyLink to={sitemap.landing.z.policies.terms} >Terms of Service</PrivacyLink> and{" "}
            <PrivacyLink to={sitemap.landing.z.policies.privacy} >Privacy Policy</PrivacyLink>
          </PrivacyText>

          <StyledFormGroup>
            <RegisterButton gradient={true} type="submit">
              Sign up
            </RegisterButton>

            {registering && <SpinnerContainer><Spinner animation="border" /></SpinnerContainer>}
          </StyledFormGroup>

          {alert.message && alert.type === 'alert-danger' && <AlertWarning>{alert.message}</AlertWarning>}
          {alert.message && alert.type === 'alert-success' && <AlertSuccess>{alert.message}</AlertSuccess>}

          <StyledLinksContainer>
            Already have an account?&nbsp;
            <StyledLoginLink to={sitemap.auth.login}>Log in</StyledLoginLink>
          </StyledLinksContainer>
        </StyledForm>

        <Divider label="or" />
        
        <SocialLogins handleGoogle={handleSubmitGoogle} handleFacebook={handleSubmitFacebook} />
      </FormWrapper>
    </MainContainer>
  );
};