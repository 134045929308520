import { otherConstants } from './other.constants';


// Static content for html head meta tags via Helmet
export const contentHelmet = {
    title: "Bunjee | The creators' link to the travel industry",
    description: "The creators' link to the travel industry",
    seo: {
        image: {
            fb: 'https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/o/public%2Fapp%2Fseo%2Fbunjee_cover_seo_fb.png?alt=media',
            tt: 'https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/o/public%2Fapp%2Fseo%2Fbunjee_cover_seo_tt.png?alt=media',
        }
    }
};


// Static content for top bar
export const topbarConstants = {
    titles: {
        crop: 'Crop Image',
        social: 'Add Social Link',
        address: 'Edit Your Address',
        password: 'Change Password',
        settings: 'Settings',
        customize: 'Customizations',
        emailChange: 'Change Email',
        editTrip: 'Edit Trip',
        myTrips: 'My Trips',
        tripPage: 'Trip Overview',
        favourites: 'Saved',
        confirm: 'Confirmation',
        myLinks: 'My Links',
        myProducts: 'My Products',
        addProduct: 'Add Product',
        editProduct: 'Edit Product',
        myNFTs: 'My NFTs',
        addNFT: 'Share NFT',
        editNFT: 'Edit NFT',
        myBookings: 'My Bookings',
        bookingDetails: 'Booking Confirmation',
    }
};


// Static content related to Social Media
export const socialConstants = {
    platforms: [
        'YouTube',
        'Facebook',
        'Instagram',
        'Twitter',
        'TikTok',
        'Github',
        'Twitch',
        'Discord',
        'Patreon',
        'Pinterest',
        'Spotify',
        'Telegram',
        'WhatsApp',
        'SubStack',
        'SoundCloud',
        'SnapChat',
        'Etsy',
        'ClubHouse',
        'Cameo',
        'BandCamp',
        'Apple Podcast',
        'Apple Music',
        'Apple App Store',
        'Android Play Store',
        'Amazon',
    ],
    placeholders: [
        'https://youtube.com/channel/channelurl',
        'https://facebook.com/pageurl',
        'https://instagram.com/username',
        'https://twitter.com/username',
        'https://tiktok.com/@username',
        'https://github.com/username',
        'https://twitch.tv/username',
        'https://discord.gg/serverurl',
        'https://patreon.com/pageurl',
        'https://pinterest.com/username',
        'https://open.spotify.com/artist/artistname',
        'https://t.me/',
        '+0000000000000',
        'https://you.substack.com',
        'https://soundcloud.com/username',
        'https://snapchat.com/add/username',
        'https://etsy.com/shop/shopurl',
        'https://clubhouse.com/@username',
        'https:/www.cameo.com/username',
        'https://you.bandcamp.com/',
        'https://podcasts.apple.com/us/podcast/yourpodcast/123456789',
        'https://music.apple.com/us/album/albumurl',
        'https://apps.apple.com/us/app/yourapp/idl123456789',
        'https://play.google.com/store/apps/details?id=com.yourapp.app',
        'https://amazon.com/shop/shopname',
    ]
};


// Static content for Travel Waitlist landing page
export const contentTravelWaitlist = {
    header: 'Earn money\nas a travel\ncontent creator',
    subheader: 'From luxury hotel stays to hiking the Alps, travel content creators join us to turn their adventures into livelihood. Travel industry, simplified.',
    inputPlaceholder: 'Enter your email address',
    inputAlert: 'Email address is required',
    inputButton: 'Join the waitlist',
    gdpr: "Be the first to know when we launch. By submitting this form you agree for us to contact you about our products and services. Don't worry, we won't spam."
};


// Static content for early access landing page
export const contentEarlyAccessLinkInBio = {
    hero: {
        header: 'Level Up Your Link in Bio',
        subheader: 'A single link to connect your content, commerce & audiences',
        primaryCta: 'CLAIM YOURS FOR FREE',
        secondaryCta: {
            part1: 'Already have an account? ',
            part2: 'Log in',
        }
    },
    cta: {
        header: 'Join the Bunjee community today',
        text: "Whether you're an awesome music artist, a cool NFT creator or legendary gamer and streamer, Bunjee is the best community to connect, inspire, learn and grow together!"
    },
    form: {
        title: 'Claim Your Bunjee Page',
        field1: {
            placeholder: 'Full name',
            warning: 'Full name is required',
        },
        field2: {
            placeholder: 'Your Instagram handle',
            warning: 'Instagram handle is required',
        },
        field3: {
            placeholder: "Link to your primary social platform",
            warning: 'Your primary social platform link is required',
            warning2: 'It looks like this is not a valid social link',
        },
        field4: {
            placeholder: 'Enter your email address',
            warning1: 'Email is required',
            warning2: 'A valid email is required',
        },
        field5: {
            placeholder: 'Account Type',
            options: ['Creator', 'Brand', 'Agent/Manager'],
        },
        button: 'SUBMIT',
        gdpr: 'By submitting this form you agree to receiving exclusive updates and content from Bunjee through email.'
    },
    featured: {
        title: 'Featured in'
    },
};


// Static content for techies early access landing page
export const contentTechiesEarlyAccess = {
    hero: {
        header: 'One Link For All Your Tech',
        subheader: 'A single link to share all your projects, games and gadgets with your fans',
        primaryCta: 'CLAIM YOURS FOR FREE',
        secondaryCta: {
            part1: 'Already have an account? ',
            part2: 'Log in',
        }
    },
    cta: {
        header: 'Get your free Bunjee today',
        text: "Whether you're into gaming or learning how to code, Bunjee is designed to easily share your cool projects, go-to dev tools, tech gadgets, gaming setup and fun twitch streams."
    },
    form: {
        title: 'Claim Your Bunjee Page',
        field1: {
            placeholder: 'Full name',
            warning: 'Full name is required',
        },
        field2: {
            placeholder: 'Your Instagram handle',
            warning: 'Instagram handle is required',
        },
        field3: {
            placeholder: "Link to your primary social platform",
            warning: 'Your primary social platform link is required',
            warning2: 'It looks like this is not a valid social link',
        },
        field4: {
            placeholder: 'Enter your email address',
            warning1: 'Email is required',
            warning2: 'A valid email is required',
        },
        field5: {
            placeholder: 'Account Type',
            options: ['Creator/Gamer', 'Creator/Developer', 'Brand/Tech', 'Brand/Gaming', 'Agent/Manager Gaming'],
        },
        button: 'SUBMIT',
        gdpr: 'By submitting this form you agree to receiving exclusive updates and content from Bunjee through email.'
    },
    featured: {
        title: 'Featured in'
    },
};


// Static content for NFT early access landing page
export const contentNFTLandingPage = {
    hero: {
        header: 'One Link For All Your NFTs',
        subheader: 'A single link to share all your NFTs and content with your followers and friends',
        primaryCta: "CLAIM YOURS FOR FREE",
        secondaryCta: {
            part1: 'Already have an account? ',
            part2: 'Log in',
        }
    },
    sectionTwo: {
        header: 'Promote & sell more NFTs',
        subheader: 'Launch your NFT page in minutes, connect your wallets and with a single link showcase all your NFTs to your fans. All with no coding and no website maintenance worries.',
        primaryCta: "GET STARTED FOR FREE",
    },
    sectionThree: {
        header: 'Share it anywhere',
        subheader: 'Use your Bunjee link to easily connect your audiences from any social network to your NFTs and other content.',
        primaryCta: "GET STARTED FOR FREE",
    },
    cta: {
        header: 'Get your free Bunjee today',
        text: "Whether you're an NFT creator, collector or brand, Bunjee is designed to help easily share your cool NFT projects and art, important content, resources and Web3 journey with your fans."
    },
    form: {
        title: 'Claim Your Bunjee Page',
        field1: {
            placeholder: 'Full name',
            warning: 'Full name is required',
        },
        field2: {
            placeholder: 'Your Instagram handle',
            warning: 'Instagram handle is required',
        },
        field3: {
            placeholder: "Link to your NFT collection",
            warning: 'Your NFT collection link is required',
            warning2: 'It looks like this is not a valid link',
        },
        field4: {
            placeholder: 'Enter your email address',
            warning1: 'Email is required',
            warning2: 'A valid email is required',
        },
        field5: {
            placeholder: 'Account Type',
            options: ['NFT Creator', 'NFT Owner', 'Brand/Other'],
        },
        button: 'SUBMIT',
        gdpr: 'By submitting this form you agree to receiving exclusive updates and content from Bunjee through email.'
    },
    featured: {
        title: 'Featured in'
    },
};


// Static content for Travel early access landing page
export const contentTravelLandingPage = {
    hero: {
        header: "Turn your travel adventures\ninto a livelihood",
        subheader: 'Connecting creators with the travel industry to sustainably monetize their content through easy-to-build travel products',
        primaryCta: "JOIN WAITLIST",
        secondaryCta: {
            part1: 'Already have an account? ',
            part2: 'Log in',
        }
    },
    sectionTwo: {
        header: 'Storefronts for travel creators',
        subheader: 'Launch your personalized travel storefront in minutes. Promote & sell your trips, merch, products, NFTs & services. No coding required.',
        primaryCta: "JOIN WAITLIST",
    },
    sectionThree: {
        header: 'Monetize your travel content',
        subheader: 'Earn money by turning your content into bookable vacation stays & experiences for your fans. 1M+ travel partners, unbeatable offers. No affiliate links.',
        primaryCta: "JOIN WAITLIST",
    },
    sectionFour: {
        header: 'Share your bio links anywhere',
        subheader: 'Use your Bunjee link to easily connect your audiences from any social network to all your links, trips, videos, music, podcast, products, NFTs and other content.',
        primaryCta: "JOIN WAITLIST",
    },
    cta: {
        header: 'Join the Bunjee community and fuel your travels',
        text: "Bunjee is your link to the travel industry, a sustainable revenue source for your content, a cool home for your fans and an amazing community of like-minded travel creators."
    },
    form: {
        title: 'Reserve Your Bunjee',
        field1: {
            placeholder: 'Full name',
            warning: 'Full name is required',
        },
        field2: {
            placeholder: 'Your Instagram handle',
            warning: 'Instagram handle is required',
        },
        field3: {
            placeholder: "Link to your primary social platform",
            warning: 'Your primary social platform link is required',
            warning2: 'It looks like this is not a valid social link',
        },
        field4: {
            placeholder: 'Enter your email address',
            warning1: 'Email is required',
            warning2: 'A valid email is required',
        },
        field5: {
            placeholder: 'Account Type',
            options: ['Creator', 'Brand', 'Agent/Manager'],
        },
        button: 'SUBMIT',
        gdpr: 'By submitting this form you agree to receiving exclusive updates and content from Bunjee through email.'
    },
    featured: {
        title: 'Featured in'
    },
};


// Static content for Landing Page Header
export const contentLandingPageHeader = {
    earlyAccess: 'JOIN WAITLIST',
    login: 'LOG IN'
};


// Static content for Landing Page Footer
export const contentLandingPageFooter = {
    menu: {
        col1: {
            title: 'CREATORS',
            item1: 'Explore',
            item2: 'Blog',
            item3: 'Features',
            item4: 'Ambassadors',
            item5: 'For Musicians',
            item6: 'For NFT Owners',
            item7: 'For Travellers',
        },
        col2: {
            title: 'PARTNERS',
            item1: 'For Agencies',
            item2: 'For Hospitality',
            item3: 'For Travel Agencies',
            item4: 'For Entrepreneurs',
        },
        col3: {
            title: 'COMPANY',
            item1: 'About',
            item2: 'Press',
            item3: 'Careers',
            item4: 'Contact',
        },
        col4: {
            title: 'SUPPORT',
            item1: 'Help Center',
            item2: 'FAQs',
            item3: 'Privacy Policy',
            item4: 'Terms of Service',
            item5: 'Cookie Policy',
        }
    },
    bottom: {
        copyright: {
            desktop: {
                part1: '© ', // add current year programmatically after part 1 and before part 2
                part2: ' Bunjee. All Rights Reserved.',
            },
            mobile: {
                part1: '© ', // add current year programmatically after part 1 and before part 2
                part2: ' Bunjee.',
                part3: 'All Rights Reserved.',
            }
        },
    },
};


// Static content for login page
export const contentLoginPage = {
    header: {
        button: 'Create Account'
    },
    title: 'Login to ' + otherConstants.projectName,
    gdpr: {
        part1: "By continuing you're indicating that you accept our ",
        part2: ' and '
    },
    form: {
        labels: {
            email: 'Email',
            password: 'Password'
        },
        placeholders: {
            email: 'Email',
            password: 'Password'
        },
        warnings: {
            email: 'Email is required',
            password: 'Password is required',
            captcha: 'reCAPTCHA is required'
        }
    },
    buttons: {
        login: {
            label: 'Log in'
        }
    },
    links: {
        resetPassword: {
            label: 'Reser your password'
        },
        register: {
            label: 'New to ' + otherConstants.projectName + '? Create an account'
        },
        terms: {
            label: 'Terms of Service'
        },
        privacy: {
            label: 'Privacy Policy'
        }
    }
};


// Static content for Dashboard page
export const contentDashboarPage = {
    tour: [
        {
            target: '.first-step',
            content: 'Here, is a quick overview of your account, including the URL of your Bunjee page.',
            title: "Account overview",
            locale: { skip: "Exit Tour" },
            disableBeacon : true, // automatically start app tour
            styles: {
                options: {
                    zIndex: 100,
                },
            }
        },
        {
            target: '.second-step',
            content: "In this section, you'll find a brief overview of all the links you shared on your Bunjee page.",
            title: "Link stats overview",
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 100,
                },
            }
        },
        {
            target: '.third-step',
            content: 'Here, you have a handy shortcuts area to quickly access frequently used features.',
            title: "Quick links",
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 100,
                },
            }
        },
        {
            target: '.last-step',
            content: "You've completed the dashboard tour! Now, your next step is to click this quick link to open the Settings page and complete your page details.",
            title: "Next step",
            locale: { last: "Done" },
            styles: {
                options: {
                    zIndex: 100,
                },
            }
        },
    ]
};


// Static content for Confirmation pages
export const contentConfirmPages = {
    email: {
        success: {
            part1: "Congratulations you've successfully verified your email address and in ",
            part2: " seconds you'll be redirected to your " + otherConstants.projectName + " account. If you're not redirected please use the top bar menu on the upper left corner of your screen."
        },
        fail: {
            part1: "If your confirmation link does not work or facing any other issues with your new " + otherConstants.projectName + " account, please email us at ",
            part2: " and let us know about the issue you're facing (also mention the above error description) so that we can best assist you in setting up your " + otherConstants.projectName + " account."
        }
    }
};


// Static content for settings page
export const contentSettingsPage = {
    deleteUserWarning: {
        title: 'Delete Account',
        body: 'Are you sure you want to permanently delete your account?',
        firstButton: 'Delete',
        secondButton: 'Cancel'
    },
    activeSubscriptionWarning: {
        title: 'Active Subscription',
        body: 'To delete your account you must first cancel your active subscription.',
        firstButton: 'Ok',
    },
    cancelSubscriptionWarning: {
        title: 'Cancel Subscription',
        body: 'Are you sure you want to cancel your account subscription? You will lose access to premium feature.',
        firstButton: 'Yes, proceed',
        secondButton: 'Keep subscription'
    },
    usernameChangeWarning: {
        title: 'Change Username',
        body: 'After you update the username you\'ll have to re-login.',
        firstButton: 'Update',
        secondButton: 'Cancel'
    },
    hasNFTsWarning: {
        title: 'Remove Active NFTs',
        body: 'To disconnect this wallet you must first remove all associated NFTs from your profile.',
        firstButton: 'Ok',
    },
    tour: [
        {
            target: '.first-step',
            content: 'This is where you can add or change your profile photo, which is displayed on your public page.',
            title: "Page photo",
            locale: { skip: "Exit Tour" },
            disableBeacon : true, // automatically start app tour
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.second-step',
            content: "You can add or edit the information displayed on your public page, including your Bunjee handle (username), display name, bio, and website.",
            title: "About you",
            placement: 'bottom',
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.third-step',
            content: 'This is where you can input all your social media profile links that will be displayed on your page.',
            title: "Social media links",
            placement: 'top',
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.fourth-step',
            content: "Here, you can view information about all the earnings generated on your Bunjee page.",
            title: "Your earnings",
            placement: 'top',
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.topbar-menu-toggle-button',
            content: 'You can access the Menu from here. You can go to your dashboard, style your page in Customize, manage your links, trips, and more.',
            title: "App menu",
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.topbar-menu-toggle-button',
            content: "You've now completed the settings tour! Your next step is to tap here to open the Menu, and go to the Customize section to style your page.",
            title: "Next step",
            locale: { last: "Done" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
    ]
};


// Static content for customizations page
export const contentCustomizePage = {
    options: {
        pageStyles: [
            { name: 'VERTICAL', label: 'Center' },
            { name: 'HORIZONTAL_LEFT', label: 'Left' },
            { name: 'HORIZONTAL_RIGHT', label: 'Right' },
            { name: 'COVER', label: 'Profile Cover Image' }
        ],
        pagePhotoStyles: [
            { name: 'NONE', label: 'None' },
            { name: 'BORDER', label: 'Border' },
            // { name: 'HEXAGON', label: 'Hexagon' },
            // { name: 'SQUARE', label: 'Square' },
        ],
        pageThemes: [
            { name: 'NONE', label: 'None', access: 'FREE' },
            { name: 'KYIV', label: 'Kyiv', access: 'FREE' },
            { name: 'KYIV_BLUE', label: 'Kyiv Blue', access: 'FREE' },
            { name: 'KYIV_RED', label: 'Kyiv Red', access: 'FREE' },
            { name: 'KYIV_GREEN', label: 'Kyiv Green', access: 'FREE' },
            { name: 'KYIV_ORANGE', label: 'Kyiv Orange', access: 'FREE' },
            { name: 'KYIV_PURPLE', label: 'Kyiv Purple', access: 'FREE' },
            { name: 'WIZARD_WHITE', label: 'Wizard White', access: 'FREE' },
            { name: 'WIZARD_GREY', label: 'Wizard Grey', access: 'FREE' },
            { name: 'WIZARD_DARK', label: 'Wizard Dark', access: 'FREE' },
            { name: 'TEAL', label: 'Teal', access: 'FREE' },
            { name: 'RUBY', label: 'Ruby', access: 'FREE' },
            { name: 'JADE', label: 'Jade', access: 'FREE' },
            { name: 'CANELA', label: 'Canela', access: 'FREE' },
            { name: 'INDIGO', label: 'Indigo', access: 'FREE' },
            { name: 'ACTIVE', label: 'Active', access: 'BASIC' },
            { name: 'OCEANIC', label: 'Oceanic', access: 'BASIC' },
            { name: 'PITCHIE', label: 'Pitchie', access: 'FREE' },
            { name: 'CHARCOAL', label: 'Charcoal', access: 'FREE' },
            { name: 'HACKER', label: 'Hacker', access: 'FREE' },
            { name: 'MOMENTS', label: 'Moments', access: 'FREE' },
            { name: 'PINKY', label: 'Pinky', access: 'FREE' },
            { name: 'EMERALD', label: 'Emerald', access: 'FREE' },
            { name: 'ALMOST', label: 'Almost', access: 'FREE' },
            { name: 'LUSH', label: 'Lush', access: 'BASIC' },
            { name: 'TRANQUIL', label: 'Tranquil', access: 'BASIC' },
            { name: 'CELESTIAL', label: 'Celestial', access: 'BASIC' },
            { name: 'ADVENTUROUS', label: 'Adventurous', access: 'BASIC' },
            { name: 'KASHMIRY', label: 'Kashmiry', access: 'BASIC' },
            { name: 'BOURBON', label: 'Bourbon', access: 'BASIC' },
            { name: 'DECENT', label: 'Decent', access: 'BASIC' },
            { name: 'SLATE', label: 'Slate', access: 'BASIC' },
            { name: 'CHERRY', label: 'Cherry', access: 'BASIC' },
            { name: 'FROST', label: 'Frost', access: 'BASIC' },
            { name: 'DUSK', label: 'Dusk', access: 'BASIC' },
            { name: 'ROYAL', label: 'Royal', access: 'BASIC' },
        ],
        pageButtonShapes: [
            { name: 'NONE', label: 'Default' },
            { name: 'ROUNDED', label: 'Rounded' },
            { name: 'OVAL', label: 'Oval' },
        ],
        pageButtonStyles: [
            { name: 'FILL', label: 'Fill' },
            { name: 'OUTLINE', label: 'Outline' },
        ],
        pageFontTypes: [
            { name: 'NONE', label: 'Montserrat' },
            { name: 'Open Sans', label: 'Open Sans' },
            { name: 'Roboto', label: 'Roboto' },
            { name: 'Hubballi', label: 'Hubballi' },
            { name: 'Smooch Sans', label: 'Smooch Sans' },
            { name: 'League Gothic', label: 'League Gothic' },
            { name: 'Ubuntu', label: 'Ubuntu' },
            { name: 'BhuTuka Expanded One', label: 'BhuTuka Expanded One' },
            { name: 'Raleway', label: 'Raleway' },
            { name: 'Nunito', label: 'Nunito' },
            { name: 'Meddon', label: 'Meddon' },
            { name: 'Abel', label: 'Abel' },
            { name: 'Nanum Gothic', label: 'Nanum Gothic' },
            { name: 'Allura', label: 'Allura' },
            { name: 'Anton', label: 'Anton' },
            { name: 'Archivo Black', label: 'Archivo Black' },
            { name: 'Baskervville', label: 'Baskervville' },
            { name: 'Didact Gothic', label: 'Didact Gothic' },
            { name: 'Noto Sans', label: 'Noto Sans' },
            { name: 'Indie Flower', label: 'Indie Flower' },
            { name: 'Paytone One', label: 'Paytone One' },
            { name: 'Russo One', label: 'Russo One' },
            { name: 'Saira Stencil One', label: 'Saira Stencil One' },
            { name: 'Sofia', label: 'Sofia' },
            { name: 'Titillium Web', label: 'Titillium Web' },
            { name: 'Quicksand', label: 'Quicksand' },
            { name: 'Sansita Swashed', label: 'Sansita Swashed' },
            { name: 'Inconsolata', label: 'Inconsolata' },
            { name: 'Karla', label: 'Karla' },
            { name: 'Syne Mono', label: 'Syne Mono' },
            { name: 'IBM Plex Sans', label: 'IBM Plex Sans' },
            { name: 'Dosis', label: 'Dosis' },
            { name: 'Source Code Pro', label: 'Source Code Pro' },
            { name: 'Bitter', label: 'Bitter' },
            { name: 'Cabin', label: 'Cabin' },
            { name: 'Lobster', label: 'Lobster' },
            { name: 'Dancing Script', label: 'Dancing Script' },
            { name: 'Barlow Condensed', label: 'Barlow Condensed' },
            { name: 'Kanit', label: 'Kanit' },
            { name: 'VT323', label: 'VT323' },
        ],
        pageSocialIconShapes: [
            { name: 'NONE', label: 'Default' },
            { name: 'FILL', label: 'Fill' },
        ],
        pageSocialIconLocations: [
            { name: 'NONE', label: 'Top' },
            { name: 'BOTTOM', label: 'Bottom' },
        ],
    },
    tour: [
        {
            target: '.first-step',
            content: 'Here, you can choose which sections of your page to show or hide. You can also pick which section to load first for your page visitors.',
            title: "Show or hide page sections",
            placement: 'bottom',
            placementBeacon: 'top',
            locale: { skip: "Exit Tour" },
            disableBeacon : true, // automatically start app tour
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.second-step',
            content: "This is where you can personalize your page photo by adjusting its style, changing its position, or uploading a cover photo.",
            title: "Style your photo",
            placement: 'top',
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.third-step',
            content: "Here, you can customize your page by choosing background colors, uploading your own background image, or selecting from a variety of pre-made page themes.",
            title: "Background color & themes",
            placement: 'top',
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.fourth-step',
            content: "In this section, you can modify the look of your page buttons by adjusting their shape, style, and color.",
            title: "Button styles",
            placement: 'top',
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.fifth-step',
            content: "Select fonts and font colors for your page from a diverse range of stylish choices.",
            title: "Font styles",
            placement: 'top',
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.sixth-step',
            content: "Here, you can customize the shapes, colors, and positions of all social media link icons on your page.",
            title: "Style all social icons",
            placement: 'top',
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.seventh-step',
            content: "Here, you have the option to remove the Bunjee branding from your page.",
            title: "Own branding",
            placement: 'top',
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.topbar-sub-button',
            content: "Here, you can see the changes you've made to your page.",
            title: "View page changes",
            placement: 'bottom',
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.topbar-menu-toggle-button',
            content: "You've now completed the page customization tour! Your next step is to tap here to open the Menu, and go to the Links section to share your first links.",
            title: "Next step",
            locale: { last: "Done" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
    ]
};


// Static content for trip pages
export const contentTripPage = {
    deleteTripWarning: {
        title: 'Delete Trip',
        body: 'Are you sure you want to delete this trip?',
        firstButton: 'Delete',
        secondButton: 'Cancel'
    },
    tour: [
        {
            target: '.topbar-add-button',
            content: 'Here, you can add a new stay or experience to your page. This will open a menu, allowing you to choose between adding a stay, an experience, or enable the search component for stays or experiences.',
            title: "Share stays or experiences",
            placement: 'left',
            locale: { skip: "Exit Tour" },
            disableBeacon : true, // automatically start app tour
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.trip-search-component',
            content: "Here, you can change the search component's label, order, functionality, or choose to remove it.",
            title: "Search component",
            placement: 'bottom',
            locale: { skip: "Exit Tour" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.topbar-menu-toggle-button',
            content: "You've now completed the app tour! Your next step is to go to your dashboard and copy your Bunje URL to share it with your audience on your social media link in bio.",
            title: "Share your Bunjee on social",
            locale: { last: "Done" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
    ]
};


// Static content for Add/Edit trip pages
export const contentAddTripPage = {
    placeholders: {
        locationSearch: 'Paphos, Cyprus',
        accommodationNameSearch: 'Elysium Hotel',
        experienceNameSearch: 'Scuba Diving',
        description: 'Type what you loved most about your experience on this trip...'
    }
};


// Static content for link pages
export const contentLinkPage = {
    deleteLinkWarning: {
        title: 'Delete Link',
        body: 'Are you sure you want to delete this link?',
        firstButton: 'Delete',
        secondButton: 'Cancel'
    },
    tour: [
        {
            target: '.topbar-add-button',
            content: 'Starting here, you can add a new link to your page. This will open an easy-to-navigate links menu, allowing you to choose the type of link you want to add.',
            title: "Share a link",
            placement: 'left',
            locale: { skip: "Exit Tour" },
            disableBeacon : true, // automatically start app tour
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
        {
            target: '.topbar-menu-toggle-button',
            content: "You've now completed the links tour! Your next step is to tap here to open the Menu, and go to the Trips section to share your fav stays and experiences.",
            title: "Next step",
            locale: { last: "Done" },
            styles: {
                options: {
                    zIndex: 300,
                },
            }
        },
    ]
};


// Static content for Upgrade pages
export const contentUpgradePage = {
    title: 'Select your plan',
    billingCycle: {
        selectorTitle: 'Save 20% with yearly billing'
    },
    planDetailsLink: 'Compare all features and plans',
    checkoutPage: {
        title: 'Plan and payment',
        selectedPlanTitle: 'Your Plan',
        summary: {
            title: 'Subscription Overview',
            unitPrice: ' (subscription)',
            proratedCost: 'Remaining time on new plan (inc. applied discounts)',
            proratedCredit: 'Unused time on current plan (inc. applied discounts)',
            subtotal: 'Subtotal',
            discounts: 'Discount',
            total: 'Total',
            startingBalance: 'Applied balance',
            amountDue: 'Amount due',
            endingBalance: 'Remaining balance',
        },
        purchaseButton: 'SUBSCRIBE NOW',
        purchaseSuccessMessage: "You're subscribed!",
    }
};


// Static content for popus
export const contentPopupUpgrade = {
    title: 'Upgrade and join others that unlocked this feature and more.',
    features: {
        text1: 'Hide the Bunjee logo',
        text2: 'Add your personal touch with extended page customizations',
        // text3: 'Get all-time detailed performance insights of your page',
        // text4: 'Get your own easy to setup branded project portfolio page',
        text3: 'Leverage social commerce with your own branded storefront page',
        text4: 'Earn more with lower transaction fees and exclusive travel products',
    },
    upgradeBtn: 'Learn more',
    cancelBtn: 'No thanks, maybe later',
};


// Static content for product pages
export const contentProductPage = {
    deleteProductWarning: {
        title: 'Delete Product',
        body: 'Are you sure you want to delete this product?',
        firstButton: 'Delete',
        secondButton: 'Cancel'
    }
};


// Static content for Add/Edit product pages
export const contentAddProductPage = {
    placeholders: {
        physicalProductNameSearch: 'Awesome T-shirt',
        digitalProductNameSearch: 'eBook: The power of mindful medidation',
        description: 'Tell us about this cool product ...'
    },
    options: {
        types: [
            'PHYSICAL',
            'DIGITAL'
        ],
    }
};


// Static content for NFT pages
export const contentNFTPage = {
    deleteNftWarning: {
        title: 'Remove NFT',
        body: 'Are you sure you want to remove this NFT?',
        firstButton: 'Delete',
        secondButton: 'Cancel'
    }
};


// Static content for Add/Edit NFT pages
export const contentAddNFTPage = {
    placeholders: {
        nameSearch: 'CryptoPunk #9818',
        description: 'Tell us about your favourite NFT ...'
    },
    options: {
        types: [
            'NFT',
        ],
    }
};


// Static content for checkout form
export const contentCheckoutForm = {
    billingDetails: {
        title: 'Billing details',
        name: 'Full Name',
        email: 'Email Address',
        placeholders: {
            name: 'John Doe',
            email: 'john.doe@example.com',
        },
    },
    guestCheckout: {
        title: 'SAVE YOUR DETAILS',
        subtitle1: "Use your contact details to create an account for seamless future checkouts.",
        subtitle2: "(We won't save your payment information.)",
        register: 'Save my details so I can checkout faster next time',
        login: {
            text: 'Already have an account? ',
            buttonText: 'Log in',
        },
        gdpr: {
            part1: "By signing in or creating an account, you accept our ",
            part2: " and ",
            termsLabel: 'Terms of Service',
            privacyLabel: 'Privacy Policy'
        }
    },
    coupon: {
        toggle: 'HAVE A COUPON?',
        name: 'Coupon',
        placeholder: 'Coupon code',
        error: 'Invalid coupon code',
    },
    billingAddress: {
        title: 'BILLING ADDRESS',
        line1: 'Address Line 1',
        line2: 'Address Line 2',
        city: 'City',
        state: 'State',
        postalCode: 'Postcode',
        country: 'Country',
        placeholders: {
            line1: 'One Apple',
            line2: 'Park Way',
            city: 'Cupertino',
            state: 'California',
            postalCode: '95014',
            country: 'United States',
        }
    },
    additionalNotes: {
        title: 'ADDITIONAL NOTES',
        placeholder: 'Share any additional info or requests you might have here',
    },
    billingPayment: {
        title: 'Payment Info',
        holdeName: "Cardholder's Name",
        cardDetails: 'Card Details',
        changePaymentMethod: 'Change payment method',
    },
    billingError: {
        cardError: 'Well that was awkward. Check your card.',
    },
    footer: {
        encryption: 'All payments are secure and encrypted.',
        privacy: 'Privacy policy applies',
        terms: {
            part1: 'By completing your purchase you accept our ',
            part2: ' and ',
            part3: '.',
            label: 'Terms of Service',
            labelCustomer: 'Customer Terms of Service',
        }
    },
};


// Static content for hotel booking checkout page
export const contentHotelBookingCheckoutPage = {
    purchaseButton: 'COMPLETE BOOKING',
    purchaseSuccessMessage: "You're booked in!",
    errorBackButtonText: "Let's try again",
}