import React from 'react';
import {
    Container,
    Header,
    Title,
    Body,
    AddLinkButton,
    ButtonIcon,
    ButtonText,
} from './LinksMenu.styles';
import Icon from '../../../assets/icons/Icon';
import { colorConstants } from '../../../constants';



export const LinksMenu = (props) => {
    const { show, onClick, onHide } = props;

    return (
        <Container show={show} onHide={onHide} centered={true} >
            <Header closeButton>
                <Title>Add to Bunjee</Title>
            </Header>

            <Body>
                <AddLinkButton onClick={() => onClick('SIMPLE')} >
                    <ButtonIcon>
                        <Icon type='link' size={30} customcolor={colorConstants.secondaryDark} />
                    </ButtonIcon>
                    <ButtonText>Add New Link</ButtonText>
                </AddLinkButton>

                <AddLinkButton onClick={() => onClick('SIMPLE_SECTION')} >
                    <ButtonIcon>
                        <Icon type='section_divider' size={30} customcolor={colorConstants.secondaryDark} />
                    </ButtonIcon>
                    <ButtonText>Create Links Section</ButtonText>
                </AddLinkButton>

                {/* <AddLinkButton onClick={() => onClick('GITHUB')} > // FEATURE: Add link types
                    <ButtonIcon>
                        <Icon type='social' platform='github' circle={true} color={true} size={30} />
                    </ButtonIcon>
                    <ButtonText>Add Github Link</ButtonText>
                </AddLinkButton> */}

                <AddLinkButton onClick={() => onClick('YOUTUBE')} >
                    <ButtonIcon>
                        <Icon type='social' platform='youtube' circle={true} color={true} size={30} />
                    </ButtonIcon>
                    <ButtonText>Add YouTube Link</ButtonText>
                </AddLinkButton>

                <AddLinkButton onClick={() => onClick('YOUTUBESHORT')} >
                    <ButtonIcon>
                        <Icon type='social' platform='youtube' circle={true} color={true} size={30} />
                    </ButtonIcon>
                    <ButtonText>Add YouTube Short Link</ButtonText>
                </AddLinkButton>

                {/* <AddLinkButton onClick={() => onClick('INSTAGRAMREEL')} >
                    <ButtonIcon>
                        <Icon type='social' platform='instagram' circle={true} color={true} size={30} />
                    </ButtonIcon>
                    <ButtonText>Add Instagram Reel Link</ButtonText>
                </AddLinkButton> */}

                {/* <AddLinkButton onClick={() => onClick('TIKTOK')} >
                    <ButtonIcon>
                        <Icon type='social' platform='tiktok' circle={true} color={true} size={30} />
                    </ButtonIcon>
                    <ButtonText>Add TikTok Link</ButtonText>
                </AddLinkButton> */}

                <AddLinkButton onClick={() => onClick('SPOTIFY')} >
                    <ButtonIcon>
                        <Icon type='social' platform='spotify' circle={true} color={true} size={30} />
                    </ButtonIcon>
                    <ButtonText>Add Spotify Link</ButtonText>
                </AddLinkButton>
            </Body>
      </Container>
    );
};