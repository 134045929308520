import React, { Component } from "react";

import { ScreenContainer } from './PoliciesCenter.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { Header } from '../../../components/custom/TopBar';
import { PoliciesCenterPage } from '../../../components/home/policies';
import { HomeFooter } from '../../../components/custom/Footer';
import { sitemap } from '../../../constants';



export class PoliciesCenter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scrollToSection: '', // used to scroll to particular section
        };

        this.handleScrollReset = this.handleScrollReset.bind(this);
        this.handleScrollToSection = this.handleScrollToSection.bind(this);
    }

    handleScrollReset(){
        if(this.state.scrollToSection) {
            this.setState({ ...this.state, scrollToSection: '' });
        }
    }

    handleScrollToSection(scrollToSection){
        this.setState({ ...this.state, scrollToSection });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title='Policies Center'/>

                <Header
                    border={true}
                    showLogo={true}
                    logoLinkTo={sitemap.landing.home}
                    primaryButtonText='LOG IN'
                    primaryButtonLinkTo={sitemap.auth.login}
                />

                <PoliciesCenterPage />

                <HomeFooter menu={false} />
            </ScreenContainer>
        );
    }
}