import styled from 'styled-components';
import Icon from '../../../assets/icons/Icon';

import { colorConstants } from '../../../constants';
import { Button } from '../Buttons';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Header = styled.div`
    margin-top: 24px;
    font-size: 18px;
    font-weight: 600;
    color: ${colorConstants.black};
    text-align: center;
`;

const Text = styled.div`
    margin-bottom: 24px;
    font-size: 12px;
    font-weight: 400;
    color: ${colorConstants.mediumGrey};
    text-align: center;
`;


export const NoProductsFound = (props) => {
    const { onClick } = props;

    return (
        <Container>
            <Icon type='product' size={90} />
            <Header>Add Awesome Products</Header>
            <Text>Share your favourite products with your fans</Text>
            <Button outline={true} small={true} onClick={onClick} >Add Product</Button>
        </Container>
    );
};