import React, { useRef, useEffect } from "react";
import { 
    Container,
    Section, SectionImageWrapper, SectionImage, SectionContent, SectionHeader, SectionSubheader, SectionButton, SectionLoginSpan, SectionLoginLink,
    RegistrationSection, RegistrationSectionContent, RegistrationSectionHeader, RegistrationSectionSubheader, RegistrationSectionArrowWrapper, RegistrationSectionRightArrow, RegistrationSectionDownArrow, RegistrationSectionImage,
    RegistrationSectionForm, RegistrationSectionFormTitle
 } from './NFTLandingPage.styles';
import { NFTLandingPageForm } from './NFTLandingPageForm';

import { generateFirebasePhotoLink } from '../../../utils';
import { sitemap, contentNFTLandingPage } from "../../../constants";


export const NFTLandingPage = (props) => {
    const {
        alert,
        handleScrollReset,
        scrollToSection,
        handleScrollToForm,
        handleChange,
        handleSubmit,
        handleCaptcha,
        registering,
        user,
        submitted,
        captcha,
        isEmail,
        isLink,
    } = props;

    // used to scroll page to registration section
    const registrationSectionRef = useRef(null);

    // set banner images
    const heroImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Fnft%2Fnft-landing-page-1.gif?alt=media');
    const sectionTwoImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Fnft%2Fnft-landing-page-2.png?alt=media');
    const sectionThreeImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Fnft%2Fnft-landing-page-3.png?alt=media');
    const registrationSectionImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Fnft%2Fnft-middle-section-image.jpg?alt=media');


    useEffect(() => {

        // Scroll to page section
        if(scrollToSection) {
          switch(scrollToSection){
            // scroll to Registration Form
            case 'registration-form': {
              if(registrationSectionRef.current) {
                registrationSectionRef.current.scrollIntoView();
                handleScrollReset();
              }
              break;
            }
    
            default:
              break;
          }
        }
  
        return () => {
          // execute code on component unmount here
        };
      }, [handleScrollReset, scrollToSection]);


    return (
        <Container>
            <Section>
                <SectionContent left='true' >
                    <SectionHeader>{contentNFTLandingPage.hero.header}</SectionHeader>
                    <SectionSubheader>{contentNFTLandingPage.hero.subheader}</SectionSubheader>
                    <SectionButton gradient={true} midCorner={true} onClick={() => handleScrollToForm('Hero Apply Button')}>{contentNFTLandingPage.hero.primaryCta}</SectionButton>
                    <div>
                        <SectionLoginSpan>{contentNFTLandingPage.hero.secondaryCta.part1}</SectionLoginSpan>
                        <SectionLoginLink to={sitemap.auth.login} >{contentNFTLandingPage.hero.secondaryCta.part2}</SectionLoginLink>
                    </div>
                </SectionContent>

                <SectionImageWrapper right='true' >
                    <SectionImage src={heroImageUrl} alt="Bunjee for NFT owners Product Image" />
                </SectionImageWrapper>
            </Section>


            <Section>
                <SectionImageWrapper left='true' >
                    <SectionImage src={sectionTwoImageUrl} alt="Bunjee for NFT owners Product Image 2" />
                </SectionImageWrapper>

                <SectionContent right='true' >
                    <SectionHeader>{contentNFTLandingPage.sectionTwo.header}</SectionHeader>
                    <SectionSubheader>{contentNFTLandingPage.sectionTwo.subheader}</SectionSubheader>
                    <SectionButton gradient={true} midCorner={true} onClick={() => handleScrollToForm('Section Two Apply Button')}>{contentNFTLandingPage.sectionTwo.primaryCta}</SectionButton>
                </SectionContent>
            </Section>


            <Section>
                <SectionContent left='true' >
                    <SectionHeader>{contentNFTLandingPage.sectionThree.header}</SectionHeader>
                    <SectionSubheader>{contentNFTLandingPage.sectionThree.subheader}</SectionSubheader>
                    <SectionButton gradient={true} midCorner={true} onClick={() => handleScrollToForm('Section Three Apply Button')}>{contentNFTLandingPage.sectionThree.primaryCta}</SectionButton>
                </SectionContent>

                <SectionImageWrapper right='true' >
                    <SectionImage src={sectionThreeImageUrl} alt="Bunjee for NFT owners Product Image 3" />
                </SectionImageWrapper>
            </Section>


            <RegistrationSection ref={registrationSectionRef}>

                <RegistrationSectionContent>
                    <RegistrationSectionHeader>{contentNFTLandingPage.cta.header}</RegistrationSectionHeader>
                    <RegistrationSectionSubheader>{contentNFTLandingPage.cta.text}</RegistrationSectionSubheader>
                    
                    <RegistrationSectionArrowWrapper>
                        <RegistrationSectionRightArrow />
                        <RegistrationSectionDownArrow />
                    </RegistrationSectionArrowWrapper>
                </RegistrationSectionContent>

                <RegistrationSectionForm>
                    <RegistrationSectionFormTitle>{contentNFTLandingPage.form.title}</RegistrationSectionFormTitle>

                    <NFTLandingPageForm
                        alert={alert}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleCaptcha={handleCaptcha}
                        registering={registering}
                        user={user}
                        submitted={submitted}
                        captcha={captcha}
                        isEmail={isEmail}
                        isLink={isLink}
                    />
                </RegistrationSectionForm>

                <RegistrationSectionImage src={registrationSectionImageUrl} alt="Bunjee for NFT owners Banner"/>

            </RegistrationSection>
        </Container>
    );
};