import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { Button } from '../Buttons';
import { colorConstants, deviceConstants } from '../../../constants';


export const Container = styled(Modal)`
    height: 100vh;

    padding: 0;
    margin: 0;
    background: ${colorConstants.white};

    .modal-dialog {
        max-width: 100%;
        margin: 0;
    }

    .modal-content {
        margin: 0;
        padding: 0;
        border-radius: 0;
        border: none;
    }

    /* Revert all above settings to style as a popup */
    @media ${deviceConstants.tablet} {
        height: 100%;
        background: none;

        .modal-dialog {
            max-width: 500px;
            margin: 1.75rem auto;
        }
    
        .modal-content {
            border: 1px solid rgba(0,0,0,.2);
            border-radius: 0.3rem;
        }
    }
`;

export const Header = styled(Modal.Header)`
    border-bottom: none;
    height: 56px;
    background: ${colorConstants.white};

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    @media ${deviceConstants.tablet} {
        position: static;
    }
`;

export const Title = styled(Modal.Title)`
    margin-right: 32px;

    font-weight: 700;
    font-size: 19px;
    background: ${colorConstants.gradientForText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Body = styled.div`
    height: calc(100vh - 56px);
    margin-top: 56px;
    padding: 0px;
    border-radius: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.tablet} {
        height: calc(100vh - 35vh);
        margin-top: 0px;
        padding: 16px;
    }
`;

export const DatePickerSection = styled.div`
    margin-bottom: 16px;
    padding: 50px 0 80px 0;

    @media ${deviceConstants.tablet} {
        padding: 0;
        display: block;

        overflow: hidden;
        overflow-y: auto;
        overflow-x: hidden;
        
        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
            border-radius: 10px;
        }
        
        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
        }
    }

    /*** Date Picker Styling ***/

    .rdp-multiple_months {
        margin: 0;

        .rdp-months {
            display: flex;
            flex-direction: column;
    
            & > * {
                margin: 0;
                margin-bottom: 12px;
            }
    
            & > *:last-child {
                margin-bottom: 0px;
            }
    
            .rdp-caption {
                text-align: start;
    
                h2 {
                    font-size: 100%;
                }
            }
        }
    }

    .rdp-cell { 
        font-size: 14px !important;
        font-weight: 500 !important;
        color: ${colorConstants.black} !important;
    }

    .rdp-button_reset:hover:not([disabled]) {
        background: ${colorConstants.primaryFaded};
    }

    .rdp-button_reset:focus:not([disabled]) {
        color: ${colorConstants.white} !important;
        background: ${colorConstants.black} !important;
        border-color: ${colorConstants.black} !important;
    }


    /* Today's day */
    .rdp-day_today:not([disabled]) {
        color: ${colorConstants.primaryDark} !important;
    }


    /* Selected range date */
    .rdp-day_range_start:not([disabled]), .rdp-day_range_end:not([disabled]) {
        color: ${colorConstants.white} !important;
        background: ${colorConstants.black} !important;
    }

    .rdp-day_range_middle:not([disabled]) {
        color: ${colorConstants.black} !important;
        background: ${colorConstants.verySoftGrey} !important;
        border-color: ${colorConstants.verySoftGrey} !important;
    }

    .rdp-day_range_middle:hover:not([disabled]) {
        border-color: ${colorConstants.black} !important;
    }
`;

export const WeekdaysSection = styled.table`
    background: ${colorConstants.white};

    position: -webkit-fixed; /* Safari */
    position: -moz-fixed; /* Firefox */
    position: -ms-fixed; /* Edge */
    position: fixed;
    top: 51px;
    z-index: 2;

    @media ${deviceConstants.tablet} {
        position: -webkit-sticky; /* Safari */
        position: -moz-sticky; /* Firefox */
        position: -ms-sticky; /* Edge */
        position: sticky;
        top: -5px;
    }

    .rdp-head_cell {
        width: 40px;
        padding: 8px 0px 16px 0px;
        margin: 0px;

        color: ${colorConstants.black};
        font-size: 14px;
        font-weight: 600;
        text-align: center;
    }
`;

export const SubmitButton = styled(Button)`
    width: 80%;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);

    position: -webkit-fixed; /* Safari */
    position: -moz-fixed; /* Firefox */
    position: -ms-fixed; /* Edge */
    position: fixed;
    bottom: 16px;
    z-index: 2;

    @media ${deviceConstants.tablet} {
        position: -webkit-sticky; /* Safari */
        position: -moz-sticky; /* Firefox */
        position: -ms-sticky; /* Edge */
        position: sticky;
        bottom: 0px;
        width: 70%;
    }
`;