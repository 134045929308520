import React, { useState, useEffect } from 'react';
import { colord } from 'colord';
import { ColorPicker } from './ColorPicker';
import {
    Container,
    ColorPickerContainer,
    ColorPickerContainerColumnLeft,
    ColorPickerContainerColumnRight,
    ColorPreviewContainer,
    ColorPreview,
    ColorPreviewGradient,
    ColorPreviewTextContainer,
    ColorPickerText,
    ManualColorPickerContainer,
    ManualColorPickerContainerColumnLeft,
    ManualColorPickerContainerColumnRight,
    InputBoxGradientBorder,
    StyledFormControl,
    InputBoxIcon,
    AngleIcon,
} from './GradientColorInput.styles';



/**
 * Method to construct string value of linear gradient css based on provided components
 * 
 * @param {JSON Object} values Object with linear gradient values
 * @returns String with linear gradient css value
 */
export function constructLinearGradientColorString(values) {
    try {
        let angle = values.angle;
        let position1 = values.color1Position;
        let position2 = values.color2Position;

        // override position and style based on mode
        if(values.mode === 'COVER') {
            const angleNum = Number(angle);
            angle = angleNum ? angleNum <= 180 ? '163' : '197' : '163';
            const pos1Num = Number(position1);
            position1 = pos1Num ? pos1Num <= 40 ? '50' : '55' : '50';
            position2 = '100';
        }

        return 'linear-gradient('
                + angle + 'deg, '
                + values.color1 + ' ' + position1 + '%, '
                + values.color2 + ' ' + position2 + '%)';
    } catch(err) {
        return '';
    }
}


/**
 * Method to extract all components of a linear gradient css value string
 * 
 * @param {String} gradient String of linear gradient css value
 * @returns JSON Object with all components extracted from linear gradient string
 */
export function decontructLinearGradientColorString(gradient){
    try{
        if(!gradient.includes('linear-gradient')) return {};
        // linear-gradient(0deg, colorConstants.white 0%, colorConstants.white 12%, rgba(0,0,0,0.1) 55%)
        // linear-gradient(166.85deg, #0093E9 47.12%, #80D0C7 99.15%)
        let values = gradient.replace('linear-gradient(', '');
        values = values.replace(')', '');
        // 166.85deg, #0093E9 47.12%, #80D0C7 99.15%
        values = values.split(', ');
        // get gradient angle
        let angle = values[0].replace('deg', '');
        // get color 1 values
        let c1 = values[1].split(' ');
        let color1 = c1[0];
        let color1Position = c1[1].replace('%', '');
        // get color 2 values
        let c2 = values[2].split(' ');
        let color2 = c2[0];
        let color2Position = c2[1].replace('%', '');
        // return all components
        return { angle, color1, color1Position, color2, color2Position };
    } catch(err){
        return {};
    }
}



export const GradientColorInput = (props) => {
    const { name, savedGradientColor, mode, handleGradientColorPick, deactivate } = props;

    // component state
    const [gradientString, setGradientString] = useState('');
    const [angle, setAngle] = useState('180');
    const [color1, setColor1] = useState('#C2C2C2');
    const [color1Position, setColor1Position] = useState('0');
    const [color2, setColor2] = useState('#C2C2C2');
    const [color2Position, setColor2Position] = useState('100');
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [input3, setInput3] = useState('');
    const [colorPicker1, setColorPicker1] = useState(false);
    const [colorPicker2, setColorPicker2] = useState(false);

    // initialize state
    useEffect(() => {
        setGradientString(savedGradientColor);

        if(savedGradientColor) {
            let gradient = {};
            gradient = decontructLinearGradientColorString(savedGradientColor);

            if(gradient.angle) {
                setAngle(gradient.angle);
                setInput3(gradient.angle);
            } else if(gradient.color1 || gradient.color2) {
                setInput3('180');
            }

            if(gradient.color1) {
                setColor1(gradient.color1);
                setInput1(gradient.color1);

                if(gradient.color1Position) setColor1Position(gradient.color1Position);
            }

            if(gradient.color2) {
                setColor2(gradient.color2);
                setInput2(gradient.color2);

                if(gradient.color2Position) setColor2Position(gradient.color2Position);
            }

        } else if(savedGradientColor === '') {
            setAngle('180');
            setInput3('');

            setColor1('#C2C2C2');
            setInput1('');
            setColor1Position('0');
            
            setColor2('#C2C2C2');
            setInput2('');
            setColor2Position('100');
        }
    }, [savedGradientColor]);

    function inputEvent(event, option) {
       event.preventDefault();
       const { value } = event.target;
       colorPick(value, option);
    }

    function getStateGradientComponents(){
        return { mode, angle, color1, color1Position, color2, color2Position };
    }

    function colorPick(value, option) {
        switch(option) {
            case '1': {
                // set type input1
                setInput1(value);

                // get color pick value
                let c1 = value;
                if(c1 === '' || !colord(c1).isValid()) c1 = '#C2C2C2'; // reset color pick 1

                // set new color 1
                setColor1(c1);
                let gradient = constructLinearGradientColorString({ ...getStateGradientComponents(), color1: c1 });
                setGradientString(gradient);
                if(handleGradientColorPick) handleGradientColorPick({ color1: c1, color2, gradient }, name);

                break;
            }

            case '2': {
                // set type input2
                setInput2(value);

                // get color pick value
                let c2 = value;
                if(c2 === '' || !colord(c2).isValid()) c2 = '#C2C2C2'; // reset color pick 2

                // set new color 2
                setColor2(c2);
                let gradient = constructLinearGradientColorString({ ...getStateGradientComponents(), color2: c2 });
                setGradientString(gradient);
                if(handleGradientColorPick) handleGradientColorPick({ color1, color2: c2, gradient }, name);

                break;
            }

            case 'angle': {
                // set type input3
                setInput3(value);

                // set new angle
                setAngle(value || '180');
                let gradient = constructLinearGradientColorString({ ...getStateGradientComponents(), angle: value || '180' });
                setGradientString(gradient);
                if(handleGradientColorPick) handleGradientColorPick({ color1, color2, gradient }, name);

                break;
            }

            default:
                break;
        }
    }

    return (
        <Container>
            <ColorPickerContainer>
                <ColorPickerContainerColumnLeft>
                    <ColorPreviewTextContainer>
                        <ColorPickerText>Color 1</ColorPickerText>
                        <ColorPickerText>Color 2</ColorPickerText>
                    </ColorPreviewTextContainer>
                    
                    <ColorPreviewContainer>
                        <ColorPreview color={color1} onClick={() => setColorPicker1(!deactivate && !colorPicker1)} deactivate={deactivate} />
                        <ColorPreviewGradient color={gradientString} />
                        <ColorPreview color={color2} onClick={() => setColorPicker2(!deactivate && !colorPicker2)} deactivate={deactivate} />
                    </ColorPreviewContainer>
                </ColorPickerContainerColumnLeft>

                {savedGradientColor &&
                <ColorPickerContainerColumnRight>
                    <ColorPickerText>Angle</ColorPickerText>

                    <InputBoxGradientBorder icon={true} deactivate={deactivate} >
                        <StyledFormControl className='input-box' type='text' name='angle-input' placeholder='angle' value={input3} onChange={(e) => inputEvent(e, 'angle')} disabled={deactivate}/>
                        <InputBoxIcon deactivate={deactivate} className='input-icon' >
                            <AngleIcon size={20} />
                        </InputBoxIcon>
                    </InputBoxGradientBorder>
                </ColorPickerContainerColumnRight> }
            </ColorPickerContainer>

            {savedGradientColor &&
            <ManualColorPickerContainer>
                <ManualColorPickerContainerColumnLeft>
                    <ColorPickerText>Color 1</ColorPickerText>

                    <InputBoxGradientBorder deactivate={deactivate} >
                        <StyledFormControl type='text' name='hex-color-input-1' placeholder='Hex color 1' value={input1} onChange={(e) => inputEvent(e, '1')} disabled={deactivate}/>
                    </InputBoxGradientBorder>
                </ManualColorPickerContainerColumnLeft>

                <ManualColorPickerContainerColumnRight>
                    <ColorPickerText>Color 2</ColorPickerText>

                    <InputBoxGradientBorder deactivate={deactivate} >
                        <StyledFormControl type='text' name='hex-color-input-2' placeholder='Hex color 2' value={input2} onChange={(e) => inputEvent(e, '2')} disabled={deactivate}/>
                    </InputBoxGradientBorder>
                </ManualColorPickerContainerColumnRight>
            </ManualColorPickerContainer> }

           {(colorPicker1 || colorPicker2) &&
           <ColorPicker
               savedColor={colorPicker1 ? color1 : color2}
               handleHide={() => {
                    setColorPicker1(false);
                    setColorPicker2(false);
               }}
               handleColorPick={(val) => colorPick(val, colorPicker1 ? '1' : colorPicker2 ? '2' : '')} /> }
        </Container>
    );
};