// export const apiPath = 'api';

// export const APP_HOST = process.env.APP_HOST || 'localhost';
// export const APP_PORT = process.env.APP_PORT || 3000;
// export const HOST = `${APP_HOST}:${APP_PORT}/`;

// export const API_URL = `http://${HOST}${apiPath}`;



// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyCHvZikbsbmMgnsDofmdf6_NcSDgTg2iyU",
    authDomain: "auth.bunj.ee", // custom domain is configured, you just enable this authDomain. Note, that it does not work on Safari 16.1+ on macOS, iOS 16.1+, Firefox 109+
    // authDomain: "bunjee-app.firebaseapp.com",
    projectId: "bunjee-app",
    storageBucket: "bunjee-app.appspot.com",
    messagingSenderId: "22065242673",
    appId: "1:22065242673:web:7e500d334c32e8eaf52b10",
    // measurementId: "G-YLLCRET4NP"
};

// For Google Tag Manager
export const gtmConfig = {
    code: 'GTM-M965Z6P',
    devEnvAuth: 'sZP1Om7pJgTgKdOeGET8Xg',
    devEnvName: 'env-5',
};