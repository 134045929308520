import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

import { colorConstants, deviceConstants } from "../../../constants";


export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const ScrollBackButton = styled(ChevronLeft)`
    display: none;
    padding: 4px;
    margin-right: 4px;
    color: ${props => props.color || colorConstants.black} !important;

    :hover {
        cursor: pointer;
    }

    @media ${deviceConstants.laptop} {
        display: block;
        margin-left: -12px;
        visibility: ${props => props.hide ? 'hidden' : 'visible'};
    }
`;

export const ScrollForwardButton = styled(ChevronRight)`
    display: none;
    padding: 4px;
    margin-left: 4px;
    color: ${props => props.color || colorConstants.black} !important;

    :hover {
        cursor: pointer;
    }

    @media ${deviceConstants.laptop} {
        display: block;
        margin-right: -12px;
        visibility: ${props => props.hide ? 'hidden' : 'visible'};
    }
`;

export const MenuItems = styled.div`
    width: 100%;
    padding: 8px 0px;
    font-family: ${props => props.type || 'inherit'} !important;
    color: ${props => props.color || colorConstants.black} !important;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    overflow-x: scroll;
    // white-space: nowrap;

    @media ${deviceConstants.laptop} {
        overflow-x: hidden;
        // flex-wrap: wrap;

        // ::-webkit-scrollbar {
        //     height: 4px;
        //     width: 4px;
        //     background: ${colorConstants.mediumGrey};
        //     border-radius: 10px;
        // }
        // ::-webkit-scrollbar-thumb:horizontal {
        //     background: ${colorConstants.darkGrey};
        //     border-radius: 10px;
        // }
        // ::-webkit-scrollbar-thumb:hover {
        //     cursor: pointer;
        // }
    }

    & > * {
        margin-right: 4px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const Button = styled.div`
    padding: 2px 8px;
    border-radius: 500px;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .fader {
        background: ${props => props.active ? (props.hastheme ? colorConstants.softGrey : colorConstants.gradientHorizontal) : 'none'};
    }

    .icon {
        display: ${props => props.active ? 'initial' : 'none'};

        z-index: 4;
    }

    :hover {
        cursor: pointer;

        .fader {
            background: ${props => props.active ? (props.hastheme ? colorConstants.softGrey : colorConstants.gradientHorizontal) : (props.hastheme ? colorConstants.verySoftGrey : colorConstants.primaryFaded)};
        }
    }
`;

export const Background = styled.div`
    border-radius: 500px;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0.4;
`;

export const Label = styled.div`
    font-weight: 600;
    font-size: 11px;
    margin-left: 4px;

    z-index: 4;
`;