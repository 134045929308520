import { customerReducerConstants } from "../constants";
import { customerService } from "../services";
import { alertActions } from './';


export const customerActions = {
    getByUserId,
    update,
    clear
};


function getByUserId(id, isGuestCheckoutSession) {
    return dispatch => {
        dispatch(request());

        customerService.getByUserId(id, isGuestCheckoutSession)
            .then(
                customer => dispatch(success(customer)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: customerReducerConstants.GETBYUSERID_REQUEST } }
    function success(customer) { return { type: customerReducerConstants.GETBYUSERID_SUCCESS, customer } }
    function failure(error) { return { type: customerReducerConstants.GETBYUSERID_FAILURE, error } }
}

function update(customerId, data) {
    return async dispatch => {
        dispatch(request());

        await customerService.update(customerId, data)
                                .then(
                                    customer => { 
                                        dispatch(success(customer));
                                        dispatch(alertActions.success('Update successful'));
                                    },
                                    error => {
                                        dispatch(failure(error.toString()));
                                        dispatch(alertActions.error(error.toString()));
                                    }
                                );
    };

    function request() { return { type: customerReducerConstants.UPDATE_REQUEST } }
    function success(customer) { return { type: customerReducerConstants.UPDATE_SUCCESS, customer } }
    function failure(error) { return { type: customerReducerConstants.UPDATE_FAILURE, error } }
}

function clear() {
    return { type: customerReducerConstants.CLEAR_ALL };
}