import { googlePlacesService } from "../services";

export const googlePlacesActions = {
    getSuggestions,
    getNearby,
    getDetails,
    getDetailsPreview,
    createInvite
};

function getSuggestions(input, type, isPublic) {
    return googlePlacesService.getSuggestions({ input, type, isPublic });
}

function getNearby(lat, lng, radius, type, keyword) {
    return googlePlacesService.getNearby({ lat, lng, radius, type, keyword });
}

function getDetails(id) {
    return googlePlacesService.getDetails(id);
}

function getDetailsPreview(id, data) {
    return googlePlacesService.getDetailsPreview(id, data);
}

function createInvite(data) {
    return googlePlacesService.createInvite(data);
}