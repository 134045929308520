import { useMemo } from 'react';
import {
    Container,
    Photo,
    ContentColumn,
    Content,
    Name,
    Location,
    AnalyticsSection,
    Stat,
    Views,
    Saves,
    ButtonsSection,
    ButtonSectionLeft,
    ButtonSectionRight,
    EditButton,
    DeleteButton,
    EnableToggle
} from './TripsListItem.styles';
import SearchListItem from './SearchListItem';
import { SectionLabelInput } from '../custom/FormInput/Links/SectionLabelInput';
import { StarSystem, GoogleRating } from '../custom/Rating';
import { DragHandle } from "../custom/DragHandle";

import { colorConstants } from '../../constants';



const TripsListItem = ({
    data,
    dragHandleProps,
    handleView,
    handleEdit,
    handleDelete,
    handleEnabled,
    ...rest
}) => {

    const hasPartner = useMemo(() => data && data.partner, [data]);
    const hasGoogle = useMemo(() => data && data.google, [data]);


    /*** SEARCH LINK COMPONENT ***/
    if(data.isSearch) return <SearchListItem
                                data={data}
                                dragHandleProps={dragHandleProps}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                {...rest} />;

    /*** SECTION LABEL COMPONENT ***/
    if(data.isSectionLabel) return <SectionLabelInput
                                        data={data}
                                        dragHandleProps={dragHandleProps}
                                        handleLabelChange={handleEdit}
                                        handleDelete={handleDelete} />;

    return (
        <Container {...rest} >
            <Photo onClick={() => handleView(data.id, data.customUrl)} src={data.tripPhotoUrls[0]} />

            <ContentColumn>
                <Content onClick={() => handleView(data.id, data.customUrl)}>
                    <Name onClick={() => handleView(data.id, data.customUrl)}>{data.name}</Name>

                    { hasPartner && data.partner.stars && <StarSystem onClick={() => handleView(data.id, data.customUrl)} rating={data.partner.stars} color='gold' /> }

                    <Location onClick={() => handleView(data.id, data.customUrl)}>{data.city + ', ' + data.country}</Location>

                    { (!hasPartner || !data.partner.stars) && hasGoogle && data.google.rating && <GoogleRating type='S4' rating={data.google.rating} reviewsCount={data.google.userRatingsTotal} /> }

                    { data.analytics && <AnalyticsSection onClick={() => handleView(data.id, data.customUrl)}>
                        <Stat>{data.analytics.views}<Views size={11} /></Stat>
                        <Stat>{data.analytics.saves}<Saves size={11} /></Stat>
                        <Stat>{data.analytics.earnings}</Stat>
                    </AnalyticsSection> }
                </Content>

                <ButtonsSection>
                    <ButtonSectionLeft>
                        <EditButton size={22} onClick={() => handleEdit(data.id, data.customUrl)} />
                        <DeleteButton size={22} onClick={() => handleDelete(data.id, data.customUrl)} />
                    </ButtonSectionLeft>

                    <ButtonSectionRight>
                        { dragHandleProps && <DragHandle size={24} customcolor={colorConstants.darkGrey} {...dragHandleProps} /> }
                        <EnableToggle type="switch" name={'enable-trip-' + data.id} checked={data.enabled} onChange={() => handleEnabled(data.id, !data.enabled)} />
                    </ButtonSectionRight>
                </ButtonsSection>
            </ContentColumn>
        </Container>
    );
};

export default TripsListItem;