import React, { useEffect } from "react";
import WebFont from 'webfontloader';

import {
    Container, Body, Footer,
    TopSection,
    CoverSection,
    CoverImage,
    CoverImageFader,
    UserInfoSection,
    Name,
    Bio,
    StyledLogo,
} from './ProfilePage.styles';
import { Avatar } from '../custom/Avatar';
import List from './List';
import Overview from './Overview';
import { FiltersMenu } from '../custom/Filter';
import { SocialMediaSection } from '../custom/Buttons';
import { useWindowSize } from '../../utils';


const ProfilePage = (props) => {
    const { profile, links, trips, products, nfts, overview, handleOpenLink, handleViewTrip, handleViewProduct, handleViewNFT, handleFilter } = props;

    // detect screen size
    const [width] = useWindowSize();

    // check if has overview page data
    const hasOverview = overview && overview.length > 0;

    // set page style
    let horizontalProfile = profile.pageOptions.pageStyle === 'HORIZONTAL_LEFT' || profile.pageOptions.pageStyle === 'HORIZONTAL_RIGHT';
    let horizontalProfileLeft = profile.pageOptions.pageStyle === 'HORIZONTAL_LEFT';
    // set page buttons
    let buttons = {
        shape: profile.pageOptions.pageButtonShape,
        style: profile.pageOptions.pageButtonStyle,
        color: profile.pageOptions.pageButtonColor,
        fontColor: profile.pageOptions.pageButtonFontColor
    };
    // set page fonts
    let fonts = {
        type: profile.pageOptions.pageFontType,
        color: profile.pageOptions.pageFontColor
    };

    useEffect(() => {
        if(fonts.type && fonts.type !== 'NONE' && fonts.type !== '') {
          WebFont.load({ google: { families: [fonts.type] } });
        }
        return () => {
          // execute code on component unmount here
        };
      }, [fonts.type]);

    return (
        <Container cover={profile.pageOptions.pageCoverImage}>
          <Body>
            <TopSection cover={profile.pageOptions.pageCoverImage} horizontal={horizontalProfile} bottomSocial={!profile.pageOptions.pageSocialIconLocationTop}>

                { profile.pageOptions.pageCoverImage &&
                <CoverSection>
                  <CoverImage src={profile.pageOptions.pageCoverImage} />
                  <CoverImageFader color={profile.pageOptions.pageBackgroundColor} dark={profile.pageOptions.pageThemeDark} />
                </CoverSection> }

                {!profile.pageOptions.pageCoverImage && (!horizontalProfile || horizontalProfileLeft) &&
                <Avatar
                  mode="view"
                  imageSize={100}
                  url={profile.profilePhotoUrl}
                  border={profile.pageOptions.pagePhotoStyle === 'BORDER'}
                  borderColor={profile.pageOptions.pagePhotoBorderColor} /> }

                <UserInfoSection horizontal={horizontalProfile} horizontalLeft={horizontalProfileLeft} >
                    <Name fontStyles={fonts} horizontal={horizontalProfile} >{profile.displayName || profile.username}</Name>

                    <Bio fontStyles={fonts} horizontal={horizontalProfile} >{profile.bio}</Bio>
                    
                    {profile.pageOptions.pageSocialIconLocationTop &&
                    <SocialMediaSection
                      social={profile.social}
                      website={profile.website}
                      alignIcons={horizontalProfile ? 'flex-start' : 'center'}
                      iconSize={20}
                      circleIcons={profile.pageOptions.pageSocialIconShape === 'FILL'}
                      colorIcons={!profile.pageOptions.pageSocialIconColor}
                      customColor={profile.pageOptions.pageSocialIconColor} /> }
                </UserInfoSection>

                {!profile.pageOptions.pageCoverImage && horizontalProfile && !horizontalProfileLeft &&
                <Avatar
                  mode="view"
                  imageSize={100}
                  url={profile.profilePhotoUrl}
                  border={profile.pageOptions.pagePhotoStyle === 'BORDER'}
                  borderColor={profile.pageOptions.pagePhotoBorderColor} /> }
            </TopSection>

            <FiltersMenu
                hasTheme={true}
                options={{ ...profile.pageOptions, metadata: profile.pageData.metadata.links }}
                handleFilter={handleFilter} />

            { !hasOverview &&
            <List
                width={width}
                trips={trips}
                links={links}
                products={products}
                nfts={nfts}
                handleOpenLink={handleOpenLink}
                handleViewTrip={handleViewTrip}
                handleViewProduct={handleViewProduct}
                handleViewNFT={handleViewNFT}
                customizations={{ fonts, buttons, bottomSocial: !profile.pageOptions.pageSocialIconLocationTop, pageThemeDark: profile.pageOptions.pageThemeDark }} /> }
            
            { hasOverview &&
            <Overview
                width={width}
                overview={overview}
                handleOpenLink={handleOpenLink}
                handleViewTrip={handleViewTrip}
                handleViewProduct={handleViewProduct}
                handleViewNFT={handleViewNFT}
                metadata={profile.pageData.metadata.links}
                customizations={{ fonts, buttons, bottomSocial: !profile.pageOptions.pageSocialIconLocationTop, pageThemeDark: profile.pageOptions.pageThemeDark }} /> }
              
            {!profile.pageOptions.pageSocialIconLocationTop &&
            <SocialMediaSection
              social={profile.social}
              website={profile.website}
              alignIcons={'center'}
              iconSize={20}
              circleIcons={profile.pageOptions.pageSocialIconShape === 'FILL'}
              colorIcons={!profile.pageOptions.pageSocialIconColor}
              customColor={profile.pageOptions.pageSocialIconColor} /> }
          </Body>

          <Footer>
            {profile.pageOptions.pageBrandingBunjeeLogo &&
            <StyledLogo color={profile.pageOptions.pageFontColor || null} /> }
          </Footer>
        </Container>
    );
};

export default ProfilePage;