import { hotelBookingReducerConstants } from '../constants';

export function hotelBooking(state = {}, action) {

  switch (action.type) {

    case hotelBookingReducerConstants.CHECKOUT_CONFIG_REQUEST: {
        return { ...state, loadingConfigs: true };
    }

    case hotelBookingReducerConstants.CHECKOUT_CONFIG_SUCCESS: {
        const { loadingConfigs, ...rest } = state;
        return { ...rest, config: action.config };
    }

    case hotelBookingReducerConstants.CHECKOUT_CONFIG_FAILURE: {
        const { loadingConfigs, ...rest } = state;
        return { ...rest, config: { error: action.error } };
    }

    case hotelBookingReducerConstants.CHECKOUT_CONFIG_WITH_COUPON_REQUEST: {
        return { ...state, loadingConfigsWithCoupon: true };
    }

    case hotelBookingReducerConstants.CHECKOUT_CONFIG_WITH_COUPON_SUCCESS: {
        const { loadingConfigsWithCoupon, ...rest } = state;
        const { couponError, ...configWithoutError } = state.config;
        return { ...rest, config: { ...configWithoutError, ...action.coupon } };
    }

    case hotelBookingReducerConstants.CHECKOUT_CONFIG_WITH_COUPON_FAILURE: {
        const { loadingConfigsWithCoupon, ...rest } = state;
        const { coupon, discount, discountPercentOff, ...configWithoutCoupon } = state.config;
        return { ...rest, config: { ...configWithoutCoupon, couponError: action.error } };
    }

    case hotelBookingReducerConstants.PAYMENT_CONFIG_REQUEST:
        return { ...state, loadingPaymentConfigs: true };

    case hotelBookingReducerConstants.PAYMENT_CONFIG_SUCCESS: {
        const { loadingPaymentConfigs, ...rest } = state;
        if(state.config && state.config.id === action.config.id) {
            return { ...rest, config: { ...action.config, ...state.config } };
        } else {
            return { ...rest, config: { error: 'Could not load booking configuration' } };
        }
    }

    case hotelBookingReducerConstants.PAYMENT_CONFIG_FAILURE: {
        const { loadingPaymentConfigs, ...rest } = state;
        return { ...rest, config: { error: action.error } };
    }
    
    case hotelBookingReducerConstants.GETALL_REQUEST:
        return { ...state, loading: true };

    case hotelBookingReducerConstants.GETALL_SUCCESS:
        return { bookings: action.bookings };

    case hotelBookingReducerConstants.GETALL_FAILURE: {
        let { loading, ...stateCopy } = state; // remove loading flag
        return { ...stateCopy, error: action.error };
    }

    case hotelBookingReducerConstants.GETBYID_REQUEST:
        return { loading: true };
    
    case hotelBookingReducerConstants.GETBYID_SUCCESS:
        return { ...action.booking };

    case hotelBookingReducerConstants.GETBYID_FAILURE:
        return { error: action.error };

    case hotelBookingReducerConstants.SENDCONFIRMATION_REQUEST:
        return { ...state, confirmationEmailSent: false, sendingConfirmationEmail: true };
    
    case hotelBookingReducerConstants.SENDCONFIRMATION_SUCCESS: {
        const { sendingConfirmationEmail, ...stateCopy } = state;
        return { ...stateCopy, confirmationEmailSent: true };
    }

    case hotelBookingReducerConstants.SENDCONFIRMATION_FAILURE: {
        const { sendingConfirmationEmail, ...stateCopy } = state;
        return { ...stateCopy, confirmationEmailSent: false };
    }

    case hotelBookingReducerConstants.CANCEL_REQUEST:
        return { ...state, cancellationRequested: false, cancelling: true };
    
    case hotelBookingReducerConstants.CANCEL_SUCCESS: {
        const { cancelling, ...stateCopy } = state;
        return { ...stateCopy, cancellationRequested: true };
    }

    case hotelBookingReducerConstants.CANCEL_FAILURE: {
        const { cancelling, ...stateCopy } = state;
        return { ...stateCopy, cancellationRequested: false };
    }

    case hotelBookingReducerConstants.RESET_CHECKOUT_CONFIG: {
        const { loadingConfigs, config, ...rest } = state;
        return { ...rest };
    }
    
    case hotelBookingReducerConstants.CLEAR_ALL:
        return {};

    default:
        return state;
  }
}