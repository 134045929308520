import React, { Component } from "react";
import { connect } from 'react-redux';
import validator from 'validator';
import qs from 'qs';

import { authActions } from '../../actions';
import { ScreenContainer } from './RegisterContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { RegisterForm } from '../../components/auth';
import { Header } from '../../components/custom/TopBar';
import { history, analytics } from '../../utils';
import { sitemap } from '../../constants';


class RegisterContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                name: '',
                email: '',
                password: '',
                passwordConfirm: '',
                // code: '' // invite code
            },
            submitted: false,
            captcha: false,
            isStrongPassword: false,
            isEmail: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitGoogle = this.handleSubmitGoogle.bind(this);
        this.handleSubmitFacebook = this.handleSubmitFacebook.bind(this);
        this.handleCaptcha = this.handleCaptcha.bind(this);
    }

    componentDidMount(){
        // // redirect to homepage if no queries
        // if (!this.props.location.search) {
        //     history.push({
        //         pathname: sitemap.landing.home,
        //         state: {
        //             from: sitemap.auth.register
        //         }
        //     });
        //     return;
        // }

        // load csrf token in cookies
        this.props.initAuth();

        // get query from link
        let query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        // save queries
        if(query) {
            let initialState = { ...this.state, user: { ...this.state.user } };
            // if(query.code && !this.state.user.code) initialState.user.code = query.code;
            if(query.name && !this.state.user.name) initialState.user.name = query.name;
            if(query.email && !this.state.user.email) initialState.user.email = query.email;
            this.setState(initialState);
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            },
            submitted: false,
            isStrongPassword: false,
            isEmail: false
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        
        this.setState({
            submitted: true,
            isEmail: validator.isEmail(this.state.user.email), // validate email
            isStrongPassword: validator.isStrongPassword(this.state.user.password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 }) // validate new password
        }, () => {
            const { user, captcha, isStrongPassword, isEmail } = this.state;
            if (isStrongPassword && isEmail && captcha && user.name && user.email && user.password && (user.password === user.passwordConfirm)) {
                // remove passwordConfirm value from user object
                let { passwordConfirm, ...data } = user;
                // register user
                this.props.register(data);
                // register click event
                analytics.event('Register Page', 'Email Register', 'Clicked Email Register Form Submit Button');
            }
        });
    }

    handleSubmitGoogle(){
        history.push({
            ...this.props.location, // pass registration params
            pathname: sitemap.auth.social.google,
            state: {
                from: sitemap.auth.register,
                data: {
                    provider: 'google'
                }
            }
        });
        // register click event
        analytics.event('Register Page', 'Social Register', 'Clicked Google Register Button');
    }

    handleSubmitFacebook(){
        history.push({
            ...this.props.location, // pass registration params
            pathname: sitemap.auth.social.facebook,
            state: {
                from: sitemap.auth.register,
                data: {
                    provider: 'facebook'
                }
            }
        });
        // register click event
        analytics.event('Register Page', 'Social Register', 'Clicked Facebook Register Button');
    }

    handleCaptcha(value) {
        if (value) this.setState({ captcha: true });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title='Register' />

                <Header
                    desktopFixed={true}
                    fullScreen={true}
                    border={true}
                    onlyMobileBorder={true}
                    showLogo={true}
                    logoLinkTo={sitemap.landing.home}
                    secondaryButtonText='Log in'
                    secondaryButtonLinkTo={sitemap.auth.login}
                    secondaryButtonLabel='Already have an account?'
                />

                <RegisterForm
                    alert={this.props.alert}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    handleSubmitGoogle={this.handleSubmitGoogle}
                    handleSubmitFacebook={this.handleSubmitFacebook}
                    handleCaptcha={this.handleCaptcha}
                    registering={this.props.registering}
                    user={this.state.user}
                    submitted={this.state.submitted}
                    captcha={this.state.captcha}
                    isStrongPassword={this.state.isStrongPassword} />
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { alert } = state;
    const { registering } = state.auth;

    // export state data to props
    return { alert, registering };
}

const actionCreators = {
    register: authActions.register,
    initAuth: authActions.loadCsrfToken
}

const connectedRegisterContainer = connect(mapState, actionCreators)(RegisterContainer);
export { connectedRegisterContainer as RegisterContainer };