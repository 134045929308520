import styled from 'styled-components';
import { CalendarWeek } from 'react-bootstrap-icons';

import { colorConstants } from '../../../constants';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Header = styled.div`
    margin-top: 24px;
    font-size: 18px;
    font-weight: 600;
    color: ${colorConstants.black};
    text-align: center;
`;

const Text = styled.div`
    margin-bottom: 24px;
    font-size: 12px;
    font-weight: 400;
    color: ${colorConstants.mediumGrey};
    text-align: center;
`;


export const NoBookingsFound = (props) => {

    return (
        <Container>
            <CalendarWeek size={72} color={colorConstants.black} />
            <Header>Nothing Booked</Header>
            <Text>Manage your travel bookings here</Text>
        </Container>
    );
};