import React, { Component } from "react";
import { connect } from 'react-redux';

import { authActions, userActions, customerActions, paymentMethodActions, hotelBookingActions, alertActions } from '../../../actions';
import { ScreenContainer } from './TripCheckoutContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { TopBar } from '../../../components/custom/TopBar';
import { TripCheckoutPage } from "../../../components/trips";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { BookingSuccess } from '../../../components/custom/Popup';
import { sitemap, urlConstants } from "../../../constants";
import { history, analytics, defaultUser, defaultCustomer, defaultPaymentMethod, hotelBookingPaymentConfigData } from '../../../utils';


class TripCheckoutContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            fromUrl: sitemap.admin.dashboard,
            scrollToSection: '', // used to scroll to particular section
            typingTimeout: 0, // used to know when user stops typing
            loginRedirect: false, // used to know when user goes to login page
            registerNewAccount: false,
            initCustomer: false, // initialize server check if customer exists
            initPaymentMethod: false, // initialize server check if payment method exists
            user: defaultUser(),
            customer: defaultCustomer(),
            notes: '',
            coupon: '',
            paymentMethod: defaultPaymentMethod(),
            completed: false,
            successPopupConfigs: {},
        };

        this.handleScrollReset = this.handleScrollReset.bind(this);
        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);

        this.handleChangeCoupon = this.handleChangeCoupon.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleOpenTerms = this.handleOpenTerms.bind(this);
        this.handleChangeBillingDetails = this.handleChangeBillingDetails.bind(this);
        this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
        this.handleChangeNotes = this.handleChangeNotes.bind(this);
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
        this.handleChangeSavedPaymentMethod = this.handleChangeSavedPaymentMethod.bind(this);

        this.handleCheckout = this.handleCheckout.bind(this);
        this.handleCompleted = this.handleCompleted.bind(this);

        this.handleViewBookings = this.handleViewBookings.bind(this);
    }

    componentDidMount() {
        this.props.loadCsrfToken(); // load csrf token in cookies
        if(this.props.cookie.id) this.props.getById(this.props.cookie.id); // get logged-in user data from db
        window.addEventListener('scroll', this.handleScroll);
        window.scrollTo(0, 0); // force scroll to top of page

        if(this.props.location.state) {
            let initialState = {};
            if(this.props.location.state.from) initialState = { ...this.state, ...initialState, fromUrl: this.props.location.state.from }; // set from URL
            // get data from previous page
            if(this.props.location.state.data) {
                const { scrollToSection, userId, guestUserId } = this.props.location.state.data;
                // set section to scroll to
                if(scrollToSection) initialState = { ...this.state, ...initialState, scrollToSection };
                // save booking data in state
                if(userId || guestUserId) {
                    initialState = { ...this.state, ...initialState, userId, guestUserId };
                } else {
                    // stop and go back if no guest or booking id
                    history.replace({ pathname: this.state.fromUrl || sitemap.admin.dashboard, state: { error: 'No booking data found' } });
                    return;
                }
            }
            // set initial settings page state
            this.setState(initialState);
        } else {
            history.replace({ pathname: sitemap.admin.dashboard, state: { error: 'Invalid Params' } });
            return;
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.clearAlert(); // reset alert in redux state
        this.props.resetCustomer();
        this.props.resetUser(); // reset user in redux state to force update on next screens
        this.props.resetHotelBookingCheckoutConfig();
    }

    componentDidUpdate() {
        if (!this.state.init) {

            const isGuest = !this.state.userId && this.state.guestUserId;
            const isLoggedinUser = this.props.user && this.props.user.id;
            
            // go back if no configs are loaded
            if((isLoggedinUser || isGuest) && (!this.props.hotelBooking || !this.props.hotelBooking.config)) {
                history.replace({ pathname: this.state.fromUrl || sitemap.admin.dashboard, state: { error: 'No checkout configs found' } });
                return;
            }

            if(isGuest) {
                // init guest user session
                this.setState({ ...this.state, init: true });

            } else if(isLoggedinUser) {
                // load user data from server into view

                // find customer on server with user id (if exists)
                this.props.getCustomer(this.props.user.id, false);

                // save user in state
                this.setState({
                    ...this.state,
                    init: true,
                    customer: defaultCustomer(this.props.user.name, this.props.user.email),
                    user: this.props.user,
                });
            }
        }


        // save existing customer in state
        if(this.state.init && !this.state.initCustomer && this.props.customer && (this.props.customer.name || this.props.customer.error)) {
            let { customer } = this.state;
            
            if(this.props.customer.name) {
                customer = { ...customer, ...this.props.customer };
                // get all payment methods
                this.props.getAllPaymentMethods(this.props.customer.id);
            }

            this.setState({ ...this.state, initCustomer: true, customer });
        }


        // save default payment method
        if(this.state.init && this.state.initCustomer && !this.state.initPaymentMethod && this.props.paymentMethod && this.props.paymentMethod.list && this.props.paymentMethod.list.length > 0) {
            const pm = this.props.paymentMethod.list.find(item => item.default);
            this.setState({ ...this.state, initPaymentMethod: true, paymentMethod: { ...pm, paymentMethodId: pm.id, paymentType: pm.type } });
        }
    }

    handleScrollReset() {
        if(this.state.init && this.state.scrollToSection) {
            this.setState({ ...this.state, scrollToSection: '' });
        }
    }

    handleTopBarBackButton() {
        // Go back
        history.push({
            pathname: this.state.fromUrl,
            state: {
                data: {
                    estimateId: this.props.hotelBooking.config.id
                }
            }
        });
    }

    handleChangeCoupon(event) {
        if(!this.props.hotelBooking || !this.props.hotelBooking.config || this.props.hotelBooking.config.status !== 'success') return;

        const { value } = event.target;
        const { userId, id, estimateSecret, total } = this.props.hotelBooking.config;

        // only registered users can apply coupons
        if(!userId) return;

        // checkout data with coupon request configs
        const hotelBookingCheckoutConfigWithCouponData = { userId, estimateId: id, estimateSecret, total, coupon: value };

        this.setState({
            ...this.state,
            typingTimeout: setTimeout(() => { this.props.validateCouponCode(hotelBookingCheckoutConfigWithCouponData) }, 1300),
            coupon: value
        });

        // clear timer
        if(this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleRegister() {
        this.setState({ ...this.state, registerNewAccount: !this.state.registerNewAccount });
    }

    handleLogin() {

    }

    handleOpenTerms(option) {
        let url = null;

        switch(option) {
            case 'TERMS': {
                url = sitemap.landing.z.policies.terms;
                break;
            }

            case 'TERMS_CUSTOMERS': {
                url = sitemap.landing.z.policies.termsForCustomers;
                break;
            }

            case 'PRIVACY': {
                url = sitemap.landing.z.policies.privacy;
                break;
            }

            default:
                break;
        }

        if(url) window.open(urlConstants.baseUrlClient + url);
    }

    handleChangeBillingDetails(event){
        let { name, value } = event.target;
        this.setState({ ...this.state, customer: { ...this.state.customer, [name]: value } });
    }

    handleChangeBillingAddress(event){
        let { name, value } = event.target;

        this.setState({
            ...this.state,
            customer: {
                ...this.state.customer,
                address: {
                    ...this.state.customer.address,
                    [name]: value,
                }
            }
        });
    }

    handleChangeNotes(event){
        let { value } = event.target;
        this.setState({ ...this.state, notes: value });
    }

    handleChangePaymentMethod(event){
        const isCard = event && event.elementType && event.elementType === 'card';
        const isCardCvc = event && event.elementType && event.elementType === 'cardCvc';

        // skip if card cvc input
        if(isCardCvc) return;

        // get current payment method (if any)
        let updated = this.state.paymentMethod;

        if(isCard) {
            // save card brand
            updated.brand = event.complete ? event.brand : '';
        } else {
            const { name, value } = event.target;
            updated[name] = value;
        }

        this.setState({
            ...this.state,
            paymentMethod: {
                ...this.state.paymentMethod,
                ...updated,
            },
        });
    }

    handleChangeSavedPaymentMethod() {
        // go to settings page and scroll to payment methods section
        history.replace({
            pathname: sitemap.admin.settings,
            state: {
                from: this.state.fromUrl,
                data: {
                    scrollToSection: 'billing-info',
                },
            }
        });
    }

    async handleCheckout(type) {
        if(!this.state.init) return;

        let paymentType = this.state.initPaymentMethod ? this.state.paymentMethod.paymentType : type;

        this.setState({
            ...this.state,
            paymentMethod: { ...this.state.paymentMethod, paymentType, }
        }, async () => {
            if(this.props.hotelBooking.config.type === 'ACCOMMODATION') {
                // add extra data to submit
                const extra = { registerNewAccount: this.state.registerNewAccount, notes: this.state.notes };
                // load booking payment configs
                this.props.loadHotelBookingPaymentConfig(hotelBookingPaymentConfigData(this.props.hotelBooking.config, this.state.customer, this.state.paymentMethod, extra), this.state.guestUserId);
                // register click event in GA
                analytics.event('Trip Checkout', 'Purchase Button Click', this.props.hotelBooking.config.currencyCode, this.props.hotelBooking.config.total / 100);
            }
        });
    }

    handleCompleted(){
        // check if guest checkout
        const isGuest = !this.state.userId && this.state.guestUserId;

        let successPopupConfigs = {};

        if(this.props.hotelBooking && this.props.hotelBooking.config) {
            successPopupConfigs = {
                onClick: this.handleViewBookings,
                onHide: this.handleViewBookings,
                type: this.props.hotelBooking.config.type,
                imageUrl: this.props.hotelBooking.config.details.photos[0].url,
                title: `Successfully Booked`,
                message: `You're going to ${this.props.hotelBooking.config.details.name} on ${this.props.hotelBooking.config.details.start} for ${this.props.hotelBooking.config.details.nights} nights!`,
                buttonText: isGuest ? 'Booking Sent To Your Email' : 'View Bookings',
            };
        }
        
        this.setState({ ...this.state, completed: true, successPopupConfigs },
            () => {
            // redirect to bookings with 2 sec delay if user does not click the button
            setTimeout(() => this.handleViewBookings(), 4000);
        });
    }

    handleViewBookings() {
        // check if guest checkout
        const isGuest = !this.state.userId && this.state.guestUserId;

        // get redirect url
        let pathname = sitemap.admin.bookings.list;
        
        if(isGuest) {
            // change redirect url
            pathname = this.state.fromUrl;
            // force logout to remove cookie
            this.props.logout();
        }

        // redirect to my booking screen
        let redirectConfig = { pathname, state: { data: {} } };
        if(this.props.hotelBooking && this.props.hotelBooking.config) {
            redirectConfig.state.data.type = this.props.hotelBooking.config.type;
            redirectConfig.state.data.bookingStimateId = this.props.hotelBooking.config.id;
            redirectConfig.state.data.status = this.props.hotelBooking.config.status;
        }

        history.replace(redirectConfig);
    }


    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title='Trip Checkout' />

                <TopBar
                    title='Book Trip'
                    backButton={this.handleTopBarBackButton} />

                { this.state.init &&
                <TripCheckoutPage
                    booking={this.props.hotelBooking}
                    customer={this.state.customer}
                    coupon={this.state.coupon}
                    handleChangeCoupon={this.handleChangeCoupon}
                    isGuestCheckout={!this.state.userId && this.state.guestUserId}
                    registerNewAccount={this.state.registerNewAccount}
                    handleRegister={this.handleRegister}
                    handleLogin={this.handleLogin}
                    handleOpenTerms={this.handleOpenTerms}
                    paymentMethod={this.state.paymentMethod}
                    handleChangeBillingDetails={this.handleChangeBillingDetails}
                    handleChangeBillingAddress={this.handleChangeBillingAddress}
                    notes={this.state.notes}
                    handleChangeNotes={this.handleChangeNotes}
                    handleChangePaymentMethod={this.handleChangePaymentMethod}
                    handleChangeSavedPaymentMethod={this.handleChangeSavedPaymentMethod}
                    handleSubmit={this.handleCheckout}
                    onComplete={this.handleCompleted}
                    goBack={this.handleTopBarBackButton} /> }
                
                <BookingSuccess
                    data={this.state.successPopupConfigs}
                    show={this.state.init && this.state.completed} />
                
                { (!this.props.user || this.props.user.loading || (this.props.customer && this.props.customer.loading) || (this.props.hotelBooking && (this.props.hotelBooking.loadingConfigs || this.props.hotelBooking.loadingConfigsWithCoupon)))
                && <FullScreenSpinner /> }
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get cookie
    let cookie = null;
    if(state.auth && state.auth.cookie) cookie = state.auth.cookie;

    // get data from app reducer state
    const { user, customer, paymentMethod, hotelBooking } = state;

    // export state data to props
    return { cookie, user, customer, paymentMethod, hotelBooking };
}

const actionCreators = {
    loadCsrfToken: authActions.loadCsrfToken,
    logout: authActions.logout,
    getCustomer: customerActions.getByUserId,
    resetCustomer: customerActions.clear,
    getById: userActions.getById,
    resetUser: userActions.clear,
    getAllPaymentMethods: paymentMethodActions.getAll,
    loadHotelBookingCheckoutConfig: hotelBookingActions.checkoutConfig,
    validateCouponCode: hotelBookingActions.checkoutConfigWithCoupon,
    resetHotelBookingCheckoutConfig: hotelBookingActions.resetCheckoutConfig,
    loadHotelBookingPaymentConfig: hotelBookingActions.paymentConfig,
    resetHotelBookings: hotelBookingActions.clear,
    clearAlert: alertActions.clear,
}

const connectedTripCheckoutContainer = connect(mapState, actionCreators)(TripCheckoutContainer);
export { connectedTripCheckoutContainer as TripCheckoutContainer };