import styled from 'styled-components';
import { FormCheck } from '../../custom/FormInput';

import { colorConstants } from "../../../constants";


const Container = styled.div`
    
`;

const Header = styled.div`
    margin-bottom: 8px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 15px;
    color: ${colorConstants.black};
`;

const TitleValue = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: ${colorConstants.mediumGrey};
`;

const OptionsSection = styled.div`
    padding: 0px 16px;

    & > * {
        margin-bottom: 8px;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }
`;



export const BedTypeSelector = ({ title, titleValue, doubleBedType, onChange }) => {

    return (
        <Container>
            <Header>
                <Title>{title}</Title>
                &nbsp;
                <TitleValue>{titleValue}</TitleValue>
            </Header>

            <OptionsSection>
                <FormCheck
                    id='single-bed'
                    text='2 Single Beds'
                    isActive={!doubleBedType}
                    onChange={(event) => onChange(event.target.name)} />
                
                <FormCheck
                    id='double-bed'
                    text='1 Double Bed'
                    isActive={doubleBedType}
                    onChange={(event) => onChange(event.target.name)} />
            </OptionsSection>
        </Container>
    );
};