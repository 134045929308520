import React, { Component } from "react";
import { connect } from 'react-redux';
import qs from 'qs';
import validator from 'validator';

import { authActions } from '../../../actions';
import { ScreenContainer, StyledLogo } from './ResetPasswordContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import ResetPasswordRequest  from "../../../components/auth/password/ResetPasswordRequest";
import ResetPasswordRequestCompleted  from "../../../components/auth/password/ResetPasswordRequestCompleted";
import ResetPassword  from "../../../components/auth/password/ResetPassword";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { history } from '../../../utils';
import { sitemap } from '../../../constants';


class ResetPasswordContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            isUpdateScreen: false,
            requestSubmitted: false,
            showRequestCompletedScreen: false,
            passwordSubmitted: false,
            isStrongPassword: false,
            isEmail: false,
            code: {
                token: null,
                userId: -1
            },
            credentials: {
                email: '',
                password: '',
                passwordConfirm: ''
            }
        };

        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitResetPasswordRequest = this.handleSubmitResetPasswordRequest.bind(this);
        this.handleSubmitResetPassword = this.handleSubmitResetPassword.bind(this);
        this.handleSubmitResendEmail = this.handleSubmitResendEmail.bind(this);
    }

    componentDidMount() {
        // get queries from link
        let query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const hasToken = query && query.token;
        const hasUserId = query && query.id;

        
        if (hasToken && hasUserId && !this.state.code.token && this.state.code.userId === -1) {
            this.setState({
                ...this.state,
                isUpdateScreen: true, // set password update screen based on queries
                code: {
                    token: query.token, // save password reset token to state
                    userId: query.id // save user id to state
                }
            });
        }
    }

    handleTopBarBackButton() {
        history.push({ pathname: sitemap.auth.login }); // Go back to login screen
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { credentials } = this.state;

        this.setState({
            ...this.state,
            requestSubmitted: false,
            passwordSubmitted: false,
            isStrongPassword: false,
            credentials: {
                ...credentials,
                [name]: value, // update input data
            }
        });
    }

    handleSubmitResetPasswordRequest(){
        const { email } = this.state.credentials;

        this.setState({
            requestSubmitted: true,
            isEmail: validator.isEmail(this.state.credentials.email)
        }, async () => {
            if(this.state.requestSubmitted && email && this.state.isEmail){
                // send reset password request to server
                let requestPromise = new Promise(async (resolve, reject) => {
                                            try {
                                                // send reset password request
                                                await this.props.resetPasswordRequest({
                                                            email,
                                                            notes: 'A simple password reset request'
                                                        });
                                                
                                                // show reset request completed screen
                                                this.setState({ showRequestCompletedScreen: true });

                                                // successfully updated
                                                resolve('Password reset requested');
                                            } catch (err) {
                                                reject(err);
                                            }
                                        });
                
                // execute password reset request promise
                await requestPromise
                        .catch((err) => {
                            console.log('PASSWORD RESET REQUEST ERROR: ', err);
                            history.push(sitemap.auth.login);
                        });
            }
        });
    }

    handleSubmitResetPassword() {
        const { credentials } = this.state;

        this.setState({
            passwordSubmitted: true,
            isStrongPassword: validator.isStrongPassword(credentials.password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 }) // validate new password
        }, async () => {
            if (this.state.passwordSubmitted && this.state.code.token && this.state.code.userId !== -1 && this.state.isStrongPassword && credentials.password && (credentials.password === credentials.passwordConfirm)){
                // update new password
                let resetPromise = new Promise(async (resolve, reject) => {
                                            try {
                                                // send update user password request
                                                await this.props.resetPassword({
                                                            token: this.state.code.token,
                                                            userId: this.state.code.userId,
                                                            password: credentials.password
                                                        });
                                                // successfully updated
                                                resolve('Password updated');
                                            } catch(err) {
                                                reject(err);
                                            }
                                        });

                // execute update password promise
                await resetPromise
                        .catch((err) => {
                            history.push(sitemap.auth.login);
                            console.log('PASSWORD RESET ERROR: ', err);
                        });
            }
        });
    }

    handleSubmitResendEmail(){
        // reset screen and reload page
        this.setState({
            ...this.state,
            requestSubmitted: false,
            isEmail: false,
            credentials: {
                ...this.state.credentials,
                email: ''
            }
        }, () => window.location.reload());
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title='Reset Password' />

                <StyledLogo noRedirect={true} />

                { !this.state.isUpdateScreen && !this.state.showRequestCompletedScreen &&
                <ResetPasswordRequest
                    credentials={this.state.credentials}
                    submitted={this.state.requestSubmitted}
                    alert={this.props.alert} // show error if not updated
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmitResetPasswordRequest} /> }

                { !this.state.isUpdateScreen && this.state.requestSubmitted && this.state.showRequestCompletedScreen &&
                <ResetPasswordRequestCompleted
                    email={this.state.credentials.email}
                    handleSubmit={this.handleSubmitResendEmail} /> }

                { this.state.isUpdateScreen &&
                <ResetPassword
                    credentials={this.state.credentials}
                    submitted={this.state.passwordSubmitted}
                    isStrongPassword={this.state.isStrongPassword}
                    alert={this.props.alert} // show error if not updated
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmitResetPassword} /> }

                {this.props.updatingPassword && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { alert, auth } = state;
    const { updatingPassword } = auth;

    // export state data to props
    return { alert, updatingPassword };
}

const actionCreators = {
    resetPassword: authActions.resetPassword,
    resetPasswordRequest: authActions.resetPasswordRequest
}

const connectedResetPasswordContainer = connect(mapState, actionCreators)(ResetPasswordContainer);
export { connectedResetPasswordContainer as ResetPasswordContainer };