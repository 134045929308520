import { http, handleResponse, handleError } from "../utils";

export const publicTripsService = {
    getAll,
    getBySlug,
    filter
};

async function getAll() {
    return await http.get("/public/trips")
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getBySlug(username, slug) {
    return await http.get(`/public/trips/${slug}?username=${username}`)
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function filter(data) {
    return await http.get(`/public/trips?${data.toString()}`)
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}