import React from "react";
import styled from 'styled-components';
import DraftProductsListItem from './DraftProductsListItem';
import { Divider } from '../../custom/Divider';
import { colorConstants } from "../../../constants";


const List = styled.div`
    margin-top: 32px;
    padding-bottom: 16px;
`;

const SectionTitle = styled.div`
    font-size: 16px;
    font-weight: 800;
    color: ${colorConstants.black};
    margin-top: 16px;
    margin-bottom: 16px;
`;


export const DraftProductsList = ({ products, handleEditProduct, handleDeleteProduct, }) => {

    // get draft products
    const draft = products && products.filter(item => !item.isSectionLabel && (!item.imageUrls || item.imageUrls.length === 0));

    // hide draft products list if no draft found
    if(!draft || draft.length === 0) return null;

    // render draft products list
    return (
        <List>
            <Divider />
            <SectionTitle>Draft Products</SectionTitle>
            {draft.map((item, index) => {
                return (
                    <div key={index}>
                        <DraftProductsListItem data={item} handleEdit={handleEditProduct} handleDelete={handleDeleteProduct} />
                    </div>
                );
            })}
            <Divider />
        </List>
    );
};