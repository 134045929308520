import { http, authHeader, handleResponse, handleError, setLocalData, deleteLocalData } from "../utils";

export const userService = {
    getById,
    update,
    creatorModeStatus,
    // enableCreatorMode,
    delete: _delete
};

async function getById(id) {
    return await http.get(`/users/${id}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function update(data) {
    return await http.put(`/users/${data.id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .then((res) => {
                        // reset local user cookie
                        if(res.resetUserCookie) {
                            deleteLocalData('user');
                            setLocalData('user', { id: res.id, username: res.username, profilePhotoUrl: res.profilePhotoUrl });
                        }

                        return res;
                    })
                    .catch((err) => handleError(err, true));
}

async function creatorModeStatus(id) {
    return await http.get(`/users/creator-mode-status/${id}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

// async function enableCreatorMode(data) {
//     return await http.put(`/admin${adminEndpointSecret}/users/creator-mode-enable/${data.id}`, data, { headers: authHeader() })
//                     .then((res) => handleResponse(res, true))
//                     .then((res) => {
//                         // reset local user cookie
//                         if(res.resetUserCookie) {
//                             deleteLocalData('user');
//                             setLocalData('user', { id: res.id, username: res.username, profilePhotoUrl: res.profilePhotoUrl });
//                         }

//                         return res;
//                     })
//                     .catch((err) => handleError(err, true));
// }

async function _delete(id) {
    return await http.delete(`/users/${id}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .then(() => deleteLocalData('user')) // delete user data from local storage
                    .then(() => window.location.replace('/')) // go to homepage
                    .catch((err) => handleError(err, true));
}