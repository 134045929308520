import { tripReducerConstants } from "../constants";
import { tripService } from "../services";
import { alertActions } from './';

export const tripActions = {
    validate,
    create,
    getAll,
    getById,
    getBySlug,
    update,
    favourite,
    delete: _delete,
    clear
};

function validate(trip) {
    return async dispatch => {
        dispatch(request());

        await tripService.validate(trip)
            .then(
                validation => dispatch(success(validation)),
                error => dispatch(failure())
            );
    };

    function request() { return { type: tripReducerConstants.VALIDATE_REQUEST } }
    function success(validation) { return { type: tripReducerConstants.VALIDATE_SUCCESS, validation } }
    function failure() { return { type: tripReducerConstants.VALIDATE_FAILURE } }
}

function create(trip) {
    return async dispatch => {
        dispatch(request());

        await tripService.create(trip)
            .then(
                trip => {
                    dispatch(success(trip));
                    dispatch(alertActions.success('Trip created successfully'));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: tripReducerConstants.CREATE_REQUEST } }
    function success(trip) { return { type: tripReducerConstants.CREATE_SUCCESS, trip } }
    function failure() { return { type: tripReducerConstants.CREATE_FAILURE } }
}

function getAll(query) {
    return dispatch => {
        dispatch(request());

        tripService.getAll(query)
            .then(
                trips => dispatch(success(trips)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: tripReducerConstants.GETALL_REQUEST } }
    function success(trips) { return { type: tripReducerConstants.GETALL_SUCCESS, trips } }
    function failure(error) { return { type: tripReducerConstants.GETALL_FAILURE, error } }
}

function getById(id, query) {
    return dispatch => {
        dispatch(request());

        tripService.getById(id, query)
            .then(
                trip => dispatch(success(trip)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: tripReducerConstants.GETBYID_REQUEST } }
    function success(trip) { return { type: tripReducerConstants.GETBYID_SUCCESS, trip } }
    function failure(error) { return { type: tripReducerConstants.GETBYID_FAILURE, error } }
}

function getBySlug(slug, query) {
    return dispatch => {
        dispatch(request());

        tripService.getBySlug(slug, query)
            .then(
                trip => dispatch(success(trip)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: tripReducerConstants.GETBYSLUG_REQUEST } }
    function success(trip) { return { type: tripReducerConstants.GETBYSLUG_SUCCESS, trip } }
    function failure(error) { return { type: tripReducerConstants.GETBYSLUG_FAILURE, error } }
}

function update(trip) {
    return async dispatch => {
        dispatch(request(trip));

        await tripService.update(trip)
            .then(
                trip => { 
                    dispatch(success(trip));
                    dispatch(alertActions.success('Update successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(trip) { return { type: tripReducerConstants.UPDATE_REQUEST, trip } }
    function success(trip) { return { type: tripReducerConstants.UPDATE_SUCCESS, trip } }
    function failure(error) { return { type: tripReducerConstants.UPDATE_FAILURE, error } }
}

function favourite(data) {
    return async dispatch => {
        dispatch(request(data));

        await tripService.favourite(data)
            .then(
                trip => { 
                    dispatch(success(trip));
                    dispatch(alertActions.success('Successfully saved'));
                },
                error => {
                    dispatch(failure(error.toString(), data.id));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) { return { type: tripReducerConstants.FAVOURITE_REQUEST, data } }
    function success(trip) { return { type: tripReducerConstants.FAVOURITE_SUCCESS, trip } }
    function failure(error, id) { return { type: tripReducerConstants.FAVOURITE_FAILURE, error, id } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        tripService.delete(id)
            .then(
                trip => dispatch(success(id)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(id) { return { type: tripReducerConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: tripReducerConstants.DELETE_SUCCESS, id } }
    function failure(error) { return { type: tripReducerConstants.DELETE_FAILURE, error } }
}

function clear() {
    return { type: tripReducerConstants.CLEAR_ALL };
}