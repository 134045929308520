import styled from 'styled-components';

import { colorConstants } from '../../constants';
import { Button } from '../custom/Buttons';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 16px;
    margin-bottom: 16px;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${colorConstants.mediumGrey};

  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

  margin-right: 16px;
`;

const SettingsUserAddressButton = (props) => {
    const { text, onClick } = props;

    return (
        <Container>
            <Text>{text}</Text>
            <Button outline={true} small={true} onClick={onClick} >Edit</Button>
        </Container>
    );
};

export default SettingsUserAddressButton;