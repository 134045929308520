import styled from 'styled-components';
import { Trash, Pencil } from 'react-bootstrap-icons';

import { colorConstants } from "../../constants";

const GradientBorder = styled.div`
    background: ${colorConstants.gradientHorizontal};
    padding: 1px;
    margin-top: 10px;
    margin-bottom: 8px;
    border-radius: 2px;

    display: flex;
    align-items: center;
`;

const Container = styled.div`
    border-radius: 2px;
    border-color: ${colorConstants.white};
    background: ${colorConstants.white};
    width: 100%;
    padding: 4px 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Text = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${colorConstants.black};
  margin-right: 8px;
`;

const SubTitle = styled.div`
    max-width: ${props => props.maxWidth + 'px'};

    font-weight: 400;
    font-size: 10px;
    color: ${colorConstants.mediumGrey};
    margin-right: 8px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; // to use this we need to set max-width
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const DeleteButton = styled(Trash)`
    margin-left: 18px;
    :hover {
        cursor: pointer;
    }
`;

const EditButton = styled(Pencil)`
    margin-left: 18px;
    :hover {
        cursor: pointer;
    }
`;

const SettingsSocialItem = (props) => {
    const { title, subtitle, handleEdit, handleDelete, maxUsernameWidth, ...other } = props;

    return (
        <GradientBorder>
            <Container>
                <Text>
                    <Title>{title}</Title>
                    <SubTitle maxWidth={maxUsernameWidth} >{subtitle}</SubTitle>
                </Text>
                <Buttons>
                    <EditButton color={colorConstants.black} onClick={handleEdit} {...other} />
                    <DeleteButton color={colorConstants.black} onClick={handleDelete} {...other} />
                </Buttons>
            </Container>
        </GradientBorder>
    );
};

export default SettingsSocialItem