import React, { Component } from "react";
import { connect } from 'react-redux';
import { colord } from 'colord';
import Joyride from 'react-joyride';

import { userActions, alertActions } from '../../actions';
import { ScreenContainer } from './CustomizeContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { Notification, Upgrade } from '../../components/custom/Popup';
import CustomizePage from "../../components/customize/CustomizePage";
import { TopBar } from '../../components/custom/TopBar';
import { SideBar } from '../../components/custom/SideBar';
import ImageCrop from "../../components/cropper/ImageCrop";
import { FullScreenSpinner } from "../../components/custom/Spinner";
import { constructLinearGradientColorString, decontructLinearGradientColorString } from '../../components/custom/ColorPicker';
import { topbarConstants, otherConstants, sitemap, pageThemes, getProfileLink, contentCustomizePage, colorConstants } from "../../constants";
import {
    history,
    notNull,
    prepareUserData,
    defaultUser,
    defaultUserPhoto,
    compressImage,
    storageRef,
    uploadFile,
    getFileDownloadURL,
    removeTokenFromFileDownloadURL,
    getImageFileDimensions,
    defaultLinksViewOptionsEquals
} from '../../utils';


class CustomizeContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            fromUrl: sitemap.admin.dashboard,
            typingTimeout: 0, // used to know when user stops typing
            sidebar: false,
            sidebarReset: false,
            sidebarPositionOffset: 0,
            sticky: false, // used when scrolling to change topbar
            featuresChanged: null, // set to newlly changed feature name
            scrollToSection: '', // used to scroll to particular section
            showUpgradePopup: false, // used to toggle upgrade popup
            user: defaultUser(),
            photos: defaultUserPhoto()
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);
        this.handleSideBarToggle = this.handleSideBarToggle.bind(this);
        this.handleScrollReset = this.handleScrollReset.bind(this);
        /* Open Profile */
        this.handleViewPublicPage = this.handleViewPublicPage.bind(this);
        /* Customization Changes */
        this.handleChangeFeatures = this.handleChangeFeatures.bind(this);
        this.handleChangePageStyles = this.handleChangePageStyles.bind(this);
        this.handleChangePhotoStyles = this.handleChangePhotoStyles.bind(this);
        this.handleChangePageThemes = this.handleChangePageThemes.bind(this);
        this.handleChangeBackground = this.handleChangeBackground.bind(this);
        this.handleChangeButtons = this.handleChangeButtons.bind(this);
        this.handleChangeFonts = this.handleChangeFonts.bind(this);
        this.handleChangeSocialIcons = this.handleChangeSocialIcons.bind(this);
        this.handleChangeBranding = this.handleChangeBranding.bind(this);
        this.handleResetCustomizations = this.handleResetCustomizations.bind(this);
        /* Upgrade */
        this.handleToggleUpgradePopup = this.handleToggleUpgradePopup.bind(this);
        this.handleUpgrade = this.handleUpgrade.bind(this);
        /* Cover Photo Upload */
        this.handleUploadPhoto = this.handleUploadPhoto.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);
        /* App Tour */
        this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        this.props.getById(this.props.userId); // get user data from db
        window.addEventListener('scroll', this.handleScroll);
        if(this.props.location.state) {
            let initialState = {};
            if(this.props.location.state.from) initialState = { ...this.state, ...initialState, fromUrl: this.props.location.state.from }; // set from URL
            // get data from previous page
            if(this.props.location.state.data) {
                const { scrollToSection } = this.props.location.state.data;
                // set section to scroll to
                if(scrollToSection) initialState = { ...this.state, ...initialState, scrollToSection };
            }
            // set initial settings page state
            this.setState(initialState);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        // load user data from server into view
        if (!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access
            if(!this.props.user.creator) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            this.setState({ ...this.state, init: true, user: this.props.user });
        }

        // update default page view options
        if(this.state.init && this.state.featuresChanged && this.props.user && this.props.user.pageOptions && this.props.user.pageData) {

            // validate received updated data from server response
            const receivedUpdatedOptions = this.state.user.pageOptions[this.state.featuresChanged] === this.props.user.pageOptions[this.state.featuresChanged];

            if(receivedUpdatedOptions) {

                // validate default links view options have changed
                const defaultLinksViewOptionsChanged = !defaultLinksViewOptionsEquals(this.state.user.pageData.metadata.defaultLinksViewOptions, this.props.user.pageData.metadata.defaultLinksViewOptions);

                // end options update
                this.setState({ ...this.state, featuresChanged: null });

                if(defaultLinksViewOptionsChanged) {
                    this.setState({
                        ...this.state,
                        featuresChanged: false,
                        user: {
                            ...this.state.user,
                            pageData: {
                                ...this.state.user.pageData,
                                metadata: this.props.user.pageData.metadata
                            }
                        }
                    });
                }
            }
        }
    }

    handleScroll(event) {
        // Enable sticky mode on scroll and reset sidebar
        this.setState({ ...this.state, sidebar: false, sidebarReset: true, sidebarPositionOffset: window.pageYOffset, sticky: window.pageYOffset > 1 });
    }
    
    handleTopBarBackButton() {
        if(this.state.photos.crop.pendingImage) this.setState({ ...this.state, photos: defaultUserPhoto() }); // reset crop image screen
        // else history.push(this.state.fromUrl); // go back to previous screen
    }

    handleSideBarToggle(){
        this.setState({ ...this.state, sidebar: !this.state.sidebar, sidebarReset: false });
    }

    handleScrollReset(){
        if(this.state.init && this.state.scrollToSection) {
            this.setState({ ...this.state, scrollToSection: '' });
        }
    }

    handleViewPublicPage(){
        history.push({
            pathname: this.state.init ? getProfileLink(this.state.user.username) : this.state.fromUrl,
            state: {
                from: sitemap.admin.customize
            }
        });
    }

    handleChangeFeatures(event, data) {
        event.preventDefault();

        // get switch name
        let { name, value } = event.target;

        if(name === 'form-selector') {
            name = 'defaultLinksView';
        }

        // update page options
        this.setState({
            ...this.state,
            featuresChanged: name === 'defaultLinksView' ? null : name,
            user: {
                ...this.state.user,
                pageOptions: {
                    ...this.state.user.pageOptions,
                    [name]: name === 'defaultLinksView' ? value : !this.state.user.pageOptions[name]
                }
            }
        }, () => this.props.update(prepareUserData(this.state.user)));
    }

    handleChangePageStyles(event) {
        event.preventDefault();
        const { value } = event.target;
        let { pageCoverImage, pageStyle, pageTheme, pageBackgroundGradient } = this.state.user.pageOptions;

        // show upgrade notification
        if(!this.state.user.basic && !this.state.user.premium && !this.state.user.platinum) {
            this.handleToggleUpgradePopup();
            return;
        }

        if(value !== 'COVER') {
            // reset cover image
            pageCoverImage = '';

            // revert gradient background for non-COVER page style mode
            if(pageStyle === 'COVER' && pageTheme && pageBackgroundGradient) {
                pageTheme = '';
            }
        }

        // update page styles
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                pageOptions: {
                    ...this.state.user.pageOptions,
                    pageStyle: value,
                    pageCoverImage,
                    pageTheme,
                }
            }
        }, () => this.props.update(prepareUserData(this.state.user)));
    }

    handleChangePhotoStyles(event, option) {
        // show upgrade notification
        if(!this.state.user.basic && !this.state.user.premium && !this.state.user.platinum) {
            this.handleToggleUpgradePopup();
            return;
        }

        let name = option;
        let value = event;

        if(option === 'pagePhotoStyle'){
            event.preventDefault();
            value = event.target.value;

            // reset photo style
            if(value === 'NONE') value = '';
        }

        // update page photo styles
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                pageOptions: {
                    ...this.state.user.pageOptions,
                    [name]: value,
                    pageTheme: name === 'pagePhotoBorderColor' ? '' : this.state.user.pageOptions.pageTheme,
                }
            }
        }, () => this.props.update(prepareUserData(this.state.user)));
    }

    handleChangePageThemes(event) {
        event.preventDefault();
        const { value } = event.target;
        let { pageCoverImage, pageBackgroundGradient } = this.state.user.pageOptions;

        // get selected theme
        const { access, ...theme } = pageThemes[value.toLowerCase()];

        // show upgrade notification
        if(access === 'BASIC' && (!this.state.user.basic && !this.state.user.premium && !this.state.user.platinum)) {
            this.handleToggleUpgradePopup();
            return;
        }

        // get background gradient color
        pageBackgroundGradient = theme.pageBackgroundGradient;

        // re-adjust gradient background for page cover style mode
        if(pageCoverImage && pageBackgroundGradient) {
            const components = decontructLinearGradientColorString(pageBackgroundGradient);
            pageBackgroundGradient = constructLinearGradientColorString({ mode: 'COVER', ...components });
        }

        // update page themes
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                pageOptions: {
                    ...this.state.user.pageOptions,
                    ...theme,
                    pageBackgroundGradient,
                }
            }
        }, () => this.props.update(prepareUserData(this.state.user)));
    }

    handleChangeBackground(value, name) {
        // show upgrade notification
        if(!this.state.user.basic && !this.state.user.premium && !this.state.user.platinum) {
            this.handleToggleUpgradePopup();
            return;
        }

        // empty attributes for reset background
        let emptyAttrb = {
            pageTheme: '',
            pageThemeDark: false,
            pageBackgroundColor: '',
            pageBackgroundGradient: '',
            pageBackgroundImage: '',
            pageBackgroundVideo: ''
        };

        if(name === 'pageBackgroundColor' && colord(value).isValid()){
            emptyAttrb.pageThemeDark = colord(value).isDark();
        }

        if(name === 'pageBackgroundGradient') {
            emptyAttrb.pageBackgroundColor = value.color1;
            emptyAttrb.pageThemeDark = colord(value.color1).isDark();

            if(colord(value.color2).isDark() && !emptyAttrb.pageThemeDark){
                // emptyAttrb.pageBackgroundColor = value.color2;
                emptyAttrb.pageThemeDark = true;
            }
            
            value = value.gradient;
        }

        // update page color user data on server with 1sec delay
        this.setState({
            ...this.state,
            typingTimeout: setTimeout(() => { this.props.update(prepareUserData(this.state.user)) }, 1000),
            user: {
                ...this.state.user,
                pageOptions: {
                    ...this.state.user.pageOptions,
                    ...emptyAttrb, // reset previously set background attributes
                    [name]: value,
                }
            }
        });

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleUploadPhoto(e, filename) {
        // Save uploaded image url in user state
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save uploaded photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: this.state.user.username + '-' + filename,
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    async handleCroppedImage(croppedImage) {
        try {
            // enable spinner
            this.setState({ ...this.state, photos: { uploading: true, crop: defaultUserPhoto().crop } });

            // compress cropped image file
            const compressedFile = await compressImage(croppedImage, croppedImage.name, 720);

            // create firebase storage reference of cover image to upload
            const imageRef = storageRef(otherConstants.environment + '/users/' + this.state.user.id + '/' + compressedFile.name);

            // Upload compressed image to firebase storage
            const snapshot = await uploadFile(imageRef, compressedFile);

            // Get firebase public url of uploaded photo
            let uploadedPhotoFirebaseUrl = await getFileDownloadURL(snapshot.ref);

            // Clean public url
            uploadedPhotoFirebaseUrl = removeTokenFromFileDownloadURL(uploadedPhotoFirebaseUrl);

            const coverImageMode = compressedFile.name.includes('page-cover-image');
            const backgroundImageMode = compressedFile.name.includes('page-background-image');

            // re-adjust gradient background for page cover style mode
            let { pageBackgroundGradient } = this.state.user.pageOptions;
            if(coverImageMode && pageBackgroundGradient) {
                const components = decontructLinearGradientColorString(pageBackgroundGradient);
                pageBackgroundGradient = constructLinearGradientColorString({ mode: 'COVER', ...components });
            }

            // update user cover url in state
            this.setState({
                ...this.state,
                user: {
                    ...this.state.user,
                    pageOptions: {
                        ...this.state.user.pageOptions,
                        pageCoverImage: coverImageMode ? uploadedPhotoFirebaseUrl : '',
                        pageTheme: backgroundImageMode ? '' : this.state.user.pageOptions.pageTheme,
                        pageBackgroundColor: backgroundImageMode ? '' : this.state.user.pageOptions.pageBackgroundColor,
                        pageBackgroundGradient: backgroundImageMode ? '' : pageBackgroundGradient,
                        pageBackgroundImage: backgroundImageMode ? uploadedPhotoFirebaseUrl : '',
                        pageBackgroundVideo: ''
                    },
                }
            }, () => {
                // update user profile photo in db
                this.props.update(prepareUserData(this.state.user));
                // disable spinner
                this.setState({ ...this.state, photos: defaultUserPhoto() });
            });
        } catch (error) {
            throw error;
        }
    }

    handleChangeButtons(event, option) {
        // show upgrade notification
        if(!this.state.user.basic && !this.state.user.premium && !this.state.user.platinum) {
            this.handleToggleUpgradePopup();
            return;
        }

        let name = option;
        let value = event;

        if(option === 'pageButtonShape' || option === 'pageButtonStyle'){
            event.preventDefault();
            value = event.target.value;

            // reser button shape
            if(option === 'pageButtonShape' && value === 'NONE') {
                value = '';
            }
        }

        // update page buttons
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                pageOptions: {
                    ...this.state.user.pageOptions,
                    [name]: value,
                    pageTheme: '',
                }
            }
        }, () => this.props.update(prepareUserData(this.state.user)));
    }

    handleChangeFonts(event, option) {
        // show upgrade notification
        if(!this.state.user.basic && !this.state.user.premium && !this.state.user.platinum) {
            this.handleToggleUpgradePopup();
            return;
        }

        let name = option;
        let value = event;

        if(option === 'pageFontType'){
            event.preventDefault();
            value = event.target.value;

            // reset fonts
            if(value === 'NONE') {
                value = '';
            }
        }

        // update page buttons
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                pageOptions: {
                    ...this.state.user.pageOptions,
                    [name]: value,
                    pageTheme: '',
                }
            }
        }, () => this.props.update(prepareUserData(this.state.user)));
    }

    handleChangeSocialIcons(event, option) {
        let name = option;
        let value = event;

        // show upgrade notification
        if(option !== 'pageSocialIconLocationTop' && !this.state.user.basic && !this.state.user.premium && !this.state.user.platinum) {
            this.handleToggleUpgradePopup();
            return;
        }

        if(option === 'pageSocialIconShape'){
            event.preventDefault();
            value = event.target.value;

            // reset fonts
            if(value === 'NONE') {
                value = '';
            }
        }

        if(option === 'pageSocialIconCustomColorToggle') {
            event.preventDefault();
            name = 'pageSocialIconColor';
            if(this.state.user.pageOptions.pageSocialIconColor) value = '';
            else value = '#181818';
        }

        if(option === 'pageSocialIconLocationTop') {
            event.preventDefault();
            value = event.target.value;

            if(value === 'NONE') value = true;
            else value = false;
        }

        // update page buttons
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                pageOptions: {
                    ...this.state.user.pageOptions,
                    [name]: value,
                    pageTheme: name === 'pageSocialIconColor' ? '' : this.state.user.pageOptions.pageTheme,
                }
            }
        }, () => this.props.update(prepareUserData(this.state.user)));
    }

    handleChangeBranding(event, option) {
        event.preventDefault();

        // show upgrade notification
        if(!this.state.user.premium && !this.state.user.platinum) {
            this.handleToggleUpgradePopup();
            return;
        }

        // update user
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                pageOptions: {
                    ...this.state.user.pageOptions,
                    pageBrandingBunjeeLogo: !this.state.user.pageOptions.pageBrandingBunjeeLogo,
                }
            }
        }, () => this.props.update(prepareUserData(this.state.user)));
    }

    handleResetCustomizations(){
        // get default customizations from default pageOption
        const { pageOptions } = defaultUser();
        const {
            defaultLinksView,
            showOverviewPage,
            showStandardLinks,
            showGithubLinks,
            showBlogLinks,
            showYoutubeLinks,
            showYoutubeshortLinks,
            showInstagramreelLinks,
            showTiktokLinks,
            showSpotifyLinks,
            showHotelLinks,
            showTravelAgencyLinks,
            showPhysicalProductLinks,
            showDigitalProductLinks,
            showNFTLinks,
            ...defaultCustomizations
        } = pageOptions;
        
        // update user
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                pageOptions: {
                    ...this.state.user.pageOptions,
                    ...defaultCustomizations,
                }
            }
        }, () => this.props.update(prepareUserData(this.state.user)));
    }

    handleToggleUpgradePopup() {
        this.setState({ ...this.state, showUpgradePopup: !this.state.showUpgradePopup });
    }

    handleUpgrade() {
        history.push({
            pathname: sitemap.billing.upgrade,
            state: {
                from: sitemap.admin.customize
            }
        });
    }

    handleJoyrideCallback(data) {
        if(!data || !this.state.init) return;
        
        const { status } = data;

        if(status === 'finished' || status === 'skipped') {
            this.setState({ ...this.state, user: { ...this.state.user, isCustomizeTourDone: true } }, () => this.props.update(prepareUserData(this.state.user)));
        }
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.customize} />

                { this.state.init && this.state.user.creator && !this.state.user.isCustomizeTourDone &&
                <Joyride
                    callback={this.handleJoyrideCallback}
                    continuous
                    hideCloseButton
                    disableCloseOnEsc
                    disableOverlayClose
                    hideBackButton
                    // scrollToFirstStep
                    showProgress
                    showSkipButton
                    steps={contentCustomizePage.tour}
                    run={true}
                    styles={{
                        options: {
                            primaryColor: colorConstants.primaryDark,
                        },
                    }}
                /> }

                <TopBar
                    title={this.state.photos.crop.pendingImage ? topbarConstants.titles.crop : topbarConstants.titles.customize}
                    sticky={this.state.sticky}
                    menuButton={this.state.photos.crop.pendingImage ? null : this.handleSideBarToggle}
                    backButton={this.state.photos.crop.pendingImage ? this.handleTopBarBackButton : null}
                    subButton={this.state.sidebar ? null : this.handleViewPublicPage}
                    subTitle='View Page Changes'
                />

                {this.state.init &&
                <SideBar
                    options={{ isAuthenticated: true, creator: this.state.user.creator, basic: this.state.user.basic, premium: this.state.user.premium, platinum: this.state.user.platinum, links: this.state.user.pageData.metadata.links }}
                    toggle={this.handleSideBarToggle}
                    from={sitemap.admin.customize}
                    enable={this.state.sidebar}
                    reset={this.state.sidebarReset}
                    position={this.state.sidebarPositionOffset} /> }
                
                { this.state.init && !this.state.photos.crop.pendingImage &&
                <CustomizePage
                    handleScrollReset={this.handleScrollReset}
                    scrollToSection={this.state.scrollToSection}
                    handleChangeFeatures={this.handleChangeFeatures}
                    handleChangePageStyles={this.handleChangePageStyles}
                    handleChangePhotoStyles={this.handleChangePhotoStyles}
                    handleChangePageThemes={this.handleChangePageThemes}
                    handleChangeBackground={this.handleChangeBackground}
                    handleUploadPhoto={this.handleUploadPhoto}
                    handleChangeButtons={this.handleChangeButtons}
                    handleChangeFonts={this.handleChangeFonts}
                    handleChangeSocialIcons={this.handleChangeSocialIcons}
                    handleChangeBranding={this.handleChangeBranding}
                    handleResetCustomizations={this.handleResetCustomizations}
                    handleUpgrade={this.handleToggleUpgradePopup}
                    basic={this.state.user.basic}
                    premium={this.state.user.premium}
                    platinum={this.state.user.platinum}
                    pageOptions={this.state.user.pageOptions}
                    pageData={this.state.user.pageData} /> }

                { this.state.photos.crop.pendingImage &&
                <ImageCrop
                    url={this.state.photos.crop.pendingImage}
                    filename={this.state.photos.crop.pendingImageFilename}
                    fileSize={this.state.photos.crop.pendingImageFileSize}
                    imageDimensions={this.state.photos.crop.pendingImageDimensions}
                    circularCrop={false}
                    handleCroppedImage={this.handleCroppedImage} /> }
                
                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                {this.state.showUpgradePopup &&
                <Upgrade
                    show={this.state.showUpgradePopup}
                    onClick={this.handleUpgrade}
                    onHide={this.handleToggleUpgradePopup} /> }
                
                {(!this.props.user || this.props.user.loading || this.state.photos.uploading) && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user } = state;

    // export state data to props
    return { userId, alert, user };
}

const actionCreators = {
    getById: userActions.getById,
    update: userActions.update,
    clearAlert: alertActions.clear
}

const connectedCustomizeContainer = connect(mapState, actionCreators)(CustomizeContainer);
export { connectedCustomizeContainer as CustomizeContainer };