import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Clock } from 'react-bootstrap-icons';
import { colorConstants } from "../../../constants";


const twoDigitLocaleConfigs = { minimumIntegerDigits: 2, useGrouping:false };


const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  line-height: 1 !important;
`;

const Icon = styled(Clock)`
  margin-right: 8px;
  font-size: ${({ size }) => size ? `${size}px` : '10px'};
  color: ${colorConstants.darkGrey};
`;

const Text = styled.div`  
  font-weight: 500;
  font-size: ${({ size }) => size ? `${size}px` : '10px'};
  color: ${({ color }) => color || colorConstants.mediumGrey};
`;


export const CountdownTimer = ({ targetDate, minutes, label, color, size, warnOnComplete, handleExpired }) => {
  const [remainingTime, setRemainingTime] = useState({ minutes: minutes || '00', seconds: '00' });
  const [isComplete, setComplete] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      // calculate time
      const targetTime = new Date(targetDate).getTime();
      const currentTime = new Date().getTime();
      const totalSeconds = Math.floor((targetTime - currentTime) / 1000);
      const remainingMinutes = Math.floor(minutes + (totalSeconds / 60));
      const remainingSeconds = ((minutes * 60) + totalSeconds) % 60;

      // save time to state
      setRemainingTime({ minutes: remainingMinutes.toLocaleString('en-US', twoDigitLocaleConfigs), seconds: remainingSeconds.toLocaleString('en-US', twoDigitLocaleConfigs) });

      // reset timer when expired
      if((remainingMinutes === 0 && remainingSeconds === 0) || remainingMinutes < 0 || remainingSeconds < 0) {
        if(warnOnComplete) setComplete(true);
        setRemainingTime({ minutes: '00', seconds: '00' });
        reset();
        return;
      }

    }, 1000);

    const reset = () => {
      clearInterval(timer);
      if(handleExpired) handleExpired(minutes);
    };

    return () => clearInterval(timer);
  }, [targetDate, minutes, handleExpired, warnOnComplete]);

  return (
    <Container>
      <Icon color={isComplete ? colorConstants.warning : color} size={size} />
      <Text color={isComplete ? colorConstants.warning : color} size={size} >{label ? label : ''}{remainingTime.minutes}:{remainingTime.seconds}</Text>
    </Container>
  );
};