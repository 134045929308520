import React, { useMemo } from "react";
import styled from 'styled-components';

import { SearchListItem } from './SearchListItem';
import { TripFooter } from '../trips/trip/TripFooter';
import { SearchListSpinner } from "../custom/Spinner";


const List = styled.div`
    margin-top: 8px;
    padding-bottom: 80px;

    /* added for sticky search toolbar */
    ${({ sticky }) => sticky && `
        padding-top: 108px;
    `};

    & > * {
        margin-bottom: 16px;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }
`;

const Spacer = styled.div`
    height: ${props => props.size}px;
`;



export const SearchList = ({ data, lastItemRef, loading, type, sticky, handleViewDetails, handleOpenTerms }) => {

    const items = useMemo(() => {
        if(!data) return null;

        switch(type) {
            case 'ACCOMMODATION': {
                return data.map((item, index) => <SearchListItem key={index} lastItemRef={data.length === (index + 1) ? lastItemRef : null} data={item} handleView={handleViewDetails} />);
            }
    
            default:
                return null;
        }
        
    }, [data, type, handleViewDetails, lastItemRef]);

    return (
        <List sticky={sticky} >
            {items}
            { loading && <SearchListSpinner streaming={true} /> }
            {items && <Spacer size='48' />}
            {items && <TripFooter handleOpenTerms={handleOpenTerms} />}
        </List>
    );
};