import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
    Container,
    Header,
    Body,
    Title,
    BodyTitle,
    BodySection, BodySectionRow,
    BodySectionDivider,
    PriceRangeSection, PriceRangeSeparator,
    StyledButton, ResetButton,
} from './HotelFilters.styles';
import { FormCheck, DoubleSlider, GradientFormInputWithLabel } from '../FormInput';
import Spinner from 'react-bootstrap/Spinner';



export const HotelFilters = ({ data, show, onHide, handleFilter, processing, resetTrigger }) => {

    const typeOptions = useMemo(() => ['Hotel', 'Resort', 'Hostel', 'Apartment', 'Guest House'], []);
    const starOptions = useMemo(() => [1,2,3,4,5], []);
    const amenityOptions = useMemo(() => ['Wi-fi', 'Spa', 'Hamam', 'Car park', 'Gym', 'Pool'], []);
    const priceRangeOptions = useMemo(() => ({ range: [0, 780], min: 0, max: 780, distance: 100 }), []);
    const [stars, setStars] = useState((data && data.stars) || starOptions);
    const [types, setTypes] = useState((data && data.stars) || typeOptions);
    const [amenities, setAmenities] = useState((data && data.amenities) || amenityOptions);
    const [discountedOnly, setDiscountedOnly] = useState(false);
    const [priceRange, setPriceRange] = useState((priceRangeOptions && priceRangeOptions.range) || [20, 500]);
    const [isResetting, setIsResetting] = useState(false);
    // ouput with all filter configs
    const output = useMemo(() => ({ stars, types, amenities, discountedOnly, priceRange: { range: priceRange, options: priceRangeOptions } }), [stars, types, amenities, discountedOnly, priceRange, priceRangeOptions]);


    // process filters when apply button is clicked
    const handleApply = useCallback(() => {
        if(handleFilter) handleFilter(output);
        if(onHide) onHide();
    }, [handleFilter, onHide, output]);

    // handle stars check box change
    const handleCheckedStars = useCallback(event => {
        const starValue = parseInt(event.target.value);
        if(event.target.checked) {
            setStars(prev => ([ ...prev, starValue ]));
        } else {
            setStars(prev => prev.filter(s => s !== starValue));
        }
    }, []);

    // render star check boxes
    const starFilterCheckBoxes = useMemo(() => {
        let output = [];

        for(let i = 0; i < starOptions.length; i++) {
            output.push(
                <BodySectionRow key={`${i}-stars-checkbox`}>
                    <FormCheck
                        type='checkbox'
                        fontSize='12px'
                        value={starOptions[i]}
                        id={`${i}-stars-checkbox`}
                        text={`${starOptions[i]} Stars`}
                        isActive={stars.includes(starOptions[i])}
                        onChange={handleCheckedStars} />
                </BodySectionRow>
            );
        }

        return output;

    }, [stars, starOptions, handleCheckedStars]);

    // handle accommodation types check box change
    const handleCheckedTypes = useCallback(event => {
        const typeValue = event.target.value;
            if(event.target.checked) {
                setTypes(prev => ([ ...prev, typeValue ]));
            } else {
                setTypes(prev => prev.filter(t => t !== typeValue));
            }
    }, []);

    // render accommodation type check boxes
    const accommodationFilterCheckBoxes = useMemo(() => {
        let output = [];

        for(let i = 0; i < typeOptions.length; i++) {
            output.push(
                <BodySectionRow key={`${i}-type-checkbox`}>
                    <FormCheck
                        type='checkbox'
                        fontSize='12px'
                        value={typeOptions[i]}
                        id={`${i}-type-checkbox`}
                        text={typeOptions[i]}
                        isActive={types.includes(typeOptions[i])}
                        onChange={handleCheckedTypes} />
                </BodySectionRow>
            );
        }

        return output;

    }, [types, typeOptions, handleCheckedTypes]);

    // handle amenities check box change
    const handleCheckedAmenities = useCallback(event => {
        const amenityValue = event.target.value;
            if(event.target.checked) {
                setAmenities(prev => ([ ...prev, amenityValue ]));
            } else {
                setAmenities(prev => prev.filter(a => a !== amenityValue));
            }
    }, []);

    // render amenities check boxes
    const amenitiesFilterCheckBoxes = useMemo(() => {
        let output = [];

        for(let i = 0; i < amenityOptions.length; i++) {
            output.push(
                <BodySectionRow key={`${i}-amenity-checkbox`}>
                    <FormCheck
                        type='checkbox'
                        fontSize='12px'
                        value={amenityOptions[i]}
                        id={`${i}-amenity-checkbox`}
                        text={amenityOptions[i]}
                        isActive={amenities.includes(amenityOptions[i])}
                        onChange={handleCheckedAmenities} />
                </BodySectionRow>
            );
        }

        return output;

    }, [amenities, amenityOptions, handleCheckedAmenities]);

    // set min and max price ranges
    const onPriceRangeInputBoxChange = useCallback((event) => {
        const { name, value } = event.target;
        let formattedVal = parseInt(value.replace(/[^0-9]/g, ''));
        
        if(name === 'min-rate-input') {
            if(isNaN(formattedVal)) formattedVal = priceRangeOptions.min;
            if(formattedVal > (priceRangeOptions.max - priceRangeOptions.distance)) return;
            const maxValue = Math.max(formattedVal + priceRangeOptions.distance, priceRange[1]);
            const hasReadhedMax = maxValue > priceRangeOptions.max;
            setPriceRange([formattedVal, hasReadhedMax ? priceRangeOptions.max : maxValue]);

        } else if(name === 'max-rate-input') {
            if(isNaN(formattedVal)) formattedVal = priceRangeOptions.max;
            const hasReadhedMax = formattedVal > priceRangeOptions.max;
            if(hasReadhedMax) return;
            const minValue = Math.min(formattedVal - priceRangeOptions.distance, priceRange[0]);
            setPriceRange([(minValue < 0) ? 0 : minValue, formattedVal]);
        }

    }, [priceRange, priceRangeOptions]);

    // reset filters to default
    const handleReset = useCallback((isResetTrigger) => {
        setStars(starOptions);
        setTypes(typeOptions);
        setAmenities(amenityOptions);
        setDiscountedOnly(false);
        setPriceRange(priceRangeOptions.range);
        if(handleFilter) handleFilter({ isResetting: true, isResetTrigger });
        if(onHide) onHide();
        setIsResetting(false);

    }, [starOptions, typeOptions, amenityOptions, priceRangeOptions, handleFilter, onHide]);

    useEffect(() => {
        if(resetTrigger === 'RESET' && !isResetting) {
            setIsResetting(true);
            handleReset(true);
        }

    }, [resetTrigger, isResetting, handleReset]);

    return (
        <Container show={show} onHide={() => handleApply()}>
            <Header closeButton>
                <Title>Filter by</Title>
            </Header>

            <Body>
                <BodySection>
                    <BodyTitle>Price Range</BodyTitle>

                    <BodySectionRow margin='16px 0 8px 0'>
                        <DoubleSlider
                            id='price-range'
                            min={priceRangeOptions.min}
                            max={priceRangeOptions.max}
                            step={1}
                            value={priceRange}
                            minDistance={priceRangeOptions.distance}
                            onChange={(event) => setPriceRange(event)}
                        />
                    </BodySectionRow>

                    <BodySectionRow margin='0 0 8px 0'>
                        <PriceRangeSection>
                            <GradientFormInputWithLabel
                                box='true'
                                label="Min Price /night"
                                type='text'
                                name='min-rate-input'
                                placeholder='€60'
                                value={priceRange && priceRange.length > 0 ? `€${priceRange[0]}` : ''}
                                onChange={onPriceRangeInputBoxChange} />

                            <PriceRangeSeparator />

                            <GradientFormInputWithLabel
                                box='true'
                                label="Max Price /night"
                                type='text'
                                name='max-rate-input'
                                placeholder='€100'
                                value={priceRange && priceRange.length > 1 ? `€${priceRange[1]}${priceRange[1] >= priceRangeOptions.max ? '+' : ''}` : ''}
                                onChange={onPriceRangeInputBoxChange} />
                        </PriceRangeSection>
                    </BodySectionRow>

                    <BodySectionRow  margin='16px 0 0 0'>
                        <FormCheck
                            type='checkbox'
                            fontSize='12px'
                            id='discount-checkbox'
                            text='Show only discounts'
                            isActive={discountedOnly}
                            onChange={() => setDiscountedOnly(!discountedOnly)} />
                    </BodySectionRow>
                </BodySection>

                <BodySectionDivider />

                <BodySection>
                    <BodyTitle>Star Rating</BodyTitle>

                    {starFilterCheckBoxes}
                </BodySection>

                <BodySectionDivider />

                <BodySection>
                    <BodyTitle>Type</BodyTitle>

                    {accommodationFilterCheckBoxes}
                </BodySection>

                <BodySectionDivider />

                <BodySection>
                    <BodyTitle>Facilities</BodyTitle>

                    {amenitiesFilterCheckBoxes}
                </BodySection>

                <StyledButton medium={true} midCorner={true} gradient={true} onClick={() => handleApply()} >{processing ? <Spinner size="sm" animation="border" /> : 'Apply'}</StyledButton>

                <ResetButton onClick={() => handleReset()}>Clear all</ResetButton>
            </Body>
      </Container>
    );
};