import React from "react";

import Carousel from 'react-bootstrap/Carousel'
import GoogleReviewsListItem from './GoogleReviewsListItem';
import styled from 'styled-components';

const Container = styled(Carousel)`
    .carousel-control-prev {
        padding-top: 48px;
    }

    .carousel-control-next {
        padding-top: 48px;
    }
`;


const GoogleReviewsList = (props) => {
    const { reviews } = props;

    const items = reviews.map((item, index) => (
        <Carousel.Item key={index} >
            <GoogleReviewsListItem className="d-block w-100" review={item} />
        </Carousel.Item>
    ));

    return <Container variant='dark' interval={12000} controls={true} indicators={false} >{items}</Container>;
};

export default GoogleReviewsList;