import React, { useRef, useEffect } from "react";
import { 
    Container,
    Section, SectionImageWrapper, SectionImage, SectionContent, SectionHeader, SectionSubheader, SectionButton, SectionLoginSpan, SectionLoginLink,
    RegistrationSection, RegistrationSectionContent, RegistrationSectionHeader, RegistrationSectionSubheader, RegistrationSectionArrowWrapper, RegistrationSectionRightArrow, RegistrationSectionDownArrow, RegistrationSectionImage,
    RegistrationSectionForm, RegistrationSectionFormTitle,
    CreatorsSpotlightSection, CreatorsSpotlightSectionHeader,
 } from './TravelLandingPage.styles';
import { TravelLandingPageForm } from './TravelLandingPageForm';
import { CreatorSpotlight } from "../CreatorSpotlight";

import { generateFirebasePhotoLink } from '../../../utils';
import { sitemap, contentTravelLandingPage } from "../../../constants";


export const TravelLandingPage = (props) => {
    const {
        alert,
        handleScrollReset,
        scrollToSection,
        handleScrollToForm,
        handleChange,
        handleFocus,
        handleBlur,
        handleSubmit,
        handleCaptcha,
        registering,
        user,
        submitted,
        captcha,
        isEmail,
        isLink,
        handleOpenCreatorPage,
    } = props;

    // used to scroll page to registration section
    const registrationSectionRef = useRef(null);

    // set banner images
    const sectionTwoImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Ftravel%2Ftravel-landing-page-product-img-2-compressed.png?alt=media');
    const sectionThreeImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Ftravel%2Ftravel-landing-page-product-img-4-compressed.png?alt=media');
    const sectionFourImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Ftravel%2Ftravel-landing-page-product-img-5-compressed.png?alt=media');
    const registrationSectionImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Fmiddle-section-image.jpg?alt=media');


    useEffect(() => {

        // Scroll to page section
        if(scrollToSection) {
          switch(scrollToSection){
            // scroll to Registration Form
            case 'registration-form': {
              if(registrationSectionRef.current) {
                registrationSectionRef.current.scrollIntoView();
                handleScrollReset();
              }
              break;
            }
    
            default:
              break;
          }
        }
  
        return () => {
          // execute code on component unmount here
        };
      }, [handleScrollReset, scrollToSection]);


    return (
        <Container>
            <Section>
                <SectionContent center='true' >
                    <SectionHeader large='true' full='true' gradient='true' >{contentTravelLandingPage.hero.header}</SectionHeader>
                    <SectionSubheader large='true' full='true' >{contentTravelLandingPage.hero.subheader}</SectionSubheader>
                    <SectionButton gradient={true} midCorner={true} onClick={() => handleScrollToForm('Hero Apply Button')}>{contentTravelLandingPage.hero.primaryCta}</SectionButton>
                    <div>
                        <SectionLoginSpan>{contentTravelLandingPage.hero.secondaryCta.part1}</SectionLoginSpan>
                        <SectionLoginLink to={sitemap.auth.login} >{contentTravelLandingPage.hero.secondaryCta.part2}</SectionLoginLink>
                    </div>
                </SectionContent>
            </Section>


            <Section>
                <SectionImageWrapper left='true' >
                    <SectionImage src={sectionTwoImageUrl} alt="Bunjee for Travel Product Image 2" />
                </SectionImageWrapper>

                <SectionContent right='true' >
                    <SectionHeader>{contentTravelLandingPage.sectionTwo.header}</SectionHeader>
                    <SectionSubheader>{contentTravelLandingPage.sectionTwo.subheader}</SectionSubheader>
                    <SectionButton gradient={true} midCorner={true} onClick={() => handleScrollToForm('Section Two Apply Button')}>{contentTravelLandingPage.sectionTwo.primaryCta}</SectionButton>
                </SectionContent>
            </Section>


            <Section>
                <SectionContent left='true' >
                    <SectionHeader>{contentTravelLandingPage.sectionThree.header}</SectionHeader>
                    <SectionSubheader>{contentTravelLandingPage.sectionThree.subheader}</SectionSubheader>
                    <SectionButton gradient={true} midCorner={true} onClick={() => handleScrollToForm('Section Three Apply Button')}>{contentTravelLandingPage.sectionThree.primaryCta}</SectionButton>
                </SectionContent>

                <SectionImageWrapper right='true' >
                    <SectionImage src={sectionThreeImageUrl} alt="Bunjee for Travel Product Image 3" />
                </SectionImageWrapper>
            </Section>


            <Section>
                <SectionImageWrapper left='true' >
                    <SectionImage src={sectionFourImageUrl} alt="Bunjee for Travel Product Image 4" />
                </SectionImageWrapper>

                <SectionContent right='true' >
                    <SectionHeader>{contentTravelLandingPage.sectionFour.header}</SectionHeader>
                    <SectionSubheader>{contentTravelLandingPage.sectionFour.subheader}</SectionSubheader>
                    <SectionButton gradient={true} midCorner={true} onClick={() => handleScrollToForm('Section Four Apply Button')}>{contentTravelLandingPage.sectionFour.primaryCta}</SectionButton>
                </SectionContent>
            </Section>

            <CreatorsSpotlightSection>
                <CreatorsSpotlightSectionHeader gradient={true} >Creator Spotlight</CreatorsSpotlightSectionHeader>
                <CreatorSpotlight items={[
                    { photo: "https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/o/production%2Fusers%2F29%2Fvicki-profile-photo.jpeg?alt=media", username: "vickirutwind" },
                    { photo: "https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/o/production%2Fusers%2F11%2Fsaltydayshq-profile-photo.jpeg?alt=media", username: "saltydays" },
                    { photo: "https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/o/production%2Fusers%2F20%2Fmarionmithamo-profile-photo.jpeg?alt=media", username: "marion_mithamo" },
                    { photo: "https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/o/production%2Fusers%2F13%2Fioannou.nicolasraphael-profile-photo.jpeg?alt=media", username: "nicolas" },
                    { photo: "https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/o/production%2Fusers%2F36%2Fhello-profile-photo.jpeg?alt=media", username: "findingalexx" },
                    { photo: "https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/o/production%2Fusers%2F39%2Fjulie-profile-photo.jpeg?alt=media", username: "juliebrose" },
                    { photo: "https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/o/production%2Fusers%2F44%2Fchloeamym.x-profile-photo.jpeg?alt=media", username: "chloeamym" },
                    { photo: "https://lh3.googleusercontent.com/a/AGNmyxZwuywQSgI0gUC03_JQjk4NIYa5fPKO40pFi6zYJg", username: "tia_does_travel" },
                ]}
                handleClick={handleOpenCreatorPage} />
            </CreatorsSpotlightSection>

            <RegistrationSection ref={registrationSectionRef}>

                <RegistrationSectionContent>
                    <RegistrationSectionHeader>{contentTravelLandingPage.cta.header}</RegistrationSectionHeader>
                    <RegistrationSectionSubheader>{contentTravelLandingPage.cta.text}</RegistrationSectionSubheader>
                    
                    <RegistrationSectionArrowWrapper>
                        <RegistrationSectionRightArrow />
                        <RegistrationSectionDownArrow />
                    </RegistrationSectionArrowWrapper>
                </RegistrationSectionContent>

                <RegistrationSectionForm>
                    <RegistrationSectionFormTitle>{contentTravelLandingPage.form.title}</RegistrationSectionFormTitle>

                    <TravelLandingPageForm
                        alert={alert}
                        handleChange={handleChange}
                        handleFocus={handleFocus}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        handleCaptcha={handleCaptcha}
                        registering={registering}
                        user={user}
                        submitted={submitted}
                        captcha={captcha}
                        isEmail={isEmail}
                        isLink={isLink}
                    />
                </RegistrationSectionForm>

                <RegistrationSectionImage src={registrationSectionImageUrl} alt="Bunjee for Travel Banner"/>

            </RegistrationSection>
        </Container>
    );
};