import React, { useState, useEffect, useCallback } from 'react';
import {
    Container,
    Body,
    Close,
} from './Notification.styles';


export const Notification = (props) => {
    const { message, onHide } = props;

    // auto-hide counter state variables
    const [isActive, setIsActive] = useState(message && onHide ? true : false); // set active true when has message and onHide method
    const [counter, setMyCounter] = useState(4); // count 4 seconds
    const [timer, setMyTimer] = useState('');


    const resetTimer = useCallback((timer) => {
        // reset timer
        clearInterval(timer);
        setMyCounter(4);
        setIsActive(false);
        // execute onHide() method - usually tied to method clearing alerts
        onHide();
    }, [onHide]);

    useEffect(() => {
        if(isActive && !timer) {
            // set timer to countdown to 0
            setMyTimer(setInterval(() => setMyCounter(counter => counter - 1), 1000));
        } else if(isActive && counter === 0) {
            // reset timer when count reaches 0
            resetTimer();
        }
    }, [isActive, timer, counter, resetTimer]);

    useEffect(() => {
        // reset interval when component unmounts so it stops trying to save to local state
        return () => clearInterval(timer);
    }, [timer]);

    // center short messages
    let centerText = message && message.length < 35;

    return (
        <Container onClick={isActive ? () => resetTimer() : null} >
            <Body centerText={centerText}>{message}</Body>
            <Close>x</Close>
        </Container>
    );
};