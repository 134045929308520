import React from "react";
import styled from 'styled-components';
import NFTListItem from './NFTListItem';
import { Divider } from '../../custom/Divider';
import { deviceConstants } from "../../../constants";


const List = styled.div`
    margin-top: 32px;
    padding-bottom: 80px;
`;

const StyledDivider = styled(Divider)`
    @media ${deviceConstants.tablet} {
        display: none;
    }
`;


export const NFTList = (props) => {
    const { nfts, handleViewNft, handleEditNft, handleDeleteNft, handleEnableNft } = props;

    // get all NFTs and filter out draft NFTs
    const addedNfts = nfts && nfts.filter(item => item.image && item.image.length > 0);
    const hasNfts = addedNfts && addedNfts.length > 0;
    let nftsList = null;
    if(hasNfts) {
        nftsList = addedNfts.map((item, index) => {
            return (
                <div key={index}>
                    <NFTListItem data={item} handleView={handleViewNft} handleEdit={handleEditNft} handleDelete={handleDeleteNft} handleEnabled={handleEnableNft} />
                    {index + 1 < addedNfts.length && <StyledDivider style={{marginBottom: '16px'}} />}
                </div>
            );
        });
    }

    // render NFTs list
    return (
        <List>
            {nftsList}
        </List>
    );
};