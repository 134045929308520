import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { Button } from '../Buttons';
import { colorConstants, deviceConstants } from '../../../constants';


export const Container = styled(Modal)`

`;

export const Header = styled(Modal.Header)`
    border-bottom: none;
`;

export const Title = styled(Modal.Title)`
    font-weight: 700;
    font-size: 20px;
    background: ${colorConstants.gradientForText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const Body = styled.div`
    padding: 32px;
    border-radius: 0 0 8px 8px;
    background: ${colorConstants.white};

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const BodyTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: ${colorConstants.secondaryDark};
`;

export const BodySection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    & > * {
        margin-bottom: 8px;
    }

    & > *:first-child {
        margin-bottom: 12px;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }
`;

export const BodySectionRow = styled.div`
    width: 100%;
    margin: ${({ margin }) => margin};
    display: flex;
    align-items: center;
`;

export const BodySectionDivider = styled.div`
    width: 100%;
    height: 1px;
    margin: 16px 0px;
    background: ${colorConstants.verySoftGrey};
`;

export const StyledButton = styled(Button)`
    width: 100%;

    margin-top: 40px;
    margin-bottom: 16px;

    /* make button sticky so that is always visible */
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 16px;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);


    @media ${deviceConstants.tablet} {
        width: 75%;
    }
`;

export const ResetButton = styled.div`
    padding: 8px 32px;
    font-weight: 600;
    font-size: 12px;
    text-aling: right;
    color: ${colorConstants.darkGrey};

    :hover {
        cursor: pointer;
        color: ${colorConstants.primary};
    }
`;

export const PriceRangeSection = styled.div`
    width: 100%;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
        margin-right: 8px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const PriceRangeSeparator = styled.div`
    width: 40px;
    height: 1px;
    background: ${colorConstants.gradientHorizontal};
`;