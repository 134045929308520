import React, { useRef } from 'react';
import {
    Container, SectionTitle, Notification,
    SearchSection, SearchResults, SearchResultItem, SearchResultItemLink,
    ProjectEditSection, StyledFormText, ProjectEditSectionInput, MaxTextIndicator,
    ProjectIconSection, FileUploader, ProjectLanguage, StyledButton,
} from './AddGithubProject.styles';
import { GradientFormInput, GradientInputEditor } from '../../../custom/FormInput';
import { UploadPhoto } from '../../../custom/Images';
import { SimpleLinkInput } from '../../../custom/FormInput';



export const AddGithubProject = ({
    alert,
    isUpgraded,
    handleUpgrade,
    username,
    repos,
    reposSearchError,
    handleRepoSelected,
    project,
    submitted,
    handleChangeUsername,
    handleChangeProject,
    handleUploadPhoto,
    handleSubmit,
}) => {

    let searchResults = null;
    if(repos && repos.length > 0) {
        searchResults = repos.map((item, index) => (
                            <SearchResultItem key={index} onClick={() => handleRepoSelected(item)} >
                                <div>{item.label}</div>
                                <SearchResultItemLink>{item.url}</SearchResultItemLink>
                            </SearchResultItem>
                        ));
    }

    // open file browser on project image click
    const inputProjectImageRef = useRef(null);
    const openProjectImageUploader = () => {
        inputProjectImageRef.current.click();
    };

    return (
        <Container>
            <SearchSection>
                <SectionTitle>Github username:</SectionTitle>

                <GradientFormInput
                    box='enable'
                    type="text"
                    placeholder='Your Github username here'
                    name="username"
                    value={username}
                    onChange={handleChangeUsername} />

                { username && reposSearchError && <StyledFormText>{reposSearchError}</StyledFormText> }

                { (!project || !project.id) && searchResults && <SearchResults>{searchResults}</SearchResults> }
            </SearchSection>

            { project && project.id &&
            <ProjectEditSection>

                <ProjectEditSectionInput>
                    <SectionTitle>Project Link</SectionTitle>

                    <SimpleLinkInput
                        data={project}
                        hasBorder={true}
                        handleLabelChange={handleChangeProject}
                        handleUrlChange={handleChangeProject} />
                    
                    { submitted && !project.label && <StyledFormText>Project label is required</StyledFormText> }
                    { alert && alert.message && submitted && <StyledFormText>{alert.message}</StyledFormText> }
                </ProjectEditSectionInput>

                <ProjectEditSectionInput>
                    <SectionTitle>Project Description</SectionTitle>

                    <GradientInputEditor
                        as="textarea"
                        rows={3}
                        placeholder='Your project description'
                        cols={2000}
                        name="description"
                        maxLength="60"
                        value={project.description || ''}
                        onChange={handleChangeProject} />
                    <MaxTextIndicator>{project.description && project.description.length > 0 ? project.description.length : '0'}/60</MaxTextIndicator>
                </ProjectEditSectionInput>

                <ProjectIconSection>
                    <SectionTitle>Project Logo {!isUpgraded && <Notification onClick={handleUpgrade}>Upgrade</Notification>}</SectionTitle>

                    <UploadPhoto deactivate={!isUpgraded} showEdit={true} url={project && project.imageUrl ? project.imageUrl : null} onClick={openProjectImageUploader} />
                    <FileUploader type='file' accept="image/*" id='project-image' ref={inputProjectImageRef} onChange={handleUploadPhoto} />
                </ProjectIconSection>

                <ProjectLanguage>The primary programming language of this project is {project.githubProgrammingLanguage}</ProjectLanguage>
            </ProjectEditSection> }

            <StyledButton
                disabled={!project.id && !project.label}
                gradient={true}
                type="submit"
                onClick={handleSubmit}
            >
                Add Project
            </StyledButton>
      </Container>
    );
};