import React from 'react';
import { GradientFormInput } from '../../custom/FormInput';
import {
    Container,
    StyledForm,
    StyledFormGroup,
    FormInputTitle,
    StyledFormText,
    StyledButton,
    PasswordRequirements
} from './ChangePassword.styles';


const ChangePassword = (props) => {
    const { credentials, submitted, isStrongPassword, handleSubmit, handleChange, alert } = props;

    return (
        <Container>
            <StyledForm id="password-change">
                <StyledFormGroup>
                    <FormInputTitle>Password</FormInputTitle>
                    <GradientFormInput type="password" name="password" value={credentials.password} onChange={handleChange} />
                    { submitted && !credentials.password && <StyledFormText>Current Password is required</StyledFormText> }
                    { alert && alert.message && <StyledFormText>{alert.message}</StyledFormText> }
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>New Password</FormInputTitle>
                    <GradientFormInput type="password" name="newPassword" value={credentials.newPassword} onChange={handleChange} />
                    { submitted && !credentials.newPassword && <StyledFormText>New Password is required</StyledFormText> }
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>Confirm New Password</FormInputTitle>
                    <GradientFormInput type="password" name="newPasswordConfirm" value={credentials.newPasswordConfirm} onChange={handleChange} />
                    { submitted && (credentials.newPassword !== credentials.newPasswordConfirm) && <StyledFormText>New Password does not match</StyledFormText> }
                    { submitted && (credentials.newPassword === credentials.newPasswordConfirm) && !isStrongPassword && 
                        <StyledFormText>Password must meet the following requirements
                            <PasswordRequirements>
                                <li>8 characters minimum length</li>
                                <li>at least 1 uppercase and 1 lowercase</li>
                                <li>at least 1 number and 1 symbol</li>
                            </PasswordRequirements>
                        </StyledFormText>
                    }
                </StyledFormGroup>
            </StyledForm>

            <StyledButton
                gradient={true}
                type="submit"
                onClick={handleSubmit}
            >
                Update Password
            </StyledButton>
        </Container>
    );
};

export default ChangePassword;