import { nftReducerConstants } from "../constants";
import { nftService } from "../services";
import { alertActions } from './';

export const nftActions = {
    create,
    getAll,
    getById,
    getBySlug,
    update,
    favourite,
    delete: _delete,
    clear
};

function create(nft) {
    return async dispatch => {
        dispatch(request());

        await nftService.create(nft)
            .then(
                nft => {
                    dispatch(success(nft));
                    dispatch(alertActions.success('NFT added successfully'));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: nftReducerConstants.CREATE_REQUEST } }
    function success(nft) { return { type: nftReducerConstants.CREATE_SUCCESS, nft } }
    function failure() { return { type: nftReducerConstants.CREATE_FAILURE } }
}

function getAll(query) {
    return dispatch => {
        dispatch(request());

        nftService.getAll(query)
            .then(
                nfts => dispatch(success(nfts)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: nftReducerConstants.GETALL_REQUEST } }
    function success(nfts) { return { type: nftReducerConstants.GETALL_SUCCESS, nfts } }
    function failure(error) { return { type: nftReducerConstants.GETALL_FAILURE, error } }
}

function getById(id, query) {
    return dispatch => {
        dispatch(request());

        nftService.getById(id, query)
            .then(
                nft => dispatch(success(nft)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: nftReducerConstants.GETBYID_REQUEST } }
    function success(nft) { return { type: nftReducerConstants.GETBYID_SUCCESS, nft } }
    function failure(error) { return { type: nftReducerConstants.GETBYID_FAILURE, error } }
}

function getBySlug(slug, query) {
    return dispatch => {
        dispatch(request());

        nftService.getBySlug(slug, query)
            .then(
                nft => dispatch(success(nft)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: nftReducerConstants.GETBYSLUG_REQUEST } }
    function success(nft) { return { type: nftReducerConstants.GETBYSLUG_SUCCESS, nft } }
    function failure(error) { return { type: nftReducerConstants.GETBYSLUG_FAILURE, error } }
}

function update(nft) {
    return async dispatch => {
        dispatch(request(nft));

        await nftService.update(nft)
            .then(
                nft => { 
                    dispatch(success(nft));
                    dispatch(alertActions.success('Update successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(nft) { return { type: nftReducerConstants.UPDATE_REQUEST, nft } }
    function success(nft) { return { type: nftReducerConstants.UPDATE_SUCCESS, nft } }
    function failure(error) { return { type: nftReducerConstants.UPDATE_FAILURE, error } }
}

function favourite(data) {
    return async dispatch => {
        dispatch(request(data));

        await nftService.favourite(data)
            .then(
                nft => { 
                    dispatch(success(nft));
                    dispatch(alertActions.success('Successfully saved'));
                },
                error => {
                    dispatch(failure(error.toString(), data.id));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) { return { type: nftReducerConstants.FAVOURITE_REQUEST, data } }
    function success(nft) { return { type: nftReducerConstants.FAVOURITE_SUCCESS, nft } }
    function failure(error, id) { return { type: nftReducerConstants.FAVOURITE_FAILURE, error, id } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        nftService.delete(id)
            .then(
                nft => dispatch(success(id)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(id) { return { type: nftReducerConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: nftReducerConstants.DELETE_SUCCESS, id } }
    function failure(error) { return { type: nftReducerConstants.DELETE_FAILURE, error } }
}

function clear() {
    return { type: nftReducerConstants.CLEAR_ALL };
}