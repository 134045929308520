import React, { useMemo } from "react";

import {
  Container,
  PageTitle,
  BillingPlansSection,
  // ComparePlansSection, CompareFeaturesIcon, ComparePlansLink,
} from './UpgradePage.styles';
import { BillingCycleSelector } from './BillingCycleSelector';
import { BillingPlan } from './BillingPlan';
import {
  contentUpgradePage,
  // sitemap
} from '../../../constants';


export const UpgradePage = (props) => {
    const {
      handleUpgrade,
      handleChangeMonthly,
      basic, premium, platinum,
      isMonthly,
      pricing,
    } = props;


    const plans = useMemo(() => {
      if(!pricing || pricing.length === 0) return null;

      return pricing.map((data, index) => {
        let isActive = false;

        switch(data.tier) {
          case 'basic': {
            isActive = basic;
            break;
          }

          case 'premium': {
            isActive = premium;
            break;
          }

          case 'platinum': {
            isActive = platinum;
            break;
          }

          default: {
            isActive = !basic && !premium && !platinum;
          }
        }

        return <BillingPlan
                  key={index}
                  handleUpgrade={handleUpgrade}
                  name={data.name}
                  price={data.monthly.price}
                  currency={data.monthly.currency.symbol}
                  priceTopLabel={data.priceTopLabel}
                  priceDiscount={data.yearlyBillingDiscountPercentage}
                  overview={data.overview}
                  features={data.features}
                  isMonthly={isMonthly}
                  isActive={isActive} />
      });

    }, [pricing, basic, premium, platinum, handleUpgrade, isMonthly]);

    
    return (
          <Container>
              <PageTitle>{contentUpgradePage.title}</PageTitle>

              <BillingCycleSelector
                title={contentUpgradePage.billingCycle.selectorTitle}
                handleChangeMonthly={handleChangeMonthly}
              />

              <BillingPlansSection>{plans}</BillingPlansSection>

              {/* { pricing &&
              <ComparePlansSection>
                <CompareFeaturesIcon />
                <ComparePlansLink to={sitemap.landing.pricing} >{contentUpgradePage.planDetailsLink}</ComparePlansLink>
              </ComparePlansSection> } */}

          </Container>
    );
};