import styled from 'styled-components';
import Icon from '../../../assets/icons/Icon';

import { colorConstants } from '../../../constants';
import { Button } from '../Buttons';


const Container = styled.div`
    margin-top: 32px;
    margin-bottom: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const IconsSection = styled.div`
    margin-top: 4px;
    display: flex;

    & > * {
        margin-right: 24px;
    }

    & > *:last-child {
        margin-right: 0px;
    }

    :hover {
        cursor: pointer;
    }
`;

const Text = styled.div`
    margin: 24px 0px 16px 0px;
    font-size: 12px;
    font-weight: 400;
    color: ${colorConstants.softGrey};
    text-align: center;
`;


export const NoCryptoWalletsFound = (props) => {
    const { onClick } = props;

    return (
        <Container>
            <IconsSection onClick={onClick} >
                <Icon type='crypto' platform="metamask" size={18} color={true} />
                <Icon type='crypto' platform="wallet_connect" size={18} color={true} circle={true} />
                <Icon type='crypto' platform="coinbase" size={18} color={true} />
            </IconsSection>

            <Text>Connect your crypto wallets and access your assets in a single place</Text>

            <Button outline={true} small={true} onClick={onClick} >Connect wallet</Button>
        </Container>
    );
};