import styled from 'styled-components';
import { ChevronDown } from 'react-bootstrap-icons';
import { Logo } from '../Branding';
import { SocialMediaSection } from '../../custom/Buttons';

import { deviceConstants, colorConstants } from "../../../constants";



export const Container = styled.div`
    padding: 0 0 56px 0;

    @media ${deviceConstants.tablet} {
        padding: 0 0 32px 0;
    }
`;


// Bottom Section
export const BottomContainer = styled.div`
    margin: 0 16px;

    @media ${deviceConstants.tablet} {
        margin: 0 18%;
    }
`;

export const BottomContent = styled.div`
    padding: 32px 16px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.tablet} {
        padding: 32px 16px;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const BottomColumnLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.tablet} {
        flex-direction: row;
        justify-content: flex-start;
    }
`;

export const BottomColumnRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    @media ${deviceConstants.tablet} {
        margin-top: 0;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

export const BottomColumnLeftContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const StyledLogo = styled(Logo)`
    height: 35px;
    width: 160px;
    min-width: 160px;

    @media ${deviceConstants.tablet} {
        height: 25px;
        width: 100px;
        min-width: 100px;
    }
`;

export const CopyrightSection = styled.div`
    display: none;

    @media ${deviceConstants.tablet} {
        display: block;

        font-size: 10px;
        font-weight: 400;
        color: ${colorConstants.black};
    
        margin-left: 32px;
    }
`;

export const CopyrightSectionMobile = styled.div`
    font-size: 10px;
    font-weight: 400;
    color: ${colorConstants.black};
    text-align: center;

    margin-top: 40px;

    & > * {
        margin-bottom: 4px;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }

    @media ${deviceConstants.tablet} {
        display: none;
    }
`;

export const PoliciesSection = styled.div`
    display: none;

    @media ${deviceConstants.tablet} {
        display: block;

        font-size: 10px;
        font-weight: 400;
        color: ${colorConstants.black};
    
        margin-left: 32px;

        a {
            text-decoration: none;
            color: ${colorConstants.black};

            :hover {
                color: ${colorConstants.primaryDark};
            }
        }
    }
`;

export const PoliciesSectionMobile = styled.div`
    font-size: 10px;
    font-weight: 400;
    color: ${colorConstants.black};
    text-align: center;

    margin-top: 8px;

    a {
        text-decoration: none;
        color: ${colorConstants.black};

        :hover {
            color: ${colorConstants.primaryDark};
        }
    }

    @media ${deviceConstants.tablet} {
        display: none;
    }
`;

export const SocialIcons = styled(SocialMediaSection)`
    display: none;

    @media ${deviceConstants.tablet} {
        display: block;

        & > * {
            margin-right: 18px;
        }
    
        & > *:last-child {
            margin-right: 0px;
        }
    }
`;

export const SocialIconsMobile = styled(SocialMediaSection)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    & > * {
        margin-right: 20px;
        margin-bottom: 16px;
    }

    & > *:last-child {
        margin-right: 0px;
    }

    @media ${deviceConstants.tablet} {
        display: none;
    }
`;


// Menu Section
export const MenuContainer = styled.div`
    margin: 0 16px;

    @media ${deviceConstants.tablet} {
        margin: 0 18%;
    }
`;

export const MenuContent = styled.div`
    padding: 32px 24px 96px 24px;

    display: flex;
    flex-direction: column;

    & > * {
        margin-bottom: 16px;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }

    @media ${deviceConstants.tablet} {
        padding: 32px 16px 48px 16px;

        flex-direction: row;

        & > * {
            margin-right: 64px;
            margin-bottom: 0;
        }
    
        & > *:last-child {
            margin-right: 0px;
        }
    }
`;

export const MenuColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MenuHeader = styled.h4`
    display: none;

    @media ${deviceConstants.tablet} {
        display: block;
        
        margin: 0 0 16px 0;
        font-weight: 500;
        font-size: 14px;
        color: ${colorConstants.black};
        line-height: normal;
    }
`;

export const MenuHeaderMobileWrapper = styled.div`
    margin-bottom: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    :hover {
        cursor: pointer;

        & > * {
            color: ${colorConstants.primaryDark};
        }
    }

    @media ${deviceConstants.tablet} {
        display: none;
    }
`;

export const MenuHeaderMobile = styled.h4`
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: ${colorConstants.black};
    line-height: normal;
`;

export const MenuHeaderMobileDownButton = styled(ChevronDown)`
    font-size: 14px;
    color: ${colorConstants.black};
`;

export const MenuItemsContainer = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    margin-bottom: ${props => props.show ? '32px' : '0'};
    padding: 0 8px;

    @media ${deviceConstants.tablet} {
        display: flex;
        margin-bottom: 0;
        padding: 0;
    }
`;

export const MenuItem = styled.a`
    font-weight: 500;
    font-size: 12px;
    color: ${colorConstants.mediumGrey};
    text-decoration: none;

    :hover {
        color: ${colorConstants.primaryDark};
        cursor: pointer;
    }
`;