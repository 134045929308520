import styled from 'styled-components';
import Form from 'react-bootstrap/Form'
import FormGroup from 'react-bootstrap/FormGroup'
import FormText from 'react-bootstrap/FormText'
import { deviceConstants, colorConstants, shadowConstants } from "../../../constants";
import { Button } from '../../custom/Buttons';


export const Container = styled.div`
    font-size: 12px;

    @media ${deviceConstants.tablet} {
        display: flex;
        justify-content: center;

        height: 100vh;
        margin-top: 32px;
        padding: 32px;
        border-radius: 8px;
        box-shadow: ${shadowConstants.adminDesktop};
        background: ${colorConstants.white};
    }
`;

export const StyledForm = styled(Form)`
    width: 100%;
`;

export const StyledFormGroup = styled(FormGroup)`
    margin-top: 16px;
`;

export const FormInputTitle = styled.div`
    font-weight: 600;
    color: ${colorConstants.softGrey};
    margin-bottom: 1px;
`;

export const StyledFormText = styled(FormText)`
  color: ${colorConstants.warning};
`;

export const StyledButton = styled(Button)`
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    @media ${deviceConstants.tablet} {
        width: 25%;
        border-radius: 2px;
        
        position: absolute;
        bottom: 20px;
        left: auto;
        right: auto;
    }

    @media ${deviceConstants.desktop} {
        width: 20%;
    }
`;