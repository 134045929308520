import React, { useEffect, useState } from 'react';
import {
    Container,
    Header,
    Title,
    Body,
    AddLinkButton,
    ButtonIcon,
    ButtonText,
} from './ConnectWalletsMenu.styles';
import Icon from '../../../assets/icons/Icon';
import { connectMetaMask } from '../../../utils/crypto/wallet/connectors';
import { obfuscateAddress } from '../../../utils';



export const ConnectWalletsMenu = (props) => {
    const { show, wallets, connectWallet, disconnectWallet, onHide } = props;

    const [metamask, setMetamask] = useState(null);
    const [walletConnect, setWalletConnect] = useState(null);
    const [coinbaseWallet, setCoinbaseWallet] = useState(null);


    // connect wallets
    async function connect(provider) {
        let address = null;

        switch(provider) {
            case 'METAMASK': {
                if(metamask) disconnectWallet(metamask);
                else address = await connectMetaMask();
                break;
            }

            case 'WALLET_CONNECT': {
                if(walletConnect) disconnectWallet(walletConnect);
                // FEATURE: connect wallet connect
                break;
            }

            case 'COINBASE': {
                if(coinbaseWallet) disconnectWallet(coinbaseWallet);
                // FEATURE: connect coinbase wallet
                break;
            }

            default:
                break;
        }

        // send wallet address for processing
        if(address) connectWallet({ type: provider, address });
    }

    useEffect(() => {
        // check which wallets are connected
        if(wallets && wallets.length > 0) {
            wallets.forEach(account => {
                switch(account.type) {
                    case 'METAMASK': {
                        setMetamask(account);
                        break;
                    }

                    case 'WALLET_CONNECT': {
                        setWalletConnect(account);
                        break;
                    }
        
                    case 'COINBASE': {
                        setCoinbaseWallet(account);
                        break;
                    }

                    default:
                        break;
                }
            });
        }

        // reset state when unmounted
        return () => {
            setMetamask(null);
            setWalletConnect(null);
            setCoinbaseWallet(null);
        };
    });
    

    return (
        <Container show={show} onHide={onHide} centered={true} >
            <Header closeButton>
                <Title>Connect Wallet</Title>
            </Header>

            <Body>
                <AddLinkButton onClick={() => connect('METAMASK')} >
                    <ButtonIcon>
                        <Icon type='crypto' platform='metamask' size={30} color={true} />
                    </ButtonIcon>
                    <ButtonText>{metamask ? `Disconnect: ${obfuscateAddress(metamask.address)}` : 'MetaMask'}</ButtonText>
                </AddLinkButton>

                {/* <AddLinkButton onClick={() => connect('WALLET_CONNECT')} >
                    <ButtonIcon>
                        <Icon type='crypto' platform='wallet_connect' size={30} circle={true} color={true} />
                    </ButtonIcon>
                    <ButtonText>{walletConnect ? `Disconnect: ${obfuscateAddress(walletConnect.address)}` : 'WalletConnect'}</ButtonText>
                </AddLinkButton>

                <AddLinkButton onClick={() => connect('COINBASE')} >
                    <ButtonIcon>
                        <Icon type='crypto' platform='coinbase' size={30} color={true} />
                    </ButtonIcon>
                    <ButtonText>{coinbaseWallet ? `Disconnect: ${obfuscateAddress(coinbaseWallet.address)}` : 'Coinbase Wallet'}</ButtonText>
                </AddLinkButton> */}
            </Body>
      </Container>
    );
};