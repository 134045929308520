import styled from 'styled-components';
import { colorConstants, deviceConstants } from '../../../constants';


export const Container = styled.div`
    padding: 16px 32px;
    border-radius: 4px;
    background: ${colorConstants.gradientHorizontal};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    max-height: 80px;
    overflow-y: auto;

    position: fixed;
    z-index: 110;
    bottom: 16px;
    left: 16px;
    right: 16px;

    -ms-overflow-style: none;  // hide scrollbar for IE 10+
    overflow: -moz-scrollbars-none;  // hide scrollbar for Firefox
    ::-webkit-scrollbar {
        display: none; // hide scrollbar for Safari and Chrome
    }

    @media ${deviceConstants.tablet} { 
        right: auto;
        max-width: 33%;
    }

    @media ${deviceConstants.laptop} { 
        max-width: 30%;
    }

    @media ${deviceConstants.desktop} { 

    }
`;

export const Body = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: ${colorConstants.white};
    text-align: ${props => props.centerText ? 'center' : 'left'};

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: none;
`;

export const Close = styled.div`
    position: absolute !important;
    top: 2px;
    right: 12px;

    font-size: 12px;
    font-weight: 500;

    color: ${colorConstants.white};
`;