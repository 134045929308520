import React, { Component } from "react";
import { connect } from 'react-redux';

import { productActions, alertActions } from '../../../actions';
import { ScreenContainer } from './EditProductContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { Notification } from '../../../components/custom/Popup';
import { EditProduct }  from "../../../components/products";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { TopBar } from '../../../components/custom/TopBar';
import ImageCrop from "../../../components/cropper/ImageCrop";
import {
    defaultProduct,
    defaultPhotos,
    history,
    notNull,
    compressImage,
    storageRef,
    uploadFile,
    getFileDownloadURL,
    removeTokenFromFileDownloadURL,
    createSlug,
    finalizeProductData,
    getImageFileDimensions
} from '../../../utils';
import { sitemap, topbarConstants, contentAddProductPage, otherConstants } from "../../../constants";


class EditProductContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            init: false,
            sticky: false,
            typingTimeout: 0, // used to know when user stops typing
            fromUrl: sitemap.admin.products.list,
            updating: false,
            photos: defaultPhotos(),
            product: defaultProduct(contentAddProductPage.options.types[0]),
            productDescriptionLength: 0 // used to force user to type a minimum description before adding
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleDescriptionLengthChange = this.handleDescriptionLengthChange.bind(this);
        
        this.handleUploadPhoto1 = this.handleUploadPhoto1.bind(this);
        this.handleUploadPhoto2 = this.handleUploadPhoto2.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if(this.props.userId && this.props.location.state && this.props.location.state.from && this.props.location.state.data && this.props.location.state.data.productId) {
            this.setState({
                fromUrl: this.props.location.state.from,
                product: {
                    ...this.state.product,
                    id: this.props.location.state.data.productId,
                    userId: this.props.userId
                }
            });
            this.props.getById(this.props.location.state.data.productId, null); // get product from db
        } else {
            history.replace({ pathname: this.state.fromUrl, state: { error: 'Invalid container startup props' } });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        if(!this.state.init && this.props.product &&  this.props.product.id) this.setState({ ...this.state, init: true, product: this.props.product });
    }

    handleScroll(event) {
        // Enable sticky mode on scroll
        this.setState({ ...this.state, sticky: window.pageYOffset > 1 });
    }

    handleTopBarBackButton() {
        if(this.state.photos.crop.pendingImage) this.setState({ ...this.state, photos: { crop: defaultPhotos().crop } }); // reset crop image screen
        else {
            // Go back
            history.push({
                pathname: this.state.fromUrl,
                state: {
                    data: {
                        productId: this.state.product.id
                    }
                }
            });
        }
    }

    handleChange(event) {
        let { name, value } = event.target;
        let withTimeout = true;

        if(name === 'customUrl') {
            // limit product custom url to minimum 5 characters
            if(!value || value.length <= 5) return;
            // create slug from input
            value = createSlug(value);
        } else if(name === 'simple-link-label') {
            // save btnLabel value
            name = 'button';
            value = { ...this.state.product.button, label: value };
        } else if(name === 'simple-link-url') {
            // save btnUrl value
            name = 'button';
            value = { ...this.state.product.button, url: value };
        } else if(name === 'product-enable') {
            name = 'enabled';
            value = !this.state.product.enabled;
            withTimeout = false;
        } else if(name === 'skipDetailsPage-enable') {
            name = 'skipDetailsPage';
            value = !this.state.product.skipDetailsPage;
            withTimeout = false;
        } else if(name === 'description') {
            if(!value || value.length < 57 || this.state.productDescriptionLength < 50) return;
        }

        this.setState({
            updating: false,
            typingTimeout: withTimeout ? setTimeout(() => { this.props.update(finalizeProductData(this.state.product)) }, 1000) : null,
            product: {
                ...this.state.product,
                [name]: value, // update input data
            }
        }, () => !withTimeout ? this.props.update(finalizeProductData(this.state.product)) : null);

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleDescriptionLengthChange(length){
        this.setState({ ...this.state, productDescriptionLength: length });
    }

    handleUploadPhoto1(e) {
        // Save inserted image url in state
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save inserted photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: 'photo-1',
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    handleUploadPhoto2(e) {
        // Save inserted image url in state
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save inserted photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: 'photo-2',
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    async handleCroppedImage(croppedImage) {

        // enable spinner and reset pending image for cropping
        this.setState({ ...this.state, updating: true, photos: { crop: defaultPhotos().crop } });

        // get product photo urls from state
        let { imageUrls } = this.state.product;
        if(!imageUrls) imageUrls = [];

        // compress cropped image file
        const compressedFile = await compressImage(croppedImage, croppedImage.name, 1080);

        // create firebase storage reference of image to upload
        const imageRef = storageRef(otherConstants.environment + '/users/' + this.state.product.userId + '/products/' + this.state.product.id + '/photos/product-' + this.state.product.id + '-' + compressedFile.name);

        // Upload image to firebase storage
        const snapshot = await uploadFile(imageRef, compressedFile);

        // Get firebase public url of uploaded photo
        let uploadedPhotoFirebaseUrl = await getFileDownloadURL(snapshot.ref);

        // Clean public url
        uploadedPhotoFirebaseUrl = removeTokenFromFileDownloadURL(uploadedPhotoFirebaseUrl);

        // save uploaded photo URL
        imageUrls[croppedImage.name.includes('photo-1') ? 0 : 1] = uploadedPhotoFirebaseUrl;

        this.setState({
            ...this.state,
            product: {
                ...this.state.product,
                imageUrls // save uploaded photo URL
            }
        }, async () => {
            // update photo urls in db
            await this.props.update({ ...finalizeProductData(this.state.product), imageUrls });

            // disable updating spinner
            this.setState({ ...this.state, updating: false });
        });
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.editProduct} />

                <TopBar
                    sticky={this.state.sticky}
                    title={this.state.photos.crop.pendingImage ? topbarConstants.titles.crop : topbarConstants.titles.editProduct}
                    backButton={this.handleTopBarBackButton} />

                { this.state.init && !this.state.photos.crop.pendingImage &&
                <EditProduct
                    product={this.state.product}
                    handleChange={this.handleChange}
                    handleDescriptionLengthChange={this.handleDescriptionLengthChange}
                    productDescriptionLength={this.state.productDescriptionLength}
                    handleUploadPhoto1={this.handleUploadPhoto1}
                    handleUploadPhoto2={this.handleUploadPhoto2} /> }

                { this.state.photos.crop.pendingImage &&
                <ImageCrop
                    url={this.state.photos.crop.pendingImage}
                    filename={this.state.photos.crop.pendingImageFilename}
                    fileSize={this.state.photos.crop.pendingImageFileSize}
                    imageDimensions={this.state.photos.crop.pendingImageDimensions}
                    circularCrop={false}
                    handleCroppedImage={this.handleCroppedImage} /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                {this.state.updating && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, product } = state;
    
    // export state data to props
    return { userId, alert, product };
}

const actionCreators = {
    update: productActions.update,
    getById: productActions.getById,
    clearAlert: alertActions.clear
}

const connectedEditProductContainer = connect(mapState, actionCreators)(EditProductContainer);
export { connectedEditProductContainer as EditProductContainer };