import qs from 'qs';
import { authReducerConstants, userReducerConstants, sitemap } from "../constants";
import { authService } from "../services";
import { alertActions } from './';
import { history } from '../utils';

export const authActions = {
    login,
    logout,
    register,
    loginWithSocialPopup,
    loginWithSocial,
    loadSocialLoginResults,
    registerGuestUser,
    loginGuestUser,
    confirmEmail,
    updatePassword,
    resetPasswordRequest,
    resetPassword,
    loadCsrfToken,
    clear,
};

function login(data, props) {
    return dispatch => {
        dispatch(request());

        authService.login(data)
            .then(
                cookie => { 
                    dispatch(success(cookie));

                    if (props && props.from) {
                        history.push({ ...props, pathname: props.from }); // forward props if login requested from another page
                    } else {
                        history.push(sitemap.admin.dashboard); // default logged in page
                    }
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: authReducerConstants.LOGIN_REQUEST } }
    function success(cookie) { return { type: authReducerConstants.LOGIN_SUCCESS, cookie } }
    function failure() { return { type: authReducerConstants.LOGIN_FAILURE } }
}

function logout() {
    return dispatch => {
        authService.logout()
                .then(() => dispatch({ type: authReducerConstants.LOGOUT }))
                .then(() => dispatch({ type: userReducerConstants.CLEAR_ALL }))
                .then(() => history.push(sitemap.auth.login));
    };
}

function register(user) {
    return dispatch => {
        dispatch(request());

        authService.register(user)
            .then(
                () => { 
                    dispatch(success());
                    history.push(sitemap.auth.login);
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: authReducerConstants.REGISTER_REQUEST } }
    function success() { return { type: authReducerConstants.REGISTER_SUCCESS } }
    function failure() { return { type: authReducerConstants.REGISTER_FAILURE } }
}

function loginWithSocialPopup(props) {
    return dispatch => {
        dispatch(request());

        authService.loginWithSocial(props, true)
            .then(
                data => { 
                    // get query params from props
                    if(props && props.params && props.params.search) {
                        let query = qs.parse(props.params.search, { ignoreQueryPrefix: true });
                        const { code } = query;
                        data.code = code;
                    }
                    // login with to server with social login data (also append invite code if any)
                    authService.loginWithSocial(data)
                        .then(
                            cookie => { 
                                dispatch(success(cookie));

                                if(props && props.params && props.params.from) {
                                    history.push({ ...props.params, pathname: props.params.from }); // forward props if login requested from another page
                                } else {
                                    history.push(sitemap.admin.dashboard); // default logged in page
                                }
                            },
                            error => {
                                dispatch(failure());
                                dispatch(alertActions.error(error.toString()));
                                history.replace({ pathname: sitemap.landing.home });
                            }
                        );
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: authReducerConstants.LOGIN_WITH_SOCIAL_REQUEST } }
    function success(cookie) { return { type: authReducerConstants.LOGIN_SUCCESS, cookie } }
    function failure() { return { type: authReducerConstants.LOGIN_FAILURE } }
}

function loginWithSocial(data) {
    return dispatch => {
        dispatch(request());

        authService.loginWithSocial(data)
            .then(
                () => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: authReducerConstants.LOGIN_WITH_SOCIAL_REQUEST } }
    function success() { return { type: authReducerConstants.LOGIN_WITH_SOCIAL_SUCCESS } }
    function failure() { return { type: authReducerConstants.LOGIN_FAILURE } }
}

function loadSocialLoginResults(props){
    return dispatch => {
        dispatch(request());

        authService.loadSocialLoginResults()
            .then(
                data => {
                    // get queries from props
                    let query = qs.parse(props.search, { ignoreQueryPrefix: true });
                    // get invite code
                    const { code } = query;
                    // login with to server with social login data (also append invite code if any)
                    authService.loginWithSocial({ ...data, code })
                        .then(
                            cookie => { 
                                dispatch(success(cookie));

                                if (props && props.from) {
                                    history.push({ ...props, pathname: props.from }); // forward props if login requested from another page
                                } else {
                                    history.push(sitemap.admin.dashboard); // default logged in page
                                }
                            },
                            error => {
                                dispatch(failure());
                                dispatch(alertActions.error(error.toString()));
                                history.replace({ pathname: sitemap.landing.home });
                            }
                        );
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: authReducerConstants.LOGIN_WITH_SOCIAL_REQUEST } }
    function success(cookie) { return { type: authReducerConstants.LOGIN_SUCCESS, cookie } }
    function failure() { return { type: authReducerConstants.LOGIN_FAILURE } }
}

function registerGuestUser() {
    return dispatch => {
        return authService.registerGuestUser()
            .then(
                cookie => {
                    dispatch(success(cookie));
                },
                error => {
                    console.log('ERR_AUTHORIZING_SESSION: ', error);
                }
            );
    };

    function success(cookie) { return { type: authReducerConstants.LOGIN_SUCCESS, cookie } }
}

function loginGuestUser(data) {
    // clear any previous user data
    clear();
    // autorize guest user
    return dispatch => {
        return authService.loginGuestUser(data)
            .then(
                cookie => {
                    dispatch(success(cookie));
                },
                error => {
                    console.log('ERR_AUTHORIZING_SESSION: ', error);
                }
            );
    };

    function success(cookie) { return { type: authReducerConstants.LOGIN_SUCCESS, cookie } }
}

function confirmEmail(id, data) {
    return dispatch => {
        dispatch(request());

        return authService.confirmEmail(id, data)
            .then(
                res => { 
                    dispatch(success());
                    dispatch(alertActions.success(res.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: authReducerConstants.CONFIRM_EMAIL_REQUEST } }
    function success() { return { type: authReducerConstants.CONFIRM_EMAIL_SUCCESS } }
    function failure(error) { return { type: authReducerConstants.CONFIRM_EMAIL_FAILURE, error } }
}

function updatePassword(id, data) {
    return dispatch => {
        dispatch(request());

        authService.updatePassword(id, data)
            .then(
                () => {
                    history.push(sitemap.auth.login);
                    dispatch(success());
                    dispatch(alertActions.success('Password updated successfully. Login with your new password.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: authReducerConstants.UPDATEPASSWORD_REQUEST } }
    function success() { return { type: authReducerConstants.UPDATEPASSWORD_SUCCESS } }
    function failure(error) { return { type: authReducerConstants.UPDATEPASSWORD_FAILURE, error } }
}

function resetPasswordRequest(data) {
    return dispatch => {
        dispatch(request());

        authService.resetPasswordRequest(data)
            .then(
                () => {
                    dispatch(success());
                    dispatch(alertActions.success('Reset password request successfully.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: authReducerConstants.RESET_PASSWORD_REQUEST_REQUEST } }
    function success() { return { type: authReducerConstants.RESET_PASSWORD_REQUEST_SUCCESS } }
    function failure(error) { return { type: authReducerConstants.RESET_PASSWORD_REQUEST_FAILURE, error } }
}

function resetPassword(data) {
    return dispatch => {
        dispatch(request());

        authService.resetPassword(data)
            .then(
                () => {
                    history.push(sitemap.auth.login);
                    dispatch(success());
                    dispatch(alertActions.success('Password reset successfully.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: authReducerConstants.RESET_PASSWORD_REQUEST } }
    function success() { return { type: authReducerConstants.RESET_PASSWORD_SUCCESS } }
    function failure(error) { return { type: authReducerConstants.RESET_PASSWORD_FAILURE, error } }
}

function loadCsrfToken(){
    return dispatch => {
        return authService.loadCsrfToken()
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    console.log('ERR_AUTHORIZING_CLIENT: ', error);
                }
            );
    };

    function success() { return { type: authReducerConstants.LOAD_CSRF_TOKEN_SUCCESS } }
}

function clear() {
    return { type: authReducerConstants.CLEAR_ALL };
}