import React from 'react';
import {
    Container,
    Title,
    Message,
    StyledButton,
} from './ResetPasswordRequestCompleted.styles';
import Icon from '../../../assets/icons/Icon';
import { colorConstants } from "../../../constants";


const ResetPasswordRequestCompleted = (props) => {
    const { email, handleSubmit } = props;

    return (
        <Container>
            <Icon type='email' circle={true} customcolor={colorConstants.primaryDark} size={100} />

            <Title>Check Your Email</Title>

            <Message>
                Please check the email address {email} for instructions to reset your password.
            </Message>

            <StyledButton
                outline={true}
                type="submit"
                onClick={handleSubmit}
            >
                Resend Email
            </StyledButton>
        </Container>
    );
};

export default ResetPasswordRequestCompleted;