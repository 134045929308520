import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import { HelmetProvider } from 'react-helmet-async';

import './index.css';
import { AppContainer } from './containers/app';
import { MetaTags } from './components/custom/Helmet';
import { store } from './utils';
import { analytics } from './utils';


analytics.init();


render(
  <React.StrictMode>
    <HelmetProvider>
      <MetaTags init={true} />
      <Provider store={store}>
        <AppContainer />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);