import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';

import { authActions, tripActions, publicTripsActions, hotelActions, hotelBookingActions } from '../../../actions';
import { ScreenContainer } from './TripContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { TripPage } from '../../../components/trips';
import { Warning, Share, DatePicker, Map } from '../../../components/custom/Popup';
import { ChildrenAgesPopup } from '../../../components/trips/trip/ChildrenAgesPopup';
import { HotelRoomCheckRatePopup } from '../../../components/trips/trip/HotelRoomCheckRatePopup';
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { history, defaultTrip, hotelBookingCheckoutConfigData, dateDifferenceIn } from '../../../utils';
import { featureOptions, contentTripPage, colorConstants, sitemap, urlConstants, getTripLink, getProfileLink } from '../../../constants';


export const TripContainer = () => {
    const dispatch = useDispatch();

    // get params and data
    const { username, slug } = useParams();
    const { state, pathname } = useLocation();

    // application state
    const { cookie, loading, publicTripData, privateTripData, hotel, hotelBooking } = useSelector(state => ({
        cookie: state.auth.cookie,
        loading: state.publicTrips.loading,
        publicTripData: state.publicTrips.trips[0],
        privateTripData: state.trip,
        hotel: state.hotel,
        hotelBooking: state.hotelBooking,
    }));

    // component state
    const [booking, setBooking] = useState({
        dates: {},
        occupancyTotal: 1, // sum of adults and children that must not exceed 16
        occupancyTotalMax: 16,
        adults: 1,
        adultsMax: 16,
        children: 0,
        childrenMax: 15,
        childrenAges: [],
        infants: 0,
        rooms: 1,
        roomsMax: 4,
        doubleBedType: true,
        mealOptions: [],
        roomOptions: [],
        selectedMeal: 0,
        selectedOccupancy: 1,
        selectedCancellationPolicy: '',
        hasCancellationPoliciesOptions: false,
        selectedRoom: {},
        selectedRoomRates: [],
        selectedRoomRate: {},
        currency: {
            code: 'EUR',
            symbol: '',
        },
    });
    const [trip, setTrip] = useState({ ...defaultTrip(featureOptions.trip.types[0]), creator: {}, partner: {} });
    const [isFavTrip, setIsFavTrip] = useState(false);
    const [fromUrl, setFromUrl] = useState(sitemap.admin.dashboard);
    const [init, setInit] = useState(false); // initialize trip data from server db
    const [authenticated, setAuthenticated] = useState(false); // used for hide/show topbar for logged in users
    const [isCreator, setIsCreator] = useState(false); // true if current user is the creator of the trip
    const [sticky, setSticky] = useState(false); // make topbar sticky
    const [deleteWarning, setDeleteWarning] = useState(false); // hide/show delete trip warning
    const [deleting, setDeleting] = useState(false); // deleting trip flag
    const [sharing, setSharing] = useState(false); // hide/show share popup
    const [occupancyOptionsToggle, setOccupancyOptionsToggle] = useState(false); // toggle occupancy options
    const [enableAvailabilityCheckRequired, setEnableAvailabilityCheckRequired] = useState(true); // enable/disable availability check requests when changing options
    const [availabilityCheckRequired, setAvailabilityCheckRequired] = useState(true); // highlights the search button and disables checkout button when true
    const [availabilityCheckButtonText, setAvailabilityCheckButtonText] = useState('SEARCH');
    const [datePicker, setDatePicker] = useState(false); // toggle date picker popup
    const [privateInit, setPrivateInit] = useState(false); // initialize private trip data from server db
    const [initCheckoutScreen, setInitCheckoutScreen] = useState(false);
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [showChildrenAges, setShowChildrenAges] = useState(false);
    const [hotelRoomCheckRateInit, setHotelRoomCheckRateInit] = useState(false);
    const [showHotelRoomCheckRate, setShowHotelRoomCheckRate] = useState(false);
    const [hotelRoomCheckRate, setHotelRoomCheckRate] = useState(null);
    const [isSearchExpired, setIsSearchExpired] = useState(false);
    const [isMapVisible, setIsMapVisible] = useState(false);

    const isAvailabilityCheckEnabled = useMemo(() => {
        if(enableAvailabilityCheckRequired && availabilityCheckRequired) return true;
        return false;
    }, [enableAvailabilityCheckRequired, availabilityCheckRequired]);

    // enable sticky mode on scroll event
    const handleScroll = useCallback((event) => {
        setSticky(window.pageYOffset > 1);
    }, [setSticky]);


    /** componentDidMount **/
    useEffect(() => {
        if(!init && !componentDidMount) {
            // set component did mount
            setComponentDidMount(true);

            // load csrf token in cookies
            dispatch(authActions.loadCsrfToken());

            if(state) {
                // set from URL
                if(state.from) setFromUrl(state.from);
                // get data from previous page
                if(state.data) {
                    const { profileTransitionAnimation } = state.data;
                    // enable transition animations
                    if(profileTransitionAnimation) {
                        
                    }
                }
            }

            // listen to scroll events
            window.addEventListener('scroll', handleScroll);
            window.scrollTo(0, 0); // force scroll to top of page

            // get public trip data
            dispatch(publicTripsActions.getBySlug(username, slug));

            // execute private startup methods for authenticated users
            if(cookie && cookie.id) {
                // set authenticated mode
                setAuthenticated(true);

                // get authenticate trip data for creator
                let query = { username, visitorUserId: cookie.id, };
                dispatch(tripActions.getBySlug(slug, new URLSearchParams(query)));

            } else {

                // check if has guest account
                let isGuest = cookie && cookie.guestUserId && cookie.guestUserCreatedAt;

                // check if cookie older than 24 hours (1 Day)
                if(isGuest) {
                    const diff = dateDifferenceIn(new Date(cookie.guestUserCreatedAt), new Date(), 'HOURS');
                    // force new guest user creation if cookie older than 1 day
                    if(diff >= 24) isGuest = false;
                }

                // register new guest user session for checkout
                if(!isGuest) dispatch(authActions.registerGuestUser());
            }
        }
    }, [init, componentDidMount, dispatch, state, cookie, slug, username, handleScroll]);


    /** componentWillUnmount **/
    useEffect(() => {
        return () => {
            // remove scroll listener
            window.removeEventListener('scroll', handleScroll);
            // reset any previous room types
            dispatch(hotelActions.clear());
            // reset trip data from state
            dispatch(publicTripsActions.reset());
            // reset private trip data
            dispatch(tripActions.clear());
        }
    }, [handleScroll, dispatch]);


    /** componentDidUpdate **/
    useEffect(() => {
        // save trip data
        if(!init && !loading && publicTripData && publicTripData.id && publicTripData.creator) {
            setInit(true);
            setIsCreator(cookie && cookie.id === publicTripData.creator.id);
            setTrip(publicTripData);
        }

        // save private trip data (for authenticated users)
        if(authenticated && init && !privateInit && privateTripData && privateTripData.id) {
            setPrivateInit(true);
            setIsFavTrip(privateTripData.favourite);
        }

        // save room type options and room type data from hotel into trip
        if(hotel && hotel.rooms && hotel.rooms.data && hotel.rooms.data.length > 0 && booking.roomOptions.length === 0) {
            const { data, options } = hotel.rooms;

            // update room data
            setTrip(prev => ({ ...prev, partner: { ...prev.partner, rooms: data } }));

            // get options
            setEnableAvailabilityCheckRequired(options.enableAvailabilityCheckRequired); // enable/disable availability check requests based on supplier API
            let roomOptions = options.rooms;
            let mealOptions = options.meals;
            // get room
            let selectedRoom = data[0];
            let selectedRoomRates = data[0].rates;
            // get room with available rates (if first does not)
            if(!selectedRoomRates || selectedRoomRates.length === 0) {
                for(let i = 0; i < data.length; i++) {
                    if(data[i].rates.length > 0) {
                        selectedRoom = data[i];
                        selectedRoomRates = data[i].rates;
                        break;
                    }
                }
            }

            if(selectedRoomRates.length > 0) {
                // filter selected rates based on meal
                if(options.meals.length > 0) {
                    let mealFilteredRates = selectedRoomRates.filter(rate => rate.meal[options.meals[0].type]);
                    if(mealFilteredRates.length > 0) selectedRoomRates = mealFilteredRates;
                }

                // set new selected rate
                let selectedRoomRate = selectedRoomRates[0];

                // set currency
                let code = selectedRoomRate.price.currencyCode;
                let symbol = getSymbolFromCurrency(code);

                // set max rooms available
                let roomsMax = booking.roomsMax;
                if(selectedRoomRate.roomsOnSale && selectedRoomRate.roomsOnSale <= 4) roomsMax = selectedRoomRate.roomsOnSale;
                else roomsMax = 4;

                // set selected cancellation option
                let selectedCancellationPolicy = selectedRoomRate.cancellationPolicy.type;
                // toggle cancellation options selector
                let hasCancellationPoliciesOptions = selectedRoomRates.length > 1 && selectedRoom.options && selectedRoom.options.cancellation && selectedRoom.options.cancellation.length > 1;

                // update booking options
                setBooking(prev => ({ ...prev, roomOptions, mealOptions, selectedRoom, selectedRoomRates, selectedRoomRate, currency: { code, symbol }, roomsMax, selectedCancellationPolicy, hasCancellationPoliciesOptions }));

            } else {
                // update booking options
                setBooking(prev => ({ ...prev, roomOptions, mealOptions, selectedRoom, selectedRoomRates }));
            }
        }

        // update selected room rate with Check Rate data
        if(!hotelRoomCheckRateInit && booking.selectedRoomRate && booking.selectedRoomRate.id && hotel && (hotel.checkRate || hotel.checkRateError)) {

            // initialize check rate
            setHotelRoomCheckRateInit(true);

            if(hotel.checkRateError) {
                // force user to get new hotel availability data
                setAvailabilityCheckRequired(true);

            } else if(hotel.checkRate) {
                if(hotel.checkRate.isValid) {
                    // load booking checkout configs
                    dispatch(hotelBookingActions.checkoutConfig(hotelBookingCheckoutConfigData({
                        ...booking,
                        checkRateCreatedAt: hotel.checkRate.createdAt,
                        userId: cookie.id,
                        guestUserId: cookie.guestUserId,
                        checkInFrom: trip.partner.checkInFrom,
                        checkOutUntil: trip.partner.checkOutTo,
                        partnerId: trip.partner.id,
                        creatorId: trip.creator.id,
                    }), cookie.guestUserId));

                } else {
                    // update selected room rate data with latest check rate data
                    let { selectedRoom, selectedRoomRates, selectedRoomRate } = booking;
                    selectedRoom.rates = selectedRoom.rates.map(rate => rate.id === hotel.checkRate.id ? hotel.checkRate : rate);
                    selectedRoomRate = hotel.checkRate;
                    selectedRoomRates = selectedRoomRates.map(rate => rate.id === hotel.checkRate.id ? hotel.checkRate : rate);
                    setBooking(prev => ({ ...prev, selectedRoom, selectedRoomRates, selectedRoomRate }));

                    let { rooms } = trip.partner;
                    rooms = rooms.map(room => ({ ...room, rates: room.rates.map(rate => rate.id === hotel.checkRate.id ? hotel.checkRate : rate) }));
                    setTrip(prev => ({ ...prev, partner: { ...prev.partner, rooms } }));

                    // set hotel room check rate data
                    const imageUrl = hotel.checkRate.photos ? hotel.checkRate.photos[0] : trip.partner.photos ? trip.partner.photos[0] : trip.google.photos ? trip.google.photos[0] : null;
                    setHotelRoomCheckRate({
                        ...hotel.checkRate,
                        roomNotes: selectedRoom.notes,
                        image: imageUrl,
                        start: booking.dates.from.label,
                        end: booking.dates.to.label,
                        hotelName: trip.name,
                        roomName: selectedRoom.name,
                        rooms: booking.rooms,
                        adults: booking.adults,
                    });

                    // show check rate popup
                    setShowHotelRoomCheckRate(true);
                }
            }
        }

        // open checkout screen
        if(init && !initCheckoutScreen) {
            // hotel booking configs
            if(hotelBooking && !hotelBooking.loadingConfigs && hotelBooking.config) {
                if(hotelBooking.config.status === 'success') {
                    // validate checkout configs
                    const isTypeValid = hotelBooking.config.type === trip.type;
                    const isUserIdValid = (hotelBooking.config.userId === cookie.id) || (hotelBooking.config.guestUserId === cookie.guestUserId);

                    // go to checkout screen
                    if(isTypeValid && isUserIdValid) {
                        setInitCheckoutScreen(true);
                        history.push({
                            pathname: sitemap.admin.trips.checkout,
                            state: {
                                from: pathname,
                                data: {
                                    userId: cookie.id,
                                    guestUserId: cookie.guestUserId,
                                }
                            }
                        });
                    }
                }
            }
        }

    }, [init, loading, publicTripData, authenticated, privateInit, privateTripData, hotel, booking, initCheckoutScreen, hotelBooking, trip, pathname, cookie, hotelRoomCheckRateInit, dispatch]);


    // go back to previous screen
    const handleTopBarBackButton = useCallback(() => {
        history.push({
            pathname: fromUrl,
            state: {
                from: getTripLink(trip.creator.username, slug),
                data: {
                    tripId: trip.id
                }
            }
        });

    }, [fromUrl, trip, slug]);

    const handleOpenTerms = useCallback((option) => {
        let url = null;

        switch(option) {
            case 'TERMS': {
                url = sitemap.landing.z.policies.terms;
                break;
            }

            case 'TERMS_CUSTOMERS': {
                url = sitemap.landing.z.policies.termsForCustomers;
                break;
            }

            case 'PRIVACY': {
                url = sitemap.landing.z.policies.privacy;
                break;
            }

            default:
                break;
        }

        if(url) window.open(urlConstants.baseUrlClient + url);
    }, []);

    const handleDeleteTripRequest = useCallback(() => {
        // additional auth check
        if(!cookie || !authenticated) return;

        // show delete trip warning
        setDeleteWarning(true);
    }, [cookie, authenticated]);

    const handleDeleteTrip = useCallback(async () => {

        // additional auth check
        if(!cookie || !authenticated) return;

        // start spinner and hide warning
        setDeleting(true);
        setDeleteWarning(false);

        // Delete trip from db & photos from firebase
        dispatch(tripActions.delete(trip.id));

        // stop spinner
        setDeleting(false);

        // go back to previous screen
        history.push({ pathname: fromUrl });
    }, [cookie, authenticated, trip, fromUrl, dispatch]);

    // hide delete trip warning
    const handleDeleteTripCancel = useCallback(() => {
        setDeleteWarning(false);
    }, [setDeleteWarning]);

    const handleEditTrip = useCallback(() => {
        // Open edit trip page
        history.push({
            pathname: sitemap.admin.trips.edit,
            state: {
                from: getTripLink(trip.creator.username, slug),
                data: {
                    tripId: trip.id
                }
            }
        });
    }, [trip, slug]);

    // hide/show sharing popup
    const handleShareTrip = useCallback(() => {
        setSharing(prev => !prev);
    }, []);

    const handleFavorTrip = useCallback(() => {
        if(!authenticated) {
            // redirect to login
            history.push({ pathname: sitemap.auth.login });
            return;
        }

        // add trip to favourites
        if(!isFavTrip) {
            // update state
            setIsFavTrip(true);
            // update in db
            dispatch(tripActions.favourite({ id: trip.id, visitorUserId: cookie.id, status: true }));
        }
    }, [authenticated, isFavTrip, trip, cookie, dispatch]);

    const handleUnFavorTrip= useCallback(() => {
        if(!authenticated) {
            // redirect to login
            history.push({ pathname: sitemap.auth.login });
            return;
        }

        if(isFavTrip) {
            // update state
            setIsFavTrip(false);
            // update in db
            dispatch(tripActions.favourite({ id: trip.id, visitorUserId: cookie.id, status: false }));
        }
    }, [authenticated, isFavTrip, trip, cookie, dispatch]);

    // open creator profile
    const handleViewCreatorProfile = useCallback((username) => {
        window.open(urlConstants.baseUrlClient + getProfileLink(username));
    }, []);

    // open map view
    const handleOpenMap = useCallback(() => {
        setIsMapVisible(true);
    }, []);

    const handleDatePickerToggle = useCallback(() => {
        // // only show availability to logged-in users
        // if(!authenticated) {
        //     // redirect to login
        //     history.push({
        //         pathname: sitemap.auth.login,
        //         state: {
        //             from: getTripLink(trip.creator.username, trip.customUrl),
        //             data: {
        //                 tripId: trip.id
        //             }
        //         }
        //     });
        //     return;
        // }

        setDatePicker(prev => !prev);
    }, []);

    const handleDatePickerSelect = useCallback((dates) => {
        // update booking dates
        setBooking(prev => ({ ...prev, dates }));

        if(dates.from && dates.from.date && dates.to && dates.to.date) {
            // reset any previous room types
            dispatch(hotelActions.clear());
            // reset any previous hotel booking checkout configs
            dispatch(hotelBookingActions.resetCheckoutConfig());

            // reset booking options
            let mealOptions = [];
            let selectedMeal = 0;
            let roomOptions = [];
            let selectedRoom = {};
            let selectedRoomRate = {};
            setTrip(prev => ({ ...prev, partner: { ...prev.partner, rooms: [] } }));
            setBooking(prev => ({ ...prev, mealOptions, selectedMeal, roomOptions, selectedRoom, selectedRoomRate, }));
            setOccupancyOptionsToggle(true);
            setEnableAvailabilityCheckRequired(true);
            setAvailabilityCheckRequired(true);
        }
    }, [dispatch]);

    const handleAvailabilitySearch = useCallback(() => {
        // exit if no availability check is required
        if(!isAvailabilityCheckEnabled) return;

        // reset any previous room types
        dispatch(hotelActions.clear());
        // reset any previous hotel booking checkout configs
        dispatch(hotelBookingActions.resetCheckoutConfig());

        // reset booking options
        let mealOptions = [];
        let selectedMeal = 0;
        let roomOptions = [];
        let selectedRoom = {};
        let selectedRoomRate = {};
        setTrip(prev => ({ ...prev, partner: { ...prev.partner, rooms: [] } }));
        setBooking(prev => ({ ...prev, mealOptions, selectedMeal, roomOptions, selectedRoom, selectedRoomRate, }));

        // create availability request configs
        let hotelAvailabilityRequestConfigs = {
            start: booking.dates.from.date,
            end: booking.dates.to.date,
            nights: booking.dates.totalDays,
            // rooms: booking.rooms, // FEATURE: enable for multiroom booking feature and remove below line (rooms: 1,)
            rooms: 1,
            adults: booking.adults,
            children: booking.children,
        };
        // add children ages
        if(booking.children > 0) hotelAvailabilityRequestConfigs.childrenAges = booking.childrenAges;
        // add guest user id when guest checkout session
        if(cookie.guestUserId) hotelAvailabilityRequestConfigs.guestUserId = cookie.guestUserId;
        // request hotel rates for selected dates
        dispatch(hotelActions.getAvailability(trip.partner.id, new URLSearchParams(hotelAvailabilityRequestConfigs), cookie.guestUserId));

        setAvailabilityCheckRequired(false);
        setAvailabilityCheckButtonText('CHANGE SEARCH');
        setIsSearchExpired(false);
    }, [trip, booking, dispatch, isAvailabilityCheckEnabled, cookie]);

    const handleBookingOptions = useCallback((option, value, metadata) => {
        // reset any previous hotel booking checkout configs
        dispatch(hotelBookingActions.resetCheckoutConfig());
        
        // initialize updated state
        var newState = { ...booking };

        // flag for updating selected room rate
        let requestSelectedRateUpdate = false;

        switch (option) {

            case 'adults': {
                // calculate new occupancyTotal value
                let occupancyTotal = value + booking.children;
                // calculate new adult max value
                let adultsMax = (booking.occupancyTotalMax - occupancyTotal) + value;
                // calculate new children max value
                let childrenMax = (booking.occupancyTotalMax - occupancyTotal) + booking.children;
                // set new values to updated state
                newState.adults = value;
                newState.occupancyTotal = occupancyTotal;
                newState.adultsMax = adultsMax;
                newState.childrenMax = childrenMax;
                // request selected rate update
                requestSelectedRateUpdate = true;
                setAvailabilityCheckRequired(true);
                setAvailabilityCheckButtonText(prev => prev !== 'SEARCH' ? 'APPLY CHANGES' : prev);
                break;
            }

            case 'children': {
                // calculate new occupancyTotal value
                let occupancyTotal = booking.adults + value;
                // calculate new adult max value
                let adultsMax = (booking.occupancyTotalMax - occupancyTotal) + booking.adults;
                // calculate new children max value
                let childrenMax = (booking.occupancyTotalMax - occupancyTotal) + value;
                // check if is increasing/decreasing number of children
                const isIncrease = value > newState.children;
                const isDecreasing = value < newState.children;
                // set new values to updated state
                newState.children = value;
                newState.occupancyTotal = occupancyTotal;
                newState.adultsMax = adultsMax;
                newState.childrenMax = childrenMax;
                // request selected rate update
                requestSelectedRateUpdate = true;
                setAvailabilityCheckRequired(true);
                setAvailabilityCheckButtonText(prev => prev !== 'SEARCH' ? 'APPLY CHANGES' : prev);
                if(isIncrease) {
                    newState.childrenAges.push(10); // add new default children age
                    setShowChildrenAges(true); // open children ages edit popup
                } else if(isDecreasing) {
                    if(metadata && metadata.children && metadata.children.hasOwnProperty('index')) {
                        newState.childrenAges.splice(metadata.children.index, 1); // remove specific child age
                    } else {
                        newState.childrenAges.pop(); // remove last added child age
                    }
                    
                    // close if last child age or open for editing
                    if(value > 0) setShowChildrenAges(true);
                    else setShowChildrenAges(false);
                }
                break;
            }

            case 'selectedMeal': {
                // update selected meal
                newState.selectedMeal = value;
                // request selected rate update
                requestSelectedRateUpdate = true;
                break;
            }

            case 'single-bed-option': {
                // set new selected bed type option
                newState.doubleBedType = false;
                break;
            }

            case 'double-bed-option': {
                // set new selected bed type option
                newState.doubleBedType = true;
                break;
            }

            case 'room-selector': {
                const { rooms } = trip.partner;
                // set new selected room
                const selectedRoom = rooms.filter(r => r.id === value)[0];
                newState.selectedRoom = selectedRoom;
                // request selected rate update
                requestSelectedRateUpdate = true;
                break;
            }

            case 'occupancy-selector': {
                // set new selected occupancy
                newState.selectedOccupancy = value;
                // request selected rate update
                requestSelectedRateUpdate = true;
                break;
            }

            case 'cancellation-policy-selector': {
                // set new selected cancellation policy
                newState.selectedCancellationPolicy = value;
                // request selected rate update
                requestSelectedRateUpdate = true;
                break;
            }

            case 'rooms': {
                newState[option] = value;
                setAvailabilityCheckRequired(true);
                setAvailabilityCheckButtonText(prev => prev !== 'SEARCH' ? 'APPLY CHANGES' : prev);
                break;
            }

            default: {
                newState[option] = value;
                break;
            }
        }


        if(requestSelectedRateUpdate) {
            const { selectedRoom, mealOptions, selectedMeal } = newState;

            if(selectedRoom && selectedRoom.rates && selectedRoom.rates.length > 0) {

                // set new selected room rates
                newState.selectedRoomRates = selectedRoom.rates;

                
                // filter rooms based on selected meal
                if(mealOptions.length > 0) {
                    let mealFilteredRates = newState.selectedRoomRates.filter(rate => rate.meal[mealOptions[selectedMeal].type]);
                    if(mealFilteredRates.length > 0) newState.selectedRoomRates = mealFilteredRates;
                }

                // filter rooms based on selected occupancy
                if(selectedRoom.options.occupancy.length > 1) {
                    let occupancyFilteredRates = newState.selectedRoomRates.filter(rate => rate.maxOccupancy === newState.selectedOccupancy);
                    if(occupancyFilteredRates.length > 0) newState.selectedRoomRates = occupancyFilteredRates;
                }

                // toggle cancellation options selector
                newState.hasCancellationPoliciesOptions = newState.selectedRoomRates.length > 1 && selectedRoom.options.cancellation.length > 1;

                // filter rooms based on selected cancellation policy
                if(selectedRoom.options.cancellation.length > 0) {
                    let policyFilteredRates = newState.selectedRoomRates.filter(rate => rate.cancellationPolicy.type === newState.selectedCancellationPolicy);
                    if(policyFilteredRates.length > 0) newState.selectedRoomRates = policyFilteredRates;
                }

                // set new selected rate
                newState.selectedRoomRate = newState.selectedRoomRates[0];
                
                // set max rooms available for sale
                if(newState.selectedRoomRate.roomsOnSale && newState.selectedRoomRate.roomsOnSale <= 4) newState.roomsMax = newState.selectedRoomRate.roomsOnSale;
                else newState.roomsMax = 4;

                // decrease rooms if previously selected rooms are over the new selected rate's available rooms
                if(newState.rooms > newState.roomsMax) newState.rooms = newState.roomsMax;
            }
        }


        // save values to state
        setBooking(newState);

    }, [booking, trip, dispatch]);

    // handle children age changes
    const handleChildrenAgeChange = useCallback((index, value) => {
        var newState = { ...booking };
        newState.childrenAges[index] = value;
        setBooking(newState);
    }, [booking]);

    // handle add new children age - default age 10
    const handleAddChildrenAge = useCallback(() => {
        handleBookingOptions('children', booking.children + 1);
    }, [handleBookingOptions, booking]);

    // handle delete specific children age
    const handleDeleteChildrenAge = useCallback((index) => {
        if(index < 0) return;
        handleBookingOptions('children', booking.children - 1, { children: { index } });
    }, [handleBookingOptions, booking]);

    // load booking checkout configs (flow continues in componentDidUpdate)
    const handleReserveBooking = useCallback(() => {
        // skip if availability check is required
        if(isAvailabilityCheckEnabled) return;

        // reset any previous hotel check room rate data
        dispatch(hotelActions.clearCheckRoomRate());
        setHotelRoomCheckRate(null);
        setHotelRoomCheckRateInit(false);

        // reset any previous hotel booking checkout configs
        dispatch(hotelBookingActions.resetCheckoutConfig());

        // format selected hotel reservation data
        const hotelReservationData = hotelBookingCheckoutConfigData({
            ...booking,
            userId: cookie.id,
            guestUserId: cookie.guestUserId,
            checkInFrom: trip.partner.checkInFrom,
            checkOutUntil: trip.partner.checkOutTo,
            partnerId: trip.partner.id,
            creatorId: trip.creator.id,
        });

        if(booking.selectedRoomRate.reCheck) dispatch(hotelActions.checkRoomRate(trip.partner.id, hotelReservationData, cookie.guestUserId)); // request a hotel room rate check
        else dispatch(hotelBookingActions.checkoutConfig(hotelReservationData, cookie.guestUserId)); // load booking checkout configs (flow continues in componentDidUpdate)

    }, [booking, cookie, trip, dispatch, isAvailabilityCheckEnabled]);

    return (
        <ScreenContainer id='screen' >
            { init && <MetaTags title={publicTripData.name} /> }

            {init &&
            <TripPage
                sticky={sticky}
                authenticated={authenticated}
                isCreator={isCreator}
                trip={trip}
                isSearchExpired={isSearchExpired}
                handleSearchExpired={() => setIsSearchExpired(true)}
                booking={booking}
                hotelBooking={hotelBooking}
                genericError={hotel.checkRateError || hotel.error}
                hotelDataLoading={hotel && hotel.loading}
                occupancyOptionsToggle={occupancyOptionsToggle}
                availabilityCheckRequired={isAvailabilityCheckEnabled}
                availabilityCheckButtonText={availabilityCheckButtonText}
                isFav={isFavTrip}
                handleBackButton={handleTopBarBackButton}
                handleOpenTerms={handleOpenTerms}
                handleDelete={handleDeleteTripRequest}
                handleEdit={handleEditTrip}
                handleShare={handleShareTrip}
                handleFavor={handleFavorTrip}
                handleUnFavor={handleUnFavorTrip}
                handleViewCreatorProfile={handleViewCreatorProfile}
                handleOpenMap={handleOpenMap}
                handleDatePickerToggle={handleDatePickerToggle}
                handleBookingOptions={handleBookingOptions}
                handleAvailabilitySearch={handleAvailabilitySearch}
                handleShowChildrenAges={setShowChildrenAges}
                handleReserveBooking={handleReserveBooking} /> }

            { (deleting || loading || hotelBooking.loadingConfigs || hotel.loadingCheckRate) && <FullScreenSpinner /> }

            <Warning
                show={deleteWarning}
                onHide={handleDeleteTripCancel}
                firstButtonClick={handleDeleteTrip}
                secondButtonClick={handleDeleteTripCancel}
                firstButton={contentTripPage.deleteTripWarning.firstButton}
                firstButtonColor={colorConstants.darkGrey}
                firstButtonBackgroundColor={colorConstants.softGrey}
                secondButton={contentTripPage.deleteTripWarning.secondButton}
                title={contentTripPage.deleteTripWarning.title}
                body={contentTripPage.deleteTripWarning.body} />

            { init && trip && trip.creator &&
            <Share
                show={sharing}
                onHide={handleShareTrip}
                title='Share'
                url={urlConstants.baseUrlClient + getTripLink(trip.creator.username, trip.customUrl)}
                imageUrl={trip.tripPhotoUrls[0]} /> }

            <DatePicker
                show={datePicker}
                dates={booking.dates}
                onHide={handleDatePickerToggle}
                showButton={true}
                onDatePick={handleDatePickerSelect} />

            <ChildrenAgesPopup
                show={showChildrenAges}
                total={booking.children}
                max={booking.childrenMax}
                ages={booking.childrenAges}
                onHide={() => setShowChildrenAges(false)}
                handleAddChildrenAge={handleAddChildrenAge}
                handleDeleteChildrenAge={handleDeleteChildrenAge}
                onChange={handleChildrenAgeChange} />

            <HotelRoomCheckRatePopup
                show={showHotelRoomCheckRate}
                onHide={() => setShowHotelRoomCheckRate(false)}
                data={hotelRoomCheckRate}
                handleAcceptChanges={handleReserveBooking}
            />

            { init && trip.google &&
            <Map
                show={isMapVisible}
                onHide={() => setIsMapVisible(false)}
                data={{ place: trip.google }}
            /> }
        </ScreenContainer>
    );
};