import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { CurrencyDollar, Link45deg } from 'react-bootstrap-icons';

import { deviceConstants, colorConstants } from "../../../constants";


const ActiveBorder = styled.div`
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Button = styled.button`
    width: 50%;
    height: 50px;

    padding: 0px;
    margin: 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 600;

    background: ${({ active }) => !active ? colorConstants.verySoftGrey : colorConstants.gradientHorizontal};
    color: ${({ active }) => !active ? colorConstants.darkGrey : colorConstants.white};

    border: none;
    border-radius: ${({ left }) => left ? '4px 0px 0px 4px' : '0px 4px 4px 0px'};

    :hover {
        background: ${colorConstants.gradientHorizontal};
        color: ${colorConstants.white};
        cursor: ${({ active }) => active ? 'default' : 'pointer'};
    }

    :disabled {
        background: ${colorConstants.softGrey};
        color: ${colorConstants.darkGrey};
    }

    @media ${deviceConstants.tablet} {
        
    }
`;

const Content = styled.div`
    width: 100%;
    padding: 0px 4px;

    display: flex;
    align-items: center;
    justify-content: ${({ left }) => left ? 'start' : 'end'};
`;

const Text = styled.div`
    width: 100%;

    text-aling: center;
`;



export const LinkMonetizationButton = ({ status, onClick }) => {
    const [active, setActive] = useState(0);

    const handleClick = useCallback((e, index) => {
        e.preventDefault(); // required so that the form is not submitted by default

        setActive(index);
        if(onClick) onClick(index);

    }, [onClick]);

    // update active state based on user options
    useEffect(() => {
        setActive(status ? 0 : 1);

    }, [status]);


    return (
        <ActiveBorder>
            <Button left={true} active={active === 0 ? true : false} onClick={(e) => handleClick(e, 0)}>
                <Content left={true} >
                    <CurrencyDollar size={30} />
                    <Text>Direct Partner</Text>
                </Content>
            </Button>

            <Button active={active === 1 ? true : false} onClick={(e) => handleClick(e, 1)}>
                <Content>
                    <Text>Custom Link</Text>
                    <Link45deg size={30} />
                </Content>
            </Button>
        </ActiveBorder>
    );
};