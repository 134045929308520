import styled from 'styled-components';
import { Image } from '../../custom/Images';
import { colorConstants } from '../../../constants';



export const Container = styled.div`
    width: 100%;

    font-size: 14px;
    font-weight: 500;
    color: ${colorConstants.black};
`;

export const GradientDivider = styled.div`
    width: 100%;
    margin-top: ${({ margintop }) => margintop};
    margin-bottom: ${({ marginbottom }) => marginbottom};
    height: 1px;
    background: ${colorConstants.gradientHorizontal};
`;

export const DescriptionSection = styled.div`
    width: 100%;
    margin-bottom: 24px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const Photo = styled(Image)`
    width: 130px;
    border-radius: 2px;
    object-fit: cover;
`;

export const DetailsColumn = styled.div`
    margin-left: 16px;
`;

export const Name = styled.div`
    font-size: 20px;
    font-weight: 600;
`;

export const Address = styled.div`
    margin-top: 14px;
    font-weight: 600;
    font-size: 12px;
    color: ${colorConstants.mediumGrey};
`;

export const CheckoutRow = styled.div`
    width: 100%;

    font-weight: 600;
    line-height: 2;

    display: flex;
    align-items: flex-end;

    & > * {
        margin-right: 16px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const CheckoutRowCol = styled.div`
    width: 100%;
    color: ${colorConstants.mediumGrey};

    ${({ rowleftalign }) => rowleftalign && `
        width: 50%;
    `}

    ${({ smalltext }) => smalltext && `
        font-size: 12px;
    `}

    ${({ columnrightalign }) => columnrightalign && `
        flex: 1;
        text-align: end;
        min-width: 30%;
    `}

    ${({ bold }) => bold && `
        font-weight: 600;
        color: ${colorConstants.black};
    `}
`;