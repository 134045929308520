import React, { Component } from "react";
import { connect } from 'react-redux';

import { nftActions, alertActions } from '../../../actions';
import { ScreenContainer } from './EditNFTContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { Notification } from '../../../components/custom/Popup';
import { EditNFT }  from "../../../components/nfts";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { TopBar } from '../../../components/custom/TopBar';
import {
    defaultNFT,
    history,
    createSlug,
    finalizeNFTData,
} from '../../../utils';
import { sitemap, topbarConstants } from "../../../constants";


class EditNFTContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            init: false,
            sticky: false,
            typingTimeout: 0, // used to know when user stops typing
            fromUrl: sitemap.admin.nft.list,
            updating: false,
            nft: null,
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);

        this.handleChange = this.handleChange.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if(this.props.userId && this.props.location.state && this.props.location.state.from && this.props.location.state.data && this.props.location.state.data.nftId) {
            this.setState({
                fromUrl: this.props.location.state.from,
                nft: {
                    id: this.props.location.state.data.nftId,
                    ...defaultNFT(this.props.userId),
                }
            });
            this.props.getById(this.props.location.state.data.nftId, null); // get NFT from db
        } else {
            history.replace({ pathname: this.state.fromUrl, state: { error: 'Invalid container startup props' } });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        if(!this.state.init && this.props.nft &&  this.props.nft.id) this.setState({ ...this.state, init: true, nft: this.props.nft });
    }

    handleScroll(event) {
        // Enable sticky mode on scroll
        this.setState({ ...this.state, sticky: window.pageYOffset > 1 });
    }

    handleTopBarBackButton() {
        // Go back
        history.push({
            pathname: this.state.fromUrl,
            state: {
                data: {
                    nftId: this.state.nft.id
                }
            }
        });
    }

    handleChange(event) {
        let { name, value } = event.target;
        let withTimeout = true;

        if(name === 'customUrl') {
            // limit NFT custom url to minimum 5 characters
            if(!value || value.length <= 5) return;
            // create slug from input
            value = createSlug(value);
        } else if(name === 'simple-link-label') {
            // save btnLabel value
            name = 'button';
            value = { ...this.state.nft.button, label: value };
        } else if(name === 'simple-link-url') {
            // save btnUrl value
            name = 'button';
            value = { ...this.state.nft.button, url: value };
        } else if(name === 'nft-enable') {
            name = 'enabled';
            value = !this.state.nft.enabled;
            withTimeout = false;
        } else if(name === 'skipDetailsPage-enable') {
            name = 'skipDetailsPage';
            value = !this.state.nft.skipDetailsPage;
            withTimeout = false;
        }

        this.setState({
            updating: false,
            typingTimeout: withTimeout ? setTimeout(() => { this.props.update(finalizeNFTData(this.state.nft)) }, 1000) : null,
            nft: {
                ...this.state.nft,
                [name]: value, // update input data
            }
        }, () => !withTimeout ? this.props.update(finalizeNFTData(this.state.nft)) : null);

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.editNFT} />

                <TopBar
                    sticky={this.state.sticky}
                    title={topbarConstants.titles.editNFT}
                    backButton={this.handleTopBarBackButton} />

                { this.state.init &&
                <EditNFT
                    nft={this.state.nft}
                    handleChange={this.handleChange} /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                {this.state.updating && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, nft } = state;
    
    // export state data to props
    return { userId, alert, nft };
}

const actionCreators = {
    update: nftActions.update,
    getById: nftActions.getById,
    clearAlert: alertActions.clear
}

const connectedEditNFTContainer = connect(mapState, actionCreators)(EditNFTContainer);
export { connectedEditNFTContainer as EditNFTContainer };