/**
 * Method to prepare product data before submission to server
 * 
 * @param {JSON Object} data The product data
 * @returns JSON Object with finalized product data for submission
 */
export function finalizeProductData(data) {
    const { imageUrls, button, ...rest} = data;
    return {
        ...rest,
        btnLabel: button.label,
        btnUrl: button.url
    };
}