import React, { useMemo } from 'react';
import styled from 'styled-components';
import { SlashCircle, CashStack, CheckCircle, DoorOpen } from 'react-bootstrap-icons';

import { colorConstants } from "../../../constants";



const Header = styled.div`
    margin-bottom: 8px;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 15px;
    color: ${colorConstants.black};

    display: flex;
    align-items: center;
`;

const TitleText = styled.div`
    color: ${({ highlight }) => highlight ? colorConstants.secondaryDark : 'inherit'};
`;

const Subtitle = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: ${colorConstants.mediumGrey};
`;

const FullRefundIcon = styled(CheckCircle)`
    color: ${colorConstants.secondaryDark};
    margin-right: 8px;
`;

const NoRefundIcon = styled(SlashCircle)`
    color: ${colorConstants.primaryDark};
    margin-right: 8px;
`;

const FeeIcon = styled(CashStack)`
    color: ${colorConstants.primaryDark};
    margin-right: 8px;
`;

const NoShowIcon = styled(DoorOpen)`
    color: ${colorConstants.primaryDark};
    margin-right: 8px;
`;



export const RoomCancellationPolicyComponent = ({ component }) => {
    // check if refundable
    const title = useMemo(() => {

        if(!component || !component.type || !component.label) return null;

        let isFullRefund = false;
        let icon = null;

        switch(component.type) {
            case 'FULL_REFUND': {
                icon = <FullRefundIcon />;
                isFullRefund = true;
                break;
            }

            case 'FEE': {
                icon = <FeeIcon />;
                break;
            }

            case 'NO_REFUND': {
                icon = <NoRefundIcon />;
                break;
            }

            case 'NO_SHOW': {
                icon = <NoShowIcon />;
                break;
            }

            default:
                break;
        }

        return (
            <Title>
                {icon}
                <TitleText highlight={isFullRefund}>{component.label}</TitleText>
            </Title>
        );
    }, [component]);

    if(!component) return null;

    // return html item
    return (
        <Header>
            {title}

            <Subtitle>{component.details}</Subtitle>
        </Header>
    );
};