import React from 'react';
import styled from 'styled-components';
import { Plus, Upload, X } from 'react-bootstrap-icons';

import { colorConstants } from "../../../constants";


const GradientBorder = styled.div`
    display: flex;
    align-items: center;
    background: ${({ disable, deactivate }) => deactivate ? (colorConstants.softGrey) : (disable ? 'none' : colorConstants.gradientHorizontal)};
    padding: ${props => props.disable ? '0' : '1px'};
    border-radius: ${({ hasphoto }) => hasphoto ? '8px' : '2px'};
    margin-top: 6px;
    margin-bottom: 2px;

    // width: 28%; // added gap of 10% to each child
    width: ${props => props.customwidth || '28%'};

    :hover {
        ${({ deactivate, hasurl }) => !deactivate && !hasurl && `
            cursor: pointer;
        `}
    }
`;

const Content = styled.div`
    width: 100%;
    background: ${({ deactivate }) => deactivate ? colorConstants.softGrey : colorConstants.white};
    border-radius: ${({ hasphoto }) => hasphoto ? '8px' : '2px'};

    padding-top: 100%; // this helps keep aspect ratio 4:3 of parent width

    position: relative;
`;

const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    
    border-radius: 8px;
`;

const UploadIcon = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const EditIcon = styled.div`
    border-radius: 1000px;
    line-height: 1;
    padding: 4px;

    position: absolute;
    right: 3px;
    bottom: 3px;

    :hover {
        background: ${colorConstants.primaryFaded};
        cursor: pointer;
    }

    z-index: 9;
`;

const DeleteIcon = styled.div`
    border-radius: 1000px;
    line-height: 1;
    padding: 4px;

    position: absolute;
    left: 3px;
    top: 3px;

    :hover {
        background: ${colorConstants.primaryFaded};
        cursor: pointer;
    }

    z-index: 9;
`;

const Fader = styled.div`
    background: black;
    opacity: 0.15;

    z-index: 8;
    
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;

    border-radius: 8px;
`;

export const UploadPhoto = (props) => {
    const { showEdit, handleDelete, url, onClick, deactivate, width } = props;

    return (
        <GradientBorder hasphoto={url} customwidth={width} deactivate={deactivate} hasurl={url} disable={url ? true : false} onClick={deactivate || url ? null : onClick}>
            <Content hasphoto={url} deactivate={deactivate}>
                {url && <Image src={url} alt="trip-photo" /> }

                {!url &&
                <UploadIcon>
                    <Plus color={deactivate ? colorConstants.darkGrey : colorConstants.primaryDark} size={32} />
                </UploadIcon>}

                {url && showEdit &&
                <Fader /> }

                {url && showEdit &&
                <EditIcon onClick={onClick} >
                    <Upload color={deactivate ? colorConstants.darkGrey : colorConstants.white} size={20} />
                </EditIcon>}

                {url && handleDelete &&
                <DeleteIcon onClick={handleDelete} >
                    <X color={deactivate ? colorConstants.darkGrey : colorConstants.white} size={20} />
                </DeleteIcon>}
            </Content>
        </GradientBorder>
    );
};