import React, { Component } from "react";
import { connect } from 'react-redux';

import { alertActions, linkActions } from '../../../../actions';
import { ScreenContainer } from './EditGithubProjectContainer.styles';
import { MetaTags } from '../../../../components/custom/Helmet';
import { EditGithubProject }  from "../../../../components/links";
import { FullScreenSpinner } from "../../../../components/custom/Spinner";
import { TopBar } from '../../../../components/custom/TopBar';
import { Notification, Upgrade } from '../../../../components/custom/Popup';
import ImageCrop from "../../../../components/cropper/ImageCrop";
import {
    defaultPhotos,
    history,
    notNull,
    compressImage,
    storageRef,
    uploadFile,
    getFileDownloadURL,
    removeTokenFromFileDownloadURL,
    getImageFileDimensions,
} from '../../../../utils';
import { sitemap, otherConstants } from "../../../../constants";


class EditGithubProjectContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            init: false,
            fromUrl: sitemap.admin.links.list,
            sticky: false,
            typingTimeout: 0, // used to know when user stops typing
            showUpgradePopup: false, // used to toggle upgrade popup
            project: {
                label: '',
                url: '',
                description: '',
            },
            photos: defaultPhotos(),
            updating: false,
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);

        // update project
        this.handleChange = this.handleChange.bind(this);
        // photo
        this.handleUploadPhoto = this.handleUploadPhoto.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);

        // notification
        this.handleClearNotification = this.handleClearNotification.bind(this);

        // upgrade notification
        this.handleToggleUpgradePopup = this.handleToggleUpgradePopup.bind(this);
        this.handleUpgrade = this.handleUpgrade.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if(this.props.user && this.props.user.id && this.props.location.state && this.props.location.state.from && this.props.location.state.data && this.props.location.state.data.linkId) {
            this.setState({ fromUrl: this.props.location.state.from });
            this.props.getById(this.props.location.state.data.linkId); // get link from db
        } else {
            history.replace({ pathname: sitemap.admin.links.list, state: { error: 'Invalid container startup props' } });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.clearAlert(); // reset alert in redux state
    }

    componentDidUpdate() {
        if(!this.state.init && this.props.link &&  this.props.link.id) this.setState({ ...this.state, init: true, project: this.props.link });
    }

    handleScroll(event) {
        // Enable sticky mode on scroll
        this.setState({ ...this.state, sticky: window.pageYOffset > 1 });
    }

    handleTopBarBackButton() {
        if(this.state.photos.crop.pendingImage) this.setState({ ...this.state, photos: { crop: defaultPhotos().crop } }); // reset crop image screen
        else {
            // Go back
            history.push({
                pathname: this.state.fromUrl,
                state: {
                    data: {
                        linkId: this.state.project.id
                    }
                }
            }); 
        }
    }

    handleChange(event) {
        let { name, value } = event.target;
        let withTimeout = true;

        if(name === 'simple-link-label') name = 'label';
        else if (name === 'simple-link-url') name = 'url';
        else if(name === 'link-enable') {
            name = 'enabled';
            value = !this.state.project.enabled;
            withTimeout = false;
        } else if(name === 'delete-image') {
            name = 'imageUrl';
            withTimeout = false;
        }

        this.setState({
            updating: false,
            typingTimeout: withTimeout ? setTimeout(() => { this.props.update(this.state.project) }, 1000) : null, // request update link data on server 1sec after user stops typing
            project: {
                ...this.state.project,
                [name]: value, // update input data
            }
        }, () => !withTimeout ? this.props.update(this.state.project) : null);

        // clear timer
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    }

    handleUploadPhoto(e) {
        // only allow subscription members to add photo
        if(!this.props.user || !this.props.user.id || (!this.props.user.basic && !this.props.user.premium && !this.props.user.platinum)) return;

        // Save inserted image url in state
        if (this.state.project.id && e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save inserted photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        ...this.state.photos,
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: 'github-link-logo',
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    async handleCroppedImage(croppedImage) {

        // enable spinner and reset pending image for cropping
        this.setState({ ...this.state, updating: true, photos: { crop: defaultPhotos().crop } });

        // compress cropped image file
        const compressedFile = await compressImage(croppedImage, croppedImage.name, 256);

        // create firebase storage reference of image to upload
        const imageRef = storageRef(otherConstants.environment + '/users/' + this.props.user.id + '/links/' + this.state.project.id + '/' + compressedFile.name);

        // Upload image to firebase storage
        const snapshot = await uploadFile(imageRef, compressedFile);

        // Get firebase public url of uploaded photo
        let uploadedPhotoFirebaseUrl = await getFileDownloadURL(snapshot.ref);

        // Clean public url
        uploadedPhotoFirebaseUrl = removeTokenFromFileDownloadURL(uploadedPhotoFirebaseUrl);

        this.setState({
            ...this.state,
            project: {
                ...this.state.project,
                imageUrl: uploadedPhotoFirebaseUrl, // save uploaded photo URL
            }
        }, async () => {
            // update photo urls in db
            await this.props.update(this.state.project);

            // disable updating spinner
            this.setState({ ...this.state, updating: false });
        });
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    handleToggleUpgradePopup() {
        this.setState({ ...this.state, showUpgradePopup: !this.state.showUpgradePopup });
    }

    handleUpgrade() {
        history.push({
            pathname: sitemap.billing.upgrade,
            state: {
                from: sitemap.admin.links.list
            }
        });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title='Edit Github Project' />

                <TopBar
                    sticky={this.state.sticky}
                    title='Edit Github Project'
                    backButton={this.handleTopBarBackButton} />

                { this.state.init && !this.state.photos.crop.pendingImage &&
                <EditGithubProject
                    isUpgraded={this.props.user && this.props.user.id && (this.props.user.basic || this.props.user.premium || this.props.user.platinum)}
                    link={this.state.project}
                    handleChange={this.handleChange}
                    handleUploadPhoto={this.handleUploadPhoto}
                    handleUpgrade={this.handleToggleUpgradePopup} /> }

                { this.state.photos.crop.pendingImage &&
                <ImageCrop
                    url={this.state.photos.crop.pendingImage}
                    filename={this.state.photos.crop.pendingImageFilename}
                    fileSize={this.state.photos.crop.pendingImageFileSize}
                    imageDimensions={this.state.photos.crop.pendingImageDimensions}
                    circularCrop={false}
                    handleCroppedImage={this.handleCroppedImage} /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                {this.state.showUpgradePopup &&
                <Upgrade
                    show={this.state.showUpgradePopup}
                    onClick={this.handleUpgrade}
                    onHide={this.handleToggleUpgradePopup} /> }

                {this.state.updating && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { alert, user, link } = state;
    
    // export state data to props
    return { alert, user, link };
}

const actionCreators = {
    update: linkActions.update,
    getById: linkActions.getById,
    clearAlert: alertActions.clear
}

const connectedEditGithubProjectContainer = connect(mapState, actionCreators)(EditGithubProjectContainer);
export { connectedEditGithubProjectContainer as EditGithubProjectContainer };