import React, { Component } from "react";
import { connect } from 'react-redux';

import { userActions, tripActions, nftActions, productActions, alertActions  } from '../../actions';
import { ScreenContainer } from './MyFavouritesContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { Notification } from '../../components/custom/Popup';
import { NoFavouritesFound } from '../../components/custom/EmptyState';
import FavouritesList from "../../components/favourites/FavouritesList";
import { TopBar } from '../../components/custom/TopBar';
import { SideBar } from '../../components/custom/SideBar';
import { FullScreenSpinner } from "../../components/custom/Spinner";
import { FiltersMenu } from '../../components/custom/Filter';
import { history, defaultUser } from '../../utils';
import { topbarConstants, sitemap, getTripLink, getNFTLink, getProductLink } from "../../constants";


class MyFavouritesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            fromUrl: sitemap.admin.dashboard,
            sidebar: false,
            sidebarReset: false,
            sidebarPositionOffset: 0,
            sticky: false,
            user: defaultUser()
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuButton = this.handleMenuButton.bind(this);
        this.handleViewTrip = this.handleViewTrip.bind(this);
        this.handleViewNFT = this.handleViewNFT.bind(this);
        this.handleViewProduct = this.handleViewProduct.bind(this);

        this.handleFilter = this.handleFilter.bind(this);
        this.handleResetFilter = this.handleResetFilter.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        if(!this.props.user || !this.props.user.id) this.props.getById(this.props.userId); // get user data from db
        window.addEventListener('scroll', this.handleScroll);
        this.props.getAllTrips(new URLSearchParams({ userId: this.props.userId, showFavourites: true })); // get all favourite trips by user id
        this.props.getAllNFTs(new URLSearchParams({ userId: this.props.userId, showFavourites: true })); // get all favourite NFTs by user id
        this.props.getAllProducts(new URLSearchParams({ userId: this.props.userId, showFavourites: true })); // get all favourite products by user id
         // set from URL
         if(this.props.location.state && this.props.location.state.from) this.setState({ ...this.state, fromUrl: this.props.location.state.from });
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.resetTrips();
        this.props.resetNFTs();
        this.props.resetProducts();
    }

    componentDidUpdate(){
        // load user data from server into component state
        if (!this.state.init && this.props.user && this.props.user.id) {
            this.setState({ ...this.state, init: true, user: this.props.user });
        }
    }

    handleScroll(event) {
        // Enable sticky mode on scroll and reset sidebar
        this.setState({ ...this.state, sidebar: false, sidebarReset: true, sidebarPositionOffset: window.pageYOffset, sticky: window.pageYOffset > 1 });
    }

    handleMenuButton() {
        this.setState({ ...this.state, sidebar: !this.state.sidebar, sidebarReset: false });
    }

    handleViewTrip(trip) {
        // open trip details screen
        history.push({
            pathname: getTripLink(trip.creator.username, trip.customUrl),
            state: {
                from: sitemap.admin.favourites,
                data: {
                    userId: this.props.userId,
                    tripId: trip.id
                }
            }
        });
    }

    handleViewNFT(nft) {
        // open NFT details screen
        history.push({
            pathname: getNFTLink(nft.creator.username, nft.customUrl),
            state: {
                from: sitemap.admin.favourites,
                data: {
                    userId: this.props.userId,
                    nftId: nft.id
                }
            }
        });
    }

    handleViewProduct(product) {
        // open product details screen
        history.push({
            pathname: getProductLink(product.creator.username, product.customUrl),
            state: {
                from: sitemap.admin.favourites,
                data: {
                    userId: this.props.userId,
                    productId: product.id
                }
            }
        });
    }

    handleFilter(selection) {
        switch (selection) {

            case 1: {
                // get stays
                this.props.getAllTrips(new URLSearchParams({ userId: this.props.userId, showFavourites: true, type: 'ACCOMMODATION' }));
                this.props.resetNFTs();
                this.props.resetProducts();
                return;
            }

            case 2: {
                // get experiences
                this.props.getAllTrips(new URLSearchParams({ userId: this.props.userId, showFavourites: true, type: 'EXPERIENCE' }));
                this.props.resetNFTs();
                this.props.resetProducts();
                return;
            }

            case 5: {
                // get physical products
                this.props.getAllProducts(new URLSearchParams({ userId: this.props.userId, showFavourites: true, type: 'PHYSICAL' }));
                this.props.resetTrips();
                this.props.resetNFTs();
                return;
            }

            case 6: {
                // get digital products
                this.props.getAllProducts(new URLSearchParams({ userId: this.props.userId, showFavourites: true, type: 'DIGITAL' }));
                this.props.resetTrips();
                this.props.resetNFTs();
                return;
            }

            case 7: {
                // get NFTs
                this.props.getAllNFTs(new URLSearchParams({ userId: this.props.userId, showFavourites: true, type: 'NFT' }));
                this.props.resetTrips();
                this.props.resetProducts();
                return;
            }

            default: {
                this.props.getAllTrips(new URLSearchParams({ userId: this.props.userId, showFavourites: true }));
                this.props.getAllProducts(new URLSearchParams({ userId: this.props.userId, showFavourites: true }));
                this.props.getAllNFTs(new URLSearchParams({ userId: this.props.userId, showFavourites: true }));
                return;
            }
        }
    }

    handleResetFilter(){
        this.props.getAllTrips(new URLSearchParams({ userId: this.props.userId, showFavourites: true }));
        this.props.getAllProducts(new URLSearchParams({ userId: this.props.userId, showFavourites: true }));
        this.props.getAllNFTs(new URLSearchParams({ userId: this.props.userId, showFavourites: true }));
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    render() {
        return (
            <ScreenContainer id='screen'>
                <MetaTags title={topbarConstants.titles.favourites} />

                <TopBar
                    title={topbarConstants.titles.favourites}
                    sticky={this.state.sticky}
                    menuButton={this.handleMenuButton} />

                {this.state.init &&
                <SideBar
                    options={{ isAuthenticated: true, creator: this.state.user.creator, basic: this.state.user.basic, premium: this.state.user.premium, platinum: this.state.user.platinum, links: this.state.user.pageData.metadata.links }}
                    toggle={this.handleMenuButton}
                    from={sitemap.admin.favourites}
                    enable={this.state.sidebar}
                    reset={this.state.sidebarReset}
                    position={this.state.sidebarPositionOffset} /> }

                <FiltersMenu
                    handleReset={this.handleResetFilter}
                    handleFilter={this.handleFilter}
                    options={{
                        showHotelLinks: true,
                        // showTravelAgencyLinks: true, // FEATURE: enable filters for saved experiences & downloadable products
                        showPhysicalProductLinks: true,
                        // showDigitalProductLinks: true,
                        // showNFTLinks: true,
                        force: true
                    }}
                    noActive={true} />

                {this.state.init &&
                <FavouritesList
                    trips={this.props.trips}
                    nfts={this.props.nfts}
                    products={this.props.products}
                    handleViewTrip={this.handleViewTrip}
                    handleViewNFT={this.handleViewNFT}
                    handleViewProduct={this.handleViewProduct} /> }

                { !this.props.loadingTrips && !this.props.loadingNFTs && !this.props.loadingProducts &&
                (!this.props.trips || this.props.trips.length === 0) && (!this.props.nfts || this.props.nfts.length === 0) && (!this.props.products || this.props.products.length === 0) &&
                <NoFavouritesFound /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                {(this.props.loadingTrips || this.props.loadingNFTs || this.props.loadingProducts) && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user } = state;
    const { loadingTrips, trips } = state.trip;
    const { loadingNFTs, nfts } = state.nft;
    const { loadingProducts, products } = state.product;

    // export state data to props
    return { userId, alert, user, loadingTrips, trips, loadingNFTs, nfts, loadingProducts, products };
}

const actionCreators = {
    getById: userActions.getById,
    getAllTrips: tripActions.getAll,
    resetTrips: tripActions.clear,
    getAllNFTs: nftActions.getAll,
    resetNFTs: nftActions.clear,
    getAllProducts: productActions.getAll,
    resetProducts: productActions.clear,
    clearAlert: alertActions.clear
}

const connectedMyFavouritesContainer = connect(mapState, actionCreators)(MyFavouritesContainer);
export { connectedMyFavouritesContainer as MyFavouritesContainer };