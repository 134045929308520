import styled from 'styled-components';
import FormGroup from 'react-bootstrap/FormGroup';
import { Button } from '../custom/Buttons';
import { AboutSection } from "../custom/Branding";
import { colorConstants, deviceConstants, shadowConstants } from '../../constants';


export const Container = styled.div`
  font-size: 12px;
  padding-bottom: 80px;
`;

export const UserInfoSection = styled.div`
  @media ${deviceConstants.tablet} {
    margin-top: 32px;
    padding: 32px;
    border-radius: 8px;
    box-shadow: ${shadowConstants.adminDesktop};
    background: ${colorConstants.white};
  }
`;

export const ProfilePhotoSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;

export const FileUploader = styled.input`
  display: none;
`;

export const PublicProfileLink = styled.div`
  font-weight: 400;
  color: ${colorConstants.softGrey};

  display: flex;
  justify-content: center;

  :hover {
    color: ${colorConstants.primaryDark};
    cursor: pointer;
  }
`;

export const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

export const FormInputTitle = styled.div`
  font-weight: 600;
  color: ${colorConstants.softGrey};
  margin-bottom: 1px;
`;

export const MaxTextIndicator = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: ${colorConstants.softGrey};
  text-align: end;
  margin-top: 1px;
`;

export const SocialMediaSection = styled.div`
  margin-top: 24px;
`;

export const CreatorModeSection = styled.div`
  margin-top: 72px;
  text-align: center;
`;

export const FinanceInfoSection = styled.div`
  margin-top: 72px;

  @media ${deviceConstants.tablet} {
    padding: 32px;
    border-radius: 8px;
    box-shadow: ${shadowConstants.adminDesktop};
    background: ${colorConstants.white};
  }
`;

export const SubscriptionSection = styled.div`
  width: 100%;
  margin-bottom: 48px;
`;

export const SubscriptionSectionTop = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SubscriptionName = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${colorConstants.black};
`;

export const SubscriptionDaysLeft = styled.div`
  padding: 2px 8px;

  font-weight: 500;
  font-size: 16px;
  color: ${colorConstants.primaryDark};
`;

export const SubscriptionManagementSection = styled.div`
  margin-top: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SubscriptionNextBillingAt = styled.div`
  width: 55%;
  margin-top: 4px;

  font-weight: 500;
  font-size: 14px;
  color: ${colorConstants.darkGrey};

  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: none;
`;

export const ChangeSubscriptionButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

export const CancelSubscription = styled.div`
  margin-top: 16px;

  text-align: end;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  color: ${colorConstants.warningDark};

  :hover {
    cursor: pointer;
    font-weight: 600;
    color: ${colorConstants.warning};
  }
`;

export const BillingMethodSection = styled.div`
  margin-bottom: 48px;
`;

export const PaymentMethodsArea = styled.div`
  margin-top: 16px;
`;

export const UserAddressSection = styled.div`
  margin-bottom: 72px;
`;

export const CryptoWalletSection = styled.div`
  margin-bottom: 48px;
`;

export const PaymentInfoSection = styled.div`
  margin-bottom: 48px;
`;

export const EarningsSection = styled.div`

`;

export const MoreOptionsSection = styled.div`
  margin-top: 72px;

  // display: flex;
  text-align: center;

  @media ${deviceConstants.tablet} {
    padding: 32px;
    border-radius: 8px;
    box-shadow: ${shadowConstants.adminDesktop};
    background: ${colorConstants.white};
  }
`;

export const StyledButton = styled(Button)`
  width: 90%;
  margin-top: 16px;
`;

export const AboutSectionWrap = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
`;

export const StyledAboutSection = styled(AboutSection)`
    width: 128px;
    min-width: 128px;
`;

export const SpinnerContainer = styled.div`
  padding: 16px 0;
  text-align: center;
  
  color: ${colorConstants.primaryDark};
`;