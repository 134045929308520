import styled from 'styled-components';
import { colorConstants } from '../../../constants';



export const Container = styled.div`
  width: 100%;
  margin-top: 48px;

  padding: 16px;
  border: 1px solid ${colorConstants.softGrey};
  border-radius: 8px;
`;

export const CheckoutSummaryTitle = styled.div`
  margin-bottom: 16px;
  
  font-size: 22px;
  font-weight: 600;
  color: ${colorConstants.black};
  text-align: start;
`;

export const CheckoutSummaryText = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: ${colorConstants.darkGrey};
`;

export const CheckoutSummaryDetailsSection = styled.div`
  width: 100%;
  margin-top: 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & > * {
    margin-bottom: 10px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const CheckoutSummaryDetailsRow = styled.div`
  ${({ hasborder }) => hasborder && `
    border-top: 1px solid ${colorConstants.verySoftGrey};
    padding-top: 8px;
  `}

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: ${({ textRight }) => textRight ? 'flex-end' : 'space-between'};

  & > *:last-child {
    margin-left: 48px;
  }
`;

export const CheckoutSummaryDetailsText = styled.div`
  font-size: 12px;
  font-weight: ${({ final }) => final ? '600' : '400'};
  color: ${({ final }) => final ? colorConstants.black : colorConstants.mediumGrey};

  ${({ number }) => number && `
    white-space: nowrap;
  `}
`;