import {
    Container,
    Name
} from './SectionLabel.styles';
import { colorConstants } from "../../constants";


export const SectionLabel = ({ data, customizations, ...rest }) => {
    let { buttons, fonts } = customizations;

    // defaults
    if(buttons.color === '') buttons.color = colorConstants.softBlack;
    if(buttons.fontColor === '') buttons.fontColor = colorConstants.white;

    if(!data.description) return null;

    return (
        <Container
            fontColor={fonts.color}
            {...rest}
        >
            <Name fontType={fonts.type}>{data.description}</Name>
        </Container>
    );
};