import React, { Component } from "react";
import { connect } from 'react-redux';
import validator from 'validator';

import { ScreenContainer } from './EarlyAccessLinkInBioContainer.styles';
import { Header } from '../../../components/custom/TopBar';
import { HomeFooter } from '../../../components/custom/Footer';
import { EarlyAccessLinkInBio } from "../../../components/home";
import { authActions, homepageActions } from '../../../actions';
import { contentLandingPageHeader, sitemap } from '../../../constants';
import { analytics } from '../../../utils';


class EarlyAccessLinkInBioContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                name: '',
                igHandle: '',
                primaryPlatform: '',
                email: '',
                type: 'CREATOR',
                note: 'Link in Bio',
            },
            submitted: false,
            captcha: false,
            isEmail: false,
            isSocialLink: false,
            scrollToSection: '', // used to scroll to particular section
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleScrollReset = this.handleScrollReset.bind(this);
        this.handleScrollToForm = this.handleScrollToForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCaptcha = this.handleCaptcha.bind(this);
    }

    componentDidMount(){
        this.props.loadCsrfToken(); // load csrf token in cookies
    }

    handleScrollReset(){
        if(this.state.scrollToSection) {
            this.setState({ ...this.state, scrollToSection: '' });
        }
    }

    handleScrollToForm(buttonName){
        this.setState({ ...this.state, scrollToSection: 'early-access-form' });
        analytics.event('Homepage', 'Clicked Go To Early Access Form', buttonName); // register click event
    }

    handleChange(event) {
        let { name, value } = event.target;
        const { user } = this.state;

        // format type
        if(name === 'type') {
            if(value === 'Agent/Manager'){
                value = 'Agent';
            }

            value = value.toUpperCase();
        }

        this.setState({
            user: {
                ...user,
                [name]: value
            },
            submitted: false,
            isEmail: false
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        // FEATURE: further validate if is social link

        const validatorUrlOptions = {
            protocols: ['http', 'https'],
            require_host: true,
        };
        
        this.setState({
            submitted: true,
            isEmail: validator.isEmail(this.state.user.email), // validate email
            isSocialLink: validator.isURL(this.state.user.primaryPlatform, validatorUrlOptions), // validate social link
        }, () => {
            const { user, captcha, isEmail, isSocialLink } = this.state;
            if (isEmail && isSocialLink && captcha && user.name && user.igHandle && user.primaryPlatform && user.email && user.type) {
                // register user for early access
                this.props.getEarlyAccess(user);
                analytics.event('Homepage', 'Early Access Form Submittion', 'Clicked Form Submit Button'); // register click event
            }
        });
    }

    handleCaptcha(value) {
        if (value) this.setState({ captcha: true });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <Header
                    border={true}
                    showLogo={true}
                    logoLinkTo={sitemap.landing.home}
                    primaryButtonText={contentLandingPageHeader.earlyAccess}
                    primaryButtonHandleClick={() => this.handleScrollToForm('Header Apply Button')}
                    primaryButtonGradientStyle={true}
                    buttons={[ { text: contentLandingPageHeader.login, link: sitemap.auth.login } ]}
                />

                <EarlyAccessLinkInBio
                    alert={this.props.alert}
                    handleScrollReset={this.handleScrollReset}
                    scrollToSection={this.state.scrollToSection}
                    handleScrollToForm={this.handleScrollToForm}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    handleCaptcha={this.handleCaptcha}
                    registering={this.props.joining}
                    user={this.state.user}
                    submitted={this.state.submitted}
                    captcha={this.state.captcha}
                    isEmail={this.state.isEmail}
                    isSocialLink={this.state.isSocialLink}
                />

                <HomeFooter menu={false} />
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { alert } = state;
    const { joining } = state.homepage;

    // export state data to props
    return { alert, joining };
}

const actionCreators = {
    getEarlyAccess: homepageActions.joinWaitlist,
    loadCsrfToken: authActions.loadCsrfToken
};

const connectedEarlyAccessLinkInBioContainer = connect(mapState, actionCreators)(EarlyAccessLinkInBioContainer);
export { connectedEarlyAccessLinkInBioContainer as EarlyAccessLinkInBioContainer };