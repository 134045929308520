import React, { Component } from "react";
import { connect } from 'react-redux';

import { TravelWaitlistv0 } from "../../../components/home";
import { homepageActions } from '../../../actions';


class TravelWaitlistV0Container extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email } = this.state;
        if (email) this.props.joinWaitlist(email);
    }

    render() {
        return <TravelWaitlistv0
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    email={this.state.email}
                    submitted={this.state.submitted}
                    joining={this.props.joining}
                    alert={this.props.alert}
                />;
    }
}

function mapState(state) {
    // get data from app reducer state
    const { alert } = state;
    const { joining } = state.homepage;

    // export state data to props
    return { alert, joining };
}

const actionCreators = {
    joinWaitlist: homepageActions.joinWaitlist
};

const connectedTravelWaitlistV0Container = connect(mapState, actionCreators)(TravelWaitlistV0Container);
export { connectedTravelWaitlistV0Container as TravelWaitlistV0Container };