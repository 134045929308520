import React, { useMemo } from "react";

import {
    Container,
    GradientDivider,
    DescriptionSection,
    Photo,
    DetailsColumn,
    Name,
    Address,
    CheckoutRow,
    CheckoutRowCol,
} from './HotelBookingSummary.styles';
import { StarSystem } from '../../custom/Rating';
import { addCurrencySymbol } from '../../../utils';



export const HotelBookingSummary = ({
    photos,
    name,
    rating,
    address,
    start,
    end,
    nights,
    rooms,
    roomName,
    total,
    basePriceBeforeCharges,
    taxesAndFees,
    taxesAndFeesNotIncludedInPrice,
    coupon,
    discount,
    discountPercentOff,
    currencyCode,
}) => {

    const hasCouponCode = useMemo(() => {
        if(coupon && discount) return true;
        return false;
    }, [coupon, discount]);

    const hasLocalTax = useMemo(() => {
        if(taxesAndFeesNotIncludedInPrice !== null && taxesAndFeesNotIncludedInPrice > 0) return true;
        return false;
    }, [taxesAndFeesNotIncludedInPrice]);

    // generate stay duration length and occupancy
    const descriptionDataStayFor = useMemo(() => {
        let output = '';
        output = output + nights + ' night' + (nights > 1 ? 's' : '');
        output = output + ', ' + rooms + ' room' + (rooms > 1 ? 's' : '');
        return output;
    }, [nights, rooms]);

    // format amounts
    const formattedTotalAmount = useMemo(() => addCurrencySymbol(currencyCode, total / 100), [currencyCode, total]);
    const formattedTaxesAndFeesAmount = useMemo(() => addCurrencySymbol(currencyCode, taxesAndFees / 100), [currencyCode, taxesAndFees]);
    const formattedLocalTax = useMemo(() => hasLocalTax ? addCurrencySymbol(currencyCode, taxesAndFeesNotIncludedInPrice / 100) : null, [currencyCode, hasLocalTax, taxesAndFeesNotIncludedInPrice]);
    const formattedTotalAmountWithLocalTax = useMemo(() => hasLocalTax ? addCurrencySymbol(currencyCode, (total + taxesAndFeesNotIncludedInPrice) / 100) : null, [currencyCode, hasLocalTax, total, taxesAndFeesNotIncludedInPrice]);
    const fomrattedPayableTotalDetails = useMemo(() => `${hasLocalTax ? ('Pay now' + (hasCouponCode ? ' (coupon applied)' : '')) : 'Discounted price'}`, [hasLocalTax, hasCouponCode]);
    const formattedPriceBeforeCharges = useMemo(() => addCurrencySymbol(currencyCode, basePriceBeforeCharges / 100), [currencyCode, basePriceBeforeCharges]);
    const formattedDiscountAmount = useMemo(() => addCurrencySymbol(currencyCode, -(discount / 100)), [currencyCode, discount]);
    const formattedDiscountAmountDetails = useMemo(() => `Discount${discountPercentOff ? (' ' + discountPercentOff) : ''} [${coupon}]`, [coupon, discountPercentOff]);
    const formattedDiscountedTotalAmount = useMemo(() => addCurrencySymbol(currencyCode, (total - discount) / 100), [currencyCode, total, discount]);


    return (
        <Container>
            <DescriptionSection>
                <Photo src={photos[0].url} fallbackSrc={photos[1].url} />

                <DetailsColumn>
                    <Name>{name}</Name>
                    <StarSystem rating={rating} />
                    <Address>{address}</Address>
                </DetailsColumn>
            </DescriptionSection>

            <CheckoutRow>
                <CheckoutRowCol bold='true' rowleftalign='true' >Check-in</CheckoutRowCol>
                <CheckoutRowCol>{start}</CheckoutRowCol>
            </CheckoutRow>

            <CheckoutRow>
                <CheckoutRowCol bold='true' rowleftalign='true' >Check-out</CheckoutRowCol>
                <CheckoutRowCol>{end}</CheckoutRowCol>
            </CheckoutRow>

            <CheckoutRow>
                <CheckoutRowCol bold='true' rowleftalign='true' >For</CheckoutRowCol>
                <CheckoutRowCol>{descriptionDataStayFor}</CheckoutRowCol>
            </CheckoutRow>

            <GradientDivider margintop='8px' marginbottom='8px' />

            <CheckoutRow>
                <CheckoutRowCol>{roomName}</CheckoutRowCol>
                <CheckoutRowCol columnrightalign='true' >{formattedPriceBeforeCharges}</CheckoutRowCol>
            </CheckoutRow>

            <CheckoutRow>
                <CheckoutRowCol>Taxes and charges</CheckoutRowCol>
                <CheckoutRowCol columnrightalign='true' >{formattedTaxesAndFeesAmount}</CheckoutRowCol>
            </CheckoutRow>

            { hasLocalTax &&
            <CheckoutRow>
                <CheckoutRowCol>Local tax (payable at property)</CheckoutRowCol>
                <CheckoutRowCol columnrightalign='true' >{formattedLocalTax}</CheckoutRowCol>
            </CheckoutRow> }

            { hasLocalTax && <GradientDivider margintop='8px' marginbottom='8px' /> }

            <CheckoutRow>
                <CheckoutRowCol bold='true' >Final price</CheckoutRowCol>
                <CheckoutRowCol bold='true' columnrightalign='true' >{formattedTotalAmountWithLocalTax || formattedTotalAmount}</CheckoutRowCol>
            </CheckoutRow>

            { hasCouponCode && <GradientDivider margintop='8px' marginbottom='8px' /> }

            { hasCouponCode &&
            <CheckoutRow>
                <CheckoutRowCol smalltext='true' >{formattedDiscountAmountDetails}</CheckoutRowCol>
                <CheckoutRowCol smalltext='true' columnrightalign='true' >{formattedDiscountAmount}</CheckoutRowCol>
            </CheckoutRow> }

            { (hasLocalTax || hasCouponCode) &&
            <CheckoutRow>
                <CheckoutRowCol>{fomrattedPayableTotalDetails}</CheckoutRowCol>
                <CheckoutRowCol columnrightalign='true' >{hasCouponCode ? formattedDiscountedTotalAmount : formattedTotalAmount}</CheckoutRowCol>
            </CheckoutRow> }

            { hasLocalTax &&
            <CheckoutRow>
                <CheckoutRowCol>Pay at property</CheckoutRowCol>
                <CheckoutRowCol columnrightalign='true' >{formattedLocalTax}</CheckoutRowCol>
            </CheckoutRow> }
            
        </Container>
    );
};