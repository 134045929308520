import React from "react";
import { SimpleLinkInput } from '../custom/FormInput';
import { DraggableList } from '../custom/List';



const listStyles = {
    marginTop: '32px',
    paddingBottom: '80px',
};

const listItemStyles = {
    marginBottom: '24px',
};


export const LinksList = ({ links, handleReorder, handleOpenEditScreen, handleEditLink, handleDeleteLink, handleOpenLink }) => {

    if(!links || links.length === 0) return null;

    return (
        <DraggableList
            name='simple-links'
            handleReorder={handleReorder}
            items={links}
            CustomListItem={SimpleLinkInput}
            listStyles={listStyles}
            listItemStyles={listItemStyles}
            handleEdit={handleOpenEditScreen}
            handleLabelChange={handleEditLink}
            handleUrlChange={handleEditLink}
            hasControls={true}
            handleEnable={handleEditLink}
            handleDelete={handleDeleteLink}
            handleOpenUrl={handleOpenLink} />
    );
};