import {
    AlertContainer,
    MessageBox,
    Counter
} from './ConfirmEmail.styles';
import { otherConstants, contentConfirmPages } from "../../../../constants";


const ConfirmEmail = (props) => {
    const { alert, redirectCounter, test } = props;

    const success = alert && alert.type === 'alert-success';
    const fail = alert && alert.type === 'alert-danger';

    return (
        <AlertContainer>
            { (success || test) && <MessageBox>{contentConfirmPages.email.success.part1}<Counter>{redirectCounter}</Counter>{contentConfirmPages.email.success.part2}</MessageBox> }
            { fail && <MessageBox>{contentConfirmPages.email.fail.part1}<a href={"mailto:" + otherConstants.supportEmail}>{otherConstants.supportEmail}</a>{contentConfirmPages.email.fail.part2}</MessageBox> }
        </AlertContainer>
    );
};

export default ConfirmEmail;