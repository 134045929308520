import { http, authHeader, handleResponse, handleError } from "../utils";
import { urlConstants } from "../constants";

export const hotelBookingService = {
    checkoutConfig,
    checkoutConfigWithCoupon,
    paymentConfig,
    getAll,
    getById,
    sendBookingConfirmationEmail,
    cancel,
};


async function checkoutConfig(data, isGuestUser) {
    const guestSessionEndpoint = isGuestUser ? urlConstants.guestSessionEndpoint : '';
    return await http.post(`/hotel-bookings${guestSessionEndpoint}/checkout-configs`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function checkoutConfigWithCoupon(data) {
    return await http.post(`/hotel-bookings/checkout-configs/coupon`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function paymentConfig(data, isGuestUser) {
    const guestSessionEndpoint = isGuestUser ? urlConstants.guestSessionEndpoint : '';
    return await http.post(`/hotel-bookings${guestSessionEndpoint}/payment-configs`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getAll(query, isGuestUser) {
    const guestSessionEndpoint = isGuestUser ? urlConstants.guestSessionEndpoint : '';
    return await http.get(`/hotel-bookings${guestSessionEndpoint}?${query.toString()}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getById(id, query, isGuestUser) {
    const guestSessionEndpoint = isGuestUser ? urlConstants.guestSessionEndpoint : '';
    let url = `/hotel-bookings${guestSessionEndpoint}/${id}`;
    if(query) url = `${url}?${query.toString()}`;
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function sendBookingConfirmationEmail(id, query, isGuestUser) {
    const guestSessionEndpoint = isGuestUser ? urlConstants.guestSessionEndpoint : '';
    let url = `/hotel-bookings${guestSessionEndpoint}/send-booking-confirmation-email/${id}`;
    if(query) url = `${url}?${query.toString()}`;
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function cancel(id, data, isGuestUser) {
    const guestSessionEndpoint = isGuestUser ? urlConstants.guestSessionEndpoint : '';
    return await http.put(`/hotel-bookings${guestSessionEndpoint}/cancel/${id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}