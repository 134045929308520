import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { sitemap } from '../constants';


// The restricted route is used to restrict the login and register pages to logged in users
// and redirect them to user profile page.
export const RestrictRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Redirect to={{ pathname: sitemap.admin.dashboard, state: { from: props.location } }} />
            : <Component {...props} />
    )} />
)