import { customerReducerConstants } from '../constants';


export function customer(state = {}, action) {

  switch (action.type) {
    
    case customerReducerConstants.GETBYUSERID_REQUEST:
        return {
            loading: true
        };
    
    case customerReducerConstants.GETBYUSERID_SUCCESS:
        return {
            ...action.customer
        };

    case customerReducerConstants.GETBYUSERID_FAILURE:
        return {
            error: action.error
        };


    case customerReducerConstants.UPDATE_REQUEST:
        return { ...state, updating: true };

    case customerReducerConstants.UPDATE_SUCCESS:
        return { ...action.customer };

    case customerReducerConstants.UPDATE_FAILURE:
        const { updating, ...rest } = state;
        return { ...rest, error: action.error };
    

    case customerReducerConstants.CLEAR_ALL:
        return {};

    default:
        return state
  }
}