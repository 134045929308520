import styled from 'styled-components';
import Icon from '../../../assets/icons/Icon';

import { colorConstants } from '../../../constants';
import { Button } from '../Buttons';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Header = styled.div`
    margin-top: 24px;
    font-size: 18px;
    font-weight: 600;
    color: ${colorConstants.black};
    text-align: center;
`;

const Text = styled.div`
    margin-bottom: 24px;
    font-size: 12px;
    font-weight: 400;
    color: ${colorConstants.mediumGrey};
    text-align: center;
`;


export const NoNFTFound = (props) => {
    const { onClick, header, text } = props;

    return (
        <Container>
            <Icon type='crypto' platform='nft' size={90} />
            {!header && <Header>Add Cool NFTs</Header>}
            {!text && <Text>Share your favourite NFTs with your fans</Text>}
            {onClick && <Button outline={true} small={true} onClick={onClick} >Add NFT</Button>}

            {header && <Header>{header}</Header>}
            {text && <Text>{text}</Text>}
        </Container>
    );
};