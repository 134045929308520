import React, { Component } from "react";
import { connect } from 'react-redux';
import Joyride from 'react-joyride';

import { userActions, alertActions } from '../../actions';
import { ScreenContainer } from './DashboardContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { FullScreenSpinner } from "../../components/custom/Spinner";
import { Notification, Upgrade } from '../../components/custom/Popup';
import { TopBar } from '../../components/custom/TopBar';
import { SideBar } from '../../components/custom/SideBar';
import { DashboardPage } from "../../components/dashboard";
import { sitemap, contentDashboarPage, colorConstants } from "../../constants";
import { history, defaultUser, prepareUserData } from '../../utils';


class DashboardContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            fromUrl: sitemap.admin.dashboard,
            sidebar: false,
            sidebarReset: false,
            sidebarPositionOffset: 0,
            sticky: false,
            showUpgradePopup: false, // used to toggle upgrade popup
            notification: '',
            user: defaultUser()
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleSideBarToggle = this.handleSideBarToggle.bind(this);

        this.handleOpenSettings = this.handleOpenSettings.bind(this);
        this.handleOpenCustomizations = this.handleOpenCustomizations.bind(this);
        this.handleOpenLinks = this.handleOpenLinks.bind(this);
        this.handleOpenTrips = this.handleOpenTrips.bind(this);
        this.handleOpenProducts = this.handleOpenProducts.bind(this);
        this.handleOpenFavourites = this.handleOpenFavourites.bind(this);
        this.handleOpenNFTs = this.handleOpenNFTs.bind(this);
        this.handleAddTrip = this.handleAddTrip.bind(this);
        this.handleAddProduct = this.handleAddProduct.bind(this);
        this.handleAddNFT = this.handleAddNFT.bind(this);
        this.handleBecomeCreator = this.handleBecomeCreator.bind(this);
        this.handleCopyLink = this.handleCopyLink.bind(this);
        /* Upgrade */
        this.handleToggleUpgradePopup = this.handleToggleUpgradePopup.bind(this);
        this.handleUpgrade = this.handleUpgrade.bind(this);
        /* App Tour */
        this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        if(!this.props.user || !this.props.user.id) {
            // get user data from db
            this.props.getById(this.props.userId);
        } else if(!this.state.init && this.props.user && this.props.user.id) {
            // load user data from server into component state
            this.setState({ ...this.state, init: true, user: this.props.user });
            // check creator mode status
            this.props.getCreatorModeStatus(this.props.user.id);
        }

        window.addEventListener('scroll', this.handleScroll);

        if(this.props.location.state) {
            let initialState = {};
            if(this.props.location.state.from) initialState = { ...this.state, ...initialState, fromUrl: this.props.location.state.from }; // set from URL
            // get data from previous page
            if(this.props.location.state.data) {
                const { scrollToSection, upgrade } = this.props.location.state.data;
                // set section to scroll to
                if(scrollToSection) initialState = { ...this.state, ...initialState, scrollToSection };
                // refresh page after plan upgrade
                if(upgrade === 'upgraded-subscription') {
                    // FEATURE: Show upgrade success popup
                }
            }
            // set initial settings page state
            this.setState(initialState);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        // load user data from server into component state
        if (!this.state.init && this.props.user && this.props.user.id) {
            this.setState({ ...this.state, init: true, user: this.props.user });
            // check creator mode status
            this.props.getCreatorModeStatus(this.props.user.id);
        }
    }

    handleScroll(event) {
        // Enable sticky mode on scroll and reset sidebar
        this.setState({ ...this.state, sidebar: false, sidebarReset: true, sidebarPositionOffset: window.pageYOffset, sticky: window.pageYOffset > 1 });
    }

    handleSideBarToggle(){
        this.setState({ ...this.state, sidebar: !this.state.sidebar, sidebarReset: false });
    }

    handleOpenSettings() {
        history.push({
            pathname: sitemap.admin.settings,
            state: {
                from: sitemap.admin.dashboard
            }
        });
    }

    handleOpenCustomizations() {
        history.push({
            pathname: sitemap.admin.customize,
            state: {
                from: sitemap.admin.dashboard
            }
        });
    }

    handleOpenLinks(){
        // check if links are enabled
        const enabled = this.state.user.pageData.metadata.links.simple.enabled || this.state.user.pageData.metadata.links.blog.enabled || this.state.user.pageData.metadata.links.github.enabled || this.state.user.pageData.metadata.links.youtube.enabled || this.state.user.pageData.metadata.links.youtubeshort.enabled || this.state.user.pageData.metadata.links.instagramreel.enabled || this.state.user.pageData.metadata.links.tiktok.enabled || this.state.user.pageData.metadata.links.spotify.enabled;

        history.push({
            pathname: enabled ? sitemap.admin.links.list : sitemap.admin.customize, // got to customize if links are disabled
            state: {
                from: sitemap.admin.dashboard
            }
        });
    }

    handleOpenTrips(){
        // check if trips are enabled
        const enabled = this.state.user.pageData.metadata.links.accommodation.enabled || this.state.user.pageData.metadata.links.experience.enabled;

        history.push({
            pathname: enabled ? sitemap.admin.trips.list : sitemap.admin.customize, // got to customize if links are disabled
            state: {
                from: sitemap.admin.dashboard
            }
        });
    }

    handleOpenProducts(){
        // check if products are enabled
        const enabled = this.state.user.pageData.metadata.links.physicalProduct.enabled || this.state.user.pageData.metadata.links.digitalProduct.enabled;

        history.push({
            pathname: enabled ? sitemap.admin.products.list : sitemap.admin.customize, // got to customize if links are disabled
            state: {
                from: sitemap.admin.dashboard
            }
        });
    }

    handleOpenFavourites(){
        history.push({
            pathname: sitemap.admin.favourites,
            state: {
                from: sitemap.admin.dashboard
            }
        });
    }

    handleOpenNFTs(){
        // check if products are enabled
        const enabled = this.state.user.pageData.metadata.links.nft.enabled;

        history.push({
            pathname: enabled ? sitemap.admin.nft.list : sitemap.admin.customize, // got to customize if links are disabled
            state: {
                from: sitemap.admin.dashboard
            }
        });
    }

    handleAddTrip(){
        // check if trips are enabled
        const enabled = this.state.user.pageData.metadata.links.accommodation.enabled || this.state.user.pageData.metadata.links.experience.enabled;

        history.push({
            pathname: enabled ? sitemap.admin.trips.list : sitemap.admin.customize, // got to customize if links are disabled
            state: {
                from: sitemap.admin.dashboard,
            }
        });
    }

    handleAddProduct(){
        // check if products are enabled
        const enabled = this.state.user.pageData.metadata.links.physicalProduct.enabled || this.state.user.pageData.metadata.links.digitalProduct.enabled;

        history.push({
            pathname: enabled ? sitemap.admin.products.add : sitemap.admin.customize, // got to customize if links are disabled
            state: {
                from: enabled ? sitemap.admin.products.list : sitemap.admin.dashboard,
            }
        });
    }

    handleAddNFT(){
        // check if products are enabled
        const enabled = this.state.user.pageData.metadata.links.nft.enabled;

        history.push({
            pathname: enabled ? sitemap.admin.nft.add : sitemap.admin.customize, // got to customize if links are disabled
            state: {
                from: enabled ? sitemap.admin.nft.list : sitemap.admin.dashboard,
            }
        });
    }

    handleBecomeCreator(){
        history.push({
            pathname: sitemap.admin.settings,
            state: {
                from: sitemap.admin.dashboard,
                data: {
                    scrollToSection: 'creator-application'
                }
            }
        });
    }

    handleCopyLink(url) {
        navigator.clipboard.writeText(url);
        this.setState({ ...this.state, notification: 'Copied to clipboard' });
    }

    handleToggleUpgradePopup() {
        this.setState({ ...this.state, showUpgradePopup: !this.state.showUpgradePopup });
    }

    handleUpgrade(){
        history.push({
            pathname: sitemap.billing.upgrade,
            state: {
                from: sitemap.admin.dashboard
            }
        });
    }

    handleJoyrideCallback(data) {
        if(!data || !this.state.init) return;
        
        const { status } = data;

        if(status === 'finished' || status === 'skipped') {
            this.setState({ ...this.state, user: { ...this.state.user, isDashboardTourDone: true } }, () => this.props.updateUser(prepareUserData(this.state.user)));
        }
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
        this.setState({ ...this.state, notification: '' });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title='Dashboard' />

                { this.state.init && this.state.user.creator && !this.state.user.isDashboardTourDone &&
                <Joyride
                    callback={this.handleJoyrideCallback}
                    continuous
                    hideCloseButton
                    disableCloseOnEsc
                    disableOverlayClose
                    hideBackButton
                    // scrollToFirstStep
                    showProgress
                    showSkipButton
                    steps={contentDashboarPage.tour}
                    run={true}
                    styles={{
                        options: {
                            primaryColor: colorConstants.primaryDark,
                        },
                    }}
                /> }

                <TopBar
                    className='dashboard-topbar'
                    sticky={this.state.sticky}
                    menuButton={this.handleSideBarToggle} />

                {this.state.init &&
                <SideBar
                    options={{ isAuthenticated: true, creator: this.state.user.creator, basic: this.state.user.basic, premium: this.state.user.premium, platinum: this.state.user.platinum, links: this.state.user.pageData.metadata.links }}
                    toggle={this.handleSideBarToggle}
                    from={sitemap.admin.dashboard}
                    enable={this.state.sidebar}
                    reset={this.state.sidebarReset}
                    position={this.state.sidebarPositionOffset}
                    mfullscreen='true' /> }

                {this.state.init &&
                <DashboardPage
                    user={this.state.user}
                    creatorModeStatus={this.props.creatorModeStatus}
                    handleSideBarToggle={this.handleSideBarToggle}
                    handleOpenSettings={this.handleOpenSettings}
                    handleOpenCustomizations={this.handleOpenCustomizations}
                    handleOpenLinks={this.handleOpenLinks}
                    handleOpenTrips={this.handleOpenTrips}
                    handleOpenProducts={this.handleOpenProducts}
                    handleOpenFavourites={this.handleOpenFavourites}
                    handleOpenNFTs={this.handleOpenNFTs}
                    handleAddTrip={this.handleAddTrip}
                    handleAddProduct={this.handleAddProduct}
                    handleAddNFT={this.handleAddNFT}
                    handleBecomeCreator={this.handleBecomeCreator}
                    handleCopyLink={this.handleCopyLink}
                    handleUpgrade={this.handleToggleUpgradePopup} /> }

                {(this.state.notification || (this.props.alert && this.props.alert.message)) &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.state.notification || this.props.alert.message} /> }

                {this.state.showUpgradePopup &&
                <Upgrade
                    show={this.state.showUpgradePopup}
                    onClick={this.handleUpgrade}
                    onHide={this.handleToggleUpgradePopup} /> }

                {this.props.user.loading && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user } = state;

    // get creator mode status
    const { creatorModeStatus } = user;

    // export state data to props
    return { userId, alert, user, creatorModeStatus };
}

const actionCreators = {
    getById: userActions.getById,
    updateUser: userActions.update,
    getCreatorModeStatus: userActions.creatorModeStatus,
    clearAlert: alertActions.clear
}

const connectedDashboardContainer = connect(mapState, actionCreators)(DashboardContainer);
export { connectedDashboardContainer as DashboardContainer };