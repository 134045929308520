import styled from 'styled-components';
import Icon from '../../assets/icons/Icon';

import { obfuscateAddress } from '../../utils';
import { colorConstants } from "../../constants";


const GradientBorder = styled.div`
    background: ${colorConstants.gradientHorizontal};
    padding: 1px;
    margin-top: 10px;
    margin-bottom: 8px;
    border-radius: 2px;

    display: flex;
    align-items: center;
`;

const Container = styled.div`
    border-radius: 2px;
    border-color: ${colorConstants.white};
    background: ${colorConstants.white};
    width: 100%;
    padding: 4px 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Content = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Address = styled.div`
    margin-left: 16px;

    font-weight: 400;
    font-size: 10px;
    color: ${colorConstants.black};
    margin-right: 8px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; // to use this we need to set max-width
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const DisconnectButton = styled(Icon)`
    margin-left: 18px;
    :hover {
        cursor: pointer;
    }
`;


const SettingsCryptoWalletItem = ({ data, handleDisconnect }) => {

    return (
        <GradientBorder>
            <Container>
                <Content>
                    <Icon type='crypto' platform={data.type.toLowerCase()} size={14} color={true} />
                    <Address>{`Address:  ${obfuscateAddress(data.address)}`}</Address>
                </Content>

                <Buttons>
                    <DisconnectButton type='disconnect' size={14} customcolor={colorConstants.black} onClick={() => handleDisconnect(data)} />
                </Buttons>
            </Container>
        </GradientBorder>
    );
};

export default SettingsCryptoWalletItem;