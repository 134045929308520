import { http, authHeader, handleResponse, handleError } from "../utils";

export const linkService = {
    create,
    getAll,
    getById,
    update,
    delete: _delete
};

async function create(data) {
    return await http.post("/links", data, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function getAll(data) {
    return await http.get(`/links?${data.toString()}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function getById(id) {
    return await http.get(`/links/${id}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function update(data) {
    return await http.put(`/links/${data.id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function _delete(id) {
    return await http.delete(`/links/${id}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}