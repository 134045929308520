import React, { useState, useEffect, useCallback } from 'react';
import {
    Container,
    Header,
    Title,
    Body,
    DatePickerSection,
    WeekdaysSection,
    SubmitButton,
} from './DatePicker.styles';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';



const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();


const WeekdaysOverlay = () => {
    const weekdays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    return (
        <WeekdaysSection className='rdp-table'>
            <thead className='rdp-head'>
                <tr className='rdp-head_row'>
                    {weekdays.map((d, index) => (
                        <th key={index} scope='col' className='rdp-head_cell'>
                            <span aria-hidden='true'>{d}</span>
                        </th>
                    ))}
                </tr>
            </thead>
        </WeekdaysSection>
    );
};



export const DatePicker = ({ onHide, show, dates, onDatePick, isReset, handleResetCompleted, showButton }) => {
    const [range, setRange] = useState();
    const [totalDays, setTotalDays] = useState();
    const [loadedPreviousDates, setLoadedPreviousDates] = useState(false);

    let title = 'Select Dates';

    if (range?.from) {
        if (!range.to) {
            title = format(range.from, 'eee dd LLL');
        } else if (range.to) {
            title = format(range.from, 'eee dd LLL') + ' - ' + format(range.to, 'eee dd LLL');
        }
    }

    // method to set selected date range
    function onSelect(d) {

        if(range && d) {
            const hasFrom = range.from && d.from;

            let resetFromDate = null;

            // reset and set new start date when selected older date than current start date
            if(hasFrom && d.from < range.from && !resetFromDate) resetFromDate = d.from;

            // reset and set new start date when select more than 29 days
            if(hasFrom && d.to && !resetFromDate) {
                const totalStayLength = Math.round((d.to.getTime() - d.from.getTime()) / (1000 * 3600 * 24));
                if(totalStayLength > 29) resetFromDate = d.to;
            }

            if(resetFromDate) {
                // reset to new start date
                setRange({ from: resetFromDate, to: null });
                // reset total days
                setTotalDays(0);
                // disaply starting date label
                if(!showButton && onDatePick) {
                    onDatePick({
                        data: d,
                        from: {
                            date: format(resetFromDate, 'yyyy-LL-dd'),
                            label: format(resetFromDate, 'eee dd LLL')
                        },
                        totalDays: 0
                    });
                }
                return;
            }
        }

        // save selected date range
        setRange(d);
        
        if(d && d.to) {
            // save total number of days in selected range
            let totalStayLength = Math.round((d.to.getTime() - d.from.getTime()) / (1000 * 3600 * 24));
            setTotalDays(totalStayLength);

            // export date range when from and to are selected
            if(!showButton && onDatePick) {
                onDatePick({
                    data: d,
                    from: {
                        date: format(d.from, 'yyyy-LL-dd'),
                        label: format(d.from, 'eee dd LLL')
                    },
                    to: {
                        date: format(d.to, 'yyyy-LL-dd'),
                        label: format(d.to, 'eee dd LLL'),
                    },
                    totalDays: totalStayLength,
                });
            }

            // hide date picker when date range is selected
            // if(onHide) onHide();
        } else if(d && d.from) {
            // set total days
            setTotalDays(0);
            // set starting date to date picker button label
            if(!showButton && onDatePick) {
                onDatePick({
                    data: d,
                    from: {
                        date: format(d.from, 'yyyy-LL-dd'),
                        label: format(d.from, 'eee dd LLL')
                    },
                    totalDays: 0,
                });
            }
        } else {
            setTotalDays(null);
        }
    }


    // Submit selected dates when button is clicked
    const handleSubmit = useCallback(() => {
        if(!showButton || !range) return;

        let output = { data: range, totalDays };

        if(range.from) {
            output.from = {
                date: format(range.from, 'yyyy-LL-dd'),
                label: format(range.from, 'eee dd LLL')
            };
        }

        if(range.to) {
            output.to = {
                date: format(range.to, 'yyyy-LL-dd'),
                label: format(range.to, 'eee dd LLL'),
            };
        }

        if(onDatePick) onDatePick(output);
        if(onHide) onHide();

    }, [showButton, range, totalDays, onDatePick, onHide]);


    useEffect(() => {
        // reset selected dates
        if(isReset) {
            setRange(null);
            setTotalDays(null);
            if(handleResetCompleted) handleResetCompleted();
        }

        // load previous dates if any
        if(!loadedPreviousDates && dates && dates.data && dates.totalDays) {
            setLoadedPreviousDates(true);
            // reset time on date object
            if(dates.data.from && dates.data.from instanceof Date) dates.data.from.setHours(0, 0, 0, 0);
            if(dates.data.to && dates.data.to instanceof Date) dates.data.to.setHours(0, 0, 0, 0);
            // save dates
            setRange(dates.data);
            setTotalDays(dates.totalDays);
        }

    }, [isReset, handleResetCompleted, dates, loadedPreviousDates]);
    

    return (
        <Container show={show} onHide={onHide}>
            <Header closeButton>
                <Title>{title}</Title>
            </Header>

            <Body>
                <WeekdaysOverlay />

                <DatePickerSection>
                    <DayPicker
                        mode="range"
                        defaultMonth={today}
                        hideHead // hide week days
                        initialFocus // focuses date picker on selected dates
                        numberOfMonths={16} // remove this for single month view
                        disableNavigation // remove this for single month view
                        min={1} // select a range of at least 1 day
                        fromDate={today} // for date navigation
                        toMonth={new Date(currentYear + 2, currentMonth - 1)} // for date navigation up to 2 years ahead
                        selected={range}
                        onSelect={onSelect}
                    />
                </DatePickerSection>
                
                { showButton && <SubmitButton midCorner={true} gradient={true} onClick={() => handleSubmit()} >Done</SubmitButton> }
            </Body>
      </Container>
    );
};