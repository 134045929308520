import {
    Container,
    LeftColumn, ActiveIcon, InactiveIcon,
    Text,
} from './Feature.styles';

export const Feature = ({
    text,
    active,
}) => {

    return (
        <Container>
            <LeftColumn>
                {active && <ActiveIcon />}
                {!active && <InactiveIcon />}
            </LeftColumn>

            <Text>{text}</Text>
        </Container>
    );
};