import { http, authHeader, handleResponse, handleError } from "../utils";

export const cryptoService = {
    connectWallet,
    disconnectWallet,
    getAllWallets,
    getWalletAssets,
};


async function connectWallet(data) {
    return await http.post("/crypto/wallet-connect", data, { headers: authHeader() })
                        .then((res) => handleResponse(res, true))
                        .catch((e) => handleError(e, true));
}

async function disconnectWallet(id) {
    return await http.delete(`/crypto/wallet-disconnect/${id}`, { headers: authHeader() })
                        .then((res) => handleResponse(res, true))
                        .catch((e) => handleError(e, true));
}

async function getAllWallets(data) {
    return await http.get(`/crypto/wallet?${data.toString()}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function getWalletAssets(id, query) {
    let url = `/crypto/wallet/assets/${id}`;
    if(query) url = url + `?${query.toString()}`;
    return await http.get(url, { headers: authHeader() })
                        .then((res) => handleResponse(res, true))
                        .catch((e) => handleError(e, true));
}