import { creatorPayoutReducerConstants } from '../constants';


export function creatorPayout(state = {}, action) {

  switch (action.type) {
    case creatorPayoutReducerConstants.GET_EARNINGS_STATS_BYID_REQUEST:
        return { loading: true };
    
    case creatorPayoutReducerConstants.GET_EARNINGS_STATS_BYID_SUCCESS:
        return { stats: action.stats };

    case creatorPayoutReducerConstants.GET_EARNINGS_STATS_BYID_FAILURE:
        return { error: action.error };
    

    case creatorPayoutReducerConstants.CLEAR_ALL:
        return {};

    default:
        return state
  }
}