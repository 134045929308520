import { publicHotelReducerConstants } from "../constants";
import { publicHotelService } from "../services";


export const publicHotelActions = {
    findAllByLocation,
    getSearchResultsBySession,
    getBySlug,
    clearSearchSession,
    clearHotelDetails,
    clear,
};


function findAllByLocation(query) {
    return dispatch => {
        dispatch(request());

        publicHotelService.findAllByLocation(query)
            .then(
                session => dispatch(success(session)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publicHotelReducerConstants.FIND_ALL_BY_LOCATION_REQUEST } }
    function success(session) { return { type: publicHotelReducerConstants.FIND_ALL_BY_LOCATION_SUCCESS, session } }
    function failure(error) { return { type: publicHotelReducerConstants.FIND_ALL_BY_LOCATION_FAILURE, error } }
}

function getSearchResultsBySession(data) {
    return dispatch => {
        dispatch(request());

        publicHotelService.getSearchResultsBySession(data)
            .then(
                hotels => dispatch(success(hotels)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publicHotelReducerConstants.GET_SEARCH_RESULTS_BY_SESSION_REQUEST } }
    function success(hotelsAtLocation) { return { type: publicHotelReducerConstants.GET_SEARCH_RESULTS_BY_SESSION_SUCCESS, hotelsAtLocation } }
    function failure(error) { return { type: publicHotelReducerConstants.GET_SEARCH_RESULTS_BY_SESSION_FAILURE, error } }
}

function getBySlug(slug, query) {
    return dispatch => {
        dispatch(request());

        publicHotelService.getBySlug(slug, query)
            .then(
                hotel => dispatch(success(hotel)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publicHotelReducerConstants.GETBYSLUG_REQUEST } }
    function success(hotel) { return { type: publicHotelReducerConstants.GETBYSLUG_SUCCESS, hotel } }
    function failure(error) { return { type: publicHotelReducerConstants.GETBYSLUG_FAILURE, error } }
}

function clearSearchSession() {
    return { type: publicHotelReducerConstants.CLEAR_SEARCH_SESSION };
}

function clearHotelDetails() {
    return { type: publicHotelReducerConstants.CLEAR_HOTEL_DETAILS };
}

function clear() {
    return { type: publicHotelReducerConstants.CLEAR_ALL };
}