import { useMemo } from 'react';
import styled from 'styled-components';
import { StarSystem, GoogleRating } from '../../custom/Rating';
import { colorConstants } from "../../../constants";


const Container = styled.div`

`;

const Row = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`;

const Location = styled.div`
    margin-bottom: 4px;

    font-weight: 500;
    font-size: 12px;

    color: ${colorConstants.black};
`;

const Name = styled.div`
    margin-top: 14px;

    font-weight: 800;
    font-size: 20px;
    line-height: 1.2;

    color: ${colorConstants.black};
`;



export const HotelHeader = ({ name, type, stars, city, country, rating, reviewsCount }) => {

    const partnerType = useMemo(() => {
        if(!type) return null;
        return stars ? `${stars}-Star ${type}` : type;
    }, [type, stars]);

    const partnerCategory = useMemo(() => {
        if(!stars) return partnerType;
        return <StarSystem rating={stars} label={partnerType} margin='0px' color='gold' size={12} />;
    }, [partnerType, stars]);

    const formatLocation = useMemo(() => {
        if(city && country) return city + ', ' + country;
        return city || country;
    }, [city, country]);


    return (
        <Container>
            <Row>
                <Column>
                    <Location>{formatLocation}</Location>
                    {partnerCategory}
                </Column>

                { rating && reviewsCount && <GoogleRating type='S8' rating={rating} reviewsCount={reviewsCount} /> }
            </Row>
            <Name>{name}</Name>
        </Container>
    );
};