import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Calendar3 } from 'react-bootstrap-icons';

import { colorConstants } from "../../../constants";


const Container = styled.div`
    padding: 1px;
    margin: 0;

    background: ${colorConstants.gradientHorizontal};

    border: none;
    border-radius: 4px;
`;

const Content = styled.div`
    width: 100%;

    padding: 8px 16px;
    margin: 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: ${colorConstants.white};
    color: ${colorConstants.black};

    border: none;
    border-radius: 4px;

    font-size: 15px;
    font-weight: 600;

    :hover {
        background: ${colorConstants.gradientHorizontal};
        color: ${colorConstants.white};
        cursor: pointer;

        .icon {
            fill: ${colorConstants.white};
        }
    }
`;

const Left = styled.div`
    width: 10%;
    margin-right: 16px;
`;

const Right = styled.div`
    width: 90%;

    display: flex;
`;

const DateText = styled.div`
    
`;



export const DateSelector = ({ dates, onClick }) => {

    const [checkIn, setCheckIn] = useState('Check-in');
    const [checkOut, setCheckOut] = useState('Check-out');

    useEffect(() => {
        if(dates) {
            if(dates.from && dates.from.label) {
                setCheckIn(dates.from.label);
            } else {
                setCheckIn('Check-in');
            }

            if(dates.to && dates.to.label) {
                setCheckOut(dates.to.label);
            } else {
                setCheckOut('Check-out');
            }
        }
    }, [dates]);
    
    return (
        <Container onClick={onClick}>
            <Content>
                <Left>
                    <Calendar3 size={18} color={colorConstants.black} className='icon' />
                </Left>

                <Right>
                    <DateText>{checkIn} - {checkOut}</DateText>
                </Right>
            </Content>
        </Container>
    );
};