import styled from "styled-components";

import Icon from '../../assets/icons/Icon';
import { colorConstants, deviceConstants } from "../../constants";


const Container = styled.div`
    width: 124px;

    @media ${deviceConstants.laptop} {
        width: 164px;
    }

    :hover {
        cursor: pointer;

        .creator-spotlight-photo {
            transform: scale(1.1);
            transition: transform 0.3s ease-in-out;
        }

        .creator-spotlight-name {
            color: ${colorConstants.primaryDark};
        }
    }
`;

const ImageSection = styled.div`
    margin-bottom: 16px;

    height: 94px;
    width: 124px;
    border-radius: 16px;
    overflow: hidden;

    @media ${deviceConstants.laptop} {
        height: 134px;
        width: 164px;
    }
`;

const Image = styled.img`
    height: 94px;
    width: 124px;
    border-radius: 16px;

    object-fit: cover;

    @media ${deviceConstants.laptop} {
        height: 134px;
        width: 164px;
    }
`;

const HandleSection = styled.div`
    display: flex;
    align-items: center;
`;

const Handle = styled.div`
    margin-right: 8px;

    font-size: 14px;
    font-weight: 600;
    color: ${colorConstants.black};
`;



export const CreatorSpotlightItem = ({ data, handleClick }) => {
    return (
        <Container onClick={() => handleClick(data)} >
            <ImageSection className="creator-spotlight-photo-section" >
                <Image src={data.photo} className="creator-spotlight-photo" />
            </ImageSection>

            { data.username &&
            <HandleSection>
                <Handle className="creator-spotlight-name" >@{data.username}</Handle>
                <Icon type='verified_check' size={13} />
            </HandleSection> }
        </Container>
    );
};