import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import qs from 'qs';

import { authActions, publicHotelActions, hotelActions, hotelBookingActions } from '../../actions';
import { ScreenContainer } from './HotelContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { HotelPage } from '../../components/hotels';
import { Share, DatePicker, Map } from '../../components/custom/Popup';
import { ChildrenAgesPopup } from '../../components/trips/trip/ChildrenAgesPopup';
import { HotelRoomCheckRatePopup } from '../../components/trips/trip/HotelRoomCheckRatePopup';
import { FullScreenSpinner } from "../../components/custom/Spinner";
import { history, hotelBookingCheckoutConfigData, dateDifferenceIn } from '../../utils';
import { sitemap, urlConstants, getHotelLink } from '../../constants';


export const HotelContainer = () => {
    const dispatch = useDispatch();

    // get params and data
    const { username, slug } = useParams();
    const { state, pathname, search } = useLocation();

    // application state
    const { cookie, loading, error, publicHotel, hotel, hotelBooking } = useSelector(state => ({
        cookie: state.auth.cookie,
        loading: state.publicHotel.loading,
        error: state.publicHotel.error,
        publicHotel: state.publicHotel.hotel,
        hotel: state.hotel,
        hotelBooking: state.hotelBooking,
    }));

    // component state
    const [booking, setBooking] = useState({
        dates: {},
        occupancyTotal: 1, // sum of adults and children that must not exceed 16
        occupancyTotalMax: 16,
        adults: 1,
        adultsMax: 16,
        children: 0,
        childrenMax: 15,
        childrenAges: [],
        infants: 0,
        rooms: 1,
        roomsMax: 4,
        doubleBedType: true,
        mealOptions: [],
        roomOptions: [],
        selectedMeal: 0,
        selectedOccupancy: 1,
        selectedCancellationPolicy: '',
        hasCancellationPoliciesOptions: false,
        selectedRoom: {},
        selectedRoomRates: [],
        selectedRoomRate: {},
        currency: {
            code: 'EUR',
            symbol: '',
        },
    });
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [init, setInit] = useState(false); // initialize hotel data
    const [isLoadingContent, setIsLoadingContent] = useState(null); // used to show spinner on extra delay
    const [pageLoadCount, setPageLoadCount] = useState(0);
    const [fromUrl, setFromUrl] = useState(sitemap.auth.login);
    const [hotelData, setHotelData] = useState(null);
    const [authenticated, setAuthenticated] = useState(false); // used for hide/show topbar for logged in users
    const [isOpenInNewTab, setIsOpenInNewTab] = useState(true);
    const [sticky, setSticky] = useState(false); // make topbar sticky
    const [sharing, setSharing] = useState(false); // hide/show share popup
    const [occupancyOptionsToggle, setOccupancyOptionsToggle] = useState(false); // toggle occupancy options
    const [enableAvailabilityCheckRequired, setEnableAvailabilityCheckRequired] = useState(true); // enable/disable availability check requests when changing options
    const [availabilityCheckRequired, setAvailabilityCheckRequired] = useState(true); // highlights the search button and disables checkout button when true
    const [availabilityCheckButtonText, setAvailabilityCheckButtonText] = useState('SEARCH');
    const [datePicker, setDatePicker] = useState(false); // toggle date picker popup
    const [initCheckoutScreen, setInitCheckoutScreen] = useState(false);
    const [showChildrenAges, setShowChildrenAges] = useState(false);
    const [hotelRoomCheckRateInit, setHotelRoomCheckRateInit] = useState(false);
    const [showHotelRoomCheckRate, setShowHotelRoomCheckRate] = useState(false);
    const [hotelRoomCheckRate, setHotelRoomCheckRate] = useState(null);
    const [isSearchExpired, setIsSearchExpired] = useState(false);
    const [isMapVisible, setIsMapVisible] = useState(false);

    const isAvailabilityCheckEnabled = useMemo(() => {
        if(enableAvailabilityCheckRequired && availabilityCheckRequired) return true;
        return false;
    }, [enableAvailabilityCheckRequired, availabilityCheckRequired]);

    // enable sticky mode on scroll event
    const handleScroll = useCallback((event) => {
        setSticky(window.pageYOffset > 1);
    }, []);

     // go back to previous screen
     const handleTopBarBackButton = useCallback(() => {
        history.push({
            pathname: fromUrl,
            state: {
                from: getHotelLink(username, slug),
            }
        });

    }, [fromUrl, username, slug]);


    /** componentDidMount **/
    useEffect(() => {
        if(!init && !componentDidMount) {
            // set component did mount
            setComponentDidMount(true);

            // increase page load count
            setPageLoadCount(prev => prev + 1);

            // reset content delay spinner
            clearInterval(isLoadingContent);
            setIsLoadingContent(null);

            // redirect to previous page if no username
            if(!username) {
                history.push({ pathname: sitemap.auth.login });
                return;
            }

            // load csrf token in cookies
            dispatch(authActions.loadCsrfToken());

            // listen to scroll events
            window.addEventListener('scroll', handleScroll);
            window.scrollTo(0, 0); // force scroll to top of page

            if(state) {
                // set from URL
                if(state.from) setFromUrl(state.from);
                // get data from previous page
                if(state.data) {
                    const { hotelId } = state.data;
                    // set is open in same tab
                    if(hotelId) setIsOpenInNewTab(false);
                }
            }

            // get query from hotel link
            let query = qs.parse(search, { ignoreQueryPrefix: true });

            // save search session in state
            let tempSession = (query && query.session) ? query.session : null;

            // create api request query
            let queryConfigs = { username };
            if(tempSession) queryConfigs.session = tempSession;
            // get public hotel data
            dispatch(publicHotelActions.getBySlug(slug, new URLSearchParams(queryConfigs)));

            // execute private startup methods for authenticated users
            if(cookie && cookie.id) {
                // set authenticated mode
                setAuthenticated(true);

            } else {

                // check if has guest account
                let isGuest = cookie && cookie.guestUserId && cookie.guestUserCreatedAt;

                // check if cookie older than 24 hours (1 Day)
                if(isGuest) {
                    const diff = dateDifferenceIn(new Date(cookie.guestUserCreatedAt), new Date(), 'HOURS');
                    // force new guest user creation if cookie older than 1 day
                    if(diff >= 24) isGuest = false;
                }

                // register new guest user session for checkout
                if(!isGuest) dispatch(authActions.registerGuestUser());
            }
        }
    }, [init, componentDidMount, dispatch, state, cookie, slug, username, search, handleScroll, isLoadingContent]);


    /** componentWillUnmount **/
    useEffect(() => {
        return () => {
            // remove scroll listener
            window.removeEventListener('scroll', handleScroll);
            // reset any previous room types
            dispatch(hotelActions.clear());
            // reset public hotel details data from state
            dispatch(publicHotelActions.clearHotelDetails());
            // reset state data
            setHotelData(null);
        }
    }, [handleScroll, dispatch]);


    /** componentDidUpdate **/
    useEffect(() => {
        // init public hotel data
        if(!init && !loading && publicHotel && publicHotel.partner && publicHotel.partner.id) {
            setInit(true);

            const { session, ...other } = publicHotel.partner;

            // save search session params
            if(session) {
                // reset any previous room types
                dispatch(hotelActions.clear());
                // reset any previous hotel booking checkout configs
                dispatch(hotelBookingActions.resetCheckoutConfig());

                // set required flags
                setOccupancyOptionsToggle(true);
                setEnableAvailabilityCheckRequired(true);
                setAvailabilityCheckRequired(false);
                setAvailabilityCheckButtonText('CHANGE SEARCH');

                // initialize updated booking options
                var newState = { ...booking };
                // get adults and children
                newState.adults = parseInt(session.adults);
                newState.children = parseInt(session.children);
                newState.childrenAges = session.childrenAges;
                newState.infants = parseInt(session.infants);
                // calculate occupancyTotal value
                newState.occupancyTotal = newState.adults + newState.children;
                // calculate adult max value
                newState.adultsMax = (booking.occupancyTotalMax - newState.occupancyTotal) + newState.adults;
                // calculate children max value
                newState.childrenMax = (booking.occupancyTotalMax - newState.occupancyTotal) + newState.children;
                // get from/to dates
                newState.dates = {
                    data: {
                        from: new Date(session.start),
                        to: new Date(session.end),
                    },
                    from: {
                        date: session.start,
                        label: session.startFormatted
                    },
                    to: {
                        date: session.end,
                        label: session.endFormatted
                    },
                    totalDays: parseInt(session.nights),
                };
                // save booking values to state
                setBooking(newState);
            }
            
            // save data
            setHotelData({ ...publicHotel, partner: other });
        }

        if(!init && !loading && publicHotel && !publicHotel.partner && publicHotel.delay) {
            // if content is not ready after 3 requests (4 x 7sec = 28sec) then redirect back to search results
            if(pageLoadCount >= 4) {
                handleTopBarBackButton();
                return;
            }

            // show spinner for additional 7 secs and setComponentDidMount(false) for getting hotel data again
            setIsLoadingContent(setInterval(() => setComponentDidMount(false), 7000));
        }

        // update room data
        if(hotel && hotel.rooms && hotel.rooms.data && hotel.rooms.data.length > 0 && booking.roomOptions.length === 0) {
            setHotelData(prev => ({ ...prev, partner: { ...prev.partner, rooms: hotel.rooms } }));
        }

        // save room type options and room type data from hotel
        if(hotelData && hotelData.partner && hotelData.partner.rooms && hotelData.partner.rooms.data && hotelData.partner.rooms.data.length > 0 && booking.roomOptions.length === 0) {
            const { data, options } = hotelData.partner.rooms;

            // update room data
            setHotelData(prev => ({ ...prev, partner: { ...prev.partner, rooms: data } }));

            // get options
            setEnableAvailabilityCheckRequired(options.enableAvailabilityCheckRequired); // enable/disable availability check requests based on supplier API
            let roomOptions = options.rooms;
            let mealOptions = options.meals;
            // get room
            let selectedRoom = data[0];
            let selectedRoomRates = data[0].rates;
            // get room with available rates (if first does not)
            if(!selectedRoomRates || selectedRoomRates.length === 0) {
                for(let i = 0; i < data.length; i++) {
                    if(data[i].rates.length > 0) {
                        selectedRoom = data[i];
                        selectedRoomRates = data[i].rates;
                        break;
                    }
                }
            }

            if(selectedRoomRates.length > 0) {
                // filter selected rates based on meal
                if(options.meals.length > 0) {
                    let mealFilteredRates = selectedRoomRates.filter(rate => rate.meal[options.meals[0].type]);
                    if(mealFilteredRates.length > 0) selectedRoomRates = mealFilteredRates;
                }

                // set new selected rate
                let selectedRoomRate = selectedRoomRates[0];

                // set currency
                let code = selectedRoomRate.price.currencyCode;
                let symbol = getSymbolFromCurrency(code);

                // set max rooms available
                let roomsMax = booking.roomsMax;
                if(selectedRoomRate.roomsOnSale && selectedRoomRate.roomsOnSale <= 4) roomsMax = selectedRoomRate.roomsOnSale;
                else roomsMax = 4;

                // set selected cancellation option
                let selectedCancellationPolicy = selectedRoomRate.cancellationPolicy.type;
                // toggle cancellation options selector
                let hasCancellationPoliciesOptions = selectedRoomRates.length > 1 && selectedRoom.options && selectedRoom.options.cancellation && selectedRoom.options.cancellation.length > 1;

                // update booking options
                setBooking(prev => ({ ...prev, roomOptions, mealOptions, selectedRoom, selectedRoomRates, selectedRoomRate, currency: { code, symbol }, roomsMax, selectedCancellationPolicy, hasCancellationPoliciesOptions }));

            } else {
                // update booking options
                setBooking(prev => ({ ...prev, roomOptions, mealOptions, selectedRoom, selectedRoomRates }));
            }
        }

        // update selected room rate with Check Rate data
        if(!hotelRoomCheckRateInit && booking.selectedRoomRate && booking.selectedRoomRate.id && hotel && (hotel.checkRate || hotel.checkRateError)) {

            // initialize check rate
            setHotelRoomCheckRateInit(true);

            if(hotel.checkRateError) {
                // force user to get new hotel availability data
                setAvailabilityCheckRequired(true);

            } else if(hotel.checkRate) {
                if(hotel.checkRate.isValid) {
                    // load booking checkout configs
                    dispatch(hotelBookingActions.checkoutConfig(hotelBookingCheckoutConfigData({
                        ...booking,
                        checkRateCreatedAt: hotel.checkRate.createdAt,
                        userId: cookie.id,
                        guestUserId: cookie.guestUserId,
                        checkInFrom: hotelData.partner.checkInFrom,
                        checkOutUntil: hotelData.partner.checkOutTo,
                        partnerId: hotelData.partner.id,
                        creatorId: hotelData.creator.id,
                    }), cookie.guestUserId));

                } else {
                    // update selected room rate data with latest check rate data
                    let { selectedRoom, selectedRoomRates, selectedRoomRate } = booking;
                    selectedRoom.rates = selectedRoom.rates.map(rate => rate.id === hotel.checkRate.id ? hotel.checkRate : rate);
                    selectedRoomRate = hotel.checkRate;
                    selectedRoomRates = selectedRoomRates.map(rate => rate.id === hotel.checkRate.id ? hotel.checkRate : rate);
                    setBooking(prev => ({ ...prev, selectedRoom, selectedRoomRates, selectedRoomRate }));

                    let { rooms } = hotelData.partner;
                    rooms = rooms.map(room => ({ ...room, rates: room.rates.map(rate => rate.id === hotel.checkRate.id ? hotel.checkRate : rate) }));
                    setHotelData(prev => ({ ...prev, partner: { ...prev.partner, rooms } }));

                    // set hotel room check rate data
                    const imageUrl = hotel.checkRate.photos ? hotel.checkRate.photos[0] : hotelData.partner.photos ? hotelData.partner.photos[0] : hotelData.google.photos ? hotelData.google.photos[0] : null;
                    setHotelRoomCheckRate({
                        ...hotel.checkRate,
                        roomNotes: selectedRoom.notes,
                        image: imageUrl,
                        start: booking.dates.from.label,
                        end: booking.dates.to.label,
                        hotelName: hotelData.partner.name,
                        roomName: selectedRoom.name,
                        rooms: booking.rooms,
                        adults: booking.adults,
                    });

                    // show check rate popup
                    setShowHotelRoomCheckRate(true);
                }
            }
        }

        // open checkout screen
        if(init && !initCheckoutScreen) {
            // hotel booking configs
            if(hotelBooking && !hotelBooking.loadingConfigs && hotelBooking.config) {
                if(hotelBooking.config.status === 'success') {
                    // validate checkout configs
                    const isTypeValid = hotelBooking.config.type === hotelData.type;
                    const isUserIdValid = (hotelBooking.config.userId === cookie.id) || (hotelBooking.config.guestUserId === cookie.guestUserId);

                    // go to checkout screen
                    if(isTypeValid && isUserIdValid) {
                        setInitCheckoutScreen(true);
                        history.push({
                            pathname: getHotelLink(username, slug) + '/checkout',
                            state: {
                                from: pathname,
                                data: {
                                    userId: cookie.id,
                                    guestUserId: cookie.guestUserId,
                                }
                            }
                        });
                    }
                }
            }
        }

    }, [init, loading, authenticated, publicHotel, hotel, hotelData, booking, initCheckoutScreen, hotelBooking, pathname, username, slug, cookie, hotelRoomCheckRateInit, dispatch, pageLoadCount, handleTopBarBackButton]);

    const handleOpenTerms = useCallback((option) => {
        let url = null;

        switch(option) {
            case 'TERMS': {
                url = sitemap.landing.z.policies.terms;
                break;
            }

            case 'TERMS_CUSTOMERS': {
                url = sitemap.landing.z.policies.termsForCustomers;
                break;
            }

            case 'PRIVACY': {
                url = sitemap.landing.z.policies.privacy;
                break;
            }

            default:
                break;
        }

        if(url) window.open(urlConstants.baseUrlClient + url);
    }, []);

    // hide/show sharing popup
    const handleShare = useCallback(() => {
        setSharing(prev => !prev);
    }, []);


    // open map view
    const handleOpenMap = useCallback(() => {
        setIsMapVisible(true);
    }, []);

    const handleDatePickerToggle = useCallback(() => {
        setDatePicker(prev => !prev);
    }, []);

    const handleDatePickerSelect = useCallback((dates) => {
        // update booking dates
        setBooking(prev => ({ ...prev, dates }));

        if(dates.from && dates.from.date && dates.to && dates.to.date) {
            // reset any previous room types
            dispatch(hotelActions.clear());
            // reset any previous hotel booking checkout configs
            dispatch(hotelBookingActions.resetCheckoutConfig());

            // reset booking options
            let mealOptions = [];
            let selectedMeal = 0;
            let roomOptions = [];
            let selectedRoom = {};
            let selectedRoomRate = {};
            setHotelData(prev => ({ ...prev, partner: { ...prev.partner, rooms: [] } })); // reset rooms
            setBooking(prev => ({ ...prev, mealOptions, selectedMeal, roomOptions, selectedRoom, selectedRoomRate, }));
            setOccupancyOptionsToggle(true);
            setEnableAvailabilityCheckRequired(true);
            setAvailabilityCheckRequired(true);
        }
    }, [dispatch]);

    const handleAvailabilitySearch = useCallback(() => {
        // exit if no availability check is required
        if(!isAvailabilityCheckEnabled) return;

        // reset any previous room types
        dispatch(hotelActions.clear());
        // reset any previous hotel booking checkout configs
        dispatch(hotelBookingActions.resetCheckoutConfig());

        // reset booking options
        let mealOptions = [];
        let selectedMeal = 0;
        let roomOptions = [];
        let selectedRoom = {};
        let selectedRoomRate = {};
        setHotelData(prev => ({ ...prev, partner: { ...prev.partner, rooms: [] } })); // reset rooms
        setBooking(prev => ({ ...prev, mealOptions, selectedMeal, roomOptions, selectedRoom, selectedRoomRate, }));

        // create availability request configs
        let hotelAvailabilityRequestConfigs = {
            start: booking.dates.from.date,
            end: booking.dates.to.date,
            nights: booking.dates.totalDays,
            // rooms: booking.rooms, // FEATURE: enable for multiroom booking feature and remove below line (rooms: 1,)
            rooms: 1,
            adults: booking.adults,
            children: booking.children,
        };
        // add children ages
        if(booking.children > 0) hotelAvailabilityRequestConfigs.childrenAges = booking.childrenAges;
        // add guest user id when guest checkout session
        if(cookie.guestUserId) hotelAvailabilityRequestConfigs.guestUserId = cookie.guestUserId;
        // request hotel rates for selected dates
        dispatch(hotelActions.getAvailability(hotelData.partner.id, new URLSearchParams(hotelAvailabilityRequestConfigs), cookie.guestUserId));

        setAvailabilityCheckRequired(false);
        setAvailabilityCheckButtonText('CHANGE SEARCH');
        setIsSearchExpired(false);
    }, [hotelData, booking, dispatch, isAvailabilityCheckEnabled, cookie]);

    const handleBookingOptions = useCallback((option, value, metadata) => {
        // reset any previous hotel booking checkout configs
        dispatch(hotelBookingActions.resetCheckoutConfig());
        
        // initialize updated state
        var newState = { ...booking };

        // flag for updating selected room rate
        let requestSelectedRateUpdate = false;

        switch (option) {

            case 'adults': {
                // calculate new occupancyTotal value
                let occupancyTotal = value + booking.children;
                // calculate new adult max value
                let adultsMax = (booking.occupancyTotalMax - occupancyTotal) + value;
                // calculate new children max value
                let childrenMax = (booking.occupancyTotalMax - occupancyTotal) + booking.children;
                // set new values to updated state
                newState.adults = value;
                newState.occupancyTotal = occupancyTotal;
                newState.adultsMax = adultsMax;
                newState.childrenMax = childrenMax;
                // request selected rate update
                requestSelectedRateUpdate = true;
                setAvailabilityCheckRequired(true);
                setAvailabilityCheckButtonText(prev => prev !== 'SEARCH' ? 'APPLY CHANGES' : prev);
                break;
            }

            case 'children': {
                // calculate new occupancyTotal value
                let occupancyTotal = booking.adults + value;
                // calculate new adult max value
                let adultsMax = (booking.occupancyTotalMax - occupancyTotal) + booking.adults;
                // calculate new children max value
                let childrenMax = (booking.occupancyTotalMax - occupancyTotal) + value;
                // check if is increasing/decreasing number of children
                const isIncrease = value > newState.children;
                const isDecreasing = value < newState.children;
                // set new values to updated state
                newState.children = value;
                newState.occupancyTotal = occupancyTotal;
                newState.adultsMax = adultsMax;
                newState.childrenMax = childrenMax;
                // request selected rate update
                requestSelectedRateUpdate = true;
                setAvailabilityCheckRequired(true);
                setAvailabilityCheckButtonText(prev => prev !== 'SEARCH' ? 'APPLY CHANGES' : prev);
                if(isIncrease) {
                    newState.childrenAges.push(10); // add new default children age
                    setShowChildrenAges(true); // open children ages edit popup
                } else if(isDecreasing) {
                    if(metadata && metadata.children && metadata.children.hasOwnProperty('index')) {
                        newState.childrenAges.splice(metadata.children.index, 1); // remove specific child age
                    } else {
                        newState.childrenAges.pop(); // remove last added child age
                    }
                    
                    // close if last child age or open for editing
                    if(value > 0) setShowChildrenAges(true);
                    else setShowChildrenAges(false);
                }
                break;
            }

            case 'selectedMeal': {
                // update selected meal
                newState.selectedMeal = value;
                // request selected rate update
                requestSelectedRateUpdate = true;
                break;
            }

            case 'single-bed-option': {
                // set new selected bed type option
                newState.doubleBedType = false;
                break;
            }

            case 'double-bed-option': {
                // set new selected bed type option
                newState.doubleBedType = true;
                break;
            }

            case 'room-selector': {
                const { rooms } = hotelData.partner;
                // set new selected room
                const selectedRoom = rooms.filter(r => r.id === value)[0];
                newState.selectedRoom = selectedRoom;
                // request selected rate update
                requestSelectedRateUpdate = true;
                break;
            }

            case 'occupancy-selector': {
                // set new selected occupancy
                newState.selectedOccupancy = value;
                // request selected rate update
                requestSelectedRateUpdate = true;
                break;
            }

            case 'cancellation-policy-selector': {
                // set new selected cancellation policy
                newState.selectedCancellationPolicy = value;
                // request selected rate update
                requestSelectedRateUpdate = true;
                break;
            }

            case 'rooms': {
                newState[option] = value;
                setAvailabilityCheckRequired(true);
                setAvailabilityCheckButtonText(prev => prev !== 'SEARCH' ? 'APPLY CHANGES' : prev);
                break;
            }

            default: {
                newState[option] = value;
                break;
            }
        }


        if(requestSelectedRateUpdate) {
            const { selectedRoom, mealOptions, selectedMeal } = newState;

            if(selectedRoom && selectedRoom.rates && selectedRoom.rates.length > 0) {

                // set new selected room rates
                newState.selectedRoomRates = selectedRoom.rates;

                
                // filter rooms based on selected meal
                if(mealOptions.length > 0) {
                    let mealFilteredRates = newState.selectedRoomRates.filter(rate => rate.meal[mealOptions[selectedMeal].type]);
                    if(mealFilteredRates.length > 0) newState.selectedRoomRates = mealFilteredRates;
                }

                // filter rooms based on selected occupancy
                if(selectedRoom.options.occupancy.length > 1) {
                    let occupancyFilteredRates = newState.selectedRoomRates.filter(rate => rate.maxOccupancy === newState.selectedOccupancy);
                    if(occupancyFilteredRates.length > 0) newState.selectedRoomRates = occupancyFilteredRates;
                }

                // toggle cancellation options selector
                newState.hasCancellationPoliciesOptions = newState.selectedRoomRates.length > 1 && selectedRoom.options.cancellation.length > 1;

                // filter rooms based on selected cancellation policy
                if(selectedRoom.options.cancellation.length > 0) {
                    let policyFilteredRates = newState.selectedRoomRates.filter(rate => rate.cancellationPolicy.type === newState.selectedCancellationPolicy);
                    if(policyFilteredRates.length > 0) newState.selectedRoomRates = policyFilteredRates;
                }

                // set new selected rate
                newState.selectedRoomRate = newState.selectedRoomRates[0];
                
                // set max rooms available for sale
                if(newState.selectedRoomRate.roomsOnSale && newState.selectedRoomRate.roomsOnSale <= 4) newState.roomsMax = newState.selectedRoomRate.roomsOnSale;
                else newState.roomsMax = 4;

                // decrease rooms if previously selected rooms are over the new selected rate's available rooms
                if(newState.rooms > newState.roomsMax) newState.rooms = newState.roomsMax;
            }
        }


        // save values to state
        setBooking(newState);

    }, [booking, hotelData, dispatch]);

    // handle children age changes
    const handleChildrenAgeChange = useCallback((index, value) => {
        var newState = { ...booking };
        newState.childrenAges[index] = value;
        setBooking(newState);
    }, [booking]);

    // handle add new children age - default age 10
    const handleAddChildrenAge = useCallback(() => {
        handleBookingOptions('children', booking.children + 1);
    }, [handleBookingOptions, booking]);

    // handle delete specific children age
    const handleDeleteChildrenAge = useCallback((index) => {
        if(index < 0) return;
        handleBookingOptions('children', booking.children - 1, { children: { index } });
    }, [handleBookingOptions, booking]);

    // load booking checkout configs (flow continues in componentDidUpdate)
    const handleReserveBooking = useCallback(() => {
        // skip if availability check is required
        if(isAvailabilityCheckEnabled) return;

        // reset any previous hotel check room rate data
        dispatch(hotelActions.clearCheckRoomRate());
        setHotelRoomCheckRate(null);
        setHotelRoomCheckRateInit(false);

        // reset any previous hotel booking checkout configs
        dispatch(hotelBookingActions.resetCheckoutConfig());

        // format selected hotel reservation data
        const hotelReservationData = hotelBookingCheckoutConfigData({
            ...booking,
            userId: cookie.id,
            guestUserId: cookie.guestUserId,
            checkInFrom: hotelData.partner.checkInFrom,
            checkOutUntil: hotelData.partner.checkOutTo,
            partnerId: hotelData.partner.id,
            creatorId: hotelData.creator.id,
        });

        if(booking.selectedRoomRate.reCheck) dispatch(hotelActions.checkRoomRate(hotelData.partner.id, hotelReservationData, cookie.guestUserId)); // request a hotel room rate check
        else dispatch(hotelBookingActions.checkoutConfig(hotelReservationData, cookie.guestUserId)); // load booking checkout configs (flow continues in componentDidUpdate)

    }, [booking, cookie, hotelData, dispatch, isAvailabilityCheckEnabled]);

    return (
        <ScreenContainer id='screen' >
            { init && <MetaTags title={hotelData.partner.name} /> }

            {init &&
            <HotelPage
                sticky={sticky}
                authenticated={authenticated}
                isOpenInNewTab={isOpenInNewTab}
                hotel={hotelData}
                isSearchExpired={isSearchExpired}
                handleSearchExpired={() => setIsSearchExpired(true)}
                booking={booking}
                hotelBooking={hotelBooking}
                genericError={hotel.checkRateError || hotel.error || error}
                hotelDataLoading={loading || (hotel && hotel.loading)}
                occupancyOptionsToggle={occupancyOptionsToggle}
                availabilityCheckRequired={isAvailabilityCheckEnabled}
                availabilityCheckButtonText={availabilityCheckButtonText}
                handleBackButton={handleTopBarBackButton}
                handleOpenTerms={handleOpenTerms}
                handleShare={handleShare}
                handleOpenMap={handleOpenMap}
                handleDatePickerToggle={handleDatePickerToggle}
                handleBookingOptions={handleBookingOptions}
                handleAvailabilitySearch={handleAvailabilitySearch}
                handleShowChildrenAges={setShowChildrenAges}
                handleReserveBooking={handleReserveBooking} /> }

            { (loading || isLoadingContent || hotelBooking.loadingConfigs || hotel.loadingCheckRate) && <FullScreenSpinner /> }

            { init && username && slug &&
            <Share
                show={sharing}
                onHide={handleShare}
                title='Share'
                url={urlConstants.baseUrlClient + getHotelLink(username, slug)}
                imageUrl={hotelData.partner.photos[0]} /> }

            <DatePicker
                show={datePicker}
                dates={booking.dates}
                onHide={handleDatePickerToggle}
                showButton={true}
                onDatePick={handleDatePickerSelect} />

            <ChildrenAgesPopup
                show={showChildrenAges}
                total={booking.children}
                max={booking.childrenMax}
                ages={booking.childrenAges}
                onHide={() => setShowChildrenAges(false)}
                handleAddChildrenAge={handleAddChildrenAge}
                handleDeleteChildrenAge={handleDeleteChildrenAge}
                onChange={handleChildrenAgeChange} />

            <HotelRoomCheckRatePopup
                show={showHotelRoomCheckRate}
                onHide={() => setShowHotelRoomCheckRate(false)}
                data={hotelRoomCheckRate}
                handleAcceptChanges={handleReserveBooking}
            />

            { init && hotelData.google &&
            <Map
                show={isMapVisible}
                onHide={() => setIsMapVisible(false)}
                data={{ place: hotelData.google }}
            /> }
        </ScreenContainer>
    );
};