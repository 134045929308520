import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { Button } from '../Buttons';
import { colorConstants, deviceConstants } from '../../../constants';


export const Container = styled(Modal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        background: none;
    }
`;

export const TopSection = styled.div`

`;

export const Header = styled(Modal.Header)`
    float: right;
    border-bottom: none;
`;

export const Image = styled.div`
    width: 100%;
    height: 300px;
    border-radius: 8px 8px 0 0;

    background-image: url("${props => props.url}");
    background-size: cover;
    // background-size: contain;
    // background-repeat: no-repeat;
`;

export const Body = styled.div`
    padding: 32px;
    border-radius: 0 0 8px 8px;
    background: ${colorConstants.white};

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled(Modal.Title)`
    margin-bottom: 24px;

    font-weight: 600;
    font-size: 18px;
    color: ${colorConstants.black};
`;

export const BodyText = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: ${colorConstants.secondaryDark};
`;

export const StyledButton = styled(Button)`
    width: 100%;

    margin-top: 40px;
    margin-bottom: 16px;

    @media ${deviceConstants.tablet} {
        width: 75%;
    }
`;