import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
    Container,
    PhotoSection,
    TopControls, TopControlFader, TopControlsColumn,
    BackButton, DeleteButton, EditButton, ShareButton, FavorButton, UnFavorButton,
    HeaderSection, Name,
    CaptionSection, CreatorProfileSection, CreatorName, Caption,
    ButtonSection, ButtonLink, StyledButton,
    PartnerSection, SectionSubTitle, PartnerDescriptionSection
} from './ProductPage.styles';
import { CarouselPhoto } from '../../custom/Images';
import { Avatar } from '../../custom/Avatar';


export const ProductPage = ({
    authenticated,
    isCreator,
    product,
    isFav,
    handleBackButton,
    handleDelete,
    handleEdit,
    handleShare,
    handleFavor,
    handleUnFavor,
    handleViewCreatorProfile
}) => {

    const hasPartner = product.partner && product.partner.id;

    const hasCustomLink = !hasPartner && product.button && product.button.label && product.button.url;

    const hasGoogleSearchLink = !hasPartner && !hasCustomLink && product.name;

    return (
        <Container>
            <PhotoSection>
                <CarouselPhoto isFirebase={true} height={'325px'} controls={false} src={product.imageUrls} name={product.customUrl} />
                <TopControls>
                    <TopControlsColumn align='flex-start' >
                        { authenticated && <BackButton onClick={handleBackButton} size={26} /> }
                    </TopControlsColumn>
                    <TopControlsColumn align='flex-end' >
                        { authenticated && isCreator && <DeleteButton onClick={handleDelete} /> }
                        { authenticated && isCreator && <EditButton onClick={handleEdit} /> }
                        <ShareButton onClick={handleShare} />
                        {!isFav && !isCreator && <FavorButton onClick={handleFavor} />}
                        {isFav && !isCreator && <UnFavorButton onClick={handleUnFavor} />}
                    </TopControlsColumn>
                    <TopControlFader />
                </TopControls>
            </PhotoSection>

            <HeaderSection>
                <Name>{product.name}</Name>
            </HeaderSection>

            <CaptionSection>
                <CreatorProfileSection onClick={() => handleViewCreatorProfile(product.creator.username)} >
                    <Avatar imageSize={30} url={product.creator.profilePhotoUrl} cursor='pointer' />
                    <CreatorName>{product.creator.displayName || product.creator.username}</CreatorName>
                </CreatorProfileSection>

                <Caption>{ReactHtmlParser(product.description)}</Caption>
            </CaptionSection>

            { hasCustomLink &&
            <ButtonSection>
                <ButtonLink href={product.button.url} target="blank">
                    <StyledButton gradient={true} >{product.button.label}</StyledButton>
                </ButtonLink>
            </ButtonSection> }

            { hasGoogleSearchLink &&
            <ButtonSection>
                <ButtonLink href={'https://www.google.com/search?q=' + product.name} target="blank">
                    <StyledButton gradient={true} >Find out more</StyledButton>
                </ButtonLink>
            </ButtonSection> }

            {hasPartner &&
            <PartnerSection>
                <PartnerDescriptionSection>
                    <SectionSubTitle>About</SectionSubTitle>
                </PartnerDescriptionSection>
            </PartnerSection> }
        </Container>
    );
};