import styled from 'styled-components';
import Icon from '../../../assets/icons/Icon';


const Container = styled.div`
    line-height: 2;

    display: flex;
    justify-content: ${props => props.alignIcons ? props.alignIcons : 'none'};
    flex-wrap: wrap;


    ${({ alignIcons }) => (!alignIcons || (alignIcons === 'center')) && `
        & > * {
            margin-right: 6px;
            margin-left: 6px;
        }
    `}

    ${({ alignIcons }) => alignIcons === 'flex-start' && `
        & > * {
            margin-right: 12px;
        }

        & > *:last-child {
            margin-right: 0px;
        }
    `}
`;

const Link = styled.a`
    text-decoration: none;
`;


export const SocialMediaSection = (props) => {
    const { social, website, iconSize, circleIcons, colorIcons, customColor, ...other } = props;

    const links = Object.entries(social).filter(entry => entry[1] && entry[1].length > 0).map((entry, index) => {
        // get social media platform name
        const platform = entry[0].replace('ProfileUrl', '');
        // return icon
        return (
            <Link key={platform} href={entry[1]} target='_blank' rel='noreferrer'>
                <Icon type='social' platform={platform} size={iconSize} circle={circleIcons} color={colorIcons} customcolor={customColor} />
            </Link>
        );
    });

    return (
        <Container {...other} >
            {links}

            {website &&
            <Link href={website} target='_blank' rel='noreferrer'>
                <Icon type='website' size={iconSize} circle={circleIcons} color={colorIcons} customcolor={customColor} />
            </Link>}
        </Container>
    );
};