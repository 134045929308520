import { http, authHeader, handleResponse, handleError } from "../utils";

export const productService = {
    create,
    getAll,
    getById,
    getBySlug,
    update,
    favourite,
    delete: _delete
};

async function create(data) {
    return await http.post("/products", data, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function getAll(query) {
    return await http.get(`/products?${query.toString()}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function getById(id, query) {
    // construct api call url
    let url = `/products/${id}`;
    if(query) url = url + `?${query.toString()}`
    // call api
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function getBySlug(slug, query) {
    // construct api call url
    let url = `/products/getBySlug/${slug}`;
    if(query) url = url + `?${query.toString()}`
    // call api
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function update(data) {
    return await http.put(`/products/${data.id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function favourite(data) {
    return await http.put(`/products/favourite/${data.id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}

async function _delete(id) {
    return await http.delete(`/products/${id}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, true))
                    .catch((err) => handleError(err, true));
}