import { hotelBookingReducerConstants } from "../constants";
import { hotelBookingService } from "../services";


export const hotelBookingActions = {
    checkoutConfig,
    checkoutConfigWithCoupon,
    paymentConfig,
    getAll,
    getById,
    sendBookingConfirmationEmail,
    cancel,
    resetCheckoutConfig,
    clear
};


function checkoutConfig(data, isGuestUser) {
    return async dispatch => {
        dispatch(request());

        await hotelBookingService.checkoutConfig(data, isGuestUser)
            .then(
                config => dispatch(success(config)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: hotelBookingReducerConstants.CHECKOUT_CONFIG_REQUEST } }
    function success(config) { return { type: hotelBookingReducerConstants.CHECKOUT_CONFIG_SUCCESS, config } }
    function failure(error) { return { type: hotelBookingReducerConstants.CHECKOUT_CONFIG_FAILURE, error } }
}

function checkoutConfigWithCoupon(data) {
    return async dispatch => {
        dispatch(request());

        await hotelBookingService.checkoutConfigWithCoupon(data)
            .then(
                coupon => dispatch(success(coupon)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: hotelBookingReducerConstants.CHECKOUT_CONFIG_WITH_COUPON_REQUEST } }
    function success(coupon) { return { type: hotelBookingReducerConstants.CHECKOUT_CONFIG_WITH_COUPON_SUCCESS, coupon } }
    function failure(error) { return { type: hotelBookingReducerConstants.CHECKOUT_CONFIG_WITH_COUPON_FAILURE, error } }
}

function paymentConfig(data, isGuestUser) {
    return async dispatch => {
        dispatch(request());

        await hotelBookingService.paymentConfig(data, isGuestUser)
            .then(
                config => dispatch(success(config)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: hotelBookingReducerConstants.PAYMENT_CONFIG_REQUEST } }
    function success(config) { return { type: hotelBookingReducerConstants.PAYMENT_CONFIG_SUCCESS, config } }
    function failure(error) { return { type: hotelBookingReducerConstants.PAYMENT_CONFIG_FAILURE, error } }
}

function getAll(query, isGuestUser) {
    return dispatch => {
        dispatch(request());

        hotelBookingService.getAll(query, isGuestUser)
            .then(
                bookings => dispatch(success(bookings)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: hotelBookingReducerConstants.GETALL_REQUEST } }
    function success(bookings) { return { type: hotelBookingReducerConstants.GETALL_SUCCESS, bookings } }
    function failure(error) { return { type: hotelBookingReducerConstants.GETALL_FAILURE, error } }
}

function getById(id, query, isGuestUser) {
    return dispatch => {
        dispatch(request({ id }));

        hotelBookingService.getById(id, query, isGuestUser)
            .then(
                booking => dispatch(success(booking)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(booking) { return { type: hotelBookingReducerConstants.GETBYID_REQUEST, booking } }
    function success(booking) { return { type: hotelBookingReducerConstants.GETBYID_SUCCESS, booking } }
    function failure(error) { return { type: hotelBookingReducerConstants.GETBYID_FAILURE, error } }
}

function sendBookingConfirmationEmail(id, query, isGuestUser) {
    return dispatch => {
        dispatch(request());

        hotelBookingService.sendBookingConfirmationEmail(id, query, isGuestUser)
            .then(
                status => dispatch(success()),
                error => dispatch(failure())
            );
    };

    function request() { return { type: hotelBookingReducerConstants.SENDCONFIRMATION_REQUEST } }
    function success() { return { type: hotelBookingReducerConstants.SENDCONFIRMATION_SUCCESS } }
    function failure() { return { type: hotelBookingReducerConstants.SENDCONFIRMATION_FAILURE } }
}

function cancel(id, data, isGuestUser) {
    return dispatch => {
        dispatch(request());

        hotelBookingService.cancel(id, data, isGuestUser)
            .then(
                status => dispatch(success()),
                error => dispatch(failure())
            );
    };

    function request() { return { type: hotelBookingReducerConstants.CANCEL_REQUEST } }
    function success() { return { type: hotelBookingReducerConstants.CANCEL_SUCCESS } }
    function failure() { return { type: hotelBookingReducerConstants.CANCEL_FAILURE } }
}

function resetCheckoutConfig() {
    return { type: hotelBookingReducerConstants.RESET_CHECKOUT_CONFIG };
}

function clear() {
    return { type: hotelBookingReducerConstants.CLEAR_ALL };
}