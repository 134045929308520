import { paymentMethodReducerConstants } from "../constants";
import { paymentMethodService } from "../services";
import { alertActions } from './';


export const paymentMethodActions = {
    getAll,
    update,
    clear,
};


function getAll(customerId, query) {
    return dispatch => {
        dispatch(request());

        paymentMethodService.getAll(customerId, query)
            .then(
                paymentMethods => dispatch(success(paymentMethods)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: paymentMethodReducerConstants.GETALL_REQUEST } }
    function success(paymentMethods) { return { type: paymentMethodReducerConstants.GETALL_SUCCESS, paymentMethods } }
    function failure(error) { return { type: paymentMethodReducerConstants.GETALL_FAILURE, error } }
}

function update(id, data) {
    return async dispatch => {
        dispatch(request());

        await paymentMethodService.update(id, data)
                                .then(
                                    paymentMethod => { 
                                        dispatch(success(paymentMethod));
                                        dispatch(alertActions.success('Update successful'));
                                    },
                                    error => {
                                        dispatch(failure(error.toString()));
                                        dispatch(alertActions.error(error.toString()));
                                    }
                                );
    };

    function request() { return { type: paymentMethodReducerConstants.UPDATE_REQUEST } }
    function success(paymentMethod) { return { type: paymentMethodReducerConstants.UPDATE_SUCCESS, paymentMethod } }
    function failure(error) { return { type: paymentMethodReducerConstants.UPDATE_FAILURE, error } }
}

function clear() {
    return { type: paymentMethodReducerConstants.CLEAR_ALL };
}