import styled from 'styled-components';
import { Logo } from "../../custom/Branding";

import { colorConstants, deviceConstants } from "../../../constants";


const Container = styled.div`
    padding-bottom: 48px;

    @media ${deviceConstants.tablet} {
        padding-bottom: 32px;
    }
`;

const StyledLogo = styled(Logo)`
    min-width: 80px;  
`;

const Content = styled.div`
    padding: 16px;
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    color: ${colorConstants.mediumGrey};
`;

const PrivacyContent = styled.div`
    margin-top: 8px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Link = styled.div`
    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;



export const TripFooter = ({ handleOpenTerms }) => {
    return (
        <Container>
            <StyledLogo color={colorConstants.mediumGrey} />
            <Content>
                Powered by Google, our wonderful Travel <br />Experience suppliers & awesome creator community!
                <PrivacyContent>
                    <Link onClick={() => handleOpenTerms('PRIVACY')}>Privacy Policy</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link onClick={() => handleOpenTerms('TERMS')}>Terms of Service</Link>
                </PrivacyContent>
            </Content>
        </Container>
    );
};