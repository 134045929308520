// app feature options constants
export const featureOptions = {
    link: {
        types: [
            'SIMPLE',
            'BLOG'
        ],
    },
    trip: {
        types: [
            'ACCOMMODATION',
            'EXPERIENCE'
        ],
        googlePlacesSearchType: [
            'lodging',
            'travel_agency'
        ]
    },
    product: {
        types: [
            'PHYSICAL',
            'DIGITAL'
        ]
    }
};


/**
 * Method to get link type arrays for enabled features
 * 
 * @param {JSON Object} options User's link metadata
 * @returns JSON Object with link type arrays
 */
export function getEnalbedFeatureTypes(options) {
    let linkTypes = [];
    let tripTypes = [];
    let productTypes = [];
    let nftTypes = [];

    if(options.simple.enabled) linkTypes.push('SIMPLE');
    if(options.github.enabled) linkTypes.push('GITHUB');
    if(options.blog.enabled) linkTypes.push('BLOG');
    if(options.accommodation.enabled) tripTypes.push('ACCOMMODATION');
    if(options.experience.enabled) tripTypes.push('EXPERIENCE');
    if(options.physicalProduct.enabled) productTypes.push('PHYSICAL');
    if(options.digitalProduct.enabled) productTypes.push('DIGITAL');
    if(options.nft.enabled) nftTypes.push('NFT');

    return {
        link: {
            types: linkTypes
        },
        trip: {
            types: tripTypes
        },
        product: {
            types: productTypes
        },
        nft: {
            types: nftTypes
        },
    };
}


/**
 * Method to get user account type or user account type label
 * 
 * @param {JSON OBJECT} user The user object
 * @param {BOOLEAN} isLabel Set to true for labels and false for type
 * @returns Returns string with the user account type (or label)
 */
export function getUserAccountType(user, isLabel){
    let type = user.platinum ? 'platinum' : (user.premium ? 'premium' : (user.basic ? 'basic' : (user.creator ? 'creator' : 'standard')));
    if(type && isLabel) return type.charAt(0).toUpperCase() + type.slice(1);
    return type;
}


// Page Themes
export const pageThemes = {
    none: {
        // user access
        access: 'FREE',
        pageTheme: '',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '',
        // background
        pageBackgroundColor: '',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: '',
        pageButtonStyle: '',
        pageButtonColor: '',
        pageButtonFontColor: '',
        // fonts
        pageFontType: '',
        pageFontColor: '',
        // social icons
        pageSocialIconColor: '',
    },
    kyiv: {
        // user access
        access: 'FREE',
        pageTheme: 'KYIV',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#FFD500',
        // background
        pageBackgroundColor: '#005BBB',
        pageBackgroundGradient: 'linear-gradient(166.85deg, #005BBB 0%, #FFD500 100%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'FILL',
        pageButtonColor: '#005BBB',
        pageButtonFontColor: '#fefefe',
        // fonts
        pageFontType: '',
        pageFontColor: '#fefefe',
        // social icons
        pageSocialIconColor: '#fefefe',
    },
    kyiv_blue: {
        // user access
        access: 'FREE',
        pageTheme: 'KYIV_BLUE',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#B9D9EB',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'OVAL',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#B9D9EB',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    kyiv_red: {
        // user access
        access: 'FREE',
        pageTheme: 'KYIV_RED',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#F08080',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'OVAL',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#F08080',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    kyiv_green: {
        // user access
        access: 'FREE',
        pageTheme: 'KYIV_GREEN',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#D1E8E2',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'OVAL',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#D1E8E2',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    kyiv_orange: {
        // user access
        access: 'FREE',
        pageTheme: 'KYIV_ORANGE',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#fbceb1',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'OVAL',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#fbceb1',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    kyiv_purple: {
        // user access
        access: 'FREE',
        pageTheme: 'KYIV_PURPLE',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#AA96DA',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'OVAL',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#AA96DA',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    wizard_white: {
        // user access
        access: 'FREE',
        pageTheme: 'WIZARD_WHITE',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#FEFEFE',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'FILL',
        pageButtonColor: '#EBEBEB',
        pageButtonFontColor: '#181818',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    wizard_grey: {
        // user access
        access: 'FREE',
        pageTheme: 'WIZARD_GREY',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#EBEBEB',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'FILL',
        pageButtonColor: '#FEFEFE',
        pageButtonFontColor: '#181818',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    wizard_dark: {
        // user access
        access: 'FREE',
        pageTheme: 'WIZARD_DARK',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#FEFEFE',
        // background
        pageBackgroundColor: '#181818',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'FILL',
        pageButtonColor: '#242424',
        pageButtonFontColor: '#FEFEFE',
        // fonts
        pageFontType: '',
        pageFontColor: '#FEFEFE',
        // social icons
        pageSocialIconColor: '#FEFEFE',
    },
    teal: {
        // user access
        access: 'FREE',
        pageTheme: 'TEAL',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#FFFFFF',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'NONE',
        pageButtonStyle: 'FILL',
        pageButtonColor: '#318CE7',
        pageButtonFontColor: '#FEFEFE',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    ruby: {
        // user access
        access: 'FREE',
        pageTheme: 'RUBY',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#FFFFFF',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'NONE',
        pageButtonStyle: 'FILL',
        pageButtonColor: '#E0115F',
        pageButtonFontColor: '#FEFEFE',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    jade: {
        // user access
        access: 'FREE',
        pageTheme: 'JADE',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#FFFFFF',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'NONE',
        pageButtonStyle: 'FILL',
        pageButtonColor: '#00A36C',
        pageButtonFontColor: '#FEFEFE',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    canela: {
        // user access
        access: 'FREE',
        pageTheme: 'CANELA',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#FEFEFE',
        // background
        pageBackgroundColor: '#C58C66',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'NONE',
        pageButtonStyle: 'FILL',
        pageButtonColor: '#FEFEFE',
        pageButtonFontColor: '#C58C66',
        // fonts
        pageFontType: '',
        pageFontColor: '#FEFEFE',
        // social icons
        pageSocialIconColor: '#FEFEFE',
    },
    indigo: {
        // user access
        access: 'FREE',
        pageTheme: 'INDIGO',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#FEFEFE',
        // background
        pageBackgroundColor: '#4B0082',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'NONE',
        pageButtonStyle: 'FILL',
        pageButtonColor: '#E6E6FA',
        pageButtonFontColor: '#4B0082',
        // fonts
        pageFontType: '',
        pageFontColor: '#FEFEFE',
        // social icons
        pageSocialIconColor: '#FEFEFE',
    },
    active: {
        // user access
        access: 'BASIC',
        pageTheme: 'ACTIVE',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#FFFB7D',
        pageBackgroundGradient: 'linear-gradient(225deg, #FFFB7D 0%, #85FFBD 100%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#85FFBD',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    oceanic: {
        // user access
        access: 'BASIC',
        pageTheme: 'OCEANIC',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#fefefe',
        // background
        pageBackgroundColor: '#0093E9',
        pageBackgroundGradient: 'linear-gradient(166.85deg, #0093E9 47.12%, #80D0C7 99.15%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#fefefe',
        pageButtonFontColor: '#0093E9',
        // fonts
        pageFontType: '',
        pageFontColor: '#fefefe',
        // social icons
        pageSocialIconColor: '#fefefe',
    },
    pitchie: {
        // user access
        access: 'FREE',
        pageTheme: 'PITCHIE',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#FEE140',
        pageBackgroundGradient: 'linear-gradient(162.51deg, #FEE140 -1.31%, #FA709A 104.91%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'OVAL',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#FEE140',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    charcoal: {
        // user access
        access: 'FREE',
        pageTheme: 'CHARCOAL',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#fefefe',
        // background
        pageBackgroundColor: '#252b30',
        pageBackgroundGradient: 'linear-gradient(45deg, #252b30 0%, #191c22 100%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#fefefe',
        pageButtonFontColor: '#191c22',
        // fonts
        pageFontType: '',
        pageFontColor: '#fefefe',
        // social icons
        pageSocialIconColor: '#fefefe',
    },
    hacker: {
        // user access
        access: 'FREE',
        pageTheme: 'HACKER',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#8be81b',
        // background
        pageBackgroundColor: '#171414',
        pageBackgroundGradient: '',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#8be81b',
        pageButtonFontColor: '#171414',
        // fonts
        pageFontType: 'Smooch Sans',
        pageFontColor: '#8be81b',
        // social icons
        pageSocialIconColor: '#8be81b',
    },
    moments: {
        // user access
        access: 'FREE',
        pageTheme: 'MOMENTS',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#F76B1C',
        pageBackgroundGradient: 'linear-gradient(197.03deg, #F76B1C -6.89%, #FAD961 110.19%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'OVAL',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#F76B1C',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    pinky: {
        // user access
        access: 'FREE',
        pageTheme: 'PINKY',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#DDD6F3',
        pageBackgroundGradient: 'linear-gradient(195.04deg, #DDD6F3 -0.87%, #FAACA8 103.86%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#FAACA8',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    emerald: {
        // user access
        access: 'FREE',
        pageTheme: 'EMERALD',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#74EBD5',
        pageBackgroundGradient: 'linear-gradient(155.16deg, #74EBD5 -7.18%, #9FACE6 102.85%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#74EBD5',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    almost: {
        // user access
        access: 'FREE',
        pageTheme: 'ALMOST',
        pageThemeDark: false,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#A9C9FF',
        pageBackgroundGradient: 'linear-gradient(187.76deg, #A9C9FF 2.44%, #FFBBEC 96.05%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'OVAL',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#A9C9FF',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    lush: {
        // user access
        access: 'BASIC',
        pageTheme: 'LUSH',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#F4D03F',
        pageBackgroundGradient: 'linear-gradient(160.73deg, #F4D03F -2.83%, #16A085 113.87%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#F4D03F',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    tranquil: {
        // user access
        access: 'BASIC',
        pageTheme: 'TRANQUIL',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#FF5ACD',
        pageBackgroundGradient: 'linear-gradient(202.04deg, #FF5ACD 11.82%, #FBDA61 99.61%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#FF5ACD',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    celestial: {
        // user access
        access: 'BASIC',
        pageTheme: 'CELESTIAL',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#fefefe',
        // background
        pageBackgroundColor: '#B721FF',
        pageBackgroundGradient: 'linear-gradient(197.71deg, #B721FF -0.71%, #21D4FD 104.76%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#fefefe',
        pageButtonFontColor: '#B721FF',
        // fonts
        pageFontType: '',
        pageFontColor: '#fefefe',
        // social icons
        pageSocialIconColor: '#fefefe',
    },
    adventurous: {
        // user access
        access: 'BASIC',
        pageTheme: 'ADVENTUROUS',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#FFE53B',
        pageBackgroundGradient: 'linear-gradient(168.49deg, #FFE53B -4.59%, #FF2525 113.1%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'OVAL',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#FFE53B',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    kashmiry: {
        // user access
        access: 'BASIC',
        pageTheme: 'KASHMIRY',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#8BC6EC',
        pageBackgroundGradient: 'linear-gradient(143.74deg, #8BC6EC 5.8%, #9599E2 73.01%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'OVAL',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#8BC6EC',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    bourbon: {
        // user access
        access: 'BASIC',
        pageTheme: 'BOURBON',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#F7CE68',
        pageBackgroundGradient: 'linear-gradient(224.32deg, #F7CE68 14.18%, #FBAB7E 94.46%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#F7CE68',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    decent: {
        // user access
        access: 'BASIC',
        pageTheme: 'DECENT',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#97D9E1',
        pageBackgroundGradient: 'linear-gradient(180deg, #97D9E1 0%, #D9AFD9 99.2%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#97D9E1',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    slate: {
        // user access
        access: 'BASIC',
        pageTheme: 'SLATE',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#fefefe',
        // background
        pageBackgroundColor: '#2C3E50',
        pageBackgroundGradient: 'linear-gradient(186.53deg, #2C3E50 1.71%, #BDC3C7 61.06%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#fefefe',
        pageButtonFontColor: '#2C3E50',
        // fonts
        pageFontType: '',
        pageFontColor: '#fefefe',
        // social icons
        pageSocialIconColor: '#fefefe',
    },
    cherry: {
        // user access
        access: 'BASIC',
        pageTheme: 'CHERRY',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#fefefe',
        // background
        pageBackgroundColor: '#eb3349',
        pageBackgroundGradient: 'linear-gradient(150deg, #eb3349 0%, #f45c43 100%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'OVAL',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#fefefe',
        pageButtonFontColor: '#eb3349',
        // fonts
        pageFontType: '',
        pageFontColor: '#fefefe',
        // social icons
        pageSocialIconColor: '#fefefe',
    },
    frost: {
        // user access
        access: 'BASIC',
        pageTheme: 'FROST',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#fefefe',
        // background
        pageBackgroundColor: '#000428',
        pageBackgroundGradient: 'linear-gradient(150deg, #000428 0%, #004e92 100%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#fefefe',
        pageButtonFontColor: '#000428',
        // fonts
        pageFontType: '',
        pageFontColor: '#fefefe',
        // social icons
        pageSocialIconColor: '#fefefe',
    },
    dusk: {
        // user access
        access: 'BASIC',
        pageTheme: 'DUSK',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#181818',
        // background
        pageBackgroundColor: '#ffd89b',
        pageBackgroundGradient: 'linear-gradient(150deg, #ffd89b 0%, #19547b 100%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#181818',
        pageButtonFontColor: '#ffd89b',
        // fonts
        pageFontType: '',
        pageFontColor: '#181818',
        // social icons
        pageSocialIconColor: '#181818',
    },
    royal: {
        // user access
        access: 'BASIC',
        pageTheme: 'ROYAL',
        pageThemeDark: true,
        // photo
        pagePhotoBorderColor: '#fefefe',
        // background
        pageBackgroundColor: '#141e30',
        pageBackgroundGradient: 'linear-gradient(150deg, #141e30 0%, #243b55 100%)',
        pageBackgroundImage: '',
        pageBackgroundVideo: '',
        // buttons
        pageButtonShape: 'ROUNDED',
        pageButtonStyle: 'OUTLINE',
        pageButtonColor: '#fefefe',
        pageButtonFontColor: '#141e30',
        // fonts
        pageFontType: '',
        pageFontColor: '#fefefe',
        // social icons
        pageSocialIconColor: '#fefefe',
    },
};