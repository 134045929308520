import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormText from 'react-bootstrap/FormText';
import { Button } from '../../custom/Buttons';
import { deviceConstants, colorConstants } from "../../../constants";


export const Container = styled.div`
    font-size: 12px;

    @media ${deviceConstants.tablet} {
        display: flex;
        justify-content: center;
    }
`;

export const StyledForm = styled(Form)`
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;

    @media ${deviceConstants.tablet} {
        padding: 0;
    }
`;

export const StyledFormGroup = styled(FormGroup)`
    margin-top: 16px;
`;

export const FormInputTitle = styled.div`
    font-weight: 600;
    color: ${colorConstants.softGrey};
    margin-bottom: 1px;
`;

export const StyledFormText = styled(FormText)`
  color: ${colorConstants.warning};
`;

export const StyledButton = styled(Button)`
    width: 100%;
    position: fixed;
    bottom: 0;

    @media ${deviceConstants.tablet} {
        width: 25%;
        border-radius: 2px;
        
        position: absolute;
        bottom: 20px;
    }
    
    @media ${deviceConstants.desktop} {
        width: 20%;
    }
`;

export const PasswordRequirements = styled.ul`
  // list-style-type: none;
`;