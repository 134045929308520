import React, { useState, useMemo, useCallback, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Spinner from 'react-bootstrap/Spinner';

import {
    Container, Title,
    StyledForm, StyledFormGroup, StyledFormLabel, StyledFormControl, StyledFormText,
    ButtonSection, Notifications, NotificationWarning, NotificationSuccess, SubmitButton, SpinnerContainer,
    ChangePaymentMethodButton,
} from './StripePaymentForm.styles';
import { StripeCardInput } from './StripeCardInput';
import { otherConstants, contentCheckoutForm } from '../../../constants';
import { prepareCustomerAddress } from '../../../utils';



const PaymentForm = ({
    clientSecret, stripeId, isSetupIntent,
    disableDetailsChange, holderName, email, phone, address, isAddressDisabled, paymentMethod, handleChange, handleChangeSavedPaymentMethod,
    isCardCvcValidationEnabled,
    preButtonSection,
    buttonText, disableSubmitButton, successMessage,
    handleSubmit, handleProcessing, onComplete,
}) => {

    const [submitted, setSubmitted] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [formattedSuccessMessage, setFormattedSuccessMessage] = useState('');

    // Stripe API
    const stripe = useStripe();
    const elements = useElements();

    // check if has either a saved or typed in a new payment method
    const hasPaymentMethod = useMemo(() => {
        if(paymentMethod.default || paymentMethod.brand) return true;
        return false;
    }, [paymentMethod]);

    // check if it has saved a default payment method
    const hasSavedDefaultPaymentMethod = useMemo(() => {
        if(paymentMethod && paymentMethod.paymentMethodId && paymentMethod.stripePaymentMethodId && paymentMethod.default) return true;
        return false;
    }, [paymentMethod]);

    // validate has address
    const hasAddress = useMemo(() => {
        if(isAddressDisabled) return true;
        if(address && (address.addressLine1 || address.addressLine2 || address.city || address.state || address.postalCode || address.country)) return true;
        return false;
    }, [address, isAddressDisabled]);

    const hasAddressCompleted = useMemo(() => {
        if(isAddressDisabled) return true;
        if(address && address.addressLine1 && address.city && address.postalCode && address.country) return true;
        return false;
    }, [address, isAddressDisabled]);

    // enable/disable submit button - Make sure to disable form submission until Stripe.js has loaded and other input data are empty.
    const isSubmitButtonDisabled = useMemo(() => {
        if(!stripe || !elements || !holderName || !email || !hasPaymentMethod || !hasAddress || !hasAddressCompleted || disableSubmitButton || processing || succeeded) return true;
        return false;
    }, [stripe, elements, holderName, email, hasPaymentMethod, hasAddress, hasAddressCompleted, disableSubmitButton, processing, succeeded]);


    const submitButtonText = useMemo(() => {
        if(buttonText) return buttonText;
        if(paymentMethod && paymentMethod.type) return `PAY WITH ${paymentMethod.type.toUpperCase()}`;
        return 'PAY NOW';
    }, [buttonText, paymentMethod]);


    // submit button onClick method
    const handleSubmitButton = useCallback((type) => {
        if(isSubmitButtonDisabled) return;
        setSubmitted(true);
        if(handleSubmit) handleSubmit(type);
        setProcessing(true);
        if(handleProcessing) handleProcessing(true);
        setError(null);
    }, [isSubmitButtonDisabled, handleSubmit, handleProcessing]);


    useEffect(() => {
        // Below code gets executed once client secret and stripe Id are downloaded
        // and loaded into the payment form component.
        if(submitted && processing && !error && clientSecret && stripeId) {

            async function confirmStripePayment() {
                // init card payment confirmation configs
                let cardPaymentConfigs = {};
    
                if(hasSavedDefaultPaymentMethod) {
                    // set saved payment method id
                    cardPaymentConfigs.payment_method = paymentMethod.stripePaymentMethodId;

                    // recollect and validate again card cvc (we set a Stripe Radar rule to decline payment if cvc validation fails)
                    // Ref. https://stripe.com/docs/payments/save-during-payment-cards-only#web-recollect-cvc
                    if(isCardCvcValidationEnabled) cardPaymentConfigs.payment_method_options = { card: { cvc: elements.getElement(CardCvcElement) } };
    
                } else {
                    // cardPaymentConfigs.receipt_email = email;
                    cardPaymentConfigs.payment_method = {};
                    cardPaymentConfigs.payment_method.card = elements.getElement(CardElement);
                    cardPaymentConfigs.payment_method.billing_details = {};
                    cardPaymentConfigs.payment_method.billing_details.name = holderName;
                    cardPaymentConfigs.payment_method.billing_details.email = email;
                    cardPaymentConfigs.payment_method.billing_details.phone = phone;
                    // format address for stripe - Stripe card only accepts country's 2-letter ISO code
                    cardPaymentConfigs.payment_method.billing_details.address = address ? prepareCustomerAddress(address, true) : {};
                }
    
                
                let result = null
                
                if(isSetupIntent) {
                    // confirm card with a SetupIntent (auth and save new card details or validate cvc for saved card) and with no charge
                    result = await stripe.confirmCardSetup(clientSecret, hasSavedDefaultPaymentMethod ? {} : cardPaymentConfigs);
                } else {
                    // confirm and pay with a PaymentIntent
                    result = await stripe.confirmCardPayment(clientSecret, cardPaymentConfigs);
                }

                const { paymentIntent, setupIntent } = result;
                // TODO: STRIPE - Permanently fix: payment_intent_unexpected_state
                const hasResultError = result && result.error;
                const isResultErrorPaymentIntentSuccess = result && result.error && result.error.payment_intent && result.error.payment_intent.status && result.error.payment_intent.status === 'succeeded';

                const isFailedResult = !result || (hasResultError && !isResultErrorPaymentIntentSuccess);
                const isSuccessResult = (paymentIntent && paymentIntent.status === 'succeeded') || (setupIntent && setupIntent.status === 'succeeded') || isResultErrorPaymentIntentSuccess;

                // handle error
                if(isFailedResult) {
                    // Add additional error handling here
                    const errorMsg = !result ? 'Something went wrong during payment method confirmation' : result.error.message;
                    setError(errorMsg);
                    setProcessing(false);
                    if(handleProcessing) handleProcessing(false);
                    setSucceeded(false);
                    return;
                }
    
                // handle response
                if(isSuccessResult) {
                    // Add additional success handling here
                    setSubmitted(false);
                    setFormattedSuccessMessage(`${isSetupIntent ? 'Your card check was successful.' : 'Your payment was successful.'} ${successMessage}`);
                    setSucceeded(true);
                    setProcessing(false);
                    if(handleProcessing) handleProcessing(false);
                    setError(null);
    
                    // execute any further actions after completion
                    if(onComplete) onComplete();
                }
            }

            // execute stripe payment confirmation
            confirmStripePayment();
        }

    }, [submitted, processing, error, clientSecret, stripeId, isSetupIntent, hasSavedDefaultPaymentMethod, paymentMethod, isCardCvcValidationEnabled, email, holderName, phone, address, stripe, elements, onComplete, handleProcessing, successMessage]);


    return (
        <Container>
            <Title>{contentCheckoutForm.billingPayment.title}</Title>

            <StyledForm>
                <StyledFormGroup>
                    <StyledFormLabel>{contentCheckoutForm.billingPayment.holdeName}</StyledFormLabel>
                    <StyledFormControl
                        disabled={hasSavedDefaultPaymentMethod || processing || disableDetailsChange}
                        type="text"
                        placeholder={disableDetailsChange ? '' : contentCheckoutForm.billingDetails.placeholders.name}
                        name="holderName"
                        value={holderName}
                        onChange={handleChange} />
                    { submitted && !holderName && <StyledFormText>{contentCheckoutForm.billingPayment.holdeName + ' is required'}</StyledFormText> }
                </StyledFormGroup>

                <StyledFormGroup>
                    <StyledFormLabel>{contentCheckoutForm.billingPayment.cardDetails}</StyledFormLabel>

                    <StripeCardInput
                        disabled={processing}
                        paymentMethod={paymentMethod}
                        enableCardCvc={isCardCvcValidationEnabled}
                        onChange={handleChange} />
                </StyledFormGroup>
            </StyledForm>

            {preButtonSection}

            <ButtonSection>
                <Notifications>
                    { submitted && error && <NotificationWarning>{error}</NotificationWarning> }

                    { succeeded && <NotificationSuccess>{formattedSuccessMessage || successMessage}</NotificationSuccess> }
                    
                    { !submitted && paymentMethod && paymentMethod.paymentType && !error &&
                    (!paymentMethod.brand || !holderName) &&
                    <div>{'Please enter your ' + paymentMethod.paymentType + ' details.'}</div> }
                </Notifications>

                { processing && <SpinnerContainer><Spinner animation="border" /></SpinnerContainer> }

                <SubmitButton disabled={isSubmitButtonDisabled} gradient={true} onClick={() => handleSubmitButton('card')}>
                    {submitButtonText}
                </SubmitButton>

                <ChangePaymentMethodButton disabled={processing}>
                    <span onClick={processing ? null : handleChangeSavedPaymentMethod}>
                        {contentCheckoutForm.billingPayment.changePaymentMethod}
                    </span>
                </ChangePaymentMethodButton>
            </ButtonSection>
        </Container>
    );
};


// Stripe Payment Form Wrapper
export const StripePaymentForm = (props) => {

    const [init, setInit] = useState(false);
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        if(!init) {
            // load stripe api
            const promise = loadStripe(otherConstants.stripePk);
            setStripePromise(promise);
            setInit(true);
        }

    }, [init]);

    // do not render component if Stripe API is not initialized
    if(!init) return null;

    return (
        <Elements stripe={stripePromise}>
            <PaymentForm {...props} />
        </Elements>
    );
};