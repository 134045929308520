import React, { useRef } from 'react';

import {
    Container, InputSection, SectionTitle,
    EnableToggleContainer, EnableToggle,
    StyledFormText, MaxTextIndicator, Notification,
    FileUploader, ProjectLanguage,
} from './EditGithubProject.styles';
import { GradientInputEditor, SimpleLinkInput } from '../../../custom/FormInput';
import { UploadPhoto } from '../../../custom/Images';


export const EditGithubProject = ({
    isUpgraded,
    link,
    handleChange,
    handleUploadPhoto,
    handleUpgrade,
}) => {

    // open file browser on profile click
    const inputPhoto = useRef(null);
    const openFileUploader = () => {
      inputPhoto.current.click();
    };

    return (
        <Container>

            <InputSection>
                <EnableToggleContainer>
                    <SectionTitle>Showcase Project</SectionTitle>

                    <EnableToggle type="switch" name={'link-enable'} checked={link.enabled} onChange={handleChange} />
                </EnableToggleContainer>
            </InputSection>

            <InputSection>
                <SectionTitle>Project Link</SectionTitle>

                <SimpleLinkInput
                    data={link}
                    hasBorder={true}
                    handleLabelChange={handleChange}
                    handleUrlChange={handleChange} />
                
                { !link.label && <StyledFormText>Project label is required</StyledFormText> }
            </InputSection>

            <InputSection>
                <SectionTitle>Project Description</SectionTitle>

                <GradientInputEditor
                    as="textarea"
                    rows={3}
                    placeholder='Your project description'
                    cols={2000}
                    name="description"
                    maxLength="60"
                    value={link.description || ''}
                    onChange={handleChange} />
                <MaxTextIndicator>{link.description && link.description.length > 0 ? link.description.length : '0'}/60</MaxTextIndicator>
            </InputSection>

            <InputSection>
                <SectionTitle>Project Logo {!isUpgraded && <Notification onClick={handleUpgrade}>Upgrade</Notification>}</SectionTitle>

                <UploadPhoto deactivate={!isUpgraded} handleDelete={() => handleChange({ target: { name: 'delete-image', value: '' } })} showEdit={true} url={link && link.imageUrl ? link.imageUrl + '&' + Date.now() : null} onClick={openFileUploader} />
                <FileUploader type='file' accept="image/*" id='photo' ref={inputPhoto} onChange={handleUploadPhoto} />
            </InputSection>

            <ProjectLanguage>The primary programming language of this project is {link.githubProgrammingLanguage}</ProjectLanguage>
        </Container>
    );
};