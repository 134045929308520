import React, { useMemo } from "react";

import {
    Container, GradientDivider, Section, SectionTitle,
    CreatorContainer, CreatorLink,
    ConfirmationCodeContainer, ConfirmatonCodeLabel, ConfirmatonCodeRow, ConfirmatonCodeNumber,
    StatusSection, StatusText,
    CheckInRow, CheckInContent, CheckInContentTop, CheckInContentTopCol, CheckInIcon,
    AddressSection, AddressIcon, AddressContent,
    HotelWebsiteLink,
    ContactDetailsRow, ContactDetailsRowCol, PhoneIcon, EmailIcon,
    PriceRow, PriceRowCol,
    RoomDetailsRow, HolderNameRow,
    PaidWithRow, PaidWithRowCol, CreditCardIcon,
    ManagementRequestLink,
    RemarksRow,
} from './HotelBooking.styles';
import { HotelHeader } from '../../hotels/components';
import { addCurrencySymbol } from '../../../utils';
import { colorConstants, otherConstants } from '../../../constants';
import Icon from '../../../assets/icons/Icon';
import { RoomCancellationPolicyComponent } from '../../trips/trip/RoomCancellationPolicyComponent';



export const HotelBooking = ({
    hotelBooking,
    handleOpenCreatorPage,
    handleCopyConfirmationCode,
    handleSendConfirmationEmail,
    handleOpenAddressWithGoogleMaps,
    handleCallPhonenumber,
    handleSendEmail,
    handleUpdateBooking,
    handleCancelBooking,
    handleContactUs,
}) => {

    const hasCouponCode = useMemo(() => {
        if(hotelBooking.coupon && hotelBooking.discount) return true;
        return false;
    }, [hotelBooking.coupon, hotelBooking.discount]);

    const hasLocalTax = useMemo(() => {
        if(hotelBooking.taxesAndFeesNotIncludedInPrice !== null && hotelBooking.taxesAndFeesNotIncludedInPrice > 0) return true;
        return false;
    }, [hotelBooking.taxesAndFeesNotIncludedInPrice]);

    // format amounts
    const formattedTotalAmount = useMemo(() => addCurrencySymbol(hotelBooking.currencyCode, hotelBooking.total / 100), [hotelBooking.currencyCode, hotelBooking.total]);
    const formattedLocalTax = useMemo(() => hasLocalTax ? addCurrencySymbol(hotelBooking.currencyCode, hotelBooking.taxesAndFeesNotIncludedInPrice / 100) : null, [hasLocalTax, hotelBooking.currencyCode, hotelBooking.taxesAndFeesNotIncludedInPrice]);
    const formattedTotalAmountWithLocalTax = useMemo(() => hasLocalTax ? addCurrencySymbol(hotelBooking.currencyCode, (hotelBooking.total + hotelBooking.taxesAndFeesNotIncludedInPrice) / 100) : null, [hasLocalTax, hotelBooking.currencyCode, hotelBooking.total, hotelBooking.taxesAndFeesNotIncludedInPrice]);
    const formattedDiscountAmount = useMemo(() => addCurrencySymbol(hotelBooking.currencyCode, -(hotelBooking.discount / 100)), [hotelBooking.currencyCode, hotelBooking.discount]);
    const formattedDiscountedTotalAmount = useMemo(() => addCurrencySymbol(hotelBooking.currencyCode, (hotelBooking.total - hotelBooking.discount) / 100), [hotelBooking.currencyCode, hotelBooking.total, hotelBooking.discount]);

    const hasConfirmationCode = useMemo(() => {
        if(hotelBooking.confirmationCode) return true;
        return false;
    }, [hotelBooking.confirmationCode]);

    const hasWebsite = useMemo(() => (hotelBooking.website ? true : false), [hotelBooking]);

    const hasContactDetails = useMemo(() => {
        if(hotelBooking.email || hotelBooking.phone) return true;
        return false;
    }, [hotelBooking]);

    const cancellationPolicyComponents = useMemo(() => {
        if(!hotelBooking || !hotelBooking.cancellationPolicyComponents || hotelBooking.cancellationPolicyComponents.length === 0) return null;
        // add No-show into components
        let comps = [...hotelBooking.cancellationPolicyComponents, { type: 'NO_SHOW', label: 'No-show fee', details: "A fee equal to the last applicable cancellation policy applies if you don't show up" }];
        // render components
        return comps.map((c, index) => <RoomCancellationPolicyComponent key={index} component={c} />);
    }, [hotelBooking]);

    const roomRateNotes = useMemo(() => {
        if(!hotelBooking || !hotelBooking.notes || hotelBooking.notes.length === 0) return null;
        if(hotelBooking.notes.length > 1) return (<ol>{hotelBooking.notes.map((note, index) => <li key={index}>{note}</li>)}</ol>);
        return hotelBooking.notes[0];
    }, [hotelBooking]);

    const isBookingCancelled = useMemo(() => {
        if(hotelBooking.cancellationStatus || hotelBooking.cancellationRequested || hotelBooking.cancelling) return true;
        return false;
    }, [hotelBooking]);

    const disableBookingUpdateRequest = useMemo(() => {
        if(isBookingCancelled) return true;
        return false;
    }, [isBookingCancelled]);

    const disableConfirmationEmailSendRequest = useMemo(() => {
        if(isBookingCancelled || hotelBooking.confirmationEmailSent || hotelBooking.sendingConfirmationEmail) return true;
        return false;
    }, [hotelBooking, isBookingCancelled]);


    return (
        <Container>
            <Section>
                <SectionTitle>Your trip booking details</SectionTitle>

                { hotelBooking.creator &&
                <CreatorContainer>
                    Inspired by&nbsp;
                    <CreatorLink onClick={() => handleOpenCreatorPage(hotelBooking.creator)} >@{hotelBooking.creator}</CreatorLink>
                </CreatorContainer> }

                { hasConfirmationCode &&
                <ConfirmationCodeContainer onClick={() => handleCopyConfirmationCode(hotelBooking.confirmationCode)}>
                    <ConfirmatonCodeLabel>Confirmation number:</ConfirmatonCodeLabel>
                    <ConfirmatonCodeRow>
                        <ConfirmatonCodeNumber>{hotelBooking.confirmationCode}</ConfirmatonCodeNumber>
                        <Icon type='copy' size={12} customcolor={colorConstants.mediumGrey} />
                    </ConfirmatonCodeRow>
                </ConfirmationCodeContainer> }
            </Section>


            <Section>
                <HotelHeader
                    name={hotelBooking.name}
                    type={hotelBooking.categoryType}
                    stars={hotelBooking.stars}
                    city={hotelBooking.city}
                    country={hotelBooking.country}
                    rating={hotelBooking.rating}
                    reviewsCount={hotelBooking.userRatingsTotal} />
            </Section>


            { hotelBooking.status &&
            <Section>
                <StatusSection>
                    Booking Status:&nbsp;&nbsp;&nbsp;&nbsp;
                    <StatusText>{hotelBooking.status}</StatusText>
                </StatusSection>
            </Section> }


            <Section>
                <CheckInRow>
                    <CheckInIcon />

                    <CheckInContent>
                        <CheckInContentTop>
                            <CheckInContentTopCol rowleftalign='true' bold='true' >Check-in:</CheckInContentTopCol>
                            <CheckInContentTopCol>{hotelBooking.start}</CheckInContentTopCol>
                        </CheckInContentTop>

                        from {hotelBooking.checkInFrom}
                    </CheckInContent>
                </CheckInRow>

                <CheckInRow>
                    <CheckInIcon />

                    <CheckInContent>
                        <CheckInContentTop>
                            <CheckInContentTopCol rowleftalign='true' bold='true' >Check-out:</CheckInContentTopCol>
                            <CheckInContentTopCol>{hotelBooking.end}</CheckInContentTopCol>
                        </CheckInContentTop>

                        until {hotelBooking.checkOutTo}
                    </CheckInContent>
                </CheckInRow>

                <AddressSection onClick={() => handleOpenAddressWithGoogleMaps(hotelBooking.address)}>
                    <AddressIcon />

                    <AddressContent>{hotelBooking.address}</AddressContent>
                </AddressSection>

                {hasWebsite && <HotelWebsiteLink href={hotelBooking.website} target='_blank' rel='noreferrer' >View more hotel info</HotelWebsiteLink>}
            </Section>


            { hasContactDetails &&
            <Section>
                <SectionTitle sub='true' >Contact Details</SectionTitle>

                { hotelBooking.phone &&
                <ContactDetailsRow onClick={() => handleCallPhonenumber(hotelBooking.phone)}>
                    <PhoneIcon />
                    <ContactDetailsRowCol>Call {hotelBooking.phone}</ContactDetailsRowCol>
                </ContactDetailsRow> }

                { hotelBooking.email &&
                <ContactDetailsRow onClick={() => handleSendEmail(hotelBooking.email, hotelBooking.confirmationCode, hotelBooking.start, hotelBooking.end)}>
                    <EmailIcon />
                    <ContactDetailsRowCol>Email at {hotelBooking.email}</ContactDetailsRowCol>
                </ContactDetailsRow> }
            </Section> }


            <Section>
                <GradientDivider margintop='8px' marginbottom='8px' />

                <PriceRow>
                    <PriceRowCol bold='true'>Total Price</PriceRowCol>
                    <PriceRowCol bold='true' columnrightalign='true' >{formattedTotalAmountWithLocalTax || formattedTotalAmount}</PriceRowCol>
                </PriceRow>

                { hasCouponCode &&
                <PriceRow>
                    <PriceRowCol smalltext='true' >Discount</PriceRowCol>
                    <PriceRowCol smalltext='true' columnrightalign='true' >{formattedDiscountAmount}</PriceRowCol>
                </PriceRow> }

                { (hasLocalTax || hasCouponCode) &&
                <PriceRow>
                    <PriceRowCol>Paid</PriceRowCol>
                    <PriceRowCol columnrightalign='true' >{hasCouponCode ? formattedDiscountedTotalAmount : formattedTotalAmount}</PriceRowCol>
                </PriceRow> }

                { hasLocalTax &&
                <PriceRow>
                    <PriceRowCol>Pay at property (local tax)</PriceRowCol>
                    <PriceRowCol columnrightalign='true' >{formattedLocalTax}</PriceRowCol>
                </PriceRow> }

                <GradientDivider margintop='8px' marginbottom='0px' />
            </Section>


            <Section>
                <SectionTitle sub='true' >Room Details</SectionTitle>

                <RoomDetailsRow>{`${hotelBooking.rooms} ${hotelBooking.roomName}`}</RoomDetailsRow>
                {(hotelBooking.adults > 0) && <RoomDetailsRow>{hotelBooking.adults} adults</RoomDetailsRow>}
                {(hotelBooking.children > 0) && <RoomDetailsRow>{hotelBooking.children} children (ages: {hotelBooking.childrenAges})</RoomDetailsRow>}
                {(hotelBooking.infants > 0) && <RoomDetailsRow>{hotelBooking.infants} infants</RoomDetailsRow>}
                {hotelBooking.meal && <RoomDetailsRow>{hotelBooking.meal} included</RoomDetailsRow>}
            </Section>


            <Section>
                <SectionTitle sub='true' >Holder Name</SectionTitle>

                <HolderNameRow>{hotelBooking.holderName}</HolderNameRow>
            </Section>


            <Section>
                <SectionTitle sub='true' >Paid With</SectionTitle>

                <PaidWithRow>
                    <CreditCardIcon />
                    <PaidWithRowCol>**** **** **** {hotelBooking.paymentMethod}</PaidWithRowCol>
                </PaidWithRow>
            </Section>

            { cancellationPolicyComponents &&
            <Section>
                <SectionTitle sub='true' >Cancellation Policy</SectionTitle>
                <div>{cancellationPolicyComponents}</div>
            </Section> }

            { roomRateNotes &&
            <Section>
                <SectionTitle sub='true' >Important Notes</SectionTitle>
                <div>{roomRateNotes}</div>
            </Section> }

            <Section>
                <SectionTitle sub='true' >Manage Booking</SectionTitle>

                <ManagementRequestLink
                    disable={disableConfirmationEmailSendRequest}
                    onClick={() => disableConfirmationEmailSendRequest ? null : handleSendConfirmationEmail()}
                >Resend your confirmation email</ManagementRequestLink>

                <ManagementRequestLink
                    disable={disableBookingUpdateRequest}
                    onClick={() => handleUpdateBooking(disableBookingUpdateRequest ? null : hotelBooking.id)}
                >Request a booking change</ManagementRequestLink>

                <ManagementRequestLink
                    disable={isBookingCancelled}
                    onClick={() => handleCancelBooking(isBookingCancelled ? null : hotelBooking.id)}
                >Cancel your booking</ManagementRequestLink>

                <ManagementRequestLink onClick={() => handleContactUs(hotelBooking.id)} >Contact us: {otherConstants.bookingsEmail}</ManagementRequestLink>
            </Section>


            <Section>
                <RemarksRow>{hotelBooking.remarks}</RemarksRow>
            </Section>
            
        </Container>
    );
};