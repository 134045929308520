import styled from 'styled-components';
import Form from "react-bootstrap/Form";
import { Button } from '../Buttons';

import { colorConstants } from '../../../constants';



export const Container = styled.div`
    width: 100%;
`;

export const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: ${colorConstants.black};
  text-align: start;
`;

export const StyledForm = styled.div`
  margin-top: 16px;

  & > * {
    margin-bottom: 8px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const StyledFormGroup = styled(Form.Group)`

`;

export const StyledFormLabel = styled(Form.Label)`
  font-weight: 600;
  color: ${colorConstants.darkGrey};
`;

export const StyledFormControl = styled(Form.Control)`
  font-size: 12px;
  padding: 12px;
  border-radius: 2px;
  border-color: ${colorConstants.softGrey2};

  :focus {
    border-color: ${colorConstants.primaryDark};
    box-shadow: none;
  }

  ::placeholder {
    color: ${colorConstants.softGrey};
  }
`;

export const StyledFormText = styled(Form.Text)`
  margin-top: 8px;
  color: ${colorConstants.warning};
`;

export const ButtonSection = styled.div`
  width: 100%;
  margin-top: 32px;
`;

export const Notifications = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${colorConstants.darkGrey};
`;

export const NotificationWarning = styled.div`
  color: ${colorConstants.warning} !important;
`;

export const NotificationSuccess = styled.div`
  color: green !important;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 4px;
`;

export const SpinnerContainer = styled.div`
  margin-top: 16px;
  text-align: center;
  
  color: ${colorConstants.primaryDark};
`;

export const ChangePaymentMethodButton = styled.div`
  width: 100%;
  margin-top: 24px;

  font-size: 12px;
  font-weight: 700;
  color: ${colorConstants.black};
  text-align: center;

  span {
    padding: 4px;
    
    :hover {
      cursor: pointer;
      color: ${colorConstants.primaryDark};
    }
  }

  ${({ disabled }) => disabled && `
    color: ${colorConstants.softGrey2};

    span {
      :hover {
        cursor: auto;
        color: ${colorConstants.softGrey2};
      }
    }
  `}
`;