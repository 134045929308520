import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Slider } from '../../custom/FormInput';

import { colorConstants } from "../../../constants";


const Container = styled.div`
    
`;

const Header = styled.div`
    margin-bottom: 8px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 15px;
    color: ${colorConstants.black};
`;

const Subtitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: ${colorConstants.mediumGrey};
`;

const OptionsSection = styled.div`
    padding: 0px 16px;

    & > * {
        margin-bottom: 8px;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }
`;



export const MealSelector = ({ id, title, options, selected, onChange }) => {

    const [value, setValue] = useState(selected);
    const [subTitle, setSubTitle] = useState('');


    useEffect(() => {
        if(options && options.length > 0) {
            // setSubTitle(Object.keys(options).filter(val => val !== 'count')[value]);
            setSubTitle(options[value].format);
        }
    }, [options, value]);

    const handleChange = (e) => {
        setValue(e.target.value);
        if(onChange) onChange(id, e.target.value);
    };


    // only show range options if more than 1 options are available
    if(!options || options.length <= 1) return null;

    return (
        <Container>
            <Header>
                <Title>{title}</Title>
                &nbsp;
                <Subtitle>{subTitle}</Subtitle>
            </Header>

            <OptionsSection>
                <Slider
                    id={id}
                    min={0}
                    max={(options.length - 1) || 5}
                    step={1}
                    value={value}
                    onChange={handleChange}
                />
            </OptionsSection>
        </Container>
    );
};