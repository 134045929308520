import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Nav from 'react-bootstrap/Nav';
import { CSSTransition  } from 'react-transition-group';

import { AboutSection } from '../Branding';
import { deviceConstants, colorConstants, sideBarLinks } from "../../../constants";
import { history } from '../../../utils';


const Container = styled.div`
    display: ${props => props.reset && !props.enable ? 'none' : 'block'};

    .sidebar-enter {
        transform: translate(-100%);
    }
    .sidebar-enter-active {
        transform: translate(0);
        transition: transform 0.2s linear;
    }
    .sidebar-exit {
        transform: translate(0);
    }
    .sidebar-exit-active {
        transform: translate(-100%);
        transition: transform 0.2s linear;
    }


    .sidebar-background-enter {
        opacity: 0;
    }
    .sidebar-background-enter-active {
        opacity: 0.6;
        transition: opacity 200ms;
    }
    .sidebar-background-exit {
        opacity: 0.6;
    }
    .sidebar-background-exit-active {
        opacity: 0;
        transition: opacity 200ms;
    }
`;

const NavBar = styled(Nav)`
    height: 100%;
    width: 65%;
    padding: 8px 0;

    ${({ mfullscreen }) => mfullscreen && `
        padding: 24px 0 8px 0; // revert on tablet screen
    `}

    background: ${colorConstants.white};
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    position: absolute;
    top: ${props => props.position + 'px'};
    left: 0;
    z-index: 401;

    @media ${deviceConstants.tablet} {
        width: 20%;

        ${({ mfullscreen, position }) => mfullscreen && `
            padding: 8px 0; // this is set to revert the mobile setting above
            top: ${(position + 56) + 'px'}; // this is set to reset the position set above
        `}
    }

    @media ${deviceConstants.desktop} {
        width: 15%;
    }
`;

const Item = styled.div`
    padding: 8px 16px;
    color: ${colorConstants.black};

    ${({ active }) => active && `
        font-weight: 600;
        color: ${colorConstants.white};
        background: ${colorConstants.gradientHorizontal};
        width: 65%;
        border-top-right-radius: 500px;
        border-bottom-right-radius: 500px;
    `}

    :hover {
        cursor: pointer;

        ${({ active }) => !active && `
            font-weight: 600;
            color: ${colorConstants.primaryDark};
            background: ${colorConstants.verySoftGrey};
            width: 65%;
            border-top-right-radius: 500px;
            border-bottom-right-radius: 500px;
        `}
    }
`;

const AboutSectionWrap = styled.div`
    margin: auto 0;
`;

const StyledAboutSection = styled(AboutSection)`
    width: 80px;
    min-width: 80px;
`;

const FadedBackground = styled.div`
    height: 100%;
    width: 100%;
    background: ${colorConstants.black};
    opacity: 0.6;

    position: absolute;
    top: ${props => props.position + 'px'};
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 400;

    @media ${deviceConstants.tablet} {
        ${({ mfullscreen, position }) => mfullscreen && `
            top: ${(position + 56) + 'px'}; // this is set to reset the position set above
        `}
    }
`;


export const SideBar = ({
    enable,
    reset,
    position,
    mfullscreen, // configure sidebar as fullscreen only on mobile screens
    toggle,
    from,
    options,
}) => {

    // calculate sidebar size
    position = position ? position + 56 : 56;
    if(mfullscreen) position = position - 56;

    const navBarRef = React.useRef(null);
    const faderRef = React.useRef(null);

    function navigate(to, from) {
        history.push({
            pathname: to,
            state: {
                from
            }
        });
    }

    const [navigationLinks, setNavigationLinks] = useState([{ url: '', name: '', enabled: false }]);

    useEffect(() => {
        const links = sideBarLinks(options.isAuthenticated, options.creator, options.basic, options.premium, options.platinum, options.links);
        setNavigationLinks(links);
    }, [options]);

    return (
        <Container reset={reset} enable={enable} >
            <CSSTransition
                nodeRef={navBarRef}
                in={enable}
                timeout={200}
                classNames="sidebar"
                unmountOnExit
            >
                <NavBar ref={navBarRef} mfullscreen={mfullscreen} position={position} className="sidebar flex-column">
                    {navigationLinks.map((link, index) => {
                        const isSelected = window.location.pathname === link.url;
                        if(link.enabled) return <Item active={isSelected} key={index} onClick={() => navigate(link.url, from)}>{link.name}</Item>;
                        return null;
                    })}

                    <AboutSectionWrap>
                        <StyledAboutSection noRedirect={true} color={colorConstants.mediumGrey} />
                    </AboutSectionWrap>
                </NavBar>
            </CSSTransition>

            <CSSTransition
                nodeRef={faderRef}
                in={enable}
                timeout={200}
                classNames="sidebar-background"
                unmountOnExit
            >
                <FadedBackground ref={faderRef} mfullscreen={mfullscreen} position={position} className="sidebar-background" onClick={toggle} />
            </CSSTransition>
        </Container>
    );
};