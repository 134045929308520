import React, { forwardRef } from 'react';
import {
    Container,
    Image,
    TopSection, BottomSection, TopSectionFader,
    Name, Token, TokenWrapper, TokenFader
} from './NFTLinkGallerySquare.styles';
import { colorConstants } from "../../constants";


export const NFTLinkGallerySquare = forwardRef((props, ref) => {
    const { data, handleOpen, customizations, maxWidth, ...rest } = props;
    let { buttons, fonts } = customizations;

    // defaults
    if(buttons.color === '') buttons.color = colorConstants.softBlack;
    if(buttons.fontColor === '') buttons.fontColor = colorConstants.white;

    // set button shape
    const buttonShape = buttons.shape === 'OVAL' ? '24px' : buttons.shape === 'ROUNDED' ? '8px' : '0px';

    // set button color
    const buttonColor = buttons.style === 'OUTLINE' ? 'transparent' : buttons.color;
    const buttonColorHover = buttons.style === 'OUTLINE' ? buttons.color : 'transparent';

    // set button font color
    const fontColor = buttons.style === 'OUTLINE' ? buttons.color : buttons.fontColor;
    const fontColorHover = buttons.style === 'OUTLINE' ? buttons.fontColor : buttons.color;

    if(!data.image || data.image.length === 0) return null;

    return (
        <Container
            ref={ref}
            shape={buttonShape}
            color={buttonColor}
            colorHover={buttonColorHover}
            fontColor={fontColor}
            fontColorHover={fontColorHover}
            fontType={fonts.type}
            onClick={() => handleOpen(data)}
            customwidth={maxWidth || '120px'}
            {...rest}
        >
            <Image className='link-image' src={data.image} customwidth={maxWidth || '120px'} />

            <TopSection>
                <TopSectionFader className='top-section-fader' />
                <Name customwidth={maxWidth || '120px'} >{data.name}</Name>
            </TopSection>

            {data.chainTokenId &&
            <BottomSection>
                <TokenWrapper>
                    <TokenFader />
                    <Token>{`#${data.chainTokenIdShort || data.chainTokenId}`}</Token>
                </TokenWrapper>
            </BottomSection> }
        </Container>
    );
});