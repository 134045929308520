import { travelAgencyReducerConstants } from "../constants";
import { travelAgencyService } from "../services";


export const travelAgencyActions = {
    getAll,
    getById,
    clear
};


function getAll(agency) {
    return dispatch => {
        dispatch(request());

        travelAgencyService.getAll(agency)
            .then(
                agencies => dispatch(success(agencies)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: travelAgencyReducerConstants.GETALL_REQUEST } }
    function success(agencies) { return { type: travelAgencyReducerConstants.GETALL_SUCCESS, agencies } }
    function failure(error) { return { type: travelAgencyReducerConstants.GETALL_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request({ id }));

        travelAgencyService.getById(id)
            .then(
                agency => dispatch(success(agency)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(agency) { return { type: travelAgencyReducerConstants.GETBYID_REQUEST, agency } }
    function success(agency) { return { type: travelAgencyReducerConstants.GETBYID_SUCCESS, agency } }
    function failure(error) { return { type: travelAgencyReducerConstants.GETBYID_FAILURE, error } }
}

function clear() {
    return { type: travelAgencyReducerConstants.CLEAR_ALL };
}