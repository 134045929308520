import styled from 'styled-components';
import FormControl from 'react-bootstrap/FormControl'

import { colorConstants } from "../../../constants";


export const Container = styled.div`
    width: 100%;
    margin-top: 8px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const ColorPreviewGradientBorder = styled.div`
    height: 32px;
    width: 32px;
    margin-right: 16px;
    border-radius: 500px;
    background: ${props => props.deactivate ? colorConstants.softGrey : colorConstants.gradientHorizontal};
    padding: ${props => props.deactivate ? '0' : '1px'};
    display: flex;
    align-items: center;
`;

export const ColorPreview = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 500px;
    background: ${props => props.color || colorConstants.softGrey};

    :hover {
        cursor: ${props => props.deactivate ? 'initial' : 'pointer'};
    }
`;

export const InputBoxGradientBorder = styled.div`
    height: 32px;
    width: 100px;
    display: flex;
    align-items: center;
    background: ${props => props.deactivate ? colorConstants.softGrey : colorConstants.gradientHorizontal};
    border-radius: 4px !important;
    padding: 1px;

    :focus-within {
        background: ${colorConstants.primaryDark};
    }
`;

export const StyledFormControl = styled(FormControl)`
    height: 100%;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    color: ${colorConstants.black};
    padding: 4px 12px;
    border-radius: 4px !important;
    border: none;

    :focus {
        border-color: ${colorConstants.primaryDark};
        box-shadow: none;
        outline: none;
    }

    ::placeholder {
        color: ${props => props.nolabel ? colorConstants.mediumGrey : colorConstants.softGrey};
        font-weight: ${props => props.nolabel ? '600' : '400'};
    }
`;