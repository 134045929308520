import styled from 'styled-components';
import { colorConstants } from "../../../constants";


const Container = styled.div`
    width: 100%;

    :hover {
        .reservation-button {
            background: ${({ enable }) => enable ? colorConstants.primaryDark : colorConstants.softGrey2};
        }

        cursor: ${({ enable }) => enable ? 'pointer' : 'auto'};
    }
`;

const GradientBorder = styled.div`
    width: 100%;
    height: 1px;
    background: ${({ enable }) => enable ? colorConstants.gradientHorizontal : colorConstants.softGrey2};
`;

const Columns = styled.div`
    width: 100%;
    padding: 8px 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
`;

const Left = styled.div`
    width: 59%;
`;

const StyledButton = styled.div`
    padding: 6px 56px;

    font-size: 16px;
    font-weight: 600;
    text-align: center;

    background: ${({ enable }) => enable ? colorConstants.gradientHorizontal : colorConstants.softGrey2};
    color: ${({ enable }) => enable ? colorConstants.white : colorConstants.darkGrey};

    border: none;
    border-radius: 2px;
`;

const Right = styled.div`
    width: 36%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const Amount = styled.div`
    font-weight: 700;
    font-size: 20px;
    color: ${colorConstants.black};
`;

const AmountSubtext = styled.div`
    font-weight: 500;
    font-size: 9px;
    color: ${colorConstants.softGrey};
    text-align: center;
`;

const Warning = styled.div`
    width: 100%;
    padding: 8px 16px;

    font-weight: 500;
    font-size: 14px;
    color: ${colorConstants.warning};
`;

const Notice = styled.div`
    width: 100%;
    padding: 8px 16px;

    font-weight: 500;
    font-size: 10px;
    color: ${colorConstants.black};
`;


export const BookingReservationButton = ({ enable, amount, amountText, buttonText, noticeText, onClick, error }) => {
    return (
        <Container enable={enable} onClick={enable ? onClick : null} >
            { error && <Warning>{error}</Warning> }

            { !error && noticeText && <Notice>{noticeText}</Notice> }

            <GradientBorder enable={enable} />

            <Columns>
                <Left>
                    <StyledButton enable={enable} className='reservation-button' >{buttonText}</StyledButton>
                </Left>
                <Right>
                    <Amount>{amount}</Amount>
                    <AmountSubtext>{amountText}</AmountSubtext>
                </Right>
            </Columns>
        </Container>
    );
};