import styled from 'styled-components';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';
import { Trash, PencilFill, BoxArrowUpRight } from 'react-bootstrap-icons';

import { colorConstants, shadowConstants } from "../../../../constants";


export const GradientBorder = styled.div`
    margin-top: 6px;
    margin-bottom: 2px;
    padding: 1px;
    border-radius: 2px;
    background: ${props => props.border ? colorConstants.gradientHorizontal : colorConstants.white};
    box-shadow: ${props => !props.border ? shadowConstants.adminListItems : 'none'};

    display: flex;

    :focus-within {
        background: ${props => props.border ? colorConstants.primaryDark : colorConstants.white};
    }
`;

export const LeftColumn = styled.div`
    width: 100%;
    background: ${colorConstants.white};
`;

export const InputContainer = styled.div`
    position: relative;
`;

export const StyledInput = styled(FormControl)`
    max-width: 100%;
    font-size: 12px;
    font-weight: 500;
    // color: ${colorConstants.black};
    color: ${props => props.enable ? colorConstants.black : colorConstants.white};
    padding: 8px;
    border-radius: 2px;
    border-color: ${colorConstants.white};
    // visibility: ${props => props.enable ? 'visible' : 'hidden'};

    :focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }

    ::placeholder {
        font-weight: 600;
        color: ${colorConstants.softGrey};
    }

    :disabled {
        background: ${colorConstants.white};
    }
`;

export const InputButtons = styled.div`
    max-width: 100%;
    padding: 8px 16px;
    background: ${colorConstants.white};

    display: flex;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;

    z-index: 22;

    visibility: ${props => props.enable ? 'visible' : 'hidden'};
`;

export const InputTitle = styled.div`
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    color: ${props => props.soft ? colorConstants.softGrey : colorConstants.mediumGrey};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    margin-right: 16px;

    :hover {
        cursor: pointer;
    }
`;

export const InputButton = styled(PencilFill)`
    min-width: 12px !important;
    max-width: 12px !important;
    min-height: 12px !important;
    max-height: 12px !important;
    color: ${colorConstants.softGrey};

    :hover {
        cursor: pointer;
    }
`;

export const BottomControls = styled.div`
    padding: 8px;

    & > * {
        margin-right: 8px;
    }

    & > *:last-child {
        margin-right: 0px;
    }

    ${({ deactivate }) => deactivate && `
        display: none;
    `}
`;

export const OpenButton = styled(BoxArrowUpRight)`
    padding: 8px;
    color: ${colorConstants.mediumGrey};

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const RightColumn = styled.div`
    padding: 8px;
    background: ${colorConstants.white};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    ${({ deactivate }) => deactivate && `
        display: none;
    `}
`;

export const StyledFormCheck = styled(FormCheck)`

    input:hover {
        cursor: pointer;
    }
    
    input:checked {
        background: ${colorConstants.primaryDark};
        border-color: ${colorConstants.primaryDark};
        background-repeat: no-repeat;
    }

    input:focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }
`;

export const DeleteButton = styled(Trash)`
    padding: 8px;
    color: ${colorConstants.mediumGrey};

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;