import React from 'react';

import {
    Container,
    StyledForm,
    StyledFormGroup,
    FormInputTitle,
    StyledFormText,
    StyledButton
} from './ChangeEmail.styles';
import { GradientFormInput } from '../../custom/FormInput';


const ChangeEmail = (props) => {
    const { confirmedEmail, pendingEmailConfirmation, email, submitted, handleSubmit, handleChange, alert } = props;

    return (
        <Container>
            <StyledForm id="email-change">
                <StyledFormGroup>
                    <FormInputTitle>{pendingEmailConfirmation ? 'Confirmed Email' : 'Current Email'}</FormInputTitle>
                    {confirmedEmail}
                </StyledFormGroup>

                { pendingEmailConfirmation &&
                <StyledFormGroup>
                    <FormInputTitle>You Must Confirm</FormInputTitle>
                    <StyledFormText>{pendingEmailConfirmation}</StyledFormText>
                </StyledFormGroup> }

                <StyledFormGroup>
                    <FormInputTitle>New Email</FormInputTitle>
                    <GradientFormInput type="email" name="email" value={email} onChange={handleChange} />
                    { submitted && !email && <StyledFormText>New email address is required</StyledFormText> }
                    { alert && alert.message && <StyledFormText>{alert.message}</StyledFormText> }
                </StyledFormGroup>
            </StyledForm>

            <StyledButton
                gradient={true}
                type="submit"
                onClick={handleSubmit}
            >
                Update Email
            </StyledButton>
        </Container>
    );
};

export default ChangeEmail;