import React from 'react';
import styled from 'styled-components';
import FormControl from 'react-bootstrap/FormControl'

import { colorConstants } from "../../../constants";

const GradientBorder = styled.div`
    background: ${colorConstants.gradientHorizontal};
    border-radius: 0px;
    padding: 0 0 1px 0;

    ${({ box }) => box && `
        border-radius: 4px;
        padding: 1px;
    `}

    ${({ box2 }) => box2 && `
        border-radius: 2px;
        padding: 1px;
    `}

    :focus-within {
        background: ${colorConstants.primaryDark};
    }
`;

const Container = styled.div`
    background: ${colorConstants.white};
    padding: 8px 4px 4px 4px;
    border-radius: 0px;

    ${({ box }) => box && `
        padding: 12px 10px 4px 10px;
        border-radius: 4px;
    `}

    ${({ box2 }) => box2 && `
        padding: 12px 10px 4px 10px;
        border-radius: 2px;
    `}

    border: none;
`;

const Label = styled.div`
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    color: ${colorConstants.mediumGrey};
`;

const StyledFormControl = styled(FormControl)`
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    padding: 4px 0;
    border: none;

    color: ${colorConstants.black};

    ${({ box, box2 }) => (box || box2) && `
        font-weight: 400;
    `}

    :focus {
        box-shadow: none;
        outline: none;
    }

    ::placeholder {
        color: ${props => props.nolabel ? colorConstants.mediumGrey : colorConstants.softGrey};
        font-weight: ${props => props.nolabel ? '600' : '400'};
    }
`;

export const GradientFormInputWithLabel = (props) => {
    const { box, box2, label } = props;

    return (
        <GradientBorder box={box} box2={box2} >
            <Container box={box} box2={box2} >
                { label && <Label>{label}</Label> }
                <StyledFormControl {...props} />
            </Container>
        </GradientBorder>
    );
};