import { Helmet } from 'react-helmet-async';
import { contentHelmet, otherConstants, urlConstants } from '../../../constants';



export const MetaTags = (props) => {
    let { init, title, description, url, imageUrl } = props;

    title = title ? title + ' | ' + otherConstants.projectName : contentHelmet.title;
    description= description || contentHelmet.description;
    url = url ? urlConstants.baseUrlClient + url : urlConstants.baseUrlClient;

    return (
        <Helmet prioritizeSeoTags>
            {init && <meta charset="utf-8" />}
            {init && <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />}
            {init && <meta name="theme-color" content="#000000" />}

            {/* Primary Meta Tags */}
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageUrl || contentHelmet.seo.image.fb} />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={imageUrl || contentHelmet.seo.image.tt} />
        </Helmet>
    );
};