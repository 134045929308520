// This reducer manages the state related to homepage actions.
import { homepageReducerConstants } from '../constants';

export function homepage(state = {}, action) {

  switch (action.type) {

    case homepageReducerConstants.JOIN_WAITLIST_REQUEST: {
      const { success, failed, ...stateCopy } = state;
      return { ...stateCopy, joining: true };
    }

    case homepageReducerConstants.JOIN_WAITLIST_SUCCESS: {
      const { joining, failed, ...stateCopy } = state;
      return { ...stateCopy, success: true };
    }

    case homepageReducerConstants.JOIN_WAITLIST_FAILURE: {
      const { joining, success, ...stateCopy } = state;
      return { ...stateCopy, failed: true };
    }

    default:
      return state
  }
}