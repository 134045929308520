import styled from 'styled-components';
import { Bug } from 'react-bootstrap-icons';

import { colorConstants } from '../../../constants';
import { Button } from '../Buttons';


const Container = styled.div`
    padding-top: 48px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Header = styled.div`
    margin-top: 24px;
    margin-bottom: 56px;
    font-size: 18px;
    font-weight: 600;
    color: ${colorConstants.black};
    text-align: center;
`;


export const TripCheckoutError = (props) => {
    const { message, buttonText, onClick } = props;

    return (
        <Container>
            <Bug size={90} />
            <Header>{message}</Header>
            <Button outline={true} small={true} onClick={onClick} >{buttonText}</Button>
        </Container>
    );
};