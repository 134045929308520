import styled from 'styled-components';
import Form from "react-bootstrap/Form";
import { ShieldLockFill } from 'react-bootstrap-icons';
import { CountriesInput } from '../FormInput';

import { colorConstants } from '../../../constants';



export const Container = styled.div`
    width: 100%;
`;

export const CouponSection = styled.div`
    width: 100%;
    margin-top: 48px;
`;

export const CouponToggle = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const CouponToggleText = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${colorConstants.darkGrey};
    text-decoration: underline;

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;

export const BillingDetailsSection = styled.div`
  width: 100%;
  margin-top: 48px;
`;

export const BillingDetailsTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: ${colorConstants.black};
  text-align: start;
`;

export const StyledForm = styled.div`
  margin-top: 16px;

  & > * {
    margin-bottom: 8px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const StyledFormGroup = styled(Form.Group)`

`;

export const StyledFormLabel = styled(Form.Label)`
  font-weight: 600;
  color: ${colorConstants.darkGrey};
`;

export const StyledFormControl = styled(Form.Control)`
  font-size: 12px;
  padding: 12px;
  border-radius: 2px;
  border-color: ${colorConstants.softGrey2};

  :focus {
    border-color: ${colorConstants.primaryDark};
    box-shadow: none;
  }

  ::placeholder {
    color: ${colorConstants.softGrey};
  }
`;

export const CountrySelector = styled(CountriesInput)`

`;

export const StyledFormText = styled(Form.Text)`
  margin-top: 8px;
  color: ${colorConstants.warning};
`;

export const GuestCheckoutRegisterSection = styled.div`
  width: 100%;
  margin-top: 48px;

  font-weight: 500;
  font-size: 11px;
`;

export const GuestCheckoutRegisterTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${colorConstants.black};
  text-align: start;
`;

export const GuestCheckoutRegisterSectionRow = styled.div`
  margin-top: 16px;
`;

export const GuestCheckoutSpan = styled.span`

`;

export const GuestCheckoutLink = styled.a`
    color: ${colorConstants.darkGrey};

    :hover {
        color: ${colorConstants.primaryDark};
        cursor: pointer;
    }
`;

export const BillingAddressSection = styled.div`
  width: 100%;
  margin-top: 48px;
`;

export const BillingAddressTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${colorConstants.black};
  text-align: start;
`;

export const AdditionalNotesSection = styled.div`
  width: 100%;
  margin-top: 48px;
`;

export const AdditionalNotesTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${colorConstants.black};
  text-align: start;
`;

export const BillingPaymentSection = styled.div`
  width: 100%;
  margin-top: 48px;
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 48px;

  font-size: 11px;
  font-weight: 500;
  color: ${colorConstants.mediumGrey};
  text-align: center;

  & > * {
    margin-bottom: 8px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const FooterRow = styled.div`
  
`;

export const EncryptedMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const EncryptedIcon = styled(ShieldLockFill)`
  margin-right: 4px;
  font-size: 13px;
  color: ${colorConstants.mediumGrey};
`;

export const FooterLink = styled.a`
    color: ${colorConstants.darkGrey};

    :hover {
        color: ${colorConstants.primaryDark};
        cursor: pointer;
    }
`;