import React, { useMemo } from 'react';

import {
    Container,
    TopSection, Header, Title,
    Group, GroupInputTitle,
    // GroupInputText, EnableToggle,
} from './EditSearchLink.styles';
import { GradientFormInput } from '../../custom/FormInput';
import { PotentialEarningsTable } from '../../custom/Monetization';


export const EditSearchLink = ({
    data,
    userAccountType,
    handleUpgrade,
    handleChange,
}) => {

    let isStays = useMemo(() => data.type === 'ACCOMMODATION' ? true : false, [data]);

    return (
        <Container>
            <TopSection>
                <Header>
                    <Title>Configure Search for {isStays ? 'Stays' : 'Experiences'}</Title>
                </Header>
            </TopSection>

            <Group>
                <GroupInputTitle>Edit Label</GroupInputTitle>
                <GradientFormInput maxLength="15" box2='true' type="text" name="search-link-label" value={data.affiliate.label} onChange={handleChange} />
            </Group>

            {/* <Group row='true' >
                <GroupInputText>Hostels Search</GroupInputText>
                <EnableToggle type="switch" name='hostel-search-enable' checked={data.hostel} onChange={handleChange} />
            </Group>

            <Group row='true' >
                <GroupInputText>Cruises Search</GroupInputText>
                <EnableToggle type="switch" name='cruise-search-enable' checked={data.cruise} onChange={handleChange} />
            </Group> */}

            <Group>
                <PotentialEarningsTable userAccountType={userAccountType} handleUpgrade={handleUpgrade} />
            </Group>
        </Container>
    );
};