/**
 * Refs:
 * 
 * Draggable Resources
 * - https://www.youtube.com/watch?v=y1w6C9A5a2A
 * - https://www.npmjs.com/package/react-beautiful-dnd
 * - https://github.com/AfzalH/react-drag-and-drop-list-re-order/
 */
import React from "react";
import styled from 'styled-components';
import Icon from '../../../assets/icons/Icon';


const DragIconWrapper = styled.div`
  display: inline-block;
  svg {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    padding: 0;
    margin: 0;
  }
`;

export function DragHandle({ size, customcolor, ...props }) {
  return (
    <DragIconWrapper {...props}>
      <Icon type='drag_handle' size={size} customcolor={customcolor} />
    </DragIconWrapper>
  );
}