import React, { Component } from "react";
import { connect } from 'react-redux';
import qs from 'qs';

import { authActions, userActions, hotelBookingActions, alertActions } from '../../../actions';
import { ScreenContainer } from './BookingDetailsContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { Notification } from '../../../components/custom/Popup';
import { TopBar } from '../../../components/custom/TopBar';
import { BookingDetailsPage } from "../../../components/bookings";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { topbarConstants, sitemap, otherConstants, urlConstants } from "../../../constants";
import { history, defaultUser } from '../../../utils';


class BookingDetailsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            fromUrl: sitemap.admin.dashboard,
            scrollToSection: '', // used to scroll to particular section
            notification: '',
            user: defaultUser(),
            guestUser: null,
            isCsrfReady: false,
        };

        this.handleScrollReset = this.handleScrollReset.bind(this);
        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);
        this.handleClearNotification = this.handleClearNotification.bind(this);

        this.handleOpenCreatorPage = this.handleOpenCreatorPage.bind(this);
        this.handleCopyConfirmationCode = this.handleCopyConfirmationCode.bind(this);
        this.handleSendConfirmationEmail = this.handleSendConfirmationEmail.bind(this);
        this.handleOpenAddressWithGoogleMaps = this.handleOpenAddressWithGoogleMaps.bind(this);
        this.handleCallPhonenumber = this.handleCallPhonenumber.bind(this);
        this.handleSendEmail = this.handleSendEmail.bind(this);
        this.handleUpdateBooking = this.handleUpdateBooking.bind(this);
        this.handleCancelBooking = this.handleCancelBooking.bind(this);
        this.handleContactUs = this.handleContactUs.bind(this);
    }

    componentDidMount() {
        // load csrf token in cookies
        this.props.loadCsrfToken();

        if(this.props.userId) {

            /* For logged-in user */

            // get user data from db
            this.props.getById(this.props.userId);

            // stop and go back if no guest or booking id
            if(!this.props.location || !this.props.location.state || !this.props.location.state.data || !this.props.location.state.data.userId || !this.props.location.state.data.bookingId || !this.props.location.state.data.type) {
                history.replace({ pathname: sitemap.admin.dashboard, state: { error: 'No booking data found' } });
                return;
            }

            let initialState = {};
            // set from URL
            if(this.props.location.state.from) initialState = { ...this.state, ...initialState, fromUrl: this.props.location.state.from };
            // get data from previous page
            const { scrollToSection, userId, bookingId, type } = this.props.location.state.data;
            // set section to scroll to
            if(scrollToSection) initialState = { ...this.state, ...initialState, scrollToSection };
            // save booking data in state
            if(userId && bookingId && type) initialState = { ...this.state, ...initialState, userId, bookingId, type };
            // set initial settings page state
            this.setState(initialState);

        } else {

            /* For guest user */

            if(this.props.location.state && this.props.location.state.data && this.props.location.state.data.userId && this.props.location.state.data.bookingId && this.props.location.state.data.type) {
                let initialState = {};
                // set from URL
                if(this.props.location.state.from) initialState = { ...this.state, ...initialState, fromUrl: this.props.location.state.from };
                // get data from previous page
                const { scrollToSection, userId, bookingId, type } = this.props.location.state.data;
                // set section to scroll to
                if(scrollToSection) initialState = { ...this.state, ...initialState, scrollToSection };
                // save booking data in state
                if(userId && bookingId && type) initialState = { ...this.state, ...initialState, userId, bookingId, type, isGuestSession: true };
                // set initial settings page state
                this.setState(initialState);
    
            } else {
                // get query from link
                let query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
                if(!query || !query.session || !query.email || !query.ref || !query.type) {
                    // redirect to login page
                    history.replace({ pathname: sitemap.auth.login, state: { error: 'Invalid Params' } });
                    return;
                }
    
                // save guest user data
                const { session, email, ref, type } = query;
                this.setState({ guestUser: { session, email, bookingId: ref, type } });
            }
        }

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.clearAlert(); // reset alert in redux state
        this.props.resetHotelBookings();
    }

    componentDidUpdate() {
        // load user data from server into view
        if (!this.state.init && ((this.props.user && this.props.user.id) || this.state.isGuestSession)) {

            if(this.state.bookingId && this.state.userId && this.state.type) {

                // stop if not own booking
                if(!this.state.isGuestSession && this.state.userId !== this.props.user.id) {
                    history.replace({ pathname: this.state.fromUrl || sitemap.admin.dashboard, state: { error: 'Invalid Booking' } });
                    return;
                }

                // get booking data from server
                switch(this.state.type){
                    case 'ACCOMMODATION': {
                        this.props.getHotelBookingById(this.state.bookingId, new URLSearchParams({ details: true, userId: this.state.isGuestSession ? -1 : this.state.userId, guestUserId: this.state.isGuestSession ? this.state.userId : null }), this.state.isGuestSession);
                        break;
                    }

                    default:
                        break;
                }
            }

            // save user in state
            this.setState({ ...this.state, init: true, user: this.state.isGuestSession ? this.state.user : this.props.user, });
        }

        // login guest user
        if(!this.state.init && this.state.guestUser && !this.state.isCsrfReady && this.props.isCsrfReady) {
            // update CSRF status
            this.setState({ ...this.state, isCsrfReady: true });
            // authorise guest user and get userId
            this.props.loginGuestUser({ session: this.state.guestUser.session, email: this.state.guestUser.email });
        }

        // get hotel booking details via query params
        if(!this.state.init && this.state.isCsrfReady && this.state.guestUser && this.props.guestUserId) {
            // init state
            this.setState({ ...this.state, init: true });

            // get booking data from server
            switch(this.state.guestUser.type){
                case 'ACCOMMODATION': {
                    this.props.getHotelBookingById(this.state.guestUser.bookingId, new URLSearchParams({ details: true, userId: -1, guestUserId: this.props.guestUserId }), true);
                    break;
                }

                default:
                    break;
            }
        }
    }

    handleScrollReset() {
        if(this.state.init && this.state.scrollToSection) {
            this.setState({ ...this.state, scrollToSection: '' });
        }
    }

    handleTopBarBackButton() {
        const isGuest = this.state.isGuestSession || this.state.guestUser;
        // Go back
        history.push({
            ...this.props.location, // pass guest user params
            pathname: isGuest ? sitemap.admin.bookings.list : this.state.fromUrl,
            state: {
                data: {
                    userId: this.state.userId,
                    bookingId: this.state.bookingId,
                }
            }
        });
    }

    handleClearNotification(){
        this.setState({ ...this.state, notification: '' });
    }

    handleOpenCreatorPage(username) {
        if(!username) return;
        window.open(urlConstants.baseUrlClient + '/' + username);
    }

    handleCopyConfirmationCode(code){
        navigator.clipboard.writeText(code);
        this.setState({ ...this.state, notification: 'Confirmation number copied' });
    }

    handleSendConfirmationEmail() {
        // skip while sending request or if confirmation sent successfully
        if(!this.props.hotelBooking || this.props.hotelBooking.confirmationEmailSent || this.props.hotelBooking.sendingConfirmationEmail) return;
        const isGuest = this.state.isGuestSession || this.state.guestUser;
        // request to resend confirmation email
        this.props.sendBookingConfirmationEmail(this.props.hotelBooking.id, new URLSearchParams({ customerId: this.props.hotelBooking.customerId, guestUserId: isGuest ? this.props.guestUserId : null }), isGuest);
    }

    handleOpenAddressWithGoogleMaps(address) {
        window.open(`http://maps.google.com/?q=${address}`);
    }

    handleCallPhonenumber(phone) {
        window.open(`tel:${phone}`, '_self');
    }

    handleSendEmail(emailAddress, confirmationCode, start, end) {
        window.open(`mailto:${emailAddress}?subject=Query%20for%20booking%20${confirmationCode ? confirmationCode : ''}%20with%20dates%20${start}%20to%20${end}`);
    }

    handleUpdateBooking(bookingId) {
        if(!bookingId) return;
        window.open(`mailto:${otherConstants.supportEmail}?subject=Hotel%20booking%20change%20request%20(id:%20${bookingId})`);
    }

    handleCancelBooking(bookingId) {
        if(!bookingId || !this.props.hotelBooking || !this.props.hotelBooking.customerId || !this.props.hotelBooking.sourceBookingId) return;
        const isGuest = this.state.isGuestSession || this.state.guestUser;
        this.props.cancelBooking(bookingId, { customerId: this.props.hotelBooking.customerId, sourceBookingId: this.props.hotelBooking.sourceBookingId, guestUserId: isGuest ? this.props.guestUserId : null }, isGuest);
    }

    handleContactUs(bookingId) {
        window.open(`mailto:${otherConstants.supportEmail}?subject=About%20hotel%20booking%20(id:%20${bookingId})`);
    }


    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.bookingDetails} />

                <TopBar
                    title={topbarConstants.titles.bookingDetails}
                    backButton={this.handleTopBarBackButton} />

                { this.state.init &&
                <BookingDetailsPage
                    hotelBooking={this.props.hotelBooking}
                    handleOpenCreatorPage={this.handleOpenCreatorPage}
                    handleCopyConfirmationCode={this.handleCopyConfirmationCode}
                    handleSendConfirmationEmail={this.handleSendConfirmationEmail}
                    handleOpenAddressWithGoogleMaps={this.handleOpenAddressWithGoogleMaps}
                    handleCallPhonenumber={this.handleCallPhonenumber}
                    handleSendEmail={this.handleSendEmail}
                    handleUpdateBooking={this.handleUpdateBooking}
                    handleCancelBooking={this.handleCancelBooking}
                    handleContactUs={this.handleContactUs}
                /> }
                
                {this.state.notification &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.state.notification} /> }
                
                { (!this.props.user || this.props.user.loading || (this.props.hotelBooking && (this.props.hotelBooking.loading || this.props.hotelBooking.cancelling)))
                && <FullScreenSpinner /> }
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get CSRF status
    const isCsrfReady = (state.auth && state.auth.isCsrfReady) ? state.auth.isCsrfReady : null;

    // get user id from cookie
    let userId = false;
    let guestUserId = null;
    if(state.auth && state.auth.cookie) {
        if(state.auth.cookie.id) {
            userId = state.auth.cookie.id;
        }

        if(state.auth.cookie.guestUserId && state.auth.cookie.guestUserCreatedAt) {
            guestUserId = state.auth.cookie.guestUserId;
        }
    }

    // get data from app reducer state
    const { user, hotelBooking } = state;

    // export state data to props
    return { isCsrfReady, userId, guestUserId, user, hotelBooking };
}

const actionCreators = {
    loadCsrfToken: authActions.loadCsrfToken,
    loginGuestUser: authActions.loginGuestUser,
    getById: userActions.getById,
    getHotelBookingById: hotelBookingActions.getById,
    sendBookingConfirmationEmail: hotelBookingActions.sendBookingConfirmationEmail,
    cancelBooking: hotelBookingActions.cancel,
    resetHotelBookings: hotelBookingActions.clear,
    clearAlert: alertActions.clear,
}

const connectedBookingDetailsContainer = connect(mapState, actionCreators)(BookingDetailsContainer);
export { connectedBookingDetailsContainer as BookingDetailsContainer };