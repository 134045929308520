import React, { Component } from "react";
import { connect } from 'react-redux';

import { userActions, alertActions, subscriptionActions } from '../../../actions';
import { ScreenContainer } from './UpgradeContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { Header } from '../../../components/custom/TopBar';
import { Notification } from '../../../components/custom/Popup';
import { UpgradePage } from "../../../components/upgrade";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { sitemap } from "../../../constants";
import { history, defaultUser } from '../../../utils';


class UpgradeContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            fromUrl: sitemap.admin.dashboard,
            scrollToSection: '', // used to scroll to particular section
            isMonthly: true,
            user: defaultUser()
        };

        this.handleScrollReset = this.handleScrollReset.bind(this);
        this.handleUpgrade = this.handleUpgrade.bind(this);
        this.handleChangeMonthly = this.handleChangeMonthly.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        this.props.getById(this.props.userId); // get user data from db
        this.props.getPlans(); // get plan pricing details from server
        window.addEventListener('scroll', this.handleScroll);
        if(this.props.location.state) {
            let initialState = {};
            if(this.props.location.state.from) initialState = { ...this.state, ...initialState, fromUrl: this.props.location.state.from }; // set from URL
            // get data from previous page
            if(this.props.location.state.data) {
                const { scrollToSection } = this.props.location.state.data;
                // set section to scroll to
                if(scrollToSection) initialState = { ...this.state, ...initialState, scrollToSection };
            }
            // set initial settings page state
            this.setState(initialState);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        // load user data from server into view
        if (!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access
            if(!this.props.user.creator) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            this.setState({ ...this.state, init: true, user: this.props.user });
        }
    }

    handleScrollReset(){
        if(this.state.init && this.state.scrollToSection) {
            this.setState({ ...this.state, scrollToSection: '' });
        }
    }

    handleChangeMonthly(value){
        this.setState({ ...this.state, isMonthly: value });
    }

    handleUpgrade(plan) {
        // open checkout screen
        if(plan && plan.length > 0) {
            if(plan === 'Free') {
                // got to settings
                history.push({
                    pathname: sitemap.admin.settings,
                    state: {
                        from: sitemap.billing.upgrade,
                        data: {
                            scrollToSection: 'subscription',
                        }
                    }
                });
            } else {
                history.push({
                    pathname: sitemap.billing.checkout,
                    state: {
                        from: sitemap.billing.upgrade,
                        data: {
                            plan: plan.toLowerCase(),
                            isMonthly: this.state.isMonthly,
                        }
                    }
                });
            }
        }
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title='Plans' />

                <Header
                    border={true}
                    showLogo={true}
                    secondaryButtonText='Go to Dashboard'
                    secondaryButtonLinkTo={this.state.fromUrl}
                />

                {this.state.init &&
                <UpgradePage
                    handleUpgrade={this.handleUpgrade}
                    handleChangeMonthly={this.handleChangeMonthly}
                    basic={this.state.user.basic}
                    premium={this.state.user.premium}
                    platinum={this.state.user.platinum}
                    isMonthly={this.state.isMonthly}
                    pricing={this.props.plans} /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }
                
                {(!this.props.user || this.props.user.loading || this.props.loadingPlans) && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user } = state;
    const { loadingPlans, plans } = state.subscription;

    // export state data to props
    return { userId, alert, user, loadingPlans, plans };
}

const actionCreators = {
    getById: userActions.getById,
    clearAlert: alertActions.clear,
    getPlans: subscriptionActions.getPlans,
}

const connectedUpgradeContainer = connect(mapState, actionCreators)(UpgradeContainer);
export { connectedUpgradeContainer as UpgradeContainer };