import styled from 'styled-components';
import { ReactComponent as IconGradientSvg } from "../../../assets/branding/icon_gradient.svg";
import { ReactComponent as IconBlackSvg } from "../../../assets/branding/icon_black.svg";
import { urlConstants } from '../../../constants';


const Container = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

const Url = styled.a`
    margin: 0;
    padding: 0;
`;

const StyledIconGradient = styled(IconGradientSvg)`
    height: auto;
    min-width: 130px;
`;

const StyledIconBlack = styled(IconBlackSvg)`
    height: auto;
    min-width: 130px;

    // Use this attribute to change color
    g {
        fill: ${props => props.setcolor ? props.setcolor : '#181818'};
    }
`;


export const Icon = (props) => {
    const { noRedirect, gradient, color, ...rest } = props;

    const icon = gradient ? <StyledIconGradient  {...rest} /> : <StyledIconBlack setcolor={color}  {...rest} />;

    return (
        <Container>
            {!noRedirect && <Url href={urlConstants.baseUrlClient}>{icon}</Url>}
            {noRedirect && icon}
        </Container>
    );
};