import styled from 'styled-components';
import { deviceConstants, colorConstants } from '../../constants';


export const ScreenContainer = styled.div`
    min-height: 100vh;
    padding: 64px 16px 24px 16px; /* padding-top: 64px => 56px topbar + 8px spacing */

    @media ${deviceConstants.tablet} { 
        padding-left: 33%;
        padding-right: 33%;
        
        padding-top: 88px;
        background: ${colorConstants.verySoftGrey};
    }

    @media ${deviceConstants.laptop} { 
        padding-left: 35%;
        padding-right: 35%;
    }

    @media ${deviceConstants.desktop} { 
        padding-left: 37.5%;
        padding-right: 37.5%;
    }
`;