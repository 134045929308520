// Manages the public users section of the application state used to list users and user info
import { publicUsersReducerConstants } from '../constants';

export function publicUsers(state = { users: [] }, action) {

  switch (action.type) {

    case publicUsersReducerConstants.GETALL_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicUsersReducerConstants.GETALL_SUCCESS:
        return {
            users: action.users
        };

    case publicUsersReducerConstants.GETALL_FAILURE:
        return {
            users: [],
            error: action.error
        };

    case publicUsersReducerConstants.GETBYUSERNAME_REQUEST: {
        const { usernameNotFound, ...stateCopy } = state;
        return {
            ...stateCopy,
            loading: true
        };
    }
        
    
    case publicUsersReducerConstants.GETBYUSERNAME_SUCCESS:
        return {
            users: [action.user]
        };

    case publicUsersReducerConstants.GETBYUSERNAME_FAILURE: {
        const { loading, usernameNotFound, ...stateCopy } = state;
        const newState = { ...stateCopy, error: action.error };
        if(action.error === 'Username Not Found') return { ...newState, usernameNotFound: true };
        return newState;
    }

    case publicUsersReducerConstants.FILTER_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicUsersReducerConstants.FILTER_SUCCESS:
        return {
            users: action.users
        };

    case publicUsersReducerConstants.FILTER_FAILURE: {
        let { loading, ...stateCopy } = state; // remove loading flag
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case publicUsersReducerConstants.USERNAME_AVAILABILITY_REQUEST:
        return {
            ...state,
            usernameAvailabilityCheck: true
        };

    case publicUsersReducerConstants.USERNAME_AVAILABILITY_SUCCESS:
        let { usernameAvailabilityCheck, ...stateCopy } = state; // remove usernameAvailabilityCheck flag
        return {
            ...stateCopy,
            usernameCheckResult: action.username
        };

    case publicUsersReducerConstants.USERNAME_AVAILABILITY_FAILURE: {
        let { usernameAvailabilityCheck, ...stateCopy } = state; // remove usernameAvailabilityCheck flag
        return {
            ...stateCopy,
            usernameCheckError: action.error
        };
    }

    case publicUsersReducerConstants.RESET_ALL: {
        return { users: [] }
    }

    default:
        return state
  }
}