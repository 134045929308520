/**
 * Package dependencies: npm i react-colorful colord
 * - https://www.npmjs.com/package/colord
 * - https://www.npmjs.com/package/react-colorful
 * 
 * Other packages:
 * - https://www.npmjs.com/package/react-color-gradient-picker
 */
import React, { useState } from 'react';
import { HexColorPicker } from "react-colorful";
// import { colord } from "colord";
import {
    Container,
    Fader,
} from './ColorPicker.styles';



export const ColorPicker = (props) => {
    const { savedColor, handleColorPick, handleHide } = props;
    // component state
    const [color, setColor] = useState(savedColor || '#C2C2C2');

    function colorPick(value) {
        setColor(value);
        if(handleColorPick) handleColorPick(value);
    }

    return (
        <Container>
            <Fader newColor={color} onClick={handleHide}/>
            <HexColorPicker color={color} onChange={colorPick} />
        </Container>
    );
};