import { paymentMethodReducerConstants } from '../constants';


export function paymentMethod(state = {}, action) {

  switch (action.type) {
    case paymentMethodReducerConstants.GETALL_REQUEST:
        return { loading: true };
    
    case paymentMethodReducerConstants.GETALL_SUCCESS:
        return { list: action.paymentMethods };

    case paymentMethodReducerConstants.GETALL_FAILURE:
        return { list: [], error: action.error };


    case paymentMethodReducerConstants.UPDATE_REQUEST: {
        return { ...state, updating: true };
    }

    case paymentMethodReducerConstants.UPDATE_SUCCESS: {
        const updatedList = state.list.map((item) => item.id === action.paymentMethod.id ? action.paymentMethod : item);
        return { list: updatedList };
    }

    case paymentMethodReducerConstants.UPDATE_FAILURE: {
        const { updating, ...rest } = state;
        return { ...rest, error: action.error };
    }
    

    case paymentMethodReducerConstants.CLEAR_ALL:
        return {};

    default:
        return state
  }
}