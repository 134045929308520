import axios from "axios";
import { urlConstants } from '../constants';


export const http = axios.create({
    baseURL: urlConstants.baseUrlServer,
    withCredentials: true,
    headers: {
        "Content-type": "application/json"
    }
});