import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Funnel, ArrowDownUp, ArrowDown, ArrowUp, Map } from 'react-bootstrap-icons';
import { CountdownTimer } from '../custom/Time';

import { deviceConstants, colorConstants } from "../../constants";



const Container = styled.div`
    ${({ sticky }) => sticky && `
        background: ${colorConstants.white};
        padding: 0 12px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    `};

    z-index: 300;
`;

const NavBar = styled.div`
    position: relative;

    ${({ sticky }) => sticky && `
        @media ${deviceConstants.tablet} { 
            max-width: 100%;
        }

        @media ${deviceConstants.laptop} { 
            padding-left: 35%;
            padding-right: 35%;
        }

        @media ${deviceConstants.desktop} { 
            padding-left: 37.5%;
            padding-right: 37.5%;
        }
    `};
`;

const MessageSection = styled.div`
    width: 100%;
    padding: 16px 0 8px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const MessageLeftColumn = styled.div`
    font-weight: 500;
    font-size: 11px;
    color: ${colorConstants.mediumGrey};
`;

const MessageRightColumn = styled.div`
    font-weight: 500;
    font-size: 11px;
    color: ${colorConstants.mediumGrey};
`;

const HighlightedText = styled.span`
    background: ${colorConstants.gradientForText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const MenuSection = styled.div`
    padding: 8px 0 16px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const MenuItem = styled.div`
        padding: 8px 24px;
        border: 1px solid ${colorConstants.mediumGrey};
        border-radius: 500px;

        color: ${colorConstants.black};

        display: flex;
        align-items: center;
        justify-content: center;

        ${({ disabled }) => disabled && `
            color: ${colorConstants.softGrey2};
            border-color: ${colorConstants.softGrey2};
        `};

        :hover {
            ${({ disabled }) => !disabled && `
                cursor: pointer;
                background: ${colorConstants.gradientHorizontal};
                color: ${colorConstants.white};
                border: none;

                .search-menu-item {
                    color: ${colorConstants.white};
                }
            `};
        }

        & > * {
            margin-right: 8px;
        }

        & > *:last-child {
            margin-right: 0px;
        }
`;

const MenuItemText = styled.div`
    font-weight: 600;
    font-size: 14px;    
`;



export const SearchPageToolBar = ({ sticky, type, createdAt, ttl, resultsCount, offersCount, isSearchProcessingComplete, handleSearchExpired, handleFilter, handleSort, handleMap, ...rest }) => {

    const [sortIcon, setSortIcon] = useState(0);

    const formattedType = useMemo(() => type === 'ACCOMMODATION' ? 'hotels' : 'experiences', [type]);

    const formattedOffersCount = useMemo(() => {
        if(!offersCount || offersCount === 0) return null;
        return `(${offersCount}${!isSearchProcessingComplete ? '+' : ''} discounted)`;
    }, [offersCount, isSearchProcessingComplete]);

    const onSortClick = useCallback(() => {
        let num = sortIcon;
        if(num === 2) {
            num = 0;
        } else {
            num++;
        }

        setSortIcon(num);
        if(handleSort) handleSort(num);

    }, [handleSort, sortIcon]);

    return (
        <Container sticky={sticky} {...rest} >
            <NavBar sticky={sticky} >
                <MessageSection>
                    { resultsCount && <MessageLeftColumn>{resultsCount} {formattedType} {formattedOffersCount && <HighlightedText>{formattedOffersCount}</HighlightedText>}</MessageLeftColumn> }
                    { createdAt && ttl &&
                    <MessageRightColumn>
                        <CountdownTimer
                            targetDate={createdAt}
                            minutes={ttl}
                            handleExpired={handleSearchExpired}
                            label='Search expires in '
                            color={colorConstants.mediumGrey}
                            warnOnComplete={true}
                            size={11} />
                    </MessageRightColumn> }
                </MessageSection>

                { resultsCount && resultsCount > 0 &&
                <MenuSection>
                    { handleFilter &&
                    <MenuItem onClick={() => handleFilter()}>
                        <Funnel className='search-menu-item' size={14} />
                        <MenuItemText>Filter</MenuItemText>
                    </MenuItem> }

                    { handleSort &&
                    <MenuItem onClick={() => onSortClick()}>
                        { sortIcon === 0 && <ArrowDownUp className='search-menu-item' size={14} /> }
                        { sortIcon === 1 && <ArrowDown className='search-menu-item' size={14} /> }
                        { sortIcon === 2 && <ArrowUp className='search-menu-item' size={14} /> }
                        <MenuItemText>Sort</MenuItemText>
                    </MenuItem> }

                    { handleMap &&
                    <MenuItem onClick={() => handleMap()}>
                        <Map className='search-menu-item' size={14} />
                        <MenuItemText>Map</MenuItemText>
                    </MenuItem> }
                </MenuSection> }
            </NavBar>
        </Container>
    );
};