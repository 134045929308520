// https://firebase.google.com/docs/auth/web/password-auth
// https://firebase.google.com/docs/auth/web/google-signin
// https://firebase.google.com/docs/auth/web/auth-state-persistence
import { 
    getAuth,
    // setPersistence,
    // inMemoryPersistence,
    signInWithEmailAndPassword,
    signInWithRedirect,
    getRedirectResult,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    GithubAuthProvider,
    signOut
} from "firebase/auth";


// initialize Firebase Authentication
const auth = getAuth();


/**
 * Method to sign in a user with email via Firebase Authentication
 * 
 * @param {JSON Object} credentials Object with username and password
 * @returns Either a success response object with user data or error object
 */
export const loginWithEmail = async (credentials) => {
    // // As httpOnly cookies are to be used, do not persist any state client side.
    // return setPersistence(auth, inMemoryPersistence)
    //     .then(() => signInWithEmailAndPassword(auth, credentials.email, credentials.password))
    //     .then(res => res.user);

    // login with local persistence for firebase storage security rules
    return signInWithEmailAndPassword(auth, credentials.email, credentials.password)
            .then(res => res.user);
};


/**
 * Method to login user with a selected auth provider through
 * firebase login with redirect or popup methods
 * 
 * @param {String} option The name of auth provider
 * @param {JSON Object} params Custom parameters to pass on to provider
 * @param {BOOLEAN} signinWithPopup Selects to sign in with a Popup window
 * @returns Sign in with social promise
 */
export const loginWithSocial = async (option, params, signinWithPopup) => {

    let provider = null;

    switch (option) {
        case 'google':
            provider = new GoogleAuthProvider();
            break;
        
        case 'facebook':
            provider = new FacebookAuthProvider();
            break;
        
        case 'github':
            provider = new GithubAuthProvider();
            break;

        default:
            provider = null;
            break;
    }

    // validate provider
    if(!provider) return null;

    // set custom parameters // FIXME: Figure out how to read custom parameters (ie. from="/confirm") after successfully logged in
    if(params) provider.setCustomParameters(params);
    
    // // As httpOnly cookies are to be used, do not persist any state client side.
    // return setPersistence(auth, inMemoryPersistence)
    //     .then(() => signInWithRedirect(auth, provider));

    // use login with popup method
    if(signinWithPopup) {
        const userCred = await signInWithPopup(auth, provider);
        return serializeUserCredentials(userCred);
    }

    // login with local persistence for firebase storage security rules
    return signInWithRedirect(auth, provider);
};


/**
 * Method to get firebase social login redirect results
 */
export const getSocialLoginResults = async () => {
    return getRedirectResult(auth)
        .then(res => serializeUserCredentials(res));
};


/**
 * Method to sign out user from Firebase Authentication
 * 
 * @returns Either an empty object on success or error
 */
export const logoutFirebaseAuth = async () => {
    return signOut(auth);
};


/**
 * Helper method to serialize user credentials
 * 
 * @param {JSON Object} res The user credentials object return by Firebase Auth
 * @returns JSON Object with serialized user credentials
 */
function serializeUserCredentials(res) {
    const isNewUser = res._tokenResponse.isNewUser; // true if redirecting from Register page and false if redirecting from Login page
    const { providerId } = res; // get provider id
    const name = res._tokenResponse.fullName; // set full name
    const firstName = res._tokenResponse.firstName; // get user first name
    const lastName = res._tokenResponse.lastName; // get user last name
    const email = res.user.email; // get user email
    const idToken = res._tokenResponse.idToken; // get idToken
    const uid = res.user.uid; // get user firebase id
    const photoURL = res.user.photoURL; // get user photo url
    return { isNewUser, providerId, name, firstName, lastName, email, idToken, uid, photoURL };   
}