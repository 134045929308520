import React from 'react';
import styled from 'styled-components';
import { List } from 'react-bootstrap-icons';
import { Icon } from '../custom/Branding';
import { Avatar } from '../custom/Avatar';

import { deviceConstants, colorConstants } from "../../constants";



const Container = styled.div`
    padding: 0 12px;
    background: ${colorConstants.white};

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    z-index: 300;
`;

const NavBar = styled.div`
    height: 56px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
    
    @media ${deviceConstants.tablet} { 
        max-width: 100%;
    }
`;

const IconsItemButton = styled.div`
    z-index: 302;

    display: flex;
    align-items: center;
`;

const StyledIcon = styled(Icon)`
    height: 25px !important;
    width: 25px !important;
    min-width: 25px !important;
`;

const Divider = styled.div`
    width: 1px;
    height: 30px;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 50px;
    background: ${colorConstants.black};
`;

const CreatorProfileSection = styled.div`    
    display: flex;
    align-items: center;

    :hover {
        cursor: pointer;
    }
`;

const CreatorName = styled.div`
    margin-left: 8px;

    font-weight: 600;
    font-size: 14px;
    color: ${colorConstants.darkGrey};

    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MenuItemButton = styled.div`
    z-index: 302;

    :hover {
        cursor: pointer;
    }
`;

const SubNavBar = styled.div`
    padding: 8px 0;
    color: ${colorConstants.mediumGrey};
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    border-top: 1px solid ${colorConstants.verySoftGreyVersion2};
    
    :hover {
        color: ${colorConstants.primaryDark};
        cursor: pointer;
    }
`;

export const SearchPageTopBar = ({ imageUrl, displayName, username, menuButton, backButton, sidebar, subButton, subTitle, ...rest }) => {

    return (
        <Container {...rest} >
            <NavBar>
                { backButton &&
                <IconsItemButton>
                    <StyledIcon gradient={true} />
                    { displayName && <Divider /> }
                    { displayName &&
                    <CreatorProfileSection onClick={backButton}>
                        <Avatar imageSize={26} url={imageUrl} cursor='pointer' />
                        <CreatorName>{displayName}</CreatorName>
                    </CreatorProfileSection> }
                </IconsItemButton> }

                { menuButton &&
                <MenuItemButton onClick={menuButton} >
                    <List color={colorConstants.black} size={24} />
                </MenuItemButton> }
            </NavBar>

            { subButton && subTitle &&
            <SubNavBar onClick={subButton} >
                {subTitle}
            </SubNavBar> }
        </Container>
    );
};