import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

import { Logo } from '../Branding';
import { deviceConstants, colorConstants } from "../../../constants";
import { Button } from '../Buttons';



const StyledNavbar = styled(Navbar)`
    height: 56px;
    z-index: 100;
    ${({ hasborder }) => hasborder &&`
        border-bottom: 1px solid ${colorConstants.verySoftGrey};
    `}

    @media ${deviceConstants.tablet} { 
        height: 80px;
        ${({ isdesktopfixed }) => isdesktopfixed && `
            width: 100%;
            position: absolute;
        `}
        ${({ hasbordermobile }) => hasbordermobile && `
            border-bottom: none;
        `}
    }
`;

const StyledContainer = styled(Container)`
    @media ${deviceConstants.tablet} {         
        ${({ fullscreen }) => fullscreen && `
            max-width: 100%;
        `}
    }
`;

const StyledLogo = styled(Logo)`
    height: 25px;
    width: 100px;
    min-width: 100px;

    @media ${deviceConstants.tablet} { 
        height: 50px;
        width: 150px;
        min-width: 150px;
    }
`;

const MenuContainer = styled(Navbar.Collapse)`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
`;

const PrimaryButton = styled(Button)`
    padding-left: 24px;
    padding-right: 24px;
`;

const PrimaryButtonLink = styled(Link)`
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
    color: ${colorConstants.white};

    :hover {
        color: ${colorConstants.white};
    }
`;

const MenuItem = styled(Link)`
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
    color: ${colorConstants.darkGrey};

    margin-right: 16px;

    @media ${deviceConstants.tablet} { 
        margin-right: 40px;
    }

    :hover {
        color: ${colorConstants.primaryDark};
    }
`;

const SecondaryButton = styled(Link)`
    font-style: normal;
    font-size: 15px;
    color: ${colorConstants.darkGrey};

    :hover {
        color: ${colorConstants.primaryDark};
    }
`;

const SecondaryButtonLabel = styled.span`
    font-size: 12px;
    margin-right: 4px;
`;

export const Header = (props) => {
    const {
        desktopFixed,
        fullScreen,
        border,
        onlyMobileBorder,
        showLogo,
        logoLinkTo,
        primaryButtonText,
        primaryButtonLinkTo,
        primaryButtonHandleClick,
        primaryButtonGradientStyle,
        secondaryButtonText,
        secondaryButtonLinkTo,
        secondaryButtonLabel,
        buttons,
    } = props;


    // logo
    let logo = null;
    if(showLogo) {
        logo = <StyledLogo noRedirect={true} gradient={true} className="logo" />;
        if(logoLinkTo) logo = <Link to={logoLinkTo}>{logo}</Link>;
    }

    // primary button
    let primaryButton = null;
    if(primaryButtonText) {
        if(primaryButtonLinkTo) {
            primaryButton = (
                <PrimaryButton gradient={primaryButtonGradientStyle} small={true} className="btn-secondary">
                    <PrimaryButtonLink to={primaryButtonLinkTo} >{primaryButtonText}</PrimaryButtonLink>
                </PrimaryButton>
            );
            
        } else if(primaryButtonHandleClick) {
            primaryButton = (
                <PrimaryButton gradient={primaryButtonGradientStyle} small={true} className="btn-secondary" onClick={primaryButtonHandleClick}>
                    {primaryButtonText}
                </PrimaryButton>
            );
        }
    }

    // secondary button
    let secondaryButton = null;
    if(secondaryButtonText) {
        if(secondaryButtonLinkTo && !primaryButtonText) {
            secondaryButton = (
                <div>
                    {secondaryButtonLabel && <SecondaryButtonLabel>{secondaryButtonLabel}</SecondaryButtonLabel> }
                    <SecondaryButton to={secondaryButtonLinkTo} >{secondaryButtonText}</SecondaryButton>
                </div>
            );
        }
    }

    // menu items
    let menuItems = null;
    if(buttons) menuItems = buttons.map((item, index) => <MenuItem key={index} to={item.link}>{item.text}</MenuItem>);

    return (
        <StyledNavbar
            isdesktopfixed={desktopFixed ? 'enabled' : null}
            hasborder={border ? 'enable' : null}
            hasbordermobile={onlyMobileBorder ? 'enable' : null} >
            <StyledContainer fullscreen={fullScreen ? 'enable' : null} >
                {logo}
                <MenuContainer>
                    {menuItems}
                    {secondaryButton}
                    {primaryButton}
                </MenuContainer>
            </StyledContainer>
        </StyledNavbar>
    );
};