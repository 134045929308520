import ReactHtmlParser from 'react-html-parser';

import {
    Container, ContainerFader, FirstColumn, SecondColumn, Image,
    ContentSection, Name, Description,
} from './ProductLink.styles';
import { colorConstants } from "../../constants";


export const ProductLink = (props) => {
    const { data, handleOpen, customizations, ...rest } = props;
    let { buttons, fonts } = customizations;

    // defaults
    if(buttons.color === '') buttons.color = colorConstants.softBlack;
    if(buttons.fontColor === '') buttons.fontColor = colorConstants.white;

    // set button shape
    const buttonShape = buttons.shape === 'OVAL' ? '500px' : buttons.shape === 'ROUNDED' ? '8px' : '0px';

    // set button color
    const buttonColor = buttons.style === 'OUTLINE' ? 'transparent' : buttons.color;
    const buttonColorHover = buttons.style === 'OUTLINE' ? buttons.color : 'transparent';

    // set button font color
    const fontColor = buttons.style === 'OUTLINE' ? buttons.color : buttons.fontColor;
    const fontColorHover = buttons.style === 'OUTLINE' ? buttons.fontColor : buttons.color;

    return (
        <Container
            shape={buttonShape}
            borderColor={buttons.color}
            color={buttonColor}
            colorHover={buttonColorHover}
            fontColor={fontColor}
            fontColorHover={fontColorHover}
            onClick={() => handleOpen(data)} {...rest}>

            <ContainerFader className='fader' />

            <FirstColumn>
                { data.imageUrls && data.imageUrls.length > 0 && <Image className='product-image' src={data.imageUrls[0]} /> }
            </FirstColumn>

            <SecondColumn className='content-box' fontType={fonts.type} >
                <ContentSection>
                    <Name>{data.name}</Name>
                    <Description>{ReactHtmlParser(data.description)}</Description>
                </ContentSection>
            </SecondColumn>
        </Container>
    );
};