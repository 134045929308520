/**
 * Method to generate default payment method JSON Object
 * 
 * @returns JSON Object with default paymentt method values
 */
 export function defaultPaymentMethod(){
    return {
        paymentMethodId: null,
        paymentType: '',
        holderName: '',
        number: '',
        last4: '',
        expMonth: null,
        expYear: null,
        brand: '',
        default: false,
        validated: false,
    };
}