import React from 'react';
import { sitemap, otherConstants } from '../../constants';

import {
    Container,
    IconWrapper,
    StyledIcon,
    Header,
    SubHeader,
    StyledLink
} from './ProfileNotFoundPage.styles';


const ProfileNotFoundPage = (props) => {

    return (
        <Container>
            <IconWrapper>
                <StyledIcon gradient={true} />
            </IconWrapper>
            <Header>The link you're looking for doesn't exist.</Header>
            <SubHeader>Want to secure this username? <StyledLink to={sitemap.auth.register} >Get your {otherConstants.projectName} creator account now.</StyledLink></SubHeader>
        </Container>
    );
};

export default ProfileNotFoundPage;