import React from 'react';
import styled from 'styled-components';

import { colorConstants } from "../../../constants";


const Container = styled.div`
    padding: 1px;
    margin: 0;

    background: ${colorConstants.gradientHorizontal};

    border: none;
    border-radius: 4px;
`;

const Content = styled.div`
    width: 100%;

    padding: 8px 16px;
    margin: 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ highlight }) => highlight ? colorConstants.gradientHorizontal : colorConstants.white};
    color: ${({ highlight }) => highlight ? colorConstants.white : colorConstants.softGrey};

    border: none;
    border-radius: 4px;

    font-size: 15px;
    font-weight: 600;

    :hover {
        background: ${colorConstants.white};
        color: ${({ highlight }) => highlight ? colorConstants.black : colorConstants.softGrey};
        cursor: ${({ highlight }) => highlight ? 'pointer' : 'auto'};

        .icon {
            fill: ${colorConstants.black};
        }
    }
`;

const Text = styled.div`
    
`;



export const BookingSearchButton = ({ label, onClick, highlight }) => {
    return (
        <Container onClick={highlight ? onClick : null}>
            <Content highlight={highlight} >
                <Text>{label}</Text>
            </Content>
        </Container>
    );
};