import {
    Container,
    TopSection,
    BottomSection,
    Title,
    Text
} from './NotificationCard.styles';


export const NotificationCard = (props) => {
    const { imgUrl, title, text, onClick } = props;

    return (
        <Container imgUrl={imgUrl} onClick={onClick} cursor={onClick ? 'pointer' : null} className='notification-card' >
            <TopSection>
                <Title>{title}</Title>
            </TopSection>

            <BottomSection>
                <Text>{text}</Text>
            </BottomSection>
        </Container>
    );
};