import React, { Component } from "react";
import { connect } from 'react-redux';

import ProfilePage from '../../components/profile/ProfilePage';
import ProfileNotFoundPage from '../../components/profile/ProfileNotFoundPage';
import { FullScreenSpinner } from "../../components/custom/Spinner";
import { GradientStripeButton } from '../../components/custom/Buttons';
import { MetaTags } from "../../components/custom/Helmet";
import { authActions, publicUsersActions, publicTripsActions, publicLinksActions, publicProductsActions, nftPublicActions, publicOverviewActions } from '../../actions';
import { history, defaultUser, getOpenseaUrl } from '../../utils';
import { ScreenContainer, BackgroundFader } from './ProfileContainer.styles';
import { sitemap, getProfileLink, getTripLink, getSearchLink, getProductLink, getNFTLink } from '../../constants';


class ProfileContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            fromUrl: sitemap.admin.dashboard,
            profile: defaultUser()
        };

        this.handleOpenLink = this.handleOpenLink.bind(this);
        this.handleViewTrip = this.handleViewTrip.bind(this);
        this.handleViewProduct = this.handleViewProduct.bind(this);
        this.handleViewNFT = this.handleViewNFT.bind(this);

        this.handleFilter = this.handleFilter.bind(this);
    }

    componentDidMount() {
        this.props.getByUsername(this.props.match.params.username); // get public profile data
        this.props.loadCsrfToken(); // load csrf token in cookies
        this.props.resetTrips(); // reset any previous trip data
        this.props.resetLinks(); // reset any previous link data
        this.props.resetProducts(); // reset any previous product data
        this.props.resetNFTs(); // reset any previous NFT data
        this.props.resetOverview(); // reset any previous overview data
        if(this.props.location.state && this.props.location.state.from) this.setState({ ...this.state, fromUrl: this.props.location.state.from }); // set from URL
    }

    componentDidUpdate() {
        if(!this.state.init && this.props.user && this.props.user.id){
            this.setState({
                ...this.state,
                init: true,
                profile: this.props.user
            });

            // get links based on user options
            const { defaultLinksView } = this.props.user.pageOptions;
            if(defaultLinksView === 'SIMPLE' || defaultLinksView === 'BLOG' || defaultLinksView === 'GITHUB'){
                this.props.getLinks(new URLSearchParams({ username: this.props.match.params.username, type: defaultLinksView }));
            } else if(defaultLinksView === 'ACCOMMODATION' || defaultLinksView === 'EXPERIENCE'){
                this.props.getTrips(new URLSearchParams({ username: this.props.match.params.username, type: defaultLinksView }));
            } else if(defaultLinksView === 'PHYSICAL' || defaultLinksView === 'DIGITAL'){
                this.props.getProducts(new URLSearchParams({ username: this.props.match.params.username, type: defaultLinksView }));
            } else if(defaultLinksView === 'OVERVIEW'){
                this.props.getOverview(new URLSearchParams({ username: this.props.match.params.username }));
            } else if(defaultLinksView === 'NFT'){
                this.props.getNFTs(new URLSearchParams({ username: this.props.match.params.username }));
            }
        }
    }

    componentWillUnmount(){
        // reset any public data when leaving this page
        this.props.resetUsers();
        this.props.resetTrips();
        this.props.resetLinks();
        this.props.resetProducts();
        this.props.resetNFTs();
        this.props.resetOverview();
    }

    handleOpenLink(url){
        // open link in new tab
        window.open(url);
    }

    handleViewTrip(trip){
        // open trip search results screen
        if(trip.isSearching && trip.search) {
            history.push({
                pathname: getSearchLink(this.state.profile.username),
                state: {
                    from: getProfileLink(this.state.profile.username),
                    data: {
                        creator: this.state.profile,
                        search: trip.search,
                        type: trip.type,
                    }
                }
            });
            return;
        }
        
        // skip details page
        if(trip.skipDetailsPage) {
            // open custom link if set
            if(trip.button && trip.button.url) {
                window.open(trip.button.url);
                return;
            }

            // search trip on google
            window.open('https://www.google.com/search?q=' + trip.name);
            return;
        }

        // open trip details screen
        if(!this.props.authenticated) {
            // open in new tab
            window.open(getTripLink(this.state.profile.username, trip.customUrl));
        } else {
            history.push({
                pathname: getTripLink(this.state.profile.username, trip.customUrl),
                state: {
                    from: getProfileLink(this.state.profile.username),
                    data: {
                        tripId: trip.id,
                        profileTransitionAnimation: true,
                    }
                }
            });
        }
    }

    handleViewProduct(product){
        // skip details page
        if(product.skipDetailsPage) {
            // open custom link if set
            if(product.button && product.button.url) {
                window.open(product.button.url);
                return;
            }

            // search product on google
            window.open('https://www.google.com/search?q=' + product.name);
            return;
        }

        // open product details screen
        if(!this.props.authenticated) {
            // open in new tab
            window.open(getProductLink(this.state.profile.username, product.customUrl));
        } else {
            history.push({
                pathname: getProductLink(this.state.profile.username, product.customUrl),
                state: {
                    from: getProfileLink(this.state.profile.username),
                    data: {
                        productId: product.id,
                        profileTransitionAnimation: true,
                    }
                }
            });
        }
    }

    handleViewNFT(nft){
        // skip details page
        if(nft.skipDetailsPage) {
            // open custom link if set
            if(nft.button && nft.button.url) {
                window.open(nft.button.url);
                return;
            }

            // open Opensea link
            window.open(getOpenseaUrl(nft.contract, nft.chainTokenId, nft.blockchain));
            return;
        }

        // open NFT details screen
        if(!this.props.authenticated) {
            // open in new tab
            window.open(getNFTLink(this.state.profile.username, nft.customUrl));
        } else {
            history.push({
                pathname: getNFTLink(this.state.profile.username, nft.customUrl),
                state: {
                    from: getProfileLink(this.state.profile.username),
                    data: {
                        nftId: nft.id,
                        profileTransitionAnimation: true,
                    }
                }
            });
        }
    }

    handleFilter(option) {
        switch (option) {

            case 99: {
                // get all links for overview page
                this.props.resetLinks();
                this.props.resetTrips();
                this.props.resetProducts();
                this.props.resetNFTs();
                this.props.getOverview(new URLSearchParams({ username: this.props.match.params.username }));
                return;
            }

            case 0: {
                // get simple links
                this.props.getLinks(new URLSearchParams({ username: this.props.match.params.username, type: 'SIMPLE' }));
                this.props.resetTrips();
                this.props.resetProducts();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            case 1: {
                // get stays
                this.props.getTrips(new URLSearchParams({ username: this.props.match.params.username, type: 'ACCOMMODATION' }));
                this.props.resetLinks();
                this.props.resetProducts();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            case 2: {
                // get experiences
                this.props.getTrips(new URLSearchParams({ username: this.props.match.params.username, type: 'EXPERIENCE' }));
                this.props.resetLinks();
                this.props.resetProducts();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            case 3: {
                // get blogs
                this.props.getLinks(new URLSearchParams({ username: this.props.match.params.username, type: 'BLOG' }));
                this.props.resetTrips();
                this.props.resetProducts();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            case 4: {
                // get github
                this.props.getLinks(new URLSearchParams({ username: this.props.match.params.username, type: 'GITHUB' }));
                this.props.resetTrips();
                this.props.resetProducts();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            case 5: {
                // get physical products
                this.props.getProducts(new URLSearchParams({ username: this.props.match.params.username, type: 'PHYSICAL' }));
                this.props.resetTrips();
                this.props.resetLinks();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            case 6: {
                // get digital products
                this.props.getProducts(new URLSearchParams({ username: this.props.match.params.username, type: 'DIGITAL' }));
                this.props.resetTrips();
                this.props.resetLinks();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            case 7: {
                // get NFTs
                this.props.getNFTs(new URLSearchParams({ username: this.props.match.params.username, type: 'NFT' }));
                this.props.resetTrips();
                this.props.resetLinks();
                this.props.resetProducts();
                this.props.resetOverview();
                return;
            }

            case 8: {
                // get youtube
                this.props.getLinks(new URLSearchParams({ username: this.props.match.params.username, type: 'YOUTUBE' }));
                this.props.resetTrips();
                this.props.resetProducts();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            case 9: {
                // get youtube shorts
                this.props.getLinks(new URLSearchParams({ username: this.props.match.params.username, type: 'YOUTUBESHORT' }));
                this.props.resetTrips();
                this.props.resetProducts();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            case 10: {
                // get instagram reels
                this.props.getLinks(new URLSearchParams({ username: this.props.match.params.username, type: 'INSTAGRAMREEL' }));
                this.props.resetTrips();
                this.props.resetProducts();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            case 11: {
                // get tiktok
                this.props.getLinks(new URLSearchParams({ username: this.props.match.params.username, type: 'TIKTOK' }));
                this.props.resetTrips();
                this.props.resetProducts();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            case 12: {
                // get spotify
                this.props.getLinks(new URLSearchParams({ username: this.props.match.params.username, type: 'SPOTIFY' }));
                this.props.resetTrips();
                this.props.resetProducts();
                this.props.resetNFTs();
                this.props.resetOverview();
                return;
            }

            default: {
                return;
            }
        }
    }

    render() {
        return (
            <ScreenContainer id='screen'
                options={{
                    color: this.state.profile.pageOptions.pageBackgroundColor,
                    gradient: this.state.profile.pageOptions.pageBackgroundGradient,
                    image: 'url("' + this.state.profile.pageOptions.pageBackgroundImage + '")',
                }}>

                { this.state.init && this.state.profile.pageOptions.pageBackgroundImage &&
                <BackgroundFader /> }

                {this.state.init &&
                <MetaTags
                    title={this.state.profile.displayName || this.state.profile.username}
                    description={this.state.profile.bio}
                    url={'/' + this.state.profile.username}
                    imageUrl={this.state.profile.profilePhotoUrl} /> }

                {this.props.authenticated && this.props.isOwner && this.state.init && <GradientStripeButton text='edit' link={this.state.fromUrl} />}

                { this.state.init && 
                <ProfilePage
                    profile={this.state.profile}
                    links={this.props.links}
                    trips={this.props.trips}
                    products={this.props.products}
                    nfts={this.props.nfts}
                    overview={this.props.overview}
                    handleOpenLink={this.handleOpenLink}
                    handleViewTrip={this.handleViewTrip}
                    handleViewProduct={this.handleViewProduct}
                    handleViewNFT={this.handleViewNFT}
                    handleFilter={this.handleFilter}
                /> }

                { !this.state.init &&  this.props.usernameNotFound && <ProfileNotFoundPage />}

                {(this.props.loadingUser || this.props.loadingTrips || this.props.loadingLinks || this.props.loadingProducts || this.props.loadingNFTs || this.props.loadingOverview) && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const authenticated = state.auth.cookie && state.auth.cookie.id ? true : false;
    const { publicUsers, publicTrips, publicLinks, publicProducts, nftPublic, publicOverview } = state;
    // get public user data
    const user = publicUsers.users[0];
    const loadingUser = publicUsers.loading;
    const usernameNotFound = publicUsers.usernameNotFound;
    // check if is profile owner
    const isOwner = authenticated && user && user.id === state.auth.cookie.id;
    // get public trips data
    const trips = publicTrips.trips;
    const loadingTrips = publicTrips.loading;
    // get public links data
    const links = publicLinks.links;
    const loadingLinks = publicLinks.loading;
    // get public product data
    const products = publicProducts.products;
    const loadingProducts = publicProducts.loading;
    // get public NFT data
    const nfts = nftPublic.nfts;
    const loadingNFTs = nftPublic.loading;
    // get overview data
    const overview = publicOverview.data;
    const loadingOverview = publicOverview.loading;

    // export state data to props
    return {
        authenticated, isOwner, user, loadingUser,
        usernameNotFound,
        trips, loadingTrips,
        links, loadingLinks,
        products, loadingProducts,
        nfts, loadingNFTs,
        overview, loadingOverview,
    };
}

const actionCreators = {
    loadCsrfToken: authActions.loadCsrfToken,
    getByUsername: publicUsersActions.getByUsername,
    resetUsers: publicUsersActions.reset,
    getTrips: publicTripsActions.filter,
    resetTrips: publicTripsActions.reset,
    getLinks: publicLinksActions.filter,
    resetLinks: publicLinksActions.reset,
    getProducts: publicProductsActions.filter,
    resetProducts: publicProductsActions.reset,
    getNFTs: nftPublicActions.filter,
    resetNFTs: nftPublicActions.reset,
    getOverview: publicOverviewActions.get,
    resetOverview: publicOverviewActions.reset,
}

const connectedProfileContainer = connect(mapState, actionCreators)(ProfileContainer);
export { connectedProfileContainer as ProfileContainer };