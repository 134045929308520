import { useMemo, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { googlePlacesActions, publicHotelActions } from '../../actions';
import { SearchAutocompleteResults } from '../custom/Search';
import { DatePicker } from '../custom/Popup';
import { NumberPicker } from '../custom/Buttons';
import { ChildrenAgesPopup } from '../trips/trip/ChildrenAgesPopup';
import {
    Container, ContainerFader, Title, Content,
    Button, SearchIcon, ButtonContent,
    ButtonSection, ButtonDivider, Text,
    WhereIcon, WhenIcon,
    StyledFormControl, ClearText, CloseIcon,
} from './SearchLink.styles';
import { colorConstants } from "../../constants";


export const SearchLink = ({
    data, hideTitle, fullScreen, noBorderWidget,
    handleSearch, onSuccess, onError, handleClear,
    customizations,
    ...rest
}) => {
    const dispatch = useDispatch();

    // application state
    const { loadingHotels, errorHotels, searchSession } = useSelector(state => ({
        loadingHotels: state.publicHotel.loading,
        searchSession: state.publicHotel.searchSession,
        errorHotels: state.publicHotel.error,
    }));

    // set default data
    if(!data) data = { type: 'ACCOMMODATION', config: {} };

    // set default customizations
    if(!customizations) {
        customizations = {
            buttons: { shape: '', style: '', color: '', fontColor: '' },
            fonts: { type: '', color: '' },
            bottomSocial: false,
            pageThemeDark: false
        };
    }

    // get customization props
    let { buttons, fonts } = customizations;

    // set state vars
    const [expanded, setExpanded] = useState(false);
    const [searching, setSearching] = useState(false);
    const [location, setLocation] = useState('');
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [locationSuggestions, setLocationSuggestions] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [dates, setDates] = useState({});
    const [isReset, setIsReset] = useState(false);
    const [occupancyTotalMax, setOccupancyTotalMax] = useState(16);
    const [adults, setAdults] = useState(1);
    const [adultsMax, setAdultsMax] = useState(16);
    const [children, setChildren] = useState(0);
    const [childrenMax, setChildrenMax] = useState(15);
    const [childrenAges, setChildrenAges] = useState([]);
    const [showChildrenAges, setShowChildrenAges] = useState(false);
    const [infants, setInfants] = useState(0);
    const [loadedPreviousConfigs, setLoadedPreviousConfigs] = useState(false);


    // defaults
    if(buttons.shape === '') buttons.shape = 'OVAL';
    if(buttons.style === '') buttons.style = 'FILL';
    if(buttons.color === '') buttons.color = colorConstants.verySoftGreyVersion5;
    if(buttons.fontColor === '') buttons.fontColor = colorConstants.darkGrey;

    // set button shape
    const isOval = useMemo(() => buttons.shape === 'OVAL', [buttons.shape]);
    const buttonShape = useMemo(() => isOval ? '24px' : buttons.shape === 'ROUNDED' ? '8px' : '0px', [isOval, buttons.shape]);

    // set button color
    const buttonColor = useMemo(() => buttons.style === 'OUTLINE' ? 'transparent' : buttons.color, [buttons.style, buttons.color]);
    const buttonColorHover = useMemo(() => buttons.style === 'OUTLINE' ? buttons.color : buttons.fontColor, [buttons.style, buttons.fontColor, buttons.color]);

    // set button font color
    const fontColor = useMemo(() => buttons.style === 'OUTLINE' ? buttons.color : buttons.fontColor, [buttons.style, buttons.color, buttons.fontColor]);
    const fontColorHover = useMemo(() => buttons.style === 'OUTLINE' ? buttons.fontColor : buttons.color, [buttons.style, buttons.fontColor, buttons.color]);

    // set search component trip type
    const isStays = useMemo(() => data.type === 'ACCOMMODATION', [data.type]);

    // set title
    const title = useMemo(() => {
        let label = (data && data.affiliate && data.affiliate.label) ? ` - ${data.affiliate.label}` : '';
        let output = (isStays ? 'Search Stays' : 'Search Experiences');
        return output + label;
    }, [isStays, data]);

    // set title padding
    const titlePadding = useMemo(() => {
        if(expanded) {
            if(isOval) {
                return `20px 28px 4px 28px`;
            }

            return `12px 16px 4px 16px`;
        } else {
            if(hideTitle) return '0px';
            
            if(isOval) {
                return '16px 28px 8px 28px';
            }

            return '8px 16px';
        }

    }, [expanded, isOval, hideTitle]);

    // set content padding
    const contentPadding = useMemo(() => {
        if(expanded) {
            if(isOval) {
                return '8px 28px 24px 28px';
            }

            return '8px 16px 16px 16px';
        } else {
            if(isOval) {
                if(hideTitle) return fullScreen ? '0px' : '16px 28px';

                return '8px 28px 16px 28px';
            }

            if(hideTitle) return fullScreen ? '0px' : '8px 16px';

            return '8px 16px';
        }

    }, [expanded, isOval, fullScreen, hideTitle]);

    // get selected date labels
    const fromDate = useMemo(() => dates && dates.from && dates.from.label, [dates]);
    const toDate = useMemo(() => dates && dates.to && dates.to.label, [dates]);

    // format date text
    const selectedDatesText = useMemo(() => `${fromDate || 'Check-in'} — ${toDate || 'Check-out'}`, [fromDate, toDate]);
    const selectedDatesTextShort = useMemo(() => fromDate && toDate ? `${fromDate.slice(4)} - ${toDate.slice(4)}` : null, [fromDate, toDate]);

    // set children ages text
    const childrenAgesText = useMemo(() => {
        if(children === 0 || !childrenAges || childrenAges.length === 0) return null;
        let output = '';
        if(childrenAges.length === 1) {
            output = `My child is ${childrenAges.toString()} years old.`;
        } else {
            let ages = [...childrenAges];
            let lastAge = ages.pop();
            output = `My children are ${ages.join(', ')} and ${lastAge} years old.`;
        }
        return output;
    }, [children, childrenAges]);


    /* Method to expand/collapse the component */

    const handleExpanded = useCallback((value) => {
        // execute before collapsing
        if(!value) {
            // clear suggested locations list
            setLocationSuggestions(null);

            // clear typed non-selected location
            if(!selectedLocation) setLocation('');
        }

        setExpanded(value);

    }, [selectedLocation]);


    /* Method to load location suggestions from Google API */

    const onLocationChange = useCallback(event => {
        event.preventDefault();

        // get keyword input
        const { value } = event.target;

        // save state of input value
        setLocation(value);

        // create getLocation() method to fetch data from API with delay
        const getLocation = async (keyword) => {
            // get suggestions from Google API
            let suggestions = await googlePlacesActions.getSuggestions(keyword, 'regions', true);
            // save location to state
            setLocationSuggestions(suggestions);
        };

        // set timer to call getLocation() method
        setTypingTimeout(setTimeout(() => { getLocation(value) }, 500));

        // clear timer
        if(typingTimeout) clearTimeout(typingTimeout);

        // clear selected location
        setSelectedLocation(null);

    }, [typingTimeout, setLocationSuggestions]);


    /* Method to save selected location */

    const onLocationSelectClick = useCallback((loc) => {
        // save location id
        setSelectedLocation(loc.place_id);

        // save location description
        setLocation(loc.description);

        // clear suggested locations list
        setLocationSuggestions(null);

    }, []);


    /* Method to save guest options */

    const onGuestsChange = useCallback((option, value, metadata) => {
        switch (option) {
            case 'adults': {
                // calculate new occupancyTotal value
                let occupancyTotal = value + children;
                // calculate new adult max value
                let adultsMax = (occupancyTotalMax - occupancyTotal) + value;
                // calculate new children max value
                let childrenMax = (occupancyTotalMax - occupancyTotal) + children;
                // update state values
                setAdults(value);
                setAdultsMax(adultsMax);
                setChildrenMax(childrenMax);
                break;
            }

            case 'children': {
                // calculate new occupancyTotal value
                let occupancyTotal = adults + value;
                // calculate new adult max value
                let adultsMax = (occupancyTotalMax - occupancyTotal) + adults;
                // calculate new children max value
                let childrenMax = (occupancyTotalMax - occupancyTotal) + value;
                // check if is increasing/decreasing number of children
                const isIncrease = value > children;
                const isDecreasing = value < children;
                // update state values
                setChildren(value);
                setAdultsMax(adultsMax);
                setChildrenMax(childrenMax);

                if(isIncrease) {
                    // add new default children age
                    let childrenAgesCopy = childrenAges;
                    childrenAgesCopy.push(10);
                    setChildrenAges(childrenAgesCopy);
                    setShowChildrenAges(true); // open children ages edit popup
                } else if(isDecreasing) {
                    if(metadata && metadata.children && metadata.children.hasOwnProperty('index')) {
                        // remove specific child age
                        let childrenAgesCopy = childrenAges;
                        childrenAgesCopy.splice(metadata.children.index, 1);
                        setChildrenAges(childrenAgesCopy);
                    } else {
                        // remove last added child age
                        let childrenAgesCopy = childrenAges;
                        childrenAgesCopy.pop();
                        setChildrenAges(childrenAgesCopy);
                    }
                    
                    // close if last child age or open for editing
                    if(value > 0) setShowChildrenAges(true);
                    else setShowChildrenAges(false);
                }
                break;
            }

            case 'infants': {
                setInfants(value);
                break;
            }

            default:
                break;
        }

    }, [adults, children, childrenAges, occupancyTotalMax]);


    /* Method to handle children age changes */

    const handleChildrenAgeChange = useCallback((index, value) => {
        let childrenAgesCopy = childrenAges;
        childrenAgesCopy[index] = value;
        setChildrenAges(childrenAgesCopy);
    }, [childrenAges]);


    /* Method to handle add new children age - default age 10 */

    const handleAddChildrenAge = useCallback(() => {
        onGuestsChange('children', children + 1);
    }, [onGuestsChange, children]);


    /* Method to handle delete specific children age */

    const handleDeleteChildrenAge = useCallback((index) => {
        if(index < 0) return;
        onGuestsChange('children', children - 1, { children: { index } });
    }, [onGuestsChange, children]);


    // enable/disable search button
    const isSearchEnabled = useMemo(() => selectedLocation && dates && dates.from && dates.to, [selectedLocation, dates]);


    /* Method to format options and call API */

    const onSearchClick = useCallback(() => {
        // check input
        if(!isSearchEnabled) return;

        // start searching
        setSearching(true);

        // collapse search component
        handleExpanded(false);

        // format search query
        let query = {
            location: selectedLocation,
            adults,
            children,
            infants,
            start: dates.from.date,
            startFormatted: dates.from.label,
            end: dates.to.date,
            endFormatted: dates.to.label,
            nights: dates.totalDays,
            rooms: 1,
        };

        // add children ages
        if(children > 0) query.childrenAges = childrenAges;

        // stop previous session if any
        if(searchSession && searchSession.session) query.previousSession = searchSession.session;

        // clear previous data
        dispatch(publicHotelActions.clear());

        // retrieve hotels from API
        dispatch(publicHotelActions.findAllByLocation(new URLSearchParams(query)));

        // generate search query data
        let searchData = {
            isSearching: true,
            type: data.type,
            search: {
                location, selectedLocation,
                dates,
                occupancyTotalMax,
                adults, adultsMax,
                children, childrenMax, childrenAges,
                infants,
            },
        };

        // send search data to parent component
        if(handleSearch) handleSearch(searchData);

    }, [handleSearch, isSearchEnabled, handleExpanded, data, location, selectedLocation, dates, occupancyTotalMax, adults, adultsMax, children, childrenMax, childrenAges, infants, dispatch, searchSession]);


    /* Method to reset all component data */

    const onResetClick = useCallback(() => {
        // init reset
        setIsReset(true);

        // clear location input state
        setLocation('');
        // clear suggested locations list
        setLocationSuggestions(null);
        // clear timer
        clearTimeout(typingTimeout);
        setTypingTimeout(0);
        // clear selected location
        setSelectedLocation('');
        // clear selected dates
        setDates({});
        setShowDatePicker(false);
        // clear guest options
        setOccupancyTotalMax(16);
        setAdults(1);
        setAdultsMax(16);
        setChildren(0);
        setChildrenMax(15);
        setChildrenAges([]);
        setShowChildrenAges(false);
        setInfants(0);

        // execute parent method callback
        if(handleClear) handleClear();

    }, [typingTimeout, handleClear]);


    const onResetCompleted = useCallback(() => {
        setIsReset(false);
    }, []);


    /* Handle search results */

    useEffect(() => {
        // search session started successfully
        if(searching && !loadingHotels && searchSession && searchSession.session && searchSession.isInit && !errorHotels) {
            setSearching(false);
            if(onSuccess) onSuccess(true);
        }

        // handle search errors
        if(searching && !loadingHotels && errorHotels) {
            setSearching(false);
            if(onError) onError(errorHotels);
        }

        // save previous configs
        if(!loadedPreviousConfigs && data && data.config && data.config.location && !location && (!dates || !dates.data)) {
            setLoadedPreviousConfigs(true);
            setLocation(data.config.location);
            setSelectedLocation(data.config.selectedLocation);
            setDates(data.config.dates);
            setOccupancyTotalMax(data.config.occupancyTotalMax);
            setAdults(data.config.adults);
            setAdultsMax(data.config.adultsMax);
            setChildren(data.config.children);
            setChildrenMax(data.config.childrenMax);
            setChildrenAges(data.config.childrenAges);
            setInfants(data.config.infants);
        }

    }, [searching, loadingHotels, searchSession, errorHotels, onSuccess, onError, data, loadedPreviousConfigs, location, dates]);


    return (
        <Container
            shape={buttonShape}
            borderColor={buttons.color}
            color={buttonColor}
            fontColor={fontColor}
            fontColorHover={fontColorHover}
            fontType={fonts.type}
            isOval={isOval}
            expanded={expanded ? 1 : null}
            hideTitle={hideTitle}
            fullScreen={fullScreen}
            titlePadding={titlePadding}
            contentPadding={contentPadding}
            {...rest} >

            <ContainerFader className='fader' />

            <Title className='title'>
                { hideTitle ? null : title }
                { expanded && <CloseIcon className='collapse-button' onClick={() => handleExpanded(false)} /> }
            </Title>

            <Content className='content'>
                <Button
                    expanded={expanded ? 1 : null}
                    noBorderWidget={noBorderWidget && !expanded}
                    shape={buttonShape}
                    fontColor={fontColor}
                    fontColorHover={fontColorHover}
                    buttonColorHover={buttonColorHover}
                    onClick={() => !expanded ? handleExpanded(true) : null} >
                    
                    { !expanded &&
                    <ButtonContent>
                        <ButtonSection>
                            { !location && <WhereIcon /> }
                            <Text>{location || (isStays ? 'Where to?' : 'To do in')}</Text>
                        </ButtonSection>
                        
                        <ButtonDivider className='button-divider'/>

                        <ButtonSection>
                            { !selectedDatesTextShort && <WhenIcon /> }
                            <Text>{selectedDatesTextShort || 'When?'}</Text>
                        </ButtonSection>
                    </ButtonContent> }

                    <SearchIcon expanded={expanded ? 1 : null} />

                    { expanded &&
                    <StyledFormControl
                        shape={buttonShape}
                        inputcolor={buttonColor}
                        fontcolor={fontColor}
                        autoFocus={true} // sets focus once visible
                        type="text"
                        placeholder="Search by location"
                        name="location"
                        value={location}
                        onChange={onLocationChange} /> }
                </Button>


                { locationSuggestions &&
                <SearchAutocompleteResults
                    items={locationSuggestions}
                    isLocation={true}
                    handleClick={onLocationSelectClick}
                    padding={isOval ? '32px' : '16px'}
                    topoffset="-4px"
                    isFullScreen={false}
                    shape={isOval ? '24px' : buttonShape}
                    color={buttons.fontColor}
                    colorHover={buttons.color}
                    fontColor={buttons.color}
                    fontColorHover={buttons.fontColor}
                /> }


                { expanded &&
                <Button
                    shape={buttonShape}
                    fontColor={fontColor}
                    fontColorHover={fontColorHover}
                    buttonColorHover={buttonColorHover}
                    onClick={showDatePicker ? null : () => setShowDatePicker(true)} >
                    <ButtonSection>
                        <WhenIcon />
                        <Text>{selectedDatesText}</Text>
                    </ButtonSection>
                </Button> }


                <DatePicker
                    show={showDatePicker}
                    dates={dates}
                    onHide={() => setShowDatePicker(false)}
                    showButton={true}
                    onDatePick={(dates) => setDates(dates)}
                    handleResetCompleted={onResetCompleted}
                    isReset={isReset} />


                { expanded &&
                <NumberPicker
                    id='adults'
                    title='Adults'
                    subtitle='Ages 13 or above'
                    value={adults}
                    min={1}
                    max={adultsMax}
                    onChange={onGuestsChange}
                    padding='0px 16px'
                    shape={buttonShape}
                    backgroundColor={buttonColor}
                    fontcolor={fontColor}
                    fontColorHover={fontColorHover}
                    isdark={customizations.pageThemeDark ? 'true' : null} /> }

                { expanded &&
                <NumberPicker
                    id='children'
                    title='Children'
                    subtitle='Ages 2–12'
                    value={children}
                    max={childrenMax}
                    onChange={onGuestsChange}
                    footerText={childrenAgesText}
                    footerButton={() => setShowChildrenAges(true)}
                    footerButtonText='Change'
                    padding='0px 16px'
                    footerPadding='0px 24px'
                    shape={buttonShape}
                    backgroundColor={buttonColor}
                    fontcolor={fontColor}
                    fontColorHover={fontColorHover}
                    isdark={customizations.pageThemeDark ? 'true' : null} /> }

                { expanded &&
                <NumberPicker
                    id='infants'
                    title='Infants'
                    subtitle='Under 2'
                    value={infants}
                    max={5}
                    onChange={onGuestsChange}
                    padding='0px 16px'
                    shape={buttonShape}
                    backgroundColor={buttonColor}
                    fontcolor={fontColor}
                    fontColorHover={fontColorHover}
                    isdark={customizations.pageThemeDark ? 'true' : null} /> }

                <ChildrenAgesPopup
                    show={showChildrenAges}
                    total={children}
                    max={childrenMax}
                    ages={childrenAges}
                    onHide={() => setShowChildrenAges(false)}
                    handleAddChildrenAge={handleAddChildrenAge}
                    handleDeleteChildrenAge={handleDeleteChildrenAge}
                    onChange={handleChildrenAgeChange} />


                { expanded &&
                <Button
                    shape={buttonShape}
                    fontColor={fontColorHover}
                    fontColorHover={fontColor}
                    buttonColor={buttonColorHover}
                    buttonColorHover={buttonColor}
                    disabled={!isSearchEnabled}
                    isdark={customizations.pageThemeDark ? 'true' : null}
                    onClick={isSearchEnabled ? () => onSearchClick() : null} >
                    <ButtonSection center={true}>
                        <Text>SEARCH</Text>
                    </ButtonSection>
                </Button> }

                { expanded &&
                <ButtonContent>
                    <ClearText fontColor={fontColor} fontColorHover={fontColorHover} onClick={() => onResetClick()}>Clear</ClearText>
                </ButtonContent> }
            </Content>
        </Container>
    );
};