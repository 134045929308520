import { productReducerConstants } from "../constants";
import { productService } from "../services";
import { alertActions } from './';

export const productActions = {
    create,
    getAll,
    getById,
    getBySlug,
    update,
    favourite,
    delete: _delete,
    clear
};

function create(product) {
    return async dispatch => {
        dispatch(request());

        await productService.create(product)
            .then(
                product => {
                    dispatch(success(product));
                    dispatch(alertActions.success('Product created successfully'));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: productReducerConstants.CREATE_REQUEST } }
    function success(product) { return { type: productReducerConstants.CREATE_SUCCESS, product } }
    function failure() { return { type: productReducerConstants.CREATE_FAILURE } }
}

function getAll(query) {
    return dispatch => {
        dispatch(request());

        productService.getAll(query)
            .then(
                products => dispatch(success(products)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: productReducerConstants.GETALL_REQUEST } }
    function success(products) { return { type: productReducerConstants.GETALL_SUCCESS, products } }
    function failure(error) { return { type: productReducerConstants.GETALL_FAILURE, error } }
}

function getById(id, query) {
    return dispatch => {
        dispatch(request());

        productService.getById(id, query)
            .then(
                product => dispatch(success(product)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: productReducerConstants.GETBYID_REQUEST } }
    function success(product) { return { type: productReducerConstants.GETBYID_SUCCESS, product } }
    function failure(error) { return { type: productReducerConstants.GETBYID_FAILURE, error } }
}

function getBySlug(slug, query) {
    return dispatch => {
        dispatch(request());

        productService.getBySlug(slug, query)
            .then(
                product => dispatch(success(product)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: productReducerConstants.GETBYSLUG_REQUEST } }
    function success(product) { return { type: productReducerConstants.GETBYSLUG_SUCCESS, product } }
    function failure(error) { return { type: productReducerConstants.GETBYSLUG_FAILURE, error } }
}

function update(product) {
    return async dispatch => {
        dispatch(request(product));

        await productService.update(product)
            .then(
                product => { 
                    dispatch(success(product));
                    dispatch(alertActions.success('Update successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(product) { return { type: productReducerConstants.UPDATE_REQUEST, product } }
    function success(product) { return { type: productReducerConstants.UPDATE_SUCCESS, product } }
    function failure(error) { return { type: productReducerConstants.UPDATE_FAILURE, error } }
}

function favourite(data) {
    return async dispatch => {
        dispatch(request(data));

        await productService.favourite(data)
            .then(
                product => { 
                    dispatch(success(product));
                    dispatch(alertActions.success('Successfully saved'));
                },
                error => {
                    dispatch(failure(error.toString(), data.id));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) { return { type: productReducerConstants.FAVOURITE_REQUEST, data } }
    function success(product) { return { type: productReducerConstants.FAVOURITE_SUCCESS, product } }
    function failure(error, id) { return { type: productReducerConstants.FAVOURITE_FAILURE, error, id } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        productService.delete(id)
            .then(
                product => dispatch(success(id)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(id) { return { type: productReducerConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: productReducerConstants.DELETE_SUCCESS, id } }
    function failure(error) { return { type: productReducerConstants.DELETE_FAILURE, error } }
}

function clear() {
    return { type: productReducerConstants.CLEAR_ALL };
}