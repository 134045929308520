import { Link } from "react-router-dom";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import FormLabel from "react-bootstrap/FormLabel";
import FormText from "react-bootstrap/FormText";
import { Button } from '../../custom/Buttons';

import { deviceConstants, colorConstants } from "../../../constants";


export const MainContainer = styled.div`

`;

export const BannerWrapper = styled.div`
  float: left;
  height: 100vh;
  overflow: hidden;
  display: none;

  @media ${deviceConstants.tablet} { 
    display: flex;
    width: 33%;
  }

  @media ${deviceConstants.laptop} {
    display: flex;
    width: 38%;
  }
`;

export const Banner = styled.img`
  opacity: 0.8;
`;

export const FormWrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;

  @media ${deviceConstants.tablet} {
    float: right;
    width: 67%;
    padding: 32px 10%;
    overflow-y: scroll;
    max-height: 100vh;
    ::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }

  @media ${deviceConstants.laptop} {
    width: 62%;
    padding: 32px 17%;
  }

  @media ${deviceConstants.desktop} {
    padding: 32px 19%;
  }
`;

export const TopSection = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const Title = styled.h2`
  background: ${colorConstants.gradientForText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const SubTitle = styled.h3`
  font-size: 16px;
  font-weight: 400;
  padding-top: 16px;
  line-height: 1.5;

  @media ${deviceConstants.laptop} { 
    display: none;
  }
`;

export const StyledForm = styled(Form)`
  font-size: 12px;
  padding-bottom: 32px;
`;

export const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

export const StyledFormLabel = styled(FormLabel)`
  font-weight: 700;
`;

export const StyledFormControl = styled(FormControl)`
  font-size: 12px;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 2px;
  border-color: ${colorConstants.softGrey2};

  :focus {
    border-color: ${colorConstants.primaryDark};
    box-shadow: none;
  }
`;

export const StyledFormText = styled(FormText)`
  color: ${colorConstants.warning};
`;

export const RegisterButton = styled(Button)`
  width: 100%;
`;

export const StyledLinksContainer = styled.div`
  text-align: center;
  margin-top: 32px;
`;

export const StyledLoginLink = styled(Link)`
  color: ${colorConstants.black};

  :hover {
    color: ${colorConstants.primaryDark};
  }
`;

export const PrivacyText = styled.div`
  color: ${colorConstants.darkGrey};
  font-size: 9px;
  margin-top: 24px;
`;

export const PrivacyLink = styled(Link)`
  color: ${colorConstants.darkGrey};

  :hover {
    color: ${colorConstants.primaryDark};
  }
`;

export const RecaptchaContainer = styled.div`
  margin-top: 24px;
`;

export const SpinnerContainer = styled.div`
  margin-top: 16px;
  text-align: center;
`;

export const AlertWarning = styled.div`
  font-weight: 600;
  color: ${colorConstants.warning};
  text-align: center;
  padding: 16px 16px 0 16px;
`;

export const AlertSuccess = styled(AlertWarning)`
  color: green !important;
`;

export const PasswordRequirements = styled.ul`
  // list-style-type: none;
`;