import styled from 'styled-components';
import { colorConstants } from '../../constants';



export const Container = styled.div`
    width: ${props => props.customwidth};
    height: ${props => props.customwidth};
    border-radius: ${props => props.shape};

    font-family: ${props => props.fontType || 'inherit'} !important;
    background-color: ${props => props.color};
    color: ${props => props.fontColor};

    position: relative;

    .link-image {
        border-radius: ${props => props.shape};
    }

    .top-section-fader {
        border-top-left-radius: ${props => props.shape};
        border-top-right-radius: ${props => props.shape};
    }

    :hover {
        cursor: pointer;
        background-color: ${props => props.colorHover};
        color: ${props => props.fontColorHover};
    }
`;

export const Image = styled.img`
    width: ${props => props.customwidth};
    height: ${props => props.customwidth};
    object-fit: cover;
`;

export const TopSection = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
`;

export const BottomSection = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 8px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const TopSectionFader = styled.div`
    background: linear-gradient(180deg, rgba(18,18,18,0.4937325271905637) 29%, rgba(18,18,18,0.16600143475358897) 60%, rgba(255,255,255,0) 78%);

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0.3;
`;

export const Name = styled.div`
    max-width: ${props => props.customwidth};

    font-size: 16px;
    font-weight: 600;
    margin: 0;

    line-height: 1em;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; // to use this we need to set max-width
`;

export const TokenWrapper = styled.div`
    padding 4px 8px;
    border-radius: 500px;

    position: relative;
`;

export const TokenFader = styled.div`
    background: ${colorConstants.softGrey};
    border-radius: 500px;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0.4;
`;

export const Token = styled.div`
    font-size: 12px;
    font-weight: 600;
    margin: 0;

    line-height: 1em;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; // to use this we need to set max-width
`;

export const Description = styled.div`
    max-width: ${props => props.customwidth};

    font-size: 11px;
    font-weight: 500;

    line-height: 1em;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; // to use this we need to set max-width
`;