import {
    Container,
    Content,
    Name,
    ButtonsSection,
    EditButton,
    DeleteButton
} from './DraftProductsListItem.styles';


const DraftProductsListItem = (props) => {
    const { data, handleEdit, handleDelete, ...rest } = props;
    
    return (
        <Container {...rest} >
            <Content onClick={() => handleEdit(data.id, data.customUrl)}>
                <Name onClick={() => handleEdit(data.id, data.customUrl)}>{data.name}</Name>
            </Content>

            <ButtonsSection>
                <EditButton size={14} onClick={() => handleEdit(data.id, data.customUrl)} />
                <DeleteButton size={14} onClick={() => handleDelete(data.id, data.customUrl)} />
            </ButtonsSection>
        </Container>
    );
};

export default DraftProductsListItem;