import { getLoggedinUsername } from '../utils';


// frontend application navigation routes
export const sitemap = {
    landing: {
        home: '/',
        about: '/about',
        contact: '/contact',
        blog: '/blog',
        pricing: '/pricing',
        explore: '/explore',
        z: {
            policies: {
                center: '/z/policies',
                privacy: '/z/policies/privacy',
                terms: '/z/policies/terms',
                termsForCustomers: '/z/policies/terms-customers',
                termsForAssociates: '/z/policies/terms-associates',
                dpa: '/z/policies/dpa',
                cookie: '/z/policies/cookie'
            },
            features: '/z/features',
            ambassadors: '/z/ambassadors',
            techies: '/z/techies',
            musicians: '/z/musicians',
            nft: '/z/nft',
            travellers: '/z/travel',
            managers: '/z/managers',
            entrepreneurs: '/z/entrepreneurs',
            press: '/z/press',
            careers: '/z/careers',
        },
        partners: {
            home: 'https://partners.bunj.ee',
            hotels: 'https://partners.bunj.ee/hotels',
            travelAgents: 'https://partners.bunj.ee/travel-agents',
        },
        help: {
            center: 'https://help.bunj.ee',
            faq: 'https://help.bunj.ee/faq',
            whitelistEmailInstruction: '/z/faq/whitelist-email',
        },
        unknown: '/', // Restrict
    },
    billing: {
        upgrade: '/upgrade/plans',
        checkout: '/upgrade/checkout',
    },
    auth: {
        login: '/login', // Restrict
        register: '/register', // Restrict
        logout: '/logout', // Private
        social: {
            google: '/auth/google', // Restrict
            facebook: '/auth/facebook' // Restrict
        },
        confirm: '/confirm', // Private
        password: {
            reset: '/reset-password', // Restrict
            change: '/admin/change-password' // Private
        },
        email: {
            change: '/admin/change-email' // Private
        }
    },
    admin: {
        invalid: '/admin', // redirect here, if no from path is set to container
        dashboard: '/admin', // Private
        profile: '/:username', // Public
        settings: '/admin/settings', // Private
        customize: '/admin/customize', // Private
        links: {
            list: '/admin/links', // Private
            github: {
                add: '/admin/links/add-github',
                edit: '/admin/links/edit-github',
            }
        },
        trips: {
            list: '/admin/trips', // Private
            add: '/admin/trips/add-trip', // Private
            edit: '/admin/trips/edit-trip', // Private
            view: '/:username/t/:slug', // Public
            checkout: '/admin/trips/checkout', // Private
        },
        travel: {
            search: '/:username/search', // Public
            hotel: '/:username/h/:slug', // Public
            hotelCheckout: '/:username/h/:slug/checkout', // Public
        },
        products: {
            list: '/admin/products', // Private
            add: '/admin/products/add-product', // Private
            edit: '/admin/products/edit-product', // Private
            view: '/:username/p/:slug' // Public
        },
        nft: {
            list: '/admin/nft', // Private
            add: '/admin/nft/add-nft', // Private
            edit: '/admin/nft/edit-nft', // Private
            view: '/:username/n/:slug' // Public
        },
        favourites: '/admin/saved', // Private
        bookings: {
            list: '/admin/bookings', // Private
            view: '/admin/bookings/details', // Private
        },
        orders: '/admin/orders',
        analytics: '/admin/analytics',
    },
    social: {
        facebookProfileUrl: 'https://www.facebook.com/bunjeehq',
        instagramProfileUrl: 'https://www.instagram.com/bunjee_hq/',
        pinterestProfileUrl: 'https://www.pinterest.com/bunjeehq',
        tiktokProfileUrl: 'https://www.tiktok.com/@bunjeehq',
        linkedinProfileUrl: 'https://www.linkedin.com/company/bunjeehq',
        twitterProfileUrl: 'https://twitter.com/bunjeehq',
        youtubeProfileUrl: 'https://www.youtube.com/@bunjeehq',
    },
};


// sidebar menu navigation items
export function sideBarLinks(isAuthenticated, creator, basic, premium, platinum, links){
    let username = getLoggedinUsername();
    return [
        {
            url: username ? getProfileLink(username) : sitemap.admin.dashboard,
            name: 'My Page',
            enabled: isAuthenticated && creator
        },
        {
            url: sitemap.admin.dashboard,
            name: 'Dashboard',
            enabled: isAuthenticated
        },
        {
            url: sitemap.admin.links.list,
            name: 'Links',
            enabled: isAuthenticated && creator && (links.simple.enabled || links.blog.enabled)
        },
        {
            url: sitemap.admin.trips.list,
            name: 'Trips',
            enabled: isAuthenticated && creator && (links.accommodation.enabled || links.experience.enabled)
        },
        {
            url: sitemap.admin.products.list,
            name: 'Products',
            enabled: isAuthenticated && creator && (links.physicalProduct.enabled || links.digitalProduct.enabled)
        },
        {
            url: sitemap.admin.nft.list,
            name: 'NFT',
            enabled: isAuthenticated && creator && links.nft.enabled
        },
        {
            url: sitemap.admin.favourites,
            name: 'Saved',
            enabled: isAuthenticated
        },
        {
            url: sitemap.admin.bookings.list,
            name: 'Bookings',
            enabled: isAuthenticated
        },
        {
            url: sitemap.admin.orders,
            name: 'Orders',
            enabled: isAuthenticated && false
        },
        {
            url: sitemap.admin.analytics,
            name: 'Analytics',
            enabled: isAuthenticated && (basic || premium || platinum) && false
        },
        {
            url: sitemap.admin.customize,
            name: 'Customize',
            enabled: isAuthenticated && creator
        },
        {
            url: sitemap.admin.settings,
            name: 'Settings',
            enabled: isAuthenticated
        },
        {
            url: sitemap.auth.logout,
            name: 'Logout',
            enabled: isAuthenticated
        },
        {
            url: sitemap.landing.home,
            name: 'Bunje homepage',
            enabled: !isAuthenticated
        },
        {
            url: sitemap.auth.register,
            name: 'Create account',
            enabled: !isAuthenticated
        },
        {
            url: sitemap.auth.login,
            name: 'Login',
            enabled: !isAuthenticated
        }
    ];
};


/**
 * Method to generate user profile link (Ref. https://v5.reactrouter.com/web/api/generatePath)
 * 
 * @param {String} username The username
 * @returns The user profile url
 */
export function getProfileLink(username) {
    return '/' + username;
}


/**
 * Method to generate trip link
 * 
 * @param {String} username The creator username
 * @param {String} tripSlug The trip custom url
 * @returns The trip url
 */
export function getTripLink(username, tripSlug) {
    return getProfileLink(username) + '/t/' + tripSlug;
}


/**
 * Method to generate search link
 * 
 * @param {String} username The creator username
 * @returns The travel search url
 */
export function getSearchLink(username) {
    return getProfileLink(username) + '/search';
}


/**
 * Method to generate hotel link
 * 
 * @param {String} username The creator username
 * @param {String} hotelSlug The hotel custom url
 * @returns The hotel url
 */
export function getHotelLink(username, hotelSlug) {
    return getProfileLink(username) + '/h/' + hotelSlug;
}


/**
 * Method to generate product link
 * 
 * @param {String} username The creator username
 * @param {String} slug The product custom url
 * @returns The product url
 */
 export function getProductLink(username, slug) {
    return getProfileLink(username) + '/p/' + slug;
}


/**
 * Method to generate NFT link
 * 
 * @param {String} username The creator username
 * @param {String} slug The product custom url
 * @returns The NFT url
 */
 export function getNFTLink(username, slug) {
    return getProfileLink(username) + '/n/' + slug;
}


/**
 * Method to generate Booking link
 * 
 * @param {INTEGER} id The booking id
 * @returns The Booking url
 */
 export function getBookingLink(id) {
    return '/admin/bookings/' + id;
}