import { nftPublicReducerConstants } from '../constants';

export function nftPublic(state = { nfts: [] }, action) {

  switch (action.type) {

    case nftPublicReducerConstants.GETALL_REQUEST:
        return {
            ...state,
            loading: true
        };

    case nftPublicReducerConstants.GETALL_SUCCESS:
        return {
            nfts: action.nfts
        };

    case nftPublicReducerConstants.GETALL_FAILURE:
        return {
            nfts: [],
            error: action.error
        };

    case nftPublicReducerConstants.GETBYSLUG_REQUEST:
        return {
            ...state,
            loading: true
        };
    
    case nftPublicReducerConstants.GETBYSLUG_SUCCESS:
        return {
            nfts: [action.nft]
        };

    case nftPublicReducerConstants.GETBYSLUG_FAILURE: {
        let { loading, ...stateCopy } = state; // remove loading flag
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case nftPublicReducerConstants.FILTER_REQUEST:
        return {
            ...state,
            loading: true
        };

    case nftPublicReducerConstants.FILTER_SUCCESS:
        return {
            nfts: action.nfts
        };

    case nftPublicReducerConstants.FILTER_FAILURE: {
        let { loading, ...stateCopy } = state; // remove loading flag
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case nftPublicReducerConstants.RESET_ALL: {
        return { nfts: [] }
    }

    default:
        return state
  }
}