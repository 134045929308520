import React from 'react';
import {
    Container,
    TopSection,
    Header,
    Image,
    Body,
    Title,
    BodyText,
    StyledButton,
} from './BookingSuccess.styles';



export const BookingSuccess = ({ data, show }) => {
    const { onClick, onHide } = data;

    return (
        <Container show={show} onHide={onHide}>
            <TopSection>
                <Header closeButton />
                <Image url={data.imageUrl} />
            </TopSection>

            <Body>
                <Title>{data.title}</Title>

                <BodyText>{data.message}</BodyText>

                <StyledButton medium={true} midCorner={true} gradient={true} onClick={onClick} >{data.buttonText}</StyledButton>
            </Body>
      </Container>
    );
};