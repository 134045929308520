import styled from "styled-components";
import Form from 'react-bootstrap/Form'
import FormGroup from 'react-bootstrap/FormGroup'
import FormControl from 'react-bootstrap/FormControl'
import FormText from 'react-bootstrap/FormText'

import { deviceConstants, colorConstants } from "../../../constants";
import { Logo } from '../../custom/Branding';
import { Button } from '../../custom/Buttons';
import { generateFirebasePhotoLink } from '../../../utils';

// set banner image
const bannerImageUrl = generateFirebasePhotoLink('public%2Fapp%2Fhomepage%2Ftravel%2Fwaitlist%20v0.1%2Flandingpage_background_2.jpg?alt=media');


export const MainContainer = styled.div`
    min-height: 100vh;
    padding: 48px 48px;

    background-image: url(${bannerImageUrl});

    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;

    @media ${deviceConstants.tablet} {
        padding: 0;

        overflow: hidden;
        
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const StyledForm = styled(Form)`
    padding: 48px 48px;
    border-radius: 2px;

    background: ${colorConstants.white};
    opacity: 0.85;

    @media ${deviceConstants.tablet} {
        padding: 48px 48px 30px 48px;
        max-width: 420px;

        opacity: 0.9;

        position: absolute;
        bottom: 10%;
        left: 3%;
    }

    @media ${deviceConstants.laptop} {
        left: 10%;
    }

    @media ${deviceConstants.desktop} {
        bottom: 25%;
        left: 13%;
    }
`;

export const StyledLogo = styled(Logo)`
    height: 50px;
    width: 100%;
    margin-top: 8px;
    
    @media ${deviceConstants.tablet} { 
        height: 35px;
        margin: 0;
    }
`;

export const Header = styled.h1`
    font-weight: 700;
    font-size: 36px;

    @media ${deviceConstants.tablet} {
        font-size: 38px;
        white-space: pre-wrap;
    }
`;

export const Subheader = styled.p`
    margin-top: 32px;
    margin-bottom: 24px;

    font-weight: 700;
    font-size: 14px;
    white-space: pre-wrap;

    @media ${deviceConstants.tablet} {
        margin-top: 24px;
        margin-bottom: 0;
    }
`;

export const StyledFormGroup = styled(FormGroup)`
  margin-top: 8px;
`;

export const AlertWarning = styled(FormText)`
  font-weight: 700;
  font-size: 12px;
  color: ${colorConstants.warning};
`;

export const AlertSuccess = styled(AlertWarning)`
  color: green;
`;

export const StyledFormControl = styled(FormControl)`
  font-size: 12px;
  padding: 12px;
  margin-top: 8px;
  border-radius: 2px;

  :focus {
    border-color: ${colorConstants.primaryDark};
    box-shadow: none;
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;

  padding-left: 0;
  padding-right: 0;

  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
`;

export const SpinnerContainer = styled.div`
  margin-top: 16px;
  text-align: center;
  color: ${colorConstants.primaryDark};
`;

export const GDPR = styled.div`
    font-size: 9px;
    margin-top: 8px;
    margin-bottom: 16px;
`;