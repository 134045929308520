// list of usernames allowed to access test features
export const testUsersList = ['andreas'];


// test hotel search results
export const testHotelSearchResults = {
    impala: [
        { place_id: 'bunjee_test_hotel_impala_1', description: 'Impala All-Inclusive Always Available Resort (Example Hotel)' },
        { place_id: 'bunjee_test_hotel_impala_2', description: 'Impala Minimalist Palace (Example Hotel)' },
    ],
};