import styled from 'styled-components';


export const Container = styled.div`
    width: ${props => props.maxwidth};
    border-radius: ${props => props.shape};

    :hover {
        cursor: default;
    }
`;