import { publicProductsReducerConstants } from "../constants";
import { publicProductsService } from "../services";

export const publicProductsActions = {
    getAll,
    getBySlug,
    filter,
    reset
};

function getAll() {
    return dispatch => {
        dispatch(request());

        publicProductsService.getAll()
            .then(
                products => dispatch(success(products)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publicProductsReducerConstants.GETALL_REQUEST } }
    function success(products) { return { type: publicProductsReducerConstants.GETALL_SUCCESS, products } }
    function failure(error) { return { type: publicProductsReducerConstants.GETALL_FAILURE, error } }
}

function getBySlug(username, slug) {
    return dispatch => {
        dispatch(request({ username, slug }));

        publicProductsService.getBySlug(username, slug)
            .then(
                product => dispatch(success(product)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(product) { return { type: publicProductsReducerConstants.GETBYSLUG_REQUEST, product } }
    function success(product) { return { type: publicProductsReducerConstants.GETBYSLUG_SUCCESS, product } }
    function failure(error) { return { type: publicProductsReducerConstants.GETBYSLUG_FAILURE, error } }
}

function filter(data) {
    return dispatch => {
        dispatch(request());

        publicProductsService.filter(data)
            .then(
                products => dispatch(success(products)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publicProductsReducerConstants.FILTER_REQUEST } }
    function success(products) { return { type: publicProductsReducerConstants.FILTER_SUCCESS, products } }
    function failure(error) { return { type: publicProductsReducerConstants.FILTER_FAILURE, error } }
}

function reset() {
    return { type: publicProductsReducerConstants.RESET_ALL };
}