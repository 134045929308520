import styled from "styled-components";
import { Link } from 'react-router-dom';
import { deviceConstants, colorConstants } from "../../../../constants";



export const Container = styled.div`
    padding: 70px 32px 170px 32px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${deviceConstants.tablet} {
        padding: 70px 20% 170px 20%;
    }
`;

export const Title = styled.h1`
    margin-bottom: 32px;

    font-weight: 700;
    font-size: 26px;
    color: ${colorConstants.black};

    @media ${deviceConstants.tablet} {
        font-size: 38px;
        text-align: start;
    }
`;

export const Header = styled.h2`
    margin-top: 24px;
    margin-bottom: 16px;

    font-weight: 600;
    font-size: 18px;
    color: ${colorConstants.darkGrey};

    @media ${deviceConstants.tablet} {
        font-size: 22px;
        text-align: start;
    }
`;

export const StyledParagraph = styled.p`
    font-weight: 500;
    font-size: 14px;
    color: ${colorConstants.darkGrey};

    a {
        color: ${colorConstants.primaryDark};

        :hover {
            color: ${colorConstants.black};
        }
    }
`;

export const List = styled.ul`
    list-style-position: outside;
`;

export const OrderedList = styled.ol`
    list-style-position: outside;
`;

export const ListItem = styled.li`
    font-weight: 500;
    font-size: 14px;
    color: ${colorConstants.darkGrey};
`;

export const StyledLink = styled(Link)`
    font-weight: 500;
    font-size: 14px;
    color: ${colorConstants.primaryDark};

    :hover {
        color: ${colorConstants.black};
    }
`;