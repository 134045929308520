import {
    Container,
    Title,
    Header,
    StyledParagraph as Text,
    List,
    OrderedList,
    ListItem as Item,
    StyledLink as Link,
} from './DataProcessingAddendumPage.styles';
import { sitemap, otherConstants } from '../../../../constants';



export const DataProcessingAddendumPage = () => {
    return (
        <Container>
            <Title>Data Processing Addendum</Title>


            <Text>By using {otherConstants.projectName}, we both (you and we) gather and use information about people (such as visitors to your profile and individuals featured in your content). There are rules in Europe to safeguard and protect this personal information (known as “EU Data Protection Laws”). This Data Processing Addendum (“DPA”) amends the {otherConstants.projectName} <Link to={sitemap.landing.z.policies.terms}>Terms of Service</Link> and will take precedence in the event of any conflict between the two. If and when your use of our Services is subject to EU Data Protection Laws, then this Data Processing Addendum ("DPA") is applicable to you.</Text>


            <Header>1. Definitions</Header>

            <OrderedList>
                <Item>“Data Processor”, “Data Controller”, “Data Subject”, “Processing”, “Subprocessor”, and “Supervisory Authority” shall be interpreted in accordance with the EU Data Protection Laws;</Item>

                <Item>“Personal Data” as used in this DPA means information that relates to, or could reasonably be linked with or to an identifiable or identified Data Subject who visits your profile (an “End User”), which {otherConstants.projectName} Processes as a Data Processor or Service Provider in the course of providing you with the Services;</Item>

                <Item>“Data Subject Request” as used in this DPA means a request for access, erasure, rectification, or portability of your End User’s Personal Data; and</Item>

                <Item>All other capitalized terms in this DPA shall have the same definition as in the Terms of Service.</Item>
            </OrderedList>


            <Header>2. Data Protection</Header>

            <OrderedList>
                <Item>
                    When {otherConstants.projectName} Processes Personal Data in the course of providing the Services, {otherConstants.projectName} will:

                    <List>
                        <Item>Process the Personal Data as a Data Processor and/or Service Provider, only for the purpose of providing the Services in accordance with documented instructions from you (provided that such instructions are commensurate with the functionalities of the Services), and as may subsequently be agreed to by you. If {otherConstants.projectName} is required by law to Process the Personal Data for any other purpose, {otherConstants.projectName} will provide you with prior notice of this requirement, unless {otherConstants.projectName} is prohibited by law from providing such notice: i) you acknowledge that {otherConstants.projectName} acts as an independent Data Controller with regards to End User Personal Data that it collects directly from consumers through its consumer-facing applications and services; and ii) notify you if, in {otherConstants.projectName}’s opinion, your instruction for the Processing of Personal Data infringes applicable EU Data Protection Laws;</Item>

                        <Item>notify you promptly, to the extent permitted by law, upon receiving an inquiry or complaint from a Supervisory Authority relating to {otherConstants.projectName}’s Processing of the Personal Data;</Item>

                        <Item>implement reasonable technical and organizational measures enabling you to execute Data Subject Requests that you are obligated to fulfil;</Item>

                        <Item>implement and maintain appropriate technical and organizational measures to protect the Personal Data against unauthorized or unlawful processing and against accidental loss, destruction, damage, theft, alteration or disclosure. These measures shall be appropriate to the harm which might result from any unauthorized or unlawful processing, accidental loss, destruction, damage or theft of Personal Data and appropriate to the nature of the Personal Data which is to be protected;</Item>

                        <Item>upon request, provide reasonable information to help the End User complete the End User’s data protection impact assessments;</Item>

                        <Item>provide you, upon request, with up-to-date attestations, reports or extracts thereof where available from a source charged with auditing {otherConstants.projectName}’s data protection practices (e.g. external auditors, internal audit, data protection auditors), or suitable certifications, to enable you to assess compliance with the terms of this DPA;</Item>

                        <Item>notify you without undue delay upon becoming aware of and confirming any accidental, unauthorized, or unlawful processing of, disclosure of, or access to the Personal Data;</Item>

                        <Item>ensure that its personnel who access the Personal Data are subject to confidentiality obligations that restrict their ability to disclose the End User Personal Data; and</Item>
                    </List>
                </Item>

                <Item>In the course of providing the Services, you acknowledge and agree that {otherConstants.projectName} may use Subprocessors to Process the Personal Data. {otherConstants.projectName}’s use of any specific Subprocessor to process the Personal Data must be in compliance with EU Data Protection Laws and must be governed by a contract between {otherConstants.projectName} and Subprocessor that requires comparable protections to this DPA. A current list of Subprocessors may be found online at our <Link to={sitemap.landing.z.policies.privacy}>Privacy Policy</Link>. If you object to the appointment of a Subprocessor you may terminate this agreement in accordance with the Terms of Service</Item>
            </OrderedList>


            <Header>3. Other</Header>

            <OrderedList>
                <Item>In the event of any conflict or inconsistency between the provisions of the Terms of Service and this DPA, the provisions of this DPA shall prevail. For avoidance of doubt and to the extent allowed by applicable law, any and all liability under this DPA, including limitations thereof, will be governed by the relevant provisions of the Terms of Service. You acknowledge and agree that {otherConstants.projectName} may amend this DPA from time to time by posting the relevant amended and restated DPA here and such amendments to the DPA are effective as of the date of posting. Your continued use of our Services after the amended DPA is posted constitutes your agreement to, and acceptance of, the amended DPA. If you do not agree to any changes to the DPA, do not continue to use our Services.</Item>

                <Item>Save as specifically modified and amended in this DPA, all of the terms, provisions and requirements contained in the Terms of Service shall remain in full force and effect and govern this DPA. If any provision of the DPA is held illegal or unenforceable in a judicial proceeding, such provision shall be severed and shall be inoperative, and the remainder of this DPA shall remain operative and binding on the parties.</Item>

                <Item>The terms of this DPA shall be governed by and interpreted in accordance with the laws of the United Kingdom applicable therein, without regard to principles of conflicts of laws. The parties irrevocably and unconditionally submit to the exclusive jurisdiction of the courts of the United Kingdom or the courts of the European Union with respect to any dispute or claim arising out of or in connection with this DPA.</Item>
            </OrderedList>

            <Text>This document was last updated on February 16, 2023</Text>

        </Container>
    );
};