import { publicHotelReducerConstants } from '../constants';

export function publicHotel(state = {}, action) {

  switch (action.type) {

    case publicHotelReducerConstants.FIND_ALL_BY_LOCATION_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicHotelReducerConstants.FIND_ALL_BY_LOCATION_SUCCESS: {
        let { loading, ...stateCopy } = state;
        return {
            ...stateCopy,
            searchSession: action.session.session,
        };
    }

    case publicHotelReducerConstants.FIND_ALL_BY_LOCATION_FAILURE: {
        let { loading, ...stateCopy } = state;
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case publicHotelReducerConstants.GET_SEARCH_RESULTS_BY_SESSION_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicHotelReducerConstants.GET_SEARCH_RESULTS_BY_SESSION_SUCCESS: {
        let { loading, ...stateCopy } = state;
        let hasSessionResultsSaved = stateCopy.searchSession && stateCopy.hotelsAtLocation && stateCopy.hotelsAtLocation.length > 0 && !action.hotelsAtLocation.session.isFilterUpdateRequest;
        return {
            ...stateCopy,
            searchSession: action.hotelsAtLocation.session,
            hotelsAtLocation: hasSessionResultsSaved ? stateCopy.hotelsAtLocation.concat(action.hotelsAtLocation.hotels) : action.hotelsAtLocation.hotels,
        };
    }

    case publicHotelReducerConstants.GET_SEARCH_RESULTS_BY_SESSION_FAILURE: {
        let { loading, ...stateCopy } = state;
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case publicHotelReducerConstants.GETBYSLUG_REQUEST:
        return {
            ...state,
            loading: true
        };

    case publicHotelReducerConstants.GETBYSLUG_SUCCESS: {
        let { loading, ...stateCopy } = state;
        return {
            ...stateCopy,
            hotel: action.hotel
        };
    }

    case publicHotelReducerConstants.GETBYSLUG_FAILURE: {
        let { loading, ...stateCopy } = state;
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case publicHotelReducerConstants.CLEAR_SEARCH_SESSION: {
        let { searchSession, ...stateCopy } = state;
        return stateCopy;
    }

    case publicHotelReducerConstants.CLEAR_HOTEL_DETAILS: {
        let { hotel, loading, ...stateCopy } = state;
        return stateCopy;
    }

    case publicHotelReducerConstants.CLEAR_ALL:
        return {};

    default:
        return state
  }
}