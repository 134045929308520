import React, { Component } from "react";
import { connect } from 'react-redux';
import qs from 'qs';

import { authActions, userActions, hotelBookingActions, alertActions } from '../../actions';
import { ScreenContainer } from './MyBookingsContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { Notification } from '../../components/custom/Popup';
import { NoBookingsFound } from '../../components/custom/EmptyState';
import { BookingsList } from "../../components/bookings";
import { TopBar } from '../../components/custom/TopBar';
import { SideBar } from '../../components/custom/SideBar';
import { FullScreenSpinner } from "../../components/custom/Spinner";
import { FiltersMenu } from '../../components/custom/Filter';
import { history, defaultUser } from '../../utils';
import { topbarConstants, sitemap } from "../../constants";


class MyBookingsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            fromUrl: sitemap.admin.dashboard,
            sidebar: false,
            sidebarReset: false,
            sidebarPositionOffset: 0,
            sticky: false,
            user: defaultUser(),
            guestUser: null,
            isCsrfReady: false,
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuButton = this.handleMenuButton.bind(this);
        this.handleViewBooking = this.handleViewBooking.bind(this);

        this.handleFilter = this.handleFilter.bind(this);
        this.handleResetFilter = this.handleResetFilter.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        if(this.props.userId) {
            // for logged-in user
            if(!this.props.user || !this.props.user.id) this.props.getById(this.props.userId); // get user data from db
            this.props.getAllHotelBookings(new URLSearchParams({ userId: this.props.userId })); // get all Hotel Bookings by user id

        } else {
            // for guest user

            // load csrf token in cookies
            this.props.loadCsrfToken();

            // get query from link
            let query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            if(!query || !query.session || !query.email || !query.ref || !query.type) {
                // redirect to login page
                history.replace({ pathname: sitemap.auth.login, state: { error: 'No booking data found' } });
                return;
            }

            // save guest user data
            const { session, email, ref, type } = query;
            this.setState({ guestUser: { session, email, bookingId: ref, type } });
        }

        // set from URL
        if(this.props.location.state && this.props.location.state.from) this.setState({ ...this.state, fromUrl: this.props.location.state.from });
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.resetHotelBookings();
    }

    componentDidUpdate(){
        // load user data from server into component state
        if (!this.state.init && this.props.user && this.props.user.id) {
            this.setState({ ...this.state, init: true, user: this.props.user });
        }

        // login guest user
        if(!this.state.init && this.state.guestUser && !this.state.isCsrfReady && this.props.isCsrfReady) {
            // update CSRF status
            this.setState({ ...this.state, isCsrfReady: true });
            // authorise guest user and get userId
            this.props.loginGuestUser({ session: this.state.guestUser.session, email: this.state.guestUser.email });
        }

        // get all guest user's hotels
        if(!this.state.init && this.state.isCsrfReady && this.state.guestUser && this.props.guestUserId) {
            // init state
            this.setState({ ...this.state, init: true });
            // get all Hotel Bookings by guest user session
            this.props.getAllHotelBookings(new URLSearchParams({ userId: -1, guestUserId: this.props.guestUserId }), true);
        }
    }

    handleScroll(event) {
        // Enable sticky mode on scroll and reset sidebar
        this.setState({ ...this.state, sidebar: false, sidebarReset: true, sidebarPositionOffset: window.pageYOffset, sticky: window.pageYOffset > 1 });
    }

    handleMenuButton() {
        this.setState({ ...this.state, sidebar: !this.state.sidebar, sidebarReset: false });
    }

    handleViewBooking(booking) {
        // open booking details screen
        history.push({
            ...this.props.location, // pass guest user params
            pathname: sitemap.admin.bookings.view,
            state: {
                from: sitemap.admin.bookings.list,
                data: {
                    userId: booking.userId,
                    bookingId: booking.id,
                    type: booking.type,
                }
            }
        });
    }

    handleFilter(selection) {
        let params = { userId: this.props.userId ? this.props.userId : -1, guestUserId: this.props.guestUserId };

        switch (selection) {

            case 1: {
                // get all ACCOMMODATION bookings
                params = { userId: this.props.userId ? this.props.userId : -1, guestUserId: this.props.guestUserId };
                break;
            }

            case 2: {
                // get all EXPERIENCE bookings
                params.type = 'EXPERIENCE';
                break;
            }

            default:
                break;
        }

        this.props.getAllHotelBookings(new URLSearchParams(params), this.props.guestUserId);
    }

    handleResetFilter(){
        this.props.getAllHotelBookings(new URLSearchParams({ userId: this.props.userId ? this.props.userId : -1, guestUserId: this.props.guestUserId }), this.props.guestUserId);
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    render() {
        return (
            <ScreenContainer id='screen'>
                <MetaTags title={topbarConstants.titles.myBookings} />

                <TopBar
                    title={topbarConstants.titles.myBookings}
                    sticky={this.state.sticky}
                    menuButton={this.state.guestUser ? null : this.handleMenuButton} />

                {this.state.init && !this.state.guestUser &&
                <SideBar
                    options={{ isAuthenticated: true, creator: this.state.user.creator, basic: this.state.user.basic, premium: this.state.user.premium, platinum: this.state.user.platinum, links: this.state.user.pageData.metadata.links }}
                    toggle={this.handleMenuButton}
                    from={sitemap.admin.bookings.list}
                    enable={this.state.sidebar}
                    reset={this.state.sidebarReset}
                    position={this.state.sidebarPositionOffset} /> }

                {this.state.init &&
                <FiltersMenu
                    handleReset={this.handleResetFilter}
                    handleFilter={this.handleFilter}
                    options={{
                        showHotelLinks: true,
                        // showTravelAgencyLinks: true,
                        force: true
                    }}
                    noActive={true} /> }

                <BookingsList
                    bookings={this.props.bookings}
                    handleViewBooking={this.handleViewBooking} />

                { !this.props.loading && (!this.props.bookings || this.props.bookings.length === 0) &&
                <NoBookingsFound /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                {this.props.loading && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get CSRF status
    const isCsrfReady = (state.auth && state.auth.isCsrfReady) ? state.auth.isCsrfReady : null;

    // get user id from cookie
    let userId = false;
    let guestUserId = null;
    if(state.auth && state.auth.cookie) {
        if(state.auth.cookie.id) {
            userId = state.auth.cookie.id;
        }

        if(state.auth.cookie.guestUserId && state.auth.cookie.guestUserCreatedAt) {
            guestUserId = state.auth.cookie.guestUserId;
        }
    }

    // get data from app reducer state
    const { alert, user } = state;
    const { loading, bookings } = state.hotelBooking;

    // export state data to props
    return { isCsrfReady, userId, guestUserId, alert, user, loading, bookings };
}

const actionCreators = {
    loadCsrfToken: authActions.loadCsrfToken,
    loginGuestUser: authActions.loginGuestUser,
    getById: userActions.getById,
    getAllHotelBookings: hotelBookingActions.getAll,
    resetHotelBookings: hotelBookingActions.clear,
    clearAlert: alertActions.clear
}

const connectedMyBookingsContainer = connect(mapState, actionCreators)(MyBookingsContainer);
export { connectedMyBookingsContainer as MyBookingsContainer };