import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import { Button } from '../../custom/Buttons';

import { deviceConstants, colorConstants } from "../../../constants";



export const Container = styled.div`

`;

export const TopSection = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;

  @media ${deviceConstants.desktop} {
    padding-top: 56px;
  }
`;

export const Title = styled.h2`
  background: ${colorConstants.gradientForText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 8px;
  font-weight: 600;
  text-align: center;
`;

export const MainSection = styled.div`
  @media ${deviceConstants.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media ${deviceConstants.laptop} {
    margin-top: 48px;
  }

  @media ${deviceConstants.desktop} {
    margin-top: 96px;
  }
`;

export const StyledForm = styled(Form)`
  font-size: 12px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 24px;

  @media ${deviceConstants.tablet} {
    width: 49%;
  }

  @media ${deviceConstants.laptop} {
    padding-left: 17%;
    padding-right: 7%
  }

  @media ${deviceConstants.desktop} {
    padding-left: 24%;
    padding-right: 6%
  }
`;

export const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

export const StyledFormLabel = styled(FormLabel)`
  font-weight: 700;
`;

export const StyledFormControl = styled(FormControl)`
  font-size: 12px;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 2px;
  border-color: ${colorConstants.softGrey2};

  :focus {
    border-color: ${colorConstants.primaryDark};
    box-shadow: none;
  }
`;

export const StyledFormText = styled(FormText)`
  color: ${colorConstants.warning};
`;

export const LoginButton = styled(Button)`
  width: 100%;
`;

export const StyledLinksContainer = styled.div`
  margin-top: 32px;
`;

export const StyledRegisterLink = styled(Link)`
  color: ${colorConstants.black};
  
  :hover{
    color: ${colorConstants.primaryDark};
  }
`;

export const StyledResetLink = styled(Link)`
  color: ${colorConstants.black};
  
  :hover{
    color: ${colorConstants.primaryDark};
  }
`;

export const DividerWrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;

  @media ${deviceConstants.tablet} {
    display: none;
  }
`;

export const VerticalDividerWrapper = styled.div`
  display: none;

  @media ${deviceConstants.tablet} {
    height: 450px;
    width: 2%;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SocialLoginsWrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;

  @media ${deviceConstants.tablet} {
    width: 49%;
  }

  @media ${deviceConstants.laptop} {
    padding-left: 2%;
    padding-right: 23%;
  }

  @media ${deviceConstants.desktop} {
    padding-left: 3%;
    padding-right: 28%;
  }
`;

export const BottomSection = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 24px;
  padding-bottom: 16px;

  @media ${deviceConstants.tablet} {
    width: 100%;
    
    position: absolute;
    top: 0;
  }

  @media ${deviceConstants.laptop} {
    margin-top: 675px;
  }

  @media ${deviceConstants.desktop} {
    margin-top: 750px;
  }
`;

export const PrivacyText = styled.div`
  color: ${colorConstants.darkGrey};
  font-size: 11px;
  text-align: center;
  max-width: 60%;
  margin: auto;
`;

export const PrivacyLink = styled(Link)`
  color: ${colorConstants.darkGrey};

  :hover {
    color: ${colorConstants.primaryDark};
  }
`;

export const RecaptchaContainer = styled.div`
  margin-top: 24px;
`;

export const SpinnerContainer = styled.div`
  margin-top: 16px;
  text-align: center;
`;

export const AlertWarning = styled.div`
  font-weight: 600;
  color: ${colorConstants.warning};
  text-align: center;
  padding: 16px 16px 0 16px;
`;

export const AlertSuccess = styled(AlertWarning)`
  color: green !important;
`;