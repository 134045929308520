import React, { forwardRef } from 'react';
import { Container } from './SpotifyLink.styles';
import { colorConstants } from "../../constants";


export const SpotifyLink = forwardRef(({ data, customizations, maxWidth, ...rest }, ref) => {
    let { buttons, fonts } = customizations;

    // defaults
    if(buttons.color === '') buttons.color = colorConstants.softBlack;
    if(buttons.fontColor === '') buttons.fontColor = colorConstants.white;

    // set button shape
    const buttonShape = buttons.shape === 'OVAL' ? '24px' : buttons.shape === 'ROUNDED' ? '8px' : '0px';

    // set button color
    const buttonColor = buttons.style === 'OUTLINE' ? 'transparent' : buttons.color;
    const buttonColorHover = buttons.style === 'OUTLINE' ? buttons.color : 'transparent';

    // set button font color
    const fontColor = buttons.style === 'OUTLINE' ? buttons.color : buttons.fontColor;
    const fontColorHover = buttons.style === 'OUTLINE' ? buttons.fontColor : buttons.color;

    if(!data.contentId) return null;


    return (
        <Container
            ref={ref}
            maxwidth={maxWidth || '100%'}
            shape={buttonShape}
            borderColor={buttons.color}
            color={buttonColor}
            colorHover={buttonColorHover}
            fontColor={fontColor}
            fontColorHover={fontColorHover}
            fontType={fonts.type}
            {...rest}
        >
            <iframe
                title={data.contentId}
                style={{ borderRadius: buttonShape }}
                src={`https://open.spotify.com/embed/${data.description}/${data.contentId}?theme=${customizations.pageThemeDark ? '1' : '0'}`}
                width={maxWidth || '100%'}
                height="80"
                frameBorder="0"
                allowFullScreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy" />
        </Container>
    );
});