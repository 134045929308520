import { subscriptionReducerConstants } from '../constants';


export function subscription(state = {}, action) {

  switch (action.type) {
    case subscriptionReducerConstants.GET_PLANS_REQUEST: {
        return { ...state, loadingPlans: true };
    }
    
    case subscriptionReducerConstants.GET_PLANS_SUCCESS: {
        const { loadingPlans, ...rest } = state;
        return { ...rest, plans: action.plans };
    }

    case subscriptionReducerConstants.GET_PLANS_FAILURE: {
        const { loadingPlans, plans, ...rest } = state;
        return rest;
    }


    case subscriptionReducerConstants.CHECKOUT_CONFIG_REQUEST: {
        return { ...state, loadingConfigs: true };
    }
    
    case subscriptionReducerConstants.CHECKOUT_CONFIG_SUCCESS: {
        const { loadingConfigs, ...rest } = state;
        return { ...rest, config: action.config };
    }

    case subscriptionReducerConstants.CHECKOUT_CONFIG_FAILURE: {
        const { loadingConfigs, ...rest } = state;
        return { ...rest, config: { error: action.error } };
    }
    
    
    case subscriptionReducerConstants.GETBY_USERID_REQUEST:
        return { config: state.config, loading: true };
    
    case subscriptionReducerConstants.GETBY_USERID_SUCCESS:
        return { config: state.config, ...action.subscription };

    case subscriptionReducerConstants.GETBY_USERID_FAILURE:
        return { config: state.config, error: action.error };
    

    case subscriptionReducerConstants.CREATE_REQUEST:
        return { ...state, processing: true };

    case subscriptionReducerConstants.CREATE_SUCCESS:
        return { plans: state.plans, config: state.config, ...action.subscription };

    case subscriptionReducerConstants.CREATE_FAILURE: {
        const { processing, error, ...rest } = state;
        return { ...rest, plans: state.plans, processingError: action.error };
    }


    case subscriptionReducerConstants.UPDATE_REQUEST:
        return { ...state, updating: true };

    case subscriptionReducerConstants.UPDATE_SUCCESS:
        return { plans: state.plans, config: state.config, ...action.subscription };

    case subscriptionReducerConstants.UPDATE_FAILURE: {
        const { updating, error, ...rest } = state;
        return { ...rest, plans: state.plans, processingError: action.error };
    }
    

    case subscriptionReducerConstants.CANCEL_REQUEST:
        return { config: state.config, cancelling: true };
    
    case subscriptionReducerConstants.CANCEL_SUCCESS: {
        const { cancelling, ...rest } = state;
        return { ...rest, isCancelled: true };
    }

    case subscriptionReducerConstants.CANCEL_FAILURE: {
        const { cancelling, ...rest } = state;
        return { ...rest, error: action.error };
    }


    case subscriptionReducerConstants.GET_COUPON_REQUEST: {
        const { promoError, promo, ...rest } = state;
        return { ...rest, loadingCoupon: true };
    }
    
    case subscriptionReducerConstants.GET_COUPON_SUCCESS: {
        const { loadingCoupon, ...rest } = state;
        return { ...rest, promo: action.coupon };
    }

    case subscriptionReducerConstants.GET_COUPON_FAILURE: {
        const { loadingCoupon, ...rest } = state;
        return { ...rest, promoError: action.error };
    }
    

    case subscriptionReducerConstants.CLEAR_ALL:
        return {};

    default:
        return state
  }
}