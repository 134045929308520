import styled from 'styled-components';



export const Container = styled.div`
    width: 120px;
    height: 146px;
    
    border-radius: ${props => props.shape};
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    font-family: ${props => props.fontType || 'inherit'} !important;
    background-color: ${props => props.color};
    color: ${props => props.fontColor};

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    position: relative;

    .fader {
        background: ${props => props.borderColor};
        border-radius: ${props => props.shape};
    }

    .link-image {
        border-top-left-radius: ${props => props.shape};
        border-top-right-radius: ${props => props.shape};
    }

    :hover {
        cursor: pointer;
        background-color: ${props => props.colorHover};
        color: ${props => props.fontColorHover};
    }
`;

export const ContainerFader = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0.1;

    z-index: 70;
`;

export const Image = styled.img`
    width: 120px;
    height: 90px;
    object-fit: cover;
    z-index: 71;
`;

export const Name = styled.div`
    max-width: 120px;
    padding: 6px 8px;
    z-index: 71;

    font-size: 14px;
    font-weight: 600;
    margin: 0;

    line-height: 1em;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; // to use this we need to set max-width
`;

export const Description = styled.div`
    max-width: 120px;
    padding: 0px 8px 4px 8px;
    z-index: 71;

    font-size: 10px;
    font-weight: 300;

    /** break text to multiple lines to contain it within div */
    word-break: break-all;
    /** limit text to 2 lines */
    line-height: 1em;
    height: 2em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;