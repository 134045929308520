import { http } from './http-common';
import { logoutFirebaseAuth } from './firebase';
import { deleteLocalData } from './local-storage';
// import { sitemap } from '../constants';
// import { history } from '../utils';



/**
 * Method to handle server request response
 * 
 * @param {JSON Object} response Server request response object
 * @param {BOOLEAN} forceLogout Set true to force user logout on error event
 * @returns JSON Object with user data
 */
 export function handleResponse(response, forceLogout) {
    const data = response.data;
    if (response.status !== 200) {
        if (response.status === 401 && forceLogout) {
            // auto logout if 401 unauthorised response returned from api
            logout();
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

    return data;
}


/**
 * Method to handle server response errors
 * 
 * @param {JSON Object} error The response with the error data
 * @param {BOOLEAN} forceLogout Set true to force user logout on error event
 * @returns Error with message
 */
export function handleError(error, forceLogout) {
    // handle NodeJS server errors
    if (!error.response) {
        // when no connection found
        return Promise.reject("Something's wrong with your connection. Please refresh your internet connection and try again.");
    }
    
    if (error.response.status !== 200) {
        if (error.response.status === 401 && forceLogout) {
            // auto logout if 401 unauthorised response returned from api
            logout();
        }
        if(error.response.status === 404) {
            // history.replace({ pathname: sitemap.landing.unknown });
        }
    }

    throw error.response.data.message;
}


/**
 * Private method to Logout the user
 */
async function logout() {
    try {
        // logout user from firebase auth
        await logoutFirebaseAuth();
        // logout session cookie
        await http.get("/auth/sessionLogout");
    } catch (err) {
        console.log(err);
    }
    // remove user data from local storage to log user out
    deleteLocalData('user');
    // reload page
    window.location.reload();
}