import { useMemo } from 'react';
import {
    Container,
    Photo,
    Fader,
    TopSection,
    // Row,
    Name,
    Type,
    BottomSection,
    Location,
    PriceRow, PriceIndicator, SavingsInfo
} from './SearchListItem.styles';
// import { StarSystem } from '../custom/Rating';
import { addCurrencySymbol } from '../../utils';


// placeholder image
const placeholderImage = 'https://firebasestorage.googleapis.com/v0/b/bunjee-app.appspot.com/o/public%2Fapp%2Fseo%2Fbunjee_cover_seo_tt.png?alt=media';


export const SearchListItem = ({ data, lastItemRef, handleView, ...rest }) => {

    // format from rate
    const fromRate = useMemo(() => (data.minRate && data.currency) ? `from ${addCurrencySymbol(data.currency, data.minRate / 100, true)}` : null, [data]);

    // format savings
    const savings = useMemo(() => (data.discount && data.currency) ? `Save ${addCurrencySymbol(data.currency, Math.abs(data.discount) / 100, true)}` : null, [data]);

    // get images
    const image = useMemo(() => data.photo || data.photoFallback || data.googlePhoto || data.googlePhotoFallback || placeholderImage, [data]);
    const fallBackImage = useMemo(() => (data.photo ? data.googlePhoto : data.googlePhotoFallback) || data.photoFallback || placeholderImage, [data]);

    return (
        <Container onClick={() => handleView(data)} ref={lastItemRef} {...rest} >
            <Photo src={image} fallbackSrc={fallBackImage} alt={data.name + ' Cover Photo'} />

            <Fader />

            <TopSection>
                <Name>{data.name}</Name>
                {/* <Row>
                    <Type>{data.type}</Type>
                    { data.stars && <StarSystem color='gold' rating={data.stars} /> }
                </Row> */}
                <Type>{data.stars ? data.stars + '-Star ' : ''}{data.type}</Type>
            </TopSection>

            <BottomSection>
                <Location>{data.city + ', ' + data.country}</Location>
                <PriceRow>
                    { savings && <SavingsInfo>{savings}</SavingsInfo> }
                    { fromRate && <PriceIndicator>{fromRate}</PriceIndicator> }
                </PriceRow>
            </BottomSection>
        </Container>
    );
};