import React, { useMemo, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import GoogleMapReact from 'google-map-react'; // https://www.npmjs.com/package/google-map-react
import {
    Container,
    Header,
    Title,
    Body,
    SectionSpinner,
    Marker, MarkerText,
    SelectedItemContainer,
    LoadMoreButton,
} from './Map.styles';
import { SearchListItem } from '../../search/SearchListItem';
import { addCurrencySymbol } from '../../../utils';
import { otherConstants } from '../../../constants';



const GoogleMapsOptions = (maps) => ({
    zoomControl: true,
    zoomControlOptions: {
     position: maps.ControlPosition.TOP_RIGHT
    }
});


export const Map = ({ show, onHide, data, handleSelectedItemClick, handleLoadMore, hasMoreToLoad, loading }) => {

    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);

    const hasData = useMemo(() => {
        if(data && ((data.place && data.place.name) || (data.places && data.places.length > 0))) return true;
        return false;
    }, [data]);

    const isSinglePlace = useMemo(() => {
        if(hasData && data.place && data.place.name) return true;
        return false;
    }, [hasData, data]);

    const title = useMemo(() => {
        if(!hasData) return null;
        if(isSinglePlace) return data.place.name || 'Location Map';
        return data.name;
    }, [hasData, isSinglePlace, data]);

    const mapsEmbedUrl = useMemo(() => {
        if(!hasData || !isSinglePlace) return null;
        return data.place.mapsEmbedUrl;
    }, [hasData, isSinglePlace, data]);

    const defaultProps = useMemo(() => ({
            center: {
                lat: (hasData && data.center) ? data.center[0] : 10.99835602,
                lng: (hasData && data.center) ? data.center[1] : 77.01502627
            },
            zoom: 13
    }), [hasData, data]);

    const markers = useMemo(() => {
        if(!hasData || isSinglePlace) return null;
        return data.places.map(p => (
            <Marker
                active={selectedPlace && selectedPlace.id === p.id}
                key={p.name}
                lat={p.latitude}
                lng={p.longitude}
                onClick={() => setSelectedPlace(prev => (prev && prev.id === p.id) ? null : p)} >
                    {addCurrencySymbol(p.currency, p.minRate / 100, true)}
                    <MarkerText active={selectedPlace && selectedPlace.id === p.id} />
            </Marker>
        ));
    }, [hasData, isSinglePlace, data, selectedPlace]);

    if(!hasData) return null;

    return (
        <Container show={show} onHide={onHide}>
            <Header closeButton>
                <Title>{title}</Title>
            </Header>

            <Body>
                { !isMapLoaded &&
                <SectionSpinner>
                    <Spinner animation="border" />
                </SectionSpinner> }

                { isSinglePlace && mapsEmbedUrl &&
                <iframe
                    title={title}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    style={{ border: 0 }}
                    src={mapsEmbedUrl}
                    allowFullScreen
                    onLoad={() => setIsMapLoaded(true)}
                ></iframe> }

                { !isSinglePlace && markers &&
                <div style={{ height: '100%', width: '100%' }}>
                    { handleLoadMore && hasMoreToLoad &&
                    <LoadMoreButton onClick={handleLoadMore} >
                        {loading ? <Spinner size="sm" animation="border" /> : 'Load more'}
                    </LoadMoreButton> }
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: otherConstants.googleMapsApiKey, libraries: ['places', 'geometry'] }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        yesIWantToUseGoogleMapApiInternals
                        options={GoogleMapsOptions}
                        onGoogleApiLoaded={() => setIsMapLoaded(true)} >
                        {markers}
                    </GoogleMapReact>
                </div> }

                { selectedPlace &&
                <SelectedItemContainer>
                    <SearchListItem data={selectedPlace} handleView={handleSelectedItemClick} shadow='true' />
                </SelectedItemContainer> }
            </Body>
      </Container>
    );
};