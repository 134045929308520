import styled from 'styled-components';
import { Bank } from 'react-bootstrap-icons';

import { colorConstants } from '../../../constants';
import { Button } from '../Buttons';


const Container = styled.div`
    margin-top: 32px;
    margin-bottom: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Text = styled.div`
    margin: 24px 0px 16px 0px;
    font-size: 12px;
    font-weight: 400;
    color: ${colorConstants.softGrey};
    text-align: center;
`;


export const NoPaymentInfoFound = (props) => {
    const { onClick } = props;

    return (
        <Container>
            <Bank size={32} color={colorConstants.black} />

            <Text>Connect a bank account for us to pay you</Text>

            <Button outline={true} small={true} onClick={onClick} >Add bank</Button>
        </Container>
    );
};