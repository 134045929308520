import React, { useState } from 'react';
import {
    Container,
    BottomContainer,
    BottomContent,
    BottomColumnLeft,
    BottomColumnRight,
    BottomColumnLeftContent,
    StyledLogo,
    CopyrightSection,
    CopyrightSectionMobile,
    PoliciesSection,
    PoliciesSectionMobile,
    SocialIcons,
    SocialIconsMobile,
    MenuContainer,
    MenuContent,
    MenuColumn,
    MenuHeader,
    MenuHeaderMobileWrapper,
    MenuHeaderMobile,
    MenuHeaderMobileDownButton,
    MenuItemsContainer,
    MenuItem
} from './HomeFooter.styles';
import { Divider } from '../../custom/Divider';

import { colorConstants, contentLandingPageFooter, sitemap } from "../../../constants";



export const HomeFooter = (props) => {
    const { menu } = props;

    const [isMenu1Visible, setMenu1Visible] = useState(false);
    const [isMenu2Visible, setMenu2Visible] = useState(false);
    const [isMenu3Visible, setMenu3Visible] = useState(false);
    const [isMenu4Visible, setMenu4Visible] = useState(false);

    return (
        <Container>

            { menu &&
            <MenuContainer>
                <Divider />

                <MenuContent>
                    <MenuColumn>
                        <MenuHeader>{contentLandingPageFooter.menu.col1.title}</MenuHeader>
                        <MenuHeaderMobileWrapper onClick={() => setMenu1Visible(!isMenu1Visible)}>
                            <MenuHeaderMobile>{contentLandingPageFooter.menu.col1.title}</MenuHeaderMobile>
                            <MenuHeaderMobileDownButton />
                        </MenuHeaderMobileWrapper>

                        <MenuItemsContainer show={isMenu1Visible}>
                            <MenuItem href={sitemap.landing.explore} target='_blank' >{contentLandingPageFooter.menu.col1.item1}</MenuItem>
                            <MenuItem href={sitemap.landing.blog} target='_blank' >{contentLandingPageFooter.menu.col1.item2}</MenuItem>
                            <MenuItem href={sitemap.landing.z.features} target='_blank' >{contentLandingPageFooter.menu.col1.item3}</MenuItem>
                            <MenuItem href={sitemap.landing.z.ambassadors} target='_blank' >{contentLandingPageFooter.menu.col1.item4}</MenuItem>
                            <MenuItem href={sitemap.landing.z.musicians} target='_blank' >{contentLandingPageFooter.menu.col1.item5}</MenuItem>
                            <MenuItem href={sitemap.landing.z.nftOwners} target='_blank' >{contentLandingPageFooter.menu.col1.item6}</MenuItem>
                            <MenuItem href={sitemap.landing.z.travellers} target='_blank' >{contentLandingPageFooter.menu.col1.item7}</MenuItem>
                        </MenuItemsContainer>
                    </MenuColumn>

                    <MenuColumn>
                        <MenuHeader>{contentLandingPageFooter.menu.col2.title}</MenuHeader>
                        <MenuHeaderMobileWrapper onClick={() => setMenu2Visible(!isMenu2Visible)}>
                            <MenuHeaderMobile>{contentLandingPageFooter.menu.col2.title}</MenuHeaderMobile>
                            <MenuHeaderMobileDownButton />
                        </MenuHeaderMobileWrapper>
                        
                        <MenuItemsContainer show={isMenu2Visible}>
                            <MenuItem href={sitemap.landing.z.managers} target='_blank' >{contentLandingPageFooter.menu.col2.item1}</MenuItem>
                            <MenuItem href={sitemap.landing.partners.hotels} target='_blank' >{contentLandingPageFooter.menu.col2.item2}</MenuItem>
                            <MenuItem href={sitemap.landing.partners.travelAgents} target='_blank' >{contentLandingPageFooter.menu.col2.item3}</MenuItem>
                            <MenuItem href={sitemap.landing.z.entrepreneurs} target='_blank' >{contentLandingPageFooter.menu.col2.item4}</MenuItem>
                        </MenuItemsContainer>
                    </MenuColumn>

                    <MenuColumn>
                        <MenuHeader>{contentLandingPageFooter.menu.col3.title}</MenuHeader>
                        <MenuHeaderMobileWrapper onClick={() => setMenu3Visible(!isMenu3Visible)}>
                            <MenuHeaderMobile>{contentLandingPageFooter.menu.col3.title}</MenuHeaderMobile>
                            <MenuHeaderMobileDownButton />
                        </MenuHeaderMobileWrapper>
                        
                        <MenuItemsContainer show={isMenu3Visible}>
                            <MenuItem href={sitemap.landing.about} target='_blank' >{contentLandingPageFooter.menu.col3.item1}</MenuItem>
                            <MenuItem href={sitemap.landing.z.press} target='_blank' >{contentLandingPageFooter.menu.col3.item2}</MenuItem>
                            <MenuItem href={sitemap.landing.z.careers} target='_blank' >{contentLandingPageFooter.menu.col3.item3}</MenuItem>
                            <MenuItem href={sitemap.landing.contact} target='_blank' >{contentLandingPageFooter.menu.col3.item4}</MenuItem>
                        </MenuItemsContainer>
                    </MenuColumn>

                    <MenuColumn>
                        <MenuHeader>{contentLandingPageFooter.menu.col4.title}</MenuHeader>
                        <MenuHeaderMobileWrapper onClick={() => setMenu4Visible(!isMenu4Visible)}>
                            <MenuHeaderMobile>{contentLandingPageFooter.menu.col4.title}</MenuHeaderMobile>
                            <MenuHeaderMobileDownButton />
                        </MenuHeaderMobileWrapper>
                        
                        <MenuItemsContainer show={isMenu4Visible}>
                            <MenuItem href={sitemap.landing.help.center} target='_blank' >{contentLandingPageFooter.menu.col4.item1}</MenuItem>
                            <MenuItem href={sitemap.landing.help.faq} target='_blank' >{contentLandingPageFooter.menu.col4.item2}</MenuItem>
                            <MenuItem href={sitemap.landing.z.policies.privacy} target='_blank' >{contentLandingPageFooter.menu.col4.item3}</MenuItem>
                            <MenuItem href={sitemap.landing.z.policies.terms} target='_blank' >{contentLandingPageFooter.menu.col4.item4}</MenuItem>
                            <MenuItem href={sitemap.landing.z.policies.cookie} target='_blank' >{contentLandingPageFooter.menu.col4.item5}</MenuItem>
                        </MenuItemsContainer>
                    </MenuColumn>
                </MenuContent>
            </MenuContainer> }

            <BottomContainer>
                <Divider />

                <BottomContent>
                    <BottomColumnLeft>
                        <StyledLogo color={colorConstants.primaryDark} />

                        <BottomColumnLeftContent>
                            <CopyrightSection>{contentLandingPageFooter.bottom.copyright.desktop.part1 + new Date().getFullYear() + contentLandingPageFooter.bottom.copyright.desktop.part2}</CopyrightSection>
                            {!menu &&
                            <PoliciesSection>
                                <a href={sitemap.landing.z.policies.privacy} target='_blank' rel='noreferrer'>Privacy Policy</a>
                                &nbsp; | &nbsp;
                                <a href={sitemap.landing.z.policies.terms} target='_blank' rel='noreferrer'>Terms of Service</a>
                            </PoliciesSection> }
                        </BottomColumnLeftContent>
                    </BottomColumnLeft>

                    <BottomColumnRight>
                        <SocialIcons social={sitemap.social} alignIcons={'center'} iconSize={20} circleIcons={false} colorIcons={false} />
                        <SocialIconsMobile social={sitemap.social} alignIcons={'center'} iconSize={26} circleIcons={false} colorIcons={false} />

                        <CopyrightSectionMobile>
                            <div>{contentLandingPageFooter.bottom.copyright.mobile.part1 + new Date().getFullYear() + contentLandingPageFooter.bottom.copyright.mobile.part2}</div>
                            <div>{contentLandingPageFooter.bottom.copyright.mobile.part3}</div>
                        </CopyrightSectionMobile>
                        {!menu &&
                            <PoliciesSectionMobile>
                                <a href={sitemap.landing.z.policies.privacy} target='_blank' rel='noreferrer'>Privacy Policy</a>
                                &nbsp; | &nbsp;
                                <a href={sitemap.landing.z.policies.terms} target='_blank' rel='noreferrer'>Terms of Service</a>
                            </PoliciesSectionMobile> }
                    </BottomColumnRight>
                </BottomContent>
            </BottomContainer>
        </Container>
    );
};