import getSymbolFromCurrency from 'currency-symbol-map'; // https://www.npmjs.com/package/currency-symbol-map



/**
 * Method to add currency symbol to an amount
 * 3-letter currency code based on ISO 4217 notation https://www.iso.org/iso-4217-currency-codes.html
 * 
 * @param {String} currency The 3-letter currency
 * @param {Integer} amount The amount to append a symbol to
 * @param {Boolean} round Set to true to round the number and hide the decimal points
 * @returns String with the symbol and amount
 */
export function addCurrencySymbol(currency, amount, round) {
    if(!currency || typeof currency !== 'string') return '';

    let output = getSymbolFromCurrency(currency);

    if(amount !== null) {
        if(Math.sign(amount) === -1) {
            output = '- ' + output + Math.abs(amount).toFixed(2);
        } else {
            output = output + amount.toFixed(2);
        }
    }

    if(round) output = output.split('.')[0];

    return output;
};