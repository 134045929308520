import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { Button } from '../Buttons';
import { colorConstants } from '../../../constants';


const Header = styled(Modal.Header)`

`;

const Title = styled(Modal.Title)`
    font-weight: 700;
    font-size: 20px;
    color: ${colorConstants.darkGrey};
`;

const Body = styled(Modal.Body)`
    font-weight: 400;
    font-size: 14px;
    color: ${colorConstants.black};
`;

const FirstButton = styled(Button)`
    padding-left: 8px;
    padding-right: 8px;
`;

const SecondButton = styled(Button)`
    padding-left: 8px;
    padding-right: 8px;
`;


export const Warning = (props) => {
    const {
        title,
        body,
        firstButton,
        firstButtonColor,
        firstButtonBackgroundColor,
        firstButtonGradient,
        firstButtonClick,
        secondButton,
        secondButtonColor,
        secondButtonBackgroundColor,
        secondButtonGradient,
        secondButtonClick,
        onHide,
        show
    } = props;
    

    return (
        <Modal show={show} onHide={onHide}>
            <Header closeButton>
                <Title>{title}</Title>
            </Header>

            <Body>{body}</Body>

            <Modal.Footer>
                <FirstButton
                    small={true}
                    customColor={firstButtonColor}
                    customBackgroundColor={firstButtonBackgroundColor}
                    gradient={firstButtonGradient}
                    onClick={firstButtonClick}
                >
                    {firstButton}
                </FirstButton>

                { secondButton &&
                <SecondButton
                    small={true}
                    customColor={secondButtonColor}
                    customBackgroundColor={secondButtonBackgroundColor}
                    gradient={secondButtonGradient}
                    onClick={secondButtonClick}
                >
                    {secondButton}
                </SecondButton> }
            </Modal.Footer>
      </Modal>
    );
};