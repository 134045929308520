import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Slider } from '../../custom/FormInput';
import { RoomCancellationPolicyComponent } from './RoomCancellationPolicyComponent';
import { InfoCircle } from 'react-bootstrap-icons';

import { colorConstants } from "../../../constants";



const Container = styled.div`
    
`;

const OptionsSection = styled.div`
    padding: 0px 16px;
    margin-bottom: 16px;
`;

const OptionsHeader = styled.div`
    margin-bottom: 8px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;


const OptionsTitle = styled.div`
    font-weight: 600;
    font-size: 15px;
    color: ${colorConstants.darkGrey};
`;

const OptionsSubtitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: ${colorConstants.mediumGrey};
`;

const TermsLink = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: ${colorConstants.mediumGrey};

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;



export const RoomCancellationPolicy = ({ id, enabled, options, selected, onChange, handleOpenTerms }) => {

    const [active, setActive] = useState(0);

    const handleChange = (e) => {
        setActive(e.target.value);
        if(onChange) onChange(id, options[e.target.value]);
    };

    useEffect(() => {
        if(options.length <= active ) {
            setActive(0);
            if(onChange) onChange(id, options[0]);
        }

    }, [onChange, id, active, options]);


    useEffect(() => {
        if(selected && selected.type !== options[active]) {
            const index = options.indexOf(selected.type);
            setActive(index);
        }

    }, [selected, active, options]);

    const components = useMemo(() => {
        if(!selected || !selected.components) return null;
        // add No-show into components
        let comps = [...selected.components, { type: 'NO_SHOW', label: 'No-show fee', details: "A fee equal to the last applicable cancellation policy applies if you don't show up" }];
        // render components
        return comps.map((c, index) => <RoomCancellationPolicyComponent key={index} component={c} />);
    }, [selected]);

    const subtitle = useMemo(() => {
        if(!selected || !selected.type) return null;
        switch(selected.type) {
            case 'FULL_REFUND':
                return 'Refundable';

            case 'NO_REFUND':
                return 'Non-refundable';

            case 'FEE':
                return 'Cancellation fee';
            
            default:
                return null;
        }
    }, [selected]);


    return (
        <Container>
            { enabled &&
            <div>
                <OptionsHeader>
                    <OptionsTitle>Policy:</OptionsTitle>
                    &nbsp;
                    <OptionsSubtitle>{subtitle}</OptionsSubtitle>
                </OptionsHeader>

                <OptionsSection>
                    <Slider
                        id={id}
                        min={0}
                        max={(options.length - 1)}
                        step={1}
                        value={active}
                        onChange={handleChange}
                    />
                </OptionsSection>
            </div> }

            {components}

            <TermsLink onClick={() => handleOpenTerms('TERMS_CUSTOMERS')}>More details on policy options <InfoCircle /></TermsLink>
        </Container>
    );
};