var _ = require('lodash');



/**
 * Method to clear data fetched from our database from redux state
 * 
 * @param {String} type The selected trip type [ACCOMMODATION OR EXPERIENCE]
 * @param {Array} types Array of Strings with all trip types
 * @param {JSON Object} props The entire component props that includes clear functions
 */
export function clearReduxState(type, types, props){
    // clear hotels previously found in db from state
    if(type === types[0]) props.clearHotels();

    // clear agencies previously found in db from state
    if(type === types[1]) props.clearAgencies();
}


/**
 * Method to generate a slug from given text
 * 
 * @param {String} data The text of the slug
 * @returns The slug of a given text
 */
export function createSlug(data) {
    const regx = /[^a-zA-Z0-9]+/ig;
    let slug = data.toLowerCase().replace(regx, '-');
    return slug;
}


/**
 * Method to prepare trip data before submission to server
 * 
 * @param {JSON Object} data The trip data
 * @returns JSON Object with finalized trip data for submission
 */
export function finalizeTripData(data) {
    const { location, tripPhotoUrls, affiliate, ...rest} = data;
    return {
        ...rest,
        affiliateTitle: affiliate.label,
        affiliateLink: affiliate.url
    };
}


/**
 * Method to prepare trip invite data before submission to server
 * 
 * @param {JSON Object} data The trip data
 * @returns JSON Object with finalized trip invite data for submission
 */
export function generateInviteData(data) {
    const { location, customUrl, affiliate, description, partnerId, tripPhotoUrls, ...inviteData} = data;
    return inviteData;
}


/**
 * Method to find the Room Rate with the closest and greatest maxOccupancy from a given occupancy.
 * Ref. https://stackoverflow.com/questions/8584902/get-the-closest-number-out-of-an-array
 * 
 * @param {Array} rates Array with Room Rate JSON Objects
 * @param {Integer} occupancyTotal Integer number of current occupancy
 * @returns Integer number with the index of the room rate
 */
export function findRoomRateWithClosestGreatestMaxOccupancy(rates, occupancyTotal) {
    // get absolute value of rate.maxOccupancy minus occupancyTotal. We'll use this to find the closest greater maxOccupancy.
    let occupanciesArr = rates.map(k => Math.abs(k.maxOccupancy - occupancyTotal));
    // get minimum occupancy from occupancies absolute values array
    let minOccupancy = Math.min.apply(Math, occupanciesArr);
    // return array index of room rate with closest greater occupancy
    return occupanciesArr.indexOf(minOccupancy);
}


/**
 * Method to format the total trip price
 * 
 * @param {Integer} amount The amount to be displayed
 * @param {String} currencySymbol The currency symbol
 * @returns String with the trip price
 */
export function formatTripPrice(amount, currencySymbol) {
    // format number
    amount = amount / 100;

    // check if it has decimals
    const hasDecimal = (amount % 1) !== 0;

    // round up amount
    let output = _.ceil(amount, 2);
    
    // hide trailing zeros when has no decimal points
    if(hasDecimal) output = output.toFixed(2);
    else output = parseFloat(output.toFixed(2));
    
    // append currency symbol
    if(currencySymbol) output = `${currencySymbol}${output}`;

    return output;
}