import React, { Component } from "react";
import { Router } from "react-router-dom";
import { connect } from 'react-redux';
import styled from 'styled-components';

import { history } from '../../utils';
import { alertActions } from '../../actions';
import { MainRouter } from "../../routers";
import { colorConstants } from "../../constants";

const RootContainer = styled.div`
  background: ${colorConstants.white};
  width: 100%;
  height: 100vh;
`;

class AppContainer extends Component {
  constructor(props){
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }


  render() {
    return (
      <RootContainer id='app' >
        <Router history={history}>
          <MainRouter />
        </Router>
      </RootContainer>
    );
  }
}


const actionCreators = {
  clearAlerts: alertActions.clear
};

const connectedAppContainer = connect(null, actionCreators)(AppContainer);
export { connectedAppContainer as AppContainer };