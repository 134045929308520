/**
 * Method to generate default NFT JSON Object
 * 
 * @param {String} defaultType The default NFT type
 * @returns JSON Object with default NFT values
 */
 export function defaultNFT(userId){
    return {
        userId: userId,
        walletId: null,
        partnerId: null,
        blockchain: '',
        contract: '',
        contractType: '',
        tokenId: '',
        collection: '',
        metadataUrl: '',
        websiteUrl: '',
        type: 'NFT',
        enabled: false,
        skipDetailsPage: false,
        name: '',
        description: '',
        caption: '',
        image: '',
        customUrl: '',
        button: { label: '', url: '' },
    };
}