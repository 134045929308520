import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { colorConstants, deviceConstants } from "../../constants";


export const Container = styled.div`
    width: 100%;
    margin-top: 16px;
    margin-bottom: ${props => props.bottomsocial ? '32px' : '0'};

    & > * {
        margin-top: 16px;
    }

    & > *:first-child {
        margin-top: 0px;
    }
`;

export const Section = styled.div`

`;

export const SectionTopArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SectionTitle = styled.div`
    // margin-bottom: 16px;
    
    font-weight: 600;
    font-size: 13px;
    font-family: ${props => props.type || 'inherit'} !important;
    color: ${props => props.color || colorConstants.black} !important;
`;

export const SectionScrollControls = styled.div`
    display: flex;
    align-items: center;

    & > *:first-child {
        margin-right: 8px;
    }
`;

export const ScrollForwardButton = styled(ChevronRight)`
    padding: 4px;
    
    color: ${props => props.color || colorConstants.black} !important;
    visibility: ${props => props.hide ? 'hidden' : 'visible'};

    :hover {
        cursor: pointer;
    }
`;

export const ScrollBackButton = styled(ChevronLeft)`
    padding: 4px;
        
    color: ${props => props.color || colorConstants.black} !important;
    visibility: ${props => props.hide ? 'hidden' : 'visible'};

    :hover {
        cursor: pointer;
    }
`;

export const SectionContent = styled.div`
    position: relative;
`;

export const SectionList = styled.div`
    padding: 16px 0px;

    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;

    & > * {
        margin-right: 16px;
    }

    & > *:last-child {
        margin-right: 0;
    }

    @media ${deviceConstants.laptop} {
        overflow-x: hidden;
    }
`;

export const SectionListLeftFader = styled.div`
    margin: 16px 0;
    display: ${props => props.hide ? 'none' : 'block'};

    background: linear-gradient(270deg,hsla(0,0%,7%,0),hsla(0,0%,7%,.1));

    z-index: 9;

    width: 24px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
`;

export const SectionListRightFader = styled.div`
    margin: 16px 0;
    display: ${props => props.hide ? 'none' : 'block'};

    background: linear-gradient(270deg,hsla(0,0%,7%,.1),hsla(0,0%,7%,0));

    z-index: 9;

    width: 24px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
`;