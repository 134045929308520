import styled from 'styled-components';
import { colorConstants } from "../../../constants";


export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-header {
        margin-top: 16px;
    }

    .section-header:first-child {
        margin-top: 0px;
    }
`;

export const Title = styled.div`
    width: 100%;
    margin-top: 24px;
    font-weight: 500;
    font-size: 12px;
    color: ${colorConstants.black};
`;