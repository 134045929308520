import React from 'react';
import styled from 'styled-components';
import { ChevronLeft, PlusSquare, Check, List, ArrowRepeat } from 'react-bootstrap-icons';

import { deviceConstants, colorConstants } from "../../../constants";



const Container = styled.div`
    padding: 0 12px;

    background: ${colorConstants.white};
    box-shadow: ${props => props.sticky ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : 'none'};

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    z-index: 200;
`;

const NavBar = styled.div`
    height: 56px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
    
    @media ${deviceConstants.tablet} { 
        max-width: 100%;
    }
`;

const MenuItemButton = styled.div`
    z-index: 202;

    :hover {
        cursor: pointer;
    }
`;

const Title = styled.div`
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    color: ${colorConstants.black};
    text-align: center;

    position: absolute;
    left: 0;
    right: 0;

    z-index: 201;
`;

const SubNavBar = styled.div`
    // display: ${props => props.sticky ? 'block' : 'none'}; // un-comment this to only show when scrolling

    padding: 8px 0;
    color: ${colorConstants.mediumGrey};
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    border-top: 1px solid ${colorConstants.verySoftGreyVersion2};
    
    :hover {
        color: ${colorConstants.primaryDark};
        cursor: pointer;
    }
`;

export const TopBar = (props) => {
    const { title, menuButton, backButton, addButton, submitButton, syncButton, sidebar, sticky, subButton, subTitle, ...rest } = props;

    return (
        <Container sticky={sticky} {...rest} >
            <NavBar>
                {menuButton && !backButton &&
                <MenuItemButton onClick={menuButton} className="topbar-menu-toggle-button" >
                    <List color={colorConstants.black} size={24} />
                </MenuItemButton>}
                
                {!menuButton && backButton &&
                <MenuItemButton onClick={backButton} >
                    <ChevronLeft color={colorConstants.black} size={16} />
                </MenuItemButton>}

                <Title>{title}</Title>
                
                {addButton &&
                <MenuItemButton onClick={addButton} className="topbar-add-button" >
                    <PlusSquare color={colorConstants.black} size={16} />
                </MenuItemButton>}

                {submitButton &&
                <MenuItemButton onClick={submitButton} >
                    <Check color={colorConstants.secondary} size={36} />
                </MenuItemButton>}

                {syncButton &&
                <MenuItemButton onClick={syncButton} >
                    <ArrowRepeat color={colorConstants.secondary} size={28} />
                </MenuItemButton>}
            </NavBar>

            { subButton && subTitle &&
            <SubNavBar sticky={sticky} onClick={subButton} className="topbar-sub-button" >
                {subTitle}
            </SubNavBar> }
        </Container>
    );
};