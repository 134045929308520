import {
    Container,
    Title,
    Header,
    StyledParagraph as Text,
    StyledLink as Link,
    List,
    ListItem as Item,
} from './AssociateTermsPage.styles';
import { sitemap, companyConstants } from '../../../../constants';
import { formatAddressHTML } from '../../../../utils';



export const AssociateTermsPage = () => {
    return (
        <Container>
            <Title>Associate Terms and Conditions</Title>

            <Text>The Bunjee Associates Program allows anyone with an audience to make money by promoting Bunjee offered travel products (accommodation, tours, etc..), goods and services.</Text>


            <Header>1. Acceptance of the Terms and Conditions</Header>

            <Text>When you click on the «Submit Application» button, successfully register a Creator account on https://bunj.ee (“Bunjee Platform”) and join our Associate Program, you accept these terms (“Associate Terms and Conditions”), our <Link to={sitemap.landing.z.policies.terms}>Terms of Service</Link>, our <Link to={sitemap.landing.z.policies.termsForCustomers}>Customer Terms of Service</Link>, our <Link to={sitemap.landing.z.policies.privacy}>Privacy Policy</Link>, our <Link to={sitemap.landing.z.policies.dpa}>Data Processing Addendum</Link>, our <Link to={sitemap.landing.z.policies.cookie}>Cookie Policy</Link>, and other terms that apply to your use of the Bunjee Platform (collectively, “Bunjee Terms”).</Text>

            <Text>An “Associate Program” is a form of business cooperation between BUNJEE PLATFORMS LTD (“Bunjee”, "us", “we”, “our” or the “Company”) and Associates, implemented on Bunjee Platform, where Associates promote the goods and/or services provided by us and our partners via the Associates’ web-resources and provide other services by using the Bunjee Platform.</Text>

            <Text>“Associates” are legal entities or individuals, authorized Creator users of the Bunjee Platform, who carry out advertising or other promotion of our offers using the Bunjee Platform. The Associate and Bunjee are also known as “Party” separately or “Parties” together.</Text>

            <Text>Associates are non-exclusive independent individuals or contractors of Bunjee, and nothing in these Associate Terms and Conditions creates an employment, partnership, joint venture, agency, franchise, or sales representative relationship with us or our partners. Associates are creators and entrepreneurs who are empowered to earn on their own schedule and in their own way. Associates are not required to do the work personally, but if they engage other people to work for them, they are responsible for the acts and omissions of those people. Associates must not misrepresent their relationship with Bunjee or imply any affiliation with Bunjee beyond their limited role as an Associate.</Text>

            <Text>Associates are not authorized to make any representations, contracts or commitments on behalf of Bunjee unless specifically requested or authorized in writing to do so by an authorized employee of Bunjee. Nothing in in these guidelines shall be construed to constitute either Party as the agent or employee of the other Party for any purpose, or create any joint venture or partnership between the Parties whatsoever, and neither Party shall bind, or attempt to bind the other Party to any contract or the performance of any other obligation, or represent to any third party that it has any right to enter into any binding obligation on the other Party's behalf. Specifically, Associates are not authorized to perform any activities which require a real estate license, such as negotiating real estate contracts or leases, performing rent or real estate valuation or appraisals, soliciting potential real estate listings, advertising real estate or creating real estate marketing material, collecting rent, or acting as an agent for a property owner for compensation.</Text>


            <Header>2. Becoming an Associate</Header>

            <Text><u>Registration:</u> To become an Associate you must complete the registration process for a standard user account on Bunjee Platform, then using your standard user account, apply and be approved for a Creator account on Bunjee Platform. If you apply on behalf of a legal entity, you shall use a corporate email address.</Text>

            <Text><u>Application Approval:</u> For your Associate application to be successful you shall provide the information requested during the application process and demonstrate that you meet certain criteria. We are committed to helping as many creators make a living through their passions as we possibly can, and we’re working hard to scale and make our Bunjee Platform and Associate Program available to the wider audience.</Text>

            <Text><u>Approved Associates:</u> After your application is successfully approved by Bunjee, you become an Associate which you’ll be notified via email along with useful resources on how to make the most of the Bunjee Platform as an Associate.</Text>

            <Text>Associates shall immediately inform Bunjee about all cases of failure, inaccuracies and errors related to the Associate Program, goods and/or services offered by us under the Associate Program as well as all our other Services (Services as described in <Link to={sitemap.landing.z.policies.terms}>Bunjee Terms</Link>), which the Associate became aware of during the course of their participation in the Associate Program.</Text>


            <Header>3. Payments and Payouts</Header>

            <Text>Associates are paid by Bunjee for Qualified Sales of Bunjee offered goods and/or services on Bunjee Platform through their Bunjee profile.</Text>

            <Text>A “Qualified Sale” is made after the End User (End Users as described in <Link to={sitemap.landing.z.policies.termsForCustomers}>Bunjee Terms</Link>) has successfully purchased a good and/or service offered by Bunjee via the Associate’s Bunjee profile and any cancellation or refund policies that may apply have completed. For example, on travel accommodation bookings a Qualified Sale is considered the day after the check-out date or in the case of goods a Qualified Sale is considered the day after the refund policy of the good is expired.</Text>

            <Text>As an Associate promoting our travel stays and experiences, every time someone booked a stay or experience through your Bunjee profile, you’ll be paid a percentage of the Qualified Sale’s net profit based on your active subscription plan on the Bunjee Platform at the time of the sale. The percentage for each plan is detailed below:</Text>

            <List>
                <Item>Free Plan: 10% of the net profit</Item>
                <Item>Basic Plan: 30% of the net profit</Item>
                <Item>Premium Plan: 50% of the net profit</Item>
                <Item>Platinum Plan: 90% of the net profit</Item>
            </List>

            <Text>Qualified Sales made on Associates Bunjee profile are tracked and accounted by Bunjee in order to calculate the Associates earnings and payouts and are provided in the Settings page of the Associates Bunjee profile and can be changed without prior notice.</Text>

            <Text><u>Payment Methods:</u> To get paid, you’ll need to set up a payment method and select currency. If you don’t have a payment method set up, you can do so from the Settings page of your Bunjee profile. Payments are made in the currency selected by the Associate, or according to the payout method selected if different. From the same Settings page you can also view your payouts (transaction history, cancellations, and gross earnings). The Associate is paid every 21st day of the month by transferring funds to the Associate’s payment method.</Text>

            <Text>Any commissions of banks, payment systems, and operators accrued in the course of settlement under these Associate Terms and Conditions, shall be borne by the Party whose bank, payment system, or operator has accrued such a commission.</Text>

            <Text>Bunjee and the Associate agree that all monetary obligations arising from the Associate Program can be ceased by Bunjee via offset of their receivables against payables.</Text>


            <Header>4. Prohibited Actions</Header>

            <Text>Associates shall not interfere, in any way, with the operation of the Bunjee Platform if doing so may lead to a violation of its functionality in whole or in part. Any technical or software modifications to the code of the Bunjee Platform or its functionality are not allowed.</Text>

            <Text>Associates must provide End Users using the Associate’s web-resources with accurate and valid information related to the operation of Bunjees' goods and services, the Bunjee Platform, and Services used by the Associate in a timely manner. Don’t mislead End Users about the characteristics, features, and functions of such services.</Text>

            <Text>Associates agree to not conduct illegal activities, violate the rights and legitimate interests of third parties, or perform any act or failure to act if doing so may result in damage to the Parties relations, or the business reputation of Bunjee and their partners. More specifically, the Associate guarantees not to send emails or other messages to the End Users without their explicit consent and/or in violation of applicable law (spam mailing).</Text>


            <Header>5. Changes to the Associate Terms and Conditions</Header>

            <Text>We always strive to support our Associates with as much value as we can with new and innovative ways, throughout our Services and offerings, meaning that these Associate Terms and Conditions may change over time. In order to do that we reserve the right to update these Associate Terms and Conditions from time to time, at our discretion. Any new goods or services which are added to our current Services and offered by us under the Associate Program shall also be subject to these Associate Terms and Conditions. We will make sure to announce any major change in a prominent way. Of course, this document is public on our policies website, and you will be able to see the changes for any new version. Your continued use of our Services following the publishing of updated Associate Terms and Conditions means that you accept and agree to the changes.</Text>


            <Header>6. Marketing Activities</Header>

            <Text>When Associates engage in marketing in connection with the Associate Program, they are considered the sender of any marketing communication they initiate and are responsible for their own compliance under applicable marketing and telecommunications laws. This means, among other things, that Associates are responsible for following applicable law requirements, such as they must not send unsolicited commercial or marketing related messages and must respect unsubscribe and do not contact requests.</Text>

            <Text>Bunjee may make available to Associates marketing collateral to be used at the Associates’ discretion and solely as authorized by Bunjee under these Associate Terms and Conditions. Bunjee is the sole owner of any marketing collateral we make available, Associates’ use of collateral is subject to any branding guidelines or instructions we provide, and Associates may not modify the collateral or remove any Bunjee logos, branding, links or identifiers.</Text>


            <Header>7. Warranties and Indemnities</Header>

            <Text>By agreeing to these Associate Terms and Conditions, you warrant that you possess all necessary rights, permissions, licenses, and consents to enter into this agreement. You shall indemnify, defend (at Bunjee's option), and hold Bunjee harmless from and against any and all disputes, claims, demands, damages, liabilities, losses, expenses, and costs (including reasonable fees and expenses of attorneys and other professionals) that arise out of or result from your acts or omissions in relation to the Associate Program, including but not limited to any negligent, wrongful, or unlawful conduct, or any allegation or claim that your content or marketing materials violate or infringe upon the intellectual property or privacy rights of a third party.</Text>


            <Header>8. Exclusion and Limitation of Liability</Header>

            <Text>Bunjee shall not be held liable for any special, incidental, punitive, exemplary, or consequential damages of any kind, even if we were previously notified of the potential for such damages. Moreover, in no circumstance shall Bunjee's total liability to you in connection with the Associate Program surpass the greater amount of either £100 GBP or the total earnings owed to you under the Associate Program.</Text>


            <Header>9. Interpretation of these Terms and Conditions</Header>

            <Text>Associates are independent individuals or contractors and not employees, intermediaries, or agents of Bunjee. You have no entitlement to, and hereby irrevocably waive any right or claim to, any benefits offered by Bunjee to its employees, at any point in time. It is strictly prohibited to represent yourself as an employee, intermediary, or agent of Bunjee, or to claim to possess the authority to bind Bunjee. In the event that certain provisions of these Associate Terms and Conditions are deemed to be invalid, such invalidity shall not impact the applicability of the remaining provisions, which shall continue to remain in effect. Any provision deemed to be invalid shall be replaced by a provision that closely aligns with the intent of the invalidated provision.</Text>


            <Header>10. Taxes</Header>

            <Text>As an Associate, you are responsible for all taxes owed on payouts earned from the Associate Program. Tax laws may necessitate us to collect and/or report appropriate tax information from Associates, withhold taxes from payouts, or both. It is the responsibility of the Associates to ensure that their tax forms are current, complete, and accurate. You acknowledge and agree that you are solely responsible for determining (i) the applicable tax reporting requirements, (ii) the taxes that must be paid, and (iii) any unique self-registration requirements for your jurisdiction. Additionally, you are solely accountable for paying all taxes, including but not limited to income, sales and use, VAT, and GST, on the payouts earned from the Associate Program. Bunjee is not able to provide tax advice to Associates. If requested or required, we may ask you to provide a valid VAT invoice to Bunjee.</Text>


            <Header>11. Program Administration; Term and Termination; Modification</Header>

            <Text>Bunjee reserves the right to modify, discontinue, or suspend any of the goods or services offered under the Associate Program, as well as the Associate Program itself, including its requirements, benefits, and materials, or your participation in it, at any time and for any reason. Bunjee will decide, at its sole discretion, whether the Associate Program requirements have been met and whether any payouts are due. Certain obligations and responsibilities under these Associate Terms and Conditions will continue to exist even after termination or expiration. If Bunjee suspects or determines that you have engaged in fraudulent activity, tampering, or have violated the Associate Terms and Conditions or the Bunjee Terms, it may take any necessary action, including disqualifying you from participating in the Associate Program and/or withholding your payouts.</Text>

            <Text>For notifications, demands, notices, documents, claims, etc. related to the Associate Program contact us <a href='#contact'>here</a>.</Text>

            <Text>The Associate agrees to provide, at the request of Bunjee, any signed documents, no later than 5 (five) business days from the date of sending such a request, if such documents are necessary for the proper execution of the Associate Program by Bunjee, in particular, to process payments to the Associate. In case of failure to fulfill such a request or its delayed execution, Bunjee isn’t responsible for any adverse consequences to the Associate.</Text>

            <Text>For the purposes of the Associate Program, the Associate provides Bunjee with all necessary and accurate information about their contact and payment details. The Associate will update such information in a timely manner in their Bunjee profile. In cases when Bunjee cannot contact the Associate through their contact details or process a payment using the specified details, Bunjees’ obligations that depend on the relevance of such data is deferred until they are updated by the Associate.</Text>

            <Text>In the event that the Associate provides false information during the acceptance and implementation of the Associate Program, Bunjee reserves the right to either request the submission of accurate information and supporting documents or disqualify the Associate from participating in the Associate Program and/or withholding any payouts to the Associate.</Text>


            <Header id='contact'>12. Contacting us</Header>

            <Text>If you have any questions about these Associate Terms and Conditions, you can contact us:</Text>

            <Text>By email: <a href='mailto:info@bunj.ee'>info@bunj.ee</a></Text>

            <Text>By post:</Text>

            <Text>Support Team<br/>{companyConstants.name.full.toUpperCase()}<br/>{formatAddressHTML(companyConstants.address)}</Text>


            <Text>This document was last updated on March 29, 2023</Text>

        </Container>
    );
};