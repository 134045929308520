import styled from 'styled-components';
import { Wallet2 } from 'react-bootstrap-icons';

import { colorConstants } from '../../../constants';


const Container = styled.div`
    margin-top: 32px;
    margin-bottom: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Text = styled.div`
    margin: 24px 0px 8px 0px;
    font-size: 12px;
    font-weight: 400;
    color: ${colorConstants.softGrey};
    text-align: center;
`;

const CTA = styled.div`
    margin: 0px 0px 16px 0px;
    font-size: 12px;
    font-weight: 400;
    color: ${colorConstants.mediumGrey};
    text-align: center;
`;


export const NoEarningsFound = (props) => {

    return (
        <Container>
            <Wallet2 size={32} color={colorConstants.black} />
            <Text>You have no payouts</Text>
            <CTA>Start earning simply by sharing your passions</CTA>
        </Container>
    );
};