import React from 'react';
import {
    Container,
    Header,
    Title,
    Body,
    AddLinkButton,
    ButtonIcon,
    ButtonText,
} from './TripsMenu.styles';
import Icon from '../../../assets/icons/Icon';
import { colorConstants } from '../../../constants';



export const TripsMenu = ({ show, onClick, onHide, enabledTypes, accommodationSearchActive, experienceSearchActive }) => {

    if(!enabledTypes) return null;

    let tripButtons = [];
    let searchButtons = [];

    enabledTypes.forEach((type, index) => {
        if(type === 'ACCOMMODATION') {
            tripButtons.push(
                <AddLinkButton key={index} onClick={() => onClick('ACCOMMODATION')} >
                    <ButtonIcon>
                        <Icon type='hotel' size={30} customcolor={colorConstants.secondaryDark} />
                    </ButtonIcon>
                    <ButtonText>Add Accommodation</ButtonText>
                </AddLinkButton>
            );
            tripButtons.push(
                <AddLinkButton key={index + '_section'} onClick={() => onClick('ACCOMMODATION_SECTION')} >
                    <ButtonIcon>
                        <Icon type='section_divider' size={30} customcolor={colorConstants.secondaryDark} />
                    </ButtonIcon>
                    <ButtonText>Create Stays Section</ButtonText>
                </AddLinkButton>
            );
            searchButtons.push(
                <AddLinkButton disabled={accommodationSearchActive} key={index} onClick={accommodationSearchActive ? null : () => onClick('ACCOMMODATION_SEARCH')} >
                    <ButtonIcon>
                        <Icon type='hotel_search' size={30} customcolor={accommodationSearchActive ? colorConstants.softGrey : colorConstants.secondaryDark} />
                    </ButtonIcon>
                    <ButtonText disabled={accommodationSearchActive} >Search Stays</ButtonText>
                </AddLinkButton>
            );
        } else if(type === 'EXPERIENCE') {
            tripButtons.push(
                <AddLinkButton key={index} onClick={() => onClick('EXPERIENCE')} >
                    <ButtonIcon>
                        <Icon type='hiking' size={30} customcolor={colorConstants.secondaryDark} />
                    </ButtonIcon>
                    <ButtonText>Add Experience</ButtonText>
                </AddLinkButton>
            );
            searchButtons.push(
                <AddLinkButton disabled={experienceSearchActive} key={index} onClick={experienceSearchActive ? null : () => onClick('EXPERIENCE_SEARCH')} >
                    <ButtonIcon>
                        <Icon type='hiking_search' size={30} customcolor={experienceSearchActive ? colorConstants.softGrey : colorConstants.secondaryDark} />
                    </ButtonIcon>
                    <ButtonText disabled={experienceSearchActive} >Search Experiences</ButtonText>
                </AddLinkButton>
            );
        }
    });

    return (
        <Container show={show} onHide={onHide} centered={true} >
            <Header closeButton>
                <Title>Add Trip</Title>
            </Header>

            <Body>{tripButtons}</Body>


            <Header>
                <Title>Add Search Link</Title>
            </Header>

            <Body>{searchButtons}</Body>
      </Container>
    );
};