import React, { useRef, useMemo } from 'react';

import {
    Container,
    TopSection, Header, Name, Location, EnableToggle,
    Group, GroupInputTitle, GroupInputText, WarningText,
    UploadPhotosContainer, FileUploader, MonetizationButtonSecion,
    NoticeMessage, PartnerStatus,
} from './EditTrip.styles';
import { GradientFormInput, GradientRichEditor } from '../../custom/FormInput';
import { UploadPhoto } from '../../custom/Images';
import { SimpleLinkInput } from '../../custom/FormInput';
import { useWindowSize } from '../../../utils';
import { LinkMonetizationButton } from '../../custom/Buttons';
import { PotentialEarningsTable } from '../../custom/Monetization';


export const EditTrip = ({
    trip,
    userAccountType,
    handleUpgrade,
    handleChange,
    handleDescriptionChange,
    handleDescriptionLengthChange,
    tripDescriptionLength,
    handleIsBookable,
    handleUploadPhoto1,
    handleUploadPhoto2
}) => {

    // detect screen size
    const [width] = useWindowSize();

    // calculate max image width
    const maxImageWidth = useMemo(() => {
        if(width < 991) return (width - (3 * 32)) / 2; // for mobile
        
        // calculate app section minus the padding on larger screens
        let section = 0;
        if(width >= 991 && width < 1440) {
            section = width - (width * 0.66); // for tablet
        } else if(width >= 1440 && width < 1600) {
            section = width - (width * 0.70); // for laptop
        } else {
            section = width - (width * 0.75); // for laptop
        }

        return (section - (3 * 32)) / 2;
    }, [width]);
    

    // open file browser on profile click
    const inputPhoto1 = useRef(null);
    const openFileUploader1 = () => {
      inputPhoto1.current.click();
    };
    const inputPhoto2 = useRef(null);
    const openFileUploader2 = () => {
      inputPhoto2.current.click();
    };

    const partnershipInProgressNotice = useMemo(() => {
        if(!trip.partnerId) {
            return (
                <Group>
                    <NoticeMessage>
                        {`We're negotiating a direct partnership with the ${trip.type === 'ACCOMMODATION' ? 'property' : 'agency'} to help you earn money from this trip. You'll get an email once it's done.`}
                    </NoticeMessage>
                </Group>
            );
        }

        return null;
    }, [trip.partnerId, trip.type]);

    return (
        <Container>
            
            <TopSection>
                <Header>
                    <Name>{trip.name}</Name>
                    <Location>{trip.city + ', ' + trip.country}</Location>
                </Header>

                <EnableToggle type="switch" name={'trip-enable'} checked={trip.enabled} onChange={handleChange} />
            </TopSection>

            { trip && trip.tripPhotoUrls &&
            <Group>
                <GroupInputTitle>Upload Trip Photos</GroupInputTitle>

                <UploadPhotosContainer>
                    <UploadPhoto showEdit={true} url={trip.tripPhotoUrls[0] ? trip.tripPhotoUrls[0] + '&' + Date.now() : null} onClick={openFileUploader1} width={maxImageWidth + 'px'} />
                    <FileUploader type='file' accept="image/*" id='photo1' ref={inputPhoto1} onChange={handleUploadPhoto1} />
                    <UploadPhoto showEdit={true} url={trip.tripPhotoUrls[1] ? trip.tripPhotoUrls[1] + '&' + Date.now() : null} onClick={openFileUploader2} width={maxImageWidth + 'px'} />
                    <FileUploader type='file' accept="image/*" id='photo2' ref={inputPhoto2} onChange={handleUploadPhoto2} />
                </UploadPhotosContainer>
            </Group> }

            <Group>
                <GroupInputTitle>Share Your Experience</GroupInputTitle>
                <GradientRichEditor value={trip.description} withDelay={500} onChange={handleDescriptionChange} maxLength={10000} getCurrentTextLength={handleDescriptionLengthChange} />
                { (!trip.description || trip.description.length < 57 || tripDescriptionLength < 50) && <WarningText>Write at least 50 characters description</WarningText> }
            </Group>
            
            { trip.partnerId &&
            <Group>
                <GroupInputTitle>Partner and monetize trip</GroupInputTitle>
                <MonetizationButtonSecion>
                    <LinkMonetizationButton
                        type='button'
                        status={trip.isBookable}
                        onClick={handleIsBookable} />
                </MonetizationButtonSecion>
            </Group> }

            { trip.partnerId && trip.isBookable &&
            <Group>
                { trip.partner && !trip.partner.contentStatus && <GroupInputTitle>Direct Partner Status</GroupInputTitle> }
                { trip.partner && !trip.partner.contentStatus && <PartnerStatus>Setting up, no further action needed ...</PartnerStatus> }
                { trip.partner && trip.partner.contentStatus && <PotentialEarningsTable userAccountType={userAccountType} handleUpgrade={handleUpgrade} /> }
            </Group> }

            { (!trip.partnerId || !trip.isBookable) &&
            <Group>
                <GroupInputTitle>Add Custom Link</GroupInputTitle>
                <SimpleLinkInput
                    data={trip.affiliate}
                    hasBorder={true}
                    handleLabelChange={handleChange}
                    handleUrlChange={handleChange} />
            </Group> }

            <Group>
                <GroupInputTitle>Edit Trip Listing URL</GroupInputTitle>
                <GradientFormInput box2='true' type="text" name="customUrl" value={trip.customUrl} onChange={handleChange} />
            </Group>

            { (!trip.partnerId || !trip.isBookable) &&
            <Group row='true' >
                <GroupInputText>Skip Trip Listing Page</GroupInputText>
                <EnableToggle type="switch" name={'skipDetailsPage-enable'} checked={trip.skipDetailsPage} onChange={handleChange} />
            </Group> }

            {partnershipInProgressNotice}
        </Container>
    );
};