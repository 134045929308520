import React from 'react';
import styled from 'styled-components';
import { CloudUploadFill, Upload } from 'react-bootstrap-icons';

import { colorConstants } from "../../../constants";
import Placeholder from '../../../assets/profile_image_placeholder.JPG';

const Container = styled.div`
    position: relative;
    
    :hover {
        cursor: ${props => props.cursor};
    }
`;

const AvatarContainer = styled.div`
    ${({ noborder }) => noborder && `
        background: none;
    `}

    ${({ noborder, color }) => !noborder && color && `
        background: ${color};
    `}

    ${({ noborder, color }) => !noborder && !color && `
        background: ${colorConstants.gradientHorizontal};
    `}

    border-radius: 1000px !important;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    float: left;
`;

const Image = styled.img`
    border-radius: 1000px;
`;

const UploadIcon = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
`;

const EditIcon = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    z-index: 9;
`;

const Fader = styled.div`
    border-radius: 1000px;
    background: black;
    opacity: 0.1;
    z-index: 8;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;


export const Avatar = (props) => {
    const { mode, url, imageSize, border, borderColor, onClick, cursor } = props;
    let borderSize = border ? imageSize + 6 : imageSize;

    // set limit on adding a border
    if(imageSize <= 40) borderSize = imageSize;

    return (
        <Container onClick={onClick} cursor={cursor} >
            <AvatarContainer style={{width: borderSize + "px", height: borderSize + "px"}} noborder={!border || imageSize <= 40} color={borderColor}>
                <Image
                style={{width: imageSize + "px", height: imageSize + "px"}}
                src={url ? url : Placeholder}
                alt="profile" />
            </AvatarContainer>

            {!url && mode === 'upload' &&
            <UploadIcon>
                <CloudUploadFill color={colorConstants.primaryDark} size={32} />
            </UploadIcon>}

            {url && mode === 'upload' &&
            <Fader style={{width: imageSize + "px", height: imageSize + "px"}} /> }

            {url && mode === 'upload' &&
            <EditIcon>
                <Upload color={colorConstants.white} size={24} />
            </EditIcon>}
        </Container>
    );
};