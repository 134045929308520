import React, { useState, useMemo, useRef } from 'react';
import {
    GradientBorder,
    LeftColumn,
    InputContainer,
    StyledInput,
    InputButtons,
    InputTitle,
    InputButton,
    BottomControls,
    OpenButton,
    RightColumn,
    StyledFormCheck,
    DeleteButton
} from './SimpleLinkInput.styles';
import { DragHandle } from "../../DragHandle";
import { SectionLabelInput } from './SectionLabelInput';
import Icon from '../../../../assets/icons/Icon';
import { colorConstants } from '../../../../constants';


export const SimpleLinkInput = ({
    data,
    dragHandleProps,
    hasBorder,
    handleEdit,
    handleLabelChange,
    handleUrlChange,
    hasControls,
    handleEnable,
    handleDelete,
    handleOpenUrl
}) => {

    const [isEditingLabel, setEditingLabel] = useState(false);
    const [isEditingUrl, setEditingUrl] = useState(false);

    var labelInputRef = useRef(null);
    var urlInputRef = useRef(null);

    const isLabelInputDisabled = useMemo(() => {
        if(data && (data.type === 'YOUTUBE' || data.type === 'YOUTUBESHORT')) return true;
        return false;
    }, [data]);

    function onLabelClick() {
        if(isLabelInputDisabled) return;

        if(data && data.type === 'GITHUB' && handleEdit) {
            handleEdit(data);
            return;
        }
        
        setEditingLabel(!isEditingLabel);
        labelInputRef.current.focus();
    }

    function onUrlClick() {
        if(data && data.type === 'GITHUB' && handleEdit) {
            handleEdit(data);
            return;
        }

        setEditingUrl(!isEditingUrl);
        urlInputRef.current.focus();
    }

    function notFocused(event){
        event.preventDefault();
    
        if(event.type === 'blur') {
            if(event.target.name === 'simple-link-label') setEditingLabel(false);
            else if(event.target.name === 'simple-link-url') setEditingUrl(false);
        }
    }

    const linkTypeIcon = useMemo(() => {
        if(!data || !data.type) return null;

        let type = null;
        let platform = null;

        switch(data.type) {
            case 'SIMPLE':
                return null;

            case 'BLOG': {
                type = 'open_book';
                break;
            }

            case 'GITHUB': {
                type = 'social';
                platform = 'github';
                break;
            }

            case 'YOUTUBE': {
                type = 'social';
                platform = 'youtube';
                break;
            }

            case 'YOUTUBESHORT': {
                type = 'social';
                platform = 'youtube';
                break;
            }

            case 'INSTAGRAMREEL': {
                type = 'social';
                platform = 'instagram';
                break;
            }

            case 'TIKTOK': {
                type = 'social';
                platform = 'tiktok';
                break;
            }

            case 'SPOTIFY': {
                type = 'social';
                platform = 'spotify';
                break;
            }

            default:
                return null;
        }

        return <Icon type={type} platform={platform} circle={true} customcolor={colorConstants.mediumGrey} size={18} />;
    }, [data]);


    /*** SECTION LABEL COMPONENT ***/
    if(data.isSectionLabel) return <SectionLabelInput
                                        data={data}
                                        dragHandleProps={dragHandleProps}
                                        handleLabelChange={handleLabelChange}
                                        handleDelete={handleDelete} />;


    return (
        <GradientBorder border={hasBorder} >
            <LeftColumn>
                <InputContainer>
                    <InputButtons enable={!isEditingLabel ? 1 : null} >
                        <InputTitle onClick={() => onLabelClick()} >{data.label ? data.label : (isLabelInputDisabled ? '' : 'Title')}</InputTitle>
                        { !isLabelInputDisabled && <InputButton onClick={() => onLabelClick()} /> }
                    </InputButtons>

                    <StyledInput
                        onFocus={notFocused}
                        onBlur={notFocused}
                        ref={labelInputRef}
                        enable={isEditingLabel ? 1 : null}
                        disabled={isLabelInputDisabled}
                        type='text'
                        name='simple-link-label'
                        value={data.label}
                        onChange={data.id ? (event) => handleLabelChange(event, data.id) : handleLabelChange} />
                </InputContainer>

                <InputContainer>
                    <InputButtons enable={!isEditingUrl ? 1 : null} >
                        <InputTitle onClick={() => onUrlClick()} soft={true}>{data.url ? data.url : 'Url'}</InputTitle>
                        <InputButton onClick={() => onUrlClick()} />
                    </InputButtons>

                    <StyledInput
                        onFocus={notFocused}
                        onBlur={notFocused}
                        ref={urlInputRef}
                        enable={isEditingUrl ? 1 : null}
                        type='url'
                        name='simple-link-url'
                        value={data.url}
                        onChange={data.id ? (event) => handleUrlChange(event, data.id) : handleUrlChange} />
                </InputContainer>

                <BottomControls deactivate={!hasControls} >
                    { handleOpenUrl && <OpenButton size={34} onClick={() => handleOpenUrl(data.url)} /> }
                    { linkTypeIcon }
                </BottomControls>
            </LeftColumn>

            <RightColumn deactivate={!hasControls} >
                { handleEnable && <StyledFormCheck type="switch" name="simple-link-enable" checked={data.enabled} onChange={(event) => handleEnable(event, data.id)} /> }
                { dragHandleProps && <DragHandle size={24} customcolor={colorConstants.darkGrey} {...dragHandleProps} /> }
                { handleDelete && <DeleteButton size={32} onClick={() => handleDelete(data.id)} /> }
            </RightColumn>
        </GradientBorder>
    );
};