import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { sitemap } from '../constants';

// The react private route component renders a route component if the user is logged in,
// otherwise it redirects the user to the login page and forwards any props.
export const RequestLoginRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{ ...props.location, from: props.location.pathname, pathname: sitemap.auth.login }} />
    )} />
)