import { cryptoWalletReducerConstants } from '../constants';

export function crypto(state = {}, action) {

  switch (action.type) {

    case cryptoWalletReducerConstants.WALLET_CONNECT_REQUEST:
        return { ...state, connecting: true };

    case cryptoWalletReducerConstants.WALLET_CONNECT_SUCCESS: {
        const { connecting, ...rest } = state;
        return { ...rest, wallets: action.wallets };
    }

    case cryptoWalletReducerConstants.WALLET_CONNECT_FAILURE: {
        const { connecting, ...rest } = state;
        return rest;
    }


    case cryptoWalletReducerConstants.WALLET_DISCONNECT_REQUEST:
        return { ...state, disconnecting: true };

    case cryptoWalletReducerConstants.WALLET_DISCONNECT_SUCCESS: {
        const { disconnecting, ...rest } = state;
        return { ...rest, wallets: action.wallets };
    }

    case cryptoWalletReducerConstants.WALLET_DISCONNECT_FAILURE: {
        const { disconnecting, ...rest } = state;
        return rest;
    }

    
    case cryptoWalletReducerConstants.WALLET_GETALL_REQUEST: {
        const { wallets, initWallets, ...rest } = state;
        return { ...rest, loadingWallets: true };
    }
        

    case cryptoWalletReducerConstants.WALLET_GETALL_SUCCESS: {
        const { loadingWallets, ...rest } = state;
        return { ...rest, initWallets: true, wallets: action.wallets };
    }

    case cryptoWalletReducerConstants.WALLET_GETALL_FAILURE: {
        const { loadingWallets, ...rest } = state;
        return rest;
    }


    case cryptoWalletReducerConstants.WALLET_GET_ASSETS_REQUEST: {
        const { initAssets, ...rest } = state;
        return { ...rest, loadingAssets: true };
    }

    case cryptoWalletReducerConstants.WALLET_GET_ASSETS_SUCCESS: {
        const { loadingAssets, ...rest } = state;
        return { ...rest, initAssets: true, assets: action.assets };
    }

    case cryptoWalletReducerConstants.WALLET_GET_ASSETS_FAILURE: {
        const { loadingAssets, ...rest } = state;
        return rest;
    }
    

    case cryptoWalletReducerConstants.WALLET_CLEAR_ALL: {
        const { wallets, initWallets, ...rest } = state;
        return rest;
    }

    case cryptoWalletReducerConstants.WALLET_ASSETS_CLEAR_ALL: {
        const { assets, initAssets, ...rest } = state;
        return rest;
    }


    default:
        return state;
  }
}