import React, { Component } from "react";
import { connect } from 'react-redux';
import validator from 'validator';

import { ScreenContainer } from './TravelLandingContainer.styles';
import { Header } from '../../../components/custom/TopBar';
import { HomeFooter } from '../../../components/custom/Footer';
import { TravelLandingPage } from "../../../components/home";
import { authActions, homepageActions } from '../../../actions';
import { contentLandingPageHeader, sitemap } from '../../../constants';
import { history, analytics } from '../../../utils';


class TravelLandingContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                name: '',
                igHandle: '',
                primaryPlatform: '',
                email: '',
                type: 'CREATOR',
                note: 'Travel',
            },
            submitted: false,
            captcha: false,
            isEmail: false,
            isLink: false,
            scrollToSection: '', // used to scroll to particular section
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleScrollReset = this.handleScrollReset.bind(this);
        this.handleScrollToForm = this.handleScrollToForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCaptcha = this.handleCaptcha.bind(this);
        this.handleOpenCreatorPage = this.handleOpenCreatorPage.bind(this);
    }

    componentDidMount(){
        this.props.loadCsrfToken(); // load csrf token in cookies
    }

    componentDidUpdate() {
        if(this.state.submitted && !this.props.joining && this.props.success && this.state.user.name && this.state.user.email) {
            // reset submitted status
            this.setState({ ...this.state, submitted: false });
            // redirect to registration page
            history.push({
                pathname: sitemap.auth.register,
                search: `?name=${this.state.user.name}&email=${this.state.user.email}`, // use landing page form data to auto-fill registration form
                state: {
                    from: sitemap.landing.home,
                }
            });
        }
    }

    handleScrollReset(){
        if(this.state.scrollToSection) {
            this.setState({ ...this.state, scrollToSection: '' });
        }
    }

    handleScrollToForm(buttonName){
        this.setState({ ...this.state, scrollToSection: 'registration-form' });
        analytics.event('Travel Homepage', 'Clicked Go To Registration Form', buttonName); // register click event
    }

    handleChange(event) {
        let { name, value } = event.target;
        const { user } = this.state;

        // format type
        if(name === 'type') {
            value = value.toUpperCase();
        }

        // append symbol @ on igHandle
        if(name === 'igHandle' && !value.startsWith("@")) value = "@" + value;

        this.setState({
            user: {
                ...user,
                [name]: value
            },
            submitted: false,
            isEmail: false
        });
    }

    handleFocus(event) {
        let { name, value } = event.target;
        // append symbol @ on igHandle when user clicks on the input box
        if(name === 'igHandle' && !value.startsWith("@")) {
            this.setState({ ...this.state, user: { ...this.state.user, igHandle: '@' + this.state.user.igHandle } });
        }
    }

    handleBlur(event) {
        let { name, value } = event.target;
        // remove the @ symbol if user did not type any username
        if(name === 'igHandle' && value === "@") {
            this.setState({ ...this.state, user: { ...this.state.user, igHandle: '' } });
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        const validatorUrlOptions = {
            protocols: ['http', 'https'],
            require_host: true,
        };
        
        this.setState({
            submitted: true,
            isEmail: validator.isEmail(this.state.user.email), // validate email
            isLink: validator.isURL(this.state.user.primaryPlatform, validatorUrlOptions), // validate collection link
        }, () => {
            const { user, captcha, isEmail, isLink } = this.state;
            if (isEmail && isLink && captcha && user.name && user.igHandle && user.primaryPlatform && user.email && user.type) {
                // register user for early access
                this.props.getEarlyAccess(user);
                analytics.event('Travel Homepage', 'Registration Form Submittion', 'Clicked Form Submit Button'); // register click event
            }
        });
    }

    handleCaptcha(value) {
        if (value) this.setState({ captcha: true });
    }

    handleOpenCreatorPage(data) {
        if(!data || !data.username) return;
        
        history.push({
            pathname: `/${data.username}`,
            state: {
                from: sitemap.landing.home,
            }
        });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <Header
                    border={true}
                    showLogo={true}
                    logoLinkTo={sitemap.landing.home}
                    primaryButtonText={contentLandingPageHeader.earlyAccess}
                    primaryButtonHandleClick={() => this.handleScrollToForm('Header Apply Button')}
                    primaryButtonGradientStyle={true}
                    buttons={[ { text: contentLandingPageHeader.login, link: sitemap.auth.login } ]}
                />

                <TravelLandingPage
                    alert={this.props.alert}
                    handleScrollReset={this.handleScrollReset}
                    scrollToSection={this.state.scrollToSection}
                    handleScrollToForm={this.handleScrollToForm}
                    handleChange={this.handleChange}
                    handleFocus={this.handleFocus}
                    handleBlur={this.handleBlur}
                    handleSubmit={this.handleSubmit}
                    handleCaptcha={this.handleCaptcha}
                    registering={this.props.joining}
                    user={this.state.user}
                    submitted={this.state.submitted}
                    captcha={this.state.captcha}
                    isEmail={this.state.isEmail}
                    isLink={this.state.isLink}
                    handleOpenCreatorPage={this.handleOpenCreatorPage}
                />

                <HomeFooter menu={false} />
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { alert } = state;
    const { joining, success } = state.homepage;

    // export state data to props
    return { alert, joining, success };
}

const actionCreators = {
    getEarlyAccess: homepageActions.joinWaitlist,
    loadCsrfToken: authActions.loadCsrfToken
};

const connectedTravelLandingContainer = connect(mapState, actionCreators)(TravelLandingContainer);
export { connectedTravelLandingContainer as TravelLandingContainer };