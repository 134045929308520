/**
 * Double React Slider Range
 * Refs:
 * - https://zillow.github.io/react-slider/
 * - https://www.npmjs.com/package/react-slider
 */
import React from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';

import { colorConstants } from "../../../constants";



const Range = styled(ReactSlider)`
    width: 100%;
    height: 20px;
    -webkit-appearance: none;
`;

const StyledThumb = styled.div`
    height: 14px;
    width: 14px;
    margin-top: -6px;
    cursor: grab;
    color: ${colorConstants.white};
    background: ${colorConstants.primaryDark};
    border: 0px solid #000000;
    border-radius: 50%;
    box-shadow: 0px 0px 0px #000000;
    -webkit-appearance: none;
`;


const Thumb = (props, state) => <StyledThumb {...props}/>;

const StyledTrack = styled.div`
    height: 2px;
    box-shadow: 0px 0px 0px #000000;
    border-radius: 13px;
    background: ${props => (props.index === 2 ? colorConstants.softGrey2 : props.index === 1 ? colorConstants.gradientHorizontal : colorConstants.softGrey2)};
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;


export const DoubleSlider = ({ id, min, max, step, value, minDistance, onChange }) => {
    
    return <Range
                name={id + '-slider'}
                min={min || 0}
                max={max || 100}
                step={step || 1}
                minDistance={minDistance || 1}
                value={value}
                renderTrack={Track}
                renderThumb={Thumb}
                pearling
                onChange={onChange} />;
};