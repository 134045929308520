import React, { useRef, useMemo } from 'react';

import {
    Container,
    StyledForm,
    StyledFormGroup,
    FormInputTitle,
    StyledFormText,
    StyledButton,
    UploadPhotosContainer,
    FileUploader,
    MonetizationButtonSecion,
    NoticeMessage
} from './AddTrip.styles';
import {
    GradientFormInputSearchBox,
    GradientRichEditor,
    GradientFormInput
} from '../../custom/FormInput';
import { UploadPhoto } from '../../custom/Images';
import { SearchAutocompleteResults } from '../../custom/Search';
import { contentAddTripPage } from "../../../constants";
import TripInfo from './TripInfo';
import { SimpleLinkInput } from '../../custom/FormInput';
import { LinkMonetizationButton } from '../../custom/Buttons';
import { PotentialEarningsTable } from '../../custom/Monetization';


export const AddTrip = (props) => {
    const {
        trip,
        userAccountType,
        handleUpgrade,
        googleSearch,
        submitted,
        creating,
        hasPhotos,
        handleSubmit,
        handleSendInviteRequest,
        handleChange,
        handleDescriptionChange,
        handleDescriptionLengthChange,
        tripDescriptionLength,
        handleChangeLocation,
        handleSelectedLocation,
        handleChangeName,
        handleSelectedName,
        handleIsBookable,
        alert,
        isDuplicate,
        handleUploadPhoto1,
        handleUploadPhoto2
    } = props;

    // open file browser on profile click
    const inputPhoto1 = useRef(null);
    const openFileUploader1 = () => {
      inputPhoto1.current.click();
    };
    const inputPhoto2 = useRef(null);
    const openFileUploader2 = () => {
      inputPhoto2.current.click();
    };

    const isExperience = useMemo(() => {
        if(trip.type === 'EXPERIENCE') return true;
        return false;
    }, [trip.type]);

    const partnershipInProgressNotice = useMemo(() => {
        if(trip.googlePlaceId && !trip.partnerId) {
            return (
                <StyledFormGroup>
                    <NoticeMessage>
                        {`We'll get to work and contact the ${isExperience ? 'agency' : 'property'} asap to negotiate a direct partnership to help you earn money from this trip. You'll get an email once it's done.`}
                    </NoticeMessage>
                </StyledFormGroup>
            );
        }

        return null;
    }, [trip.googlePlaceId, trip.partnerId, isExperience]);


    return (
        <Container>
            <StyledForm id="add-trip">
                <StyledFormGroup>
                    <FormInputTitle>Location</FormInputTitle>
                    <GradientFormInputSearchBox type="text" placeholder={contentAddTripPage.placeholders.locationSearch} name="location" value={trip.location} onChange={handleChangeLocation} />
                    { submitted && !trip.location && <StyledFormText>Trip location is required</StyledFormText> }
                    { alert && alert.message && !creating && <StyledFormText>{alert.message}</StyledFormText> }
                    
                    { trip.location && googleSearch.location.locations &&
                    <SearchAutocompleteResults
                        items={googleSearch.location.locations}
                        isLocation={true}
                        handleClick={handleSelectedLocation}
                        padding='32px'
                        topoffset='-1px'
                        isFullScreen={true} /> }
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>{isExperience ? 'Experience' : 'Accommodation'} Name</FormInputTitle>
                    <GradientFormInputSearchBox type="text" placeholder={isExperience ? contentAddTripPage.placeholders.experienceNameSearch : contentAddTripPage.placeholders.accommodationNameSearch} name="name" value={trip.name} onChange={handleChangeName} />
                    { submitted && !trip.name && <StyledFormText>Trip name is required</StyledFormText> }
                    { alert && alert.message && !creating && <StyledFormText>{alert.message}</StyledFormText> }
                    { trip.googlePlaceId && isDuplicate && <StyledFormText>Trip already added</StyledFormText> }

                    { trip.name && googleSearch.place.places &&
                    <SearchAutocompleteResults
                        items={googleSearch.place.places}
                        schema="description"
                        handleClick={handleSelectedName}
                        padding='32px'
                        topoffset='-1px'
                        isFullScreen={true} /> }
                </StyledFormGroup>

                <StyledFormGroup>
                    { trip.googlePlaceId && googleSearch.place.preview && <FormInputTitle>Trip Info</FormInputTitle> }
                    { trip.googlePlaceId && googleSearch.place.preview && <TripInfo place={googleSearch.place.preview} /> }
                </StyledFormGroup>

                { !isDuplicate &&
                <StyledFormGroup>
                  { trip.googlePlaceId && <FormInputTitle>Share your experience</FormInputTitle> }
                  { trip.googlePlaceId && <GradientRichEditor value={trip.description} onChange={handleDescriptionChange} maxLength={10000} getCurrentTextLength={handleDescriptionLengthChange} /> }
                  { submitted && (!trip.description || tripDescriptionLength < 50) && <StyledFormText>Write at least 50 characters description</StyledFormText> }
                </StyledFormGroup> }

                { !isDuplicate &&
                <StyledFormGroup>
                    { trip.googlePlaceId && <FormInputTitle>Upload Photos</FormInputTitle> }

                    <UploadPhotosContainer>
                        { trip.googlePlaceId && <UploadPhoto showEdit={true} url={trip.tripPhotoUrls[0]} onClick={openFileUploader1} /> }
                        { trip.googlePlaceId && <FileUploader type='file' accept="image/*" id='photo1' ref={inputPhoto1} onChange={handleUploadPhoto1} /> }
                        { trip.googlePlaceId && <UploadPhoto showEdit={true} url={trip.tripPhotoUrls[1]} onClick={openFileUploader2} /> }
                        { trip.googlePlaceId && <FileUploader type='file' accept="image/*" id='photo2' ref={inputPhoto2} onChange={handleUploadPhoto2} /> }
                    </UploadPhotosContainer>
                    { trip.googlePlaceId && submitted && !hasPhotos && <StyledFormText>You should add at least one photo</StyledFormText> }
                </StyledFormGroup> }

                { !isDuplicate &&
                <StyledFormGroup>
                    { trip.googlePlaceId && <FormInputTitle>Customize Trip URL</FormInputTitle> }
                    { trip.googlePlaceId && <GradientFormInput type="text" name="customUrl" value={trip.customUrl} onChange={handleChange} /> }
                    { trip.googlePlaceId && alert && alert.message && !creating && <StyledFormText>{alert.message}</StyledFormText> }
                </StyledFormGroup> }

                { trip.googlePlaceId && trip.partnerId && !isDuplicate &&
                <StyledFormGroup>
                    <FormInputTitle>Partner and monetize trip</FormInputTitle>
                    <MonetizationButtonSecion>
                        <LinkMonetizationButton
                            type='button'
                            status={trip.isBookable}
                            onClick={handleIsBookable} />
                    </MonetizationButtonSecion>
                </StyledFormGroup> }

                { trip.googlePlaceId && trip.partnerId && trip.isBookable && !isDuplicate &&
                <StyledFormGroup>
                    <PotentialEarningsTable userAccountType={userAccountType} handleUpgrade={handleUpgrade} />
                </StyledFormGroup> }

                { trip.googlePlaceId && (!trip.partnerId || !trip.isBookable) && !isDuplicate &&
                <StyledFormGroup>
                    <FormInputTitle>Add Custom Link</FormInputTitle>
                    <SimpleLinkInput
                        data={trip.affiliate}
                        hasBorder={true}
                        handleLabelChange={handleChange}
                        handleUrlChange={handleChange} />
                </StyledFormGroup> }

                {partnershipInProgressNotice}
            </StyledForm>

            <StyledButton
                static={trip.name && trip.googlePlaceId && !isDuplicate} // set button position to static
                gradient={true}
                type="submit"
                disabled={!trip.name || isDuplicate}
                onClick={trip.name && !trip.googlePlaceId ? handleSendInviteRequest : handleSubmit}
            >
                {trip.name && !trip.googlePlaceId ? 'Send Request' : 'Add Trip'}
            </StyledButton>
        </Container>
    );
};