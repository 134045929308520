import React, { useMemo } from "react";
import {
    Container,
    CheckoutSummaryTitle, CheckoutSummaryText, CheckoutSummaryDetailsSection,
    CheckoutSummaryDetailsRow, CheckoutSummaryDetailsText,
} from './UpgradeCheckoutSummary.styles';
import { addCurrencySymbol } from '../../../utils';



export const UpgradeCheckoutSummary = ({
    summary, plan, isMonthly,
    monthlyPrice, yearlyPrice,
    isDataReady, data,
}) => {

    const proratedCost = useMemo(() => isDataReady ? addCurrencySymbol(data.currency, data.cost) : 0, [isDataReady, data]);
    const showProratedCost = useMemo(() => isDataReady ? (data.cost !== 0) : false, [isDataReady, data]); // toggle field on/off

    const proratedCredit = useMemo(() => isDataReady ? addCurrencySymbol(data.currency, data.credit) : 0, [isDataReady, data]);
    const showProratedCredit = useMemo(() => isDataReady ? (data.credit !== 0) : false, [isDataReady, data]); // toggle field on/off

    const subtotal = useMemo(() => isDataReady ? addCurrencySymbol(data.currency, data.subtotal) : 0, [isDataReady, data]);

    const totalDiscounts = useMemo(() => isDataReady ? addCurrencySymbol(data.currency, data.discounts) : 0, [isDataReady, data]);
    const showDiscount = useMemo(() => isDataReady ? (data.discounts !== 0) : false, [isDataReady, data]); // toggle field on/off

    const total = useMemo(() => isDataReady ? addCurrencySymbol(data.currency, data.total) : 0, [isDataReady, data]);

    const startingBalance = useMemo(() => isDataReady ? addCurrencySymbol(data.currency, data.startingBalance) : 0, [isDataReady, data]);
    const showStartingBalance = useMemo(() => isDataReady ? (data.startingBalance !== 0) : false, [isDataReady, data]); // toggle field on/off

    const amountDue = useMemo(() => isDataReady ? addCurrencySymbol(data.currency, data.amountDue) : 0, [isDataReady, data]);
    const showAmountDue = useMemo(() => isDataReady ? ((data.amountDue !== 0 && data.amountDue !== data.total) || data.startingBalance !== 0 || data.endingBalance !== 0) : false, [isDataReady, data]); // toggle field on/off

    const endingBalance = useMemo(() => isDataReady ? addCurrencySymbol(data.currency, data.endingBalance) : 0, [isDataReady, data]);
    const showEndingBalance = useMemo(() => isDataReady ? (data.endingBalance !== 0) : false, [isDataReady, data]); // toggle field on/off


    return (
        <Container>
            <CheckoutSummaryTitle>{summary.title}</CheckoutSummaryTitle>
            <CheckoutSummaryText>{plan.name} Plan</CheckoutSummaryText>
            <CheckoutSummaryText>{isMonthly ? (monthlyPrice + ' Billed Monthly') : (yearlyPrice + ' Billed Annually')}</CheckoutSummaryText>

            { isDataReady &&
            <CheckoutSummaryDetailsSection>

                { showProratedCost &&
                <CheckoutSummaryDetailsRow hasborder={true} >
                    <CheckoutSummaryDetailsText>{(isDataReady ? data.costDescription : '') || summary.proratedCost}</CheckoutSummaryDetailsText>
                    <CheckoutSummaryDetailsText number={true} >{proratedCost}</CheckoutSummaryDetailsText>
                </CheckoutSummaryDetailsRow> }

                { showProratedCredit &&
                <CheckoutSummaryDetailsRow hasborder={true} >
                    <CheckoutSummaryDetailsText>{(isDataReady ? data.creditDescription : '') || summary.proratedCredit}</CheckoutSummaryDetailsText>
                    <CheckoutSummaryDetailsText number={true} >{proratedCredit}</CheckoutSummaryDetailsText>
                </CheckoutSummaryDetailsRow> }

                <CheckoutSummaryDetailsRow hasborder={true} textRight={true} >
                    <CheckoutSummaryDetailsText final={true} >{summary.subtotal}</CheckoutSummaryDetailsText>
                    <CheckoutSummaryDetailsText final={true} number={true} >{subtotal}</CheckoutSummaryDetailsText>
                </CheckoutSummaryDetailsRow>

                { showDiscount &&
                <CheckoutSummaryDetailsRow textRight={true} >
                    <CheckoutSummaryDetailsText>{summary.discounts}</CheckoutSummaryDetailsText>
                    <CheckoutSummaryDetailsText number={true} >{totalDiscounts}</CheckoutSummaryDetailsText>
                </CheckoutSummaryDetailsRow> }

                <CheckoutSummaryDetailsRow textRight={true} >
                    <CheckoutSummaryDetailsText final={true} >{summary.total}</CheckoutSummaryDetailsText>
                    <CheckoutSummaryDetailsText final={true} number={true} >{total}</CheckoutSummaryDetailsText>
                </CheckoutSummaryDetailsRow>

                { showStartingBalance &&
                <CheckoutSummaryDetailsRow textRight={true} >
                    <CheckoutSummaryDetailsText>{summary.startingBalance}</CheckoutSummaryDetailsText>
                    <CheckoutSummaryDetailsText number={true} >{startingBalance}</CheckoutSummaryDetailsText>
                </CheckoutSummaryDetailsRow> }

                { showAmountDue &&
                <CheckoutSummaryDetailsRow textRight={true}>
                    <CheckoutSummaryDetailsText final={true} >{summary.amountDue}</CheckoutSummaryDetailsText>
                    <CheckoutSummaryDetailsText final={true} number={true} >{amountDue}</CheckoutSummaryDetailsText>
                </CheckoutSummaryDetailsRow> }

                { showEndingBalance &&
                <CheckoutSummaryDetailsRow textRight={true} >
                    <CheckoutSummaryDetailsText>{summary.endingBalance}</CheckoutSummaryDetailsText>
                    <CheckoutSummaryDetailsText number={true} >{endingBalance}</CheckoutSummaryDetailsText>
                </CheckoutSummaryDetailsRow> }

            </CheckoutSummaryDetailsSection> }
        </Container>
    );
};