import {
    Container,
    Header,
    SubHeader,
    ControlsContainer,
    Notification,
    StyledFormCheck
} from './Feature.styles';


const Feature = (props) => {
    const { label, name, enabled, onToggle, header, notification, handleNotification, disabled, ...rest } = props;

    return (
        <Container {...rest} >
            {header && <Header>{label}</Header>}
            {!header && <SubHeader>{label}</SubHeader>}

            <ControlsContainer>
                {notification && <Notification hover={handleNotification} onClick={handleNotification} >{notification}</Notification>}
                {onToggle && <StyledFormCheck disabled={disabled} type="switch" name={name} checked={enabled} onChange={(event) => onToggle(event, !enabled)} /> }
            </ControlsContainer>
        </Container>
    );
};

export default Feature;