import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { PersonFill } from 'react-bootstrap-icons';
import { Slider } from '../../custom/FormInput';

import { colorConstants } from "../../../constants";



const Container = styled.div`
    
`;

const Header = styled.div`
    margin-bottom: 8px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 15px;
    color: ${colorConstants.black};
`;

const Subtitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: ${colorConstants.mediumGrey};
`;

const OptionsSection = styled.div`
    padding: 0px 16px;
    margin-bottom: 16px;
`;

const IconsList = styled.div`
    & > * {
        margin-right: 2px;
    }

    & > *:last-child {
        margin-right: 8px;
    }
`;

const Icon = styled(PersonFill)`
    font-size: 16px;
    color: ${colorConstants.primaryDark};
`;

const IconSmall = styled(PersonFill)`
    font-size: 14px;
    color: ${colorConstants.primaryFaded};
`;

const OverOccupiedWarning = styled.div`
    margin-bottom: 8px;

    font-weight: 600;
    font-size: 15px;
    color: ${colorConstants.secondaryDark};
`;

const RoomsAvailableWarning = styled.div`
    margin-bottom: 8px;

    font-weight: 600;
    font-size: 15px;
    color: ${colorConstants.warningDark};
`;



export const RoomOccupancy = ({ id, options, adults, children, maxRoomCapacity, roomsAvailable, onChange }) => {

    const [active, setActive] = useState(0);


    const maxAdults = useMemo(() => {
        if(active < options.length) return options[active];
        else return options[0];
    }, [active, options]);

    const iconsAdults = useMemo(() => {
        let output = [];
        for(let i = 0; i < maxAdults; i++) output.push(<Icon key={i +'-icon-occupancy'} />);
        return output;
    }, [maxAdults]);

    const iconsChildren = useMemo(() => {
        // if adults number exceed rate's max adults then do not show additional icons
        if(adults > maxAdults) return null;
        let output = [];
        let extra = maxRoomCapacity - maxAdults;
        // if children number is less than room's additional people capacity then only show additional icons for each children
        if(children < extra) extra = children;
        for(let i = 0; i < extra; i++) output.push(<IconSmall key={i +'-icon-small-occupancy'} />);
        return output;
    }, [maxAdults, maxRoomCapacity, adults, children]);

    const overOccupiedWarning = useMemo(() => {
        // check if number of adults are equal or lower to selected rate maxOccupancy allowed
        if(adults > maxAdults) {
            let required = Math.trunc(adults / maxAdults) + (((adults % maxAdults) > 0) ? 1 : 0);
            return (<OverOccupiedWarning>{`To fit ${adults} adult${adults > 1 ? 's' : ''}, you'll need to book ${required} room${required > 1 ? 's' : ''}`}</OverOccupiedWarning>);
        }

        // check if total number of people (inc. children) does not exceed the total capacity of the room even if the rate's adults maxOccupancy is ok.
        const totalPeople = adults + children;
        if(totalPeople > maxRoomCapacity) {
            let required = Math.trunc(totalPeople / maxRoomCapacity) + (((totalPeople % maxRoomCapacity) > 0) ? 1 : 0);
            // let required = Math.trunc(extra / maxRoomCapacity) + (extra % maxRoomCapacity);
            return (<OverOccupiedWarning>{`To fit ${totalPeople}, you'll better book ${required} room${required > 1 ? 's' : ''}`}</OverOccupiedWarning>);
        }
        
        return null;
    }, [adults, children, maxAdults, maxRoomCapacity]);

    const roomsAvailabilityNotice = useMemo(() => {
        if(roomsAvailable < 10) return (<RoomsAvailableWarning>Only {roomsAvailable} room{roomsAvailable > 1 ? 's' : ''} left on our site</RoomsAvailableWarning>);
        return null;
    }, [roomsAvailable]);

    const handleChange = (e) => {
        setActive(e.target.value);
        if(onChange) onChange(id, options[e.target.value]);
    };

    useEffect(() => {
        if(options.length <= active ) {
            setActive(0);
            if(onChange) onChange(id, options[0]);
        }

    }, [onChange, id, active, options]);


    return (
        <Container>
            {overOccupiedWarning}

            <Header>
                <Title>Sleeps:</Title>
                &nbsp;&nbsp;
                <Subtitle>
                    <IconsList>{iconsAdults} {iconsChildren}</IconsList>
                </Subtitle>
            </Header>

            { (options.length > 1) &&
            <OptionsSection>
                <Slider
                    id={id}
                    min={0}
                    max={(options.length - 1)}
                    step={1}
                    value={active}
                    onChange={handleChange}
                />
            </OptionsSection> }

            {roomsAvailabilityNotice}
        </Container>
    );
};