import { publicOverviewReducerConstants } from "../constants";
import { publicOverviewService } from "../services";

export const publicOverviewActions = {
    get,
    reset
};

function get(query) {
    return dispatch => {
        dispatch(request());

        publicOverviewService.get(query)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publicOverviewReducerConstants.GET_REQUEST } }
    function success(data) { return { type: publicOverviewReducerConstants.GET_SUCCESS, data } }
    function failure(error) { return { type: publicOverviewReducerConstants.GET_FAILURE, error } }
}

function reset() {
    return { type: publicOverviewReducerConstants.RESET_ALL };
}