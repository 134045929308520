import styled from 'styled-components';
import { colorConstants } from "../../../constants";


export const Container = styled.div`
    padding: 8px 16px;
`;

export const TopSection = styled.div`
    display: flex;
`;

export const TopSectionColumn = styled.div`
    margin-left: 16px;

    display: flex;
    flex-direction: column;
`;

export const Name = styled.h3`
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: ${colorConstants.black};

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: none;
`;

export const Review = styled.div`
    margin: 8px 40px 0 48px;
    font-size: 11px;
    font-weight: 500;
    color: ${colorConstants.black};

    max-height: 130px;
    overflow-y: auto;
`;