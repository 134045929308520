import { http, handleResponse, handleError } from "../utils";

export const homepageService = {
    joinWaitlist
};

async function joinWaitlist(data) {
    return await http.post("/homepage/join", data)
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}