import React, { useState } from 'react';


// Image with a fallback source url functionality
export const Image = ({ src, fallbackSrc, alt, ...props }) => {
    const [errored, setErrored] = useState(false);

    return (
        <img
          src={errored ? fallbackSrc : src}
          onError={() => (errored ? null : setErrored(true))}
          alt={alt}
          {...props}
        />
      );
};