import React, { useMemo, useState } from "react";

import { Container, CheckoutExpirySection } from './TripCheckoutPage.styles';
import { HotelBookingSummary } from './HotelBookingSummary';
import { BillingForm } from '../../custom/Finance';
import { CountdownTimer } from '../../custom/Time';
import { TripCheckoutError } from '../../custom/ErrorScreen';
import { contentHotelBookingCheckoutPage, colorConstants } from '../../../constants';


export const TripCheckoutPage = ({
    booking,
    customer,
    coupon, handleChangeCoupon,
    isGuestCheckout, registerNewAccount, handleRegister, handleLogin, handleOpenTerms,
    paymentMethod,
    handleChangeBillingDetails,
    handleChangeBillingAddress,
    notes, handleChangeNotes,
    handleChangePaymentMethod,
    handleChangeSavedPaymentMethod,
    handleSubmit, onComplete,
    goBack,
}) => {

    const [isCheckoutExpired, setIsCheckoutExpired] = useState(false);

    // validate checkout page configured
    const isConfigured = useMemo(() => booking && booking.config && booking.config.status === 'success', [booking]);

    // check if error
    const hasError = useMemo(() => !isConfigured && booking.config && booking.config.error, [isConfigured, booking]);

    // validate coupon code error
    const inValidCoupon = useMemo(() => {
        if(booking && booking.config && booking.config.couponError === 'Invalid coupon code') return true;
        return false;
    }, [booking]);

    // determine booking type
    const isHotelBooking = useMemo(() => {
        if(isConfigured && booking.config.type === 'ACCOMMODATION') return true;
        return false;
    }, [isConfigured, booking]);

    //validate booking details
    const hasBookingDetails = useMemo(() => isConfigured && booking.config.details, [isConfigured, booking.config]);

    // check if checkout session has expiry time
    const isCheckoutExpiry = useMemo(() => hasBookingDetails && booking.config.ttl && booking.config.createdAt, [hasBookingDetails, booking.config]);

    return (
        <Container>

            { hasBookingDetails && isHotelBooking &&
            <HotelBookingSummary
                photos={booking.config.details.photos}
                name={booking.config.details.name}
                rating={booking.config.details.rating}
                address={booking.config.details.address}
                start={booking.config.details.start}
                end={booking.config.details.end}
                nights={booking.config.details.nights}
                rooms={booking.config.details.rooms}
                roomName={booking.config.details.roomName}
                total={booking.config.total}
                basePriceBeforeCharges={booking.config.basePriceBeforeCharges}
                taxesAndFees={booking.config.taxesAndFees}
                taxesAndFeesNotIncludedInPrice={booking.config.taxesAndFeesNotIncludedInPrice}
                coupon={booking.config.coupon}
                discount={booking.config.discount}
                discountPercentOff={booking.config.discountPercentOff}
                currencyCode={booking.config.currencyCode}
            /> }

            { isCheckoutExpiry &&
            <CheckoutExpirySection>
                <CountdownTimer
                    targetDate={booking.config.createdAt}
                    minutes={booking.config.ttl}
                    handleExpired={() => setIsCheckoutExpired(true)}
                    label='Checkout expires in '
                    color={colorConstants.secondaryDark}
                    size={13} />
            </CheckoutExpirySection> }

            { isConfigured &&
            <BillingForm
                // Coupon
                disableCoupons={isGuestCheckout}
                coupon={coupon}
                handleChangeCoupon={handleChangeCoupon}
                inValidCoupon={inValidCoupon}
                // Billing Details
                isGuestCheckout={isGuestCheckout}
                registerNewAccount={registerNewAccount}
                handleRegister={handleRegister}
                handleLogin={handleLogin}
                handleOpenTerms={handleOpenTerms}
                name={customer.name}
                email={customer.email}
                phone={customer.phone}
                handleChangeBillingDetails={handleChangeBillingDetails}
                // Billing Address
                address={customer.address}
                handleChangeBillingAddress={handleChangeBillingAddress}
                // additional notes
                notes={notes}
                handleChangeNotes={handleChangeNotes}
                // Payment
                paymentMethod={paymentMethod}
                handleChangePaymentMethod={handleChangePaymentMethod}
                handleChangeSavedPaymentMethod={handleChangeSavedPaymentMethod}
                isCardCvcValidationEnabled={true}
                // Payment: Stripe
                clientSecret={booking.config.clientSecret}
                stripeId={booking.config.stripeId}
                isSetupIntent={booking.config.isSetupIntent}
                // Payment: Button
                disableSubmitButton={isCheckoutExpired}
                successMessage={contentHotelBookingCheckoutPage.purchaseSuccessMessage}
                buttonText={contentHotelBookingCheckoutPage.purchaseButton}
                handleSubmit={handleSubmit}
                onComplete={onComplete}
            /> }

            { hasError &&
            <TripCheckoutError
                onClick={goBack}
                message={booking.config.error}
                buttonText={contentHotelBookingCheckoutPage.errorBackButtonText}
            /> }

        </Container>
    );
};