import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
    Container,
    Section,
    SectionTopArea,
    SectionTitle,
    SectionScrollControls, ScrollForwardButton, ScrollBackButton,
    SectionContent, SectionList,
    SectionListLeftFader, SectionListRightFader,
} from './Overview.styles';
import { SimpleLink, GithubLink, SquareLink, YoutubeLink, SpotifyLink } from '../links';
import { SearchLink } from '../search';
import { ProductLinkSquare } from '../products';




const Overview = ({ width, overview, handleViewTrip, handleOpenLink, handleViewProduct, handleViewNFT, metadata, customizations }) => {

    const [sections, setSections] = useState([]);
    const [searchSection, setSearchSection] = useState(null);

    // calculate full screen width minus 64px so that the next item in the list is visible as well for better scrolling experience
    const fullWidth = useMemo(() => {
        if(width < 991) return (width - (4 * 16)); // for mobile
        
        // calculate app section minus the padding on larger screens
        let section = 0;
        if(width >= 991 && width < 1440) {
            section = width - (width * 0.66); // for tablet
        } else if(width >= 1440 && width < 1600) {
            section = width - (width * 0.70); // for laptop
        } else {
            section = width - (width * 0.75); // for laptop
        }

        return (section - (4 * 32));
    }, [width]);


    /* Observe if first and last section items are visible or not in the screen */
    
    // create first and last items refs
    const sectionFirstItemsRef = useRef([]);
    const sectionLastItemsRef = useRef([]);

    // create first and last items intersecting state flags
    const [firstItemsIntersecting, setFirstItemsIntersecting] = useState([]);
    const [lastItemsIntersecting, setLastItemsIntersecting] = useState([]);

    // observe first and last items
    useEffect(() => {
        // create observers
        let options = { rootMargin: "100% 0px 100% 0px", threshold: 0.8 };
        const firstItemObservers = [];
        const lastItemObservers = [];

        sectionFirstItemsRef.current.forEach((item, index) => {
            // set scroll container
            options.root = sectionNodesRef.current[index];
            // create observers
            firstItemObservers[index] = new IntersectionObserver(([entry]) => {
                let stateCopy = [...firstItemsIntersecting];
                stateCopy[index] = entry.isIntersecting;
                setFirstItemsIntersecting(stateCopy);
            }, options);
            // assign items to respective observers
            if(item) firstItemObservers[index].observe(item);
        });

        sectionLastItemsRef.current.forEach((item, index) => {
            // set scroll container
            options.root = sectionNodesRef.current[index];
            // create observers
            lastItemObservers[index] = new IntersectionObserver(([entry]) => {
                let stateCopy = [...lastItemsIntersecting];
                stateCopy[index] = entry.isIntersecting;
                setLastItemsIntersecting(stateCopy);
            }, options);
            // assign items to respective observers
            if(item) lastItemObservers[index].observe(item);
        });

        // remove the observers as soon as the component is unmounted
        return () => {
            for(let i = 0; i < firstItemObservers.length; i++) firstItemObservers[i].disconnect();
            for(let i = 0; i < lastItemObservers.length; i++) lastItemObservers[i].disconnect();
        };
    }, [sections, firstItemsIntersecting, lastItemsIntersecting]);



    /* Create onScroll functions */

    // create scroll container refs
    const sectionNodesRef = useRef([]);

    // function to handle button scroll
    function handleScrollButtons(nodeRef, forward){
        if(!nodeRef) return;
        const scrollContainerRef = nodeRef;
        if(forward) scrollContainerRef.scrollLeft += 200;
        else scrollContainerRef.scrollLeft += -200;
    }



    useEffect(() => {
        function getItemType(item, index, isFirst, isLast, sectionIndex) {
            const addItemRef = (ref) => {
                if(isFirst) sectionFirstItemsRef.current[sectionIndex] = ref;
                if(isLast) sectionLastItemsRef.current[sectionIndex] = ref;
            };
    
            switch(item.type) {
                case 'SIMPLE':
                    return <SimpleLink ref={(ref) => addItemRef(ref)} key={index} link={item} customizations={customizations} />;
                
                case 'BLOG':
                    return <SimpleLink ref={(ref) => addItemRef(ref)} key={index} link={item} customizations={customizations} />;
                
                case 'GITHUB':
                    return <GithubLink ref={(ref) => addItemRef(ref)} overview={true} key={index} link={item} handleOpen={handleOpenLink} customizations={customizations} />;
    
                case 'ACCOMMODATION':
                    return <SquareLink ref={(ref) => addItemRef(ref)} key={index} data={item} handleOpen={handleViewTrip} customizations={customizations} isTrip={true} />;
    
                case 'EXPERIENCE':
                    return <SquareLink ref={(ref) => addItemRef(ref)} key={index} data={item} handleOpen={handleViewTrip} customizations={customizations} isTrip={true} />;
    
                case 'PHYSICAL':
                    return <ProductLinkSquare ref={(ref) => addItemRef(ref)} key={index} data={item} handleOpen={handleViewProduct} customizations={customizations} />;
    
                case 'DIGITAL':
                    return <ProductLinkSquare ref={(ref) => addItemRef(ref)} key={index} data={item} handleOpen={handleViewProduct} customizations={customizations} />;

                case 'NFT':
                    return <ProductLinkSquare ref={(ref) => addItemRef(ref)} key={index} data={item} handleOpen={handleViewNFT} customizations={customizations} isNFT={true} />;
                
                case 'YOUTUBE':
                    return <YoutubeLink ref={(ref) => addItemRef(ref)} key={index} data={item} customizations={customizations} maxWidth={metadata.youtube.totalActive > 1 ? fullWidth + 'px' : null} maxHeight='146px' />;
                
                case 'YOUTUBESHORT':
                    return <YoutubeLink ref={(ref) => addItemRef(ref)} key={index} data={item} customizations={customizations} maxWidth={'120px'} maxHeight={'170px'} />;

                case 'SPOTIFY':
                    return <SpotifyLink ref={(ref) => addItemRef(ref)} key={index} data={item} customizations={customizations} maxWidth={metadata.spotify.totalActive > 1 ? fullWidth + 'px' : null} />;
    
                default:
                    return;
            }
        }

        // set section data
        let sectionList = null;
        if(overview && overview.length > 0) {

            // get search section
            let search = overview.filter((section) => section.type === 'SEARCH' && section.item);
            if(search && search.length > 0) {
                search = (
                    <Section key={search[0].type} >
                        <SectionContent>
                            <SearchLink data={search[0].item} handleSearch={handleViewTrip} customizations={customizations} />
                        </SectionContent>
                    </Section>
                );
            } else {
                search = null;
            }
            setSearchSection(search);
            
            // get other sections
            sectionList = overview.filter((section) => section.items && section.items.length > 0).map((section, index) => {
                // get list items
                const list = section.items.map((item, i) => {
                    const isFirstItem = i === 0;
                    const isLastItem = (section.items.length === 1) || (i === section.items.length - 1);
                    return getItemType(item, i, isFirstItem, isLastItem, index);
                });

                return (
                    <Section key={section.category} >
                        <SectionTopArea>
                            { section.category && <SectionTitle type={customizations.fonts.type} color={customizations.fonts.color}>{section.category}</SectionTitle> }
                            <SectionScrollControls>
                                <ScrollBackButton
                                    hide={firstItemsIntersecting[index] ? 'true' : null}
                                    size={20}
                                    color={customizations.fonts.color}
                                    onClick={() => handleScrollButtons(sectionNodesRef.current[index], false)}
                                />
                                <ScrollForwardButton
                                    hide={lastItemsIntersecting[index] ? 'true' : null}
                                    size={20}
                                    color={customizations.fonts.color}
                                    onClick={() => handleScrollButtons(sectionNodesRef.current[index], true)}
                                />
                            </SectionScrollControls>
                        </SectionTopArea>
                        
                        <SectionContent>
                            <SectionListLeftFader hide={firstItemsIntersecting[index] ? 'true' : null} />
                            <SectionListRightFader hide={lastItemsIntersecting[index] ? 'true' : null} />
                            <SectionList ref={(ref) => sectionNodesRef.current[index] = ref} >{list}</SectionList>
                        </SectionContent>
                    </Section>
                );
            });
            setSections(sectionList);
        }
    }, [fullWidth, metadata, overview, customizations, firstItemsIntersecting, lastItemsIntersecting, handleOpenLink, handleViewProduct, handleViewTrip, handleViewNFT]);

    return (
        <Container bottomsocial={customizations.bottomSocial} >
            {searchSection}
            {sections}
        </Container>
    );
};

export default Overview;