import React, { useMemo } from 'react';
import styled from 'styled-components';
import { CardElement, CardCvcElement } from '@stripe/react-stripe-js';
import Icon from '../../../assets/icons/Icon';

import { colorConstants } from '../../../constants';


const CARD_ELEMENT_OPTIONS  = {
    hidePostalCode: true,
    style: {
      base: {
        color: colorConstants.black,
        fontFamily: 'Montserrat, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: colorConstants.softGrey
        }
      },
      invalid: {
        fontFamily: 'Montserrat, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
};

const CVC_ELEMENT_OPTIONS  = {
  style: {
    base: {
      color: colorConstants.black,
      fontFamily: 'Montserrat, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "18px",
      "::placeholder": {
        color: colorConstants.primaryDark,
        fontWeight: "700",
      }
    },
    invalid: {
      fontFamily: 'Montserrat, sans-serif',
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const Container = styled.div`
  ${({ hide }) => hide && `
    visibility: hidden;
    max-height: 0 !important;
    max-width: 0 !important;
  `}

  .StripeElement {
    padding: 12px;
    border: 1px solid ${colorConstants.softGrey2};
    border-radius: 2px;
  }

  .StripeElement--focus {
    border: 1px solid ${colorConstants.primaryDark};
    border-radius: 2px;
  }
`;

const SavedCardContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const CardDetailsBox = styled.div`
  display: flex;
  align-items: center;
`;

const CardNumberBox = styled.div`
  margin-left: 8px;

  font-weight: 700;
  color: ${colorConstants.black};
`;

const CvcBox = styled.div`
  .cvc-check-input-box {
    border: none;
    border-bottom: 1px solid ${colorConstants.primaryDark};
    padding: 4px;
  }
`;

const CvcBoxTitle = styled.div`
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 12px;
  color: ${colorConstants.black};
`;



export const StripeCardInput = (props) => {
  const { disabled, enableCardCvc, paymentMethod } = props;

  const hasSavedCard = useMemo(() => {
    if(paymentMethod && paymentMethod.paymentMethodId && paymentMethod.stripePaymentMethodId) return true;
    return false;
  }, [paymentMethod]);


    return (
      <Container>
        { !hasSavedCard && <CardElement id="card-element" options={{ disabled, ...CARD_ELEMENT_OPTIONS }} {...props} /> }

        { hasSavedCard &&
        <SavedCardContainer>
          <CardDetailsBox>
            <Icon type='payment' platform={paymentMethod.brand} size={34} color={true} />
            <CardNumberBox>****&nbsp;{paymentMethod.last4}</CardNumberBox>
          </CardDetailsBox>

          { enableCardCvc &&
          <CvcBox>
            <CvcBoxTitle>Card security code</CvcBoxTitle>
            <CardCvcElement className="cvc-check-input-box" id="card-cvc-element" options={{ disabled, ...CVC_ELEMENT_OPTIONS }} {...props} />
          </CvcBox> }
        </SavedCardContainer> }
      </Container>
    );
}