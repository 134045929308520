import React, { Component } from "react";
import { connect } from 'react-redux';

import { nftActions, nftPublicActions } from '../../../actions';
import { ScreenContainer } from './ViewNFTContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { NFTPage } from '../../../components/nfts';
import { Warning, Share } from '../../../components/custom/Popup';
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { history } from '../../../utils';
import { contentNFTPage, colorConstants, sitemap, urlConstants, getNFTLink, getProfileLink } from '../../../constants';


class ViewNFTContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            fromUrl: sitemap.admin.dashboard,
            init: false, // initialize NFT data from server db
            authenticated: false, // used for hide/show topbar for logged in users
            isLinkCreator: false, // true if current user is the creator of the NFT link
            sticky: false, // make topbar sticky
            deleteWarning: false, // hide/show delete NFT warning
            deleting: false, // deleting NFT flag
            sharing: false, // hide/show share popup
            nft: null,
            privateInit: false, // initialize private NFT data from server db
            privateNFTData: { favourite: false }
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);

        this.handleDeleteNFTRequest = this.handleDeleteNFTRequest.bind(this);
        this.handleDeleteNFT = this.handleDeleteNFT.bind(this);
        this.handleDeleteNFTCancel = this.handleDeleteNFTCancel.bind(this);

        this.handleEditNFT = this.handleEditNFT.bind(this);
        this.handleShareNFT = this.handleShareNFT.bind(this);
        this.handleFavorNFT = this.handleFavorNFT.bind(this);
        this.handleUnFavorNFT = this.handleUnFavorNFT.bind(this);

        this.handleViewCreatorProfile = this.handleViewCreatorProfile.bind(this);
    }

    componentDidMount() {
        if(this.props.location.state) {
            let initialState = {};
            if(this.props.location.state.from) initialState = { ...this.state, ...initialState, fromUrl: this.props.location.state.from }; // set from URL
            // get data from previous page
            if(this.props.location.state.data) {
                const { profileTransitionAnimation } = this.props.location.state.data;
                // enable transition animations
                if(profileTransitionAnimation) {
                    
                }
            }
            // set initial page state
            this.setState(initialState);
        }

        // listen to scroll events
        window.addEventListener('scroll', this.handleScroll);

        // get public NFT data
        this.props.getBySlug(this.props.match.params.username, this.props.match.params.slug);

        // execute private startup methods for authenticated users
        if(this.props.cookie && this.props.cookie.id) {
            // set authenticated mode
            this.setState({ authenticated: true });

            // get authenticate NFT data for creator
            let query = {
                username: this.props.match.params.username,
                visitorUserId: this.props.cookie.id,
            };
            this.props.getAuthenticatedNFTData(this.props.match.params.slug, new URLSearchParams(query));
        }
    }

    componentDidUpdate() {
        // save NFT data
        if(!this.state.init && !this.props.loading && this.props.nft && this.props.nft.id) {
            this.setState({
                init: true,
                isLinkCreator: this.props.cookie && this.props.cookie.id === this.props.nft.creator.id,
                nft: this.props.nft
            });
        }

        // save private NFT data (for authenticated users)
        if(this.state.authenticated && this.state.init && !this.state.privateInit && this.props.privateNFTData && this.props.privateNFTData.id) {
            this.setState({ ...this.state, privateInit: true, privateNFTData: this.props.privateNFTData });
        }
    }

    componentWillUnmount() {
        // remove scroll listener
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        // enable sticky mode on scroll event
        this.setState({ ...this.state, sticky: window.pageYOffset > 1 });
    }

    handleTopBarBackButton() {
        // reset NFT data from state
        this.props.reset();
        // reset private NFT data
        this.props.resetPrivateNFTData();
        // go back to previous screen
        history.push({
            pathname: this.state.fromUrl,
            state: {
                from: getNFTLink(this.state.nft.creator.username, this.props.match.params.slug),
                data: {
                    nftId: this.state.nft.id
                }
            }
        });
    }

    handleDeleteNFTRequest(){
        // additional auth check
        if(!this.props.cookie || !this.state.authenticated) return;

        // show delete NFT warning
        this.setState({ ...this.state, deleteWarning: true });
    }

    async handleDeleteNFT(){

        // additional auth check
        if(!this.props.cookie || !this.state.authenticated) return;

        // start spinner and hide warning
        this.setState({ ...this.state, deleting: true, deleteWarning: false });

        // Delete NFT from db
        await this.props.delete(this.state.nft.id);

        // reset NFT data from state
        this.props.reset();
        // reset private NFT data
        this.props.resetPrivateNFTData();

        // stop spinner
        this.setState({ ...this.state, deleting: false });

        // go back to previous screen
        history.push({ pathname: this.state.fromUrl });
    }

    handleDeleteNFTCancel(){
        // hide delete NFT warning
        this.setState({ ...this.state, deleteWarning: false });
    }

    handleEditNFT(){
        // Open edit NFT page
        history.push({
            pathname: sitemap.admin.nft.edit,
            state: {
                from: getNFTLink(this.state.nft.creator.username, this.props.match.params.slug),
                data: {
                    nftId: this.state.nft.id
                }
            }
        });
    }

    handleShareNFT(){
        // hide/show sharing popup
        this.setState({ ...this.state, sharing: !this.state.sharing });
    }

    handleFavorNFT(){
        if(!this.state.authenticated) {
            // redirect to login
            history.push({ pathname: sitemap.auth.login });
            return;
        }

        // add NFT to favourites
        if(!this.state.privateNFTData.favourite) {
            // update state
            this.setState({ ...this.state, privateNFTData: { ...this.state.privateNFTData, favourite: true } });
            // update in db
            this.props.favourite({ id: this.state.nft.id, visitorUserId: this.props.cookie.id, status: true });
        }
    }

    handleUnFavorNFT(){
        if(!this.state.authenticated) {
            // redirect to login
            history.push({ pathname: sitemap.auth.login });
            return;
        }

        if(this.state.privateNFTData.favourite) {
            // update state
            this.setState({ ...this.state, privateNFTData: { ...this.state.privateNFTData, favourite: false } });
            // update in db
            this.props.favourite({ id: this.state.nft.id, visitorUserId: this.props.cookie.id, status: false });
        }
    }

    handleViewCreatorProfile(username) {
        // reset NFT data from state
        this.props.reset();
        // reset private NFT data
        this.props.resetPrivateNFTData();

        // open creator profile
        history.push({
            pathname: getProfileLink(username),
            state: {
                from: getNFTLink(username, this.state.nft.customUrl),
                data: {
                    nftId: this.state.nft.id
                }
            }
        });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                { this.state.init && <MetaTags title={this.state.nft.name} /> }

                {this.state.init &&
                <NFTPage
                    authenticated={this.state.authenticated}
                    isLinkCreator={this.state.isLinkCreator}
                    nft={this.state.nft}
                    isFav={this.state.privateNFTData.favourite}
                    handleBackButton={this.handleTopBarBackButton}
                    handleDelete={this.handleDeleteNFTRequest}
                    handleEdit={this.handleEditNFT}
                    handleShare={this.handleShareNFT}
                    handleFavor={this.handleFavorNFT}
                    handleUnFavor={this.handleUnFavorNFT}
                    handleViewCreatorProfile={this.handleViewCreatorProfile} /> }

                {(this.state.deleting || this.props.loading) && <FullScreenSpinner />}

                <Warning
                    show={this.state.deleteWarning}
                    onHide={this.handleDeleteNFTCancel}
                    firstButtonClick={this.handleDeleteNFT}
                    secondButtonClick={this.handleDeleteNFTCancel}
                    firstButton={contentNFTPage.deleteNftWarning.firstButton}
                    firstButtonColor={colorConstants.darkGrey}
                    firstButtonBackgroundColor={colorConstants.softGrey}
                    secondButton={contentNFTPage.deleteNftWarning.secondButton}
                    title={contentNFTPage.deleteNftWarning.title}
                    body={contentNFTPage.deleteNftWarning.body} />

                { this.state.init && this.state.nft && this.state.nft.creator &&
                <Share
                    show={this.state.sharing}
                    onHide={this.handleShareNFT}
                    title='Share'
                    url={urlConstants.baseUrlClient + getNFTLink(this.state.nft.creator.username, this.state.nft.customUrl)}
                    imageUrl={this.state.nft.image} /> }
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { auth, nftPublic } = state;
    const cookie = auth.cookie;
    const { loading } = nftPublic;
    const nft = nftPublic.nfts[0];
    // get private NFT data
    const privateNFTData = state.nft;

    // export state data to props
    return { cookie, loading, nft, privateNFTData };
}

const actionCreators = {
    getBySlug: nftPublicActions.getBySlug,
    reset: nftPublicActions.reset,
    getAuthenticatedNFTData: nftActions.getBySlug,
    update: nftActions.update,
    favourite: nftActions.favourite,
    delete: nftActions.delete,
    resetPrivateNFTData: nftActions.clear
}

const connectedViewNFTContainer = connect(mapState, actionCreators)(ViewNFTContainer);
export { connectedViewNFTContainer as ViewNFTContainer };