import React, { forwardRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Container, ContainerFader, Image, Name, Description } from './SquareLink.styles';
import { colorConstants } from "../../constants";


export const SquareLink = forwardRef((props, ref) => {
    const { data, handleOpen, customizations, isTrip, isNFT, ...rest } = props;
    let { buttons, fonts } = customizations;

    // defaults
    if(buttons.color === '') buttons.color = colorConstants.softBlack;
    if(buttons.fontColor === '') buttons.fontColor = colorConstants.white;

    // set button shape
    const buttonShape = buttons.shape === 'OVAL' ? '24px' : buttons.shape === 'ROUNDED' ? '8px' : '0px';

    // set button color
    const buttonColor = buttons.style === 'OUTLINE' ? 'transparent' : buttons.color;
    const buttonColorHover = buttons.style === 'OUTLINE' ? buttons.color : 'transparent';

    // set button font color
    const fontColor = buttons.style === 'OUTLINE' ? buttons.color : buttons.fontColor;
    const fontColorHover = buttons.style === 'OUTLINE' ? buttons.fontColor : buttons.color;

    // set image url
    let imageUrl = null;
    if(isTrip && data.tripPhotoUrls && data.tripPhotoUrls.length > 0) imageUrl= data.tripPhotoUrls[0];
    else if(isNFT && data.image && data.image.length > 0) imageUrl = data.image;
    else if(data.imageUrls && data.imageUrls.length > 0) imageUrl = data.imageUrls;


    return (
        <Container
            ref={ref}
            shape={buttonShape}
            borderColor={buttons.color}
            color={buttonColor}
            colorHover={buttonColorHover}
            fontColor={fontColor}
            fontColorHover={fontColorHover}
            fontType={fonts.type}
            onClick={() => handleOpen(data)} {...rest}
        >
            <ContainerFader className='fader' />

            { imageUrl && <Image className='link-image' src={imageUrl} /> }
            <Name>{data.name}</Name>
            <Description>{ReactHtmlParser(data.description)}</Description>
        </Container>
    );
});