export const colorConstants = {
    black: '#181818',
    softBlack: '#282828',
    darkGrey: '#404040',
    mediumGrey: '#7A7A7A',
    softGrey: '#C2C2C2',
    softGrey2: '#ced4da', // used for input borders
    verySoftGrey: '#F1F1F1',
    verySoftGreyVersion2: '#EFEDEC',
    verySoftGreyVersion3: '#EDF2F7',
    verySoftGreyVersion4: '#CBD5E0',
    verySoftGreyVersion5: '#E2E8F0',
    primary: '#30B4FF', // Blue
    primaryDark: '#2CA1E6', // Dark Blue
    primaryFaded: 'rgba(46, 178, 255, 0.3)', // Faded Blue: opacity 0.3
    primaryLight: '#A6E4FF',
    secondary: '#8B62FD', // Purple
    secondaryDark: '#7D59E3', // Dark Purple
    secondaryFaded: 'rgba(140, 99, 253, 0.3)', // Faded Purple: opacity 0.3
    secondaryLight: '#CCC1F6',
    gradientHorizontal: 'linear-gradient(153deg, rgba(48,180,255,1) 0%, rgba(139,98,253,1) 100%)',
    gradientVertical: 'linear-gradient(180deg, rgba(48,180,255,1) 0%, rgba(139,98,253,1) 100%)',
    gradientForText: '-webkit-linear-gradient(0deg, rgba(48,180,255,1) 0%, rgba(139,98,253,1) 88%)',
    gradientForShortText: '-webkit-linear-gradient(304deg, rgba(48,180,255,1) 3%, rgba(139,98,253,1) 29%)',
    softWhite: '#F6F6F6',
    verySoftWhite: '#F8F8F8',
    cream: '#E8E5E0',
    creamLight: '#FFFFED',
    white: '#FEFEFE',
    warning: '#b71c1c',
    warningDark: '#7f0000',
};



/*** DEVICE RELATED CONSTANTS ***/

const size = {
    mobile: '599px',
    tablet: '991px',
    laptop: '1440px',
    desktop: '1600px',
};

export const deviceConstants = {
    mobile: `(min-width: ${size.mobile})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    desktop: `(min-width: ${size.desktop})`
};



/*** SHADOW CONSTANTS ***/

export const shadowConstants = {
    adminDesktop: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    adminListItems: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
    smallButton: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    googleMapsMarker: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
};