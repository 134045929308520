import { http, authHeader, handleResponse, handleError } from "../utils";
import { urlConstants } from "../constants";

export const hotelService = {
    find,
    getAll,
    getById,
    getAvailability,
    checkRoomRate,
};


async function find(data) {
    return await http.get(`/hotels/find?${data.toString()}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getAll(data) {
    return await http.get(`/hotels?${data.toString()}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getById(id) {
    return await http.get(`/hotels/${id}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getAvailability(id, query, isGuestUser) {
    const guestSessionEndpoint = isGuestUser ? urlConstants.guestSessionEndpoint : '';
    let url = `/hotels${guestSessionEndpoint}/availability/${id}`;
    if(query) url = `${url}?${query.toString()}`;
    return await http.get(url, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function checkRoomRate(id, data, isGuestUser) {
    const guestSessionEndpoint = isGuestUser ? urlConstants.guestSessionEndpoint : '';
    return await http.post(`/hotels${guestSessionEndpoint}/checkroomrate/${id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}