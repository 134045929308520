import styled from 'styled-components';
import { deviceConstants, colorConstants } from '../../constants';


export const ScreenContainer = styled.div`
    min-height: 100vh;
    padding: 24px 16px;
    background-color: ${props => props.options.color || 'inherit'} !important;
    background-image: ${props => props.options.gradient || props.options.image || 'none'} !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

    @media ${deviceConstants.tablet} { 
        padding-left: 33%;
        padding-right: 33%;
    }

    @media ${deviceConstants.laptop} { 
        padding-left: 35%;
        padding-right: 35%;
    }

    @media ${deviceConstants.desktop} { 
        padding-left: 37.5%;
        padding-right: 37.5%;
    }
`;

export const BackgroundFader = styled.div`
    z-index: 0;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    background: ${colorConstants.softGrey};
    opacity: 0.55;
`;