import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormText from 'react-bootstrap/FormText';
import Spinner from 'react-bootstrap/Spinner';

import { deviceConstants, colorConstants, shadowConstants } from "../../../constants";
import { Button } from '../../custom/Buttons';


export const Container = styled.div`
    font-size: 12px;

    @media ${deviceConstants.tablet} {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        margin-top: 32px;
        border-radius: 8px;
        box-shadow: ${shadowConstants.adminDesktop};
        background: ${colorConstants.white};
    }

    
    .select-wallet {
        ${({ sticky }) => sticky && `
            background: ${colorConstants.white};
            box-shadow: rgba(149, 157, 165, 0.2) 0px 20px 24px;
            position: fixed;
            top: 32px;

            @media ${deviceConstants.tablet} { 
                padding-left: 33%;
                padding-right: 33%;

                & > * {
                    padding-left: 32px;
                    padding-right: 32px;
                }
            }
        
            @media ${deviceConstants.laptop} { 
                padding-left: 35%;
                padding-right: 35%;
            }
        
            @media ${deviceConstants.desktop} { 
                padding-left: 37.5%;
                padding-right: 37.5%;
            }
        `}
    }

    .assets-list {
        ${({ sticky }) => sticky && `
            padding-top: 128px;
        `}
    }
`;

export const StyledForm = styled(Form)`
    width: 100%;
    padding: 32px;
`;

export const StyledFormGroup = styled(FormGroup)`
    margin-top: 16px;
`;

export const FormInputTitle = styled.div`
    font-weight: 600;
    color: ${colorConstants.softGrey};
    margin-bottom: 1px;
`;

export const StyledFormText = styled(FormText)`
  color: ${colorConstants.warning};
`;

export const SpinnerWrap = styled.div`
    padding: 12px;
    text-align: center;
`;

export const StyledSpinner = styled(Spinner)`
    color: ${colorConstants.primaryDark};
`;

export const List = styled.div`
    width: 100%;
    padding: 32px;
    padding-bottom: 170px;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    & > * {
        margin-bottom: 24px;
    }
`;

export const StyledButton = styled(Button)`
    width: 100%;
    border-radius: 0px;
    position: ${props => props.static ? 'static' : 'fixed'};
    bottom: 0;

    @media ${deviceConstants.tablet} {
        width: ${props => props.static ? '100%' : '25%'};
        border-radius: 2px;

        position: ${props => props.static ? 'static' : 'absolute'};
        bottom: 20px;
        margin-bottom: ${props => props.static ? '20px' : '0'};
    }
    
    @media ${deviceConstants.desktop} {
        width: ${props => props.static ? '100%' : '20%'};
    }
`;