import React from "react";
import ProductsListItem from './ProductsListItem';
import { DraggableList } from '../custom/List';


const listStyles = {
    paddingBottom: '80px',
};

const listItemStyles = {
    marginBottom: '24px',
};



export const ProductsList = ({
    products,
    handleReorder,
    handleViewProduct,
    handleEditProduct,
    handleDeleteProduct,
    handleEnableProduct
}) => {

    // get all products and filter out draft products
    const addedProducts = products && products.filter(item => item.isSectionLabel || (item.imageUrls && item.imageUrls.length > 0));
    if(!products || !addedProducts || addedProducts.length === 0) return null;

    // render draggable list with products
    return (
        <DraggableList
            name='products-links'
            handleReorder={handleReorder}
            items={addedProducts}
            CustomListItem={ProductsListItem}
            listStyles={listStyles}
            listItemStyles={listItemStyles}
            handleView={handleViewProduct}
            handleEdit={handleEditProduct}
            handleDelete={handleDeleteProduct}
            handleEnabled={handleEnableProduct} />
    );
};