import React, { Component } from "react";
import { connect } from 'react-redux';
import validator from 'validator';

import { authActions, userActions } from '../../../actions';
import { ScreenContainer } from './ChangePasswordContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import ChangePassword  from "../../../components/auth/password/ChangePassword";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { TopBar } from '../../../components/custom/TopBar';
import { history, prepareUpdatePasswordData } from '../../../utils';
import { topbarConstants, sitemap } from "../../../constants";


class ChangePasswordContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            fromUrl: sitemap.admin.dashboard,
            submitted: false,
            isStrongPassword: false,
            credentials: {
                email: '',
                password: '',
                newPassword: '',
                newPasswordConfirm: ''
            }
        };

        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.getById(this.props.userId); // get user data from db
        if(this.props.location.state && this.props.location.state.from) this.setState({ ...this.state, fromUrl: this.props.location.state.from }); // set from URL
    }

    componentDidUpdate() {
        if (!this.state.credentials.email && this.props.user && !this.props.user.loading) {
            this.setState({ ...this.state, credentials: { ...this.state.credentials, email: this.props.user.email } });
        }
    }

    handleTopBarBackButton() {
        history.push({ pathname: this.state.fromUrl }); // Go back
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { credentials } = this.state;

        this.setState({
            submitted: false,
            credentials: {
                ...credentials,
                [name]: value, // update input data
            }
        });
    }

    handleSubmit() {
        const { credentials } = this.state;

        this.setState({
            submitted: true,
            isStrongPassword: validator.isStrongPassword(credentials.newPassword, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 }) // validate new password
        }, async () => {
            if (this.state.submitted && this.state.isStrongPassword && credentials.newPassword && (credentials.newPassword === credentials.newPasswordConfirm)){
                // authorize with current password before updating with new password in db
                await this.props.updatePassword(this.props.userId, prepareUpdatePasswordData(credentials));
            }
        });   
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.password} />

                <TopBar
                    title={topbarConstants.titles.password}
                    backButton={this.handleTopBarBackButton} />

                <ChangePassword
                    credentials={this.state.credentials}
                    submitted={this.state.submitted}
                    isStrongPassword={this.state.isStrongPassword}
                    alert={this.props.alert} // show error if not updated
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit} />

                {this.props.updatingPassword && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user, auth } = state;
    const { updatingPassword } = auth;

    // export state data to props
    return { userId, alert, user, updatingPassword };
}

const actionCreators = {
    updatePassword: authActions.updatePassword,
    getById: userActions.getById
}

const connectedChangePasswordContainer = connect(mapState, actionCreators)(ChangePasswordContainer);
export { connectedChangePasswordContainer as ChangePasswordContainer };