import imageCompression from 'browser-image-compression';

/**
 * Compress Image
 * 
 * @param {Blob} image The image file
 * @param {String} filename The file name to pre-pend to the compressed image file name
 * @param {Integer} maxWidth The number of the desired max width of the image
 * @returns The compress Blob image file
 */
export const compressImage = async (image, filename, maxWidth) => {
    // set compress options
    const compressOptions = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: maxWidth ? maxWidth : 512,
        useWebWorker: true
    };

    // compress image file
    const compressedFile = await imageCompression(image, compressOptions);

    // save new image filename
    compressedFile.name = filename;

    return compressedFile;
};


/**
 * Method to get uploaded image file dimensions
 * 
 * @param {FILE} file The uploaded image file
 * @returns JSON Object with image dimensions
 */
 export function getImageFileDimensions(file){
    let image = new Image();
    const promise = new Promise((resolve, reject) => {
        image.onload = function() {
            resolve({ height: image.naturalHeight, width: image.naturalWidth });
        };
        // Reject promise on error
        image.onerror = reject;
    });
    image.src = file;
    return promise;
}