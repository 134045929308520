import styled from 'styled-components';
import { colorConstants } from '../../../constants';



export const Container = styled.div`

`;

export const PageTitle = styled.div`
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 700;
  color: ${colorConstants.black};
`;

export const SelectedPlanSection = styled.div`
  width: 100%;
  padding: 16px;
  border: 1px solid ${colorConstants.softGrey};
  border-radius: 8px;

  font-size: 16px;
  font-weight: 500;
  color: ${colorConstants.black};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const SelectedPlanTitle = styled.div`
  font-weight: 600;
  color: ${colorConstants.mediumGrey};
`;

export const SelectedPlanName = styled.div`
  margin-top: 12px;
  margin-bottom: 8px;

  font-size: 32px;
  font-weight: 700;
  background: ${colorConstants.gradientForText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SelectedPlanOverview = styled.div`

`;

export const SelectedBillingCycleSection = styled.div`
  margin-top: 24px;
  
  & > * {
    margin-bottom: 16px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const GradientText = styled.span`
  font-weight: 600;
  background: ${colorConstants.gradientForText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;