import {
    Container,
    Title,
    Header,
    StyledParagraph as Text,
    List,
    OrderedList,
    ListItem as Item,
    StyledLink as Link,
} from './PrivacyPolicyPage.styles';
import { sitemap, companyConstants, otherConstants } from '../../../../constants';
import { formatAddressHTML } from '../../../../utils';



export const PrivacyPolicyPage = () => {
    return (
        <Container>
            <Title>Privacy Policy</Title>


            <Header>Our privacy commitment</Header>

            <Text>Our pledge is to put users first. Your privacy is at the heart of the process we design and build our services and products so, that you can trust and enjoy them the most.</Text>

            <Text>We appreciate you trust us with your information when using our services and take this big responsibility very seriously. We work hard to protect your information and also have you in control.</Text>

            <Text>This Privacy Policy is meant to help you understand what information we collect, why we collect it, how we use it and your meaningful choices regarding your information.</Text>

            <Text>We strive to be transparent about our privacy practices so, we have written our Privacy Policy and related documents in a plain language.</Text>


            <Header>Our services</Header>

            <Text>We build a range of services that help people in various new ways. Our services include:</Text>

            <List>
                <Item>{otherConstants.projectName} websites and apps such as {otherConstants.domain} and {otherConstants.subDomains.partners}</Item>
            </List>

            <Text>You may find a complete list of all our Services at section <a href={sitemap.landing.z.policies.terms + '#14-services'}>14. Services</a> of our Terms of Service.</Text>


            <Header>Who we are</Header>

            <Text>Our websites and services are operated by {companyConstants.name.full.toUpperCase()}, a company registered in England and which is responsible for your information. Our address is:</Text>

            <Text>{companyConstants.name.full.toUpperCase()}<br/>{formatAddressHTML(companyConstants.address)}</Text>


            <Header>Where this Privacy Policy applies</Header>

            <Text>This Privacy Policy applies to websites, apps and other services operated by {otherConstants.projectName}. To make this Privacy Policy simpler we refer to all of these as our “services”. We also included links on all applicable services to this Privacy Policy.</Text>

            <Text>Some services may need their own exclusive privacy policy. If a services has its own privacy policy, then that policy applies and not this Privacy Policy.</Text>


            <Header>Information we collect</Header>

            <Text>We design and build our websites and services with the sole purpose to help make our user lives more meaningful and convenient. We can't help though without some information about you, such as basic profile details. While using our services we also collect generated information such as access logs, and third party information like when you access our services through a social media account. We are going into more detail below.</Text>

            <Text><strong><i>Information you provide us</i></strong></Text>

            <Text>To use our services, you provide us with specific information such as:</Text>

            <List>
                <Item>For our services to work you provide us with some necessary information when creating an account such as your login credentials, as well as some basic information such as your name, your email address, your gender and your date of birth.</Item>

                <Item>You also have the opportunity to share with us additional information, such as details about your personality, activities and products you are interested in and other details about you, as well as content such as photos. To share with us specific content, such as photos, you may allow us to access your camera or photo library. Be aware that some of the content you provide us may be considered “sensitive”, for example, your racial or ethnic background, sexual and religious denomination. Where you choose to provide us with sensitive data about yourself, you explicitly consent to our processing of that information.</Item>

                <Item>You also provide us or our payment service provider with information, such as your credit or debit card number or other financial information, when you subscribe to a paid service or make a purchase directly from us (rather than through a platform such as iOS or Android).</Item>

                <Item>When you participate in our events, contests, promotions, subscribe to marketing campaigns, focus groups or surveys, we collect the information that you enter as well as the insights you give us on our products and services, replies to our testimonials and questions.</Item>

                <Item>When you contact our customer support team, we collect the information you provide us during the communication.</Item>

                <Item>Additionally, we also process your chats with other users, as part of the operation of some of our services.</Item>
            </List>

            <Text><strong><i>Information we get from others</i></strong></Text>

            <Text>We also receive information on you from others, including:</Text>

            <List>
                <Item><strong>Other Users</strong><br/>Other users of our services may provide us with information about you, for example when they contact us about you.</Item>
                <Item><strong>Social Media</strong><br/>We may provide you with the ability to use your social media login (such as Facebook or Google login), to create and log into your account in our services, for your own convenience, which also allows you to share with us some information from your social media account.</Item>
                <Item><strong>Our Partners</strong><br/>When we run and publish our services ads on a partner's platforms or websites, we may receive information about you along a campaign's success details.</Item>
            </List>

            <Text><strong><i>Information we collect when you use our services</i></strong></Text>

            <Text>We also collect additional information when you use our services, such as the features you have used, how did you use them and the devices you used our services on. You may find more details below:</Text>

            <List>
                <Item><strong>Usage Information</strong><br/>When you use our services we collect information about your activity and how you use them, such as the time and date you accessed them, the features you used, the buttons you clicked and pages that have been displayed to you, the referring website links and advertising that you click on, as well as your interaction with other of our users, such as which users you connect and interact with, the date and time of your interactions and the number of messages you exchange.</Item>

                <Item><strong>Device information</strong><br/>We collect information about the devices you access our services from, such as hardware and software information like the device ID, device type, IP address, advertising IDs, app crashes, operating system, browser type, language and version, time zones, cookie identifiers or other technologies that may uniquely identify your browser or device, like MAC address and UDID/IMEI, information on device sensors, like gyroscopes, accelerometers and compasses, and information on your mobile and wireless network connection, like your signal strength and service provider.</Item>

                <Item><strong>Other information with your permission</strong><br/>We prompt you for additional information which you may give us permission to, such as to collect precise geolocation (longitude and latitude) via different methods based on the device and the service you are using, such as Bluetooth, GPS or Wi-Fi connections. The collection may be done in the background even if you aren't using our services and if the permission you gave us allows it, we will not collect it if you decline the permission to us. Furthermore, when you choose to publish a photo on our services, with your consent, we may collect those photos.</Item>
            </List>


            <Header>Cookies and similar data collection technologies</Header>

            <Text>On our services, we may use and we also may allow others to use cookies or other similar technologies to identify you and/or your device(s). We share more information in our <Link to={sitemap.landing.z.policies.cookie}>Cookies Policy</Link> on why we use those technologies, for example, to remember your settings and preferences, to authenticate you, to deliver and measure the effectiveness of advertising campaigns, to analyse site trends and traffic and also to allow you to use social media features. We also share with you how you can control cookies via different tools.</Text>

            <Text>There are browsers and websites that support a “Do Not Track” (“DNT”) feature, which sends a website a signal to not collect specific information about the browser's user. This feature though is not supported by all browsers and thus a lot of businesses do not currently respond to DNT signals, as this is not a reliable method.</Text>


            <Header>How we use information</Header>

            <Text>Delivering and improving our services is the primary reason we use your information. To do that, we also use your information to help keep you safe and also to cater you with advertising that may be of your interest. You may read some more detailed information of the different reasons we use your information below.</Text>

            <Text><strong><i>To provide you with our services and administer your account</i></strong></Text>

            <List>
                <Item>Create and handle your account</Item>

                <Item>Reply to your inquiries and cater you with customer support</Item>

                <Item>Fulfil your transactions</Item>

                <Item>Contact you about our services, including billing and order management</Item>
            </List>

            <Text><strong><i>To help connect you with other users</i></strong></Text>

            <List>
                <Item>Analyze your profile and searches as well that of other users, and recommend relevant connections</Item>

                <Item>Show users' profiles to each other</Item>
            </List>

            <Text><strong><i>To ensure a steady experience across your devices</i></strong></Text>

            <List>
                <Item>We use your information to sync the various devices you use to provide you with a true experience of our services on all of your devices. We link all your data coming from the browser and devices, like browser version, full or partial IP address and similar data, so that when you login we can identify your devices and link them.</Item>
            </List>

            <Text><strong><i>To deliver you relevant ads and offers</i></strong></Text>

            <List>
                <Item>Manage discounts, contests or other offers</Item>

                <Item>Create, present and track advertising and content tailored to your interests on our services and other sites</Item>

                <Item>Contact you by email, social media, phone or mobile device regarding services and products that we think may be of your interest</Item>
            </List>

            <Text><strong><i>To better our services and build new ones</i></strong></Text>

            <List>
                <Item>Manage surveys and focus groups</Item>

                <Item>Perform research and analysis on the behaviour of the users so that we can improve our services and content</Item>

                <Item>Build new services and features based on requests received from users</Item>
            </List>

            <Text><strong><i>To be legally compliant</i></strong></Text>

            <List>
                <Item>Adhere to legal requirements</Item>

                <Item>To help law enforcement</Item>

                <Item>Apply or practice our rights, for instance, our Terms</Item>
            </List>

            <Text>Legal bases we are based on, so that we can process your information as mentioned above:</Text>

            <List>
                <Item><i>Provide you with our service:</i> In order to execute your contract with us, almost all the time we need to process your information. For example, when you are using our services, we maintain your profile and your account using your information, to allow other users to view it and to recommend it to them.</Item>

                <Item><i>Legitimate interest:</i> Your information may be used by us where we have legitimate interests to do so, such as to analyse users' behavior on our services so that we can improve them, to suggest offers that we think might be of your interest, and to process your information for administrative, fraud detection and other legal purposes.</Item>

                <Item><i>Your consent:</i> We may want to use your information for certain particular reasons from time to time, and we may ask for your consent to do so. Your consent may be withdrawn at any time if you'd want to by simply contacting us at the address mentioned at the end of this Privacy Policy.</Item>
            </List>


            <Header id='how-we-share-information'>How we share information</Header>

            <Text>It goes without saying that, we share your information with other users of our services, in order to be able to provide you with awesome and meaningful services. Additionally, we share some of our users' information with partners and service providers who help us in operating our services including legal authorities. You may read below for more details on how we share your information with others.</Text>

            <List>
                <Item><strong>With our other users</strong><br/>When you willingly publish information on our services you are sharing information with our other users, including your public profile. Be aware that when you publish information with other users neither you nor we can control what others do with your information, so make sure that you are comfortable with the content you share publicly.</Item>

                <Item>
                    <strong>With our partners and service providers</strong>
                    <br/>
                    We partner with third parties that help us operate our services by specifically helping us in tasks like analytics, data hosting and maintenance, advertising, customer care, payment processing and security operations.
                    <br/>
                    We also have partners that distribute and help us advertise our services, that we may also share information with, such as limited information on you in a non-human readable form.
                    <br/>
                    We evaluate very strictly our service providers and partners before we proceed in any form of cooperation with them and proceed only with strict confidentiality agreements.
                </Item>
                
                <Item><strong>For corporate transactions</strong><br/>There are a series of corporate transactions (whole or partial) that we may transfer your information, such as a merger, acquisition, sale, restructure, divestiture, reorganize, dissolution, bankruptcy or other forms of ownership or control change.</Item>
                
                <Item><strong>When the law requires it</strong><br/>We may share your information if necessary: to be compliant with a legal process, like a court order, search warrant or subpoena, government or law enforcement investigation or other legal requirements; to help in the detection or prevention of crime (each case is subject to applicable law); or to protect a person's safety.</Item>
                
                <Item><strong>To enforce legal rights</strong><br/>Also, we may share information: if publication would ease our liability in an actual or threatened lawsuit; to protect our legal rights and legal rights of our users, business partners or other interested parties as necessary; to enforce our agreements with you; and to investigate, prevent, or take other action regarding suspected fraud, illegal activity or other wrongdoing.</Item>
                
                <Item>
                    <strong>At your request or with your consent</strong>
                    <br/>
                    In any case, we may want to share your information with third parties, we will ask for your consent first and we'll be very clear on why we want to do this.
                    <br/>
                    Under any of the above-mentioned cases, we may use and share: non-personal information which by itself, does not identify who you are, like device information, general behavioral data, general; demographics, geolocation in a form that is not identifiable; and personal information in a non-human readable form. Also, we may share this information with our advertisers to create and cater targeted advertising on our services and on applications or websites of third parties and to analyze and report on advertising you see. Additionally, we may couple this information with extra non-personal or personal information in a non-human readable form, collected from other sources. You may find more details and information on our use of cookies and similar technologies in our <Link to={sitemap.landing.z.policies.cookie}>Cookies Policy</Link>.
                </Item>
            </List>


            <Header>Cross-border data transfers</Header>

            <Text>Information sharing as mentioned in the <a href='#how-we-share-information'>How we share information</a> section at times may require cross-border data transfers in countries around the world such as the United States of America and other jurisdictions. This means that while our services allow for users to be located anywhere in the world, such as in the EEA (“European Economic Area”), their personal information might be transferred to countries other than where they are located, such as outside of the EEA. To permit cross-border data transfers, we use contracts that commit companies transferring personal data and binding them in protecting and securing your data, as per the specifications of European Commission or any other suitable security authorities.</Text>


            <Header id='your-rights'>Your rights</Header>

            <Text>We provide you with the following tools, to make sure that you are in control of your privacy and information across our services:</Text>

            <List>
                <Item><u>Manage and update tools. </u>Account settings and other tools to help you access, update or delete information associated with your account directly in our services that you've given to us. For further assistance on any of those tools please contact us <a href='#contact'>here</a>.</Item>

                <Item><u>Device and browser permissions. </u>Web browsers and mobile device platforms have permission systems for particular types of notifications and data, such as location services, phone book and push notifications. You can modify your settings on your device and browser to either oppose or consent the collection of the analogous information or the appearance of an analogous notification. It goes without saying though that certain services may stop fully functioning if you do that.</Item>

                <Item><u>Deletion. </u>You can delete your account directly from within our services by using the analogous functionality.</Item>
            </List>

            <Text>Also please go through the below important points in order to be aware of your privacy rights.</Text>

            <List>
                <Item><u>Information review. </u>Based on your country, privacy laws may provide you with the right to review (also called the right of access, right of portability and similar terms) the personal information we hold about you. You may request a copy of your personal information by sending us a request <a href='#contact'>here</a>.</Item>

                <Item><u>Information update. </u>You may also request that we update, delete or stop processing your information if you believe they are inaccurate or that we are no longer designated to use them. To do so please contact us <a href='#contact'>here</a>.</Item>
            </List>

            <Text>For security purposes and to keep you and the rest of our users secure we may ask that you provide proof of identity before we can proceed to answer the above inquiries.</Text>

            <Text>Be aware though, that we may deny requests for some reasons, including if the requests are not legal or if it may breach intellectual property or trade secrets or another user's privacy. If you request to receive information that discloses another user's information, like a copy of a chat messages transcript between you and another user, the other user has to contact us and provide us with their written consent before we release the information.</Text>

            <Text>Additionally, we may be unable to satisfy specific requests to stop processing of personal information, such as your date of birth, as this kind of request would set us unable to provide you with our services anymore.</Text>

            <List>
                <Item><u>Uninstall. </u>By uninstalling an app you can stop all information collection, this can be done using the standard process for your device. If you do that though, the unique identifier associated with your device will remain stored. This will provide us with the ability to re-associate this identifier to your previous activities and transaction when you install again the app on the same device.</Item>

                <Item><u>Accountability. </u>Some countries, like the European Union, provide you with the right to lodge a complaint if you have any concerns about the way we process your personal information. You may lodge a complaint with the relevant data protection authorities where you reside or where we are established.</Item>
            </List>


            <Header>Residents of California</Header>

            <Text>In case you are a California resident, you may request information regarding the disclosure of the categories of personal information about you from us to third parties for their direct marketing purposes during the previous calendar year. You can request this information by contacting us <a href='#contact'>here</a>. Again, for security purposes and to keep you and the rest of our users secure we may ask that you provide proof of identity before we can proceed to answer this kind of request.</Text>


            <Header>How we protect your information</Header>

            <Text>We work hard to protect you and our services from unauthorised access, alteration, disclosure or destruction of the information we hold including your personal information. While we take reasonable steps against possible security breaches of our services, as with any website and technology companies, we cannot guarantee, and you should not expect, that your personal information will remain secure.</Text>

            <Text>Our technical experts monitor our systems regularly for possible attacks and vulnerabilities and frequently review our information collection, processing and storage practices to update our security measures.</Text>

            <Text>In case that we detect or suspect any security breach, we may suspend your use of our services without notice. Also, please notify us immediately <a href='#contact'>here</a>, if you believe that your information or account is not secure anymore.</Text>


            <Header>How long we retain your information</Header>

            <Text>We only hold your personal information for as long as we need it for legitimate business purposes as mentioned in the <a href='#how-we-use-information'>How we use information</a> section and as allowed by applicable law. When you delete your account it will become immediately unavailable but your account information will be retained for 30 days, and that is to protect the security and safety of our users.</Text>

            <Text>Upon deletion (following the above mentioned 30 days) or after 30 months of continuous account inactivity we anonymize or delete your information, except for:</Text>

            <OrderedList>
                <Item>information we hold for anti-fraud and anti-spam purposes such as payment information, IP addresses, mobile numbers and email addresses. This information is kept for as long as they are relevant (usually determined by the expiry of the payment method);</Item>

                <Item>information we hold in order to comply with applicable law, such as some “traffic data” which are kept for one year to comply with statutory data retention obligations;</Item>

                <Item>information we hold in order to confirm our compliance with applicable law, such as records with your consents to our Terms, Privacy Policy and other similar consents which are kept for five years;</Item>

                <Item>the information related to unresolved issues, disputes or claims which are kept until is resolved.</Item>
            </OrderedList>


            <Header>Children's privacy</Header>

            <Text>We restrict the use of our services to users who are 18 years of age and above. We deny access to our services and we never collect or maintain personal information from users we actually know are under the age of 18. If you believe that a user is under the age of 18, please report it immediately <a href='#contact'>here</a>.</Text>


            <Header>Changes to this Privacy Policy</Header>

            <Text>As we always strive to provide you with as much value as we can with new and innovative ways, this policy may change over time. When we do change this policy we will make sure to notify you in prior so that you can review the changes before they take effect.</Text>


            <Header id='contact'>Contacting us</Header>

            <Text>If you have any questions about this Privacy Policy, you can contact us:</Text>

            <Text>By email: <a href='mailto:info@bunj.ee'>info@bunj.ee</a></Text>

            <Text>By post:</Text>

            <Text>Support Team<br/>{companyConstants.name.full.toUpperCase()}<br/>{formatAddressHTML(companyConstants.address)}</Text>


            <Text>This document was last updated on January 18, 2022</Text>

        </Container>
    );
};