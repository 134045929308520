import styled from 'styled-components';
import FormText from 'react-bootstrap/FormText';
import { Button } from '../../../custom/Buttons';

import { colorConstants, deviceConstants } from '../../../../constants';


export const Container = styled.div`
    font-size: 12px;
    padding-bottom: 170px;

    @media ${deviceConstants.tablet} {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

export const SectionTitle = styled.div`
    font-weight: 700;
    font-size: 14px;
    color: ${colorConstants.black};
`;

export const SearchSection = styled.div`
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
`;

export const SearchResults = styled.div`
    padding-top: 16px;

    box-shadow: 0 4px 6px rgb(32 33 36 / 28%);
    background: ${colorConstants.white};

    min-height: 150px;
    max-height: 250px;
    overflow: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    width: 100%;

    & > * {
        border-bottom: 1px solid ${colorConstants.softGrey};
    }

    & > *:last-child {
        border-bottom: none;
    }
`;

export const SearchResultItem = styled.div`
    border-radius: 2px;
    padding: 16px;

    font-size: 14px;
    font-weight: 500;
    color: ${colorConstants.black};

    :hover {
        cursor: pointer;
        background: ${colorConstants.primaryFaded};
    }
`;

export const SearchResultItemLink = styled.div`
    margin-top: 6px;

    font-size: 10px;
    font-weight: 400;
    color: ${colorConstants.mediumGrey};
`;

export const ProjectEditSection = styled.div`
    width: 100%;
    margin-top: 48px;
    padding-left: 32px;
    padding-right: 32px;

    & > * {
        margin-bottom: 32px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;

export const StyledFormText = styled(FormText)`
  color: ${colorConstants.warning};
`;

export const ProjectEditSectionInput = styled.div`

`;

export const MaxTextIndicator = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: ${colorConstants.softGrey};
  text-align: end;
  margin-top: 1px;
`;

export const ProjectIconSection = styled.div`

`;

export const FileUploader = styled.input`
    display: none;
`;

export const ProjectLanguage = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;

    font-size: 12px;
    font-weight: 500;
    color: ${colorConstants.black};
`;

export const StyledButton = styled(Button)`
    width: 100%;
    position: fixed;
    bottom: 0;

    @media ${deviceConstants.tablet} {
        width: 25%;
        border-radius: 2px;
        
        position: absolute;
        bottom: 20px;
    }

    @media ${deviceConstants.desktop} {
        width: 20%;
    }
`;

export const Notification = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${colorConstants.primaryDark};

  :hover {
    color: ${colorConstants.primary};
    cursor: pointer;
  }
`;