import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ReCAPTCHA from "react-google-recaptcha";
import {
    StyledForm,
    StyledFormGroup,
    StyledFormText,
    StyledFormLabel,
    SubmitButton,
    GDPR,
    RecaptchaContainer,
    SpinnerContainer,
    AlertWarning,
    AlertSuccess,
} from './TechiesForm.styles';
import { GradientFormInput, GradientFormSelector } from '../../custom/FormInput';
import { otherConstants, contentTechiesEarlyAccess } from "../../../constants";



export const TechiesForm = (props) => {
    const { 
        alert,
        handleChange,
        handleSubmit,
        handleCaptcha,
        registering,
        user,
        submitted,
        captcha,
        isEmail,
        isSocialLink,
     } = props;

    return (
        <StyledForm onSubmit={handleSubmit}>
            <StyledFormGroup className={submitted && !user.name ? " has-error" : ""} >
                <GradientFormInput
                    box={'enable'}
                    nolabel={'enable'}
                    type="text"
                    placeholder={contentTechiesEarlyAccess.form.field1.placeholder}
                    name="name"
                    value={user.name}
                    onChange={handleChange}
                />
                { submitted && !user.name && <StyledFormText>{contentTechiesEarlyAccess.form.field1.warning}</StyledFormText> }
            </StyledFormGroup>

            <StyledFormGroup className={submitted && !user.igHandle ? " has-error" : ""} >
                <GradientFormInput
                    box={'enable'}
                    nolabel={'enable'}
                    type="text"
                    placeholder={contentTechiesEarlyAccess.form.field2.placeholder}
                    name="igHandle"
                    value={user.igHandle}
                    onChange={handleChange}
                />
                { submitted && !user.igHandle && <StyledFormText>{contentTechiesEarlyAccess.form.field2.warning}</StyledFormText> }
            </StyledFormGroup>

            <StyledFormGroup className={submitted && !user.primaryPlatform ? " has-error" : ""} >
                <GradientFormInput
                    box={'enable'}
                    nolabel={'enable'}
                    type="text"
                    placeholder={contentTechiesEarlyAccess.form.field3.placeholder}
                    name="primaryPlatform"
                    value={user.primaryPlatform}
                    onChange={handleChange}
                />
                { submitted && !user.primaryPlatform && <StyledFormText>{contentTechiesEarlyAccess.form.field3.warning}</StyledFormText> }
                { submitted && user.primaryPlatform && !isSocialLink && <StyledFormText>{contentTechiesEarlyAccess.form.field3.warning2}</StyledFormText> }
            </StyledFormGroup>

            <StyledFormGroup className={submitted && !user.email ? " has-error" : ""} >
                <GradientFormInput
                    box={'enable'}
                    nolabel={'enable'}
                    type="email"
                    placeholder={contentTechiesEarlyAccess.form.field4.placeholder}
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                />
                { submitted && !user.email && <StyledFormText>{contentTechiesEarlyAccess.form.field4.warning1}</StyledFormText> }
                { submitted && user.email && !isEmail && <StyledFormText>{contentTechiesEarlyAccess.form.field4.warning2}</StyledFormText> }
            </StyledFormGroup>

            <StyledFormGroup>
                <StyledFormLabel>{contentTechiesEarlyAccess.form.field5.placeholder}</StyledFormLabel>
                <GradientFormSelector
                    big={'enable'}
                    options={contentTechiesEarlyAccess.form.field5.options}
                    id="selector"
                    name="type"
                    onChange={handleChange} />
            </StyledFormGroup>

            <RecaptchaContainer>
                <ReCAPTCHA
                    sitekey={otherConstants.captchaKey}
                    onChange={handleCaptcha}
                />
                { submitted && !captcha && <StyledFormText>reCAPTCHA is required</StyledFormText> }
            </RecaptchaContainer>

            <StyledFormGroup>
                <SubmitButton midCorner={true} gradient={true} type="submit">
                    {contentTechiesEarlyAccess.form.button}
                </SubmitButton>

                {registering && <SpinnerContainer><Spinner animation="border" /></SpinnerContainer>}
            </StyledFormGroup>

            <GDPR>{contentTechiesEarlyAccess.form.gdpr}</GDPR>

            {alert.message && alert.type === 'alert-danger' && <AlertWarning>{alert.message}</AlertWarning>}
            {alert.message && alert.type === 'alert-success' && <AlertSuccess>{alert.message}</AlertSuccess>}
        </StyledForm>
    );
};