 import React, { useState, useEffect } from 'react';
 import { colord } from 'colord';
 import { ColorPicker } from './ColorPicker';
 import {
     Container,
     ColorPreviewGradientBorder,
     ColorPreview,
     InputBoxGradientBorder,
     StyledFormControl,
 } from './ColorInput.styles';
 
 
 
 export const ColorInput = (props) => {
     const { name, savedColor, handleColorPick, deactivate } = props;

     // component state
     const [color, setColor] = useState('#C2C2C2');
     const [input, setInput] = useState('');
     const [colorPicker, setColorPicker] = useState(false);

     function inputEvent(event) {
        event.preventDefault();
        const { value } = event.target;
        colorPick(value);
     }
 
     function colorPick(value) {
        // set type input
        setInput(value);

        // set new color
        if(colord(value).isValid()){
            setColor(value);
            if(handleColorPick) handleColorPick(value, name);
        }

        // reset color pick
        if(value === '') {
            setColor('#C2C2C2');
            if(handleColorPick) handleColorPick('', name);
        }
     }

     useEffect(() => {
         // initialize state values
        if(savedColor === '' || colord(savedColor).isValid()) {
            setColor(savedColor);
            setInput(savedColor);
        }
     }, [savedColor]);
 
     return (
         <Container>
             <ColorPreviewGradientBorder deactivate={!savedColor}>
                <ColorPreview color={color} onClick={() => setColorPicker(!deactivate && !colorPicker)} deactivate={deactivate} />
             </ColorPreviewGradientBorder>

             <InputBoxGradientBorder deactivate={deactivate} >
                <StyledFormControl type='text' name='hex-color-input' placeholder='Hex color' value={input} onChange={inputEvent} disabled={deactivate}/>
            </InputBoxGradientBorder>

            {colorPicker &&
            <ColorPicker
                savedColor={color}
                handleHide={() => setColorPicker(!colorPicker)}
                handleColorPick={colorPick} /> }
         </Container>
     );
 };