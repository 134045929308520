import {
    Container,
    Content,
    Name,
    Location,
    ButtonsSection,
    EditButton,
    DeleteButton
} from './DraftTripsListItem.styles';


const DraftTripsListItem = (props) => {
    const { trip, handleEdit, handleDelete, ...rest } = props;
    return (
        <Container {...rest} >
            <Content onClick={() => handleEdit(trip.id, trip.customUrl)}>
                <Name onClick={() => handleEdit(trip.id, trip.customUrl)}>{trip.name}</Name>
                <Location onClick={() => handleEdit(trip.id, trip.customUrl)}>{trip.city + ', ' + trip.country}</Location>
            </Content>

            <ButtonsSection>
                <EditButton size={14} onClick={() => handleEdit(trip.id, trip.customUrl)} />
                <DeleteButton size={14} onClick={() => handleDelete(trip.id, trip.customUrl)} />
            </ButtonsSection>
        </Container>
    );
};

export default DraftTripsListItem;