import React, { useMemo, useCallback, useState, useEffect } from 'react';
import {
    Container, Header, Title, Body, BodyTitle, BodySubtitle,
    ButtonsList, Button, ButtonLeft, ButtonTitle, ButtonRight, ButtonNumber, ButtonMinus, ButtonPlus,
    DeleteChildButton, AddChildButton,
} from './ChildrenAgesPopup.styles';



export const ChildrenAgesPopup = ({ onHide, show, total, max, ages, handleAddChildrenAge, handleDeleteChildrenAge, onChange }) => {

    const [disableMinus, setDisableMinus] = useState([]);
    const [disablePlus, setDisablePlus] = useState([]);
    const [disableAddChild, setDisableAddChild] = useState(false);

    const enableButtons = useCallback((index, isPlus) => {
        if(!ages || ages.length === 0) {
            setDisableMinus([]);
            setDisablePlus([]);
            return;
        } else {
            let minus = [];
            let plus = [];
            for(let i = 0; i < ages.length; i++) {
                if(index === i) {
                    minus.push(!isPlus ? ages[i] === 3 : false);
                    plus.push(isPlus ? ages[i] === 11 : false);
                } else {
                    minus.push(ages[i] === 2);
                    plus.push(ages[i] === 12);
                }
            }
             // show/hide minus button
            setDisableMinus(minus);
            // show/hide plus button
            setDisablePlus(plus);
        }
    }, [ages]);

    const handleMinus = useCallback((index) => {
        enableButtons(index, false);
        if(ages[index] === 2) return;
        if(onChange) onChange(index, ages[index] - 1);
    }, [ages, onChange, enableButtons]);

    const handlePlus = useCallback((index) => {
        enableButtons(index, true);
        if(ages[index] === 12) return;
        if(onChange) onChange(index, ages[index] + 1);
    }, [ages, onChange, enableButtons]);

    const buttons = useMemo(() => {
        if(!total || total === 0 || !ages) return null;

        return ages.map((age, index) => (
                                    <Button key={`child_${index}`}>
                                        <ButtonLeft>
                                            <DeleteChildButton onClick={() => handleDeleteChildrenAge(index)} size={11} className='icon-delete-child' />
                                            <ButtonTitle>{`Child ${index + 1}`}</ButtonTitle>
                                        </ButtonLeft>

                                        <ButtonRight>
                                            <ButtonMinus isdisabled={disableMinus[index] ? 'true' : null} onClick={() => handleMinus(index)} size={18} className='icon-minus' />
                                            <ButtonNumber>{age} years old</ButtonNumber>
                                            <ButtonPlus isdisabled={disablePlus[index] ? 'true' : null} onClick={() => handlePlus(index)} size={18} className='icon-plus' />
                                        </ButtonRight>
                                    </Button>
                                    ));

    }, [total, ages, disableMinus, disablePlus, handleMinus, handlePlus, handleDeleteChildrenAge]);


    useEffect(() => {
        setDisableAddChild(total === max);
    }, [total, max]);
    
    
    return (
        <Container show={show} onHide={onHide}>
            <Header closeButton>
                <Title>Age of children at check-out</Title>
            </Header>

            <Body>
                <BodyTitle>What are the ages of the children you're travelling with?</BodyTitle>
                <BodySubtitle>Note, we'll use child ages to find you the best match in room size, beds, and prices.</BodySubtitle>
                <ButtonsList>{buttons}</ButtonsList>
                <AddChildButton isdisabled={disableAddChild ? 'true' : null} onClick={handleAddChildrenAge}>Add child</AddChildButton>
            </Body>
      </Container>
    );
};