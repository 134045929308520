import {
    Container,
    ProfileIcon,
    LinksIcon,
    PlusIcon,
    EditIcon,
    TextSection,
    Title,
    Text,
    SubText
} from './QuickLink.styles';
import { colorConstants } from "../../../constants";
import Icon from '../../../assets/icons/Icon';


export const QuickLink = ({ profileIcon, linkIcon, tripIcon, productIcon, nftIcon, customizeIcon, editBioIcon, addIcon, editIcon, title, text, secondaryText, onClick, ...props }) => {

    return (
        <Container onClick={onClick} {...props} >
            { profileIcon && <ProfileIcon /> }
            { linkIcon && <LinksIcon /> }
            { tripIcon && <Icon type='hiking' size={24} customcolor={colorConstants.primaryDark} /> }
            { productIcon && <Icon type='product' size={24} customcolor={colorConstants.primaryDark} /> }
            { nftIcon && <Icon type='crypto' platform='nft' size={24} customcolor={colorConstants.primaryDark} /> }
            { customizeIcon && <Icon type='color_palette' size={24} customcolor={colorConstants.primaryDark} /> }
            { editBioIcon && <Icon type='user_bio' size={24} customcolor={colorConstants.primaryDark} /> }

            <TextSection>
                <Title>{title}</Title>
                <Text>{text}</Text>
            </TextSection>

            { addIcon && !editIcon && !secondaryText && <PlusIcon /> }
            { !addIcon && editIcon && !secondaryText && <EditIcon /> }
            { !addIcon && !editIcon && secondaryText && <SubText>{secondaryText}</SubText> }
        </Container>
    );
};