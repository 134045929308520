import styled from 'styled-components';
import { Button } from '../../custom/Buttons';
import { colorConstants } from "../../../constants";


export const Container = styled.div`
    padding: 0 32px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.div`
    margin-top: 20px;
    font-size: 22px;
    font-weight: 500;
    color: ${colorConstants.black};
`;

export const Message = styled.div`
    margin: 16px 0 24px 0;
    padding: 0 16px;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
`;

export const StyledButton = styled(Button)`
    width: 100%;
`;