import React from "react";
import styled from 'styled-components';
import DraftTripsListItem from './DraftTripsListItem';
import { Divider } from '../../custom/Divider';
import { colorConstants } from "../../../constants";


const List = styled.div`
    margin-top: 32px;
    padding-bottom: 16px;
`;

const SectionTitle = styled.div`
    font-size: 16px;
    font-weight: 800;
    color: ${colorConstants.black};
    margin-top: 16px;
    margin-bottom: 16px;
`;


export const DraftTripsList = (props) => {
    const { trips, handleEditTrip, handleDeleteTrip } = props;

    // get draft trips
    const draft = trips && trips.filter(item => !item.isSearch && !item.isSectionLabel && (!item.tripPhotoUrls || item.tripPhotoUrls.length === 0));

    // hide draft trips list if no draft found
    if(!draft || draft.length === 0) return null;

    // render draft trips list
    return (
        <List>
            <Divider />
            <SectionTitle>Draft Trips</SectionTitle>
            {draft.map((item, index) => {
                return (
                    <div key={index}>
                        <DraftTripsListItem trip={item} handleEdit={handleEditTrip} handleDelete={handleDeleteTrip} />
                    </div>
                );
            })}
            <Divider />
        </List>
    );
};