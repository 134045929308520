// Manages the users section of the application state used to list users and user info
import { userReducerConstants } from '../constants';

export function user(state = {}, action) {

  switch (action.type) {

    case userReducerConstants.GETBYID_REQUEST:
        return {
            loading: true
        };
    
    case userReducerConstants.GETBYID_SUCCESS:
        return {
            ...action.user
        };

    case userReducerConstants.GETBYID_FAILURE:
        return {
            error: action.error
        };

    case userReducerConstants.UPDATE_REQUEST:
        if (state.id === action.user.id) return { ...state, updating: true };
        return state;

    case userReducerConstants.UPDATE_SUCCESS: { // I use blocks here to scope the declared variables
        let { updating, ...stateCopy } = state; // remove updating flag
        if (state.id === action.user.id) return { ...stateCopy, ...action.user };
        return stateCopy;
    }

    case userReducerConstants.UPDATE_FAILURE: { // I use blocks here to scope the declared variables
        let { updating, ...stateCopy } = state; // remove updating flag
        if (state.id === action.id) return { ...stateCopy, updateError: action.error };
        return stateCopy;
    }

    case userReducerConstants.CREATOR_STATUS_REQUEST: {
        return {
            ...state,
            creatorModeStatusLoading: true,
        };
    }
    
    case userReducerConstants.CREATOR_STATUS_SUCCESS: {
        let { creatorModeStatusLoading, ...stateCopy } = state;
        return {
            ...stateCopy,
            creatorModeStatus: action.status,
        };
    }        
    
    case userReducerConstants.CREATOR_STATUS_FAILURE: {
        let { creatorModeStatusLoading, ...stateCopy } = state;
        return stateCopy;
    }

    // case userReducerConstants.BECOME_CREATOR_REQUEST:
    //     if (state.id === action.id) return { ...state, enablingCreator: true };
    //     return state;
    
    // case userReducerConstants.BECOME_CREATOR_SUCCESS: {
    //     let { enablingCreator, ...stateCopy } = state; // remove enablingCreator flag
    //     if (state.id === action.user.id) return { ...stateCopy, ...action.user };
    //     return stateCopy;
    // }        
    
    // case userReducerConstants.BECOME_CREATOR_FAILURE: { // I use blocks here to scope the declared variables
    //     let { enablingCreator, ...stateCopy } = state; // remove enablingCreator flag
    //     if (state.id === action.id) return { ...stateCopy, creatorModeError: action.error };
    //     return stateCopy;
    // }

    case userReducerConstants.DELETE_REQUEST:
        if (state.id === action.id) return { ...state, deleting: true };
        return state;

    case userReducerConstants.DELETE_SUCCESS:
        if (state.id === action.id) return {};
        return state;

    case userReducerConstants.DELETE_FAILURE: {
        let { deleting, ...stateCopy } = state;
        return { ...stateCopy, deleteError: action.error }; // remove deleting flag and add error
    }

    case userReducerConstants.CLEAR_ALL:
        return {};

    default:
        return state
  }
}