import styled from 'styled-components';
import FormControl from 'react-bootstrap/FormControl';
import { GeoAltFill, Calendar3, People, Search, X } from 'react-bootstrap-icons';

import { colorConstants } from "../../constants";



export const Container = styled.div`
    min-width: 100%;
    
    border-radius: ${props => props.shape};
    ${({ isOval, expanded }) => isOval && expanded && `
        border-radius: 24px !important;
    `};
    font-family: ${props => props.fontType || 'inherit'} !important;

    position: relative;

    .fader {
        background: ${props => props.borderColor};
        border-radius: ${props => props.shape};
        ${({ isOval, expanded }) => isOval && expanded && `
            border-radius: 24px !important;
        `};
    }

    .title {
        background-color: ${props => props.color};
        color: ${props => props.fontColor};

        border-top-left-radius: ${({ shape, isOval }) => isOval ? '24px' : shape};
        border-top-right-radius: ${({ shape, isOval }) => isOval ? '24px' : shape};

        padding: ${props => props.titlePadding};

        .collapse-button {
            :hover {
                cursor: pointer;
                color: ${props => props.fontColorHover};
            }
        }

        ${({ hideTitle }) => hideTitle && `
            justify-content: flex-end !important;
        `};
    }

    .content {
        background-color: ${props => props.color};
        color: ${props => props.fontColor};

        border-bottom-left-radius: ${({ shape, isOval }) => isOval ? '24px' : shape};
        border-bottom-right-radius: ${({ shape, isOval }) => isOval ? '24px' : shape};

        ${({ hideTitle, expanded, shape }) => hideTitle && !expanded && `
            border-radius: ${shape} !important;
        `};

        padding: ${props => props.contentPadding};
    }
`;

export const ContainerFader = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0.1;

    z-index: -1;
`;

export const Title = styled.div`
    width: 100%;
    margin: 0;

    // text-align: center;
    font-size: 15px;
    font-weight: 600;

    /** break text to multiple lines to contain it within div */
    word-break: break-all;
    line-height: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const CloseIcon = styled(X)`
    font-size: 28px;
    padding: 4px;
`;

export const Content = styled.div`
    width: 100%;
    margin: 0;

    & > * {
        margin-bottom: 8px !important;
    }

    & > *:last-child {
        margin-bottom: 0 !important;
    }
`;

export const Button = styled.div`
    width: 100%;
    border: solid 1px ${props => props.fontColor};
    ${({ noBorderWidget }) => noBorderWidget && `
        border: none;
        padding-top: 8px;
        padding-bottom: 8px;
    `};
    border-radius: ${props => props.shape};
    background-color: ${props => props.buttonColor || 'none'};
    color: ${props => props.fontColor};

    ${({ disabled, isdark }) => disabled && `
        background-color: transparent;
        color: ${isdark ? colorConstants.mediumGrey : colorConstants.softGrey};
        border-color: ${isdark ? colorConstants.mediumGrey : colorConstants.softGrey};
    `};

    display: flex;
    align-items: center;
    justify-content: space-between;

    .button-divider {
        border-color: ${props => props.fontColor};
    }

    :hover {
        cursor: ${({expanded, disabled}) => disabled ? 'auto' : expanded ? 'text' : 'pointer'};
        background-color: ${({expanded, disabled, buttonColorHover}) => disabled ? 'transparent' : !expanded ? buttonColorHover : 'inherit'};
        color: ${({expanded, disabled, fontColorHover, isdark}) => disabled ? (isdark ? colorConstants.mediumGrey : colorConstants.softGrey) : !expanded ? fontColorHover : 'inherit'};
        border-color: ${({expanded, disabled, fontColorHover, isdark}) => disabled ? (isdark ? colorConstants.mediumGrey : colorConstants.softGrey) : !expanded ? fontColorHover : 'inherit'};

        .button-divider {
            border-color: ${({expanded, disabled, fontColorHover}) => disabled ? colorConstants.softGrey : !expanded ? fontColorHover : 'inherit'};
        }
    }
`;

export const SearchIcon = styled(Search)`
    font-size: ${({ expanded }) => expanded ? '14px' : '20px'};
    margin-left: ${({ expanded }) => expanded ? '16px' : '8px'};
    margin-right: ${({ expanded }) => expanded ? '0px' : '16px'};
`;

export const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonSection = styled.div`
    padding: 8px 16px;
    
    display: flex;
    align-items: center;

    ${({center}) => center && `
        width: 100%;
        justify-content: center;
    `};
`;

export const ButtonDivider = styled.div`
    width: 1px;
    height: 26px;
    border-right: 1px solid;
`;

export const Text = styled.div`
    margin: 0;

    font-size: 15px;
    font-weight: 600;

    /** break text to multiple lines to contain it within div */
    word-break: break-all;
    /** limit text to 1 line */
    // line-height: 1.5em;
    // height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const WhereIcon = styled(GeoAltFill)`
    font-size: 14px;
    margin-right: 8px;
`;

export const WhenIcon = styled(Calendar3)`
    font-size: 14px;
    margin-right: 8px;
`;

export const WhoIcon = styled(People)`
    font-size: 14px;
    margin-right: 8px;
`;

export const StyledFormControl = styled(FormControl)`
    font-size: 15px;
    font-weight: 600;
    background-color: ${props => props.inputcolor} !important;
    color: ${props => props.fontcolor} !important;
    padding: 8px;
    border: none;
    border-radius: ${props => props.shape};

    :focus {
        background-color: ${props => props.inputcolor} !important;
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }

    ::placeholder {
        color: ${props => props.fontcolor} !important;
        opacity: 0.5;
    }
`;

export const ClearText = styled.div`
    margin: 0;
    padding: 8px 16px;
    color: ${props => props.fontColor};

    text-align: center;
    font-size: 11px;
    font-weight: 400;

    :hover {
        cursor: pointer;
        color: ${props => props.fontColorHover};
    }
`;