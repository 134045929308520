import React from 'react';
import styled from 'styled-components';
import FormControl from 'react-bootstrap/FormControl'

import { colorConstants } from "../../../constants";

const GradientBorder = styled.div`
    display: flex;
    align-items: center;
    background: ${colorConstants.gradientHorizontal};
    ${({ disabled }) => disabled && `
        background: #e9ecef;
    `}
    padding: 1px;
    margin-top: 4px;
    border-radius: 2px;

    :focus-within {
        background: ${colorConstants.primaryDark};
    }
`;

const StyledFormControl = styled(FormControl)`
    font-size: 12px;
    font-weight: 500;
    color: ${colorConstants.black};
    padding: 2px 4px;
    border-radius: 2px;
    border-color: ${colorConstants.white};

    :focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }
    
    ::placeholder {
        color: ${colorConstants.softGrey};
    }
`;

export const GradientInputEditor = (props) => {
    const { disabled } = props;
    return (
        <GradientBorder disabled={disabled} >
            <StyledFormControl {...props} />
        </GradientBorder>
    );
};