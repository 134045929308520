import styled from 'styled-components';
import { StarHalf, StarFill } from 'react-bootstrap-icons';

import { colorConstants } from "../../../constants";

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: ${({ margin }) => margin || '4px 0'};

    & > * {
        margin-right: 2px;
    }

    & > *:last-child {
        margin-right: 0px;
    }

    :hover {
        cursor: pointer;
    }
`;

const Label = styled.span`
    padding: 0px;
    margin: 0px;
    margin-left: 4px;
    line-height: 1.2;
    font-size: ${({ size }) => size ? `${size > 2 ? size-2 : size}px` : '6px'};
    font-weight: 400;
    color: ${colorConstants.mediumGrey};
`;

export const StarSystem = ({ rating, color, size, margin, label }) => {

    let num = (rating % 1).toFixed(1);
    let count = rating - num;
    let stars = [];

    for (let i = 0; i < count; i++){
        stars.push(<StarFill className='star' key={'star-' + i} size={size || 8} color={color || colorConstants.primary} />);
    }

    if(num >= 0.5) stars.push(<StarHalf className='star' key={'star-' + count} size={size || 8} color={color || colorConstants.primary} />);

    return <Container margin={margin} >{stars}{label && <Label size={size} >{label}</Label>}</Container>;
};