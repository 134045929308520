import styled from 'styled-components';
import { BoxArrowUpRight, List } from 'react-bootstrap-icons';

import { colorConstants, deviceConstants } from "../../constants";


export const Container = styled.div`
    @media ${deviceConstants.tablet} {
        padding-top: 48px; // FEATURE: MOVE CONTROLS TO THE RIGHT - change to 80px
        display: flex;
    }
`;

export const DashboardSection = styled.div`
    @media ${deviceConstants.tablet} {
        width: 65%;
    }
`;

export const ProfilePreviewSection = styled.div`
    display: none;

    @media ${deviceConstants.tablet} {
        display: flex;
        flex-direction: column;
    }
`;

export const TopSection = styled.div`
    padding: 32px 24px 16px 24px;
`;

export const Settings = styled.div`
    display: flex;
    justify-content: flex-end;

    @media ${deviceConstants.tablet} {
        display: none;
    }
`;

export const SettingsButton = styled(List)`
    font-size: 24px;
    color: ${colorConstants.black};

    :hover {
        cursor: pointer;
    }
`;

export const TopSectionControls = styled.div`
    display: none;

    @media ${deviceConstants.tablet} {
        margin-bottom: 32px; // FEATURE: MOVE CONTROLS TO THE RIGHT - remove margin

        display: flex;
        justify-content: flex-start; // FEATURE: MOVE CONTROLS TO THE RIGHT - change to flex-end
    }
`;

export const CopyLink = styled.div`
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    color: ${colorConstants.black};

    display: flex;
    align-items: center;

    & > *:first-child {
        margin-right: 8px;
    }

    :hover {
        cursor: pointer;
        background: ${colorConstants.verySoftWhite};
    }
`;

export const UserInfoSection = styled.div`
    display: flex;
    align-items: flex-end;

    & > *:first-child {
        margin-right: 16px;
    }
`;

export const UserInfoSectionColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const UserInfoFullNameRow = styled.div`
    display: flex;
    align-items: center;

    & > *:first-child {
        margin-right: 12px;
    }
`;

export const UserInfoFullName = styled.div`
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    color: ${colorConstants.primaryDark};
`;

export const UserInfoProfileLink = styled.a`
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    color: ${colorConstants.primaryDark};

    :hover {
        color: ${colorConstants.secondaryDark};

        & > * {
            color: ${colorConstants.secondaryDark};
        }
    }
`;

export const OpenPublicPageButton = styled(BoxArrowUpRight)`
    margin-left: 8px;
    font-size: 12px;
    color: ${colorConstants.primaryDark};
`;

export const UserInfoEmail = styled.div`
    margin-top: 2px;
    font-size: 11px;
    font-weight: 500;
    color: ${colorConstants.black};
`;

export const UserInfoAccountType = styled.div`
    margin-top: 2px;
    font-size: 11px;
    font-weight: 500;
    color: ${colorConstants.black};
`;

export const ContentSection = styled.div`
    padding: 16px 24px;
`;

export const QuickLinks = styled.div`
    padding: 8px 2px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: scroll;

    & > * {
        margin-right: 16px;
    }

    & > *:last-child {
        margin-right: 0;
    }

    @media ${deviceConstants.tablet} {
        overflow-x: hidden;
    }
`;

export const Header = styled.div`
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 700;
    background: ${colorConstants.gradientForShortText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const HeaderGreetingsName = styled.div`
    font-size: 22px;
    font-weight: 700;
`;

export const CreatorApplicationSection = styled.div`
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 600;
    color: ${colorConstants.darkGrey};
    
    & > *:first-child {
        margin-right: 16px;
    }
`;

export const NotificationCardsSection = styled.div`
    @media ${deviceConstants.tablet} { 
        .notification-card {
            width: 30%;
        }
    }
`;

export const CategoriesOverviewSection = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media ${deviceConstants.tablet} {
        justify-content: flex-start;

        .category-card {
            width: 200px;
            margin-right: 32px;
        }

        .category-card:last-child {
            margin-right: 0;
        }
    }
`;