import { Component } from "react";
import { connect } from "react-redux";

import { authActions } from "../../actions";

class LogoutContainer extends Component {
    componentDidMount() {
        this.props.logout();
    }

    render() {
        return null;
    }
}

const actionCreators = {
    logout: authActions.logout
}

const connectedLogoutContainer = connect(null, actionCreators)(LogoutContainer);
export { connectedLogoutContainer as LogoutContainer };