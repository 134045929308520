import styled from 'styled-components';
import FormControl from 'react-bootstrap/FormControl';
import { Trash, PencilFill } from 'react-bootstrap-icons';

import { colorConstants, deviceConstants, shadowConstants } from "../../../../constants";


export const Container = styled.div`
    margin-bottom: 16px;

    display: flex;
    background: ${colorConstants.primaryLight};
    border-radius: 4px !important;

    :focus-within {
        // background: ${props => props.border ? colorConstants.primaryDark : colorConstants.white};
    }

    @media ${deviceConstants.tablet} {
        box-shadow: ${shadowConstants.adminListItems};
    }
`;

export const LeftColumn = styled.div`
    width: 100%;
`;

export const Title = styled.div`
    max-width: 100%;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 700;
    color: ${colorConstants.black};
`;

export const InputContainer = styled.div`
    position: relative;
`;

export const StyledInput = styled(FormControl)`
    max-width: 100%;
    font-size: 12px;
    font-weight: 500;
    color: ${colorConstants.black};
    background: none;
    padding: 8px;
    border: none;
    opacity: ${props => props.enable ? 1 : 0};

    :focus {
        box-shadow: none;
        outline: none;
        background: none;
    }

    ::placeholder {
        font-weight: 600;
        color: ${colorConstants.softGrey};
    }

    :disabled {
        // background: ${colorConstants.primaryFaded};
    }
`;

export const InputButtons = styled.div`
    max-width: 100%;
    padding: 8px 16px;

    display: flex;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;

    z-index: 22;

    visibility: ${props => props.enable ? 'visible' : 'hidden'};
`;

export const InputTitle = styled.div`
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    color: ${props => props.soft ? colorConstants.softGrey : colorConstants.mediumGrey};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    margin-right: 16px;

    :hover {
        cursor: pointer;
    }
`;

export const InputButton = styled(PencilFill)`
    min-width: 12px !important;
    max-width: 12px !important;
    min-height: 12px !important;
    max-height: 12px !important;
    color: ${colorConstants.softGrey};

    :hover {
        cursor: pointer;
    }
`;

export const RightColumn = styled.div`
    padding: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    ${({ deactivate }) => deactivate && `
        display: none;
    `}
`;

export const DeleteButton = styled(Trash)`
    padding: 8px;
    color: ${colorConstants.mediumGrey};

    :hover {
        cursor: pointer;
        color: ${colorConstants.primaryDark};
    }
`;