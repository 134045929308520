import { hotelReducerConstants } from "../constants";
import { hotelService } from "../services";


export const hotelActions = {
    find,
    getAll,
    getById,
    getAvailability,
    checkRoomRate,
    clear,
    clearCheckRoomRate,
};


function find(hotel) {
    return dispatch => {
        dispatch(request());

        hotelService.find(hotel)
            .then(
                hotel => dispatch(success(hotel)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: hotelReducerConstants.FIND_REQUEST } }
    function success(hotel) { return { type: hotelReducerConstants.FIND_SUCCESS, hotel } }
    function failure(error) { return { type: hotelReducerConstants.FIND_FAILURE, error } }
}

function getAll(hotel) {
    return dispatch => {
        dispatch(request());

        hotelService.getAll(hotel)
            .then(
                hotels => dispatch(success(hotels)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: hotelReducerConstants.GETALL_REQUEST } }
    function success(hotels) { return { type: hotelReducerConstants.GETALL_SUCCESS, hotels } }
    function failure(error) { return { type: hotelReducerConstants.GETALL_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request({ id }));

        hotelService.getById(id)
            .then(
                hotel => dispatch(success(hotel)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(hotel) { return { type: hotelReducerConstants.GETBYID_REQUEST, hotel } }
    function success(hotel) { return { type: hotelReducerConstants.GETBYID_SUCCESS, hotel } }
    function failure(error) { return { type: hotelReducerConstants.GETBYID_FAILURE, error } }
}

function getAvailability(id, query, isGuestUser) {
    return dispatch => {
        dispatch(request({ id }));

        hotelService.getAvailability(id, query, isGuestUser)
            .then(
                hotel => dispatch(success(hotel)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(hotel) { return { type: hotelReducerConstants.GETAVAILABILITY_REQUEST, hotel } }
    function success(hotel) { return { type: hotelReducerConstants.GETAVAILABILITY_SUCCESS, hotel } }
    function failure(error) { return { type: hotelReducerConstants.GETAVAILABILITY_FAILURE, error } }
}

function checkRoomRate(id, data, isGuestUser) {
    return dispatch => {
        dispatch(request());

        hotelService.checkRoomRate(id, data, isGuestUser)
            .then(
                checkRate => dispatch(success(checkRate)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: hotelReducerConstants.CHECKRATE_REQUEST } }
    function success(checkRate) { return { type: hotelReducerConstants.CHECKRATE_SUCCESS, checkRate } }
    function failure(error) { return { type: hotelReducerConstants.CHECKRATE_FAILURE, error } }
}

function clear() {
    return { type: hotelReducerConstants.CLEAR_ALL };
}

function clearCheckRoomRate() {
    return { type: hotelReducerConstants.CLEAR_CHECKRATE };
}