import styled from 'styled-components';
import { colorConstants, deviceConstants, shadowConstants } from "../../constants";


export const Container = styled.div`
    height: 120px;
    display: flex;
    justify-content: flex-start;

    margin-bottom: 16px;

    @media ${deviceConstants.tablet} {
        height: 136px;
        padding: 8px;
        border-radius: 2px;
        box-shadow: ${shadowConstants.adminListItems};
        background: ${colorConstants.white};
    }
`;

export const Photo = styled.img`
    width: 120px;
    border-radius: 2px;
    object-fit: cover;

    :hover {
        cursor: pointer;
    }
`;

export const ContentColumn = styled.div`
    width: 100%;
    margin-left: 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Content = styled.div`
    :hover {
        cursor: pointer;
    }
`;

export const Name = styled.h3`
    font-size: 16px;
    font-weight: 700;
    color: ${colorConstants.black};
    margin: 0;

    // width: 180px; // FEATURE: cut NFT name to fit screen
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;

    :hover {
        cursor: pointer;
    }
`;