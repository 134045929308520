import { http, authHeader, setLocalData, deleteLocalData, handleResponse, handleError } from "../utils";
import { loginWithEmail, loginWithSocial, getSocialLoginResults, logoutFirebaseAuth } from "../utils/firebase";


export const authService = {
    login,
    logout,
    register,
    loginWithSocial: _loginWithSocial,
    loadSocialLoginResults,
    registerGuestUser,
    loginGuestUser,
    confirmEmail,
    updatePassword,
    resetPasswordRequest,
    resetPassword,
    loadCsrfToken
};


async function login(data){
    let idToken = null;
    try {
        // login to firebase auth
        let user = await loginWithEmail(data);

        // get Firebase ID token
        idToken = await user.getIdToken();
    } catch (err) {
        return Promise.reject("Something went wrong loging you in. Please refresh your internet connection and try again.");
    }

    // request session cookie from server (saved as httpOnly in cookies) with Firebase ID Token and user cookie data
    return await http.post("/auth/login", { email: data.email, idToken })
                        .then(res => handleResponse(res, true))
                        .then(async (userCookie) => {
                            // save user cookie data in local storage
                            await setLocalData('user', userCookie);
                            // return user cookie
                            return userCookie;
                        })
                        .catch((e) => handleError(e, true));
}

async function logout() {
    try {
        // logout user from firebase auth
        await logoutFirebaseAuth();
        // logout session cookie
        await http.get("/auth/sessionLogout", { headers: authHeader() });
    } catch (err) {
        console.log(err);
    }

    // remove user data from local storage to log user out
    deleteLocalData('user');
}

async function register(data) {
    return await http.post("/auth/register", data)
                        .then((res) => handleResponse(res, true))
                        .catch((e) => handleError(e, true));
}

async function _loginWithSocial(data, signinWithPopup) {
    // Log in user with firebase social auth login; if new user, create new user on server
    if(data.email && data.idToken && data.uid) {
        return await http.post("/auth/loginWithSocial", data)
                        .then(res => handleResponse(res, true))
                        .then(async (userCookie) => {
                            // save user cookie data in local storage
                            await setLocalData('user', userCookie);
                            // return user cookie
                            return userCookie;
                        })
                        .catch((e) => handleError(e, true));
    }

    // Login user with firebase social auth provider
    try {
        // login user with social logins (creates account if does not exist) and return credentials in case of Popup login
        return await loginWithSocial(data.provider, data.params, signinWithPopup);
    } catch(err) {
        return Promise.reject("Something went wrong creating your account. Please refresh your internet connection and try again.");
    }
}

async function loadSocialLoginResults() {
    return await getSocialLoginResults();
}

async function registerGuestUser() {
    // request guest user session cookie from server (saved as httpOnly in cookies)
    return await http.get("/auth/register-guest-user")
                        .then((res) => handleResponse(res, false))
                        .then(async (userCookie) => {
                            // save user cookie data in local storage
                            await setLocalData('user', userCookie);
                            // return user cookie
                            return userCookie;
                        })
                        .catch((e) => handleError(e, true));
}

async function loginGuestUser(data) {
    // remove any previous user data from local storage
    deleteLocalData('user');
    // autorize guest user
    return await http.post("/auth/login-guest-user", data)
                        .then((res) => handleResponse(res, false))
                        .then(async (userCookie) => {
                            // save user cookie data in local storage
                            await setLocalData('user', userCookie);
                            // return user cookie
                            return userCookie;
                        })
                        .catch((e) => handleError(e, true));
}

async function confirmEmail(id, data) {
    return await http.put(`/auth/confirm-email/${id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((e) => handleError(e, false));
}

async function updatePassword(id, data) {
    const { email, password, newPassword } = data;
    let idToken = null;
    try {
        // login to firebase auth
        let user = await loginWithEmail({ email, password });

        // get Firebase ID token
        idToken = await user.getIdToken();
    } catch (err) {
        return Promise.reject("Something went wrong. Please refresh your internet connection and try again.");
    }

    // update password in Firebase and request session cookie from server (saved as httpOnly in cookies) with Firebase ID Token and user cookie data
    await http.put(`/auth/${id}`, { idToken, password: newPassword }, { headers: authHeader() })
                    .then(res => handleResponse(res, true))
                    .then(async () => {
                        // logout user from firebase auth
                        await logoutFirebaseAuth().catch((e) => console.log(e));
                        // remove user data from local storage to log user out
                        deleteLocalData('user');
                    })
                    .catch((e) => handleError(e, true));
}

async function resetPasswordRequest(data) {
    return await http.post("/auth/reset-password-request", data)
                    .then((res) => handleResponse(res, false))
                    .catch((e) => handleError(e, false));
}

async function resetPassword(data) {
    return await http.post("/auth/reset-password", data)
                    .then((res) => handleResponse(res, false))
                    .catch((e) => handleError(e, false));
}

async function loadCsrfToken() {
    return await http.get('/auth')
            .then((res) => handleResponse(res, false))
            .catch((e) => handleError(e, false));
}