import {
    Container,
    Photo,
    ContentColumn,
    Content,
    Name,
    Location
} from './FavouriteTripsListItem.styles';
import { StarSystem } from '../custom/Rating';


const FavouriteTripsListItem = (props) => {
    const { trip, handleView, ...rest } = props;
    return (
        <Container {...rest} >
            <Photo onClick={() => handleView(trip)} src={trip.tripPhotoUrls[0]} />

            <ContentColumn>
                <Content onClick={() => handleView(trip)}>
                    <Name onClick={() => handleView(trip)}>{trip.name}</Name>

                    <StarSystem onClick={() => handleView(trip)} rating={trip.rating} />

                    <Location onClick={() => handleView(trip)}>{trip.city + ', ' + trip.country}</Location>
                </Content>
            </ContentColumn>
        </Container>
    );
};

export default FavouriteTripsListItem;