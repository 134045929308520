import {
    Container,
    Photo,
    ContentColumn,
    Content,
    Name,
} from './FavouriteProductsListItem.styles';


const FavouriteProductsListItem = (props) => {
    const { data, handleView, ...rest } = props;

    return (
        <Container {...rest} >
            <Photo onClick={() => handleView(data)} src={data.imageUrls[0]} />

            <ContentColumn>
                <Content onClick={() => handleView(data)}>
                    <Name onClick={() => handleView(data)}>{data.name}</Name>
                </Content>
            </ContentColumn>
        </Container>
    );
};

export default FavouriteProductsListItem;