import { http, authHeader, handleResponse, handleError } from "../utils";

export const travelAgencyService = {
    getAll,
    getById
};


async function getAll(data) {
    return await http.get(`/travel-agencies?${data.toString()}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getById(id) {
    return await http.get(`/travel-agencies/${id}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}