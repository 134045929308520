import React, { Component } from "react";
import { connect } from 'react-redux';

import { userActions, productActions, alertActions } from '../../actions';
import { ScreenContainer } from './MyProductsContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { Notification, Warning } from '../../components/custom/Popup';
import { NoProductsFound } from '../../components/custom/EmptyState';
import { ProductsMenu } from '../../components/custom/Menu';
import { ProductsList, DraftProductsList } from "../../components/products";
import { TopBar } from '../../components/custom/TopBar';
import { SideBar } from '../../components/custom/SideBar';
import { FullScreenSpinner } from "../../components/custom/Spinner";
// import { FiltersMenu } from '../../components/custom/Filter';
import { history, defaultUser, defaultProductSectionComponentData } from '../../utils';
import { topbarConstants, contentProductPage, colorConstants, sitemap, getProductLink, getEnalbedFeatureTypes } from "../../constants";


class MyProductsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            initProducts: false,
            fromUrl: sitemap.admin.dashboard,
            typingTimeout: 0, // used to know when user stops typing
            sidebar: false,
            sidebarReset: false,
            sidebarPositionOffset: 0,
            sticky: false,
            user: defaultUser(),
            deleting: false,
            productIdToDelete: null,
            deleteWarning: false, // hide/show delete product warning
            products: null,
            showProductsMenu: false, // toggle products menu on/off
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuButton = this.handleMenuButton.bind(this);
        this.handleProductsMenuToggle = this.handleProductsMenuToggle.bind(this);
        this.handleProductsMenuClick = this.handleProductsMenuClick.bind(this);

        this.handleViewProduct = this.handleViewProduct.bind(this);
        this.handleEditProduct = this.handleEditProduct.bind(this);
        this.handleEnableProduct = this.handleEnableProduct.bind(this);
        this.handleReorderProducts = this.handleReorderProducts.bind(this);

        this.handleDeleteProductRequest = this.handleDeleteProductRequest.bind(this);
        this.handleDeleteProduct = this.handleDeleteProduct.bind(this);
        this.handleDeleteProductCancel = this.handleDeleteProductCancel.bind(this);

        this.handleFilter = this.handleFilter.bind(this);
        this.handleResetFilter = this.handleResetFilter.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        if(!this.props.user || !this.props.user.id) {
            // get user data from db
            this.props.getById(this.props.userId);
        } else if(!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access or all categories disabled
            if(!this.props.user.creator || (!this.props.user.pageOptions.showPhysicalProductLinks && !this.props.user.pageOptions.showDigitalProductLinks)) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            // load user data from server into component state
            this.setState({ ...this.state, init: true, user: this.props.user });
        }
        window.addEventListener('scroll', this.handleScroll);
        this.props.getAll(new URLSearchParams({ userId: this.props.userId })); // get all products by user id
         // set from URL
        if(this.props.location.state && this.props.location.state.from) this.setState({ ...this.state, fromUrl: this.props.location.state.from });
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.reset();
    }

    componentDidUpdate(){
        // load user data from server into component state
        if (!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access or all categories disabled
            if(!this.props.user.creator || (!this.props.user.pageOptions.showPhysicalProductLinks && !this.props.user.pageOptions.showDigitalProductLinks)) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            this.setState({ ...this.state, init: true, user: this.props.user });
        }

        // load products from db to state component and initialize list
        if(this.state.init && !this.state.initProducts && this.props.products) {
            this.setState({ ...this.state, initProducts: true, products: this.props.products });
        }
    }

    handleScroll(event) {
        // Enable sticky mode on scroll and reset sidebar
        this.setState({ ...this.state, sidebar: false, sidebarReset: true, sidebarPositionOffset: window.pageYOffset, sticky: window.pageYOffset > 1 });
    }

    handleMenuButton() {
        this.setState({ ...this.state, sidebar: !this.state.sidebar, sidebarReset: false });
    }

    handleProductsMenuToggle(toggle) {
        // reset sidebar if enabled
        if(this.state.sidebar) this.setState({ ...this.state, sidebar: false, sidebarReset: true });
        else this.setState({ ...this.state, showProductsMenu: toggle });
    }

    handleProductsMenuClick(type) {
        const handleAddProductPerType = async () => {

            // initialize component
            let component = null;
            // initialize state update
            let newState = { ...this.state };

            switch(type) {
                // create accommodation section component
                case 'PHYSICAL_SECTION': {
                    component = defaultProductSectionComponentData('PHYSICAL', this.props.user.id);
                    break;
                }

                // FEATURE: create experience section component
                case 'DIGITAL_SECTION': {
                    component = defaultProductSectionComponentData('DIGITAL', this.props.user.id);
                    break;
                }

                default: {
                    // open add product screen
                    history.push({
                        pathname: sitemap.admin.products.add,
                        state: {
                            from: sitemap.admin.products.list,
                            data: { type },
                        }
                    });
                    return;
                }
            }

            if(component) {
                // save search component to server
                await this.props.create(component);
                
                if(!this.props.products && this.props.productId) {
                    // save search component id
                    component.id = this.props.productId;
                    // add search component to products list
                    const { products } = this.state;
                    products.unshift(component);
                    newState.products = products;
                    // save updated state
                    this.setState(newState);
                }
            }
        };

        // close products menu and execute handle add product per type
        this.setState({ ...this.state, showProductsMenu: false }, () => handleAddProductPerType());
    }

    handleViewProduct(id, slug) {
        // open view product details screen
        history.push({
            pathname: getProductLink(this.props.username, slug),
            state: {
                from: sitemap.admin.products.list,
                data: {
                    userId: this.props.userId,
                    productId: id
                }
            }
        });
    }

    handleEditProduct(event, data) {
        if(typeof event === "number") {
            // Open edit product page
            history.push({
                pathname: sitemap.admin.products.edit,
                state: {
                    from: sitemap.admin.products.list,
                    data: {
                        productId: event
                    }
                }
            });

        } else if(typeof event === "object") {
            event.preventDefault();

            // get section label input name and updated value
            let { name, value } = event.target;

            if(name === 'section-label') name = 'description';

            // update section label
            this.setState({
                ...this.state,
                typingTimeout: setTimeout(() => { this.props.update(this.state.products.find(product => product.id === data)) }, 1000), // request update component data on server 1sec after user stops typing
                products: this.state.products.map(product => product.id === data ? { ...product, [name]: value } : product)
            });

            // clear timer
            if(this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
        }
    }

    handleEnableProduct(id, enabled){
        // find & update product in state
        const products = this.state.products.map(p => {
            if(p.id === id) p.enabled = enabled;
            return p;
        });
        this.setState({ ...this.state, products }, () => this.props.update({ id, userId: this.props.userId, enabled }));
    }

    handleReorderProducts(src, des) {
        if(des) {
            // create new list and filter out any draft products
            const newList = [...this.state.products.filter(item => item.isSectionLabel || (item.imageUrls && item.imageUrls.length > 0))];
            // get dragged item's id
            const draggedItemId = newList[src].id;
            // remove item from first position and insert to new position
            newList.splice(des, 0, newList.splice(src, 1)[0]);

            let newOrder = [];
            
            newList.forEach((item, index) => {
                // update list order fields for all items
                item.order = index;
                // generate updated order data map
                newOrder.push({ id: item.id, order: index });
            });
            
            // update products order on server
            this.setState({ ...this.state, products: newList }, () => this.props.update({ id: draggedItemId, newOrder }));
        }
    }

    handleDeleteProductRequest(id){
        // show delete product warning
        this.setState({ ...this.state, productIdToDelete: id, deleteWarning: true });
    }

    async handleDeleteProduct() {
        // get id of product to be deleted from state
        let id = this.state.productIdToDelete;

        // start spinner and hide warning
        this.setState({ ...this.state, deleting: true, productIdToDelete: null, deleteWarning: false }, async () => {
            // find & remove product from state
            const products = this.state.products.filter(p => p.id !== id);

            // Delete product from db & photos from firebase
            await this.props.delete(id);

            // stop spinner
            this.setState({ ...this.state, products, deleting: false });
        });
    }

    handleDeleteProductCancel(){
        // hide delete product warning
        this.setState({ ...this.state, productIdToDelete: null, deleteWarning: false });
    }

    handleFilter(selection) {
        switch (selection) {

            case 5: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'PHYSICAL' }));
                return;
            }

            case 6: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'DIGITAL' }));
                return;
            }

            default: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId }));
                return;
            }
        }
    }

    handleResetFilter(){
        this.props.getAll(new URLSearchParams({ userId: this.props.userId }));
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    render() {
        return (
            <ScreenContainer id='screen'>
                <MetaTags title={topbarConstants.titles.myProducts} />

                <TopBar
                    title={topbarConstants.titles.myProducts}
                    sticky={this.state.sticky}
                    menuButton={this.handleMenuButton}
                    addButton={() => this.handleProductsMenuToggle(true)} />

                {this.state.init &&
                <SideBar
                    options={{ isAuthenticated: true, creator: this.state.user.creator, basic: this.state.user.basic, premium: this.state.user.premium, platinum: this.state.user.platinum, links: this.state.user.pageData.metadata.links }}
                    toggle={this.handleMenuButton}
                    from={sitemap.admin.products.list}
                    enable={this.state.sidebar}
                    reset={this.state.sidebarReset}
                    position={this.state.sidebarPositionOffset} /> }

                {/* {this.state.init &&
                <FiltersMenu
                    handleReset={this.handleResetFilter}
                    handleFilter={this.handleFilter}
                    options={{
                        showPhysicalProductLinks: this.state.user.pageData.metadata.links.physicalProduct.enabled || this.state.user.pageData.metadata.links.physicalProduct.total > 0,
                        showDigitalProductLinks: this.state.user.pageData.metadata.links.digitalProduct.enabled || this.state.user.pageData.metadata.links.digitalProduct.total > 0,
                        force: true
                    }}
                    noActive={true} /> } */}

                <DraftProductsList
                    products={this.state.products}
                    handleEditProduct={this.handleEditProduct}
                    handleDeleteProduct={this.handleDeleteProductRequest} />

                <ProductsList
                    products={this.state.products}
                    handleReorder={this.handleReorderProducts}
                    handleViewProduct={this.handleViewProduct}
                    handleEditProduct={this.handleEditProduct}
                    handleDeleteProduct={this.handleDeleteProductRequest}
                    handleEnableProduct={this.handleEnableProduct} />

                { !this.state.deleting && !this.props.loading && (!this.state.products || this.state.products.length === 0) &&
                <NoProductsFound
                    onClick={() => this.handleProductsMenuToggle(true)} /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                <ProductsMenu
                    show={this.state.showProductsMenu}
                    onHide={() => this.handleProductsMenuToggle(false)}
                    enabledTypes={(this.props.user && this.props.user.id) ? getEnalbedFeatureTypes(this.props.user.pageData.metadata.links).product.types : null}
                    onClick={this.handleProductsMenuClick} />

                {(this.state.deleting || this.props.loading) && <FullScreenSpinner />}

                <Warning
                    show={this.state.deleteWarning}
                    onHide={this.handleDeleteProductCancel}
                    firstButtonClick={this.handleDeleteProduct}
                    secondButtonClick={this.handleDeleteProductCancel}
                    firstButton={contentProductPage.deleteProductWarning.firstButton}
                    firstButtonColor={colorConstants.darkGrey}
                    firstButtonBackgroundColor={colorConstants.softGrey}
                    secondButton={contentProductPage.deleteProductWarning.secondButton}
                    title={contentProductPage.deleteProductWarning.title}
                    body={contentProductPage.deleteProductWarning.body} />
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user } = state;
    const { username } = state.auth.cookie;
    const { loading, products } = state.product;
    const productId = state.product.id;

    // export state data to props
    return { userId, alert, user, username, loading, products, productId };
}

const actionCreators = {
    getById: userActions.getById,
    getAll: productActions.getAll,
    create: productActions.create,
    update: productActions.update,
    delete: productActions.delete,
    reset: productActions.clear,
    clearAlert: alertActions.clear
}

const connectedMyProductsContainer = connect(mapState, actionCreators)(MyProductsContainer);
export { connectedMyProductsContainer as MyProductsContainer };