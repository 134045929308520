import { http, authHeader, handleResponse, handleError } from "../utils";

export const googlePlacesService = {
    getSuggestions,
    getNearby,
    getDetails,
    getDetailsPreview,
    createInvite
};

async function getSuggestions(data){
    // add search input to API url
    let url = `${data.isPublic ? '/public' : ''}/google-places-api/suggestions?input=${data.input}`;
    // add type query to url if any
    if(data.type) url = `${url}&type=${data.type}`;
    // call API
    return await http.get(url, { headers: data.isPublic ? null : authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getNearby(data){
    return await http.get(`/google-places-api/nearby?lat=${data.lat}&lng=${data.lng}&radius=${data.radius}&type=${data.type}&keyword=${data.keyword}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getDetails(id){
    return await http.get(`/google-places-api/details/${id}`, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getDetailsPreview(id, data){
    return await http.post(`/google-places-api/preview/${id}`, data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function createInvite(data) {
    return await http.post("/google-places-api/invite", data, { headers: authHeader() })
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}