import { 
    MainContainer,
    StyledForm,
    StyledLogo,
    Header,
    Subheader,
    StyledFormGroup,
    AlertWarning,
    AlertSuccess,
    StyledFormControl,
    SubmitButton,
    SpinnerContainer,
    GDPR
 } from './TravelWaitlistv0.styles';
import Spinner from 'react-bootstrap/Spinner'

import { contentTravelWaitlist } from "../../../constants";


export const TravelWaitlistv0 = (props) => {
    const { handleSubmit, submitted, email, handleChange, joining, alert } = props;

    return (
        <MainContainer>
            <StyledForm onSubmit={handleSubmit}>

                <Header>{contentTravelWaitlist.header}</Header>

                <Subheader>{contentTravelWaitlist.subheader}</Subheader>

                <StyledFormGroup className={(submitted && !email ? ' has-error' : '')}>
                    {submitted && !email && <AlertWarning>{contentTravelWaitlist.inputAlert}</AlertWarning>}
                    {alert && alert.message && alert.type === 'alert-danger' && <AlertWarning>{alert.message}</AlertWarning>}
                    {alert && alert.message && alert.type === 'alert-success' && <AlertSuccess>{alert.message}</AlertSuccess>}

                    <StyledFormControl type="email" placeholder={contentTravelWaitlist.inputPlaceholder} name="email" value={email} onChange={handleChange} />
                </StyledFormGroup>
                
                <StyledFormGroup>
                    <SubmitButton gradient={true} type="submit">{contentTravelWaitlist.inputButton}</SubmitButton>
                
                    {joining && <SpinnerContainer><Spinner animation="border" /></SpinnerContainer>}
                </StyledFormGroup>

                <GDPR>{contentTravelWaitlist.gdpr}</GDPR>

                <StyledLogo />
            </StyledForm>
        </MainContainer>
    );
};