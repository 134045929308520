import styled from "styled-components";
import { colorConstants, deviceConstants, shadowConstants } from '../../../../constants';


export const AlertContainer = styled.div`
    font-size: 15px;
    padding: 32px 64px;
    text-align: center;

    @media ${deviceConstants.tablet} {
        margin-top: 32px;
        padding: 32px;
        border-radius: 8px;
        box-shadow: ${shadowConstants.adminDesktop};
        background: ${colorConstants.white};
      }
`;

export const MessageBox = styled.div`
    font-size: 12px;
    text-align: left;
    margin-bottom: 32px;
`;

export const Counter = styled.span`
    margin-left: 4px;
    margin-right: 4px;
    color: green;
    font-weight: 700;
`;