import React from 'react';
import styled from 'styled-components';

import { colorConstants } from "../../../constants";

const Container = styled.div`
  padding-top: ${props => props.hasLabel ? '8px' : '0'};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 50px;
  background: ${colorConstants.primaryDark};
  background: ${colorConstants.gradientHorizontal};
`;

const Label = styled.div`
  display: ${props => props.enabled ? 'block' : 'none'};
  text-align: center;
  color: ${colorConstants.black};
  font-size: 14px;
  background: ${colorConstants.white};
  max-width: 32px;
  margin: auto;
  margin-top: -12px;
  z-index: 10;
`;

export const Divider = (props) => {
  const { label, ...rest } = props;
    return (
        <Container hasLabel={label} {...rest} >
            <Line />
            <Label enabled={label}>{label}</Label>
        </Container>
    );
};