import React from "react";
import TripsListItem from './TripsListItem';
import { DraggableList } from '../custom/List';


const listStyles = {
    marginTop: '32px',
    paddingBottom: '80px',
};

const listItemStyles = {
    marginBottom: '24px',
};


export const TripsList = ({ trips, handleReorder, handleViewTrip, handleEditTrip, handleDeleteTrip, handleEnableTrip }) => {

    // filter out draft trips if any
    const addedTrips = trips && trips.filter(item => item.isSearch || item.isSectionLabel || (item.tripPhotoUrls && item.tripPhotoUrls.length > 0));
    if(!trips || !addedTrips || addedTrips.length === 0) return null;

    // render draggable list with trips
    return (
        <DraggableList
            name='trip-links'
            handleReorder={handleReorder}
            items={addedTrips}
            CustomListItem={TripsListItem}
            listStyles={listStyles}
            listItemStyles={listItemStyles}
            handleView={handleViewTrip}
            handleEdit={handleEditTrip}
            handleDelete={handleDeleteTrip}
            handleEnabled={handleEnableTrip} />
    );
};