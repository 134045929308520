import { alertReducerConstants } from '../constants';

// This reducer manages the application state for alerts / notifications
export function alert(state = {}, action) {

  switch (action.type) {

    case alertReducerConstants.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message
      };

    case alertReducerConstants.ERROR:
      return {
        type: 'alert-danger',
        message: action.message
      };

    case alertReducerConstants.CLEAR:
      return {};

    default:
      return state
  }
}