import styled from 'styled-components';
import FormSelect from 'react-bootstrap/FormSelect';
import { colorConstants } from "../../../constants";


export const Container = styled.div`
  width: 100%;
  margin-top: 16px;

  padding: 16px;
  border: 1px solid ${colorConstants.softGrey};
  border-radius: 8px;
`;

export const Header = styled.div`
  margin-bottom: 16px;
  
  font-size: 22px;
  font-weight: 600;
  color: ${colorConstants.black};
  text-align: start;
`;

export const Subheader = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: ${colorConstants.darkGrey};

  ${({ gradient }) => gradient && `
    font-weight: 600;
    background: ${colorConstants.gradientForText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}
`;

export const Table = styled.div`
  width: 100%;
  margin-top: 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & > * {
    margin-bottom: 10px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TableRow = styled.div`
  ${({ hasborder }) => hasborder && `
    border-top: 1px solid ${colorConstants.verySoftGrey};
    padding-top: 8px;
  `}

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: ${({ textRight }) => textRight ? 'flex-end' : 'space-between'};

  & > *:last-child {
    margin-left: ${({ normarginleft }) => normarginleft ? '0px' : '48px'};
  }
`;

export const TableRowText = styled.div`
  font-size: 12px;
  font-weight: ${({ final }) => final ? '600' : '400'};
  color: ${({ final }) => final ? colorConstants.black : colorConstants.mediumGrey};

  ${({ number }) => number && `
    white-space: nowrap;
  `}
`;

export const TableRowColumn = styled.div`
  width: 100%;
  
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const SelectorGradientBorder = styled.div`
    width: 50%;
    display: flex;
    align-items: center;

    background: ${colorConstants.gradientHorizontal};
    
    padding: 1px;
    border-radius: 2px;

    :focus-within {
        background: ${colorConstants.primaryDark};
    }
`;

export const Selector = styled(FormSelect)`
    font-size: 11px;
    font-weight: 500;
    color: ${colorConstants.black};

    padding: 1px 4px;
    border-radius: 2px;
    border: none;

    :focus {
        box-shadow: none;
        outline: none;
    }
`;

export const FeeNoticeText = styled.div`
  font-size: 10px;
  color: ${colorConstants.mediumGrey};
`;

export const UpgradeText = styled.div`
  font-size: 16px;
  font-weight: 600;
  background: ${colorConstants.gradientForText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  :hover {
    cursor: pointer;
    background: none;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    color: ${colorConstants.primary};
  }
`;