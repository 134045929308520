import { publicTripsReducerConstants } from "../constants";
import { publicTripsService } from "../services";

export const publicTripsActions = {
    getAll,
    getBySlug,
    filter,
    reset
};

function getAll() {
    return dispatch => {
        dispatch(request());

        publicTripsService.getAll()
            .then(
                trips => dispatch(success(trips)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publicTripsReducerConstants.GETALL_REQUEST } }
    function success(trips) { return { type: publicTripsReducerConstants.GETALL_SUCCESS, trips } }
    function failure(error) { return { type: publicTripsReducerConstants.GETALL_FAILURE, error } }
}

function getBySlug(username, slug) {
    return dispatch => {
        dispatch(request({ username, slug }));

        publicTripsService.getBySlug(username, slug)
            .then(
                trip => dispatch(success(trip)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(trip) { return { type: publicTripsReducerConstants.GETBYSLUG_REQUEST, trip } }
    function success(trip) { return { type: publicTripsReducerConstants.GETBYSLUG_SUCCESS, trip } }
    function failure(error) { return { type: publicTripsReducerConstants.GETBYSLUG_FAILURE, error } }
}

function filter(data) {
    return dispatch => {
        dispatch(request());

        publicTripsService.filter(data)
            .then(
                trips => dispatch(success(trips)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: publicTripsReducerConstants.FILTER_REQUEST } }
    function success(trips) { return { type: publicTripsReducerConstants.FILTER_SUCCESS, trips } }
    function failure(error) { return { type: publicTripsReducerConstants.FILTER_FAILURE, error } }
}

function reset() {
    return { type: publicTripsReducerConstants.RESET_ALL };
}