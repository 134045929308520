import styled from 'styled-components';
import {
    TypeBold,
    TypeItalic,
    TypeUnderline,
    Code,
    TypeH1,
    TypeH2,
    TypeH3,
    BlockquoteLeft,
    ListOl,
    ListUl
} from 'react-bootstrap-icons';


import { colorConstants } from "../../../../../constants";



export const GradientBorder = styled.div`
    background: ${colorConstants.gradientHorizontal};
    padding: 1px;
    margin-top: 4px;
    border-radius: 2px;

    :focus-within {
        background: ${colorConstants.primaryDark};
    }

    .DraftEditor-root {
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: ${colorConstants.black};
        background: ${colorConstants.white};
        min-height: 100px;
    }

    .RichEditor-editor .RichEditor-blockquote {
        border-left: 5px solid ${colorConstants.verySoftGrey};
        color: ${colorConstants.mediumGrey};
        font-family: 'Hoefler Text', 'Georgia', serif;
        font-style: italic;
        margin: 16px 0;
        padding: 10px 20px;
    }
`;


export const Toolbar = styled.div`
    padding: 8px 16px 16px 16px;
    border-bottom: 2px solid ${colorConstants.softWhite};
    border-radius: 2px 2px 0 0;
    background: ${colorConstants.white};

    & > * {
        display: inline-block;
        margin-right: 16px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

export const Button = styled.div`
    cursor: pointer;
    color: ${props => props.reversed ? props.active ? 'white' : '#aaa' : props.active ? 'black' : '#ccc'};
`;

export const IconWrap = styled.div`
    font-size: 18px;
    vertical-align: text-bottom;
`;

export const Icons = {
    bold: TypeBold,
    italic: TypeItalic,
    underline: TypeUnderline,
    code: Code,
    h1: TypeH1,
    h2: TypeH2,
    h3: TypeH3,
    quote: BlockquoteLeft,
    ol: ListOl,
    ul: ListUl
};

export const MaxTextIndicator = styled.div`
    padding: 4px 16px;
    font-size: 10px;
    font-weight: 500;
    text-align: end;
    color: ${colorConstants.softGrey};
    background: ${colorConstants.white};
    border-top: 2px solid ${colorConstants.softWhite};
    border-radius: 0 0 2px 2px;
`;