import styled from 'styled-components';
import { deviceConstants } from '../../../constants';



export const Container = styled.div`
  padding: 48px 24px 48px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${deviceConstants.tablet} { 
    padding-left: 33%;
    padding-right: 33%;
  }

  @media ${deviceConstants.laptop} { 
      padding-bottom: 80px;
      padding-left: 35%;
      padding-right: 35%;
  }

  @media ${deviceConstants.desktop} { 
      padding-bottom: 96px;
      padding-left: 37.5%;
      padding-right: 37.5%;
  }
`;