import styled from 'styled-components';
import FormCheck from 'react-bootstrap/FormCheck';

import { colorConstants, deviceConstants, shadowConstants } from "../../../constants";



export const Container = styled.div`
    width: 100%;
    padding-bottom: 170px;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 12px;

    @media ${deviceConstants.tablet} {
        margin-top: 32px;
        padding: 32px;
        border-radius: 8px;
        box-shadow: ${shadowConstants.adminDesktop};
        background: ${colorConstants.white};
    }
`;

export const TopSection = styled.div`
    margin-bottom: 64px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const Header = styled.div`

`;

export const Name = styled.div`
    font-weight: 800;
    font-size: 20px;
    color: ${colorConstants.black};
`;

export const EnableToggle = styled(FormCheck)`

    input:hover {
        cursor: pointer;
    }
    
    input:checked {
        background: ${colorConstants.primaryDark};
        border-color: ${colorConstants.primaryDark};
        background-repeat: no-repeat;
    }

    input:focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }
`;

export const Group = styled.div`
    width: 100%;
    margin-bottom: 32px;

    ${({ row }) => row && `
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    `}
`;

export const GroupInputTitle = styled.div`
    font-weight: 600;
    color: ${colorConstants.softGrey};
    margin-bottom: 1px;
`;

export const GroupInputText = styled.div`
    font-weight: 500;
    font-size: 12px;
`;

export const WarningText = styled.div`
    color: ${colorConstants.warning};
`;

export const UploadPhotosContainer = styled.div`
    margin-top: 8px;
    
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    & > * {
        margin-bottom: 24px;
    }
`;

export const FileUploader = styled.input`
  display: none;
`;