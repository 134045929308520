import React, { Component } from "react";
import { connect } from 'react-redux';

import { cryptoActions, nftActions } from '../../../actions';
import { ScreenContainer, EmptyScreenArea } from './AddNFTContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { SelectNFT, EditNFT }  from "../../../components/nfts";
import { NoCryptoWalletsFound } from '../../../components/custom/EmptyState';
import { TopBar } from '../../../components/custom/TopBar';
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { history, defaultNFT, finalizeNFTData } from '../../../utils';
import { topbarConstants, sitemap } from "../../../constants";


class AddNFTContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            init: false,
            fromUrl: sitemap.admin.nft.list,
            sticky: false,
            submitted: false,
            adding: false,
            selectedWallet: null,
            forceSyncWallet: false,
            assets: [],
            selectedNFT: null,
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);

        this.handleChangeWallet = this.handleChangeWallet.bind(this);
        this.handleSyncTokens = this.handleSyncTokens.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

        this.handleChangeCaption = this.handleChangeCaption.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleConnectCryptoWallet = this.handleConnectCryptoWallet.bind(this);
    }

    componentDidMount() {
        if(this.props.location.state && this.props.location.state.from) {
            this.setState({ fromUrl: this.props.location.state.from });
        } else {
            history.replace({ pathname: this.state.fromUrl, state: { error: 'Invalid container startup props' } });
        }

        if(!this.props.user || !this.props.user.id) {
            history.replace({ pathname: this.state.fromUrl, state: { error: 'Invalid container startup props' } });
        }

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
        this.props.resetAssets();
    }

    componentDidUpdate() {
        // initialize state
        if(!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access
            if(!this.props.user.creator) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            this.setState({ ...this.state, init: true });

            // get connected crypto wallets
            this.props.getWallets(new URLSearchParams({ userId: this.props.user.id }));
        }

        if(this.state.init && this.props.initWallets && this.props.wallets && this.props.wallets.length > 0 && !this.state.selectedWallet) {
            // get wallet assets
            const query = this.state.forceSyncWallet ? new URLSearchParams({ sync: true }) : null; // set force sync query
            this.props.getWalletAssets(this.props.wallets[0].id, query);
            // save initial wallet address and reset force sync wallet flag
            this.setState({ ...this.state, selectedWallet: this.props.wallets[0], forceSyncWallet: false });
        }

        if(this.state.init && this.props.initWallets && this.props.initAssets && this.props.assets && this.props.assets.length > 0 && !this.state.assets.length) {
            // load selected wallet assets
            this.setState({ ...this.state, assets: this.props.assets });
        }
    }

    handleScroll(event) {
        // Enable sticky mode on scroll
        this.setState({ ...this.state, sticky: window.pageYOffset > 1 });
    }

    handleTopBarBackButton() {
        if(this.state.selectedNFT && !this.state.submitted) this.setState({ ...this.state, selectedNFT: null }); // go back to assets list
        else history.push({ pathname: this.state.fromUrl }); // Go back
    }

    handleChangeWallet(wallet) {
        // change selected wallet address
        this.setState({ ...this.state, selectedWallet: wallet });
        // reset loaded assets for previous wallet
        this.props.resetAssets();
        this.setState({ ...this.state, assets: [] });
        // get selected wallet's assets
        this.props.getWalletAssets(wallet.id);
    }

    handleSyncTokens(){
        // reset loaded wallets
        this.props.resetWallets();
        // reset loaded assets for previous wallet
        this.props.resetAssets();
        // reset state init, reset selected wallet and set force wallet to true
        this.setState({ ...this.state, init: false, selectedWallet: null, forceSyncWallet: true });
    }

    handleLoadMore(){
        // FEATURE: create load more NFTs from wallet functionality
    }

    handleSelect(nft) {
        if(!this.state.init) return;

        // save selected NFT to state and show add NFT screen
        const selectedNFT = {
            ...defaultNFT(this.props.user.id),
            ...nft,
        };
        this.setState({ ...this.state, selectedNFT });
    }

    handleChangeCaption(event){
        const { name, value } = event.target;
        this.setState({ ...this.state, selectedNFT: { ...this.state.selectedNFT, [name]: value } });
    }

    handleSubmit() {
        this.setState({
            submitted: true,
            adding: true // enable spinner
        }, async () => {

            // save NFT to server
            await this.props.save(finalizeNFTData(this.state.selectedNFT));

            // Go back to previous screen
            history.push({
                pathname: this.state.fromUrl,
                state: {
                    from: sitemap.admin.nft.add
                }
            });
        });
    }

    handleConnectCryptoWallet(){
        // Go to settings screen
        history.push({
            pathname: sitemap.admin.settings,
            state: {
                from: sitemap.admin.nft.list,
                data: {
                    scrollToSection: 'crypto-wallet'
                }
            }
        });
    }


    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.addNFT} />

                {this.state.init &&
                <TopBar
                    sticky={this.state.selectedNFT && this.state.sticky}
                    title={topbarConstants.titles.addNFT}
                    backButton={this.handleTopBarBackButton}
                    syncButton={this.state.selectedNFT ? null : this.handleSyncTokens}
                    submitButton={this.state.selectedNFT ? this.handleSubmit : null} /> }

                { this.state.init && this.props.initWallets && this.props.wallets && this.props.wallets.length > 0 && !this.state.selectedNFT &&
                <SelectNFT
                    sticky={this.state.sticky}
                    wallets={this.props.wallets}
                    selectedWallet={this.state.selectedWallet}
                    assets={this.state.assets}
                    loadingWallets={this.props.loadingWallets}
                    loadingAssets={this.props.loadingAssets}
                    initAssets={this.props.initAssets}
                    handleChangeWallet={this.handleChangeWallet}
                    handleSelect={this.handleSelect}
                    handleLoadMore={this.handleLoadMore}
                    alert={this.props.alert} /> }

                { this.state.selectedNFT &&
                <EditNFT
                    nft={this.state.selectedNFT}
                    handleChange={this.handleChangeCaption}
                    addNFTMode={true}
                    alert={this.props.alert} /> }

                { this.state.init && this.props.initWallets && (!this.props.wallets || this.props.wallets.length === 0) &&
                <EmptyScreenArea>
                    <NoCryptoWalletsFound onClick={this.handleConnectCryptoWallet} />
                </EmptyScreenArea> }

                {this.state.adding && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { alert, user, crypto } = state;
    const { initWallets, loadingWallets, wallets, initAssets, loadingAssets, assets } = crypto;

    // export state data to props
    return { alert, user, initWallets, loadingWallets, wallets, initAssets, loadingAssets, assets };
}

const actionCreators = {
    save: nftActions.create,
    getWallets: cryptoActions.getAllWallets,
    getWalletAssets: cryptoActions.getWalletAssets,
    resetAssets: cryptoActions.clearWalletAssets,
    resetWallets: cryptoActions.clearWallets,
}

const connectedAddNFTContainer = connect(mapState, actionCreators)(AddNFTContainer);
export { connectedAddNFTContainer as AddNFTContainer };