import styled from 'styled-components';
import { Image } from '../custom/Images'
import { colorConstants, shadowConstants } from "../../constants";


export const Container = styled.div`
    border-radius: 8px;
    box-shadow: ${({ shadow }) => shadow && shadowConstants.smallButton};

    position: relative;

    :hover {
        cursor: pointer;
    }
`;

export const Photo = styled(Image)`
    width: 100%;
    height: 190px;
    border-radius: 8px;
    object-fit: cover;
`;

export const Fader = styled.div`
    background: black;
    border-radius: 8px;
    opacity: 0.3;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const TopSection = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 12px;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > * {
        margin-right: 16px !important;
    }

    & > *:last-child {
        margin-right: 0px !important;
    }
`;

export const Name = styled.h3`
    font-size: 16px;
    font-weight: 700;
    color: ${colorConstants.white};
    margin: 0;

    /** break text to multiple lines to contain it within div */
    // word-break: break-all;
    /** limit text to 1 line */
    // line-height: 1.5em;
    // height: 1.5em;
    /** overflow text and show ellipsis */
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
`;

export const Type = styled.div`
    font-size: 11px;
    font-weight: 700;
    color: ${colorConstants.white};
    margin: 0;
`;

export const BottomSection = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 8px 12px;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const Location = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${colorConstants.white};

    /** break text to multiple lines to contain it within div */
    word-break: break-all;
    /** limit text to 2 lines */
    line-height: 1em;
    max-height: 2em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const PriceRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const PriceIndicator = styled.div`
    font-size: 15px;
    font-weight: 700;
    color: ${colorConstants.white};

    line-height: 1em;
`;

export const SavingsInfo = styled.div`
    padding: 4px 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    background: ${colorConstants.primary};

    font-size: 12px;
    font-weight: 700;
    color: ${colorConstants.white};

    line-height: 1em;
`;