import styled from 'styled-components';
import { Logo } from "../custom/Branding";
import { colorConstants, deviceConstants, shadowConstants } from '../../constants';


export const Container = styled.div`
    min-height: 92vh;
    padding-top: ${props => props.cover ? '130px' : '0'};
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${deviceConstants.tablet} {
        min-height: 100vh;

        padding-left: 32px;
        padding-right: 32px;
        padding-top: ${props => props.cover ? '130px' : '32px'};
        border-radius: 8px;
        box-shadow: ${props => props.cover ? shadowConstants.adminDesktop : 'none'};
      }
`;

export const Body = styled.div`
    padding-bottom: 80px;
`;

export const Footer = styled.div`
    @media ${deviceConstants.tablet} {
        padding-bottom: 32px;
    }
`;

export const TopSection = styled.div`
    padding-top: ${props => !props.cover ? '32px' : '0px'};
    padding-bottom: ${props => props.horizontal && !props.bottomSocial ? '24px' : !props.horizontal && !props.bottomSocial ? '16px' : props.horizontal && props.bottomSocial ? '16px' : '4px'};

    display: flex;
    flex-direction: ${props => props.horizontal ? 'none' : 'column'};
    justify-content: ${props => props.horizontal ? 'space-between' : 'center'};
    align-items: ${props => props.horizontal ? 'none' : 'center'};
    // align-items: center; /* not sure if is better to always align center */
`;

export const CoverSection = styled.div`
    height: 240px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    z-index: -1;

    @media ${deviceConstants.tablet} { 
        margin-left: 33%;
        margin-right: 33%;
        border-radius: 8px;
    }

    @media ${deviceConstants.laptop} { 
        margin-left: 35%;
        margin-right: 35%;
    }

    @media ${deviceConstants.desktop} { 
        margin-left: 37.5%;
        margin-right: 37.5%;
    }
`;

export const CoverImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;

    @media ${deviceConstants.tablet} { 
        border-radius: 8px;
    }
`;

export const CoverImageFader = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    
    background: linear-gradient(0deg, ${props => props.color || colorConstants.white} 0%, ${props => props.color || colorConstants.white} 12%, ${props => props.dark ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)'} 55%);
`;

export const UserInfoSection = styled.div`
    margin-top: ${props => props.horizontal ? '0' : '16px'};
    margin-left: ${props => props.horizontal && props.horizontalLeft ? '16px' : '0'};
    margin-right: ${props => props.horizontal && !props.horizontalLeft ? '16px' : '0'};
`;

export const Name = styled.div`
    font-family: ${props => props.fontStyles.type || 'inherit'} !important;
    font-size: 18px;
    font-weight: 800;
    line-height: normal;
    color: ${props => props.fontStyles.color || colorConstants.black} !important;

    text-align: ${props => props.horizontal ? 'none' : 'center'};

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: none;

    @media ${deviceConstants.tablet} { 
        font-size: 32px;
    }
`;

export const Bio = styled.div`
    font-family: ${props => props.fontStyles.type || 'inherit'} !important;
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.fontStyles.color || colorConstants.black} !important;

    text-align: ${props => props.horizontal ? 'none' : 'center'};

    padding-top: 8px;
    padding-bottom: 8px;

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
`;

export const StyledLogo = styled(Logo)`
    min-width: 80px;
`;