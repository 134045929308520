import styled from 'styled-components';
import { colorConstants } from "../../../constants";
import { PersonCheck, Link45deg, Plus, Pencil } from 'react-bootstrap-icons';



export const Container = styled.div`
    height: 48px;
    min-width: 240px;
    padding: 16px;

    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
        margin-right: 16px;
    }

    & > *:last-child {
        margin-right: 0;
    }

    :hover {
        cursor: pointer;
        background: ${colorConstants.verySoftWhite};
    }
`;

export const ProfileIcon = styled(PersonCheck)`
    font-size: 24px;
    color: ${colorConstants.primaryDark};
`;

export const LinksIcon = styled(Link45deg)`
    font-size: 24px;
    color: ${colorConstants.primaryDark};
`;

export const PlusIcon = styled(Plus)`
    font-size: 24px;
    color: ${colorConstants.darkGrey};
`;

export const EditIcon = styled(Pencil)`
    font-size: 18px;
    color: ${colorConstants.darkGrey};
`;

export const TextSection = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const Title = styled.div`
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    color: ${colorConstants.black};
`;

export const Text = styled.div`
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    color: ${colorConstants.black};
`;

export const SubText = styled.div`
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    color: ${colorConstants.primaryDark};
`;