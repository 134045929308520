import styled from 'styled-components';
import { colorConstants } from "../../../constants";


export const Container = styled.div`
    margin-bottom: 16px;
    border-radius: 4px;

    position: relative;
`;

export const Photo = styled.img`
    width: 100%;
    height: 150px;
    border-radius: 4px;
    object-fit: cover;
`;

export const Fader = styled.div`
    background: ${colorConstants.black};
    border-radius: 4px;
    opacity: 0.25;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const TopSection = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px 8px;
`;

export const Name = styled.h3`
    font-size: 16px;
    font-weight: 700;
    color: ${colorConstants.white};
    margin: 0;

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: none;
`;

export const BottomSection = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 4px 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Location = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${colorConstants.white};
`;