import styled from 'styled-components';
import FormCheck from 'react-bootstrap/FormCheck';
import FormText from 'react-bootstrap/FormText';

import { colorConstants, deviceConstants, shadowConstants } from "../../../../constants";


export const Container = styled.div`
    width: 100%;
    padding-bottom: 170px;
    padding-left: 32px;
    padding-right: 32px;

    & > * {
        margin-bottom: 16px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    @media ${deviceConstants.tablet} {
        margin-top: 32px;
        padding: 32px;
        border-radius: 8px;
        box-shadow: ${shadowConstants.adminDesktop};
        background: ${colorConstants.white};
    }
`;

export const InputSection = styled.div`

`;

export const SectionTitle = styled.div`
    font-weight: 700;
    font-size: 14px;
    color: ${colorConstants.black};
`;

export const EnableToggleContainer = styled.div`
    margin-bottom: 64px;
    
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const EnableToggle = styled(FormCheck)`

    input:hover {
        cursor: pointer;
    }
    
    input:checked {
        background: ${colorConstants.primaryDark};
        border-color: ${colorConstants.primaryDark};
        background-repeat: no-repeat;
    }

    input:focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
    }
`;

export const StyledFormText = styled(FormText)`
    color: ${colorConstants.warning};
`;

export const MaxTextIndicator = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: ${colorConstants.softGrey};
  text-align: end;
  margin-top: 1px;
`;

export const Notification = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${colorConstants.primaryDark};

  :hover {
    color: ${colorConstants.primary};
    cursor: pointer;
  }
`;

export const FileUploader = styled.input`
  display: none;
`;

export const ProjectLanguage = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;

    font-size: 12px;
    font-weight: 500;
    color: ${colorConstants.black};
`;