import React, { Component } from "react";
import { connect } from 'react-redux';

import { productActions, publicProductsActions } from '../../../actions';
import { ScreenContainer } from './ViewProductContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { ProductPage } from '../../../components/products';
import { Warning, Share } from '../../../components/custom/Popup';
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { history, defaultProduct } from '../../../utils';
import { contentProductPage, colorConstants, sitemap, urlConstants, getProductLink, getProfileLink } from '../../../constants';


class ViewProductContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            fromUrl: sitemap.admin.dashboard,
            init: false, // initialize product data from server db
            authenticated: false, // used for hide/show topbar for logged in users
            isCreator: false, // true if current user is the creator of the product
            sticky: false, // make topbar sticky
            deleteWarning: false, // hide/show delete product warning
            deleting: false, // deleting product flag
            sharing: false, // hide/show share popup
            product: {
                ...defaultProduct('PHYSICAL'),
                creator: {},
                partner: {}
            },
            privateInit: false, // initialize private product data from server db
            privateProductData: { favourite: false }
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);

        this.handleDeleteProductRequest = this.handleDeleteProductRequest.bind(this);
        this.handleDeleteProduct = this.handleDeleteProduct.bind(this);
        this.handleDeleteProductCancel = this.handleDeleteProductCancel.bind(this);

        this.handleEditProduct = this.handleEditProduct.bind(this);
        this.handleShareProduct = this.handleShareProduct.bind(this);
        this.handleFavorProduct = this.handleFavorProduct.bind(this);
        this.handleUnFavorProduct = this.handleUnFavorProduct.bind(this);

        this.handleViewCreatorProfile = this.handleViewCreatorProfile.bind(this);
    }

    componentDidMount() {
        if(this.props.location.state) {
            let initialState = {};
            if(this.props.location.state.from) initialState = { ...this.state, ...initialState, fromUrl: this.props.location.state.from }; // set from URL
            // get data from previous page
            if(this.props.location.state.data) {
                const { profileTransitionAnimation } = this.props.location.state.data;
                // enable transition animations
                if(profileTransitionAnimation) {
                    
                }
            }
            // set initial page state
            this.setState(initialState);
        }

        // listen to scroll events
        window.addEventListener('scroll', this.handleScroll);

        // get public product data
        this.props.getBySlug(this.props.match.params.username, this.props.match.params.slug);

        // execute private startup methods for authenticated users
        if(this.props.cookie && this.props.cookie.id) {
            // set authenticated mode
            this.setState({ authenticated: true });

            // get authenticate product data for creator
            let query = {
                username: this.props.match.params.username,
                visitorUserId: this.props.cookie.id,
            };
            this.props.getAuthenticatedProductData(this.props.match.params.slug, new URLSearchParams(query));
        }
    }

    componentDidUpdate() {
        // save product data
        if(!this.state.init && !this.props.loading && this.props.product && this.props.product.id) {
            this.setState({
                init: true,
                isCreator: this.props.cookie && this.props.cookie.id === this.props.product.creator.id,
                product: this.props.product
            });
        }

        // save private product data (for authenticated users)
        if(this.state.authenticated && this.state.init && !this.state.privateInit && this.props.privateProductData && this.props.privateProductData.id) {
            this.setState({ ...this.state, privateInit: true, privateProductData: this.props.privateProductData });
        }
    }

    componentWillUnmount() {
        // remove scroll listener
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        // enable sticky mode on scroll event
        this.setState({ ...this.state, sticky: window.pageYOffset > 1 });
    }

    handleTopBarBackButton() {
        // reset product data from state
        this.props.reset();
        // reset private product data
        this.props.resetPrivateProductData();
        // go back to previous screen
        history.push({
            pathname: this.state.fromUrl,
            state: {
                from: getProductLink(this.state.product.creator.username, this.props.match.params.slug),
                data: {
                    productId: this.state.product.id
                }
            }
        });
    }

    handleDeleteProductRequest(){
        // additional auth check
        if(!this.props.cookie || !this.state.authenticated) return;

        // show delete product warning
        this.setState({ ...this.state, deleteWarning: true });
    }

    async handleDeleteProduct(){

        // additional auth check
        if(!this.props.cookie || !this.state.authenticated) return;

        // start spinner and hide warning
        this.setState({ ...this.state, deleting: true, deleteWarning: false });

        // Delete product from db & photos from firebase
        await this.props.delete(this.state.product.id);

        // reset product data from state
        this.props.reset();
        // reset private product data
        this.props.resetPrivateProductData();

        // stop spinner
        this.setState({ ...this.state, deleting: false });

        // go back to previous screen
        history.push({ pathname: this.state.fromUrl });
    }

    handleDeleteProductCancel(){
        // hide delete product warning
        this.setState({ ...this.state, deleteWarning: false });
    }

    handleEditProduct(){
        // Open edit product page
        history.push({
            pathname: sitemap.admin.products.edit,
            state: {
                from: getProductLink(this.state.product.creator.username, this.props.match.params.slug),
                data: {
                    productId: this.state.product.id
                }
            }
        });
    }

    handleShareProduct(){
        // hide/show sharing popup
        this.setState({ ...this.state, sharing: !this.state.sharing });
    }

    handleFavorProduct(){
        if(!this.state.authenticated) {
            // redirect to login
            history.push({ pathname: sitemap.auth.login });
            return;
        }

        // add product to favourites
        if(!this.state.privateProductData.favourite) {
            // update state
            this.setState({ ...this.state, privateProductData: { ...this.state.privateProductData, favourite: true } });
            // update in db
            this.props.favourite({ id: this.state.product.id, visitorUserId: this.props.cookie.id, status: true });
        }
    }

    handleUnFavorProduct(){
        if(!this.state.authenticated) {
            // redirect to login
            history.push({ pathname: sitemap.auth.login });
            return;
        }

        if(this.state.privateProductData.favourite) {
            // update state
            this.setState({ ...this.state, privateProductData: { ...this.state.privateProductData, favourite: false } });
            // update in db
            this.props.favourite({ id: this.state.product.id, visitorUserId: this.props.cookie.id, status: false });
        }
    }

    handleViewCreatorProfile(username) {
        // reset product data from state
        this.props.reset();
        // reset private product data
        this.props.resetPrivateProductData();

        // open creator profile
        history.push({
            pathname: getProfileLink(username),
            state: {
                from: getProductLink(username, this.state.product.customUrl),
                data: {
                    productId: this.state.product.id
                }
            }
        });
    }

    render() {
        return (
            <ScreenContainer id='screen' >
                { this.state.init && <MetaTags title={this.state.product.name} /> }
                
                {this.state.init &&
                <ProductPage
                    authenticated={this.state.authenticated}
                    isCreator={this.state.isCreator}
                    product={this.state.product}
                    isFav={this.state.privateProductData.favourite}
                    handleBackButton={this.handleTopBarBackButton}
                    handleDelete={this.handleDeleteProductRequest}
                    handleEdit={this.handleEditProduct}
                    handleShare={this.handleShareProduct}
                    handleFavor={this.handleFavorProduct}
                    handleUnFavor={this.handleUnFavorProduct}
                    handleViewCreatorProfile={this.handleViewCreatorProfile} /> }

                {(this.state.deleting || this.props.loading) && <FullScreenSpinner />}

                <Warning
                    show={this.state.deleteWarning}
                    onHide={this.handleDeleteProductCancel}
                    firstButtonClick={this.handleDeleteProduct}
                    secondButtonClick={this.handleDeleteProductCancel}
                    firstButton={contentProductPage.deleteProductWarning.firstButton}
                    firstButtonColor={colorConstants.darkGrey}
                    firstButtonBackgroundColor={colorConstants.softGrey}
                    secondButton={contentProductPage.deleteProductWarning.secondButton}
                    title={contentProductPage.deleteProductWarning.title}
                    body={contentProductPage.deleteProductWarning.body} />

                { this.state.init && this.state.product && this.state.product.creator &&
                <Share
                    show={this.state.sharing}
                    onHide={this.handleShareProduct}
                    title='Share'
                    url={urlConstants.baseUrlClient + getProductLink(this.state.product.creator.username, this.state.product.customUrl)}
                    imageUrl={this.state.product.imageUrls[0]} /> }
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { auth, publicProducts } = state;
    const cookie = auth.cookie;
    const { loading } = publicProducts;
    const product = publicProducts.products[0];
    // get private product data
    const privateProductData = state.product;

    // export state data to props
    return { cookie, loading, product, privateProductData };
}

const actionCreators = {
    getBySlug: publicProductsActions.getBySlug,
    reset: publicProductsActions.reset,
    getAuthenticatedProductData: productActions.getBySlug,
    update: productActions.update,
    favourite: productActions.favourite,
    delete: productActions.delete,
    resetPrivateProductData: productActions.clear
}

const connectedViewProductContainer = connect(mapState, actionCreators)(ViewProductContainer);
export { connectedViewProductContainer as ViewProductContainer };