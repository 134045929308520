import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CardChecklist } from 'react-bootstrap-icons';

import { colorConstants, deviceConstants } from '../../../constants';


export const Container = styled.div`
  padding: 48px 24px 120px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${deviceConstants.tablet} {
    padding-bottom: 150px;
  }
`;

export const PageTitle = styled.div`
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 700;
  color: ${colorConstants.black};
`;

export const BillingPlansSection = styled.div`
  width: 100%;
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    margin-bottom: 24px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  @media ${deviceConstants.tablet} { 
    width: 80%;
    flex-direction: row;
    align-items: flex-start;

    & > * {
      margin-bottom: 0;
      margin-right: 24px;
    }
  
    & > *:last-child {
      margin-right: 0;
    }
  }
`;

export const ComparePlansSection = styled.div`
  margin-top: 50px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CompareFeaturesIcon = styled(CardChecklist)`
  font-size: 18px;
  color: ${colorConstants.black};
`;

export const ComparePlansLink = styled(Link)`
  margin-left: 16px;

  font-weight: 500;
  font-size: 14px;
  color: ${colorConstants.black};

  :hover {
      color: ${colorConstants.primaryDark};
  }
`;