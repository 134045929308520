import React, { Component } from "react";
import { connect } from 'react-redux';
import Joyride from 'react-joyride';

import { userActions, tripActions, alertActions } from '../../actions';
import { ScreenContainer } from './MyTripsContainer.styles';
import { MetaTags } from '../../components/custom/Helmet';
import { Notification } from '../../components/custom/Popup';
import { NoTripsFound } from '../../components/custom/EmptyState';
import { TripsMenu } from '../../components/custom/Menu';
import { TripsList, DraftTripsList } from "../../components/trips";
import { TopBar } from '../../components/custom/TopBar';
import { SideBar } from '../../components/custom/SideBar';
import { FullScreenSpinner } from "../../components/custom/Spinner";
import { Warning } from '../../components/custom/Popup';
import { FiltersMenu } from '../../components/custom/Filter';
import { history, defaultUser, defaultTripSearchComponentData, defaultTripSectionComponentData, prepareUserData } from '../../utils';
import { topbarConstants, contentTripPage, colorConstants, sitemap, getTripLink, getEnalbedFeatureTypes } from "../../constants";


class MyTripsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            initTrips: false,
            fromUrl: sitemap.admin.dashboard,
            typingTimeout: 0, // used to know when user stops typing
            sidebar: false,
            sidebarReset: false,
            sidebarPositionOffset: 0,
            sticky: false,
            user: defaultUser(),
            deleting: false,
            tripIdToDelete: null,
            deleteWarning: false, // hide/show delete trip warning
            trips: null,
            showTripsMenu: false, // toggle trips menu on/off
            accommodationSearchActive: false, // toggle trips search component menu on/off
            experienceSearchActive: false, // toggle trips search component menu on/off
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuButton = this.handleMenuButton.bind(this);
        this.handleTripsMenuToggle = this.handleTripsMenuToggle.bind(this);
        this.handleTripsMenuClick = this.handleTripsMenuClick.bind(this);
        this.handleViewTrip = this.handleViewTrip.bind(this);
        this.handleEditTrip = this.handleEditTrip.bind(this);
        this.handleEnableTrip = this.handleEnableTrip.bind(this);
        this.handleReorderTrips = this.handleReorderTrips.bind(this);

        this.handleDeleteTripRequest = this.handleDeleteTripRequest.bind(this);
        this.handleDeleteTrip = this.handleDeleteTrip.bind(this);
        this.handleDeleteTripCancel = this.handleDeleteTripCancel.bind(this);

        this.handleFilter = this.handleFilter.bind(this);
        this.handleResetFilter = this.handleResetFilter.bind(this);

        /* App Tour */
        this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        if(!this.props.user || !this.props.user.id) {
            // get user data from db
            this.props.getById(this.props.userId);
        } else if(!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access or all categories disabled
            if(!this.props.user.creator || (!this.props.user.pageOptions.showHotelLinks && !this.props.user.pageOptions.showTravelAgencyLinks)) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            // load user data from server into component state
            this.setState({ ...this.state, init: true, user: this.props.user });
        }
        window.addEventListener('scroll', this.handleScroll);
        this.props.getAll(new URLSearchParams({ userId: this.props.userId })); // get all trips by user id
         // set from URL
        if(this.props.location.state && this.props.location.state.from) this.setState({ ...this.state, fromUrl: this.props.location.state.from });
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.reset();
    }

    componentDidUpdate(){
        // load user data from server into component state
        if (!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access or all categories disabled
            if(!this.props.user.creator || (!this.props.user.pageOptions.showHotelLinks && !this.props.user.pageOptions.showTravelAgencyLinks)) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            this.setState({ ...this.state, init: true, user: this.props.user });
        }

        // load trips from db to state component and initialize list
        if(this.state.init && !this.state.initTrips && this.props.trips) {
            let accommodationSearchActive = false;
            let experienceSearchActive = false;
            // check if search components exist
            this.props.trips.forEach(item => {
                if(item.isSearch) {
                    if(item.type === 'ACCOMMODATION') {
                        accommodationSearchActive = true;
                    } else if(item.type === 'EXPERIENCE') {
                        experienceSearchActive = true;
                    }
                }
            });
            this.setState({ ...this.state, initTrips: true, trips: this.props.trips, accommodationSearchActive, experienceSearchActive });
        }
    }

    handleScroll(event) {
        // Enable sticky mode on scroll and reset sidebar
        this.setState({ ...this.state, sidebar: false, sidebarReset: true, sidebarPositionOffset: window.pageYOffset, sticky: window.pageYOffset > 1 });
    }

    handleMenuButton() {
        this.setState({ ...this.state, sidebar: !this.state.sidebar, sidebarReset: false });
    }

    handleTripsMenuToggle(toggle) {
        // reset sidebar if enabled
        if(this.state.sidebar) this.setState({ ...this.state, sidebar: false, sidebarReset: true });
        else this.setState({ ...this.state, showTripsMenu: toggle });
    }

    handleTripsMenuClick(type) {
        const handleAddTripPerType = async () => {

            // initialize component
            let component = null;
            // initialize state update
            let newState = { ...this.state };

            switch(type) {
                // create accommodation search component
                case 'ACCOMMODATION_SEARCH': {
                    component = defaultTripSearchComponentData('ACCOMMODATION', this.props.user.id);
                    newState.accommodationSearchActive = true;
                    break;
                }

                // create accommodation section component
                case 'ACCOMMODATION_SECTION': {
                    component = defaultTripSectionComponentData('ACCOMMODATION', this.props.user.id);
                    break;
                }

                // FEATURE: create experience search component
                case 'EXPERIENCE_SEARCH': {
                    // component = defaultTripSearchComponentData('EXPERIENCE', this.props.user.id);
                    // newState.experienceSearchActive = true;
                    break;
                }

                // FEATURE: create experience section component
                case 'EXPERIENCE_SECTION': {
                    component = defaultTripSectionComponentData('EXPERIENCE', this.props.user.id);
                    break;
                }

                default: {
                    // open add trip screen
                    history.push({
                        pathname: sitemap.admin.trips.add,
                        state: {
                            from: sitemap.admin.trips.list,
                            data: { type },
                        }
                    });
                    return;
                }
            }

            if(component) {
                // save search component to server
                await this.props.create(component);
                
                if(!this.props.trips && this.props.tripId) {
                    // save search component id
                    component.id = this.props.tripId;
                    // add search component to trips list
                    const { trips } = this.state;
                    trips.unshift(component);
                    newState.trips = trips;
                    // save updated state
                    this.setState(newState);
                }
            }
        };

        // close trips menu and execute handle add trip per type
        this.setState({ ...this.state, showTripsMenu: false }, () => handleAddTripPerType());
    }

    handleViewTrip(id, slug) {
        // open view trip details screen
        history.push({
            pathname: getTripLink(this.props.username, slug),
            state: {
                from: sitemap.admin.trips.list,
                data: {
                    userId: this.props.userId,
                    tripId: id
                }
            }
        });
    }

    handleEditTrip(event, data) {
        if(typeof event === "number") {
            // Open edit trip page
            history.push({
                pathname: sitemap.admin.trips.edit,
                state: {
                    from: sitemap.admin.trips.list,
                    data: {
                        tripId: event
                    }
                }
            });

        } else if(typeof event === "object") {
            event.preventDefault();

            // get section label input name and updated value
            let { name, value } = event.target;

            if(name === 'section-label') name = 'description';

            // update section label
            this.setState({
                ...this.state,
                typingTimeout: setTimeout(() => { this.props.update(this.state.trips.find(trip => trip.id === data)) }, 1000), // request update component data on server 1sec after user stops typing
                trips: this.state.trips.map(trip => trip.id === data ? { ...trip, [name]: value } : trip)
            });

            // clear timer
            if(this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
        }
    }

    handleEnableTrip(id, enabled){
        // find & update trip in state
        const trips = this.state.trips.map(t => {
            if(t.id === id) t.enabled = enabled;
            return t;
        });
        this.setState({ ...this.state, trips }, () => this.props.update({ id, userId: this.props.userId, enabled }));
    }

    handleReorderTrips(src, des) {
        if(des !== null) {
            // create new list and filter out any draft trips
            const newList = [...this.state.trips.filter(item => item.isSearch || item.isSectionLabel || (item.tripPhotoUrls && item.tripPhotoUrls.length > 0))];
            // get dragged item's id
            const draggedItemId = newList[src].id;
            // remove item from first position and insert to new position
            newList.splice(des, 0, newList.splice(src, 1)[0]);

            let newOrder = [];
            
            newList.forEach((item, index) => {
                // update list order fields for all items
                item.order = index;
                // generate updated order data map
                newOrder.push({ id: item.id, order: index });
            });
            
            // update trips order on server
            this.setState({ ...this.state, trips: newList }, () => this.props.update({ id: draggedItemId, newOrder }));
        }
    }

    handleDeleteTripRequest(id){
        // show delete trip warning
        this.setState({ ...this.state, tripIdToDelete: id, deleteWarning: true });
    }

    async handleDeleteTrip() {
        // get id of trip to be deleted from state
        let id = this.state.tripIdToDelete;

        // start spinner and hide warning
        this.setState({ ...this.state, deleting: true, tripIdToDelete: null, deleteWarning: false }, async () => {
            let newState = { ...this.state, deleting: false };

            // find & remove trip from state
            const trips = this.state.trips.filter(t => {
                if(t.id !== id) return true;

                // enable search component add menu item when deleting a search component
                if(t.isSearch) {
                    if(t.type === 'ACCOMMODATION') newState.accommodationSearchActive = false;
                    else if(t.type === 'EXPERIENCE') newState.experienceSearchActive = false;   
                }

                return false;
            });
            newState.trips = trips;

            // Delete trip from db & photos from firebase
            await this.props.delete(id);

            // update state and stop spinner
            this.setState(newState);
        });
    }

    handleDeleteTripCancel(){
        // hide delete trip warning
        this.setState({ ...this.state, tripIdToDelete: null, deleteWarning: false });
    }

    handleFilter(selection) {
        switch (selection) {

            case 1: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'ACCOMMODATION' }));
                return;
            }

            case 2: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'EXPERIENCE' }));
                return;
            }

            default: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId }));
                return;
            }
        }
    }

    handleResetFilter(){
        this.props.getAll(new URLSearchParams({ userId: this.props.userId }));
    }

    handleJoyrideCallback(data) {
        if(!data || !this.state.init) return;
        
        const { status } = data;

        if(status === 'finished' || status === 'skipped') {
            this.setState({ ...this.state, user: { ...this.state.user, isTripsTourDone: true } }, () => this.props.updateUser(prepareUserData(this.state.user)));
        }
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    render() {
        return (
            <ScreenContainer id='screen'>
                <MetaTags title={topbarConstants.titles.myTrips} />

                { this.state.init && this.state.user.creator && !this.state.user.isTripsTourDone &&
                <Joyride
                    callback={this.handleJoyrideCallback}
                    continuous
                    hideCloseButton
                    disableCloseOnEsc
                    disableOverlayClose
                    hideBackButton
                    // scrollToFirstStep
                    showProgress
                    showSkipButton
                    steps={contentTripPage.tour}
                    run={true}
                    styles={{
                        options: {
                            primaryColor: colorConstants.primaryDark,
                        },
                    }}
                /> }

                <TopBar
                    title={topbarConstants.titles.myTrips}
                    sticky={this.state.sticky}
                    menuButton={this.handleMenuButton}
                    addButton={() => this.handleTripsMenuToggle(true)} />

                {this.state.init &&
                <SideBar
                    options={{ isAuthenticated: true, creator: this.state.user.creator, basic: this.state.user.basic, premium: this.state.user.premium, platinum: this.state.user.platinum, links: this.state.user.pageData.metadata.links }}
                    toggle={this.handleMenuButton}
                    from={sitemap.admin.trips.list}
                    enable={this.state.sidebar}
                    reset={this.state.sidebarReset}
                    position={this.state.sidebarPositionOffset} /> }

                {this.state.init &&
                <FiltersMenu
                    handleReset={this.handleResetFilter}
                    handleFilter={this.handleFilter}
                    options={{
                        showHotelLinks: this.state.user.pageData.metadata.links.accommodation.enabled || this.state.user.pageData.metadata.links.accommodation.total > 0,
                        showTravelAgencyLinks: this.state.user.pageData.metadata.links.experience.enabled || this.state.user.pageData.metadata.links.experience.total > 0,
                        force: true
                    }}
                    noActive={true} /> }

                <DraftTripsList
                    trips={this.state.trips}
                    handleEditTrip={this.handleEditTrip}
                    handleDeleteTrip={this.handleDeleteTripRequest} />

                <TripsList
                    trips={this.state.trips}
                    handleReorder={this.handleReorderTrips}
                    handleViewTrip={this.handleViewTrip}
                    handleEditTrip={this.handleEditTrip}
                    handleDeleteTrip={this.handleDeleteTripRequest}
                    handleEnableTrip={this.handleEnableTrip} />

                { !this.state.deleting && !this.props.loading && (!this.state.trips || this.state.trips.length === 0) &&
                <NoTripsFound
                    onClick={() => this.handleTripsMenuToggle(true)} /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                <TripsMenu
                    show={this.state.showTripsMenu}
                    onHide={() => this.handleTripsMenuToggle(false)}
                    enabledTypes={(this.props.user && this.props.user.id) ? getEnalbedFeatureTypes(this.props.user.pageData.metadata.links).trip.types : null}
                    accommodationSearchActive={this.state.accommodationSearchActive}
                    experienceSearchActive={this.state.experienceSearchActive}
                    onClick={this.handleTripsMenuClick} />

                {(this.state.deleting || this.props.loading) && <FullScreenSpinner />}

                <Warning
                    show={this.state.deleteWarning}
                    onHide={this.handleDeleteTripCancel}
                    firstButtonClick={this.handleDeleteTrip}
                    secondButtonClick={this.handleDeleteTripCancel}
                    firstButton={contentTripPage.deleteTripWarning.firstButton}
                    firstButtonColor={colorConstants.darkGrey}
                    firstButtonBackgroundColor={colorConstants.softGrey}
                    secondButton={contentTripPage.deleteTripWarning.secondButton}
                    title={contentTripPage.deleteTripWarning.title}
                    body={contentTripPage.deleteTripWarning.body} />
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user } = state;
    const { username } = state.auth.cookie;
    const { loading, trips } = state.trip;
    const tripId = state.trip.id;

    // export state data to props
    return { userId, alert, user, username, loading, trips, tripId };
}

const actionCreators = {
    getById: userActions.getById,
    updateUser: userActions.update,
    getAll: tripActions.getAll,
    create: tripActions.create,
    update: tripActions.update,
    delete: tripActions.delete,
    reset: tripActions.clear,
    clearAlert: alertActions.clear
}

const connectedMyTripsContainer = connect(mapState, actionCreators)(MyTripsContainer);
export { connectedMyTripsContainer as MyTripsContainer };