import { Link } from 'react-router-dom';
import styled from "styled-components";

import { Button } from '../../custom/Buttons';
import { ReactComponent as RightArrow } from '../../../assets/screens/EarlyAccess/right-arrow.svg';
import { ReactComponent as DownArrow } from '../../../assets/screens/EarlyAccess/down-arrow.svg';
import { deviceConstants, colorConstants } from "../../../constants";



export const Container = styled.div`
    padding-bottom: 350px;
    padding-left: 24px;
    padding-right: 24px;

    @media ${deviceConstants.tablet} {
        padding-bottom: 400px;
        padding-left: 0;
        padding-right: 0;
    }
`;



// Hero Section
export const HeroSection = styled.div`
    width: 100%;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.tablet} {
        padding: 80px 0;

        flex-direction: row;
        justify-content: center;
        align-items: stretch;
    }
`;

export const HeroContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.tablet} {
        max-width: 320px;
        margin-right: 120px;
        margin-top: 60px;

        display: block;
        flex-direction: row;
        align-items: stretch;
    }
`;

export const HeroHeader = styled.h1`
    font-weight: 700;
    font-size: 26px;
    text-align: center;

    @media ${deviceConstants.tablet} {
        font-size: 38px;
        text-align: start;
        white-space: pre-wrap;
    }
`;

export const HeroSubheader = styled.p`
    margin-top: 8px;

    font-weight: 500;
    font-size: 14px;
    text-align: center;
    white-space: pre-wrap;

    @media ${deviceConstants.tablet} {
        margin-top: 24px;

        font-size: 15px;
        text-align: start;
    }
`;

export const HeroButton = styled(Button)`
  margin-top: 24px;
  margin-bottom: 20px;

  width: 70%;

  padding-left: 0;
  padding-right: 0;

  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;

  @media ${deviceConstants.tablet} {
    width: 100%;

    margin-top: 48px;
    margin-bottom: 16px;
    font-size: 14px;
  }
`;

export const HeroLoginSpan = styled.span`
    font-weight: 500;
    font-size: 13px;
`;

export const HeroLoginLink = styled(Link)`
    font-weight: 500;
    font-size: 13px;
    color: ${colorConstants.darkGrey};

    :hover {
        color: ${colorConstants.primaryDark};
    }
`;

export const HeroImageWrapper = styled.div`
    margin-top: 64px;

    @media ${deviceConstants.tablet} {
        margin-top: 0;
    }
`;

export const HeroImage = styled.img`
    width: 100%;

    @media ${deviceConstants.tablet} {
        height: 500px;
        width: auto;
    }
`;



// Middle Section
export const MiddleSection = styled.div`
    width: 100%;
    padding: 40px 0 30px 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.tablet} {
        padding: 180px 0 80px 0;

        flex-direction: row;
        justify-content: center;
        align-items: stretch;
    }
`;

export const MiddleContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${deviceConstants.tablet} {
        max-width: 320px;
        margin-right: 160px;
        margin-top: -80px;

        display: block;
        flex-direction: row;
        align-items: stretch;
    }
`;

export const MiddleHeader = styled.h1`
    font-weight: 700;
    font-size: 26px;
    text-align: center;

    @media ${deviceConstants.tablet} {
        font-size: 32px;
        text-align: start;
        white-space: pre-wrap;
    }
`;

export const MiddleSubheader = styled.p`
    margin-top: 8px;

    font-weight: 500;
    font-size: 14px;
    text-align: center;
    white-space: pre-wrap;

    @media ${deviceConstants.tablet} {
        margin-top: 24px;

        font-size: 15px;
        text-align: start;
    }
`;

export const MiddleArrowWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 8px 0;

    @media ${deviceConstants.tablet} {
        justify-content: flex-end;
        padding: 24px 0;
    }
`;

export const MiddleRightArrow = styled(RightArrow)`
    display: none;

    @media ${deviceConstants.tablet} {
        display: block;
        max-width: 150px;
        max-height: 150px;
        margin-right: 24px;
    }
`;

export const MiddleDownArrow = styled(DownArrow)`
    max-width: 100px;
    max-height: 100px;

    @media ${deviceConstants.tablet} {
        display: none;
    }
`;

export const MiddleImage = styled.img`
    width: 100%;
    height: 500px;
    object-fit: cover;

    z-index: -1;
    position: absolute;
    left: 0;
    margin-top: 600px;

    @media ${deviceConstants.tablet} {
        height: auto;
        object-fit: fill;

        margin-top: 390px;
    }
`;

export const MiddleForm = styled.div`
    max-width: 380px;
    padding: 40px 16px 40px 16px;
    border-radius: 8px;
    background: ${colorConstants.white};
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    @media ${deviceConstants.tablet} {
        padding: 40px 32px 56px 32px;    
    }
`;

export const MiddleFormTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
    text-align: center;

    @media ${deviceConstants.tablet} {
        font-size: 24px;
        white-space: pre-wrap;
    }
`;