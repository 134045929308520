import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { colorConstants } from '../../../constants';


export const Container = styled(Modal)`

`;

export const Header = styled(Modal.Header)`
    border-bottom: none;
`;

export const Title = styled(Modal.Title)`
    font-weight: 600;
    font-size: 18px;
    color: ${colorConstants.black};
`;

export const Body = styled.div`
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
        margin-bottom: 16px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;

export const AddLinkButton = styled.div`
    width: 100%;
    padding: 16px;
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    :hover {
        background: ${colorConstants.primaryFaded};
        cursor: pointer;
    }
`;

export const ButtonIcon = styled.div`
    width: 50px;
    margin-right: 16px;
    text-align: center;
`;

export const ButtonText = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${colorConstants.black};
`;