import styled from 'styled-components';
import { GradientFormSelector } from '../../custom/FormInput';

import { colorConstants } from "../../../constants";


const Container = styled.div`
    
`;

const Header = styled.div`
    margin-bottom: 8px;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 15px;
    color: ${colorConstants.black};
`;

const OptionsSection = styled.div`
    padding: 0px 16px;
`;



export const RoomSelector = ({ title, options, selected, onChange }) => {

    return (
        <Container>
            <Header>
                <Title>{title}</Title>
            </Header>

            <OptionsSection>
                <GradientFormSelector
                    options={options}
                    id="room-selector"
                    name="room-selector"
                    selected={selected}
                    onChange={(event) => onChange(event.target.name, event.target.value)} />
            </OptionsSection>
        </Container>
    );
};