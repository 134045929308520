import React, { useState, useRef } from 'react';
import {
    Container,
    LeftColumn,
    Title,
    InputContainer,
    StyledInput,
    InputButtons,
    InputTitle,
    InputButton,
    RightColumn,
    DeleteButton
} from './SectionLabelInput.styles';
import { DragHandle } from "../../DragHandle";
import { colorConstants } from '../../../../constants';


export const SectionLabelInput = ({
    data,
    dragHandleProps,
    handleLabelChange,
    handleDelete,
}) => {

    const [isEditingLabel, setEditingLabel] = useState(false);

    var labelInputRef = useRef(null);

    function onLabelClick() {        
        setEditingLabel(!isEditingLabel);
        labelInputRef.current.focus();
    }

    function notFocused(event){
        event.preventDefault();
    
        if(event.type === 'blur' && event.target.name === 'section-label') {
            setEditingLabel(false);
        }
    }


    return (
        <Container>
            <LeftColumn>
                <Title>Page Section</Title>

                <InputContainer>
                    <InputButtons enable={!isEditingLabel ? 1 : null} >
                        <InputTitle onClick={() => onLabelClick()} >{data.description ? data.description : 'Type section label ...'}</InputTitle>
                        <InputButton onClick={() => onLabelClick()} />
                    </InputButtons>

                    <StyledInput
                        onFocus={notFocused}
                        onBlur={notFocused}
                        ref={labelInputRef}
                        enable={isEditingLabel ? 1 : null}
                        type='text'
                        name='section-label'
                        value={data.description}
                        onChange={data.id ? (event) => handleLabelChange(event, data.id) : handleLabelChange} />
                </InputContainer>
            </LeftColumn>

            <RightColumn>
                { dragHandleProps && <DragHandle size={24} customcolor={colorConstants.darkGrey} {...dragHandleProps} /> }
                { handleDelete && <DeleteButton size={32} onClick={() => handleDelete(data.id)} /> }
            </RightColumn>
        </Container>
    );
};