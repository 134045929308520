import React, { Component } from "react";
import { connect } from 'react-redux';

import { userActions, nftActions, alertActions } from '../../../actions';
import { ScreenContainer } from './MyNFTsContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { Notification, Warning } from '../../../components/custom/Popup';
import { NoNFTFound } from '../../../components/custom/EmptyState';
import { NFTList } from "../../../components/nfts";
import { TopBar } from '../../../components/custom/TopBar';
import { SideBar } from '../../../components/custom/SideBar';
import { FullScreenSpinner } from "../../../components/custom/Spinner";
// import { FiltersMenu } from '../../components/custom/Filter';
import { history, defaultUser } from '../../../utils';
import { topbarConstants, contentNFTPage, colorConstants, sitemap, getNFTLink } from "../../../constants";


class MyNFTsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false, // initialize user data from server db
            fromUrl: sitemap.admin.dashboard,
            sidebar: false,
            sidebarReset: false,
            sidebarPositionOffset: 0,
            sticky: false,
            user: defaultUser(),
            deleting: false,
            nftIdToDelete: null,
            deleteWarning: false, // hide/show delete nft warning
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleMenuButton = this.handleMenuButton.bind(this);

        this.handleAddNft = this.handleAddNft.bind(this);
        this.handleViewNft = this.handleViewNft.bind(this);
        this.handleEditNft = this.handleEditNft.bind(this);
        this.handleEnableNft = this.handleEnableNft.bind(this);

        this.handleDeleteNftRequest = this.handleDeleteNftRequest.bind(this);
        this.handleDeleteNft = this.handleDeleteNft.bind(this);
        this.handleDeleteNftCancel = this.handleDeleteNftCancel.bind(this);

        this.handleFilter = this.handleFilter.bind(this);
        this.handleResetFilter = this.handleResetFilter.bind(this);

        this.handleClearNotification = this.handleClearNotification.bind(this);
    }

    componentDidMount() {
        if(!this.props.user || !this.props.user.id) {
            // get user data from db
            this.props.getById(this.props.userId);
        } else if(!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access or all categories disabled
            if(!this.props.user.creator || !this.props.user.pageOptions.showNFTLinks) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            // load user data from server into component state
            this.setState({ ...this.state, init: true, user: this.props.user });
        }
        window.addEventListener('scroll', this.handleScroll);
        this.props.getAll(new URLSearchParams({ userId: this.props.userId })); // get all NFTs by user id
         // set from URL
        if(this.props.location.state && this.props.location.state.from) this.setState({ ...this.state, fromUrl: this.props.location.state.from });
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.props.reset();
    }

    componentDidUpdate(){
        // load user data from server into component state
        if (!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access or all categories disabled
            if(!this.props.user.creator || !this.props.user.pageOptions.showNFTLinks) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            this.setState({ ...this.state, init: true, user: this.props.user });
        }
    }

    handleScroll(event) {
        // Enable sticky mode on scroll and reset sidebar
        this.setState({ ...this.state, sidebar: false, sidebarReset: true, sidebarPositionOffset: window.pageYOffset, sticky: window.pageYOffset > 1 });
    }

    handleMenuButton() {
        this.setState({ ...this.state, sidebar: !this.state.sidebar, sidebarReset: false });
    }

    handleAddNft() {
        if(this.state.sidebar) this.setState({ ...this.state, sidebar: false, sidebarReset: true });
        // open add NFT screen
        history.push({
            pathname: sitemap.admin.nft.add,
            state: {
                from: sitemap.admin.nft.list
            }
        });
    }

    handleViewNft(id, slug) {
        // open view NFT details screen
        history.push({
            pathname: getNFTLink(this.props.username, slug),
            state: {
                from: sitemap.admin.nft.list,
                data: {
                    userId: this.props.userId,
                    productId: id
                }
            }
        });
    }

    handleEditNft(id, slug) {
        // Open edit NFT page
        history.push({
            pathname: sitemap.admin.nft.edit,
            state: {
                from: sitemap.admin.nft.list,
                data: {
                    nftId: id
                }
            }
        });
    }

    handleEnableNft(id, enabled){
        this.props.update({ id, userId: this.props.userId, enabled });
    }

    handleDeleteNftRequest(id){
        // show delete NFT warning
        this.setState({ ...this.state, nftIdToDelete: id, deleteWarning: true });
    }

    async handleDeleteNft() {
        // get id of NFT to be deleted from state
        let id = this.state.nftIdToDelete;

        // start spinner and hide warning
        this.setState({ ...this.state, deleting: true, nftIdToDelete: null, deleteWarning: false });

        // Delete NFT from db & photos from firebase
        await this.props.delete(id);

        // stop spinner
        this.setState({ ...this.state, deleting: false });
    }

    handleDeleteNftCancel(){
        // hide delete NFT warning
        this.setState({ ...this.state, nftIdToDelete: null, deleteWarning: false });
    }

    handleFilter(selection) {
        switch (selection) {

            case 5: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId, type: 'NFT' }));
                return;
            }

            default: {
                this.props.getAll(new URLSearchParams({ userId: this.props.userId }));
                return;
            }
        }
    }

    handleResetFilter(){
        this.props.getAll(new URLSearchParams({ userId: this.props.userId }));
    }

    handleClearNotification(){
        this.props.clearAlert(); // reset alert in redux state
    }

    render() {
        return (
            <ScreenContainer id='screen'>
                <MetaTags title={topbarConstants.titles.myNFTs} />

                <TopBar
                    title={topbarConstants.titles.myNFTs}
                    sticky={this.state.sticky}
                    menuButton={this.handleMenuButton}
                    addButton={this.handleAddNft} />

                {this.state.init &&
                <SideBar
                    options={{ isAuthenticated: true, creator: this.state.user.creator, basic: this.state.user.basic, premium: this.state.user.premium, platinum: this.state.user.platinum, links: this.state.user.pageData.metadata.links }}
                    toggle={this.handleMenuButton}
                    from={sitemap.admin.nft.list}
                    enable={this.state.sidebar}
                    reset={this.state.sidebarReset}
                    position={this.state.sidebarPositionOffset} /> }

                {/* {this.state.init &&
                <FiltersMenu
                    handleReset={this.handleResetFilter}
                    handleFilter={this.handleFilter}
                    options={{
                        showNFTLinks: this.state.user.pageData.metadata.links.nft.enabled || this.state.user.pageData.metadata.links.nft.total > 0,
                        force: true
                    }}
                    noActive={true} /> } */}

                <NFTList
                    nfts={this.props.nfts}
                    handleViewNft={this.handleViewNft}
                    handleEditNft={this.handleEditNft}
                    handleDeleteNft={this.handleDeleteNftRequest}
                    handleEnableNft={this.handleEnableNft} />

                { !this.state.deleting && !this.props.loading && (!this.props.nfts || this.props.nfts.length === 0) &&
                <NoNFTFound onClick={this.handleAddNft} /> }

                {this.props.alert && this.props.alert.message &&
                <Notification
                    onHide={this.handleClearNotification}
                    message={this.props.alert.message} /> }

                {(this.state.deleting || this.props.loading) && <FullScreenSpinner />}

                <Warning
                    show={this.state.deleteWarning}
                    onHide={this.handleDeleteNftCancel}
                    firstButtonClick={this.handleDeleteNft}
                    secondButtonClick={this.handleDeleteNftCancel}
                    firstButton={contentNFTPage.deleteNftWarning.firstButton}
                    firstButtonColor={colorConstants.darkGrey}
                    firstButtonBackgroundColor={colorConstants.softGrey}
                    secondButton={contentNFTPage.deleteNftWarning.secondButton}
                    title={contentNFTPage.deleteNftWarning.title}
                    body={contentNFTPage.deleteNftWarning.body} />
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get user id from cookie
    let userId = false;
    if(state.auth && state.auth.cookie && state.auth.cookie.id) {
        userId = state.auth.cookie.id;
    }

    // get data from app reducer state
    const { alert, user } = state;
    const { username } = state.auth.cookie;
    const { loading, nfts } = state.nft;

    // export state data to props
    return { userId, alert, user, username, loading, nfts };
}

const actionCreators = {
    getById: userActions.getById,
    getAll: nftActions.getAll,
    update: nftActions.update,
    delete: nftActions.delete,
    reset: nftActions.clear,
    clearAlert: alertActions.clear
}

const connectedMyNFTsContainer = connect(mapState, actionCreators)(MyNFTsContainer);
export { connectedMyNFTsContainer as MyNFTsContainer };