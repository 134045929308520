// Manages the trips section of the application state used to list trips and trip info
import { hotelReducerConstants } from '../constants';

export function hotel(state = {}, action) {

  switch (action.type) {

    case hotelReducerConstants.FIND_REQUEST:
        return {
            ...state,
            loading: true
        };

    case hotelReducerConstants.FIND_SUCCESS:
        return {
            ...action.hotel
        };

    case hotelReducerConstants.FIND_FAILURE: {
        let { loading, ...stateCopy } = state;
        return {
            ...stateCopy,
            error: action.error
        };
    }
    
    case hotelReducerConstants.GETALL_REQUEST:
        return {
            ...state,
            loading: true
        };

    case hotelReducerConstants.GETALL_SUCCESS:
        return {
            hotels: action.hotels
        };

    case hotelReducerConstants.GETALL_FAILURE: {
        let { loading, ...stateCopy } = state; // remove loading flag
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case hotelReducerConstants.GETBYID_REQUEST:
        return {
            loading: true
        };
    
    case hotelReducerConstants.GETBYID_SUCCESS:
        return {
            ...action.hotel
        };

    case hotelReducerConstants.GETBYID_FAILURE:
        return {
            error: action.error
        };

    case hotelReducerConstants.GETAVAILABILITY_REQUEST:
        return {
            ...state,
            loading: true
        };
    
    case hotelReducerConstants.GETAVAILABILITY_SUCCESS: {
        const { loading, ...stateCopy } = state;
        if(state.id && action.hotel && action.hotel.id && state.id !== action.hotel.id) return { ...stateCopy, error: 'Invalid booking options' };
        return {
            ...stateCopy,
            ...action.hotel,
        };
    }

    case hotelReducerConstants.GETAVAILABILITY_FAILURE: {
        const { loading, ...stateCopy } = state;
        return {
            ...stateCopy,
            error: action.error
        };
    }

    case hotelReducerConstants.CHECKRATE_REQUEST:
        return {
            ...state,
            loadingCheckRate: true
        };
    
    case hotelReducerConstants.CHECKRATE_SUCCESS: {
        let { loadingCheckRate, ...stateCopy } = state;
        return {
            ...stateCopy,
            checkRate: action.checkRate,
        };
    }

    case hotelReducerConstants.CHECKRATE_FAILURE: {
        let { loadingCheckRate, ...stateCopy } = state;
        return {
            ...stateCopy,
            checkRateError: action.error,
        };
    }
    
    case hotelReducerConstants.CLEAR_ALL:
        return {};

    case hotelReducerConstants.CLEAR_CHECKRATE: {
        let { loadingCheckRate, checkRate, checkRateError, ...stateCopy } = state;
        return stateCopy;
    }

    default:
        return state
  }
}