import { http, handleResponse, handleError } from "../utils";

export const publicHotelService = {
    findAllByLocation,
    getSearchResultsBySession,
    getBySlug,
};


async function findAllByLocation(data) {
    return await http.get(`/public/hotels/findAllByLocation?${data.toString()}`)
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getSearchResultsBySession(data) {
    return await http.post(`/public/hotels/getSearchResultsBySession`, data)
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}

async function getBySlug(slug, query) {
    // construct api call url
    let url = `/public/hotels/${slug}`;
    if(query) url = url + `?${query.toString()}`
    // call api
    return await http.get(url)
                    .then((res) => handleResponse(res, false))
                    .catch((err) => handleError(err, false));
}