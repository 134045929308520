import React, { Component } from "react";
import { connect } from 'react-redux';

import { productActions } from '../../../actions';
import { ScreenContainer } from './AddProductContainer.styles';
import { MetaTags } from '../../../components/custom/Helmet';
import { AddProduct }  from "../../../components/products";
import { FullScreenSpinner } from "../../../components/custom/Spinner";
import { TopBar } from '../../../components/custom/TopBar';
import ImageCrop from "../../../components/cropper/ImageCrop";
import {
    defaultProduct,
    defaultPhotos,
    history,
    notNull,
    compressImage,
    storageRef,
    uploadFile,
    getFileDownloadURL,
    removeTokenFromFileDownloadURL,
    finalizeProductData,
    getImageFileDimensions
} from '../../../utils';
import { topbarConstants, contentAddProductPage, getEnalbedFeatureTypes, otherConstants, sitemap, featureOptions } from "../../../constants";


class AddProductContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            init: false,
            fromUrl: sitemap.admin.products.list,
            typingTimeout: 0, // used to know when user stops typing
            submitted: false,
            creating: false,
            photos: defaultPhotos(),
            product: defaultProduct(contentAddProductPage.options.types[0]),
            productDescriptionLength: 0 // used to force user to type a minimum description before adding
        };

        this.handleTopBarBackButton = this.handleTopBarBackButton.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleDescriptionLengthChange = this.handleDescriptionLengthChange.bind(this);

        this.handleUploadPhoto1 = this.handleUploadPhoto1.bind(this);
        this.handleUploadPhoto2 = this.handleUploadPhoto2.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if(!this.props.location.state || !this.props.location.state.from || !this.props.location.state.data || !this.props.location.state.data.type || !featureOptions.product.types.includes(this.props.location.state.data.type) || !this.props.user || !this.props.user.id) {
            history.replace({ pathname: this.state.fromUrl, state: { error: 'Invalid container startup props' } });
        }

        // set from URL
        let initialState = { ...this.state, fromUrl: this.props.location.state.from };

        // get data from previous page
        const { type } = this.props.location.state.data;

        // set product type
        if(type) initialState = { ...initialState, product: defaultProduct(type) };

        // set initial settings page state
        this.setState(initialState);
    }

    componentDidUpdate() {
        // initialize state
        if(!this.state.init && this.props.user && this.props.user.id) {
            // redirect if no access
            if(!this.props.user.creator) {
                history.replace({ pathname: this.state.fromUrl, state: { error: 'Not Authorized' } });
                return;
            }
            this.setState({ ...this.state, init: true, product: { ...this.state.product, userId: this.props.user.id } });
        }
    }

    handleTopBarBackButton() {
        if(this.state.photos.crop.pendingImage) this.setState({ ...this.state, photos: { ...this.state.photos, crop: defaultPhotos().crop } }); // reset crop image screen
        else history.push({ pathname: this.state.fromUrl }); // Go back
    }

    handleChange(event) {
        let { name, value } = event.target;
        const { product } = this.state;

        // clear state on product type change
        if(name === 'type' && value !== this.state.product.type){
            this.setState({
                submitted: false,
                creating: false,
                photos: defaultPhotos(),
                product: defaultProduct(value, this.state.product.userId)
            });
            return;
        } else if(name === 'simple-link-label') {
            // save btnLabel value
            name = 'button';
            value = { ...this.state.product.button, label: value };
        } else if(name === 'simple-link-url') {
            // save btnUrl value
            name = 'button';
            value = { ...this.state.product.button, url: value };
        }

        this.setState({
            submitted: false,
            creating: false,
            product: {
                ...product,
                [name]: value, // update input data
            }
        });
    }

    handleDescriptionLengthChange(length){
        this.setState({ ...this.state, productDescriptionLength: length });
    }

    handleUploadPhoto1(e){
        // Save inserted image url in state
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save inserted photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        ...this.state.photos,
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: 'photo-1',
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    handleUploadPhoto2(e){
        // Save inserted image url in state
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                // Save inserted photo url in state ready for cropping
                this.setState({
                    ...this.state,
                    photos: {
                        ...this.state.photos,
                        crop: {
                            pendingImage: notNull(reader.result),
                            pendingImageFilename: 'photo-2',
                            pendingImageFileSize: e.target.files[0].size,
                            pendingImageDimensions: await getImageFileDimensions(reader.result)
                        }
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    async handleCroppedImage(croppedImage){

        // get temp images from state
        let { temp } = this.state.photos;
        if(!temp) temp = [];

        // get product photo urls from state
        let { imageUrls } = this.state.product;
        if(!imageUrls) imageUrls = [];

        // remove previously uploaded image if any
        if(temp.length > 0) {
            temp = temp.filter(img => {
                            if(img.file.name.includes(croppedImage.name)){
                                // remove previous url from product photo urls array
                                if(imageUrls.length > 0) imageUrls = imageUrls.filter(url => url !== img.url);
                                return false;
                            }
                            return true;
                        });
        }

        // compress cropped image file
        const compressedFile = await compressImage(croppedImage, croppedImage.name, 1080);

        // save compressed image to temp state
        let tempImage = { file: compressedFile, url: notNull(URL.createObjectURL(compressedFile)) };
        temp.push(tempImage);

        // save compressed file URL
        imageUrls.push(tempImage.url);

        this.setState({
            ...this.state,
            product: {
                ...this.state.product,
                imageUrls: imageUrls // save compressed image URI
            },
            photos: {
                crop: defaultPhotos().crop, // reset pending image for cropping
                temp: temp
            }
        });
    }

    handleSubmit() {
        const { product } = this.state;

        this.setState({
            submitted: true,
            creating: true // enable spinner
        }, async () => {

            // do not submit product with no photos
            if(!this.state.photos.temp || this.state.photos.temp.length === 0){
                // disable spinner and stop
                this.setState({ ...this.state, creating: false });
                return;
            }

            // do not submit product with no product name
            if(!this.state.product.name || this.state.product.name < 4) {
                // disable spinner and stop
                this.setState({ ...this.state, creating: false });
                return;
            }

            // do not submit product with no description
            if(!this.state.product.description || this.state.productDescriptionLength < 50) {
                // disable spinner and stop
                this.setState({ ...this.state, creating: false });
                return;
            }

            // create new product
            let createNewProductPromise = new Promise(async (resolve, reject) => {

                // create new product
                await this.props.create(finalizeProductData(product));

                if(this.props.product.id) resolve(this.props.product.id); // return newlly created product's ID saved in state

                reject('Could not create product');
            });

            await createNewProductPromise.then(async (productId) => {
                // get local product photo URLs
                let { imageUrls } = product;

                for(const photo of this.state.photos.temp) {
                    // create firebase storage reference of image to upload
                    const imageRef = storageRef(otherConstants.environment + '/users/' + this.props.user.id + '/products/' + productId + '/photos/product-' + productId + '-' + photo.file.name);

                    // Upload image to firebase storage
                    const snapshot = await uploadFile(imageRef, photo.file);

                    // Get firebase public url of uploaded photo
                    let uploadedPhotoFirebaseUrl = await getFileDownloadURL(snapshot.ref);

                    // Clean public url
                    uploadedPhotoFirebaseUrl = removeTokenFromFileDownloadURL(uploadedPhotoFirebaseUrl);

                    // remove local image url from product photos
                    imageUrls = imageUrls.filter(url => {
                        if(url === photo.url){
                            URL.revokeObjectURL(url); // revoke local file url
                            return false;
                        }
                        return true;
                    });

                    // add firebase url in product photos
                    imageUrls.push(uploadedPhotoFirebaseUrl);
                }

                // save uploaded product photos url in state
                this.setState({ ...this.state, product: { ...this.state.product, imageUrls } }, async () => {
                    // update product photos in db
                    if(imageUrls && imageUrls.length > 0) await this.props.update({ id: productId, userId: product.userId, imageUrls });

                    // reset photos in state and disable spinner
                    this.setState({ ...this.state, creating: false, photos: defaultPhotos() });

                    // Go back to previous screen
                    history.push({
                        pathname: this.state.fromUrl,
                        state: {
                            from: sitemap.admin.products.add
                        }
                    });
                });
            }).catch(err => {
                // stop spinner and reset product data
                this.setState({
                    ...this.state,
                    creating: false,
                    photos: defaultPhotos(),
                    product: defaultProduct(contentAddProductPage.options.types[0], this.state.product.userId)
                });
            });
        });
    }


    render() {
        return (
            <ScreenContainer id='screen' >
                <MetaTags title={topbarConstants.titles.addProduct} />

                {this.state.init &&
                <TopBar
                    sticky={this.state.product.name}
                    title={topbarConstants.titles.addProduct}
                    backButton={this.handleTopBarBackButton} /> }

                { this.state.init && !this.state.photos.crop.pendingImage &&
                <AddProduct
                    product={this.state.product}
                    productTypes={getEnalbedFeatureTypes(this.props.user.pageData.metadata.links).product.types}
                    submitted={this.state.submitted}
                    creating={this.state.creating}
                    hasPhotos={this.state.photos.temp && this.state.photos.temp.length > 0}
                    alert={this.props.alert} // show error if not updated
                    handleChange={this.handleChange}
                    handleDescriptionLengthChange={this.handleDescriptionLengthChange}
                    productDescriptionLength={this.state.productDescriptionLength}
                    handleSubmit={this.handleSubmit}
                    handleUploadPhoto1={this.handleUploadPhoto1}
                    handleUploadPhoto2={this.handleUploadPhoto2} /> }

                { this.state.photos.crop.pendingImage &&
                <ImageCrop
                    url={this.state.photos.crop.pendingImage}
                    filename={this.state.photos.crop.pendingImageFilename}
                    fileSize={this.state.photos.crop.pendingImageFileSize}
                    imageDimensions={this.state.photos.crop.pendingImageDimensions}
                    circularCrop={false}
                    handleCroppedImage={this.handleCroppedImage} /> }

                {(this.state.creating) && <FullScreenSpinner />}
            </ScreenContainer>
        );
    }
}

function mapState(state) {
    // get data from app reducer state
    const { alert, user, product } = state;

    // export state data to props
    return { alert, user, product };
}

const actionCreators = {
    create: productActions.create,
    update: productActions.update,
}

const connectedAddProductContainer = connect(mapState, actionCreators)(AddProductContainer);
export { connectedAddProductContainer as AddProductContainer };