import React, { useState } from 'react';
import validator from 'validator';
import {
    Container,
    SectionTitle,
    StyledForm,
    StyledFormGroup,
    FormInputTitle, FormInputText,
    StyledFormText,
    StyledFormLabel,
    AlertWarning,
    AlertSuccess,
    ButtonSection, StyledButton, TermsText, TermsLink,
} from './CreatorApplicationForm.styles';
import { GradientFormInput, GradientFormSelector } from '../../custom/FormInput';
import { sitemap, contentTravelLandingPage } from '../../../constants';


const CreatorApplicationForm = ({ user, alert, submitData }) => {

    const [igHandle, setIgHandle] = useState('');
    const [primaryPlatform, setPrimaryPlatform] = useState('');
    const [type, setType] = useState('CREATOR');
    const [isLink, setIsLink] = useState(false);
    const [submitted, setSubmitted] = useState(false);


    // handle change
    function handleChange(event) {
        let { name, value } = event.target;

        switch(name) {
            case 'igHandle': {
                // append symbol @
                if(!value.startsWith("@")) value = "@" + value;
                setIgHandle(value);
                break;
            }

            case 'primaryPlatform': {
                setPrimaryPlatform(value);
                break;
            }

            case 'type': {
                setType(value.toUpperCase());
                break;
            }

            default:
                break;
        }

        setSubmitted(false);
    }


    // append symbol @ on input focus
    function handleFocus(event) {
        let { value } = event.target;
        // append symbol @ on igHandle when user clicks on the input box
        if(!value.startsWith("@")) setIgHandle('@' + igHandle);
    }


    // remove symbol @ when not on focus
    function handleBlur(event) {
        let { value } = event.target;
        // remove the @ symbol if user did not type any username
        if(value === "@") setIgHandle('');
    }


    // handle submit
    function handleSubmit() {
        if(!submitData) return;

        const validatorUrlOptions = {
            protocols: ['http', 'https'],
            require_host: true,
        };

        // validate collection link
        const isValidLink = validator.isURL(primaryPlatform, validatorUrlOptions);
        setIsLink(isValidLink);

        setSubmitted(true);

        if(!isValidLink) return;

        // submit final data
        submitData({ name: user.name, email: user.email, igHandle, primaryPlatform, type, note: 'Standard User' });
    };


    return (
        <Container>
            <StyledForm>
                <SectionTitle>To apply for a creator account submit the information below</SectionTitle>

                <StyledFormGroup>
                    <FormInputTitle>Full Name</FormInputTitle>
                    <FormInputText>{user.name}</FormInputText>
                </StyledFormGroup>

                <StyledFormGroup>
                    <FormInputTitle>Email</FormInputTitle>
                    <FormInputText>{user.email}</FormInputText>
                </StyledFormGroup>

                <StyledFormGroup className={submitted && !user.igHandle ? " has-error" : ""} >
                    <GradientFormInput
                        box={'enable'}
                        nolabel={'enable'}
                        type="text"
                        placeholder={contentTravelLandingPage.form.field2.placeholder}
                        name="igHandle"
                        value={igHandle}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    { submitted && !igHandle && <StyledFormText>{contentTravelLandingPage.form.field2.warning}</StyledFormText> }
                </StyledFormGroup>

                <StyledFormGroup className={submitted && !user.primaryPlatform ? " has-error" : ""} >
                    <GradientFormInput
                        box={'enable'}
                        nolabel={'enable'}
                        type="text"
                        placeholder={contentTravelLandingPage.form.field3.placeholder}
                        name="primaryPlatform"
                        value={primaryPlatform}
                        onChange={handleChange}
                    />
                    { submitted && !primaryPlatform && <StyledFormText>{contentTravelLandingPage.form.field3.warning}</StyledFormText> }
                    { submitted && primaryPlatform && !isLink && <StyledFormText>{contentTravelLandingPage.form.field3.warning2}</StyledFormText> }
                </StyledFormGroup>

                <StyledFormGroup>
                    <StyledFormLabel>{contentTravelLandingPage.form.field5.placeholder}</StyledFormLabel>
                    <GradientFormSelector
                        big={'enable'}
                        options={contentTravelLandingPage.form.field5.options}
                        id="selector"
                        name="type"
                        onChange={handleChange} />
                </StyledFormGroup>

                {alert && alert.message && alert.type === 'alert-danger' && <AlertWarning>{alert.message}</AlertWarning>}
                {alert && alert.message && alert.type === 'alert-success' && <AlertSuccess>{alert.message}</AlertSuccess>}
            </StyledForm>

            <ButtonSection>
                <TermsText>{'By submitting this form you agree to our '}<TermsLink to={sitemap.landing.z.policies.termsForAssociates} >Associate Terms and Conditions</TermsLink>.</TermsText>
                <StyledButton gradient={true} onClick={handleSubmit} >Submit Application</StyledButton>
            </ButtonSection>
        </Container>
    );
};

export default CreatorApplicationForm;